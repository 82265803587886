<div class="xavier-steps-wrapper container">
  <div class="row">
    <div class="col-sm-4">
      <div class="xavier-steps header">
        Create Account
      </div>
      <div class="xavier-steps-component">
        <xavier-steps step="2"></xavier-steps>
      </div>
    </div>
    <div class="col-sm-8" [@slideIn]>
      <form [formGroup]="registrationData" novalidate (ngSubmit)="onSubmit(registrationData)">
        <div class="row create-wrapper">
          <div class="form-group col-6 xavier-input-text">
            <label for="firstName">First name</label>
            <span class="required">&bull;</span>
            <input type="text" class="form-control" id="firstName" formControlName="firstName" placeholder="First name">
            <xavier-error-message [control]="registrationData.get('firstName')"></xavier-error-message>
          </div>
          <div class="form-group col-6 xavier-input-text">
            <label for="lastName">Surname</label>
            <span class="required">&bull;</span>
            <input type="text" class="form-control" id="lastName" formControlName="lastName" placeholder="Surname">
            <xavier-error-message [control]="registrationData.get('lastName')"></xavier-error-message>
          </div>
        </div>
        <div class="row" formGroupName="account">
          <div class="form-group col-6 xavier-input-text">
            <label for="email">Email Address</label>
            <span class="required">&bull;</span>
            <input type="email" class="form-control" id="email" formControlName="email" placeholder="Email Address">
            <xavier-error-message [control]="registrationData.get('account.email')"></xavier-error-message>
          </div>
          <div class="form-group col-6 xavier-input-text">
            <label for="emailConfirm">Confirm Email Address</label>
            <span class="required">&bull;</span>
            <input type="email" class="form-control" id="emailConfirm" formControlName="emailConfirm" placeholder="Confirm Email Address">
            <xavier-error-message [control]="registrationData.get('account')"></xavier-error-message>
          </div>
        </div>
        <xavier-multi-select-buttons
          [title]="'Entity Type'" [id]="'entityType'" [elements]="step2MultiSelectButtons" [errorClass]="multiSelectErrorClass"
          [parentFormControl]="registrationData.get('entityType')">
        </xavier-multi-select-buttons>
        <div class="row" *ngIf="showCompanyRow" [@fadeIn]>
          <div class="form-group col-6 xavier-input-text">
            <label for="companyName" id="companyNameLabel">{{typeOfEntity}} Name</label>
            <span class="required">&bull;</span>
            <input type="text" class="form-control" id="companyName" formControlName="companyName" placeholder="{{typeOfEntity}} Name">
            <xavier-error-message [control]="registrationData.get('companyName')"></xavier-error-message>
          </div>
          <div class="form-group col-6 xavier-input-text">
            <label for="registrationNumber">Registration Number</label>
            <span class="required">&bull;</span>
            <input type="text" class="form-control" id="registrationNumber" formControlName="registrationNumber" placeholder="Registration Number">
            <xavier-error-message [control]="registrationData.get('registrationNumber')"></xavier-error-message>
          </div>
        </div>
        <div class="row" *ngIf="showPrivateRow" [@fadeIn]>
          <div class="form-group col-6 xavier-input-text">
            <label for="insuranceNumber">National Insurance Number</label>
            <span class="required">&bull;</span>
            <input type="text" class="form-control" id="insuranceNumber" formControlName="insuranceNumber" placeholder="National Insurance Number">
            <xavier-error-message [control]="registrationData.get('insuranceNumber')"></xavier-error-message>
          </div>
        </div>
        <div *ngIf="showAddressFields">
          <div class="row" formGroupName="address">
            <div class="form-group col-6 xavier-input-text">
              <label for="addressLine1">Address Line 1</label>
              <span class="required">&bull;</span>
              <input type="text" class="form-control" id="addressLine1" formControlName="addressLine1" placeholder="Address Line 1">
              <xavier-error-message [control]="registrationData.get('address.addressLine1')"></xavier-error-message>
            </div>
            <div class="form-group col-6 xavier-input-text">
              <label for="addressLine2">Address Line 2</label>
              <input type="text" class="form-control" id="addressLine2" formControlName="addressLine2" placeholder="Address Line 2">
            </div>
          </div>
          <div class="row" formGroupName="address">
            <div class="form-group col-6 xavier-input-text">
              <label for="city">City</label>
              <span class="required">&bull;</span>
              <input type="text" class="form-control" id="city" formControlName="city" placeholder="City">
              <xavier-error-message [control]="registrationData.get('address.city')"></xavier-error-message>
            </div>
            <div class="form-group col-6 xavier-input-text">
              <label for="country">Country</label>
              <input type="text" class="form-control" id="country" formControlName="country" placeholder="Country">
            </div>
          </div>
        </div>
        <div class="row" *ngIf="showCompanyRow || showPrivateRow" [@fadeIn]>
          <div class="form-group col-12 xavier-input-text" *ngIf="!showAddressFields">
            <label for="{{addressLookupParams.id}}">{{addressLookupParams.title}}</label>
            <xavier-select-dropdown (entrySelectedEmitter)="onEntrySelected($event)" (createItemEmitter)="displayAddressFields()" [componentParams]="addressLookupParams">
            </xavier-select-dropdown>
            <xavier-error-message [control]="registrationData.get('address.postcode')"></xavier-error-message>
            <div *ngTemplateOutlet="enterManually"></div>
          </div>
          <div class="form-group col-12 xavier-input-text" formGroupName="address" *ngIf="showAddressFields">
            <label for="postcode">Postcode</label>
            <input type="text" class="form-control" id="postcode" formControlName="postcode" placeholder="Postcode">
            <xavier-error-message [control]="registrationData.get('address.postcode')"></xavier-error-message>
            <div *ngTemplateOutlet="enterManually"></div>
          </div>
        </div>
        <div class="row vat">
          <div class="form-group col-6 xavier-input-checkbox">
            <input type="checkbox" class="form-check-input" id="vatInEU" formControlName="vatInEU" (click)="clickCheckbox()">
            <label for="vatInEU" class="form-check-label">My business is VAT registered in the EU</label>
          </div>
          <div class="form-group col-6 xavier-input-text" [@popInOut]="!showVatField">
            <label for="vat">Vat Number</label>
            <span class="required">&bull;</span>
            <input type="text" class="form-control" id="vat" formControlName="vat" placeholder="Enter VAT number...">
            <xavier-error-message [control]="registrationData.get('vat')"></xavier-error-message>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-xs-12 col-xl-6 xavier-input-text phones">
            <label for="mobilePhone">Mobile Phone</label>
            <span class="required">&bull;</span>
            <input type="tel" class="form-control" id="mobilePhone" formControlName="mobilePhone" placeholder="Mobile Phone">
            <xavier-error-message [control]="registrationData.get('mobilePhone')"></xavier-error-message>
          </div>
          <div class="form-group col-xs-12 col-xl-4 xavier-input-text office">
            <label for="officePhone">Office Phone</label>
            <span class="required">&bull;</span>
            <input type="tel" class="form-control" id="officePhone" formControlName="officePhone" placeholder="Office Phone">
            <xavier-error-message [control]="registrationData.get('officePhone')"></xavier-error-message>
          </div>
          <div class="form-group col-xs-12 col-xl-2 xavier-input-text officeExt">
            <label for="officePhoneExt">Ext.</label>
            <input type="text" class="form-control" id="officePhoneExt" formControlName="officePhoneExt" placeholder="Ext.">
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-xl-2">
            <button type="button" class="btn btn-block xavier-button-plain" (click)="cancelRegistration()">
              <span>Cancel</span>
            </button>
          </div>
          <div class="col-5 button-spacer"></div>
          <div class="col-xs-12 col-xl-5">
            <button type="submit" class="btn btn-block xavier-button continue-step">
              <span>Continue to Legal Signatories</span> <i class="material-icons">&#xE5C8;</i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #enterManually>
  <div class="enter-manually text-muted" (click)="onEnterManuallyClicked()">{{showAddressFields ? 'Use postcode lookup' : 'Enter manually'}}</div>
</ng-template>
