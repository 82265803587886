import {style, state, animate, transition, trigger} from '@angular/animations';

export const popInOut = trigger('popInOut', [
  state('false' , style({ opacity: 1, transform: 'scale(1.0)' })),
  state('true', style({ opacity: 0, transform: 'scale(0.0)'  })),
  transition('1 => 0', animate('100ms')),
  transition('0 => 1', animate('100ms'))
]);

export const fadeIn = trigger('fadeIn',[
  transition(':enter', [
    style({opacity: 0}), animate('300ms', style({'opacity': 1}))
  ])
]);

export const fadeOut = trigger('fadeOut',[
  transition(':leave', [
    style({opacity: 1}), animate('300ms', style({'opacity': 0}))
  ])
]);

export const slideIn = trigger('slideIn', [
  transition(':enter', [
    style({opacity: 0, transition: '1s', left: '100px'}), animate('300ms ease-in')
  ])
]);

export const slideLeftFromHidden = trigger('slideLeftFromHidden', [
  transition(':enter', [
    style({transform: 'translateX(100px)', opacity: 0}),
    animate('500ms ease-in', style({transform: 'translateX(0)', 'opacity': 1}))
  ]),
  transition(':leave', [
    style({transform: 'translateX(0)', 'opacity': 1}),
    animate('0ms', style({transform: 'translateX(100%)', 'opacity': 0}))
  ])
]);

export const slideRightFromHidden = trigger('slideRightFromHidden', [
  transition(':enter', [
    style({transform: 'translateX(-100px)', opacity: 0}),
    animate('500ms ease-in', style({transform: 'translateX(0)', 'opacity': 1}))
  ]),
  transition(':leave', [
    style({transform: 'translateX(0)', 'opacity': 1}),
    animate('0ms', style({transform: 'translateX(100%)', 'opacity': 0}))
  ])
]);
