export const environment = {
  production: false,
  // apiUrl: 'http://localhost:8080',
  apiUrl: 'https://api-xavier-dev.projects.sprint-in.com',
  dbsUiUrl: 'https://xavier-dbs-check-order-dev.web.app',
  domain: '.projects.sprint-in.com',
  apiKey: "AIzaSyAfPHuLJsGYlEaM8mJRMsP244UX7CgnsBQ",
  authDomain: "xavier-development.firebaseapp.com",
  databaseURL: "xavier-development.firebaseio.com",
  projectId: "xavier-development",
  storageBucket: "api-xavier-dev.projects.sprint-in.com",
  messagingSenderId: "70878625373",
  serviceWorkerEnabled: true,
  yotiIdentityVerificationUrl: "https://api.yoti.com/sandbox/idverify/v1/web/index.html",
  yotiDigitalIdClientSdkId: "c9df0b27-b57d-4d89-83d5-6194fad4f99b",
  identityVerificationCheckId: 'checkId111',
  yotiScenarioIds: {
    ANY_ID: "905f4cdc-c3fc-4dd8-ab22-c67f0657187c",
    ANY_COUNTRY_PASSPORT: "960e06c1-223e-4905-8d70-099bbdcaabd0"
  },
};
