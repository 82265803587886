import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "../shared/shared.module";
import {Title} from "@angular/platform-browser";
import {TemplateComponent} from "./cmps/template/template.component";
import {RegistrationRoutingModule} from './registration.routing';
import {StepsComponent} from "./cmps/steps/steps.component";
import {Step1Component} from "./cmps/step1/step1.component";
import {Step2Component} from "./cmps/step2/step2.component";
import {Step3Component} from "./cmps/step3/step3.component";
import {Step4Component} from "./cmps/step4/step4.component";
import {Step5Component} from "./cmps/step5/step5.component";
import {LoginComponent} from "./cmps/login/login.component";
import {ForgotPasswordComponent} from "./cmps/forgot/forgot.component";
import {ResetPasswordComponent} from "./cmps/reset/reset.component";
import {ActivationComponent} from "./cmps/activation/activation.component";
import {ApplicationReviewComponent} from "./cmps/application-review/application-review.component";
import {SignUpComponent} from "./cmps/signup/signup.component";
import {MultiSelectButtonsComponent} from "./cmps-parts/multi-select-buttons/multi-select-buttons.component";
import {SelectDropdownComponent} from "./cmps-parts/select-dropdown/select-dropdown.component";
import {FileUploaderComponent} from "./cmps-parts/file-uploader/file-uploader.component";
import {PasswordStrengthComponent} from "./cmps-parts/password-strenght/password-strength.component";
import {environment} from "../../environments/environment";
import {HttpClientModule} from "@angular/common/http";
import {FormService} from "./services/form.service";
import {UserService} from "./services/user.service";
import {UserAuth} from "../../core/userAuth.core";
import {ToastyModule} from "ng2-toasty";
import {IntegrationModule} from '../integration/integration.module';
import {TFAuthStep1Component} from "./cmps/2fa/2fa-step1/2fa-step1.component";
import { CodeInputComponent } from "./cmps/2fa/2fa-code-input/code-input.component";
import { TFAuthStep2Component } from "./cmps/2fa/2fa-step2/2fa-step2.component";
import { TFAuthLoginComponent } from "./cmps/2fa/2fa-login/2fa-login.component";

@NgModule({
  declarations: [
    TemplateComponent,
    StepsComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    Step4Component,
    Step5Component,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ActivationComponent,
    ApplicationReviewComponent,
    SignUpComponent,
    MultiSelectButtonsComponent,
    SelectDropdownComponent,
    FileUploaderComponent,
    PasswordStrengthComponent,
    TFAuthStep1Component,
    TFAuthStep2Component,
    CodeInputComponent,
    TFAuthLoginComponent
  ],
  imports: [
    ReactiveFormsModule,
    RegistrationRoutingModule,
    IntegrationModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    ToastyModule.forRoot()
  ],
  exports: [],
  providers: [
    FormService,
    UserService,
    UserAuth,
    Title
  ]
})

export class RegistrationModule {

}
