<ng2-toasty [position]="'top-center'" class="xavier-toasty"></ng2-toasty>
<div class="xavier-add-user-wrapper container" [@fadeIn]>
  <div class="row header">
    <div class="col-11">
      <h1>Edit User</h1>
    </div>
    <div class="col-1 text-right">
      <button type="button" class="close close-user" aria-label="Close" (click)="cancelAddUser()">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
  <form [formGroup]="userDataForm" novalidate (ngSubmit)="onSubmit(userDataForm)">
    <div class="row">
      <div class="col-sm-12 col-xl-3">
        <h4>Personal Details</h4>
      </div>
      <div class="col-sm-12 col-xl-3">
        <div class="form-group xavier-input-text">
          <label for="firstName">First Name(s)</label>
          <input type="text" class="form-control" id="firstName" formControlName="firstName" placeholder="First Name(s)">
          <xavier-error-message [control]="userDataForm.get('firstName')"></xavier-error-message>
        </div>
      </div>
      <div class="col-sm-12 col-xl-3">
        <div class="form-group xavier-input-text">
          <label for="lastName">Last Name</label>
          <input type="text" class="form-control" id="lastName" formControlName="lastName" placeholder="Last Name">
          <xavier-error-message [control]="userDataForm.get('lastName')"></xavier-error-message>
        </div>
      </div>
      <div class="col-sm-12 col-xl-3">
        <div class="form-group xavier-input-text">
          <label for="email">Email</label>
          <input type="text" class="form-control" id="email" formControlName="email" placeholder="Email">
          <xavier-error-message [control]="userDataForm.get('email')"></xavier-error-message>
        </div>
      </div>
    </div>
    <div class="row" [@fadeIn]>
      <div class="col-sm-12 col-xl-3">
        <h4>Role</h4>
      </div>
      <div class="col-sm-12 col-xl-3">
        <div class="form-group xavier-input-text">
          <label for="roleName">Role</label>
          <xavier-autocomplete-select-dropdown (dropdownEntrySelectedEmitter)="onDropdownEntrySelected($event)"
                                               [componentParams]="roleSearchParams">
          </xavier-autocomplete-select-dropdown>
        </div>
      </div>
    </div>
    <div *ngIf="showCompanyDropdown" class="row">
      <div class="col-sm-12 col-xl-3">
        <h4>Company</h4>
      </div>
      <div class="col-sm-12 col-xl-3">
        <div class="form-group xavier-input-text" [@fadeIn]>
          <label for="companyName">Company</label>
          <xavier-autocomplete-select-dropdown (dropdownEntrySelectedEmitter)="onDropdownEntrySelected($event)"
                                               [componentParams]="companySearchParams">
          </xavier-autocomplete-select-dropdown>
        </div>
      </div>
    </div>
    <div class="row" [@fadeIn]>
      <div class="col-sm-12 col-xl-3">
        <h4>Divisions</h4>
      </div>
      <ng-container *ngFor="let division of companyDivisions; let i = index">
        <div *ngIf="i % 2 != 0 || i == 0" class="col-sm-12 col-xl-4">
          <div class="form-group xavier-input-checkbox">
            <input type="checkbox" class="form-check-input" formControlName="divisions" [id]="'division'+division.id">
            <label [for]="'division'+division.id" class="form-check-label">{{division.divisionName}}</label>
            <xavier-error-message [control]="userDataForm.get('divisions')"></xavier-error-message>
          </div>
        </div>
        <ng-container *ngIf="i % 2 == 0 && i != 0" >
          <div class="col-sm-12 col-xl-3"></div>
          <div class="col-sm-12 col-xl-4">
            <div class="form-group xavier-input-checkbox">
              <input type="checkbox" class="form-check-input" formControlName="divisions" [id]="'division'+division.id">
              <label [for]="'division'+division.id" class="form-check-label">{{division.divisionName}}</label>
              <xavier-error-message [control]="userDataForm.get('divisions')"></xavier-error-message>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="row xavier-add-user">
      <div class="col-sm-12 col-xl-3"></div>
      <div class="col-sm-12 col-xl-6">
        <button type="submit" class="btn xavier-button continue-step" id="submitButton" [disabled]="disabled">
          <span>Save Changes</span>
        </button>
        <button type="button" class="btn xavier-button-plain" (click)="cancelAddUser()">
          <span>Cancel</span>
        </button>
      </div>
      <div class="col-sm-12 col-xl-2">
        <button *ngIf="!userActivated" type="button"
                class="btn btn-block xavier-button continue-step candidate-button"
                id="resendButton" [disabled]="resendDisabled" (click)="resendInvite()">
          <span>Resend Invite</span>
        </button>
      </div>
      <div *ngIf="userId !== 0" class="col-sm-12 col-xl-1">
        <button type="button" class="btn xavier-button-plain white" id="disableButton" (click)="disableUser()" [disabled]="disabled">
          <span>Disable</span>
        </button>
      </div>
    </div>
  </form>
</div>
