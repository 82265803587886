import { Component } from '@angular/core';
import { Confirmation, DialogService } from './dialog.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.sass'],
})
export class DialogComponent {
  public get confirmation(): Confirmation {
    return this.dialogService.confirmation;
  }

  public get isAcceptButtonLoading(): boolean {
    return this.dialogService.isAcceptButtonLoading;
  }

  public get isRejectButtonLoading(): boolean {
    return this.dialogService.isRejectButtonLoading;
  }

  constructor(private dialogService: DialogService) {
  }

  public onAcceptClicked() {
    this.dialogService.accept();
  }

  public onRejectClicked(): void {
    if (!this.confirmation.rejectLabelHidden) {
      this.dialogService.reject();
    } else {
      this.dialogService.accept();
    }
  }

  public onCloseClicked(): void {
    if (!this.confirmation.rejectLabelHidden) {
      this.dialogService.close();
    } else {
      this.dialogService.accept();
    }
  }
}
