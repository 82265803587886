import {Directive, HostListener, Input} from "@angular/core";
import * as _ from 'lodash';

@Directive({
  selector: '[validateOnBlur]',
})

export class ValidateOnBlurDirective {
  @Input('validateFormControl') validateFormControl;

  constructor() {

  }

  @HostListener('focus', ['$event.target'])
  onFocus(target) {
    if (target.attributes.id.value == 'email' || target.attributes.id.value == 'emailConfirm' ||
        target.attributes.id.value == 'password') {
      if (this.validateFormControl.hasOwnProperty('controls'))
        _.forEach(this.validateFormControl.controls, function (control) {
          control.updateValueAndValidity();
        });
      else
        this.validateFormControl.updateValueAndValidity();
    }
  }

  @HostListener('focusout', ['$event.target'])
  onFocusout(target) {
    if (target.attributes.id.value == 'email' || target.attributes.id.value == 'emailConfirm' ||
        target.attributes.id.value == 'password') {
      if (this.validateFormControl.hasOwnProperty('controls'))
        _.forEach(this.validateFormControl.controls, function (control) {
          control.updateValueAndValidity();
        });
      else
        this.validateFormControl.updateValueAndValidity();
    }
  }

  @HostListener('keydown', ['$event.target'])
  onKeydown(target) {
    if (target.attributes.id.value == 'email' || target.attributes.id.value == 'emailConfirm' ||
      target.attributes.id.value == 'password') {
      if (this.validateFormControl.hasOwnProperty('controls'))
        _.forEach(this.validateFormControl.controls, function (control) {
          control.updateValueAndValidity();
        });
      else
        this.validateFormControl.updateValueAndValidity();
    }
  }
}
