<div class="container panel">
  <div class="row id-verification-form">
    <div class="col-sm-12 d-xl-block">
      <h4>Candidates Info</h4>
      <div *ngIf="candidateInfo">
        <table class="table">
          <tbody>
            <tr>
              <th>Current Name</th>
              <td>{{ candidateInfo.currentName }}</td>
            </tr>
            <tr>
              <th>Date of Birth</th>
              <td>{{ candidateInfo.dateOfBirth }}</td>
            </tr>
            <tr>
              <th>Gender</th>
              <td>{{ candidateInfo.gender }}</td>
            </tr>
            <tr>
              <th>Position Details</th>
              <td>{{ candidateInfo.positionDetails }}</td>
            </tr>
            <tr>
              <th>Place of birth</th>
              <td>{{ candidateInfo.placeOfBirth }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br>
      <h4>Address History</h4>
      <div *ngIf="addressHistory">
        <table class="table">
          <tbody>
            <tr>
              <th>Current Address</th>
              <td>{{ addressHistory.currentAddress.address }}</td>
            </tr>
            <tr>
              <th>Dates</th>
              <td>
                {{ addressHistory.currentAddress.dateFrom | date: 'dd/MM/yyyy' }} - {{
                addressHistory.currentAddress.dateUntil | date: 'dd/MM/yyyy' }}
              </td>
            </tr>
            <tr>
              <th>Previous Address</th>
              <td>{{ addressHistory.previousAddress.address }}</td>
            </tr>
            <tr>
              <th>Dates</th>
              <td>
                {{ addressHistory.previousAddress.dateFrom | date: 'dd/MM/yyyy' }} - {{
                addressHistory.previousAddress.dateUntil | date: 'dd/MM/yyyy' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br>
      <h4>Name History</h4>
      <div *ngIf="nameHistory">
        <table class="table">
          <tbody>
            <tr>
              <th>Current Name</th>
              <td>{{ nameHistory.currentName.name }}</td>
            </tr>
            <tr>
              <th>Dates</th>
              <td>
                {{ nameHistory.currentName.nameDateFrom | date: 'dd/MM/yyyy' }} - {{
                nameHistory.currentName.nameDateUntil | date: 'dd/MM/yyyy' }}
              </td>
            </tr>
            <tr>
              <th>Birth Name</th>
              <td>{{ nameHistory.birthName.name }}</td>
            </tr>
            <tr>
              <th>Dates</th>
              <td>
                {{ nameHistory.birthName.nameDateFrom | date: 'dd/MM/yyyy' }} - {{
                nameHistory.birthName.nameDateUntil | date: 'dd/MM/yyyy' }}
              </td>
            </tr>
            <tr>
              <th>Previous Name</th>
              <td>{{ nameHistory.previousName.name }}</td>
            </tr>
            <tr>
              <th>Dates</th>
              <td>
                {{ nameHistory.previousName.nameDateFrom | date: 'dd/MM/yyyy' }} - {{
                nameHistory.previousName.nameDateUntil | date: 'dd/MM/yyyy' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br>
    </div>
  </div>
</div>

