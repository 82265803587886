import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {FormService} from "../../services/form.service";
import {fadeIn} from "../../../../data/animations.data";

@Component({
  moduleId: module.id,
  selector: 'xavier-steps',
  templateUrl: 'steps.component.html',
  styleUrls: ['steps.stylesheet.sass', 'steps-mediaqueries.stylesheet.sass'],
  animations: [fadeIn]
})

export class StepsComponent implements OnInit, AfterViewInit {

  @Input() public step: string;
  @Input() public maxSteps = 4;
  @Input() public stepDescriptionLine1 = "";
  @Input() public stepDescriptionLine2 = "";
  public displayStatusMsg: boolean = false;
  public statusMsg: string;
  public stepsArray: number[];
  private statusMsgSub;

  constructor(private formService: FormService) {

  }

  public initDefaultSteps(step: string): void {
    switch (step) {
      case "1": {
        this.stepDescriptionLine1 = "Registration Method";
        break;
      }
      case "2": {
        this.stepDescriptionLine1 = "Company Information";
        break;
      }
      case "3": {
        this.stepDescriptionLine1 = "Legal Signatories";
        this.stepDescriptionLine2 = "We require identity verification of all directors required for signing out terms with Eurocom C.I Limited.";
        break;
      }
      case "4": {
        this.stepDescriptionLine1 = "Account Security";
        break;
      }
      default: {
        this.stepDescriptionLine1 = "Undefined Step";
        break;
      }
    }
  }


  ngOnInit() {
    this.stepsArray = Array.from({length: this.maxSteps}, (_, i) => i + 1);
    
    if (!this.stepDescriptionLine1 && this.stepDescriptionLine2) {
      this.initDefaultSteps(this.step); 
    }
  }

  ngAfterViewInit() {
    this.statusMsgSub = this.formService.sendStatusMsg$.subscribe(
      msg => {
        setTimeout(() => {
          this.statusMsg = msg;
          this.displayStatusMsg = true;
        }, 0);
      });
  }

}
