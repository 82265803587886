import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { catchError, filter } from "rxjs/operators";
import { fadeIn } from "../../../../data/animations.data";
import { evidenceAcceptanceConditons, evidenceCheckDashboardBadges, evidenceCheckDashboardTypes, evidenceRejectionReasons, evidenceTypes, moreInformationRequired } from "../../../../data/variables.data";
import { MultiverseService } from "../../services/multiverse.service";
import { forkJoin, of } from "rxjs";
import { addToaster, formatDueDate, setAsTouched } from "../../../utils/functions.utils";
import { DomSanitizer } from "@angular/platform-browser";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DropdownComponentCompareWithFn, DropdownComponentItemSourceFn } from "../../../shared-cmps/dropdown/dropdown.component";
import { isNullOrUndefined } from "util";
import { ToastyService } from "ng2-toasty";
import { environment } from "../../../../environments/environment";

@Component({
  moduleId: module.id,
  selector: "xavier-edit-evidence-check",
  templateUrl: "./edit-evidence-check.component.html",
  styleUrls: ["./edit-evidence-check.stylesheet.sass"],
  animations: [fadeIn],
})
export class EditEvidenceCheckComponent implements OnDestroy {
  @Input() public loading: boolean = true;
  @Input() public eventLogLoading: boolean = true;

  public editApprenticeData;
  public percCompletedChecks;
  public evidenceCheckType;
  public qualificationData;
  public candidateData;
  public openAiData;
  public resistantAiData;
  public evidenceCheckStatus;
  public isInternational;
  public qualificationProgress;
  public eventLogs;
  public nextPageData;
  public prevPageData;
  public dueDate;
  public documentsData;
  public selectedDocument;
  public disabled: boolean = false;
  public initialFormValues;

  private id: string;
  private routeSub: any;

  constructor(
    private router: Router,
    private location: Location,
    private multiverseService: MultiverseService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private toastyService: ToastyService,
  ) {
    this.routeSub = this.activatedRoute.params.subscribe((params) => {
      this.id = params["id"];
      this.loadPageData();
    });
  }

  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
  }

  setActiveItem(item) {
    const document = { ...item };
    document.documentURL= document.folder && document.name ?
      `https://${environment.storageBucket}/gcs/Multiverse/${document.folder}/${document.name}` : document.documentURL;
    document.documentURL = this.sanitizer.bypassSecurityTrustResourceUrl(document.documentURL);
    this.selectedDocument = document;
  }

  public loadPageData() {
    const evidenceCheck = this.location.path().split("/")[4];
    this.evidenceCheckType = evidenceCheckDashboardTypes.find(
      (item) => item.name === evidenceCheck
    );

    this.editApprenticeData = this.fb.group({
      ecctisEnquiry: [''],
      notLegible: [''],
      nameChangeDiscrepancy: [''],
      dateOfBirthDiscrepancy: [''],
      minimumQualificationMet: [''],
      awardDateVisible: [''],
      missingFourCorners: [''],
      officialStamps: [''],
      officialSig: [''],
      officialLogo: [''],
      eviAccepted: [''],
      reasonQualificationRejected: [''],
      documentType: [''],
      evidenceCanBeAcceptedIf: [''],
      ecctisDatabaseExtract: [''],
      ecctisCorrespondence: [''],
      furtherInformationRequired: [''],
      country: [''],
      dateOfBirth: [''],
      fullName: [''],
      awardOrganisation: [''],
      awardDate: [''],
      qualificationName: [''],
      subjectName: [''],
      subjectGrade: [''],
      requiredTranslation: [''],
      requiredTranscript: [''],
    });

    this.loading = true;
    this.nextPageData = null;
    this.prevPageData = null;

    forkJoin([
      this.multiverseService.getQualificationRecord(this.id) || of(null),
      this.multiverseService.getEvidenceProgress(this.id, this.evidenceCheckType.progressBarApiPath) || of(null),
      this.multiverseService.getNextQualificationRecord(this.id, this.evidenceCheckType.nextPageApiPath) || of(null),
      this.multiverseService.getPrevQualificationRecord(this.id, this.evidenceCheckType.prevPageApiPath) || of(null),
      this.multiverseService.getEventLog(this.id) || of(null),
    ]).pipe(
      catchError(error => {
        console.log(error);
        return of(null);
      })
    ).subscribe(results => {
      if (results) {
        if (results[0]) {
          this.qualificationData = results[0].qualification;
          this.candidateData = results[0].qualification.candidateDetail;
          this.openAiData = results[0].chatGPTResponse;
          this.resistantAiData = results[0].resistantAIResponse;
          this.evidenceCheckStatus = results[0].evidenceCheckStatus;
          this.isInternational = results[0].international;
          this.dueDate = formatDueDate(results[0].sla);

          this.resistantAiData["background"] =
            evidenceCheckDashboardBadges.find((badge) => badge.name === this.resistantAiData.resistantFraudResult);

          this.documentsData = this.mergeDocuments(this.qualificationData.attachments, this.qualificationData.documents)
            .concat(this.mergeDocuments(this.candidateData.attachments, this.candidateData.documents));

          this.setActiveItem(this.documentsData[0]);

          const accpetanceConditions = evidenceAcceptanceConditons.map(
            (item) => ({
              ...item,
              selected: this.openAiData[item.updatedName] || this.openAiData[item.name],
              edited: this.openAiData[item.updatedName] ? true : false,
            })
          );
          const rejectionReasons = evidenceRejectionReasons.map((item) => ({
            ...item,
            selected: this.openAiData[item.updatedName] || this.openAiData[item.name],
            edited: this.openAiData[item.updatedName] ? true : false,
          }));
          const furtherInfo = moreInformationRequired.map(item => ({...item, selected: results[0].furtherInformationRequired === item.name}));
          const documentType = evidenceTypes.map((item) => ({
            ...item,
            selected: this.openAiData.updatedDocumentType === item.name || this.openAiData.documentType === item.name,
          }));

          this.editApprenticeData.get('ecctisEnquiry').setValue(results[0].ecctisEnquiry);
          this.editApprenticeData.get('notLegible').setValue(this.openAiData.updatedNotLegible || this.openAiData.notLegible);
          this.editApprenticeData.get('nameChangeDiscrepancy').setValue(this.openAiData.updatedNameChangeDiscrepancy || this.openAiData.nameChangeDiscrepancy);
          this.editApprenticeData.get('dateOfBirthDiscrepancy').setValue(this.openAiData.updatedDateOfBirthDiscrepancy || this.openAiData.dateOfBirthDiscrepancy);
          this.editApprenticeData.get('minimumQualificationMet').setValue(this. openAiData.updatedMinimumQualificationMet || this.openAiData.minimumQualificationMet);
          this.editApprenticeData.get('awardDateVisible').setValue(this. openAiData.updatedAwardDateVisible || this.openAiData.awardDateVisible);
          this.editApprenticeData.get('requiredTranslation').setValue(this. openAiData.updatedRequiredTranslation || this.openAiData.requiredTranslation);
          this.editApprenticeData.get('requiredTranscript').setValue(this. openAiData.updatedRequiredTranscript || this.openAiData.requiredTranscript);
          this.editApprenticeData.get('missingFourCorners').setValue(this.openAiData.updatedMissingFourCorners || this.openAiData.missingFourCorners);
          this.editApprenticeData.get('officialStamps').setValue(this.openAiData.updatedOfficialStamps || this.openAiData.officialStamps);
          this.editApprenticeData.get('officialSig').setValue(this.openAiData.updatedOfficialSig || this.openAiData.officialSig);
          this.editApprenticeData.get('officialLogo').setValue(this.openAiData.updatedOfficialLogo || this.openAiData.officialLogo);
          this.editApprenticeData.get('eviAccepted').setValue(this.openAiData.updatedEviAccepted || this.openAiData.eviAccepted);
          this.editApprenticeData.get('reasonQualificationRejected').setValue(rejectionReasons.find(item => item.selected));
          this.editApprenticeData.get('documentType').setValue(documentType.find(item => item.selected));
          this.editApprenticeData.get('evidenceCanBeAcceptedIf').setValue(accpetanceConditions.find(item => item.selected));
          this.editApprenticeData.get('ecctisDatabaseExtract').setValue(results[0].ecctisDatabaseExtract);
          this.editApprenticeData.get('ecctisCorrespondence').setValue(results[0].ecctisCorrespondence);
          this.editApprenticeData.get('furtherInformationRequired').setValue(furtherInfo.find(item => item.selected));
          this.editApprenticeData.get('country').setValue(this.openAiData.updatedCountry || this.openAiData.country);
          this.editApprenticeData.get('dateOfBirth').setValue(this.openAiData.updatedDateOfBirth || this.openAiData.dateOfBirth);
          this.editApprenticeData.get('fullName').setValue(this.openAiData.updatedFullName || this.openAiData.fullName);
          this.editApprenticeData.get('awardOrganisation').setValue(this.openAiData.updatedAwardOrganisation || this.openAiData.awardOrganisation);
          this.editApprenticeData.get('qualificationName').setValue(this.openAiData.updatedQualificationName || this.openAiData.qualificationName);
          this.editApprenticeData.get('awardDate').setValue(this.openAiData.updatedAwardDate || this.openAiData.awardDate);
          this.editApprenticeData.get('subjectName').setValue(this.openAiData.updatedSubjectName || this.openAiData.subjectName);
          this.editApprenticeData.get('subjectGrade').setValue(this.openAiData.updatedSubjectGrade || this.openAiData.subjectGrade);
          this.initialFormValues = { ...this.editApprenticeData.value };
        }

        if (results[1]) {
          this.qualificationProgress = results[1];
          this.percCompletedChecks = (results[1].currentIndex / results[1].totalRecords) * 100;
        }
        this.nextPageData = results[2] && results[2];
        this.prevPageData = results[3] && results[3];
        this.eventLogs = results[4] && results[4].items
        this.loading = false;
      }
    });

    if (this.evidenceCheckType.name === 'evidence-check-completed') {
      this.disabled = true;
      this.editApprenticeData.disable();
    }
  }

  public reasonQualificationRejectedItemSource: DropdownComponentItemSourceFn = () => {
    return of(evidenceRejectionReasons);
  };

  public evidenceTypeProvidedItemSource: DropdownComponentItemSourceFn = () => {
    return of(evidenceTypes);
  };

  public evidenceCanBeAcceptedIfItemSource: DropdownComponentItemSourceFn = () => {
    return of(evidenceAcceptanceConditons);
  };

  public moreInformationRequiredItemSource: DropdownComponentItemSourceFn = () => {
    return of(moreInformationRequired);
  };

  public compareFn: DropdownComponentCompareWithFn = (a, b) => a.id === b.id;

  onSubmit(form: FormGroup) {
    const changes = this.getChangedFormvalues(this.initialFormValues, form.value);
    if (form.valid) {
      this.disabled = true;

      let submitButton = document.getElementById('submitButton');
      const spinnerWrap = document.createElement('div');
      spinnerWrap.classList.add('spinner-wrap');
      spinnerWrap.classList.add('button');

      submitButton.innerHTML = '';
      submitButton.appendChild(spinnerWrap);

      let body = Object.keys(changes).length > 0 ? {
        ...changes,
        documentType: changes.documentType && changes.documentType.name,
        evidenceCanBeAcceptedIf: changes.evidenceCanBeAcceptedIf && changes.evidenceCanBeAcceptedIf.value,
        furtherInformationRequired: changes.furtherInformationRequired && changes.furtherInformationRequired.name,
        reasonQualificationRejected: changes.reasonQualificationRejected && changes.reasonQualificationRejected.value,
      } : {};

      this.multiverseService.editQualificationRecord(body, this.id).subscribe(response => {
        if (!isNullOrUndefined(response.id)) {
          this.disabled = false;
          submitButton.innerHTML = "<span>Save and Next</span>";
          this.skipEvidenceCheck();
        }
      }, err => {
        this.disabled = false;
        submitButton.innerHTML = "<span>Save and Next</span>";
        let action = 'serverError';
        if (err.status === 403)
          action = '403';
        addToaster('error', this.toastyService, action, 'errors');
      });
    } else {
      setAsTouched(this.editApprenticeData);
    }
  }

  skipEvidenceCheck() {
    if (this.nextPageData)
      this.router.navigate([`/dashboard/evidence-check/apprentices/${this.evidenceCheckType.name}/${this.nextPageData.id}`]);
    else
      this.router.navigate([`/dashboard/${this.evidenceCheckType.name}`]);
  }

  prevEvidenceCheck() {
    if (this.prevPageData)
      this.router.navigate([`/dashboard/evidence-check/apprentices/${this.evidenceCheckType.name}/${this.prevPageData.id}`]);
  }

  mergeDocuments(attachments = [], documents = []) {
    return attachments
    .map((docs) => {
      const match = documents.find((docs2) => docs.name.includes(docs2.documentType));
      return match ? { ...docs, ...match } : {...docs, documentType: docs.name.split(' ')[0]};
    })
    .concat(
      documents.filter(
        (docs2) => !attachments.some((docs) => docs.name.includes(docs2.documentType))
      )
    );
  }

  getChangedFormvalues(initialValues, currenValues): any {
    let changed = {};
    const compare = (initialValues, currenValues, changed) => {
      for (let key in initialValues) {
        if (initialValues.hasOwnProperty(key)) {
          if (typeof initialValues[key] === 'object' && initialValues[key] !== null && !Array.isArray(initialValues[key])) {
            if (currenValues.hasOwnProperty(key)) {
              let nestedChanges = this.getChangedFormvalues(initialValues[key], currenValues[key]);
              if (Object.keys(nestedChanges).length > 0) {
                changed[key] = nestedChanges;
              }
            }
          } else {
            if (currenValues.hasOwnProperty(key) && initialValues[key] !== currenValues[key]) {
              changed[key] = currenValues[key];
            }
          }
        }
      }

      for (let key in currenValues) {
        if (currenValues.hasOwnProperty(key) && !initialValues.hasOwnProperty(key)) {
          changed[key] = currenValues[key]
        }
      }
    }
    compare(initialValues, currenValues, changed);
    return changed;
  }
}
