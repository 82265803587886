<div class="container panel">
  <div class="row dashboard-container no-hover">
    <div class="col-sm-12 col-xl-3 dashboard-name">
      <div>Purchase Order Number</div>
    </div>
    <div class="col-sm-12 col-xl-6 dashboard-value col inline-hover" inlineEdit
         [inlineElementId]="'purchaseOrderNumber'"
         [inlineObjectId]="details.user.id"
         [inlinePropertyType]="'dashboard-screening-details'">
      <b>{{details.purchaseOrderNumber}}</b>
    </div>
  </div>
  <div class="row dashboard-container no-hover">
    <div class="col-sm-12 col-xl-3 dashboard-name">
      <div>Invoice Number</div>
    </div>
    <div class="col-sm-12 col-xl-6 dashboard-value col inline-hover" inlineEdit
         [inlineElementId]="'invoiceNumber'"
         [inlineObjectId]="details.user.id"
         [inlinePropertyType]="'dashboard-screening-details'">
      <b>{{details.invoiceNumber}}</b>
    </div>
  </div>
  <div class="row dashboard-container no-hover">
    <div class="col-sm-12 col-xl-3 dashboard-name">
      <div>Additional Cost</div>
    </div>
    <div class="col-sm-12 col-xl-6 dashboard-value col inline-hover priceAdjustment" inlineEdit
         [inlineElementId]="'priceAdjustment'"
         [inlineObjectId]="details.user.id"
         [inlinePropertyType]="'dashboard-screening-details'">
      <b>{{details.priceAdjustment}}</b>
    </div>
  </div>
  <div class="row dashboard-container no-hover">
    <div class="col-sm-12 col-xl-3 dashboard-name">
      <div>Closing Comment</div>
    </div>
    <div class="col-sm-12 col-xl-6 dashboard-value col inline-hover" inlineEdit
         [inlineElementId]="'closingComment'"
         [inlineObjectId]="details.user.id"
         [inlinePropertyType]="'dashboard-screening-details'">
      <b>{{details.closingComment}}</b>
    </div>
  </div>
</div>
