<div class="xavier-reset-wrapper container">
  <div *ngIf="loading" class="row" [@fadeIn]>
    <div class="spinner-container not-button"><div class="spinner-wrap"></div></div>
  </div>
  <form [formGroup]="answerData" novalidate>
    <div *ngIf="reset" class="row" [@fadeIn]>
      <div class="col-12">
        <div class="header">
          Hi {{name}}, answer your security question
        </div>
        <div class="header smaller">
          {{secret}}
        </div>
      </div>
      <div class="form-group col-12 xavier-input-text">
        <label for="answer">Answer</label>
        <input type="text" class="form-control" id="answer" formControlName="answer" placeholder="Answer">
        <xavier-error-message [control]="answerData.get('answer')"></xavier-error-message>
      </div>
      <div class="col-sm-12 col-xl-4 continue-col">
        <button type="button" class="btn btn-block xavier-button continue-step" id="continueButton" (click)="onContinue(answerData)" [disabled]="disabledContinue">
          <span>Continue</span> <i class="material-icons">&#xE5C8;</i>
        </button>
      </div>
    </div>
  </form>
  <form [formGroup]="resetPasswordData" novalidate (ngSubmit)="onSubmit(resetPasswordData)">
    <div *ngIf="cont" class="row" formGroupName="manager" [@fadeIn]>
      <div class="col-12">
        <div class="header">
          Create your new password
        </div>
      </div>
      <div class="form-group col-12 xavier-input-text">
        <label for="password">New Password</label>
        <input type="password" class="form-control" id="password" formControlName="password" placeholder="New Password" validateOnBlur [validateFormControl]="resetPasswordData.get('manager')">
        <xavier-error-message [control]="resetPasswordData.get('manager').get('password')"></xavier-error-message>
      </div>
      <div class="form-group col-12 xavier-input-text">
        <label for="passwordConfirm">Confirm Password</label>
        <input type="password" class="form-control" id="passwordConfirm" formControlName="passwordConfirm" placeholder="Confirm Password" validateOnBlur [validateFormControl]="resetPasswordData.get('manager')">
        <xavier-error-message [control]="resetPasswordData.get('manager')"></xavier-error-message>
      </div>
      <div class="col-sm-12 col-xl-5 update-col">
        <button type="submit" class="btn btn-block xavier-button continue-step" id="submitButton" [disabled]="disabledSubmit">
          <span>Update Password</span>
        </button>
      </div>
    </div>
    <div *ngIf="changed" class="row" formGroupName="manager" [@fadeIn]>
      <div class="col-12">
        <div class="header">
          Password updated successfully
          <span class="description">Go to Login page to login to Xavier.</span>
        </div>
      </div>
      <div class="col-sm-12 col-xl-5 login-col">
        <button type="button" class="btn btn-block xavier-button continue-step" (click)="onLogin()">
          <span>Login</span>
        </button>
      </div>
    </div>
  </form>
  <div *ngIf="wrongToken" class="row" [@fadeIn]>
    <div class="col-12">
      <div class="header">
        Your Token has expired or has already been used.
        <span class="description">Go to Login page to login to Xavier.</span>
      </div>
      <div class="col-sm-12 col-xl-5 login-col">
        <button type="button" class="btn btn-block xavier-button continue-step" (click)="onLogin()">
          <span>Login</span>
        </button>
      </div>
    </div>
  </div>
</div>
