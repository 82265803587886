import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
  ViewContainerRef
} from "@angular/core";
import {FormControl, Validators} from "@angular/forms";
import {ErrorMessageComponent} from "../shared-cmps/error-message/error-message.component";
import {FormService} from "../registration/services/form.service";
import {emailValidator, phoneValidator, ukPostcodeValidator, ValidateEmailExists} from "../utils/form.validators";
import {entities, saasClient, retentionLengths, summaryEmailDuration} from '../../data/variables.data';
import {EditClientService} from "../admin/services/edit-client.service";
import {CompanyService} from "../admin/services/company.service";
import {CandidateScreeningService} from "../admin/services/candidate-screening.service";
import {ClientData, Signatories} from "../admin/model/client-data.model";
import {UserPermission} from "../admin/model/permissions.model";
import {UserAuth} from "../../core/userAuth.core";
import {ToastyService} from "ng2-toasty";
import {addToaster} from "../utils/functions.utils";
import {isNullOrUndefined} from "util";
import * as _ from 'lodash';

@Directive({
  selector: '[inlineEdit]'
})

export class InlineEditDirective {
  @Input('inlineElementId') inlineElementId;
  @Input('inlineObjectSignature') inlineObjectSignature;
  @Input('inlineObjectId') inlineObjectId;
  @Input('inlinePropertyType') inlinePropertyType;
  @Input('inlineSignatoryArrayIndex') inlineSignatoryArrayIndex;
  inlineControl: FormControl;

  private componentFactory: ComponentFactory<ErrorMessageComponent>;
  private errorMessageComponentRef: ComponentRef<ErrorMessageComponent> = null;

  constructor(private el: ElementRef, private renderer: Renderer2, private cmpFctryRslvr: ComponentFactoryResolver,
              private viewContainer: ViewContainerRef, private formService: FormService, private toastyService: ToastyService,
              private companyService: CompanyService, private editClientService: EditClientService, private userAuth: UserAuth,
              private candidateScreeningService: CandidateScreeningService) {
    if (!UserPermission.isAllowed(['COMPANY_EDIT_ALL', 'COMPANY_EDIT_OWN', 'CANDIDATE_SCREENING_EDIT'], this.userAuth.getUser().permissionList))
      this.el.nativeElement.classList.remove('inline-hover');
  }

  @HostListener('click', ['$event']) onClick() {
    if (UserPermission.isAllowed(['COMPANY_EDIT_ALL', 'COMPANY_EDIT_OWN', 'CANDIDATE_SCREENING_EDIT'], this.userAuth.getUser().permissionList)) {
      // hide the static data field
      this.el.nativeElement.classList.add('visibility-none');

      /* Elements created and structure:

         parentContainer
         |_ inputContainer
            |_ inputField
               errorMessage (dynamically created with factory)
         |_ controlsContainer
            |_ cancelButton
               saveButton

         Exceptions: ENTITY_TYPE: inputField is a select box
      */

      let inputContainer = this.createInputContainer();

      let inputField = this.createInputField(this.inlineElementId, inputContainer);
      // create the form control object and set validators, based on the type of data
      this.createFormControl();

      let controlsContainer = document.createElement('div');
      controlsContainer.classList.add('col-3');
      controlsContainer.classList.add('xavier-inline-controls-container');
      let cancelButton = document.createElement('button');
      cancelButton.innerHTML = '<i class="material-icons">&#xE5CD;</i>';
      cancelButton.setAttribute('type', 'button');
      cancelButton.classList.add('btn');
      cancelButton.classList.add('xavier-button');
      cancelButton.classList.add('controls');
      cancelButton.classList.add('edit');
      cancelButton.onclick = (function () {
        this.cancelInlineEditingMode(inputContainer, controlsContainer, 'cancelButton');
      }.bind(this));
      controlsContainer.appendChild(cancelButton);

      let saveButton = document.createElement('button');
      saveButton.innerHTML = '<i class="material-icons save-icon">&#xE876;</i>';
      saveButton.setAttribute('type', 'button');
      saveButton.classList.add('btn');
      saveButton.classList.add('xavier-button');
      saveButton.classList.add('controls');
      saveButton.classList.add('save');
      // attach onmousedown to prevent the blur propagation
      saveButton.onmousedown = function (event) {
        event.preventDefault();
      };
      saveButton.onclick = (function () {
        this.saveButtonAction(inputField, inputContainer);
        inputField.blur();
      }.bind(this));

      controlsContainer.appendChild(saveButton);

      this.renderer.appendChild(this.el.nativeElement.parentElement, inputContainer);
      this.renderer.appendChild(this.el.nativeElement.parentElement, controlsContainer);

      // after rendering attach the ENTER keypress event
      inputField.onkeypress = (function (ev) {
        if (ev.keyCode === 13) {
          this.saveButtonAction(inputField, inputContainer);
          inputField.blur();
        }
      }.bind(this));
      // after rendering attach the ESC keypress event, doesn't work on keypress
      inputField.onkeydown = (function (ev) {
        if (ev.keyCode === 27)
          inputField.blur();
      }.bind(this));
      // On blur cancel inline edit mode
      inputField.addEventListener('blur', (function () {
        this.cancelInlineEditingMode(inputContainer, controlsContainer);
      }).bind(this));
      // Set the focus on the inputField
      inputField.focus();
    }
  }

  saveButtonAction(inputField, inputContainer) {
    if (inputField.value !== '' && inputField.value === this.el.nativeElement.innerText.trim())
      inputField.blur();
    else {
      // validation
      this.inlineControl.setValue(inputField.value);
      this.inlineControl.updateValueAndValidity();
      // send new value to the backend if valid
      if (this.inlineControl.valid) {
        this.updateProperty(inputField.value).toPromise().then(response => {
          if (this.inlinePropertyType === 'dashboard-screening-details')
            this.candidateScreeningService.sendEventLogEntry(response);
          else
            this.companyService.sendEventLogEntry(response);
          return;
        }).catch(err => {
          let action = 'serverError';
          if (err.status === 403)
            action = '403';
          addToaster('error', this.toastyService, action, 'errors');
        });
      } else {
        // instantiate and render the errorMessageComponent only once
        if (isNullOrUndefined(this.errorMessageComponentRef)) {
          this.componentFactory = this.cmpFctryRslvr.resolveComponentFactory(ErrorMessageComponent);
          this.errorMessageComponentRef = this.viewContainer.createComponent(this.componentFactory);
          this.errorMessageComponentRef.instance.control = this.inlineControl;
          this.errorMessageComponentRef.instance.noLabel = true;
          this.renderer.appendChild(
            inputContainer,
            this.errorMessageComponentRef.injector.get(ErrorMessageComponent).elRef.nativeElement
          );

          // manually apply the error classes
          inputField.classList.add('ng-touched');
          inputField.classList.add('ng-invalid');
          inputContainer.classList.add('xavier-inline-container-error');
        }
      }
    }
  }

  private updateProperty(value: any) {
    if (this.inlinePropertyType === 'dashboard-screening-details') {
      let body = {
        editedProperty: this.inlineElementId,
        screeningDetails: {}
      };
      body['screeningDetails'][this.inlineElementId] = value;
      this.candidateScreeningService.updateScreeningDetailsBinding({
        property: this.inlineElementId,
        value: value
      });
      return this.candidateScreeningService.editScreeningDetails(this.inlineObjectId, body);
    } else if (this.inlinePropertyType === 'signatory') {
      let signatoryObject = new Signatories(this.inlineObjectSignature, value);
      this.editClientService.updateClientBinding({
        signature: this.inlineObjectSignature,
        value: value,
        signatoryArrayIndex: this.inlineSignatoryArrayIndex,
      });
      return this.editClientService.updateSignatoryData(this.inlineObjectId, this.inlineObjectSignature, signatoryObject);
    } else {
      let clientDataObject = new ClientData(this.inlineObjectSignature, value);
      this.editClientService.updateClientBinding({ signature: this.inlineObjectSignature, value: value });
      return this.editClientService.updateClientData(this.inlineObjectId, this.inlineObjectSignature, clientDataObject);
    }
  }

  cancelInlineEditingMode(inputContainer, controlsContainer, override?) {
    if (this.inlineElementId !== 'email' || override === 'cancelButton') {
      this.el.nativeElement.parentElement.removeChild(inputContainer);
      this.el.nativeElement.parentElement.removeChild(controlsContainer);
      this.errorMessageComponentRef = null;
      this.el.nativeElement.classList.remove('visibility-none');
    }
  }

  createInputContainer() {
    let inputContainer = document.createElement('div');
    inputContainer.classList.add('form-group');
    inputContainer.classList.add('xavier-inline-input-text');
    switch (this.inlineElementId) {
      case 'discountPercentage':
      case 'minimumPrice':
        inputContainer.classList.add('col-2');
        break;
      default:
        inputContainer.classList.add('col-6');
        break;
    }

    return inputContainer;
  }

  createInputField(inlineElementId: string, inputContainer: any) {
    let addon;
    let caret;
    let inputField;
    let inputGroup;
    switch (inlineElementId) {
      case 'discountPercentage':
        inputGroup = document.createElement('div');
        inputGroup.classList.add('input-group');

        inputField = document.createElement('input');
        inputField.classList.add('form-control');
        inputField.setAttribute('value', this.el.nativeElement.innerText.trim());
        // set the cursor at the end of the text
        inputField.setSelectionRange(inputField.value.length, inputField.value.length);

        addon = document.createElement('span');
        addon.classList.add('input-group-addon');
        addon.innerHTML = '%';

        inputGroup.appendChild(inputField);
        inputGroup.appendChild(addon);

        inputContainer.appendChild(inputGroup);
        break;
      case 'entityType':
        caret = document.createElement('i');
        caret.classList.add('material-icons');
        caret.classList.add('dropdown-caret');
        caret.innerText = 'arrow_drop_down';
        inputContainer.appendChild(caret);

        inputField = document.createElement('select');
        inputField.classList.add('form-control');
        inputField.classList.add('inline-dropdown');
        _.forEach(entities, ( function(entity) {
          let option = document.createElement('option');
          option.value = entity.id;
          option.text = entity.label;
          if (this.el.nativeElement.innerText.trim() === entity.label)
            option.selected = true;
          inputField.appendChild(option);
        }).bind(this));

        inputContainer.appendChild(inputField);
        break;
      case 'retentionLength':
        caret = document.createElement('i');
        caret.classList.add('material-icons');
        caret.classList.add('dropdown-caret');
        caret.innerText = 'arrow_drop_down';
        inputContainer.appendChild(caret);

        inputField = document.createElement('select');
        inputField.classList.add('form-control');
        inputField.classList.add('inline-dropdown');
        _.forEach(retentionLengths, ( function(rententionLength) {
          let option = document.createElement('option');
          option.value = rententionLength.id;
          option.text = rententionLength.label;
          if (this.el.nativeElement.innerText.trim() === rententionLength.label)
            option.selected = true;
          inputField.appendChild(option);
        }).bind(this));

        inputContainer.appendChild(inputField);
        break;
      case 'summaryEmailDuration':
        caret = document.createElement('i');
        caret.classList.add('material-icons');
        caret.classList.add('dropdown-caret');
        caret.innerText = 'arrow_drop_down';
        inputContainer.appendChild(caret);

        inputField = document.createElement('select');
        inputField.classList.add('form-control');
        inputField.classList.add('inline-dropdown');
        _.forEach(summaryEmailDuration, ( function(summary) {
          let option = document.createElement('option');
          option.value = summary.id;
          option.text = summary.label;
          if (this.el.nativeElement.innerText.trim() === summary.label)
            option.selected = true;
          inputField.appendChild(option);
        }).bind(this));

        inputContainer.appendChild(inputField);
        break;
      case 'saasClient':
        caret = document.createElement('i');
        caret.classList.add('material-icons');
        caret.classList.add('dropdown-caret');
        caret.innerText = 'arrow_drop_down';
        inputContainer.appendChild(caret);

        inputField = document.createElement('select');
        inputField.classList.add('form-control');
        inputField.classList.add('inline-dropdown');
        _.forEach(saasClient, ( function(summary) {
          let option = document.createElement('option');
          option.value = summary.id;
          option.text = summary.label;
          if (this.el.nativeElement.innerText.trim() === summary.label)
            option.selected = true;
          inputField.appendChild(option);
        }).bind(this));

        inputContainer.appendChild(inputField);
        break;
      case 'minimumPrice':
      case 'priceAdjustment':
        inputGroup = document.createElement('div');
        inputGroup.classList.add('input-group');

        inputField = document.createElement('input');
        inputField.classList.add('form-control');
        inputField.setAttribute('value', this.el.nativeElement.innerText.trim());
        // set the cursor at the end of the text
        inputField.setSelectionRange(inputField.value.length, inputField.value.length);

        addon = document.createElement('span');
        addon.classList.add('input-group-addon');
        addon.innerHTML = '£';

        inputGroup.appendChild(addon);
        inputGroup.appendChild(inputField);

        inputContainer.appendChild(inputGroup);
        break;
      default:
        inputField = document.createElement('input');
        inputField.classList.add('form-control');
        if (inlineElementId === 'postcode' || inlineElementId === 'signatoryPostcode')
          inputField.classList.add('uppercase');
        inputField.setAttribute('value', this.el.nativeElement.innerText.trim());
        // set the cursor at the end of the text
        inputField.setSelectionRange(inputField.value.length, inputField.value.length);

        inputContainer.appendChild(inputField);
        break;
    }
    return inputField;
  }

  createFormControl() {
    this.inlineControl = new FormControl('');
    switch (this.inlineElementId) {
      case 'email':
        this.inlineControl.setValidators([Validators.required, emailValidator]);
        this.inlineControl.setAsyncValidators(ValidateEmailExists.createValidator(this.formService));
        break;
      case 'postcode':
      case 'signatoryPostcode':
        this.inlineControl.setValidators([Validators.required, ukPostcodeValidator]);
        break;
      case 'mobilePhone':
      case 'officePhone':
        this.inlineControl.setValidators([Validators.required, phoneValidator]);
        break;
      default:
        this.inlineControl.setValidators(Validators.required);
        break;
    }
  }

}
