import {
  Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild,
} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {AutocompleteSelectDropdown} from "../../model/autocomplete-select-dropdown.model";
import {CandidateService} from "../../services/candidate.service";
import {CompanyService} from "../../services/company.service";
import {UserService} from "../../services/user.service";
import {isNullOrUndefined} from "util";

@Component({
  moduleId: module.id,
  selector: 'xavier-autocomplete-select-dropdown',
  templateUrl: 'autocomplete-select-dropdown.component.html',
  styleUrls: ['autocomplete-select-dropdown.stylesheet.sass'],
  host: { '(document:click)': 'onClick($event)' }
})

export class AutocompleteSelectDropdownComponent implements OnInit, OnDestroy {

  @Input() public componentParams: AutocompleteSelectDropdown;
  @Input() public elements: Array<string>;

  @Output() dropdownEntrySelectedEmitter = new EventEmitter();
  @ViewChild('dropdown') dropdown;
  @ViewChild('input', { static: true }) input;

  public currentPos: number = -1;
  public dataToDisplay = [];
  public displayValues: boolean = false;
  public displayLoader: boolean = false;
  public userId: any = null;
  public error: string;

  private entriesData = [];
  private formControlSub;
  private routeSub;

  constructor(private _eref: ElementRef, private candidateService: CandidateService, private activatedRoute: ActivatedRoute,
              private userService: UserService, private companyService: CompanyService) {
    this.routeSub = this.activatedRoute.params.subscribe(params => {
      if (!isNullOrUndefined(params['userId'])) {
        this.userId = params['userId'];
      }
    });
  }

  ngOnInit() {
    this.dataToDisplay = this.componentParams.data;
    this.formControlSub = this.componentParams.formControl
      .valueChanges
      .debounceTime(1000)
      .filter((value: string) => {
        if (value === '' && this.dataToDisplay.length === 0)
          this.dataToDisplay = [];
        return value != '' || this.componentParams.id === 'roleName';
      })
      .subscribe(value => {
        this.displayLoader = true;
        let companyId: number = null;
        let query: string = null;
        switch (this.componentParams.id) {
          case 'companyName':
            this.companyService.searchCompany(value).subscribe(response => {
              this.parseData(response, false);
            });
            break;
          case 'divisionName':
            if (typeof value === 'string')
              query = value;
            if (typeof value === 'number')
              companyId = value;
            this.companyService.searchDivision(companyId, query).subscribe(response => {
              this.parseData(response, true);
            });
            break;
          case 'divisionNamePersonnel':
            if (typeof value === 'string')
              query = value;
            if (typeof value === 'number')
              companyId = value;
            this.companyService.searchDivisionWithPersonnel(companyId, query).subscribe(response => {
              this.parseData(response, true);
            });
            break;
          case 'defaultLineManager':
            this.companyService.lineManagersCompany(value).subscribe(response => {
              this.parseData(response, true);
            });
            break;
          case 'defaultHumanResources':
            this.companyService.humanResourcesCompany(value).subscribe(response => {
              this.parseData(response, true);
            });
            break;
          case 'lineManagerName':
            this.companyService.lineManagersDivision(value).subscribe(response => {
              this.parseData(response, true);
            });
            break;
          case 'humanResourcesName':
            this.companyService.humanResourcesDivision(value).subscribe(response => {
              this.parseData(response, true);
            });
            break;
          case 'screeningName':
            let companyAndDivisionId = value.split('/');
            this.candidateService.getDivisionBasedInfo(companyAndDivisionId[0], companyAndDivisionId[1]).subscribe(response => {
              this.parseDivisionData(response, 'screenings', true);
            });
            break;
          case 'screenerName':
            this.userService.getScreeners().subscribe(response => {
              this.parseData(response, true);
            });
            break;
          case 'roleName':
            this.userService.getRoles(this.userId).subscribe(response => {
              this.parseData(response, true);
            });
            break;
          default:
            break;
        }
      });
  }

  ngOnDestroy() {
    this.formControlSub.unsubscribe();
    this.routeSub.unsubscribe();
  }

  private parseData(data: any, doNotDisplayOnParse: boolean) {
    if (!isNullOrUndefined(data.items)) {
      if (!this.displayValues && !doNotDisplayOnParse)
        this.displayValues = true;
      if (data.items) {
        this.entriesData = data.items;
        this.currentPos = -1;
        this.dataToDisplay = [];
        if (this.entriesData.length > 0)
          for (let item of this.entriesData)
            this.dataToDisplay.push(item);
        else {
          this.componentParams.data = [];
        }
      }
      this.displayLoader = false;
    }
  }

  private parseDivisionData(data: any, type: string, doNotDisplayOnParse: boolean) {
    if (this.isEmpty(data)) {
      this.displayLoader = false;
      return;
    }

    if (!isNullOrUndefined(data[type])) {
      this.parseData({ items: data[type] }, doNotDisplayOnParse);
      this.error = null;
    } else {
      this.dataToDisplay = [];
      this.entriesData = [];
      this.displayLoader = false;
      this.error = 'There are no screenings associated with this division, please select another division or add a new screening.';
    }
  }

  private isEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0;
  }

  onWrapClick() {
    if (this.dataToDisplay.length > 0 && !this.displayValues)
      this.displayValues = true;
    else
      this.displayValues = false;
  }

  selectEntry(entry) {
    this.dropdownEntrySelectedEmitter.emit(entry);
    if (entry.hasOwnProperty('companyName'))
      this.componentParams.formControl.setValue(entry.companyName, {emitEvent: false});
    if (entry.hasOwnProperty('divisionName') && this.componentParams.id === 'divisionName')
      this.componentParams.formControl.setValue(entry.divisionName, {emitEvent: false});
    if (entry.hasOwnProperty('divisionName') && this.componentParams.id === 'divisionNamePersonnel') {
      this.componentParams['additionalParams'] = {};
      if (entry.hasOwnProperty('lineManager'))
        this.componentParams['additionalParams']['selectedLineManager'] = entry.lineManager;
      if (entry.hasOwnProperty('humanResources'))
        this.componentParams['additionalParams']['selectedHumanResources'] = entry.humanResources;
      this.componentParams.formControl.setValue(entry.divisionName, { emitEvent: false });
    }
    if (entry.hasOwnProperty('fullName') && this.componentParams.id === 'defaultLineManager')
      this.componentParams.formControl.setValue(entry.fullName, { emitEvent: false });
    if (entry.hasOwnProperty('fullName') && this.componentParams.id === 'defaultHumanResources')
      this.componentParams.formControl.setValue(entry.fullName, { emitEvent: false });
    if (entry.hasOwnProperty('fullName') && this.componentParams.id === 'lineManagerName')
      this.componentParams.formControl.setValue(entry.fullName, { emitEvent: false });
    if (entry.hasOwnProperty('fullName') && this.componentParams.id === 'humanResourcesName')
      this.componentParams.formControl.setValue(entry.fullName, { emitEvent: false });
    if (entry.hasOwnProperty('screeningName'))
      this.componentParams.formControl.setValue(entry.screeningName, { emitEvent: false });
    if (entry.hasOwnProperty('fullName') && this.componentParams.id === 'screenerName')
      this.componentParams.formControl.setValue(entry.fullName, { emitEvent: false });
    if (entry.hasOwnProperty('name') && this.componentParams.id === 'roleName')
      this.componentParams.formControl.setValue(entry.name, { emitEvent: false });
    this.onWrapClick();
  }

  displayEntryLabel(entry: any) {
    let label;
    switch (this.componentParams.id) {
      case 'companyName':
        label = entry.companyName;
        break;
      case 'divisionName':
      case 'divisionNamePersonnel':
        label = entry.divisionName;
        break;
      case 'defaultLineManager':
      case 'defaultHumanResources':
      case 'lineManagerName':
      case 'humanResourcesName':
        label = entry.fullName;
        break;
      case 'screeningName':
        label = entry.screeningName;
        break;
      case 'screenerName':
        label = entry.fullName;
        break;
      case 'roleName':
        label = entry.name;
        break;
      default:
        label = '';
        break;
    }
    return label;
  }

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target) && this.displayValues === true)
      this.displayValues = !this.displayValues;
  }

  onKeyUp(event) {
    if (this.displayValues && event.code == "ArrowDown" && !(this.currentPos >= this.dataToDisplay.length - 1)) {
      this.currentPos++;
      this.dropdown.nativeElement.scrollTo(0, this.currentPos * 44);
    }
    if (this.displayValues && event.code == "ArrowUp" && !(this.currentPos < 1)) {
      this.currentPos--;
    }
    if (this.displayValues && event.code == "Escape")
      this.onWrapClick();
  }

  onChange(event, allow: boolean) {
    if(!allow)
      event.preventDefault();
  }
  onKeyDown(event, allow: boolean) {
    if (!allow && (event.code === 'Backspace' || event.code === 'Delete'))
      return false;
    if (event.code === 'Enter')
      event.preventDefault();
    if (this.displayValues && this.dataToDisplay[this.currentPos] && (event.code === 'Enter' || event.code === 'Tab')) {
      this.dropdownEntrySelectedEmitter.emit(this.dataToDisplay[this.currentPos]);
      if (this.dataToDisplay[this.currentPos].hasOwnProperty('companyName'))
        this.componentParams.formControl.setValue(this.dataToDisplay[this.currentPos].companyName, { emitEvent: false });
      if (this.dataToDisplay[this.currentPos].hasOwnProperty('divisionName'))
        this.componentParams.formControl.setValue(this.dataToDisplay[this.currentPos].divisionName, { emitEvent: false });
      this.onWrapClick();
    }
  }

  allowTypingOnKeyPress(allow: boolean) {
    if (!allow)
      return false;
  }
}
