import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {HttpService} from "../../shared-services/http.service";
import {ReferenceCancelDTO} from '../model/candidate/reference-cancel.dto';

@Injectable()

export class ReferenceFormService {

  constructor(private httpService: HttpService) {

  }

  public getReferenceForm(referenceId) {
    let params = [
      { 'referenceId': referenceId }
    ];
    return this.get('getReferenceForm', null, params);
  }

  public saveReferenceData(body, referenceId) {
    let params = [
      { 'referenceId': referenceId }
    ];
    return this.post('saveReferenceData', body, params, false);
  }

  public editReferenceData(candidateId, referenceId, body) {
    let params = [
      { 'candidateId': candidateId },
      { 'referenceId': referenceId }
    ];
    return this.post('editReferenceData', body, params, false);
  }

  public cancelReference(candidateId: string, referenceId: string, dto: ReferenceCancelDTO) {
    const params = [
      { 'candidateId': candidateId },
      { 'referenceId': referenceId }
    ];
    return this.post('cancelReference', dto, params, false);
  }

  public post(methodName: string, body: any, params: any, reportProgress: boolean): Observable<any> {
    return this.httpService.post('/_ah/api/reference/v1', methodName, body, params, reportProgress);
  }

  public delete(methodName: string, params: any): Observable<any> {
    return this.httpService.delete('/_ah/api/reference/v1', methodName, params);
  }

  public get(methodName: string, body: any, params: any): Observable<any> {
    return this.httpService.get('/_ah/api/reference/v1', methodName, params);
  }
}
