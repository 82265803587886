import {Component, OnInit} from "@angular/core";
import {Attribute, ATTRIBUTE_TYPES_MAP} from "../attribute.class";
import {DynamicFormService} from "../../../../services/dynamic-form.service";
import {CONFIRMATION_TYPE} from "../../../../../../data/variables.data";

@Component({
  moduleId: module.id,
  selector: 'xavier-confirmation',
  templateUrl: 'confirmation.component.html',
  styleUrls: ['confirmation.stylesheet.sass']
})

export class ConfirmationAttribute extends Attribute implements OnInit {

  constructor(public dynamicFormService: DynamicFormService) {
    super(dynamicFormService);
  }

  ngOnInit() {
    let formGroup = this.dynamicFormService.getFormGroup(this.formGroupId);
    let formControl = this.dynamicFormService.attributeToFormControl(this.data);

    formGroup.addControl(this.formGroupId+'-'+this.data.id, formControl);

    this.formGroup = formGroup;
    this.formControl = formControl;
  }

  validityCheck() {
    setTimeout(() => {
      if (this.formControl.value === false)
        this.formControl.setErrors({ invalid: true });
    }, 0);
  }

  initCheckbox() {
    return "";
  }

}

ATTRIBUTE_TYPES_MAP.set(CONFIRMATION_TYPE, ConfirmationAttribute);
