<input type="text"
       class="form-control uppercase"
       id="{{componentParams.id}}"
       placeholder="{{componentParams.placeholder}}"
       autocomplete="off"
       (keyup)="onKeyUp($event)"
       (keydown)="onKeyDown($event)"
       (focus)="onWrapClick()"
       [formControl]="componentParams.formControl">
<i *ngIf="componentParams.icon" class="material-icons selectIcon" (click)="onWrapClick()">{{componentParams.icon}}</i>
<small *ngIf="componentParams.smallHelp" class="form-text text-muted">{{componentParams.smallHelp}}</small>
<xavier-error-message [control]="componentParams.formControl"></xavier-error-message>
<div *ngIf="displayValues" class="dropdown-values-wrapper">
  <div class="display-data" #dropdown>
    <ng-container *ngIf="dataToDisplay?.length != 0">
      <div *ngFor="let entry of dataToDisplay; let i = index"
           (click)="selectEntry(entry)"
           class="entry"
           [class.selected]="i === currentPos"
           (mouseenter)="currentPos = -1">
        {{entry | removeCommas}}
      </div>
    </ng-container>
    <ng-container *ngIf="dataToDisplay?.length == 0 && displayValues">
      <div class="entry-no-results">
        No results
      </div>
    </ng-container>
  </div>
  <div *ngIf="componentParams.createItemLabel" class="create-item-row" (click)="createItem()">
    <i class="material-icons addIcon">&#xE145;</i><span>{{componentParams.createItemLabel}}</span>
  </div>
</div>
<img *ngIf="displayLoader" class="loaderIcon" src="../../../../assets/img/loader.gif" />
