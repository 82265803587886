import {Component, OnInit} from '@angular/core';
import {ATTRIBUTE_TYPES_MAP, DashboardAttribute} from '../dashboard-attribute.class';
import {DynamicFormService} from '../../../../services/dynamic-form.service';
import {IDENTITY_VERIFICATION_TYPE} from '../../../../../../data/variables.data';

@Component({
  moduleId: module.id,
  selector: 'xavier-dashboard-identity-verification',
  templateUrl: 'dashboard-identity-verification.component.html',
  styleUrls: ['dashboard-identity-verification.stylesheet.sass']
})
export class DashboardIdentityVerificationAttribute extends DashboardAttribute implements OnInit {

  constructor(public dynamicFormService: DynamicFormService) {
    super(dynamicFormService);
  }

  ngOnInit() {
    const formGroup = this.dynamicFormService.getFormGroup(this.formGroupId);
    const formControl = this.dynamicFormService.attributeToFormControl(this.data);

    formGroup.addControl(this.formGroupId + '-' + this.data.id, formControl);

    this.formGroup = formGroup;
    this.formControl = formControl;
  }
}

ATTRIBUTE_TYPES_MAP.set(IDENTITY_VERIFICATION_TYPE, DashboardIdentityVerificationAttribute);
