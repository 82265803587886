import {Directive, ElementRef, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {UserAuth} from "../../core/userAuth.core";
import {isNullOrUndefined} from "util";
import * as _ from 'lodash';

@Directive({
  selector: '[isAllowed]'
})

export class IsAllowedDirective {

  constructor(private el: ElementRef, private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef,
              private userAuth: UserAuth) {

  }

  private user = this.userAuth.getUser();

  @Input() set isAllowed(permissions: string[]) {
    if (!isNullOrUndefined(this.user) && _.intersection(permissions, this.user.permissionList).length !== 0) {
      // if the length of the intersection between the required permissions and the user permissionList
      // is NOT zero, meaning that at least ONE item in permissions is in permissionList, then render the template
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      // otherwise remove the template
      this.viewContainer.clear();
    }
  }

}
