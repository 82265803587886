<div (click)="onWrapClick()">
  <input #input
         type="text"
         class="form-control dropdown-shadow"
         [ngClass]="!componentParams.allowTyping ? 'dropdown-disabled' : ''"
         id="{{componentParams.id}}"
         placeholder="{{componentParams.placeholder}}"
         autocomplete="one-time-code"
         [attr.disabled]="!componentParams.allowTyping ? 'disabled' : null"
         (keyup)="onKeyUp($event)"
         (paste)="onChange($event, componentParams.allowTyping)"
         (keydown)="onKeyDown($event, componentParams.allowTyping)"
         (focus)="onWrapClick()"
         [formControl]="componentParams.formControl"
         (keypress)="allowTypingOnKeyPress(componentParams.allowTyping)">
  <i *ngIf="componentParams.icon" class="material-icons selectIcon">{{componentParams.icon}}</i>
  <img *ngIf="displayLoader" class="loaderIcon" src="../../../../assets/img/loader.gif" />
</div>

<div class="error" *ngIf="error">{{error}}</div>

<xavier-error-message [control]="componentParams.formControl"></xavier-error-message>
<div *ngIf="displayValues" class="dropdown-values-wrapper">
  <div class="display-data" #dropdown>
    <ng-container *ngIf="dataToDisplay?.length != 0">
      <div *ngFor="let entry of dataToDisplay; let i = index"
           (click)="selectEntry(entry)"
           class="entry"
           [class.selected]="i === currentPos"
           (mouseenter)="currentPos = -1">
        {{displayEntryLabel(entry)}}
      </div>
    </ng-container>
    <ng-container *ngIf="dataToDisplay?.length == 0 && displayValues">
      <div class="entry-no-results">
        No results
      </div>
    </ng-container>
  </div>
</div>


