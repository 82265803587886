import { GenericTableComponent, GtExpandedRow, GtInformation } from '@angular-generic-table/core';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import * as _ from 'lodash';
import { ToastyService } from 'ng2-toasty';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { isNullOrUndefined } from 'util';
import { UserAuth } from '../../../../core/userAuth.core';
import { fadeIn } from '../../../../data/animations.data';
import {
  candidatesOverviewDashboardColumns,
  candidatesOverviewDashboardFilters,
  candidatesToasterOptions,
  itemsPerPageValues,
  roleDependantCandidatesOverviewColumns
} from '../../../../data/variables.data';
import { addToaster, formatDate, getToasterMessagesLabel } from '../../../utils/functions.utils';
import { PercentBarComponent } from '../../cmps-parts/percent-bar/percent-bar.component';
import { StatusIconComponent } from '../../cmps-parts/status-icon/status-icon.component';
import { UserPermission } from '../../model/permissions.model';
import { CandidateService } from '../../services/candidate.service';
import { TableService } from '../../services/table.service';
import { configObject } from './candidates-overview.data';

@Component({
  moduleId: module.id,
  selector: 'xavier-candidates-overview',
  templateUrl: 'candidates-overview.component.html',
  styleUrls: ['candidates-overview.stylesheet.sass'],
  animations: [fadeIn]
})

export class CandidatesOverviewComponent implements OnInit, OnDestroy, AfterViewChecked {

  @ViewChild('rowLength', { static: true }) public rowLength: any;
  // @ViewChild('search', { static: true }) public search: any;

  public startRouteUrl: string;
  public itemsPerPageValues = itemsPerPageValues;
  public candidatesOverviewDashboardColumns = candidatesOverviewDashboardColumns;
  public candidatesColumnsParams = {
    title: "Show/Hide Columns",
    columns: null,
    columnConfig: null
  };
  public searchControl = new FormControl();
  public data: Array<any> = [];
  public candidatesOverviewDashboardFilters = candidatesOverviewDashboardFilters;
  public roleDependantCandidatesOverviewColumns = roleDependantCandidatesOverviewColumns;
  public hideCheckColumns: boolean;

  @ViewChild('candidatesOverviewTable', { static: true })
  private candidatesOverviewTable: GenericTableComponent<any, GtExpandedRow<any>>;
  private page: number = 1;
  private records: number = 10;
  private query: string = '';
  private sortBy: string = '';
  private sortOrder: string = '';
  private tableOwner: string;
  private columnConfig: any = {};
  private getCandidatesDataReq: any;
  private routeSub: any;
  private searchSub: any;
  private tableOptionsSub: any;
  private tableOwnerSub: any;
  private tableColumnsSub: any;
  private toasterControls = {
    created: false
  };
  private tableInfo = {
    'pageNext': null,
    'pageCurrent': 1,
    'pagePrevious': null,
    'pageTotal': 1,
    'recordFrom': 1,
    'recordLength': 1,
    'recordsAfterFilter': 1,
    'recordsAfterSearch': 1,
    'recordsAll': 1
  };

  constructor(
    private candidatesService: CandidateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private tableService: TableService,
    private toastyService: ToastyService,
    private cdr: ChangeDetectorRef,
    private userAuth: UserAuth
  ) {
    this.startRouteUrl = this.router.url;

    this.routeSub = this.activatedRoute.queryParams.subscribe(params => {
      this.toasterControls['created'] = (!isNullOrUndefined(params['created'])) ? params['created'] : false;
    });

    this.tableOwnerSub = this.tableService.currentOwner.subscribe(owner => {
      this.tableOwner = owner;
    });

    this.tableColumnsSub = this.tableService.currentConfigColumns.subscribe(config => {
      if (config.length !== 0 && this.data.length !== 0) {
        this.columnConfig['columns'] = config;

        // show columns that are selected in the dropdown
        _.forEach(this.columnConfig.columns, (function (column) {
          let columnElements = document.getElementsByClassName(_.kebabCase(column) + '-column');
          _.forEach(columnElements, (function (elem) {
            elem.classList.remove('visibility-none');
          }).bind(this));
        }).bind(this));

        // hide all the rest of the columns that are not selected in the dropdown
        _.forEach(candidatesOverviewDashboardColumns, (function (column) {
          if (isNullOrUndefined(this.columnConfig) || _.indexOf(this.columnConfig.columns, column.name) === -1) {
            let columnElements = document.getElementsByClassName(_.kebabCase(column.name) + '-column');
            _.forEach(columnElements, (function (elem) {
              elem.classList.add('visibility-none');
            }).bind(this));
          }
        }).bind(this));
      }
    });

    this.searchSub = this.searchControl.valueChanges
      .debounceTime(1000)
      .subscribe(newValue => {
        this.query = newValue;
        this.page = 1;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(this.page, this.candidatesOverviewTable.gtInfo.recordLength, this.query);
      });

    if (this.userAuth.getUser().role.name !== 'Admin' && this.userAuth.getUser().role.name !== 'Screener') {
      let i = this.configObject.settings.length;
      while (i--)
        if (this.roleDependantCandidatesOverviewColumns.includes(this.configObject.settings[i].objectKey))
          this.configObject.settings.splice(i, 1);
      i = this.configObject.fields.length;
      while (i--)
        if (this.roleDependantCandidatesOverviewColumns.includes(this.configObject.fields[i].objectKey))
          this.configObject.fields.splice(i, 1);
      i = this.candidatesOverviewDashboardColumns.length;
      while (i--)
        if (this.roleDependantCandidatesOverviewColumns.includes(this.candidatesOverviewDashboardColumns[i].name))
          this.candidatesOverviewDashboardColumns.splice(i, 1);
    }
    this.candidatesColumnsParams.columns = this.candidatesOverviewDashboardColumns;

    this.hideCheckColumns = ["1", "2"].includes(this.userAuth.getUser().role.id as any);
  }

  ngOnInit() {
    this.titleService.setTitle('Dashboard - Xavier Eurocom CI');

    this.routeSub = this.router.events.subscribe((value) => {
      if (value instanceof NavigationStart)
        this.startRouteUrl = value.url;
    });

    this.tableOptionsSub = this.tableService.currentOptions.subscribe(options => {
      if (this.tableOwner === btoa(this.constructor.toString())) {
        this.page = options.page ? options.page : 1;
        this.records = options.records ? options.records : 10;
        this.query = options.query ? options.query : '';
        this.sortBy = options.sortBy ? options.sortBy : '';
        this.sortOrder = options.sortOrder ? options.sortOrder : '';
      } else {
        this.tableService.changeTableOwner(btoa(this.constructor.toString()));
        this.page = 1;
        this.records = 10;
        this.query = '';
        this.sortBy = '';
        this.sortOrder = '';
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
      }
    });

    this.getData(this.page, this.records, this.query, this.sortBy, this.sortOrder);

    setTimeout(() => {
      this.fixTablecolumns();
    }, 0);
  }

  // Temporary solution to add CSS classes to fixed columns of the table
  private fixTablecolumns() {
    const candidateColuns = document.getElementsByClassName('candidate-column');
    candidateColuns[0].classList.add('candidate', 'sort-enable', 'no-top-border');

    const assignedTimeCoumns = document.getElementsByClassName('assigned-time-column');
    assignedTimeCoumns[0].classList.add('assigned-time', 'sort-enable', 'no-top-border');

    const slaRemainingColumns = document.getElementsByClassName('sla-remaining-column');
    slaRemainingColumns[0].classList.add('sla-remaining', 'sort-enable', 'no-top-border');

    const screeningStatusColumns = document.getElementsByClassName('screening-status-column');
    screeningStatusColumns[0].classList.add('screening-status', 'sort-enable', 'no-top-border');
  }

  ngAfterViewChecked() {
    if (!UserPermission.isAllowed(['CANDIDATE_SCREENING_VIEW_ALL', 'CANDIDATE_SCREENING_VIEW_COMPANY', 'CANDIDATE_SCREENING_VIEW_DIVISION'], this.userAuth.getUser().permissionList)) {
      let tables = document.getElementsByTagName('table');
      tables[0].classList.add('tr-no-pointer');
    }

    _.forEach(this.toasterControls, (function (actionValue, action) {
      if (actionValue) {
        let config = candidatesToasterOptions;
        config.msg = getToasterMessagesLabel(action, 'candidateOverview');
        this.toastyService.info(config);
      }
    }).bind(this));

    this.rowLength.nativeElement.value = this.records;
    // this.search.nativeElement.value = this.query;

    this.applyWrappers(); // wrapping the table with wrappers for the horizontal scrolling

    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.columnConfig = null;
    this.roleDependantCandidatesOverviewColumns = null;
    this.routeSub.unsubscribe();
    this.searchSub.unsubscribe();
    this.tableOptionsSub.unsubscribe();
    this.tableOwnerSub.unsubscribe();
    this.tableColumnsSub.unsubscribe();
  }

  getData = function (pageNum: number, pageSize: number, query: string, sortBy?: string, sortOrder?: string) {
    this.data = [];
    let loading: Array<any> = [];
    loading.push({ candidate: 'Loading...' });
    this.configObject.data = loading;

    if (typeof this.getCandidatesDataReq !== 'undefined') {
      this.getCandidatesDataReq.unsubscribe();
    }

    this.info = {
      'pageNext': null,
      'pageCurrent': 1,
      'pagePrevious': null,
      'pageTotal': 1,
      'recordFrom': 1,
      'recordLength': 1,
      'recordsAfterFilter': 1,
      'recordsAfterSearch': 1,
      'recordsAll': 1
    };
    this.configObject.info = <GtInformation>this.info;
    if (isNullOrUndefined(query))
      query = '';
    if (!sortBy)
      sortBy = 'assignedTime';
    if (!sortOrder)
      sortOrder = 'DESC';

    forkJoin([
      this.candidatesService.getCandidateStatuses(pageSize, pageNum, query, sortBy, sortOrder, null),
      this.candidatesService.getViewConfig('CANDIDATE_STATUSES')
    ]).subscribe(results => {
      let statuses = <any>results[0];
      let config = <any>results[1];
      if (!isNullOrUndefined(statuses['candidates'])) {
        this.data = [];
        // set all columns that are checks to visible false, reset
        _.forEach(this.configObject.settings, (function (check, index) {
          if (_.startsWith(check.objectKey, 'checkId')) {
            this.configObject.settings[index]['visible'] = false;
          }
        }).bind(this));
        // set all columns that are checks AND that are returned from the API call to be visible true
        _.forEach(statuses['checks'], (function (check) {
          let index = this.configObject.settings.findIndex(x => x.objectKey == 'checkId' + check.id);
          if (index > 0)
            this.configObject.settings[index]['visible'] = true;
        }).bind(this));
        // redraw the table, necessary
        this.candidatesOverviewTable.redraw();

        _.forEach(statuses['candidates'], (function (candidate) {
          let remainingSLA;
          let screeningSLA;
          let percCompleted;
          if (candidate.remainingSLA && candidate.screeningSLA) {
            percCompleted = (candidate.remainingSLA / candidate.screeningSLA) * 100;
            remainingSLA = candidate.remainingSLA;
            screeningSLA = candidate.screeningSLA;
          } else {
            percCompleted = '-';
          }
          let status = this.getStatusProperties(candidate.screeningStatus);
          let candidateObject = {
            'id': candidate.candidateId,
            'candidate': candidate.candidate,
            'company.companyName': candidate.company,
            'division.divisionName': candidate.division,
            'screening.screeningName': candidate.screening,
            'user.activated': candidate.candidateActivated ? 'Active' : 'Inactive',
            'initiator': candidate.initiator ? candidate.initiator : '-',
            'screener': candidate.screener ? candidate.screener : '-',
            'lineManager': candidate.lineManager ? candidate.lineManager : '-',
            'humanResources': candidate.humanResources ? candidate.humanResources : '-',
            'profit': candidate.profit ? '£ ' + candidate.profit : '-',
            'assignedTime': candidate.assigned ? formatDate(candidate.assigned) : '-',
            'submittedTime': candidate.submitted ? formatDate(candidate.submitted) : '-',
            'completedTime': candidate.completed ? formatDate(candidate.completed) : '-',
            'invoicedDate': candidate.invoiceDate ? formatDate(candidate.invoiceDate) : '-',
            'priceAdjustment': candidate.additionalCost ? '£  ' + candidate.additionalCost : '-',
            'chaserEmails': candidate.chaserEmails ? candidate.chaserEmails : '-',
            'slaRemaining': new PercentBarComponent(),
            'remainingSLA': remainingSLA,
            'screeningSLA': screeningSLA,
            'percCompleted': percCompleted,
            'screeningStatus': new StatusIconComponent(),
            'statusValue': candidate.screeningStatus,
            'statusType': status.statusType,
            'statusColor': status.statusColor,
            'statusBorder': status.statusBorder,
            'statusFill': status.statusFill,
            'statusSize': status.statusSize
          };
          _.forEach(candidate.checkStatuses, (function (status) {
            if (status.hasOwnProperty('checkStatus')) {
              let statusIconValues = this.getStatusProperties(status.checkStatus);
              candidateObject['checkId' + status.checkId] = `<div class="filter-icon-wrapper ` + status.checkStatus + `"><i class="material-icons small">` + statusIconValues.statusType + `</i></div>`;
            }
            else {
              candidateObject['checkId' + status.checkId] = ' ';
            }
          }).bind(this));
          this.data.push(candidateObject);
        }).bind(this));
        this.configObject.data = this.data;
        let pageNext = pageNum < statuses['pages'] ? pageNum + 1 : null;
        let pagePrev = pageNum === 1 ? null : pageNum - 1;
        this.tableInfo = {
          'pageNext': pageNext,
          'pageCurrent': pageNum,
          'pagePrevious': pagePrev,
          'pageTotal': statuses['pages'],
          'recordLength': pageSize,
          'recordsAfterFilter': statuses['count'],
          'recordsAfterSearch': statuses['count'],
          'recordsAll': statuses['count']
        };
        this.configObject.info = <GtInformation>this.tableInfo;
      } else if (!isNullOrUndefined(statuses['count'])) {
        this.configObject.data = [];
        this.tableInfo = {
          'pageNext': null,
          'pageCurrent': pageNum,
          'pagePrevious': null,
          'pageTotal': statuses['pages'],
          'recordFrom': statuses['count'],
          'recordLength': pageSize,
          'recordsAfterFilter': statuses['count'],
          'recordsAfterSearch': statuses['count'],
          'recordsAll': statuses['count']
        };
        this.configObject.info = <GtInformation>this.tableInfo;
      }

      if (!isNullOrUndefined(config) && config.hasOwnProperty('columns')) {
        this.columnConfig['columns'] = Object.assign({}, config.columns);
        this.tableService.changeTableHiddenColumns(config.columns);
      }
    }, err => {
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
    }, () => {
      setTimeout(() => {
        _.forEach(candidatesOverviewDashboardColumns, (function (column) {
          if (isNullOrUndefined(this.columnConfig) || _.indexOf(this.columnConfig.columns, column.name) === -1) {
            let columnElements = document.getElementsByClassName(_.kebabCase(column.name) + '-column');
            _.forEach(columnElements, (function (elem) {
              elem.classList.add('visibility-none');
            }).bind(this));
          }
        }).bind(this));
      }, 0);
    });
  };

  addCandidate() {
    this.router.navigate(['/dashboard/candidates/add'], { queryParams: { startRouteUrl: this.startRouteUrl } });
  }

  trigger = function ($event) {
    switch ($event.name) {
      case 'gt-row-select':
      case 'gt-row-deselect':
        if (UserPermission.isAllowed(['CANDIDATE_SCREENING_VIEW_ALL', 'CANDIDATE_SCREENING_VIEW_COMPANY', 'CANDIDATE_SCREENING_VIEW_DIVISION'], this.userAuth.getUser().permissionList))
          this.router.navigate(['/dashboard/candidates-overview/screening-dashboard/' + $event.value.changedRow.id], { queryParams: { startRouteUrl: this.startRouteUrl } });
        break;
      case 'gt-row-length-changed':
        this.records = $event.value;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(this.candidatesOverviewTable.gtInfo.pageCurrent, this.records, this.query);
        break;
      case 'gt-page-changed':
        this.page = $event.value.pageCurrent;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(this.page, this.candidatesOverviewTable.gtInfo.recordLength, this.query, this.sortBy, this.sortOrder);
        break;
      case 'gt-sorting-applied':
        let sortBy;
        let sortOrder = 'ASC';
        if ($event.value[0].charAt(0) === '-' && $event.value[0].charAt(0) !== '$') {
          sortOrder = 'DESC';
          sortBy = $event.value[0].slice(1, $event.value[0].length);
        } else if ($event.value[0].charAt(0) !== '$') {
          sortBy = $event.value[0];
        }
        this.sortBy = sortBy;
        this.sortOrder = sortOrder;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(
          this.candidatesOverviewTable.gtInfo.pageCurrent,
          this.candidatesOverviewTable.gtInfo.recordLength,
          this.query, this.sortBy, this.sortOrder
        );
        break;
      default:
        break;
    }
  };

  resetSearch() {
    this.query = '';
    this.tableService.changeOptions({
      page: this.page,
      records: this.records,
      query: this.query
    });
    this.searchControl.setValue('');
  }

  private applyWrappers() {
    // XA-1282 Upgrade Angular
    if (document.querySelector(".scrolling.overview")) return;

    let table = document.getElementsByTagName('table')[0];
    let wrapper = document.createElement('div');
    wrapper.classList.add('wrapper');
    let scrolling = document.createElement('div');
    scrolling.classList.add('scrolling');
    scrolling.classList.add('overview');
    table.parentNode.insertBefore(scrolling, table);
    scrolling.appendChild(table);
    scrolling.parentNode.insertBefore(wrapper, scrolling);
    wrapper.appendChild(scrolling);
  }

  getStatusProperties(value: string) {
    let res = {
      'statusType': '',
      'statusColor': '',
      'statusBorder': '#DEE0E0',
      'statusFill': '',
      'statusSize': 'small'
    };
    _.forEach(this.candidatesOverviewDashboardFilters, function (filter) {
      if (filter.name === value) {
        res.statusType = filter.icon;
        res.statusColor = filter.color;
        res.statusBorder = filter.border;
        res.statusFill = filter.fill;
        res.statusSize = filter.size;
      }
    });
    return res;
  }

  filterInfoToStatus(filter: any) {
    return {
      'statusValue': filter.value,
      'statusType': filter.icon,
      'statusColor': filter.color,
      'statusBorder': filter.border,
      'statusFill': filter.fill,
      'statusSize': filter.size,
    };
  }

  public configObject = configObject;

}
