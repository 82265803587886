<div class="xavier-steps-wrapper container">
  <div class="col-12" [@slideIn]>
    <div class="row resend-wrapper">
      <div class="col-12 line1">
        <span class="header">Please Check Your Email</span>
        <span class="description">
          We have sent an email to {{emailAddress}}.<br>
          Please check your email and click the email verification link to verify your email address and login.<br><br>
          After verifying your email address please wait for your client access application to be approved. This can take up to 48 hrs. An email will be sent you as soon as your access has been enabled.
        </span>
      </div>
      <div class="col-12 line2">
        <span class="header">Not received the email?</span>
        <span class="description">
          Please wait a few minutes and check your e-mail's spam folder. If you still haven't received the email, please resend it below.
        </span>
      </div>
      <div class="col-12 form-row">
        <form novalidate (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="form-group col-sm-12 col-xl-3 xavier-input-text">
              <button *ngIf="!showConfirm" type="submit" class="btn btn-block xavier-button continue-step" id="resendButton" [disabled]="disabled" [@fadeIn]>
                <span>Resend Email</span>
              </button>
              <button *ngIf="showConfirm" type="button" class="btn btn-block xavier-button continue-step" [@fadeIn]>
                <span>Email sent successfully</span> <i class="material-icons">&#xE876;</i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
