<ng-container *ngIf="!hidden">
  <div class="form-group xavier-dropdown-wrapper" [formGroup]="formGroup">
    <div>
      <label [for]="formGroupId+'-'+data.id">{{data.attributeName}}</label>
      <span *ngIf="data.required" class="required">&bull;</span>
      <div *ngIf="data.informationVisible === null || !data.informationVisible" class="xavier-popover-info">
        <i *ngIf="data.information !== ''"
           class="material-icons"
           tooltip="{{data.information}}"
           placement="auto"
           containerClass="xavier-popover-container">&#xE8FD;</i>
      </div>
      <ng-container *ngIf="data.informationVisible !== null && data.informationVisible">
        <span class="information-always-visible">{{data.information}}</span>
      </ng-container>
    </div>
    <div class="xavier-dropdown-wrapper">
      <ng-select
        class="form-control xavier-dropdown"
        [id]="formGroupId+'-'+data.id"
        [options]="options"
        placeholder="Please Select..."
        [formControlName]="formGroupId+'-'+data.id"
        [allowClear]="false">
      </ng-select>
      <i class="material-icons ng-select-search">&#xE8B6;</i>
      <xavier-error-message [control]="formControl"></xavier-error-message>
    </div>
    <div class="returned-comment">{{commentMsg}}</div>
  </div>
</ng-container>
