<div class="btn-group filter-group" dropdown [isOpen]="status.isopen" (isOpenChange)="change($event)">
  <div class="active-filter" *ngIf="activeFilter()"></div>
  <button dropdownToggle type="button" class="btn btn-block xavier-button-plain dropdown-toggle xavier-dropdown-toggle">
    Filter <span class="caret xavier-caret"></span>
  </button>
  <div *dropdownMenu class="dropdown-menu dropdown-menu-right xavier-dropdown-filter-menu" role="menu">
    <div class="container">
      <div class="row">
        <div class="col-12 filter-col">
          <h4>{{componentParams.title}}</h4>
        </div>
        <div *ngFor="let filter of componentParams.filters" class="col-6">
          <div class="row filter-row">
            <div class="col-3 xavier-input-checkbox filter-checkbox">
              <input type="checkbox" class="form-check-input" id="{{filter.name}}" checked="" [checked]="initCheckbox(filter.name)">
              <label for="{{filter.name}}" class="form-check-label"></label>
            </div>
            <div class="col-3 filter-icon">
              <xavier-status-icon [row]="filterInfoToStatus(filter)"></xavier-status-icon>
            </div>
            <div class="col-6 filter-value-wrapper">
              <button class="btn xavier-button-plain filter-value" (click)="clickCheckBox(filter.name)">
                {{filter.value}}
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 buttons-col">
          <div class="row filter-row">
            <div class="col-4">
              <button type="button" class="btn btn-block xavier-button continue-step" id="saveFilters" (click)="saveFilters($event)">
                <span>Save Filters</span>
              </button>
            </div>
            <div class="col-3">
              <button dropdownToggle type="button" class="btn btn-block xavier-button-plain">
                Cancel
              </button>
            </div>
            <div class="col-3">
              <button type="button" class="btn btn-block xavier-button-plain white" id="resetFilters" (click)="resetFilters()">
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
