<h2>Account Security</h2>
<div class="container panel">
  <div *ngFor="let sec of security" class="row">
    <div class="col-3 col">
      {{sec.label}}
    </div>
    <div class="col-9 col">
      <b>{{cp.getValueFromProperty(sec.id)}}</b>
    </div>
  </div>
</div>
