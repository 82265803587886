import {Component, Input, OnDestroy} from '@angular/core';
import {EditClientService} from "../../../services/edit-client.service";
import {CandidateScreeningService} from "../../../services/candidate-screening.service";
import {fadeIn} from "../../../../../data/animations.data";

@Component({
  moduleId: module.id,
  selector: 'xavier-dashboard-screening-details',
  templateUrl: 'dashboard-screening-details.component.html',
  styleUrls: ['dashboard-screening-details.stylesheet.sass'],
  animations: [fadeIn],
  providers: [EditClientService]
})

export class DashboardScreeningDetailsComponent implements OnDestroy {

  @Input() public details;

  private screeningDetailsDataObjectSub;

  constructor(private candidateScreeningService: CandidateScreeningService) {
    this.screeningDetailsDataObjectSub = this.candidateScreeningService.screeningDetailsData$.subscribe(
      update => {
        this.details[update.property] = update.value;
      });
  }

  ngOnDestroy() {
    this.screeningDetailsDataObjectSub.unsubscribe();
  }

}
