import {Component, Input} from '@angular/core';
import {ClientPropertiesComponent} from "../client-properties.component";

@Component({
  moduleId: module.id,
  selector: 'xavier-client-properties-security',
  templateUrl: 'security.component.html'
})

export class ClientPropertiesSecurityComponent {

  @Input() public security;

  constructor(public cp: ClientPropertiesComponent) {

  }

}
