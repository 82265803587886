import {StatusIconComponent} from "../../cmps-parts/status-icon/status-icon.component";

export const configObject = {
  settings: [{
    objectKey: 'id',
    visible: false
  }, {
    objectKey: 'candidate',
    sortOrder: 0
  }, {
    objectKey: 'company.companyName',
    sortOrder: 0
  }, {
    objectKey: 'division.divisionName',
    sortOrder: 0
  }, {
    objectKey: 'screening.screeningName',
    sortOrder: 0
  }, {
    objectKey: 'user.activated',
    sortOrder: 0
  }, {
    objectKey: 'initiator',
    sort: 'disable'
  }, {
    objectKey: 'screener',
    sort: 'disable'
  }, {
    objectKey: 'lineManager',
    sort: 'disable'
  }, {
    objectKey: 'humanResources',
    sort: 'disable'
  }, {
    objectKey: 'profit',
    sort: 'disable'
  }, {
    objectKey: 'assignedTime',
    sortOrder: 0
  }, {
    objectKey: 'submittedTime',
    sortOrder: 0
  }, {
    objectKey: 'completedTime',
    sortOrder: 0,
    sort: 'desc'
  }, {
    objectKey: 'invoicedDate',
    sortOrder: 0
  }, {
    objectKey: 'priceAdjustment',
    sortOrder: 0
  }, {
    objectKey: 'chaserEmails',
    sortOrder: 0
  }, {
    objectKey: 'screeningStatus',
    sortOrder: 0
  }, {
    objectKey: 'statusValue',
    visible: false
  }, {
    objectKey: 'statusType',
    visible: false
  }, {
    objectKey: 'statusColor',
    visible: false
  }, {
    objectKey: 'statusBorder',
    visible: false
  }, {
    objectKey: 'statusFill',
    visible: false
  }, {
    objectKey: 'statusSize',
    visible: false
  }],
  fields: [{
    name: 'id',
    objectKey: 'id'
  }, {
    name: 'Name',
    objectKey: 'candidate',
    columnClass: (row) => {
      if (row == undefined || row == 'th')
        return 'no-top-border candidate';
    }
  }, {
    name: 'Company',
    objectKey: 'company.companyName',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border';
    }
  }, {
    name: 'Division',
    objectKey: 'division.divisionName',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Screening Name',
    objectKey: 'screening.screeningName',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Candidate Status',
    objectKey: 'user.activated',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Initiator',
    objectKey: 'initiator',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Screener',
    objectKey: 'screener',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Line Manager',
    objectKey: 'lineManager',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Human Resources',
    objectKey: 'humanResources',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Profit (Ex. VAT)',
    objectKey: 'profit',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Assigned Date',
    objectKey: 'assignedTime',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Submitted Date',
    objectKey: 'submittedTime',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Completed Date',
    objectKey: 'completedTime',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Invoice Date',
    objectKey: 'invoicedDate',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Additional Costs',
    objectKey: 'priceAdjustment',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Chaser Emails',
    objectKey: 'chaserEmails',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Overall Status',
    objectKey: 'screeningStatus',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border screening-status';
    },
    columnComponent: {
      type: StatusIconComponent
    }
  }],
  data: [],
  info: {}
};
