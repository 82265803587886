import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {FormService} from "../../services/form.service";
import {UserService} from "../../services/user.service";
import {securityQuestions} from "../../../../data/variables.data";
import {fieldsMatcher} from "../../../utils/form.validators";
import {Title} from "@angular/platform-browser";
import {fadeIn} from "../../../../data/animations.data";
import {setAsTouched} from "../../../utils/functions.utils";
import {isNullOrUndefined} from "util";
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'xavier-reset',
  templateUrl: 'reset.component.html',
  styleUrls: ['reset.stylesheet.sass'],
  animations: [fadeIn]
})

export class ResetPasswordComponent implements OnInit, OnDestroy {

  @Input() loading: boolean = true;
  @Input() reset: boolean = false;
  @Input() cont: boolean = false;
  @Input() changed: boolean = false;
  @Input() wrongToken: boolean = false;
  @Input() name: string;
  @Input() secret: string;

  public answerData: FormGroup;
  public resetPasswordData: FormGroup;
  public disabledContinue: boolean = false;
  public disabledSubmit: boolean = false;

  private token: string;
  private questionSubscription: any;
  private answerSubscription: any;
  private resetPasswordSubscription: any;

  constructor(private fb: FormBuilder, private router: Router, private activeRoute: ActivatedRoute, private titleService: Title,
              private formService: FormService, private userService: UserService) {

  }

  ngOnInit() {
    this.titleService.setTitle('Change Password - Xavier Eurocom CI');

    this.answerData = this.fb.group({
      answer: ['', [Validators.required]]
    });

    this.resetPasswordData = this.fb.group({
      manager: this.fb.group({
        password: ['', [Validators.required]],
        passwordConfirm: ['', [Validators.required]]
      }, {validator: fieldsMatcher('password', 'passwordConfirm')}),
    });

    this.token = this.activeRoute.snapshot.queryParams['token'];

    if (isNullOrUndefined(this.token)) {
      this.wrongToken = true;
      this.loading = false;
    } else {
      this.questionSubscription = this.userService.getSecurityQuestion(this.token).subscribe(response => {
        if (!isNullOrUndefined(response.question)) {
          _.forEach(securityQuestions, (function (question) {
            if (question.id == response.question) {
              this.secret = question.label;
              this.name = response.name;
              return false;
            }
          }).bind(this));
          this.loading = false;
          this.reset = true;
        } else {
          this.loading = false;
          this.cont = true;
        }
      },
      error => {
        this.wrongToken = true;
        this.loading = false;
      });
    }
  }

  ngOnDestroy() {
    if (!isNullOrUndefined(this.questionSubscription))
      this.questionSubscription.unsubscribe();
    if (!isNullOrUndefined(this.answerSubscription))
      this.answerSubscription.unsubscribe();
    if (!isNullOrUndefined(this.resetPasswordSubscription))
      this.resetPasswordSubscription.unsubscribe();
  }

  onContinue({ value, valid } : { value: any, valid: boolean }) {
    if (!valid) {
      setAsTouched(this.answerData);
    } else {
      this.disabledContinue = true;
      let continueButton = document.getElementById('continueButton');
      const spinnerWrap = document.createElement('div');
      spinnerWrap.classList.add('spinner-wrap');
      spinnerWrap.classList.add('button');

      continueButton.innerHTML = '';
      continueButton.appendChild(spinnerWrap);

      this.answerSubscription = this.userService.checkSecurityAnswer(this.token, value.answer).subscribe(response => {
        this.disabledContinue = false;
        continueButton.innerHTML = '<span>Continue</span> <i class="material-icons">&#xE5C8;</i>';
        if (response.value) {
          this.reset = false;
          this.cont = true;
        } else {
          this.answerData.get('answer').setErrors({ secretAnswer: true });
        }
      });
    }
  }

  onSubmit({ value, valid } : { value: any, valid: boolean }) {
    if (!valid) {
      setAsTouched(this.answerData);
    } else {
      this.disabledSubmit = true;
      let submitButton = document.getElementById('submitButton');
      const spinnerWrap = document.createElement('div');
      spinnerWrap.classList.add('spinner-wrap');
      spinnerWrap.classList.add('button');

      submitButton.innerHTML = '';
      submitButton.appendChild(spinnerWrap);

      this.resetPasswordSubscription = this.userService.resetPassword(this.token, this.answerData.get('answer').value, value.manager.password).subscribe(response => {
        this.disabledSubmit = false;
        submitButton.innerHTML = '<span>Update Password</span>';
        this.cont = false;
        this.changed = true;
      }, error => {
        this.disabledSubmit = false;
        submitButton.innerHTML = '<span>Update Password</span>';
        this.answerData.get('answer').setErrors({ secretAnswer: true });
      });
    }
  }

  onLogin() {
    this.router.navigate(['/login']);
  }

}
