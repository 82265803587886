import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, Renderer2, TemplateRef, ViewChild} from '@angular/core';
import {DatePipe} from '@angular/common';
import {Subject} from 'rxjs';
import {BsModalService} from 'ngx-bootstrap/modal';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {DynamicFormDirective} from '../../../directives/dynamic-form.directive';
import {DynamicFormService} from '../../services/dynamic-form.service';
import {CandidateService} from '../../services/candidate.service';
import {CandidateScreeningService} from '../../services/candidate-screening.service';
import {FileuploadAttribute} from './model/fileupload/fileupload.component';
import {SignatureAttribute} from './model/signature/signature.component';
import {TelAttribute} from './model/tel/tel.component';
import {UserAuth} from '../../../../core/userAuth.core';
import {ToastyService} from 'ng2-toasty';
import {addToaster, getEventLogActivityTypeLabel, getToasterMessagesLabel, isVerified, parseDate} from '../../../utils/functions.utils';
import {candidateScreeningToasterOptions, noticeToasterOptions} from '../../../../data/variables.data';
import {fadeIn, slideLeftFromHidden, slideRightFromHidden} from '../../../../data/animations.data';
import {isNullOrUndefined} from 'util';
import * as _ from 'lodash';
import {first, takeUntil} from 'rxjs/operators';
import {Confirmation, DialogService} from '../../../../app/dialog/dialog.service';
import {FormGroup} from '@angular/forms';

const SAVE_AS_DRAFT_CONFIRMATION: Confirmation = {
  title: `Check Not Ready`,
  message: `You have not yet completed all the required information correctly for this check. You can save as draft, or return to correct it.`,
  acceptLabel: `Return`,
  rejectLabel: `Save as Draft`,
};

@Component({
  moduleId: module.id,
  selector: 'xavier-dynamic-form',
  templateUrl: 'dynamic-form.component.html',
  styleUrls: [
    'dynamic-form.stylesheet.sass',
    'dynamic-form-mediaqueries.stylesheet.sass'
  ],
  animations: [fadeIn, slideLeftFromHidden, slideRightFromHidden]
})

export class DynamicFormComponent implements AfterViewInit{

  @ViewChild(DynamicFormDirective) dnForm: DynamicFormDirective;
  @ViewChild('navigateAwayModal') navigateAwayModal: TemplateRef<any>;

  @Input() public formModel: any;
  @Input() public formData: any;
  @Input() public formComments: any;
  @Input() public preview: boolean;
  @Input() public candidateId: string;
  @Input() public screeningId: string;
  @Input() public canSubmitScreening: boolean = false;
  @Input() public globalValidationMsg: boolean = false;
  @Input() public selectedItem;
  @Input() public sidePanel = true;
  @Input() public checkStatus: any = {
    statusBorder: "",
    statusFill: "",
    statusColor: "",
    statusSize: "",
    statusType: ""
  };
  @Input() public eventLogLoading: boolean = true;

  @Output() public submitted = new EventEmitter<boolean>();

  public saveContinueLabel = 'Save and Continue';
  public submitCheckDisabled;
  public footerCopyrightDate = new Date();
  public checkStatusValue;
  public commentMsg;
  public eventLog;
  public modalRef: BsModalRef;

  private globalValidationMsgSub;
  private panelSub;
  private dfContent;
  private dfMenu;
  private formGroupsFromData = [];
  private eventLogSub;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private dynamicFormService: DynamicFormService,
    private cdr: ChangeDetectorRef,
    private candidateService: CandidateService,
    private candidateScreeningService: CandidateScreeningService,
    private toastyService: ToastyService,
    private userAuth: UserAuth,
    private renderer: Renderer2,
    private datepipe: DatePipe,
    private modalService: BsModalService,
    private dialogService: DialogService,
  ) {
    this.subscribeToIdVerificationCompleted();
    this.dynamicFormService.identityVerificationRestarted.pipe(
      takeUntil(this.ngUnsubscribe),
    ).subscribe(() => this.subscribeToIdVerificationCompleted());
    this.dynamicFormService.identityVerificationBackToMenu.pipe(
      takeUntil(this.ngUnsubscribe),
    ).subscribe(() => this.backToMenu());

    this.globalValidationMsgSub = this.dynamicFormService.globalValidationMsg$.subscribe(value => {
      this.globalValidationMsg = value;
    });
    this.panelSub = this.dynamicFormService.selectedPanel$.subscribe(panel => {
      this.selectedItem = panel;
    });

    this.eventLogSub = this.candidateScreeningService.eventLogEntry$.takeUntil(this.ngUnsubscribe).subscribe(entry => {
      let eventPanel = document.getElementById('eventLogPanel');
      let newEntry = document.createElement('div');
      newEntry.classList.add('row');
      let executor = document.createElement('div');
      executor.classList.add('col-8');
      executor.classList.add('executor');
      executor.innerText = entry.executorName + ', ' + entry.executorRole;
      let timestamp = document.createElement('div');
      timestamp.classList.add('col-4');
      timestamp.classList.add('timestamp');
      timestamp.classList.add('text-right');
      timestamp.innerText = this.datepipe.transform(new Date(), 'dd MMM yyyy, HH:mm');
      let activity = document.createElement('div');
      activity.classList.add('col-12');
      activity.classList.add('activity');
      activity.innerText = getEventLogActivityTypeLabel(entry.activityType);
      if (entry.details !== '')
        activity.innerText += ' - ' + entry.details;

      newEntry.appendChild(executor);
      newEntry.appendChild(timestamp);
      newEntry.appendChild(activity);

      this.renderer.insertBefore(eventPanel, newEntry, eventPanel.firstChild);
    });
  }

  private subscribeToIdVerificationCompleted(): void {
    this.dynamicFormService.identityVerificationCompleted.pipe(
      first()
    ).subscribe(yotiProcessResponse => {
      this.onSaveScreeningCheckDataSuccess(yotiProcessResponse.candidateData);
      this.onSaveScreeningCheckDataComplete([], false, true);
    });
  }

  ngOnInit() {
    this.dfMenu = document.getElementById('dfMenu');
    this.dfContent = document.getElementById('dfContent');
    // select the first check if in preview mode
    // or select the first non-submitted check if in candidate mode and desktop view
    let width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;
    if (this.preview)
      this.selectedItem = !isNullOrUndefined(this.formModel.checks) ? this.formModel.checks[0].id : '';
    else if (width > 1200) {
      let active = this.searchNextCheckToActivate(0);
      if (active !== 0 && !this.preview)
        this.selectedItem = active;
      else
        this.selectedItem = !isNullOrUndefined(this.formModel.checks) ? this.formModel.checks[0].id : '';
    }

    this.updateFormGroupsFromData();
  }

  ngAfterViewInit() {
    let canSubmitScreening = true;
    this.formModel.checks.forEach(check => {
      if (check.checkStatus === 'WITH_CANDIDATE') {
        check.isPristine = true;
      } else {
        check.isPristine = false;
      }
    });

    const identityVerificationCheck = this.getIdentityVerification();
    _.forEach(this.formModel.checks, ( function(check) {
      if (check.checkStatus === 'FAILED'
        || check.checkStatus === 'WITH_CANDIDATE'
        || check.checkStatus === 'RETURNED'
        || check.checkStatus === 'DRAFT'
        || (identityVerificationCheck && check.id === identityVerificationCheck.id && identityVerificationCheck.checkStatus === 'IN_PROGRESS')) {
        canSubmitScreening = false;
        return false;
      }
    }).bind(this));
    if (this.formModel.screeningStatus === 'CANDIDATE_SAVED' && canSubmitScreening)
      canSubmitScreening = false;
    this.canSubmitScreening = canSubmitScreening;

    if (!this.preview) {
      setTimeout(() => {
        this.createStructure();
      }, 0);
      setTimeout(() => {
        this.patchData();
      }, 500);
    }

    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.globalValidationMsgSub.unsubscribe();
    this.panelSub.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  createStructure() {
    this.globalValidationMsg = false;
    let selectedCheckStatus = this.formModel.checks[this.searchCheckSelectedIndex()].checkStatus;

    // the first pass will create in order the top level groups, level 1, so the children have a parent to be attached to
    // it relies on the the ordering of the data from the backend ! IMPORTANT !
    _.forEach(this.formGroupsFromData, ( function(groupId) {
      setTimeout(() => {
        let checkId;
        if (groupId.indexOf('#') !== -1) {
          let temp = groupId.split('#');
          checkId = temp[1].split('-')[0];
        } else {
          checkId = groupId.split('-')[0];
        }
        let groupComponentInstance = this.dynamicFormService.getGroupComponentInstance(groupId);
        if (groupComponentInstance === undefined && _.includes(this.formGroupsFromData, groupId) &&
          groupId.indexOf('#') !== -1 && this.selectedItem === checkId) {
          let parentNode = _.find(this.formData, function (o) {
            if (o.hasOwnProperty('parentNodeId') && o.nodeId.substring(0, o.nodeId.lastIndexOf('-')) === groupId)
              return o;
          });
          // if parentNode is null this is a top level group, level 1
          if (isNullOrUndefined(parentNode)) {
            let firstElement = <HTMLElement>(document.querySelector('xavier-df-group > div'));
            if (firstElement.id.indexOf('#') !== -1) {
              let tempInstance = this.dynamicFormService.getGroupComponentInstance(firstElement.id);
              if (!isNullOrUndefined(tempInstance))
                groupComponentInstance = tempInstance.addDfGroup(null, groupId.substring(0, groupId.indexOf('#')+1));
            } else {
              let temp = groupId.split('#');
              let parentElementIdPrefix = '1#' + temp[1];
              let tempInstance = this.dynamicFormService.getGroupComponentInstance(parentElementIdPrefix);
              if (!isNullOrUndefined(tempInstance))
                groupComponentInstance = tempInstance.addDfGroup(null, groupId.substring(0, groupId.indexOf('#')+1));
            }
          } else {
            // else this is a child, it might be attached to the wrong father for the time being
            // it's checked and amended in the second pass
            let parentElem = <HTMLElement>document.getElementById(parentNode.parentNodeId);
            if (!isNullOrUndefined(parentElem)) {
            let siblingElem = <HTMLElement>(<HTMLElement>parentElem.querySelector('xavier-df-group > div'));
              let tempInstance = this.dynamicFormService.getGroupComponentInstance(siblingElem.id);
              if (!isNullOrUndefined(tempInstance))
                groupComponentInstance = tempInstance.addDfGroup(parentNode.parentNodeId, groupId.substring(0, groupId.indexOf('#') + 1));
            }
          }
        }
        if (groupComponentInstance !== undefined && _.includes(this.formGroupsFromData, groupId) &&
          groupComponentInstance.isVisible === false && this.selectedItem === checkId) {
          groupComponentInstance.visibilityCheck(true);
        }
        // if (!this.preview && !isNullOrUndefined(groupComponentInstance)) {
        //   groupComponentInstance.deleteGroupIsVisible = false;
        //   groupComponentInstance.addGroupIsVisible = false;
        // }
      }, 0);
    }).bind(this));

    // this second pass is needed to amend children appended in the wrong place
    // and to fill the parentNodeId field in the group instance
    _.forEach(this.formGroupsFromData, ( function(groupId) {
      setTimeout(() => {
        let checkId;
        if (groupId.indexOf('#') !== -1) {
          let temp = groupId.split('#');
          checkId = temp[1].split('-')[0];
        } else {
          checkId = groupId.split('-')[0];
        }
        let groupComponentInstance = this.dynamicFormService.getGroupComponentInstance(groupId);
        if (groupComponentInstance !== undefined && _.includes(this.formGroupsFromData, groupId) &&
          groupComponentInstance.isVisible === true && this.selectedItem === checkId) {
          let parentNode = _.find(this.formData, function (o) {
            if (o.hasOwnProperty('parentNodeId') && o.nodeId.substring(0, o.nodeId.lastIndexOf('-')) === groupId)
              return o;
          });
          if (!isNullOrUndefined(parentNode) && groupComponentInstance.parentNodeId !== parentNode.parentNodeId) {
            groupComponentInstance.parentNodeId = parentNode.parentNodeId;
            let containerElem = <HTMLElement>(document.getElementById(groupId)).parentElement.parentElement;
            if (parentNode.parentNodeId !== '' && containerElem.id !== parentNode.parentNodeId)
              document.getElementById(parentNode.parentNodeId).appendChild(document.getElementById(groupId).parentElement);
          }
        }
      }, 0);
    }).bind(this));

    // add / delete repeated groups management
    _.forEach(this.formGroupsFromData, ( function(groupId) {
      setTimeout(() => {
        let checkId;
        if (groupId.indexOf('#') !== -1) {
          let temp = groupId.split('#');
          checkId = temp[1].split('-')[0];
        } else {
          checkId = groupId.split('-')[0];
        }
        let groupComponentInstance = this.dynamicFormService.getGroupComponentInstance(groupId);
        if (groupComponentInstance !== undefined && _.includes(this.formGroupsFromData, groupId) &&
          groupComponentInstance.isVisible === true && this.selectedItem === checkId) {
          groupComponentInstance.deleteGroupIsVisible = this.dynamicFormService.deleteGroupDisabled(groupId, groupComponentInstance.group.level);
        }
      }, 0);
    }).bind(this));

    _.forEach(this.formModel.checks, ( function(check) {
      let commentMsg = '';
      let comments = _.find(this.formComments, ['nodeId', check.id]);
      if (!isNullOrUndefined(comments)) {
        _.forEach(comments.comments, (function (comment) {
          if (comment.type === 'RETURN')
            commentMsg += comment.comment + ', ';
        }).bind(this));
      }
      if (commentMsg !== '' && this.selectedItem === check.id && check.checkStatus !== 'CANDIDATE_SAVED') {
        let commentContainer = document.getElementById('checkCommentMsg');
        commentContainer.innerText = commentMsg.slice(0, -2);
      }
    }).bind(this));

    // disable submit check button for checks that are flagged/adverse/passed and in general not with candidate
    let status = this.formModel.checks[this.searchCheckSelectedIndex()].checkStatus;
    if (status === 'RETURNED' || status === 'WITH_CANDIDATE' || status === 'CANDIDATE_SAVED' || status === 'DRAFT')
      this.submitCheckDisabled = false;
    else
      this.submitCheckDisabled = true;
  }

  patchData() {
    const selectedCheckStatus = this.formModel.checks[this.searchCheckSelectedIndex()].checkStatus;
    const allGroups = this.dynamicFormService.getAllFormGroups();

    // patch data - all logic first iteration
    _.forEach(this.formGroupsFromData, ( function(groupId) {
      const groupComponentInstance = this.dynamicFormService.getGroupComponentInstance(groupId);
      if (groupComponentInstance !== undefined && _.includes(Array.from(allGroups.keys()), groupId)) {
        _.forEach(this.formData, ( function(node) {
          if (_.startsWith(node.nodeId, groupId)) {
            const attribute = this.dynamicFormService.getAttributeComponentInstance(node.nodeId);
            this.setupDisabledState(selectedCheckStatus, node, attribute);
            if (node.hasOwnProperty('file')) {
              this.patchFileNode(node, attribute);
            } else if (parseDate(node.value) && !isNullOrUndefined(groupComponentInstance.formGroup.get(node.nodeId))) { // date value
              setTimeout(() => {
                const date = new Date(node.value);
                const dateComponents = {
                  year: date.getFullYear(),
                  month: date.getMonth() + 1,
                  day: date.getDate()
                };
                groupComponentInstance.formGroup.get(node.nodeId).setValue({
                  date: dateComponents,
                  jsdate: date
                }, {emitEvent: true});
                attribute.dfdatepicker.emitDateChanged(dateComponents);
              }, 0);
            } else if (attribute instanceof TelAttribute) {
              setTimeout(() => {
                const phoneNumber = node.value.split(' ');
                const prefixSelect = <HTMLSelectElement>document.getElementById(attribute.formGroupId + '-' + attribute.data.id + '-tel-select');
                prefixSelect.value = phoneNumber[0];
                groupComponentInstance.formGroup.get(node.nodeId).setValue(phoneNumber.slice(1).join(' '), {emitEvent: true});
              }, 0);
            } else {
              if (!isNullOrUndefined(groupComponentInstance.formGroup.get(node.nodeId)))
                groupComponentInstance.formGroup.get(node.nodeId).setValue(node.value, {emitEvent: true});
            }
          }
        }).bind(this));

        if (selectedCheckStatus !== 'CANDIDATE_SAVED') {
          let groupCommentMsg = '';
          const comments = _.find(this.formComments, ['nodeId', groupId]);
          if (!isNullOrUndefined(comments)) {
            if (_.startsWith(groupId, groupComponentInstance.formGroupId) && groupId.indexOf('#') !== -1)
              groupComponentInstance.deleteGroupIsVisible = true;

            _.forEach(comments.comments, (function (comment) {
              if (comment.type === 'RETURN')
                groupCommentMsg += comment.comment + ', ';
            }).bind(this));
          }
          groupComponentInstance.commentMsg = groupCommentMsg.slice(0, -2);
        }
      }
    }).bind(this));

    // patch data - files and disabled state - as some fields might have been changed because of dates conditions
    window.setTimeout(() => {
      _.forEach(this.formGroupsFromData, ( function(groupId) {
        const groupComponentInstance = this.dynamicFormService.getGroupComponentInstance(groupId);
        if (groupComponentInstance !== undefined && _.includes(Array.from(allGroups.keys()), groupId)) {
          _.forEach(this.formData, ( function(node) {
            if (_.startsWith(node.nodeId, groupId)) {
              const attribute = this.dynamicFormService.getAttributeComponentInstance(node.nodeId);
              this.setupDisabledState(selectedCheckStatus, node, attribute);
              if (node.hasOwnProperty('file')) {
                this.patchFileNode(node, attribute);
              }
              this.initYotiSettings(attribute, node);
            }
          }).bind(this));
        }
      }).bind(this));
    }, 1200);

    // window.setTimeout(() => {
    //   _.forEach(this.formGroupsFromData, ( function(groupId) {
    //     const groupComponentInstance = this.dynamicFormService.getGroupComponentInstance(groupId);
    //     if (groupComponentInstance !== undefined && _.includes(Array.from(allGroups.keys()), groupId)) {
    //       _.forEach(this.formData, ( function(node) {
    //         if (_.startsWith(node.nodeId, groupId)) {
    //           const attribute = this.dynamicFormService.getAttributeComponentInstance(node.nodeId);
    //           this.initYotiSettings(attribute, node);
    //         }
    //       }).bind(this));
    //     }
    //   }).bind(this));
    // }, 0);
  }

  private initYotiSettings(attribute, node) {
    if (!isNullOrUndefined(attribute) && node.prefilled) {
      attribute.prefilledByYoti = true;
      attribute.verifiedValue = node.verifiedValue;

      // if personal data changes ask for passport or driving license upload
      this.showOrHideAttributesBasedOnYotiResult(this.dynamicFormService.dynamicForm.personalDetailsAttributes,
        this.dynamicFormService.dynamicForm.personalDetailsNodes,
        this.dynamicFormService.dynamicForm.personalDetailsVerificationNodes,
        node,
        attribute);

      // if current address changes ask for proofs of address
      this.showOrHideAttributesBasedOnYotiResult(this.dynamicFormService.dynamicForm.currentAddressAttributes,
        this.dynamicFormService.dynamicForm.currentAddressNodes,
        this.dynamicFormService.dynamicForm.currentAddressVerificationNodes,
        node,
        attribute);
    }
  }

  private showOrHideAttributesBasedOnYotiResult(attrs, nodes, verificationNodes, node, attribute) {
    // check if current node is handled by Yoti
    if (attrs.includes(node.yotiMapping)) {
      // fetch verification nodes to enable/disable them
      const verificationAttributes = verificationNodes
        .map(n => this.dynamicFormService.getAttributeComponentInstance(n));
      // fetch yoti nodes to check if they were changed
      const attributes = nodes
        .map(n => this.dynamicFormService.getAttributeComponentInstance(n))
        .filter(a => a && a.prefilledByYoti);
      // if current node changes, check all nodes are still verified or not (incl. current node)
      attribute.formControl.valueChanges.subscribe(() => {
        this.showOrHideAttributesBasedOnYotiResultImpl(verificationAttributes, this.allVerified(attributes));
      });
      this.showOrHideAttributesBasedOnYotiResultImpl(verificationAttributes, this.allVerified(attributes));
    }
  }

  private showOrHideAttributesBasedOnYotiResultImpl(verificationAttributes, allVerified: boolean) {
    verificationAttributes.forEach(v => {
      v.hidden = allVerified;
      if (allVerified) {
        v.formControl.disable({onlySelf: true, emitEvent: false});
        if (v instanceof FileuploadAttribute) {
          v.filenameFormControl.disable({onlySelf: true, emitEvent: false});
          v.folderFormControl.disable({onlySelf: true, emitEvent: false});
        }
      } else {
        v.formControl.enable({onlySelf: true, emitEvent: false});
        if (v instanceof FileuploadAttribute) {
          v.filenameFormControl.enable({onlySelf: true, emitEvent: false});
          v.folderFormControl.enable({onlySelf: true, emitEvent: false});
        }
      }
    });
  }

  public allVerified(attributes): boolean {
    let allVerified = true;
    attributes.forEach(a => allVerified = allVerified && a.isVerified());
    return allVerified;
  }

  private setupDisabledState(selectedCheckStatus, node, attribute) {
    if ((this.formModel.screeningStatus === 'DELAYED' || this.formModel.screeningStatus === 'RETURNED') &&
      selectedCheckStatus === 'RETURNED' && !node.returned && !isNullOrUndefined(attribute)) {
      if (node.hasOwnProperty('file')) {
        const deleteButton = <HTMLInputElement>document.getElementById('deleteButton_' + node.nodeId);
        // fileUpload component
        if (!isNullOrUndefined(deleteButton)) {
          deleteButton.disabled = true;
        }
      } else {
        attribute.formControl.disable({onlySelf: true, emitEvent: false});
      }
    } else if (node.returned && selectedCheckStatus !== 'CANDIDATE_SAVED' && !isNullOrUndefined(attribute)) {
      this.globalValidationMsg = true;
      let commentMsg = '';
      const comments = _.find(this.formComments, ['nodeId', node.nodeId]);
      if (!isNullOrUndefined(comments)) {
        _.forEach(comments.comments, (function (comment) {
          if (comment.type === 'RETURN') {
            commentMsg += comment.comment + ', ';
          }
        }).bind(this));
      }
      attribute.commentMsg = commentMsg.slice(0, -2);
    } else if ((this.formModel.screeningStatus === 'DELAYED' || this.formModel.screeningStatus === 'RETURNED') &&
      selectedCheckStatus === 'RETURNED' && !node.returned && !isNullOrUndefined(attribute)) {
      attribute.formControl.disable({onlySelf: true, emitEvent: false});
    }
    if ((this.formModel.screeningStatus === 'DELAYED' || this.formModel.screeningStatus === 'RETURNED') &&
      selectedCheckStatus === 'CANDIDATE_SAVED' && !isNullOrUndefined(attribute)) {
      attribute.formControl.disable({onlySelf: true, emitEvent: false});
    }
  }

  private patchFileNode(node, attribute) {
    const value = {
      name: node.file.name,
      folder: node.file.folder,
      id: ''
    };
    if (node.file.hasOwnProperty('id')) {
      value['id'] = node.file.id;
    }

    setTimeout(() => {
      if (node.file.hasOwnProperty('thumbnail') && !isNullOrUndefined(attribute) && !isNullOrUndefined(attribute.thumbnailFormControl)) {
        attribute.thumbnailFormControl.setValue(node.file['thumbnail']);
        value['thumbnail'] = node.file['thumbnail'];
      }
      if (attribute instanceof FileuploadAttribute) {
        attribute.removeThumbnail();
        attribute.setThumbnail(node.file);
        attribute.filenameFormControl.setValue(node.file.name);
        attribute.folderFormControl.setValue(node.file.folder);
        attribute.writeValue(value);
        attribute.formControl.setErrors(null);
      }
    }, 0);
  }

  async submitActiveCheck() {
    const values = [];
    const allGroups = this.dynamicFormService.getAllFormGroups();

    this.logComponents();

    const ranges = [];
    _.forEach(Array.from(this.dynamicFormService.getAllDateConditionValues().entries()), (function (val) {
      let keyCheckId;
      if (val[0].indexOf('#') !== -1)
        keyCheckId = val[0].split('#')[1].split('-')[0];
      else
        keyCheckId = val[0].split('-')[0];
      // make sure we're comparing dates for the active check
      if (keyCheckId === this.selectedItem) {
        ranges.push({
          start: val[1].dateStartValue.jsdate,
          end: val[1].dateEndValue.jsdate
        });
      }
    }).bind(this));
    // the first validation is for overlapping dates in case the user messes them up
    if (this.overlapDates(ranges)) {
      if (await this.saveAsDraft(allGroups))
        return;

      window.scrollTo(0, 0);
      const config = noticeToasterOptions;
      config.timeout = 5000;
      config.msg = getToasterMessagesLabel('dateValidationWarning', 'notices');
      this.toastyService.warning(config);
      return;
    }

    // validation for the rest of the form groups
    let isValid = true;
    allGroups.forEach(( function(group, index) {
      if (Object.keys(group.controls).length) {
        if (!isValid)
          return false;
        if (!this.dynamicFormService.getGroupComponentInstance(index).isVisible)
          return false;
        if (!group.valid && !group.disabled) {
          isValid = false;
          return false;
        }
        if ((group.pristine && group.untouched && group.invalid) || group.disabled)
          return false;

        this.collectValues(index, group, values);
      }
    }).bind(this));

    if (!isValid) {
      if (await this.saveAsDraft(allGroups))
        return;

      allGroups.forEach((function(group, index) {
        _.forEach(group.controls, ( function(control, nodeId) {
          if (control.invalid && control.untouched && this.dynamicFormService.getGroupComponentInstance(index).isVisible) {
            const attribute = this.dynamicFormService.getAttributeComponentInstance(nodeId);
            if (attribute instanceof FileuploadAttribute) {
              const fileDropZone = document.getElementById('fileDropArea_' + nodeId);
              if (!isNullOrUndefined(fileDropZone))
                fileDropZone.classList.add('required');
            }
            if (attribute instanceof SignatureAttribute) {
              const signatureWrapper = document.getElementById('canvas-wrapper_' + nodeId);
              if (!isNullOrUndefined(signatureWrapper))
                signatureWrapper.classList.add('required');
            }
            control.markAsTouched({onlySelf: true});
          }
        }).bind(this));
      }).bind(this));
      window.scrollTo(0, 0);
      const config = noticeToasterOptions;
      config.msg = getToasterMessagesLabel('validationWarning', 'notices');
      this.toastyService.warning(config);
    } else {
      // check if there is any upload in progress
      if (this.isUploading(allGroups)) {
        window.scrollTo(0, 0);
        const config = noticeToasterOptions;
        config.msg = getToasterMessagesLabel('uploadingWarning', 'notices');
        this.toastyService.warning(config);
        return;
      } else {
        this.submitCheckDisabled = true;
        const submitCheck = document.getElementById('submitCheck');
        const spinnerWrap = document.createElement('div');
        spinnerWrap.classList.add('spinner-wrap');
        spinnerWrap.classList.add('button');
        spinnerWrap.classList.add('grey');

        submitCheck.innerHTML = '';
        submitCheck.appendChild(spinnerWrap);

        const user = this.userAuth.getUser();
        const methodName = this.isReturned ? 'saveCorrectedCheckData' : 'saveScreeningCheckData';

        this.candidateScreeningService.saveScreeningCheckData(methodName, { 'values': values }, user.id, this.formModel.screeningId, this.selectedItem).subscribe(response => {
          this.onSaveScreeningCheckDataSuccess(response);
        }, err => {
          window.scrollTo(0, 0);
          this.submitCheckDisabled = false;
          submitCheck.innerHTML = this.saveContinueLabel;
          let action = 'serverError';
          if (err.status === 403)
            action = '403';
          addToaster('error', this.toastyService, action, 'errors');
        }, () => {
          this.onSaveScreeningCheckDataComplete(values);
        });
      }
    }
  }

  private async saveAsDraft(allGroups: Map<string, FormGroup>): Promise<boolean> {
    if (this.isReturned)
      return false;

    const resp = await this.dialogService.confirmAndDo(SAVE_AS_DRAFT_CONFIRMATION, null, this.saveAsDraftAction(allGroups));
    return resp === 'REJECT';
  }

  private saveAsDraftAction(allGroups: Map<string, FormGroup>) {
    return async () => {
      try {
        const draftValues = [];
        allGroups.forEach((function (group, index) {
          if (!this.dynamicFormService.getGroupComponentInstance(index).isVisible) {
            return false;
          }
          if ((group.pristine && group.untouched && group.invalid) || group.disabled) {
            return false;
          }
          if (Object.keys(group.controls).length) {
            this.collectValues(index, group, draftValues);
          }
        }).bind(this));
        const response = await this.candidateScreeningService.saveScreeningCheckData('saveScreeningCheckData', {
          'values': draftValues,
          'draft': true,
        }, this.userAuth.getUser().id, this.formModel.screeningId, this.selectedItem).toPromise();
        this.onSaveScreeningCheckDataSuccess(response);
        this.onSaveScreeningCheckDataComplete(draftValues, true);
      } catch (e) {
        window.scrollTo(0, 0);
        let error = 'serverError';
        if (e.status === 403) {
          error = '403';
        }
        addToaster('error', this.toastyService, error, 'errors');
        return;
      }
    };
  }

  private isUploading(allGroups: Map<string, FormGroup>) {
    let uploading = false;

    allGroups.forEach((function (group) {
        _.forEach(group.controls, (function (control, nodeId) {
          const attribute = this.dynamicFormService.getAttributeComponentInstance(nodeId);
          if (attribute instanceof FileuploadAttribute) {
            const attributeGroup = this.dynamicFormService.getGroupComponentInstance(attribute.formGroupId);

            if (attributeGroup.isVisible && attribute.data.required && !attribute.folderFormControl.value && !attribute.hidden) {
              console.error('Missing file for node ' + nodeId);
              console.error(attribute);
              uploading = true;
            }
          }
        }).bind(this));
    }).bind(this));

    return uploading;
  }

  private logComponents() {
    console.log('************************ Groups ****************************');
    console.log(this.dynamicFormService.getAllFormGroups());
    console.log('************************ Components ************************');
    console.log(this.dynamicFormService.getAllAttributeComponentsInstance());
  }

  private collectValues(groupIndex, group, values: any[]) {
    const groupInstance = this.dynamicFormService.getGroupComponentInstance(groupIndex);
    _.forEach(group.value, (function (val, index) {
      const checkId = this.getCheckIdFromGroupId(index);
      if (checkId === this.selectedItem) {
        const attrib = this.dynamicFormService.getAttributeComponentInstance(index);
        let newValue = null;
        if (attrib instanceof FileuploadAttribute) {
          newValue = {
            nodeId: index,
            file: attrib.getValue()
          };
        } else if (attrib instanceof SignatureAttribute) {
          newValue = {
            nodeId: index,
            signature: val
          };
        // datepicker instance
        } else if (val && val.hasOwnProperty('jsdate')) {
          newValue = {
            nodeId: index,
            value: val.jsdate.getFullYear() + '-' + ('0' + (val.jsdate.getMonth() + 1)).slice(-2) + '-' + ('0' + val.jsdate.getDate()).slice(-2)
          };
        } else if (attrib instanceof TelAttribute) {
          const prefixSelect = <HTMLSelectElement>document.getElementById(attrib.formGroupId + '-' + attrib.data.id + '-tel-select');
          const selectIndex = prefixSelect.selectedIndex;
          const prefixValue = prefixSelect.options[selectIndex].text;
          newValue = {
            nodeId: index,
            value: prefixValue + ' ' + val
          };
        } else {
          newValue = {nodeId: index, value: val};
        }

        if (!isNullOrUndefined(groupInstance.parentNodeId)) {
          newValue['parentNodeId'] = groupInstance.parentNodeId;
        }

        values.push(newValue);
      }
    }).bind(this));
  }

  private get isReturned(): boolean {
    return this.formModel.checks[this.searchCheckSelectedIndex()].checkStatus === 'RETURNED';
  }

  private onSaveScreeningCheckDataSuccess(response) {
    if (!isNullOrUndefined(response) && response.hasOwnProperty('checksValues')) {
      this.formData = response.checksValues;
      this.dynamicFormService.dynamicFormData = response.checksValues;
      this.updateFormGroupsFromData();
    }
  }

  private onSaveScreeningCheckDataComplete(values: any[], draft = false, onlyIdentityVerification = false) {
    const config = candidateScreeningToasterOptions;
    config.msg = getToasterMessagesLabel('checksaved', 'candidateScreening');
    this.toastyService.info(config);

    _.forEach(this.formModel.checks, (function (check, index) {
      if (onlyIdentityVerification) {
        if (this.isIdentityVerification(check)) {
          this.formModel.checks[index].checkStatus = 'CANDIDATE_SAVED';
          return false;
        }
      } else {
        if (check.id === this.selectedItem) {
          this.formModel.checks[index].checkStatus = draft ? 'DRAFT' : 'CANDIDATE_SAVED';
          return false;
        }
      }
    }).bind(this));

    this.submitCheckDisabled = false;
    const submitCheck = document.getElementById('submitCheck');
    if (!!submitCheck) {
      submitCheck.innerHTML = this.saveContinueLabel;
    }

    let canSubmitScreening = true;
    _.forEach(this.formModel.checks, function (check) {
      if (check.checkStatus === 'FAILED' || check.checkStatus === 'WITH_CANDIDATE' || check.checkStatus === 'RETURNED' || check.checkStatus === 'DRAFT') {
        canSubmitScreening = false;
        return false;
      }
    });
    this.canSubmitScreening = canSubmitScreening;
    this.dynamicFormService.canSubmitScreeningNotification(this.canSubmitScreening);
    this.globalValidationMsg = false;

    const checkComment = document.getElementById('checkCommentMsg');
    if (!isNullOrUndefined(checkComment)) {
      checkComment.innerText = '';
    }
    _.forEach(this.formComments, (function (comments, commentsIndex) {
      _.forEach(values, (function (value) {
        const attribute = this.dynamicFormService.getAttributeComponentInstance(value.nodeId);
        if (!isNullOrUndefined(attribute)) {
          attribute.commentMsg = '';
        } else {
          const group = this.dynamicFormService.getGroupComponentInstance(value.nodeId);
          if (!isNullOrUndefined(group)) {
            group.commentMsg = '';
          }
        }
        if (value.nodeId === comments.nodeId) {
          delete this.formComments[commentsIndex];
        }
      }).bind(this));
    }).bind(this));

    const active = this.searchNextCheckToActivate(this.selectedItem);
    if (active !== 0) {
      this.setActiveItem(null, active);
    } else {
      this.backToMenu();
      this.saveContinueLabel = 'Save';
    }
    window.scrollTo(0, 0);
  }

  overlapDates(dateRanges){
    let sortedRanges = dateRanges.sort((previous, current) => {
      let previousTime = previous.start.getTime();
      let currentTime = current.start.getTime();
      if (previousTime < currentTime)
        return -1;
      if (previousTime === currentTime)
        return 0;
      return 1;
    });

    let result = sortedRanges.reduce((result, current, idx, arr) => {
      if (idx === 0) { return result; }
      let previous = arr[idx-1];
      let previousEnd = previous.end.getTime();
      let currentStart = current.start.getTime();
      let overlap = (previousEnd >= currentStart);
      if (overlap)
        result = true;
      return result;
    }, false);
    return result;
  }

  updateFormGroupsFromData() {
    let unique = [];
    let repeated = [];
    _.forEach(this.formData, (function (node) {
      let formGroupId = node.nodeId.substring(0, node.nodeId.lastIndexOf('-'));
      if (!_.includes(repeated, formGroupId) && !_.includes(unique, formGroupId)) {
        if (formGroupId.indexOf('#') !== -1)
          repeated.push(formGroupId);
        else
          unique.push(formGroupId);
      }
    }).bind(this));
    this.formGroupsFromData = unique.concat(repeated);
  }

  searchNextCheckToActivate(checkId) {
    let result = 0;
    _.forEach(this.formModel.checks, function(check) {
      if ((check.checkStatus === 'WITH_CANDIDATE' || check.checkStatus === 'RETURNED' || check.checkStatus === 'DRAFT') && check.id !== checkId) {
        result = check.id;
        return false;
      }
    });
    return result;
  }

  searchCheckSelectedIndex() {
    let result = 0;
    _.forEach(this.formModel.checks, ( function(check, index) {
      if (check.id === this.selectedItem) {
        result = index;
        return false;
      }
    }).bind(this));
    return result;
  }

  async changeTabModal(event, active) {
    const allGroups = this.dynamicFormService.getAllFormGroups();
    let untouched = true;
    allGroups.forEach((function(group) {
      if (group.touched) {
        untouched = false;
        return false;
      }
    }).bind(this));
    if (this.selectedItem !== active) {
      if (this.preview || (this.sidePanel && window.screen.width <= 1200)) {
        this.setActiveItem(event, active);
      } else if (!this.sidePanel || window.screen.width >= 1200) {
        if (untouched) {
          if (isNullOrUndefined(event))
            this.backToMenu();
          else
            this.setActiveItem(event, active);
        } else {
          if (await this.saveAsDraft(allGroups))
            this.setActiveItem(event, active);
        }
      }
    }
  }

  changeTabConfirm(event, active) {
    if (!event)
      this.backToMenu();
    else
      this.setActiveItem(event, active);

    this.modalRef.hide();
  }

  setActiveItem(event, active) {
    if (this.selectedItem !== active) {
      if (!this.preview) {
        this.dfContent.classList.remove('d-none');
        this.dfMenu.classList.add('d-none');
      }
      let width = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
      if (!this.preview && width <= 1200)
        this.sidePanel = false;

      this.selectedItem = active;

      this.dynamicFormService.selectPanel(active);

      setTimeout(() => {
        this.createStructure();
      }, 0);
      setTimeout(() => {
        this.patchData();
        setTimeout(() => {
          this.dynamicFormService.getAllFormGroups().forEach((function(group) {
            group.markAsUntouched();
          }).bind(this));
        }, 100);
      }, 500);
    }
  }

  getCheckIdFromGroupId (groupId) {
    let checkId;
    if (groupId.indexOf('#') !== -1) {
      let temp = groupId.split('#');
      checkId = temp[1].split('-')[0];
    } else {
      checkId = groupId.split('-')[0];
    }
    return checkId;
  }

  submitScreening() {
    this.canSubmitScreening = false;
    this.submitted.emit(true);
  }

  closeModal() {
    this.modalRef.hide();
  }

  backToMenu() {
    if (!this.preview) {
      this.dfContent.classList.add('d-none');
      this.dfMenu.classList.remove('d-none');
    }

    let width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;
    if (!this.preview && width <= 1200) {
      this.sidePanel = true;
      this.selectedItem = 0;
    }
  }

  public isIdentityVerificationCheck(): boolean {
    const identityVerification = this.getIdentityVerification();
    return identityVerification && identityVerification.id == this.selectedItem;
  }

  public getIdentityVerification() {
    return this.formModel && this.formModel.checks && this.formModel.checks.find(ch => this.isIdentityVerification(ch));
  }

  public isIdentityVerification(check): boolean {
    return check && check.groups && check.groups.length > 0 && check.groups[0].attributes && check.groups[0].attributes.find(a => a.dataType === 'IDENTITY_VERIFICATION') != null;
  }
}
