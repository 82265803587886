<div class="xavier-steps-wrapper container">
  <div class="row">
    <div class="col-sm-4">
      <div class="xavier-steps header">
        Create Account
      </div>
      <div class="xavier-steps-component">
        <xavier-steps step="3"></xavier-steps>
      </div>
    </div>
    <div class="col-sm-8" [@slideIn]>
      <form [formGroup]="registrationData" novalidate (ngSubmit)="onSubmit(registrationData)">
        <div formArrayName="signatories" class="signatories-wrapper">
          <div *ngFor="let signatory of signatories.controls; let i=index">
            <div [formGroupName]="i" [@fadeIn]>
              <div class="row">
                <div class="col-xs-12 col-xl-3">
                  <div class="col-12 signatory-header">
                    <span>Legal Signatory {{i + 1}}</span>
                  </div>
                  <div class="col-12" *ngIf="signatories.controls.length > 1">
                    <button type="button" class="btn btn-block xavier-button-plain remove" (click)="removeSignatory(i)">
                      <span>Remove</span>
                    </button>
                  </div>
                </div>
                <div class="col-xs-12 col-xl-1 signatory-spacer"></div>
                <div class="col-xs-12 col-xl-8">
                  <div class="form-group col-12 xavier-input-text">
                    <label>First name</label>
                    <span class="required">&bull;</span>
                    <input type="text" class="form-control" formControlName="firstName" placeholder="First name">
                    <xavier-error-message [control]="signatory.get('firstName')"></xavier-error-message>
                  </div>
                  <div class="form-group col-12 xavier-input-text">
                    <label>Surname</label>
                    <span class="required">&bull;</span>
                    <input type="text" class="form-control" formControlName="lastName" placeholder="Surname">
                    <xavier-error-message [control]="signatory.get('lastName')"></xavier-error-message>
                  </div>
                  <div class="form-group col-12 xavier-input-text">
                    <label>Job Title</label>
                    <span class="required">&bull;</span>
                    <input type="text" class="form-control" formControlName="jobTitle" placeholder="Job Title">
                    <xavier-error-message [control]="signatory.get('jobTitle')"></xavier-error-message>
                  </div>
                  <div class="form-group col-12 xavier-input-text">
                    <xavier-multi-select-buttons
                      [title]="'Identification Upload'" [id]="'idDocument-'+i" [elements]="step3MultiSelectButtons" [errorClass]="multiSelectErrorClass"
                      [parentFormControl]="signatory.get('idDocument')">
                    </xavier-multi-select-buttons>
                    <div *ngFor="let documentType of step3MultiSelectButtons" class="row document-visibility" id="{{documentType.id+'-'+i}}">
                      <xavier-file-uploader [inputId]="documentType.id+'-'+i"
                                            [containerIndex]="i"
                                            [documentType]="documentType"
                                            [multiSelectOptions]="step3MultiSelectButtons"
                                            [errorClass]="uploadBoxErrorClass"
                                            [filenameFormControl]="signatory.get('name')"
                                            [folderFormControl]="signatory.get('folder')"
                                            [documentTypeFormControl]="signatory.get('documentType')">
                      </xavier-file-uploader>
                      <div *ngIf="showAddresses[i]" class="form-group col-12 xavier-input-text">
                        <div id="{{documentType.id+'-'+i+'-Address'}}">
                          <div class="row">
                            <div class="form-group col-6 xavier-input-text">
                              <label for="addressLine1">Address Line 1</label>
                              <span class="required">&bull;</span>
                              <input type="text" class="form-control" id="addressLine1" formControlName="addressLine1" placeholder="Address Line 1">
                              <xavier-error-message [control]="signatory.get('addressLine1')"></xavier-error-message>
                            </div>
                            <div class="form-group col-6 xavier-input-text">
                              <label for="addressLine2">Address Line 2</label>
                              <input type="text" class="form-control" id="addressLine2" formControlName="addressLine2" placeholder="Address Line 2">
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group col-6 xavier-input-text">
                              <label for="city">City</label>
                              <span class="required">&bull;</span>
                              <input type="text" class="form-control" id="city" formControlName="city" placeholder="City">
                              <xavier-error-message [control]="signatory.get('city')"></xavier-error-message>
                            </div>
                            <div class="form-group col-6 xavier-input-text">
                              <label for="country">Country</label>
                              <input type="text" class="form-control" id="country" formControlName="country" placeholder="Country">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-12 xavier-input-text">
                        <ng-container *ngIf="!showAddresses[i]">
                          <label>Residential Address</label>
                          <xavier-select-dropdown (entrySelectedEmitter)="onEntrySelected(documentType.id, i, $event)"
                                                  (createItemEmitter)="displayAddressFields(documentType.id, i)"
                                                  [componentParams]="initAddressLookupParams(i)">
                          </xavier-select-dropdown>
                          <xavier-error-message [control]="signatory.get('postcode')"></xavier-error-message>
                          <div *ngTemplateOutlet="enterManually; context: { $implicit: i }"></div>
                        </ng-container>
                        <ng-container *ngIf="showAddresses[i]">
                          <label for="postcode">Postcode</label>
                          <input type="text" class="form-control" id="postcode" formControlName="postcode" placeholder="Postcode">
                          <xavier-error-message [control]="signatory.get('postcode')"></xavier-error-message>
                          <div *ngTemplateOutlet="enterManually; context: { $implicit: i }"></div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="xavier-add-signatory">
              <button type="button" class="btn xavier-button-plain add-signatory" (click)="addSignatory()">
                <i class="material-icons addIcon">&#xE145;</i>Add Another Legal Signatory
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="xavier-agreement xavier-input-checkbox">
              <input type="checkbox" class="form-check-input" id="agreement" formControlName="agreement" required>
              <label for="agreement" class="form-check-label">
                I understand and accept you will be undertaking identity checks and anti money laundering checks on the above named legal signatories.
              </label>
            </div>
          </div>
        </div>
        <div class="row buttons-spacer">
          <div class="col-xs-12 col-xl-2">
            <button type="button" class="btn btn-block xavier-button-plain" (click)="backRegistration()">
              <span>Back</span>
            </button>
          </div>
          <div class="col-5 button-spacer"></div>
          <div class="col-xs-12 col-xl-5">
            <button *ngIf="!reSubmit" type="submit" class="btn btn-block xavier-button continue-step">
              <span>Continue to Account Security</span> <i class="material-icons">&#xE5C8;</i>
            </button>
            <button *ngIf="reSubmit" type="submit" id="confirmForm" class="btn btn-block xavier-button continue-step" [disabled]="disabled">
              <span>Re-Submit Application</span> <i class="material-icons">&#xE5C8;</i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #enterManually let-i>
  <div class="enter-manually text-muted" (click)="onEnterManuallyClicked(i)">{{showAddresses[i] ? 'Use postcode lookup' : 'Enter manually'}}</div>
</ng-template>
