<div class="row">
  <div class="col-sm-12 col-xl-5 all-checks-container">
    <label>All Checks</label>
    <div class="all-checks-wrapper">
      <div class="form-group xavier-input-text">
        <div class="input-group">
          <span class="input-group-addon" id="search-all-addon"><i class="material-icons">&#xE8B6;</i></span>
          <input #allChecksSearch
                 type="text"
                 class="form-control search-all-checks"
                 id="search-all-checks"
                 placeholder="Search all checks..."
                 autocomplete="off"
                 (keyup)="filterChecks('all-checks', allChecksSearch.value)">
        </div>
        <i *ngIf="allChecksSearch.value !== ''" class="material-icons checks-search-reset" (click)="resetChecksSearch(allChecksSearch)">&#xE5C9;</i>
      </div>
      <div class="all-checks" id="all-checks">
        <div *ngFor="let check of activeChecks"
             class="row check"
             [id]="check.id">
          <div class="info">
            <div class="title">{{check.checkName}}</div>
            <div class="description">Price: £{{check.price}}</div>
          </div>
          <i class="material-icons add" (click)="addCheck(check, $event)">&#xE147;</i>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 checks-separator">
    <i class="material-icons arrows">&#xE915;</i>
  </div>
  <div class="col-sm-12 col-xl-6 added-checks-container">
    <label>Added Checks</label><i class="material-icons error visibility-none" id="added-checks-error">&#xE000;</i>
    <div class="added-checks-wrapper">
      <div class="form-group xavier-input-text">
        <div class="input-group">
          <span class="input-group-addon" id="search-added-addon"><i class="material-icons">&#xE8B6;</i></span>
          <input #addedChecksSearch
                 type="text"
                 class="form-control search-added-checks"
                 id="search-added-checks"
                 placeholder="Search added checks..."
                 autocomplete="off"
                 (keyup)="filterChecks('added-checks', addedChecksSearch.value)">
        </div>
        <i *ngIf="addedChecksSearch.value !== ''" class="material-icons checks-search-reset" (click)="resetChecksSearch(addedChecksSearch)">&#xE5C9;</i>
      </div>
      <div class="added-checks" id="added-checks" [dragula]='"addedChecks"'></div>
    </div>
  </div>
</div>
