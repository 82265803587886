import {Role} from './role.model';
import {PermissionList, UserPermission} from './permissions.model';
import {isNullOrUndefined} from 'util';
import * as _ from 'lodash';

export class User {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  lastLogin: string;
  companyId: string;
  companyName: string;
  role: Role;
  candidateId: string; // present only if candidate
  permissionList: UserPermission[] = Array();
  email?: string;
  saasClient?: string;
  dbsCountersignatory?: boolean;


  constructor(
    id: string,
    firstName: string,
    lastName: string,
    fullName: string,
    lastLogin: string,
    companyId: string,
    companyName: string,
    role: Role,
    candidateId: string,
    permissions: UserPermission,
    email: string,
    saasClient?: string,
    dbsCountersignatory?: boolean,
  ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.fullName = fullName;
    this.lastLogin = lastLogin;
    this.companyId = companyId;
    this.companyName = companyName;
    this.role = role;
    this.candidateId = candidateId;
    this.email = email;
    this.saasClient = saasClient;
    this.dbsCountersignatory = dbsCountersignatory;


    _.forEach(permissions, ( function(permission) {
      const temp = new UserPermission(permission['object'], permission['action'], permission['access'], !isNullOrUndefined(permission['scope']) ? permission['scope'] : null);
      _.forEach(PermissionList, ( function(p, index) {
        if (_.isEqual(temp, p)) {
          this.permissionList.push(index);
        }
      }).bind(this));
    }).bind(this));
  }

}
