<ng2-toasty [position]="'top-center'" class="xavier-toasty"></ng2-toasty>
<div class="xavier-steps-wrapper container">
  <div class="flex-cont">
    <div class="col-sm-4">
      <div class="xavier-steps header">Secure Account</div>
      <div class="xavier-steps-component">
        <xavier-steps
          step="2"
          [maxSteps]="2"
          [stepDescriptionLine1]="'Verify your mobile number'"
          [stepDescriptionLine2]="
            'Please enter the 6 digit code we have sent to ' + (userAuth.phoneNumber$ | async).replace(';', '') + '. This will expire in 10 mins.'
          "
        ></xavier-steps>
      </div>
    </div>
    <div class="col-xs-12 col-md-8 d-flex justify-content-center flex-column">
      <div class="pt-4 container col-xs-12 col-lg-8 d-flex align-items-center">
        <xavier-code-input (codeEnter)="handleCodeInput($event)" [disabled]="isDisabled" [requesting]="isDisabled"></xavier-code-input>
      </div>

      <div class="mt-4 d-flex justify-content-center color-grey">Didn’t get a code? Please wait 30 seconds before requesting a new code. </div>
      <div class="mt-4 d-flex justify-content-center color-def"><span class="cursor-pointer" [ngClass]="pressed" (click)="requestNewCode($event)">Request new code</span></div>
      <div *ngIf="isRequesting" class="mt-4">
        <ng-container *ngTemplateOutlet="loading"></ng-container>
      </div>

      <div class="btn-container col-xs-12 col-lg-1">
        <button type="submit" class="btn btn-block xavier-button-plain" (click)="goBack()">
          <span>Back</span>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
    <div class="spinner-container not-button"><div class="spinner-wrap"></div></div>
</ng-template>
