import {StatusIconComponent} from '../../cmps-parts/status-icon/status-icon.component';
import {PercentBarComponent} from '../../cmps-parts/percent-bar/percent-bar.component';
import {DownloadIconComponent} from '../../cmps-parts/download-icon/download-icon.component';
import { OptionsDropdownComponent } from '../../cmps-parts/options-dropdown/options-dropdown.component';

export const LIVE_ID_ORDERS_CONFIG = {
  settings: [{
    objectKey: 'id',
    visible: false
  }, {
    objectKey: 'candidateName',
    sortOrder: 0
  }, {
    objectKey: 'ordererName',
    sortOrder: 0
  }, {
    objectKey: 'ordererEmail',
    sortOrder: 0
  }, {
    objectKey: 'ordererPhone',
    sortOrder: 0
  }, {
    objectKey: 'ordererCompany',
    sort: 'disable'
  }, {
    objectKey: 'candidateEmail',
    sortOrder: 0
  }, {
    objectKey: 'checkPurpose',
    sort: 'disable'
  }, {
    objectKey: 'liveIdCheck',
    sort: 'disable'
  }, {
    objectKey: 'creditReport',
    sort: 'disable'
  }, {
    objectKey: 'amlCheck',
    sort: 'disable'
  }, {
    objectKey: 'marketingOptIn',
    sort: 'disable'
  }, {
    objectKey: 'orderDate',
    sortOrder: 0,
    sort: 'desc'
  }, {
    objectKey: 'daysRemaining',
    sort: 'disable'
  }, {
    objectKey: 'status',
    sort: 'disable'
  }, {
    objectKey: 'optionsDropdown',
    sort: 'disable'
  }, {
    objectKey: 'certificate',
    sort: 'disable'
  }],
  fields: [{
    name: 'id',
    objectKey: 'id'
  }, {
    name: 'Candidate Name',
    objectKey: 'candidateName',
    columnClass: (row) => {
      if (row == null) {
        return 'no-top-border candidate-name';
      }
    }
  }, {
    name: 'Orderer’s Name',
    objectKey: 'ordererName',
    columnClass: (row) => {
      if (row == null) {
        return 'no-top-border';
      }
    }
  }, {
    name: 'Orderer’s Email Address',
    objectKey: 'ordererEmail',
    columnClass: (row) => {
      if (row == null) {
        return 'no-top-border';
      }
    }
  }, {
    name: 'Orderer’s Phone Number',
    objectKey: 'ordererPhone',
    columnClass: (row) => {
      if (row == null) {
        return 'no-top-border';
      }
    }
  }, {
    name: 'Orderer’s Company',
    objectKey: 'ordererCompany',
    columnClass: (row) => {
      if (row == null) {
        return 'no-top-border';
      }
    }
  }, {
    name: 'Candidate’s Email Address',
    objectKey: 'candidateEmail',
    columnClass: (row) => {
      if (row == null) {
        return 'no-top-border';
      }
    }
  }, {
    name: 'Purpose of Checks',
    objectKey: 'checkPurpose',
    columnClass: (row) => {
      if (row == null) {
        return 'no-top-border';
      }
    }
  }, {
    name: 'Live ID Ordered',
    objectKey: 'liveIdCheck',
    columnClass: (row) => {
      if (row == null) {
        return 'no-top-border';
      }
    }
  }, {
    name: 'Credit Ordered',
    objectKey: 'creditReport',
    columnClass: (row) => {
      if (row == null) {
        return 'no-top-border';
      }
    }
  }, {
    name: 'AML Ordered',
    objectKey: 'amlCheck',
    columnClass: (row) => {
      if (row == null) {
        return 'no-top-border';
      }
    }
  }, {
    name: 'Marketing Opt-in',
    objectKey: 'marketingOptIn',
    columnClass: (row) => {
      if (row == null) {
        return 'no-top-border';
      }
    }
  }, {
    name: 'Order Date',
    objectKey: 'orderDate',
    columnClass: (row) => {
      if (row == null) {
        return 'no-top-border order-date';
      }
    }
  }, {
    name: 'Days Remaining',
    objectKey: 'daysRemaining',
    columnClass: (row) => {
      if (row == null) {
        return 'no-top-border days-remaining';
      }
    },
    columnComponent: {
      type: PercentBarComponent
    }
  }, {
    name: 'Status',
    objectKey: 'status',
    columnClass: (row) => {
      if (row == null) {
        return 'no-top-border status';
      }
    },
    columnComponent: {
      type: StatusIconComponent
    }
  }, {
    name: '',
    objectKey: 'optionsDropdown',
    columnClass: (row) => {
      if (row == null) {
        return 'no-top-border options-dropdown';
      }
    },
    columnComponent: {
      type: OptionsDropdownComponent
    }
  }, {
    name: '',
    objectKey: 'certificate',
    columnClass: (row) => {
      if (row == null) {
        return 'no-top-border certificate';
      }
    },
    columnComponent: {
      type: DownloadIconComponent
    }
  }],
  data: [],
  info: {}
};
