import {Address} from "./address.model";
import {Signatory} from "./signatories.model";
import *  as _ from 'lodash';

export class AdminUser {
  id: number;
  companyName: string;
  entityType: string;
  registrationNumber: string;
  insuranceNumber: string;
  address: Address;
  vatInEU: boolean;
  vat: string;
  mobilePhone: string;
  officePhone: string;
  officePhoneExt: string;
  signatories: Signatory[];
  firstName: string;
  lastName: string;
  account: {
    email: string
  };
  manager: {
    password: string
  };
  securityQuestion: string;
  securityAnswer: string;
  googleToken: string;

  constructor(email?: string, uid?: string, clientData?: any) {
    if (email && uid) {
      this.account = { email: email };
      this.googleToken = uid;
    }
    if (clientData) {
      let signatories = [];
      _.forEach(clientData.signatories, function(signatory) {
        let temp = {
          "id": signatory.id,
          "firstName": signatory.firstName,
          "lastName": signatory.lastName,
          "jobTitle": signatory.jobTitle,
          "addressLine1": signatory.address.addressLine1,
          "addressLine2": signatory.address.addressLine2,
          "postcode": signatory.address.postcode.toUpperCase(),
          "city": signatory.address.city,
          "county": signatory.address.county,
          "country": signatory.address.country,
          "name": signatory.idDocument.name,
          "folder": signatory.idDocument.folder,
          "documentType": signatory.documentType
        };
        signatories.push(temp);
      });

      this.companyName = clientData.companyName;
      this.entityType = clientData.entityType;
      this.registrationNumber = clientData.registrationNumber ? clientData.registrationNumber : '';
      this.insuranceNumber = clientData.insuranceNumber ? clientData.insuranceNumber : '';
      this.address = clientData.address;
      this.vatInEU = clientData.vatInEU;
      this.vat = clientData.vat ? clientData.vat : '';
      this.mobilePhone = clientData.mobilePhone;
      this.officePhone = clientData.officePhone;
      this.officePhoneExt = clientData.officePhoneExt ? clientData.officePhoneExt : '';
      this.signatories = signatories;
      this.firstName = clientData.manager.firstName;
      this.lastName = clientData.manager.lastName;
      this.account = { email: clientData.manager.email };
      this.id = clientData.id;
    }
  }
}
