import {AbstractControl, FormGroup} from '@angular/forms';
import {AfterViewInit, ElementRef, Injectable, OnDestroy, Renderer2} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DATE_END, DATE_START, DF_VALIDATION_FN} from '../../../../../data/variables.data';
import {DynamicFormService} from '../../../services/dynamic-form.service';
import {DateAttribute} from './date/date.component';
import {IMyDateModel} from 'mydatepicker';
import {isNullOrUndefined} from 'util';
import * as _ from 'lodash';
import {RadioAttribute} from './radio/radio.component';
import {formatDate, isVerified} from '../../../../utils/functions.utils';

type AttributeType = { new (DynamicFormService, HttpClient): Attribute };

export const ATTRIBUTE_TYPES_MAP = new Map<string, AttributeType>();

@Injectable()
export class Attribute implements AfterViewInit, OnDestroy {

  public data: any;
  public formGroupId: string;
  public formGroup: FormGroup;
  public formControl: AbstractControl;
  public filenameFormControl: AbstractControl;
  public folderFormControl: AbstractControl;
  public thumbnailFormControl: AbstractControl;
  public subsequentDatesError: boolean;
  public commentMsg: string;
  public prefilledByYoti = false;
  public verifiedValue: string;
  public hidden = false;

  constructor(
    public dynamicFormService: DynamicFormService,
    public http?: HttpClient,
  ) {

  }

  public static getTypeFor(name: string): AttributeType {
    return ATTRIBUTE_TYPES_MAP.get(name);
  }

  ngAfterViewInit() {
    this.dynamicFormService.setAttributeComponentInstance(this.formGroupId + '-' + this.data.id, this);
  }

  ngOnDestroy() {
    this.dynamicFormService.removeAttributeComponentInstance(this.formGroupId);
  }

  public DETECT_GAPS(caller: any) {
    const dates = Array.from(this.dynamicFormService.getAllDateConditionValues().entries());
    let gapIndex = -1;
    // find the index of the caller's gap in the Array of gaps (originally a Map)
    _.forEach(dates, ( function(value, index) {
      const callerAttribute = this.dynamicFormService.getAttributeComponentInstance(caller) as DateAttribute;
      if (callerAttribute.formGroupId === value[0])
        gapIndex = index;
    }).bind(this));

    // if the gap has found and has a predecessor..
    if (gapIndex !== -1 && gapIndex >= 1) {
      let gapDates = dates[gapIndex - 1];
      // calculate the days of difference between gaps, getTime is used for precision
      const diffDays = Math.round(
        Math.abs(gapDates[1].dateStartValue.jsdate.getTime() - dates[gapIndex][1].dateEndValue.jsdate.getTime())
        /
        (1000 * 3600 * 24)
      );
      // display or hide the "Reason for Gap" for the predecessor caller's group if the difference is bigger than 7 days
      if (!isNullOrUndefined(this.dynamicFormService.getGroupComponentInstance(gapDates[0]))) {
        this.dynamicFormService.getGroupComponentInstance(gapDates[0]).group.conditions.forEach((condition) => {
          if (condition.fn === DF_VALIDATION_FN.DETECT_GAPS) {
            const maxSeparatorIndex = this.dynamicFormService.getGroupComponentInstance(gapDates[0]).formGroupId.lastIndexOf('-');
            const instanceId = this.dynamicFormService.getGroupComponentInstance(gapDates[0]).formGroupId
              .substr(0, maxSeparatorIndex) + '-' + condition.targetGroup.id;
            const gapGroup = this.dynamicFormService.getGroupComponentInstance(instanceId);
            const visibility = diffDays > 7;
            if (!!gapGroup) {
              const startGapDate = dates[gapIndex][1].dateEndValue.formatted;
              const endGapDate = gapDates[1].dateStartValue.formatted;

              if (gapGroup.group.groupName.includes('-')) {
                gapGroup.group.groupName = gapGroup.group.groupName.split('-')[0];
              }

              if (startGapDate !== "not used" && endGapDate !== "not used") {
                gapGroup.group.groupName = `${gapGroup.group.groupName} - ${startGapDate} to ${endGapDate}`
              }
              gapGroup.visibilityCheck(visibility);
            }
          }
        });
      }
      // it's the last group so there cannot be gap at the end
      if (dates.length - 1 === gapIndex) {
        // display or hide the "Reason for Gap" for the successor caller's group if the difference is bigger than 7 days
        gapDates = dates[gapIndex];
        this.dynamicFormService.getGroupComponentInstance(gapDates[0]).group.conditions.forEach((condition) => {
          if (condition.fn === DF_VALIDATION_FN.DETECT_GAPS) {
            const parentGroup = this.dynamicFormService.getGroupComponentInstance(gapDates[0]);
            const instanceId = parentGroup.formGroupId.substr(0, parentGroup.formGroupId.indexOf('#')) + '#' + parentGroup.checkId + '-' + condition.targetGroup.id;
            const gapGroup = this.dynamicFormService.getGroupComponentInstance(instanceId);
            if (!!gapGroup) {
              // console.log('2 visibility check: ' + instanceId + ' ' + false);
              gapGroup.visibilityCheck(false);
            }
          }
        });
      }

    // else if the gap has found but it's the group following..
    } else if (gapIndex === 0 && dates.length > 1) {
      const gapDates = dates[gapIndex];
      if (gapDates[1].dateStartValue.jsdate.getTime() >= dates[gapIndex + 1][1].dateEndValue.jsdate.getTime()) {
        // calculate the days of difference between gaps, getTime is used for precision
        const diffDays = Math.round(
          Math.abs(gapDates[1].dateStartValue.jsdate.getTime() - dates[gapIndex + 1][1].dateEndValue.jsdate.getTime())
          /
          (1000 * 3600 * 24)
        );
        // display or hide the "Reason for Gap" for the successor caller's group if the difference is bigger than 7 days
        this.dynamicFormService.getGroupComponentInstance(gapDates[0]).group.conditions.forEach((condition) => {
          if (condition.fn === DF_VALIDATION_FN.DETECT_GAPS) {
            const instanceId = this.dynamicFormService.getGroupComponentInstance(gapDates[0]).checkId + '-' + condition.targetGroup.id;
            const visibility = diffDays > 7;
            // console.log('3 visibility check: ' + instanceId + ' ' + visibility);
            this.dynamicFormService.getGroupComponentInstance(instanceId).visibilityCheck(visibility);
          }
        });
      }

    // there is just one date yet so no dates to calculate gap
    } else if (gapIndex === 0 && dates.length === 1) {
      const gapDates = dates[gapIndex];
      this.dynamicFormService.getGroupComponentInstance(gapDates[0]).group.conditions.forEach((condition) => {
        if (condition.fn === DF_VALIDATION_FN.DETECT_GAPS) {
          const instanceId = this.dynamicFormService.getGroupComponentInstance(gapDates[0]).checkId + '-' + condition.targetGroup.id;
          const gapGroup = this.dynamicFormService.getGroupComponentInstance(instanceId);
          if (!!gapGroup) {
            // console.log('4 visibility check: ' + instanceId + ' ' + false);
            gapGroup.visibilityCheck(false);
          }
        }
      });
    }
  }

  public MATCH_RADIO(caller: any, value: any, condition: any) {
    if (!isNullOrUndefined(value)) {
      const displayCondition = (value === condition.comparator);
      this.dynamicFormService.getAllGroupComponentsInstance().forEach((instance, key) => {
        if (caller.indexOf('#') !== -1) {
          const index = caller.split('#')[0];
          const suffix = instance.formGroupId.split('#')[1];
          if (key === index + '#' + suffix && suffix.indexOf(condition.targetGroup.id) !== -1) {
            if (!displayCondition)
              instance.formGroup.reset();

            // console.log('5 visibility check: ' + key + ' ' + displayCondition);
            instance.visibilityCheck(displayCondition);
          }
        } else if (instance.group.id === condition.targetGroup.id) {
          if (!displayCondition)
            instance.formGroup.reset();

          // console.log('6 visibility check: ' + key + ' ' + displayCondition);
          instance.visibilityCheck(displayCondition);
        }
      });
    }
  }

  public MATCH_SELECT(caller: any, value: any, condition: any) {
    if (!isNullOrUndefined(value)) {
      const displayCondition = (value === condition.comparator);
      this.dynamicFormService.getAllGroupComponentsInstance().forEach((instance, key) => {
        if (caller.indexOf('#') !== -1) {
          const index = caller.split('#')[0];
          const suffix = instance.formGroupId.split('#')[1];
          if (key === index + '#' + suffix && suffix.indexOf(condition.targetGroup.id) !== -1) {
            if (!displayCondition)
              instance.formGroup.reset();

            // console.log('7 visibility check: ' + key + ' ' + displayCondition);
            instance.visibilityCheck(displayCondition);
          }
        } else if (instance.group.id === condition.targetGroup.id) {
          if (!displayCondition)
            instance.formGroup.reset();

          // console.log('8 visibility check: ' + key + ' ' + displayCondition);
          instance.visibilityCheck(displayCondition);
        }
      });
    }
  }

  public MIN_YEAR_GROUP(caller: any, type: string, value: any, condition: any) {
    if (!isNullOrUndefined(value)) {
      const valueToTimestamp = Date.parse(value.jsdate) / 1000;
      let otherValue, startAttributeId, startAttributeValue, endAttributeId, endAttributeValue;
      const callerAttribute = this.dynamicFormService.getAttributeComponentInstance(caller) as DateAttribute;
      if (type === DATE_START) {
        startAttributeId = caller;
        startAttributeValue = callerAttribute.componentDate;
        if (!isNullOrUndefined(callerAttribute.endAttributeId)) {
          otherValue = (this.dynamicFormService.getAttributeComponentInstance(callerAttribute.endAttributeId) as DateAttribute).componentDate;
          endAttributeId = callerAttribute.endAttributeId;
          endAttributeValue = otherValue;
        } else {
          const today = new Date();
          const pastSevenDays = today.getDate() - 8;
          today.setDate(pastSevenDays);
          otherValue = {
            date: {
              year: today.getFullYear(),
              month: today.getMonth() + 1,
              day: today.getDate()
            },
            jsdate: today,
            formatted: 'not used',
            epoc: today.getTime()
          } as IMyDateModel;
          endAttributeId = '';
          endAttributeValue = otherValue;
        }
      } else if (type === DATE_END) {
        otherValue = (this.dynamicFormService.getAttributeComponentInstance(callerAttribute.startAttributeId) as DateAttribute).componentDate;
        startAttributeId = callerAttribute.startAttributeId;
        startAttributeValue = otherValue;
        endAttributeId = caller;
        endAttributeValue = callerAttribute.componentDate;
      }
      if (otherValue !== null) {
        const daysRange = Math.abs(valueToTimestamp - (Date.parse(otherValue.jsdate) / 1000)) / 86400;
        this.dynamicFormService.setDateConditionValue(
          callerAttribute.formGroupId, startAttributeId, startAttributeValue,
          endAttributeId, endAttributeValue, daysRange
        );
        const callerGroupInstance = this.dynamicFormService.getGroupComponentInstance(callerAttribute.formGroupId);
        const yearsMissing = this.yearsHistoryCheck(condition.comparator, type === DATE_START ? startAttributeValue.date : null);
        // iterate through all groups
        const groupsDesc = new Map(Array.from(this.dynamicFormService.getAllGroupComponentsInstance().entries()).sort().reverse());
        groupsDesc.forEach((instance, key) => {
          setTimeout(() => {
            // process only the repeated target group for given condition
            if (_.endsWith(key, '#' + instance.checkId + '-' + condition.targetGroup.id)) {
              // show or hide first of them
              if ('1' === key.substr(0, key.indexOf('#'))) {
                const targetGroupInstance = this.dynamicFormService.getGroupComponentInstance(key);
                if (this.hasCallerEmploymentHistoryMoreAttribute(callerGroupInstance)) {
                  this.showOrHideMoreEmploymentQuestion(callerGroupInstance, yearsMissing);
                  if (!yearsMissing) {
                    // console.log('9 visibility check: ' + key + ' ' + false);
                    targetGroupInstance.visibilityCheck(false);
                    this.removeCrossGroupsConditions(callerGroupInstance, targetGroupInstance);
                  }
                } else {
                  // console.log('10 visibility check: ' + key + ' ' + yearsMissing);
                  targetGroupInstance.visibilityCheck(yearsMissing);
                  if (!yearsMissing)
                    this.removeCrossGroupsConditions(callerGroupInstance, targetGroupInstance);
                }
              // delete all the others
              } else if (!yearsMissing) {
                if (this.hasCallerEmploymentHistoryMoreAttribute(callerGroupInstance))
                  this.showOrHideMoreEmploymentQuestion(callerGroupInstance, false);

                const previousGroupInstance = this.dynamicFormService.getGroupComponentInstance((Number(key.substr(0, key.indexOf('#'))) - 1)
                  + key.substr(key.indexOf('#'), key.length));
                const targetGroupInstance = this.dynamicFormService.getGroupComponentInstance(key);
                this.removeCrossGroupsConditions(previousGroupInstance, targetGroupInstance);
                const groupToDelete = this.dynamicFormService.getGroupComponentInstance(key);
                if (!!groupToDelete)
                  groupToDelete.deleteDfGroup();
              }
            }
          }, 0);
        });
      }
    }
  }

  public MIN_YEARS(caller: any, type: string, value: any, condition: any) {
    if (!isNullOrUndefined(value)) {
      const valueToTimestamp = Date.parse(value.jsdate) / 1000;
      let otherValue, startAttributeId, startAttributeValue, endAttributeId, endAttributeValue;
      const callerAttribute = this.dynamicFormService.getAttributeComponentInstance(caller) as DateAttribute;
      if (type === DATE_START) {
        otherValue = (this.dynamicFormService.getAttributeComponentInstance(callerAttribute.endAttributeId) as DateAttribute).componentDate;
        startAttributeId = caller;
        startAttributeValue = callerAttribute.componentDate;
        endAttributeId = callerAttribute.endAttributeId;
        endAttributeValue = otherValue;
      } else if (type === DATE_END) {
        otherValue = (this.dynamicFormService.getAttributeComponentInstance(callerAttribute.startAttributeId) as DateAttribute).componentDate;
        startAttributeId = callerAttribute.startAttributeId;
        startAttributeValue = otherValue;
        endAttributeId = caller;
        endAttributeValue = callerAttribute.componentDate;
      }
      if (otherValue !== null) {
        const daysRange = Math.abs(valueToTimestamp - (Date.parse(otherValue.jsdate) / 1000)) / 86400;
        this.dynamicFormService.setDateConditionValue(
          callerAttribute.formGroupId, startAttributeId, startAttributeValue,
          endAttributeId, endAttributeValue, daysRange
        );
        const callerGroupInstance = this.dynamicFormService.getGroupComponentInstance(callerAttribute.formGroupId);
        const yearsMissing = this.yearsHistoryCheck(condition.comparator, type === DATE_START ? startAttributeValue.date : null);
        // add new target group
        if (yearsMissing) {
          if (this.hasCallerEmploymentHistoryMoreAttribute(callerGroupInstance))
            this.showOrHideMoreEmploymentQuestion(callerGroupInstance, true);
          else {
            const targetGroupInstanceId = (Number(caller.substr(0, caller.indexOf('#'))) + 1)
              + callerGroupInstance.formGroupId.substr(callerGroupInstance.formGroupId.indexOf('#'), callerGroupInstance.formGroupId.length);
            setTimeout(() => {
              const targetGroupInstance = this.dynamicFormService.getGroupComponentInstance(targetGroupInstanceId);
              if (isNullOrUndefined(targetGroupInstance)) {
                callerGroupInstance.addDfGroup();
              }
            }, 0);
          }
        // delete all groups with higher repeat ID
        } else {
          if (this.hasCallerEmploymentHistoryMoreAttribute(callerGroupInstance))
            this.showOrHideMoreEmploymentQuestion(callerGroupInstance, false);

          const callerRepeatId = Number(caller.substr(0, caller.indexOf('#')));
          // iterate through all groups
          const groupsDesc = new Map(Array.from(this.dynamicFormService.getAllGroupComponentsInstance().entries()).sort().reverse());
          groupsDesc.forEach((instance, key) => {
            setTimeout(() => {
              // process only the repeated target group for given condition
              if (_.endsWith(key, callerGroupInstance.formGroupId.substr(callerGroupInstance.formGroupId.indexOf('#'), callerGroupInstance.formGroupId.length))) {
                // delete group with higher repeat ID
                const keyRepeatId = Number(key.substr(0, key.indexOf('#')));
                if (keyRepeatId > callerRepeatId) {
                  const previousGroupInstance = this.dynamicFormService.getGroupComponentInstance((Number(key.substr(0, key.indexOf('#'))) - 1)
                    + key.substr(key.indexOf('#'), key.length));
                  const targetGroupInstance = this.dynamicFormService.getGroupComponentInstance(key);
                  this.removeCrossGroupsConditions(previousGroupInstance, targetGroupInstance);
                  if (!!targetGroupInstance)
                    targetGroupInstance.deleteDfGroup();
                }
              }
            }, 0);
          });
        }
      }
    }
  }

  public SUBSEQUENT_DATES(caller: any) {
    const dates = Array.from(this.dynamicFormService.getAllDateConditionValues().entries());
    let gapIndex = -1;
    // find the index of the caller's gap in the Array of gaps (originally a Map)
    _.forEach(dates, ( function(value, index) {
      const callerAttribute = this.dynamicFormService.getAttributeComponentInstance(caller) as DateAttribute;
      if (callerAttribute.formGroupId === value[0])
        gapIndex = index;
    }).bind(this));
    // if the gap has found and has a predecessor..
    if (gapIndex >= 1) {
      const gapDates = dates[gapIndex - 1];
      // calculate the days of difference between gaps, getTime is used for precision
      const diffDays = Math.round(
        Math.abs(gapDates[1].dateStartValue.jsdate.getTime() - dates[gapIndex][1].dateEndValue.jsdate.getTime())
        /
        (1000 * 3600 * 24)
      );
      // display error message if diffDays > 1
      if (diffDays > 1) {
        this.subsequentDatesError = true;
        this.formControl.markAsTouched();
        this.formControl.setErrors({ invalid: true });
        this.dynamicFormService.globalValidationMsgNotification(true);
      } else {
        this.subsequentDatesError = false;
      }
    }
  }

  public REPEAT_MATCH_GROUP(caller: any, value: any, condition: any) {
    if (!isNullOrUndefined(value)) {
      // iterate through all groups
      this.dynamicFormService.getAllGroupComponentsInstance().forEach((instance, key) => {
          setTimeout(() => {
            // process only the repeated target group for given condition
            if (_.endsWith(key, '#' + instance.checkId + '-' + condition.targetGroup.id)) {
              // show or hide first of them
              if ('1' === key.substr(0, key.indexOf('#'))) {
                const callerGroupInstance = this.dynamicFormService.getGroupComponentInstance(caller);
                const targetGroupInstance = this.dynamicFormService.getGroupComponentInstance(key);
                // console.log('11 visibility check: ' + key + ' ' + value === condition.comparator);
                targetGroupInstance.visibilityCheck(value === condition.comparator);
                if (value === condition.comparator) {
                  this.setupCrossGroupsConditions(callerGroupInstance, targetGroupInstance);
                } else
                  this.removeCrossGroupsConditions(callerGroupInstance, targetGroupInstance);
              // delete all the others
              } else if (value !== condition.comparator) {
                this.dynamicFormService.removeDateConditionKey(key);
                this.dynamicFormService.getGroupComponentInstance(key).deleteDfGroup();
              }
            }
          }, 0);
      });
    }
  }

  public REPEAT_MATCH(caller: any, value: any, condition: any) {
    if (!isNullOrUndefined(value)) {
      const callerGroupInstance = this.dynamicFormService.getGroupComponentInstance(caller);
      // add new target group
      if (value === condition.comparator) {
        const targetGroupInstanceId = (Number(caller.substr(0, caller.indexOf('#'))) + 1) + caller.substr(caller.indexOf('#'), caller.length);
        let targetGroupInstance = this.dynamicFormService.getGroupComponentInstance(targetGroupInstanceId);
        if (isNullOrUndefined(targetGroupInstance)) {
          targetGroupInstance = callerGroupInstance.addDfGroup();
        }
        window.setTimeout(() => {
          this.setupCrossGroupsConditions(callerGroupInstance, targetGroupInstance);
        }, 0);
      // delete all groups with higher repeat ID
      } else {
        const callerRepeatId = Number(caller.substr(0, caller.indexOf('#')));
        // iterate through all groups
        this.dynamicFormService.getAllGroupComponentsInstance().forEach((instance, key) => {
          setTimeout(() => {
            // process only the repeated target group for given condition
            if (_.endsWith(key, '#' + instance.checkId + '-' + condition.targetGroup.id)) {
              // delete group with higher repeat ID
              const keyRepeatId = Number(key.substr(0, key.indexOf('#')));
              if (keyRepeatId > callerRepeatId) {
                const targetGroupInstance = this.dynamicFormService.getGroupComponentInstance(key);
                this.removeCrossGroupsConditions(callerGroupInstance, targetGroupInstance);
                targetGroupInstance.deleteDfGroup();
              }
            }
          }, 0);
        });
      }
    }
  }

  public EMPLOYMENT_HISTORY_MORE(caller: any, value: any, condition: any) {
    if (!isNullOrUndefined(value) && value !== '') {
      const callerGroupInstance = this.dynamicFormService.getGroupComponentInstance(caller);

      // show or hide gap
      callerGroupInstance.group.groups.forEach((group) => {
        if (group.id === condition.targetGroup.id) {
          let instanceId = '';
          if (callerGroupInstance.formGroupId.indexOf('#') >= 0) {
            instanceId = callerGroupInstance.formGroupId.substr(0, callerGroupInstance.formGroupId.indexOf('#')) + '#';
          }
          instanceId = instanceId + callerGroupInstance.checkId + '-' + group.id;
          const instance = this.dynamicFormService.getGroupComponentInstance(instanceId);
          if (value === condition.comparator) {
            const conditionStartToEndDate = this.groupsStartToEndDate(callerGroupInstance, caller);
            // console.log('12 visibility check: ' + instanceId + ' ' + true);
            if (instance.group.groupName.includes('-')) {
              instance.group.groupName = instance.group.groupName.split('-')[0];
            }
            if (conditionStartToEndDate) {
              instance.group.groupName = `${instance.group.groupName} - ${conditionStartToEndDate}`;
            }
            instance.visibilityCheck(true);
          } else {
            instance.formGroup.reset();
            // console.log('13 visibility check: ' + instanceId + ' ' + false);
            instance.visibilityCheck(false);
          }
        }
      });

      // add new employment
      if (value !== condition.comparator) {
        // current employment -> previous
        const callerMinYearGroupCondition = this.getCallerMinYearGroupCondition(callerGroupInstance);
        if (!isNullOrUndefined(callerMinYearGroupCondition)) {
          setTimeout(() => {
            const checkId = caller.substring(0, caller.indexOf('-'));
            const groupId = `1#${checkId}-${callerMinYearGroupCondition.targetGroup.id}`;
            const targetGroupInstance = this.dynamicFormService.getGroupComponentInstance(groupId);
            // console.log('14 visibility check: ' + groupId + ' ' + true);
            targetGroupInstance.visibilityCheck(true);
          }, 0);
        }

        // another previous employment
        const callerMinYearsCondition = this.getCallerMinYearsCondition(callerGroupInstance);
        if (!isNullOrUndefined(callerMinYearsCondition)) {
          setTimeout(() => {
            const targetGroupInstanceId = (Number(caller.substr(0, caller.indexOf('#'))) + 1) + caller.substr(caller.indexOf('#'), caller.length);
            const targetGroupInstance = this.dynamicFormService.getGroupComponentInstance(targetGroupInstanceId);
            if (isNullOrUndefined(targetGroupInstance)) {
              callerGroupInstance.addDfGroup();
            }
          }, 0);
        }
      // delete all previous employments with higher repeat ID
      } else {
        const callerMinYearGroupCondition = this.getCallerMinYearGroupCondition(callerGroupInstance);
        let previousEmployment = caller;
        if (!isNullOrUndefined(callerMinYearGroupCondition)) {
          const checkId = caller.substring(0, caller.indexOf('-'));
          previousEmployment = `1#${checkId}-${callerMinYearGroupCondition.targetGroup.id}`;
        }

        const callerRepeatId = Number(previousEmployment.substr(0, previousEmployment.indexOf('#')));

        // iterate through all groups
        const groupsDesc = new Map(Array.from(this.dynamicFormService.getAllGroupComponentsInstance().entries()).sort().reverse());
        groupsDesc.forEach((instance, key) => {
          setTimeout(() => {
            // process only the repeated target group for previous employment
            if (_.endsWith(key, previousEmployment.substr(previousEmployment.indexOf('#'), previousEmployment.length))) {
              // delete group with higher repeat ID
              const keyRepeatId = Number(key.substr(0, key.indexOf('#')));
              if (keyRepeatId > callerRepeatId) {
                const targetGroupInstance = this.dynamicFormService.getGroupComponentInstance(key);
                this.dynamicFormService.removeDateConditionKey(targetGroupInstance.formGroupId);
                targetGroupInstance.deleteDfGroup();
              }
            }
          }, 0);
        });

        // hide first previous employment if needed
        setTimeout(() => {
          if (!isNullOrUndefined(callerMinYearGroupCondition)) {
            const targetGroupInstance = this.dynamicFormService.getGroupComponentInstance(previousEmployment);
            if (!isNullOrUndefined(targetGroupInstance)) {
              // console.log('15 visibility check: ' + targetGroupInstance.formGroupId + ' ' + false);
              targetGroupInstance.visibilityCheck(false);
              this.dynamicFormService.removeDateConditionKey(targetGroupInstance.formGroupId);
              const dateStartAttributeId = this.formGroupId + '-' + callerMinYearGroupCondition.dateStartAttribute.id;
              const dateStartAttribute = this.dynamicFormService.getAttributeComponentInstance(dateStartAttributeId) as DateAttribute;
              dateStartAttribute.DETECT_GAPS(dateStartAttributeId);
            }
          }

          const callerMinYearsCondition = this.getCallerMinYearsCondition(callerGroupInstance);
          if (!isNullOrUndefined(callerMinYearsCondition)) {
            const dateStartAttributeId = callerGroupInstance.formGroupId + '-' + callerMinYearsCondition.dateStartAttribute.id;
            const dateStartAttribute = this.dynamicFormService.getAttributeComponentInstance(dateStartAttributeId) as DateAttribute;
            dateStartAttribute.DETECT_GAPS(dateStartAttributeId);
          }
        }, 0);
      }
    }
  }

  public CURRENT_EMPLOYMENT_STATUS(caller: any, value: any, condition) {
    if (!isNullOrUndefined(value) && value !== '') {
      const displayCondition = (value === condition.comparator);

      if (displayCondition) {
        // Clear previous dates condition set
        this.dynamicFormService.removeAllDateConditionValues();

        // Clear detect gap group already displayed
        this.dynamicFormService.getGroupComponentInstance(caller).group.conditions.forEach((condition) => {
          if (condition.fn === DF_VALIDATION_FN.DETECT_GAPS) {
            const instanceId = this.dynamicFormService.getGroupComponentInstance(caller).checkId + "-" + condition.targetGroup.id;
            const gapGroup = this.dynamicFormService.getGroupComponentInstance(instanceId);
            gapGroup && gapGroup.visibilityCheck(false);
          }
        });

        // Set date for current employment status if user has never been employed
        const attrId = caller + "-" + this.data.id;
        this.dynamicFormService.setDateConditionValue(
          caller,
          attrId,
          this.getTempororaryPastOrFutureDate(-7, "day"),
          "",
          this.getTempororaryPastOrFutureDate(0, "day"),
          7
        );
      }

      this.dynamicFormService.getAllGroupComponentsInstance().forEach((instance, key) => {
        const targetGroup = condition.targetGroup && instance.group.id === condition.targetGroup.id;
        const valueIssYes = value === 'Yes';
        const valueIsNo = value === 'No'
        const comparatorNeverEmployed = condition.comparator === "I've never been employed";

        if (targetGroup) {
          if (valueIssYes) {
            if (comparatorNeverEmployed) {
              instance.visibilityCheck(false);
            } else {
              if (displayCondition || instance.isVisible) {
                instance.visibilityCheck(true);
              }
            }
          } else if (valueIsNo) {
            if (condition.comparator !== "No") {
              instance.visibilityCheck(false);
            } else {
              instance.visibilityCheck(true);
            }
          } else {
            if (!comparatorNeverEmployed) {
              instance.visibilityCheck(false);
            } else {
              instance.visibilityCheck(true);
            }
          }
        }
      });

    }
  }

  private yearsHistoryCheck(years: number, customMostEarlyDate?: number) {
    let diff = 0;
    const allDates = _.cloneDeep(this.dynamicFormService.getAllDateConditionValues());
    const allDatesKeys = Array.from(allDates.keys());
    let mostRecentDate;
    let mostEarlyDate;
    if (allDatesKeys.length > 0) {
      mostRecentDate = allDates.get(allDatesKeys[0]).dateEndValue.date;
      mostEarlyDate = customMostEarlyDate ? customMostEarlyDate : allDates.get(allDatesKeys[allDatesKeys.length - 1]).dateStartValue.date;
      diff = mostRecentDate.year - mostEarlyDate.year;
      if (mostEarlyDate.month > mostRecentDate.month)
        diff--;
      else if (mostEarlyDate.month === mostRecentDate.month && mostEarlyDate.day > mostRecentDate.day)
        diff--;
    }
    return diff < years;
  }

  private groupsStartToEndDate(callerGroupInstance: any, caller: any) {
    const dates = _.cloneDeep(this.dynamicFormService.getAllDateConditionValues());
    let groupsRecentDate;
    let conditionStartDate;

    if (dates && dates.get(caller)) {
      groupsRecentDate = dates.get(caller).dateStartValue.formatted;
    }

    if (!isNullOrUndefined(callerGroupInstance.group.conditions)) {
      for (let groupCondition of callerGroupInstance.group.conditions) {
        if (groupCondition.fn === DF_VALIDATION_FN.MIN_YEARS || groupCondition.fn === DF_VALIDATION_FN.MIN_YEAR_GROUP) {
          const currentDate = new Date();
          conditionStartDate = new Date(currentDate.getFullYear() - groupCondition.comparator, currentDate.getMonth(), currentDate.getDate())
        }
      }
    }

    return  conditionStartDate && groupsRecentDate ? `${formatDate(conditionStartDate)} to ${groupsRecentDate}` : null;
  }

  private setupCrossGroupsConditions(callerGroupInstance: any, targetGroupInstance: any) {
    // check if caller and target want to detect gaps
    const callerCondition = this.getConditionWithStartAndEndDates(callerGroupInstance);
    const targetCondition = this.getConditionWithStartAndEndDates(targetGroupInstance);
    if (!callerCondition || !targetCondition)
      return;

    // init caller
    const callerStartDateAttributeId = callerGroupInstance.formGroupId + '-' + callerCondition.dateStartAttribute.id;
    const callerStartDateAttributeInstance = this.dynamicFormService.getAttributeComponentInstance(callerStartDateAttributeId) as DateAttribute;

    // init target
    const targetEndDateAttributeId = targetGroupInstance.formGroupId + '-' + targetCondition.dateEndAttribute.id;
    const targetEndDateAttributeInstance = this.dynamicFormService.getAttributeComponentInstance(targetEndDateAttributeId) as DateAttribute;

    // make caller start date to start when target end date finishes
    callerStartDateAttributeInstance.setEndDateProperties(targetEndDateAttributeId);

    // make target end date to finish when caller start date starts
    targetEndDateAttributeInstance.setStartDateProperties(callerStartDateAttributeId);
  }

  private removeCrossGroupsConditions(callerGroupInstance: any, targetGroupInstance: any) {
    if (!!callerGroupInstance)
      this.dynamicFormService.removeDateConditionKey(callerGroupInstance.formGroupId);
    if (!!targetGroupInstance)
      this.dynamicFormService.removeDateConditionKey(targetGroupInstance.formGroupId);

    // check if caller and target want to detect gaps
    const callerCondition = this.getConditionWithStartAndEndDates(callerGroupInstance);
    const targetCondition = this.getConditionWithStartAndEndDates(targetGroupInstance);
    if (!callerCondition || !targetCondition)
      return;

    // remove condition which requires to be "since target's end date"
    const callerStartDateAttributeId = callerGroupInstance.formGroupId + '-' + callerCondition.dateStartAttribute.id;
    const callerStartDateAttributeInstance = this.dynamicFormService.getAttributeComponentInstance(callerStartDateAttributeId) as DateAttribute;
    callerStartDateAttributeInstance.setEndDateProperties(null);

    // remove condition which requires to be "until caller's start date"
    const targetEndDateAttributeId = targetGroupInstance.formGroupId + '-' + targetCondition.dateEndAttribute.id;
    const targetEndDateAttributeInstance = this.dynamicFormService.getAttributeComponentInstance(targetEndDateAttributeId) as DateAttribute;
    if (targetEndDateAttributeInstance)
      targetEndDateAttributeInstance.setStartDateProperties(null);
  }

  private getConditionWithStartAndEndDates(groupInstance: any) {
    return groupInstance
      && groupInstance.group
      && groupInstance.group.conditions
      && groupInstance.group.conditions.find(c => c.fn === DF_VALIDATION_FN.DETECT_GAPS
        || c.fn === DF_VALIDATION_FN.SUBSEQUENT_DATES_SINCE
        || c.fn === DF_VALIDATION_FN.SUBSEQUENT_DATES);
  }

  private getCallerMinYearGroupCondition(callerGroupInstance: any) {
    return callerGroupInstance
      && callerGroupInstance.group
      && callerGroupInstance.group.conditions
      && callerGroupInstance.group.conditions.find(c => c.fn === DF_VALIDATION_FN.MIN_YEAR_GROUP);
  }

  private getCallerMinYearsCondition(callerGroupInstance: any) {
    return callerGroupInstance
      && callerGroupInstance.group
      && callerGroupInstance.group.conditions
      && callerGroupInstance.group.conditions.find(c => c.fn === DF_VALIDATION_FN.MIN_YEARS);
  }

  private getCallerEmploymentHistoryMoreCondition(attribute: any) {
    return attribute
      && attribute.conditions
      && attribute.conditions.find(c => c.fn === DF_VALIDATION_FN.EMPLOYMENT_HISTORY_MORE);
  }

  private getCallerEmploymentHistoryMoreAttribute(callerGroupInstance: any) {
    return callerGroupInstance
      && callerGroupInstance.group
      && callerGroupInstance.group.attributes
      && callerGroupInstance.group.attributes.find(a => a.conditions && a.conditions.find(c => c.fn === DF_VALIDATION_FN.EMPLOYMENT_HISTORY_MORE));
  }

  private hasCallerEmploymentHistoryMoreAttribute(callerGroupInstance: any): boolean {
    return !isNullOrUndefined(this.getCallerEmploymentHistoryMoreAttribute(callerGroupInstance));
  }

  private showOrHideMoreEmploymentQuestion(groupInstance, show) {
    const attr = this.getCallerEmploymentHistoryMoreAttribute(groupInstance);
    const attrId = groupInstance.formGroupId + '-' + attr.id;
    const radioAttr = this.dynamicFormService.getAttributeComponentInstance(attrId) as RadioAttribute;
    if (radioAttr) {
      radioAttr.show(show, this.isMoreEmploymentQuestionDisabled(attrId));
      if (!show) {
        // hide gap as whole question is about to be hidden
        const employmentHistoryMoreCondition = this.getCallerEmploymentHistoryMoreCondition(attr);
        this.dynamicFormService.getAllGroupComponentsInstance().forEach((instance) => {
          setTimeout(() => {
            if (instance.group.id === employmentHistoryMoreCondition.targetGroup.id) {
              instance.formGroup.reset();
              // console.log('17 visibility check: ' + instance.group.id + ' ' + false);
              instance.visibilityCheck(false);
            }
          }, 0);
        });
      }
    }
  }

  private isMoreEmploymentQuestionDisabled(attributeId: string): boolean {
    if (!this.dynamicFormService.dynamicFormData)
      return false;

    // disable question explicitly if screening returned but the question is not returned
    const node = this.dynamicFormService.dynamicFormData.find(data => data.nodeId === attributeId);
    return this.dynamicFormService.dynamicForm.screeningStatus === 'RETURNED' && node && !node.returned;
  }

  public isVerified(): boolean {
    return isVerified(this.formControl.value, this.verifiedValue);
  }

  /**
   * Returns temporary date.
   *
   * @param num - negative number for past. positive for future
   * @param unit - day, month or year of num.
   */
  private getTempororaryPastOrFutureDate(num: number, unit: "day" | "month" | "year") {
    const date: any = new Date();
    if (unit === "day") {
      date.setDate(date.getDate() + num);
    } else if (unit === "month") {
      date.setMonth(date.getMonth() + num);
    } else if (unit === "year") {
      date.setFullYear(date.getFullYear() + num);
    }
    return {
      date: {
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDate(),
      },
      jsdate: date,
      formatted: formatDate(date),
      epoc: date.getTime(),
    } as IMyDateModel;
  }
}
