import { GtCustomComponent, GtRow } from '@angular-generic-table/core';
import { Component, ElementRef, Input, OnInit, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { ToastyService } from 'ng2-toasty';
import { HttpService } from '../../../shared-services/http.service';
import { environment } from '../../../../environments/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TableService } from '../../services/table.service';

@Component({
  selector: 'xavier-options-dropdown',
  templateUrl: './options-dropdown.component.html',
  styleUrls: ['./options-dropdown.component.sass'],
})
export class OptionsDropdownComponent extends GtCustomComponent<GtRow>  {

  @Input() public row;
  @ViewChild('menuList') menuLists: ElementRef;
  @ViewChild('cancelScreeningModal', { static: true }) cancelScreeningModal: TemplateRef<any>;
  public dropdownOpened: boolean = false;
  public modalRef: BsModalRef;

  private modalConfig = { animated: true};

  constructor(
    private toastyService: ToastyService,
    private httpService: HttpService,
    private modalService: BsModalService,
    private tableService: TableService
  ) {
    super();
  }

  toggleDropdown(): void {
    const el = document.querySelectorAll('.dropdown-menu-list');
    el.forEach(e => {
      if (e.classList.contains('show')) {
        e.classList.remove('show');
      }
    });

    if (!this.dropdownOpened) {
      this.menuLists.nativeElement.classList.add('show');
      this.dropdownOpened = true;
    } else {
      this.menuLists.nativeElement.classList.remove('show');
      this.dropdownOpened = false;
    }
  }

  copyScreeningLink(): void{
    const screeningLink = `${environment.dbsUiUrl}/screening/${this.row.id}`
    navigator.clipboard.writeText(screeningLink);
    this.toastyService.info('Screening link has been copied to clipboard');
  }

  cancelScreening(): void {
    this.modalRef = this.modalService.show(this.cancelScreeningModal, this.modalConfig);
  }

  closeModal() {
    this.modalRef.hide();
  }

  confirmDeleteScreening(): void {
    this.httpService.delete(`/_ah/api/screeningOrder/v1`, `deleteScreeningOrder/${this.row.id}`, '').subscribe(result => {
      if (result) {
        this.toastyService.info(result);
        this.tableService.changeGetTableDataValue(true);
      } else {
        this.toastyService.error('Screening order cannot be deleted');
      }
      this.modalRef.hide();
    }, error => {
      this.modalRef.hide();

      this.toastyService.error('Screening order cannot be deleted');
    })
  }
}
