import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {DynamicFormService} from "../../services/dynamic-form.service";
import {ReferenceFormService} from "../../services/reference-form.service";
import {ToastyService} from "ng2-toasty";
import {UserAuth} from "../../../../core/userAuth.core";
import {FileuploadAttribute} from "../dynamic-form/model/fileupload/fileupload.component";
import {SignatureAttribute} from "../dynamic-form/model/signature/signature.component";
import {DateAttribute} from "../dynamic-form/model/date/date.component";
import {noticeToasterOptions} from "../../../../data/variables.data";
import {addToaster, getToasterMessagesLabel} from "../../../utils/functions.utils";
import {isNullOrUndefined} from "util";
import {fadeIn} from "../../../../data/animations.data";
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'xavier-candidate-reference-form',
  templateUrl: 'candidate-reference-form.component.html',
  styleUrls: ['candidate-reference-form.stylesheet.sass', 'candidate-reference-form-mediaqueries.stylesheet.sass'],
  animations: [fadeIn]
})

export class CandidateReferenceFormComponent implements OnInit, OnDestroy {

  @Input() public loading: boolean = true;
  @Input() public showForm: boolean = false;
  @Input() public submitted: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public wrongToken: boolean = false;
  @Input() public referenceForm: any;

  public referenceId;

  private routeSub: any;

  constructor(private activatedRoute: ActivatedRoute, private dynamicFormService: DynamicFormService,
              private userAuth: UserAuth, private referenceFormService: ReferenceFormService,
              private toastyService: ToastyService) {
    this.routeSub = this.activatedRoute.params.subscribe(params => {
      this.referenceId = params['id'];
    });
  }

  ngOnInit() {
    this.referenceFormService.getReferenceForm(this.referenceId).subscribe( response => {
      if (!isNullOrUndefined(response.checkName)) {
        this.referenceForm = response;
        this.userAuth.referenceFormCandidateNameNotification(this.referenceForm.candidateName);

        // finally set the loading overlay to false, show the content
        this.loading = false;
        this.showForm = true;
      }
    }, () => {
      this.loading = false;
      this.wrongToken = true;
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  submitResponse() {
    let isValid = true;
    let values = [];
    let allGroups = this.dynamicFormService.getAllFormGroups();
    allGroups.forEach(( function(group, index) {
      if (!isValid)
        return false;

      if (Object.keys(group.controls).length > 0) {
        if (!this.dynamicFormService.getGroupComponentInstance(index).isVisible) {
          allGroups.delete(index);
          this.dynamicFormService.removeFormGroup(index);
          return false;
        }

        if (!group.valid) {
          isValid = false;
          return false;
        }
        if (group.pristine && group.untouched)
          return false;

        _.forEach(group.value, (function (val, index) {
          let attribute = this.dynamicFormService.getAttributeComponentInstance(index);
          if (attribute instanceof FileuploadAttribute) {
            values.push({
              nodeId: index,
              file: attribute.getValue()
            });
          } else if (attribute instanceof SignatureAttribute) {
            values.push({
              nodeId: index,
              signature: val
            });
          } else if (attribute instanceof DateAttribute && !isNullOrUndefined(val) && val.hasOwnProperty('jsdate')) {// datepicker instance
            values.push({
              nodeId: index,
              value: val.jsdate.getFullYear() + '-' + ('0' + (val.jsdate.getMonth() + 1)).slice(-2) + '-' + ('0' + val.jsdate.getDate()).slice(-2)
            });
          } else {
            values.push({nodeId: index, value: val});
          }
        }).bind(this));
      } else {
        allGroups.delete(index);
        this.dynamicFormService.removeFormGroup(index);
      }
    }).bind(this));

    if (!isValid) {
      allGroups.forEach((function(group, index) {
        _.forEach(group.controls, ( function(control, nodeId) {
          if (control.invalid && control.untouched && this.dynamicFormService.getGroupComponentInstance(index).isVisible) {
            let attribute = this.dynamicFormService.getAttributeComponentInstance(nodeId);
            if (attribute instanceof FileuploadAttribute) {
              let fileDropZone = document.getElementById('fileDropArea_' + nodeId);
              if (!isNullOrUndefined(fileDropZone))
                fileDropZone.classList.add('required');
            }
            if (attribute instanceof SignatureAttribute) {
              let signatureWrapper = document.getElementById('canvas-wrapper_' + nodeId);
              if (!isNullOrUndefined(signatureWrapper))
                signatureWrapper.classList.add('required');
            }
            control.markAsTouched({onlySelf: true});
          }
        }).bind(this));
      }).bind(this));
      window.scrollTo(0, 0);
      let config = noticeToasterOptions;
      config.msg = getToasterMessagesLabel('validationWarning', 'notices');
      this.toastyService.warning(config);
    } else {
      this.disabled = true;
      let submitCheck = document.getElementById('submitCheck');
      const spinnerWrap = document.createElement('div');
      spinnerWrap.classList.add('spinner-wrap');
      spinnerWrap.classList.add('button');

      submitCheck.innerHTML = '';
      submitCheck.appendChild(spinnerWrap);

      this.referenceFormService.saveReferenceData({'values': values}, this.referenceId)
      .subscribe(() => {
        window.scrollTo(0, 0);
      }, err => {
        this.disabled = false;
        submitCheck.innerHTML = 'Submit Response';
        let action = 'serverError';
        if (err.status === 403)
          action = '403';
        addToaster('error', this.toastyService, action, 'errors');
      }, () => {
        this.disabled = false;
        submitCheck.innerHTML = 'Submit Response';
        this.showForm = false;
        this.submitted = true;
      });
    }
  }

}
