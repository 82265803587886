<ng2-toasty [position]="'top-center'" class="xavier-toasty"></ng2-toasty>

<div class="xavier-add-candidate-wrapper container" [@fadeIn]>
  <div class="row header">
    <div class="col-11">
      <h1>Add New Candidate</h1>
    </div>
    <div class="col-1 text-right">
      <button type="button" class="close close-candidate" aria-label="Close" (click)="cancelAddCandidate()">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>

  <form [formGroup]="form" novalidate>
    <div class="row">
      <div class="col-sm-12 col-xl-3">
        <h4>Personal Details</h4>
      </div>
      <div class="col-sm-12 col-xl-4">
        <div class="form-group xavier-input-text">
          <label for="firstName">First Name(s)</label>
          <input type="text" class="form-control" id="firstName" formControlName="firstName" placeholder="First Name(s)" autocomplete="one-time-code">
          <xavier-error-message [control]="form.get('firstName')"></xavier-error-message>
        </div>
      </div>
      <div class="col-sm-12 col-xl-4">
        <div class="form-group xavier-input-text">
          <label for="lastName">Last Name</label>
          <input type="text" class="form-control" id="lastName" formControlName="lastName" placeholder="Last Name" autocomplete="one-time-code">
          <xavier-error-message [control]="form.get('lastName')"></xavier-error-message>
        </div>
      </div>
      <div class="col-sm-12 col-xl-3"></div>
      <div class="col-sm-12 col-xl-4">
        <div class="form-group xavier-input-text">
          <label for="email">Email</label>
          <input type="email" class="form-control" id="email" formControlName="email" placeholder="Email" autocomplete="one-time-code">
          <xavier-error-message [control]="form.get('email')"></xavier-error-message>
        </div>
      </div>
      <div class="col-sm-12 col-xl-4">
        <div class="form-group xavier-input-text">
          <div>
            <label for="phonePrefix">Mobile Number</label>
          </div>
          <div style="display: flex;">
            <select class="xavier-tel-combo-select" id="phonePrefix" formControlName="phonePrefix">
              <option data-countryCode="GB" value="+44" selected>+44</option>
              <option data-countryCode="US" value="+1">+1</option>
              <option data-countryCode="EG" value="+20">+20</option>
              <option data-countryCode="ZA" value="+27">+27</option>
              <option data-countryCode="GR" value="+30">+30</option>
              <option data-countryCode="NL" value="+31">+31</option>
              <option data-countryCode="BE" value="+32">+32</option>
              <option data-countryCode="FR" value="+33">+33</option>
              <option data-countryCode="ES" value="+34">+34</option>
              <option data-countryCode="HU" value="+36">+36</option>
              <option data-countryCode="IT" value="+39">+39</option>
              <option data-countryCode="RO" value="+40">+40</option>
              <option data-countryCode="CH" value="+41">+41</option>
              <option data-countryCode="AT" value="+43">+43</option>
              <option data-countryCode="DK" value="+45">+45</option>
              <option data-countryCode="SE" value="+46">+46</option>
              <option data-countryCode="NO" value="+47">+47</option>
              <option data-countryCode="PL" value="+48">+48</option>
              <option data-countryCode="DE" value="+49">+49</option>
              <option data-countryCode="PE" value="+51">+51</option>
              <option data-countryCode="MX" value="+52">+52</option>
              <option data-countryCode="CU" value="+53">+53</option>
              <option data-countryCode="AR" value="+54">+54</option>
              <option data-countryCode="BR" value="+55">+55</option>
              <option data-countryCode="CL" value="+56">+56</option>
              <option data-countryCode="CO" value="+57">+57</option>
              <option data-countryCode="VE" value="+58">+58</option>
              <option data-countryCode="MY" value="+60">+60</option>
              <option data-countryCode="AU" value="+61">+61</option>
              <option data-countryCode="ID" value="+62">+62</option>
              <option data-countryCode="PH" value="+63">+63</option>
              <option data-countryCode="NZ" value="+64">+64</option>
              <option data-countryCode="SG" value="+65">+65</option>
              <option data-countryCode="TH" value="+66">+66</option>
              <option data-countryCode="JP" value="+81">+81</option>
              <option data-countryCode="KR" value="+82">+82</option>
              <option data-countryCode="VN" value="+84">+84</option>
              <option data-countryCode="CN" value="+86">+86</option>
              <option data-countryCode="TR" value="+90">+90</option>
              <option data-countryCode="CY" value="+90">+90</option>
              <option data-countryCode="IN" value="+91">+91</option>
              <option data-countryCode="PK" value="+92">+92</option>
              <option data-countryCode="LK" value="+94">+94</option>
              <option data-countryCode="MN" value="+95">+95</option>
              <option data-countryCode="IR" value="+98">+98</option>
              <option data-countryCode="MA" value="+212">+212</option>
              <option data-countryCode="DZ" value="+213">+213</option>
              <option data-countryCode="TN" value="+216">+216</option>
              <option data-countryCode="LY" value="+218">+218</option>
              <option data-countryCode="GM" value="+220">+220</option>
              <option data-countryCode="SN" value="+221">+221</option>
              <option data-countryCode="MR" value="+222">+222</option>
              <option data-countryCode="ML" value="+223">+223</option>
              <option data-countryCode="GN" value="+224">+224</option>
              <option data-countryCode="BF" value="+226">+226</option>
              <option data-countryCode="NE" value="+227">+227</option>
              <option data-countryCode="TG" value="+228">+228</option>
              <option data-countryCode="BJ" value="+229">+229</option>
              <option data-countryCode="LR" value="+231">+231</option>
              <option data-countryCode="SL" value="+232">+232</option>
              <option data-countryCode="GH" value="+233">+233</option>
              <option data-countryCode="NG" value="+234">+234</option>
              <option data-countryCode="CF" value="+236">+236</option>
              <option data-countryCode="CM" value="+237">+237</option>
              <option data-countryCode="CV" value="+238">+238</option>
              <option data-countryCode="ST" value="+239">+239</option>
              <option data-countryCode="GQ" value="+240">+240</option>
              <option data-countryCode="GA" value="+241">+241</option>
              <option data-countryCode="CG" value="+242">+242</option>
              <option data-countryCode="AO" value="+244">+244</option>
              <option data-countryCode="GW" value="+245">+245</option>
              <option data-countryCode="SC" value="+248">+248</option>
              <option data-countryCode="SD" value="+249">+249</option>
              <option data-countryCode="RW" value="+250">+250</option>
              <option data-countryCode="ET" value="+251">+251</option>
              <option data-countryCode="SO" value="+252">+252</option>
              <option data-countryCode="DJ" value="+253">+253</option>
              <option data-countryCode="KE" value="+254">+254</option>
              <option data-countryCode="UG" value="+256">+256</option>
              <option data-countryCode="BI" value="+257">+257</option>
              <option data-countryCode="MZ" value="+258">+258</option>
              <option data-countryCode="ZM" value="+260">+260</option>
              <option data-countryCode="MG" value="+261">+261</option>
              <option data-countryCode="RE" value="+262">+262</option>
              <option data-countryCode="ZW" value="+263">+263</option>
              <option data-countryCode="NA" value="+264">+264</option>
              <option data-countryCode="MW" value="+265">+265</option>
              <option data-countryCode="LS" value="+266">+266</option>
              <option data-countryCode="BW" value="+267">+267</option>
              <option data-countryCode="SZ" value="+268">+268</option>
              <option data-countryCode="KM" value="+269">+269</option>
              <option data-countryCode="YT" value="+269">+269</option>
              <option data-countryCode="SH" value="+290">+290</option>
              <option data-countryCode="ER" value="+291">+291</option>
              <option data-countryCode="AW" value="+297">+297</option>
              <option data-countryCode="FO" value="+298">+298</option>
              <option data-countryCode="GL" value="+299">+299</option>
              <option data-countryCode="GI" value="+350">+350</option>
              <option data-countryCode="PT" value="+351">+351</option>
              <option data-countryCode="LU" value="+352">+352</option>
              <option data-countryCode="IE" value="+353">+353</option>
              <option data-countryCode="IS" value="+354">+354</option>
              <option data-countryCode="MT" value="+356">+356</option>
              <option data-countryCode="CY" value="+357">+357</option>
              <option data-countryCode="FI" value="+358">+358</option>
              <option data-countryCode="BG" value="+359">+359</option>
              <option data-countryCode="LT" value="+370">+370</option>
              <option data-countryCode="LV" value="+371">+371</option>
              <option data-countryCode="EE" value="+372">+372</option>
              <option data-countryCode="MD" value="+373">+373</option>
              <option data-countryCode="AM" value="+374">+374</option>
              <option data-countryCode="BY" value="+375">+375</option>
              <option data-countryCode="AD" value="+376">+376</option>
              <option data-countryCode="MC" value="+377">+377</option>
              <option data-countryCode="SM" value="+378">+378</option>
              <option data-countryCode="VA" value="+379">+379</option>
              <option data-countryCode="UA" value="+380">+380</option>
              <option data-countryCode="CS" value="+381">+381</option>
              <option data-countryCode="HR" value="+385">+385</option>
              <option data-countryCode="SI" value="+386">+386</option>
              <option data-countryCode="BA" value="+387">+387</option>
              <option data-countryCode="MK" value="+389">+389</option>
              <option data-countryCode="LI" value="+417">+417</option>
              <option data-countryCode="CZ" value="+420">+420</option>
              <option data-countryCode="SK" value="+421">+421</option>
              <option data-countryCode="FK" value="+500">+500</option>
              <option data-countryCode="BZ" value="+501">+501</option>
              <option data-countryCode="GT" value="+502">+502</option>
              <option data-countryCode="SV" value="+503">+503</option>
              <option data-countryCode="HN" value="+504">+504</option>
              <option data-countryCode="NI" value="+505">+505</option>
              <option data-countryCode="CR" value="+506">+506</option>
              <option data-countryCode="PA" value="+507">+507</option>
              <option data-countryCode="HT" value="+509">+509</option>
              <option data-countryCode="GP" value="+590">+590</option>
              <option data-countryCode="BO" value="+591">+591</option>
              <option data-countryCode="GY" value="+592">+592</option>
              <option data-countryCode="EC" value="+593">+593</option>
              <option data-countryCode="GF" value="+594">+594</option>
              <option data-countryCode="PY" value="+595">+595</option>
              <option data-countryCode="MQ" value="+596">+596</option>
              <option data-countryCode="SR" value="+597">+597</option>
              <option data-countryCode="UY" value="+598">+598</option>
              <option data-countryCode="NP" value="+670">+670</option>
              <option data-countryCode="GU" value="+671">+671</option>
              <option data-countryCode="NF" value="+672">+672</option>
              <option data-countryCode="BN" value="+673">+673</option>
              <option data-countryCode="NR" value="+674">+674</option>
              <option data-countryCode="PG" value="+675">+675</option>
              <option data-countryCode="TO" value="+676">+676</option>
              <option data-countryCode="SB" value="+677">+677</option>
              <option data-countryCode="VU" value="+678">+678</option>
              <option data-countryCode="FJ" value="+679">+679</option>
              <option data-countryCode="PW" value="+680">+680</option>
              <option data-countryCode="WF" value="+681">+681</option>
              <option data-countryCode="CK" value="+682">+682</option>
              <option data-countryCode="NU" value="+683">+683</option>
              <option data-countryCode="KI" value="+686">+686</option>
              <option data-countryCode="NC" value="+687">+687</option>
              <option data-countryCode="TV" value="+688">+688</option>
              <option data-countryCode="PF" value="+689">+689</option>
              <option data-countryCode="FM" value="+691">+691</option>
              <option data-countryCode="MH" value="+692">+692</option>
              <option data-countryCode="KP" value="+850">+850</option>
              <option data-countryCode="HK" value="+852">+852</option>
              <option data-countryCode="MO" value="+853">+853</option>
              <option data-countryCode="KH" value="+855">+855</option>
              <option data-countryCode="LA" value="+856">+856</option>
              <option data-countryCode="BD" value="+880">+880</option>
              <option data-countryCode="TW" value="+886">+886</option>
              <option data-countryCode="MV" value="+960">+960</option>
              <option data-countryCode="LB" value="+961">+961</option>
              <option data-countryCode="JO" value="+962">+962</option>
              <option data-countryCode="SY" value="+963">+963</option>
              <option data-countryCode="IQ" value="+964">+964</option>
              <option data-countryCode="KW" value="+965">+965</option>
              <option data-countryCode="SA" value="+966">+966</option>
              <option data-countryCode="YE" value="+967">+967</option>
              <option data-countryCode="OM" value="+968">+968</option>
              <option data-countryCode="YE" value="+969">+969</option>
              <option data-countryCode="AE" value="+971">+971</option>
              <option data-countryCode="IL" value="+972">+972</option>
              <option data-countryCode="BH" value="+973">+973</option>
              <option data-countryCode="QA" value="+974">+974</option>
              <option data-countryCode="BT" value="+975">+975</option>
              <option data-countryCode="MN" value="+976">+976</option>
              <option data-countryCode="NP" value="+977">+977</option>
              <option data-countryCode="TJ" value="+992">+992</option>
              <option data-countryCode="TM" value="+993">+993</option>
              <option data-countryCode="AZ" value="+994">+994</option>
              <option data-countryCode="KG" value="+996">+996</option>
              <option data-countryCode="UZ" value="+998">+998</option>
              <option data-countryCode="BS" value="+1242">+1242</option>
              <option data-countryCode="BB" value="+1246">+1246</option>
              <option data-countryCode="AI" value="+1264">+1264</option>
              <option data-countryCode="AG" value="+1268">+1268</option>
              <option data-countryCode="KY" value="+1345">+1345</option>
              <option data-countryCode="BM" value="+1441">+1441</option>
              <option data-countryCode="GD" value="+1473">+1473</option>
              <option data-countryCode="TC" value="+1649">+1649</option>
              <option data-countryCode="MS" value="+1664">+1664</option>
              <option data-countryCode="SC" value="+1758">+1758</option>
              <option data-countryCode="PR" value="+1787">+1787</option>
              <option data-countryCode="DM" value="+1809">+1809</option>
              <option data-countryCode="DO" value="+1809">+1809</option>
              <option data-countryCode="TT" value="+1868">+1868</option>
              <option data-countryCode="KN" value="+1869">+1869</option>
              <option data-countryCode="JM" value="+1876">+1876</option>
              <option data-countryCode="GE" value="+7880">+7880</option>
              <option data-countryCode="CA" value="+1">CA+1</option>
              <option data-countryCode="VG" value="+1">VG+1</option>
              <option data-countryCode="VI" value="+1">VI+1</option>
              <option data-countryCode="RU" value="+7">+7</option>
              <option data-countryCode="KZ" value="+7">+7</option>
            </select>

            <input type="text" class="form-control xavier-tel-number" id="phone" formControlName="phone" placeholder="Mobile Number" autocomplete="one-time-code">
            <xavier-error-message [control]="form.get('phone')"></xavier-error-message>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-xl-6"></div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-xl-3">
        <h4>Company Details</h4>
      </div>
      <div class="col-sm-12 col-xl-4" *isAllowed="['CANDIDATE_NEW_ALL']">
        <div class="form-group xavier-input-text">
          <label>Company</label>
          <xavier-dropdown [itemSource]="companyItemSource" [itemSourceWithSearch]="true" [compareWith]="compareFn" placeholder="Search company…" formControlName="company">
            <ng-template let-item #itemTemplate>{{ item?.companyName }}</ng-template>
            <ng-template let-item #valueTemplate>{{ item?.companyName }}</ng-template>
          </xavier-dropdown>
        </div>
      </div>
      <div class="col-sm-12 col-xl-4">
        <div *ngIf="value.company" class="form-group xavier-input-text" [@fadeIn]>
          <label>Division</label>
          <xavier-dropdown [itemSource]="divisionItemSource" [compareWith]="compareFn" formControlName="division">
            <ng-template let-item #itemTemplate>{{ item?.divisionName }}</ng-template>
            <ng-template let-item #valueTemplate>{{ item?.divisionName }}</ng-template>
          </xavier-dropdown>
        </div>
      </div>
      <div *ngIf="companyHiddenPadding" class="col-sm-12 col-xl-4" [@fadeIn]></div>
      <div *ngIf="value.division" class="col-sm-12 col-xl-3" [@fadeIn]></div>
      <div *ngIf="value.division" class="col-sm-12 col-xl-4" [@fadeIn]>
        <div class="form-group xavier-input-text">
          <label for="positionApplied">Position Applied</label>
          <input type="text" class="form-control" id="positionApplied" formControlName="positionApplied" placeholder="Position Applied" autocomplete="one-time-code">
          <xavier-error-message [control]="form.get('positionApplied')"></xavier-error-message>
        </div>
      </div>
      <div *ngIf="value.division" class="col-sm-12 col-xl-4" [@fadeIn]>
        <div class="form-group xavier-input-text">
          <label for="purchaseOrderNumber">Purchase Order No. (Optional)</label>
          <input type="text" class="form-control" id="purchaseOrderNumber" formControlName="purchaseOrderNumber" placeholder="Purchase Order No." autocomplete="one-time-code">
          <xavier-error-message [control]="form.get('purchaseOrderNumber')"></xavier-error-message>
        </div>
      </div>
    </div>
    <div *isAllowed="['CANDIDATE_PERSONNEL', 'CANDIDATE_PERSONNEL_SELF']">
      <div *ngIf="value.division" class="row">
        <div class="col-sm-12 col-xl-3">
          <h4>Personnel Assignments</h4>
        </div>
        <div class="col-sm-12 col-xl-4">
          <div class="form-group xavier-input-text">
            <label for="lineManagerName">Line Manager (Optional)</label>
            <xavier-autocomplete-select-dropdown (dropdownEntrySelectedEmitter)="onDropdownEntrySelected($event, 'lineManagerName')"
                                                 [componentParams]="lineManagerSearchParams">
            </xavier-autocomplete-select-dropdown>
          </div>
        </div>
        <div class="col-sm-12 col-xl-4">
          <div class="form-group xavier-input-text">
            <label for="humanResourcesName">Human Resources (Optional)</label>
            <xavier-autocomplete-select-dropdown (dropdownEntrySelectedEmitter)="onDropdownEntrySelected($event, 'humanResourcesName')"
                                                 [componentParams]="humanResourcesSearchParams">
            </xavier-autocomplete-select-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="value.division" class="row">
      <div class="col-sm-12 col-xl-3">
        <h4>Screening Details</h4>
      </div>
      <div class="col-sm-12 col-xl-4">
        <div class="form-group xavier-input-text">
          <label for="screeningName">Screening</label>
            <xavier-dropdown [itemSource]="screeningItemSource" [compareWith]="compareFn" formControlName="screening">
              <ng-template let-item #itemTemplate>{{ item?.screeningName }}</ng-template>
              <ng-template let-item #valueTemplate>{{ item?.screeningName }}</ng-template>
            </xavier-dropdown>
        </div>
      </div>
      <div class="col-sm-12 col-xl-4" *isAllowed="['CANDIDATE_SCREENER_ASSIGN']">
        <div class="form-group xavier-input-text">
          <label for="screenerName">Screener (Optional)</label>
            <xavier-dropdown [itemSource]="screenerItemSource" [compareWith]="compareFn" formControlName="screener">
              <ng-template let-item #itemTemplate>{{ item?.fullName }}</ng-template>
              <ng-template let-item #valueTemplate>{{ item?.fullName }}</ng-template>
            </xavier-dropdown>
        </div>
      </div>
    </div>

    <ng-container *ngIf="displayIdVerification">
      <div class="row">
        <div class="col-sm-12 col-xl-3">
          <h4>ID Verification</h4>
        </div>
        <div class="col-sm-12 col-xl-4">
          <div class="form-group xavier-input-text">
            <label for="idVerifierName">ID Verifier</label>
              <xavier-dropdown [itemSource]="idVerifierItemSource" [compareWith]="compareFn" formControlName="idVerifier">
                <ng-template let-item #itemTemplate>{{ item?.fullName }}</ng-template>
                <ng-template let-item #valueTemplate>{{ item?.fullName }}</ng-template>
              </xavier-dropdown>
          </div>
        </div>
        <div class="col-sm-12 col-xl-5"></div>
        <div class="col-sm-12 col-xl-3"></div>

        <ng-container *ngIf="displayWorkAtHomeAddress">
          <div  class="col-sm-12 col-xl-8">
            <div class="form-group xavier-input-text">
              <label for="workAtHomeAddress">Does position involve working with children or adults at the applicant’s home address?</label>
              <app-radio-group name="workAtHomeAddress" id="workAtHomeAddress">
                <app-radio formControlName="workAtHomeAddress" [value]="true" label="Yes"></app-radio>
                <app-radio formControlName="workAtHomeAddress" [value]="false" label="No"></app-radio>
              </app-radio-group>
              <xavier-error-message [control]="form.get('workAtHomeAddress')"></xavier-error-message>
            </div>
          </div>
          <div class="col-sm-12 col-xl-3"></div>
        </ng-container>

        <div class="col-sm-12 col-xl-8">
          <div class="form-group">
            <input type="checkbox" class="form-check-input form-control" id="confirmTermsAndConditions"
              formControlName="confirmTermsAndConditions">
            <label for="confirmTermsAndConditions" class="form-check-label">Tick this box to confirm you have read these <a [routerLink]="['.']" (click)="openTermsAndCondition()">terms and conditons</a></label>
            <xavier-error-message [control]="form.get('confirmTermsAndConditions')"></xavier-error-message>
          </div>
        </div>
        <div class="col-sm-12 col-xl-3"></div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-xl-3">
          <h4>Certificate</h4>
        </div>
        <div class="col-sm-12 col-xl-8">
          <div class="form-group xavier-input-text">
            <label for="nameOfEmployerOnCertificate">Name of Employer (as you want it to appear on the certificate)</label>
            <input type="text" class="form-control" id="nameOfEmployerOnCertificate" formControlName="nameOfEmployerOnCertificate" placeholder="Name of Employer..." autocomplete="one-time-code">
            <xavier-error-message [control]="form.get('nameOfEmployerOnCertificate')"></xavier-error-message>
          </div>
        </div>
        <div class="col-sm-12 col-xl-3"></div>
        <div class="col-sm-12 col-xl-8">
          <div class="form-group xavier-input-text">
            <label for="receivePaperCertificate">Would you like to receive a paper DBS certificate?</label>
            <app-radio-group name="receivePaperCertificate" id="receivePaperCertificate">
              <app-radio formControlName="receivePaperCertificate" [value]="true" label="Yes"></app-radio>
              <app-radio formControlName="receivePaperCertificate" [value]="false" label="No"></app-radio>
            </app-radio-group>
            <xavier-error-message [control]="form.get('receivePaperCertificate')"></xavier-error-message>
          </div>
        </div>
        <ng-container *ngIf="control('receivePaperCertificate').value">
          <div class="col-sm-12 col-xl-3"></div>
          <div class="col-sm-12 col-xl-8" >
            <div class="form-group xavier-input-text">
              <label for="receivePaperCertificateAtCurrentAddress">Would you like to receive the paper certificate at the candidate’s current address?</label>
              <app-radio-group name="receivePaperCertificateAtCurrentAddress" id="receivePaperCertificateAtCurrentAddress">
                <app-radio formControlName="receivePaperCertificateAtCurrentAddress" [value]="true" label="Yes"></app-radio>
                <app-radio formControlName="receivePaperCertificateAtCurrentAddress" [value]="false" label="No"></app-radio>
              </app-radio-group>
              <xavier-error-message [control]="form.get('receivePaperCertificateAtCurrentAddress')"></xavier-error-message>
            </div>
          </div>
          <ng-container *ngIf="control('receivePaperCertificateAtCurrentAddress').value === false">
            <div class="col-sm-12 col-xl-3"></div>
            <div class="col-sm-12 col-xl-8">
              <div class="form-group xavier-input-text">
                <label for="addressToReceivePaperCertificate">Add the address to where the certificate needs to be sent.</label>
                <textarea class="form-control xavier-textarea" formControlName="addressToReceivePaperCertificate"
                  id="addressToReceivePaperCertificate" placeholder="Type address here..." rows="3" cols="59"
                  spellcheck="true"></textarea>
                <xavier-error-message [control]="form.get('addressToReceivePaperCertificate')"></xavier-error-message>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <div class="row">
      <div class="col-sm-12 col-xl-3">
        <h4>Message</h4>
      </div>
      <div class="col-sm-12 col-xl-8">
        <div class="form-group xavier-input-text">
          <label for="inviteMessage">Message</label>
          <textarea class="form-control xavier-textarea" formControlName="inviteMessage" id="inviteMessage" placeholder="Type your message..."
                    rows="5" cols="59" spellcheck="true"></textarea>
          <xavier-error-message [control]="form.get('inviteMessage')"></xavier-error-message>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-xl-3"></div>
      <div class="col-sm-12 col-xl-9 buttons">
        <xavier-button [isLoading]="isSubmitting" (click)="onSubmit()">
          Add New Candidate
        </xavier-button>

        <button type="button" class="btn btn-block xavier-button-plain candidate-button" (click)="cancelAddCandidate()">
          <span>Cancel</span>
        </button>
      </div>
    </div>
  </form>

  <!-- <debug-form [form]="form"></debug-form> -->
</div>

<!-- After Create Candidate Modal -->
<ng-template #createdCandidateModal>
  <div class="small-info">
    {{ value?.firstName }} {{ value?.lastName }} was created and has been assigned to {{ value.screening?.screeningName }}.
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 text-center">
        <h1 class="modal-title">What would you like to do next?</h1>
      </div>
    </div>
  </div>
  <div class="modal-buttons">
    <div class="row">
      <div class="col-5 text-center">
        <button type="button" class="btn xavier-button continue-step weight-normal" id="addAnotherButton" (click)="addAnotherCandidate()">
          <i class="material-icons addIcon">&#xE145;</i> Add Another Candidate
        </button>
      </div>
      <div *isAllowed="['CANDIDATE_EDIT', 'CANDIDATE_EDIT_COMPANY']" class="col-4 text-center">
        <button type="button" class="btn xavier-button-plain weight-normal" id="viewCandidateButton" (click)="viewCandidate()">
          View this Candidate
        </button>
      </div>
      <div class="col-3 text-center">
        <button type="button" class="btn xavier-button-plain weight-normal white" (click)="cancelAddCandidate()">
          <span>Close</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #termsAndConditonsModal>
  <div class="modal-header">
    <h2 class="modal-title pull-left">Terms and conditions</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div class="modal-body">
    <p>By selecting "Yes" and accessing the system you agree to the terms outlined in our Statement of Fair Processing
      (below).</p>
    <p>Please note that the following ONLY applies to standard and enhanced applications for
      Disclosure and Barring Service (DBS) Checks and only in cases where an applicant cannot provide sufficient
      documentation to evidence their identity under route one criteria.</p>
    <p><a href="https://www.gov.uk/government/publications/dbs-identity-checking-guidelines" target="_blank">DBS
        identity checking guidelines</a></p>
    <p>This DOES NOT apply to Disclosure Scotland Basic (unspent convictions) checks for example
      those applicants who require basic checks in order to meet BPSS (Baseline Personnel Security Standard)
      requirements</p>
    <h3>Statement of Fair Processing</h3>
    <p>By accessing xavier.eurocomci.co.uk and providing us with your personal details you are agreeing to the terms of
      our privacy policy as laid out in this Statement of Fair Processing.</p>
    <p>All personal information provided will be treated with the strictest of confidence and in accordance with the
      Data Protection Act 2018.</p>
    <p>Personal data will only be processed with the consent of the individual for the purpose of administering a
      Disclosure application. Any information provided will be securely held on a password-protected database, compliant
      with ISO27001, with access restricted to named individuals who require access as part of their normal duties.</p>
    <p>All information is submitted to and from the DBS via a secure government pathway. The information is encrypted
      whilst in transit.</p>
    <p>Information will not be shared with any other third parties outside of the Disclosure process, except where
      required by Law, without the express consent of the individual</p>
    <p>All information held following a Disclosure application will be securely destroyed when no longer required.</p>
    <p>In line with Section 7 of the Act you will be provided with a copy of any information we hold on you, on request.
    </p>
    <p>Any organisation using xavier.eurocomci.co.uk are required to sign a contract and by doing so they agree to:</p>
    <ul class="list-unstyled">
      <li>• Abide by the DBS Code of Practice</li>
      <li>• Abide by the Data Protection Act 2018</li>
      <li>• Have in place a policy for the recruitment of ex-offenders</li>
      <li>• Have a policy in place for the secure storage, retention, handling, use and disposal of disclosures and
        disclosure information</li>
    </ul>
  </div>
</ng-template>

