<div class="edit-check-status-icon wrapper">
  <xavier-status-icon [row]="icon"></xavier-status-icon>
</div>
<div class="edit-check-status-label wrapper">
  {{icon.statusValue}}
</div>
<div class="wrapper">
  <button *ngIf="startDisplay" type="button" class="btn btn-block xavier-button-plain controls" (click)="startValidation()">Start</button>
  <div *ngIf="controlsDisplay" [@fadeIn]>
    <button type="button" class="btn xavier-button controls passed" (click)="validateAction('passed')">
      <i class="material-icons">&#xE876;</i>
    </button>
    <button type="button" class="btn xavier-button controls rejected" (click)="validateAction('rejected')">
      <i class="material-icons">&#xE033;</i>
    </button>
  </div>
  <div *isAllowed="['COMPANY_STATUS_EDIT']">
    <button *ngIf="editDisplay" type="button" class="btn xavier-button controls edit" (click)="editValidation()">
      <i class="material-icons">&#xE3C9;</i>
    </button>
  </div>
</div>
