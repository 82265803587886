<div class="xavier-activation-wrapper container">
  <div *ngIf="loading" class="row" [@fadeIn]>
    <div class="spinner-container not-button"><div class="spinner-wrap"></div></div>
  </div>
  <div *ngIf="active" class="row" [@fadeIn]>
    <div class="col-12">
      <div class="header">Email verification successfully</div>
      <span>You will be able to login once your application has been approved.</span>
    </div>
    <div class="col-12 login-col">
      <a href="/login">Go to the Login page</a>
    </div>
  </div>
  <div *ngIf="resubmit" class="row" [@fadeIn]>
    <div class="col-12">
      <div class="header">
        Thank you
      </div>
      <div class="small">
        Your application has been resubmitted
      </div>
    </div>
    <div class="col-12 login-col">
      <a href="/login">Go to the Login page</a>
    </div>
  </div>
  <div *ngIf="error" class="row" [@fadeIn]>
    <div class="col-12">
      <div class="header">
        Token not valid
      </div>
    </div>
    <div class="col-12 register-col">
      <a href="/registration/1">Don't have an account? Create Account here.</a>
    </div>
  </div>
</div>
