import { HttpClient, HttpParams } from "@angular/common/http";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastyService } from "ng2-toasty";
import { UserAuth } from "../../../../../core/userAuth.core";
import { environment } from "../../../../../environments/environment";
import { User } from "../../../../admin/model/user.model";
import { addToasterWithMessage } from "../../../../utils/functions.utils";
import {interval} from 'rxjs/observable/interval';

@Component({
  moduleId: module.id,
  selector: "xavier-2fa-login",
  templateUrl: "2fa-login.component.html",
  styleUrls: ["2fa-login.component.scss"],
})
export class TFAuthLoginComponent {
  isDisabled = false;
  isRequesting = false;

  number: string = '';
  pressed: string = '';

  constructor(
    private http: HttpClient,
    private router: Router,
    public userAuth: UserAuth,
    private toastyService: ToastyService,
    private route: ActivatedRoute
  ) {
    const number = this.route.snapshot.paramMap.get('mobilePhone');
    this.number = number ? number : '';
  }

  public handleCodeInput(event: any) {
    this.isDisabled = true;
    this.isRequesting = true;

    const params = new HttpParams().set('ngsw-bypass', 'true');

    this.http.post(
      environment.apiUrl + '/multifactor/verifySmsCode',
      { code: event },
      { params: params, withCredentials: true }
    ).subscribe(response => {
      this.isDisabled = false;
      const user = new User(response['id'], response['firstName'], response['lastName'],
        response['fullName'], response['lastLogin'], response['company'].id,
        response['company'].companyName, response['role'], response['candidateId'], response['permissions'], response['email']);
      this.userAuth.setUser(user);

      if (response['role'].name === 'Candidate')
        this.router.navigate(['/dashboard/candidate-screening']);
      else
        this.router.navigate(['/dashboard/candidates-overview']);
    }, error => {
      if (error && error.error && error.error.error && error.error.error.message) {
        addToasterWithMessage('error', this.toastyService, error.error.error.message);
      } else {
        addToasterWithMessage('error', this.toastyService, 'Code was not verified. Please try again.');
      }
      this.isDisabled = false;
      this.isRequesting = false;
    });
  }

  public requestNewCode(event: any) {
    let i: number = 30;
    this.pressed = 'span-disable';
    let timer = interval(1000).subscribe(() => {
      if (i > 0) {
        event.target.innerHTML =  'Next request will be available in ' + i;
        i -= 1;
      } else {
        this.pressed = '';
        event.target.innerHTML =  'Request new code';
        timer.unsubscribe();
      }
    });
    this.http.get(
      environment.apiUrl + '/_ah/api/user/v1/requestNewSmsCode',
      { withCredentials: true }
    ).subscribe(
      () => {},
      error => {
        if (error && error.error && error.error.error && error.error.error.message) {
          addToasterWithMessage('error', this.toastyService, error.error.error.message);
        } else {
          addToasterWithMessage('error', this.toastyService, 'Something went wrong. Please try again.');
        }
      }
    );
  }

  public goBack() {
    return this.router.navigateByUrl('/two-factor/1');
  }
}
