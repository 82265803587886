import {Component, OnInit, OnDestroy, Output, EventEmitter, Input} from '@angular/core';
import {DragulaService} from "ng2-dragula";
import {ScreeningService} from "../../services/screening.service";
import {ToastyService} from "ng2-toasty";
import {isNullOrUndefined} from "util";
import {addToaster} from "../../../utils/functions.utils";
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'xavier-checks-selector',
  templateUrl: 'checks-selector.component.html',
  styleUrls: ['checks-selector.stylesheet.sass']
})

export class ChecksSelectorComponent implements OnInit, OnDestroy {

  @Input() public parent;
  public activeChecks: any;

  @Output() checkAddedEmitter = new EventEmitter();
  @Output() checkRemovedEmitter = new EventEmitter();

  constructor(private screeningService: ScreeningService, private dragulaService: DragulaService,
              private toastyService: ToastyService) {

  }

  ngOnInit() {
    this.dragulaService.setOptions('addedChecks', {
      mirrorContainer: document.getElementById('added-checks'),
      moves: function (el, container, handle) {
        return handle.classList[0] === 'handle';
      }
    });
    /* commented functions for drag and drop callbacks */
    // this.dragulaService.drag.subscribe((value) => {
    //   this.onDrag(value.slice(1));
    // });
    // this.dragulaService.drop.subscribe((value) => {
    //   this.onDrop(value.slice(1));
    // });

    this.screeningService.getActiveChecks(this.parent).subscribe(response => {
      if (!isNullOrUndefined(response.items)) {
        this.activeChecks = response.items;
      }
    }, err => {
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
    });
  }

  ngOnDestroy() {
    this.dragulaService.destroy('addedChecks');
  }

  public addCheck(check: any, event: any) {
    if (!isNullOrUndefined(event))
      event.preventDefault();

    let container = document.getElementById('added-checks');
    let newCheck = document.createElement('div');
    newCheck.setAttribute('id', check.id);
    newCheck.classList.add('row');
    newCheck.classList.add('addedCheck');
    let handle = document.createElement('div');
    handle.classList.add('handle');
    handle.innerHTML = '<i class="handle material-icons">&#xE25D;</i>';
    let info = document.createElement('div');
    info.classList.add('info');
    let title = document.createElement('div');
    title.classList.add('title');
    title.innerText = check.checkName;
    let description = document.createElement('div');
    description.classList.add('description');
    description.innerText = 'Price: £'+check.price;
    info.appendChild(title);
    info.appendChild(description);
    let remove = document.createElement('i');
    remove.classList.add('material-icons');
    remove.classList.add('remove');
    remove.innerHTML = '&#xE15C;';
    remove.addEventListener('click', ( function($event) {
      this.removeCheck(check, $event);
    }).bind(this));

    newCheck.appendChild(handle);
    newCheck.appendChild(info);
    newCheck.appendChild(remove);
    container.appendChild(newCheck);

    if (!isNullOrUndefined(event)) {
      event.target.parentElement.classList.add('visibility-none');
      event.target.parentElement.classList.add('added');
    }
    let addedChecksError = document.getElementById('added-checks-error');
    if (!addedChecksError.classList.contains('visibility-none'))
      addedChecksError.classList.add('visibility-none');

    this.checkAddedEmitter.emit(check);
  }

  public hideCheckRow(id) {
    let allChecks = document.getElementById('all-checks');
    Array.prototype.forEach.call(allChecks.children, function(element) {
      if (element.getAttribute('id') === id)
        element.classList.add('visibility-none');
        element.classList.add('added');
    });
  }

  removeCheck(check: any, event: any) {
    event.preventDefault();
    event.target.parentNode.parentNode.removeChild(event.target.parentNode);

    let allChecks = document.getElementById('all-checks');
    _.forEach(allChecks.children, ( function(htmlCheck) {
      if (htmlCheck.getAttribute('id') === check.id && htmlCheck.classList.contains('visibility-none', 'added')) {
        htmlCheck.classList.remove('visibility-none');
        htmlCheck.classList.remove('added');
        this.checkRemovedEmitter.emit(check);
        return false
      }
    }).bind(this));
  }

  filterChecks(containerClass: string, query: string) {
    let container = document.getElementsByClassName(containerClass);
    _.forEach(container[0].children, function(check) {
      let text = check.getElementsByClassName('title')[0].innerText;
      text = text.toLowerCase();
      if (text.indexOf(query) === -1)
        check.classList.add('visibility-none');
      else if (check.classList.contains('visibility-none') && !check.classList.contains('added'))
        check.classList.remove('visibility-none');
    });
  }

  resetChecksSearch(search: any) {
    search.value = '';
    this.filterChecks(search.id.replace('search-', ''), '');
  }

  /* commented functions for drag and drop callbacks */
  // private onDrag(args) {
  //   let [el, target, source] = args;
  //   let index = this.getElementIndex(el);
  //   console.log('onDrag: ', index);
  // }
  //
  // private onDrop(args) {
  //   let [el, target, source] = args;
  //   let index = this.getElementIndex(el);
  //   console.log('onDrop: ', index);
  // }
  //
  // private getElementIndex(el: any) {
  //   return [].slice.call(el.parentElement.children).indexOf(el);
  // }

}
