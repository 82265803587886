import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EmbeddedViewRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {DynamicFormDirective} from '../../../../directives/dynamic-form.directive';
import {DynamicFormService} from '../../../services/dynamic-form.service';
import {DATE_END, DATE_START, DF_VALIDATION_FN} from '../../../../../data/variables.data';
import {DateAttribute} from '../model/date/date.component';
import {fadeIn} from '../../../../../data/animations.data';
import {isNullOrUndefined} from 'util';
import {IMyDateModel} from 'mydatepicker';

@Component({
  moduleId: module.id,
  selector: 'xavier-df-group',
  templateUrl: 'df-group.component.html',
  styleUrls: ['df-group.stylesheet.sass'],
  animations: [fadeIn]
})

export class DfGroupComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {

  @ViewChild(DynamicFormDirective, { static: false }) dnForm: DynamicFormDirective;

  @Input() public group: any;
  @Input() public checkId: any;
  @Input() public preview: boolean;
  @Input() public repeatedParentPrefix: any;
  @Input() public parentNodeId: string = null;
  @Input() public noRecursion: boolean = null;
  @Input() public isReferenceForm: boolean = false;
  @Input() public isCheck: boolean = false;
  @Input() public isPristine: boolean;

  public formGroup;
  public formGroupId: string;
  public isVisible: boolean;
  public commentMsg: string;
  public deleteGroupIsVisible: boolean = true;
  public addGroupIsVisible: boolean = true;

  constructor(private dynamicFormService: DynamicFormService, public cdr: ChangeDetectorRef,
              private resolver: ComponentFactoryResolver, private injector: Injector,
              private appRef: ApplicationRef, private _eref: ElementRef) {

  }

  ngOnInit() {
    this.formGroupId = this.checkId + '-' + this.group.id;
    if (this.group.repeated || this.group.level > 2) {
      if (this.repeatedParentPrefix === undefined || (this.repeatedParentPrefix && this.isPristine)) {
        this.repeatedParentPrefix = this.dynamicFormService.getFormGroupRepeatedIndex(this.formGroupId) + '#';
      }

      this.formGroupId = this.repeatedParentPrefix + this.formGroupId;
    }
    if (!this.group.repeated && this.repeatedParentPrefix !== undefined) {
      this.formGroupId = this.repeatedParentPrefix + this.checkId + '-' + this.group.id;
    }

    this.dynamicFormService.setGroupComponentInstance(this.formGroupId, this);
    this.newFormGroup();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    if (this.group.level > 1 && isNullOrUndefined(this.parentNodeId))
      setTimeout(() => {
        if (!isNullOrUndefined(this._eref.nativeElement.parentElement))
          this.parentNodeId = this._eref.nativeElement.parentElement.id;
      }, 0);
    setTimeout(() => {
      this.deleteGroupIsVisible = this.dynamicFormService.deleteGroupDisabled(this.formGroupId, this.group.level);
    }, 0);
    this.applyGroupConditions();
    this.cdr.detectChanges();
  }

  ngOnChanges() {
    this.isVisible = !this.group.conditional;
  }

  ngOnDestroy() {
    if ((this.group.repeated || this.group.level > 2) && this.formGroupId.indexOf('#')) {
      let nonRepeatedIndex = this.formGroupId.split('#')[1];
      this.dynamicFormService.removeRepeatedFormGroups(nonRepeatedIndex);
      this.dynamicFormService.removeRepeatedDateConditionValues(nonRepeatedIndex);
      this.dynamicFormService.removeRepeatedGroupComponentsInstances(nonRepeatedIndex);
    } else {
      this.dynamicFormService.removeFormGroup(this.formGroupId);
      this.dynamicFormService.removeDateConditionKey(this.formGroupId);
      this.dynamicFormService.removeGroupComponentInstance(this.formGroupId);
    }
  }

  public newFormGroup() {
    let formGroup: FormGroup = new FormGroup({});
    this.dynamicFormService.setFormGroup(this.formGroupId, formGroup);

    this.formGroup = formGroup;
  }

  public visibilityCheck(override?: boolean) {
    if (override !== undefined && !this.isVisible) {
      this.isVisible = override;
      this.cdr.detectChanges();
      this.applyGroupConditions();
      return override;
    } else if (override !== undefined) {
      this.cdr.detectChanges();
      this.isVisible = override;
      return override;
    }
    return this.isVisible;
  }

  public deleteDfGroup() {
    if (this.dynamicFormService.countFormGroupRepeatedElements(this.formGroupId) > 1) {
      let toDelete = document.getElementById(this.formGroupId);
      let children = <HTMLElement>(<HTMLElement>toDelete.querySelector('xavier-df-group > div'));
      if (!isNullOrUndefined(children)) {
        this.dynamicFormService.removeFormGroup(children.id);
        this.dynamicFormService.removeGroupComponentInstance(children.id);
      }
      this.dynamicFormService.removeFormGroup(this.formGroupId);
      this.dynamicFormService.removeGroupComponentInstance(this.formGroupId);
      this._eref.nativeElement.parentElement.removeChild(this._eref.nativeElement);
    } else {
      // console.log('19 visibility check: ' + this.formGroupId + ' ' + false);
      this.visibilityCheck(false);
    }

    this.dynamicFormService.removeDateConditionKey(this.formGroupId);
  }

  public addDfGroupManual(parentNodeId?: string, repeatedParentPrefix?: string, override?: string) {
    const instance = this.addDfGroup(parentNodeId, repeatedParentPrefix, override);
    // set as pristine as new group is always pristine
    instance.isPristine = true;
  }

  public addDfGroup(parentNodeId?: string, repeatedParentPrefix?: string, override?: string) {
    const componentRef = this.resolver
      .resolveComponentFactory(DfGroupComponent)
      .create(this.injector);
    componentRef.instance.group = this.group;
    componentRef.instance.checkId = this.checkId;
    componentRef.instance.isVisible = true;

    if (!isNullOrUndefined(parentNodeId))
      componentRef.instance.parentNodeId = parentNodeId;
    else if (this.group.level > 1) {
      componentRef.instance.parentNodeId = this._eref.nativeElement.parentElement.id;
    }
    if (!isNullOrUndefined(repeatedParentPrefix))
      componentRef.instance.repeatedParentPrefix = repeatedParentPrefix;

    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    if (!isNullOrUndefined(override))
      document.getElementById(override).parentElement.parentElement.appendChild(domElem);
    else
      this._eref.nativeElement.parentElement.appendChild(domElem);

    componentRef.instance.isPristine = this.isPristine;
    return componentRef.instance;
  }

  private applyGroupConditions() {
    if (!isNullOrUndefined(this.group.conditions)) {
      for (let condition of this.group.conditions) {
        // DETECT_GAPS condition for dateStartAttribute
        if (condition.hasOwnProperty('dateStartAttribute') && this.isVisible && condition.fn === DF_VALIDATION_FN.DETECT_GAPS) {
          let attributeId = this.formGroupId + '-' + condition.dateStartAttribute.id;
          let attributeInstance = this.dynamicFormService.getAttributeComponentInstance(attributeId) as DateAttribute;
          this.formGroup.get(attributeId).valueChanges.subscribe(
            () => attributeInstance.DETECT_GAPS(attributeId)
          );

          if (!attributeInstance.data.required && attributeInstance.data.disabled) {
            attributeInstance.show(false);
          }

          if (!!condition.dateEndAttribute && !!condition.dateStartAttribute) {
            const endDateAttributeId = this.formGroupId + '-' + condition.dateEndAttribute.id;
            const endDateAttributeInstance = this.dynamicFormService.getAttributeComponentInstance(endDateAttributeId) as DateAttribute;
            const startDateAttributeId = this.formGroupId + '-' + condition.dateStartAttribute.id;
            const startDateAttributeInstance = this.dynamicFormService.getAttributeComponentInstance(startDateAttributeId) as DateAttribute;

            // condition when start date can finish
            startDateAttributeInstance.setStartDateProperties(endDateAttributeId);

            // condition when end date can start
            endDateAttributeInstance.setEndDateProperties(startDateAttributeId);

            // when start date or endDate changes, populate these changes to service which calculates gaps
            this.subscribeToDateChanges(startDateAttributeId, startDateAttributeInstance, endDateAttributeId, endDateAttributeInstance);
          }
        }

        // DETECT_GAPS condition for dateEndAttribute
        if (condition.hasOwnProperty('dateEndAttribute') && this.isVisible && condition.fn === DF_VALIDATION_FN.DETECT_GAPS) {
          let attributeId = this.formGroupId + '-' + condition.dateEndAttribute.id;
          let attributeInstance = this.dynamicFormService.getAttributeComponentInstance(attributeId) as DateAttribute;
          this.formGroup.get(attributeId).valueChanges.subscribe(
            () => attributeInstance.DETECT_GAPS(attributeId)
          );
        }

        // MIN_YEARS or MIN_YEARS_GROUP condition for dateStartAttribute
        if (condition.hasOwnProperty('dateStartAttribute') && this.isVisible &&
            (condition.fn === DF_VALIDATION_FN.MIN_YEARS || condition.fn === DF_VALIDATION_FN.MIN_YEAR_GROUP)) {
          const attributeId = this.formGroupId + '-' + condition.dateStartAttribute.id;
          const attributeInstance = this.dynamicFormService.getAttributeComponentInstance(attributeId) as DateAttribute;
          let endAttributeId = null;
          if (condition.dateEndAttribute)
            endAttributeId = this.formGroupId + '-' + condition.dateEndAttribute.id;
          attributeInstance.setStartDateProperties(endAttributeId);
          this.formGroup.get(attributeId).valueChanges.subscribe(value => {
            if (condition.fn === DF_VALIDATION_FN.MIN_YEARS)
              attributeInstance.MIN_YEARS(attributeId, DATE_START, value, condition);
            if (condition.fn === DF_VALIDATION_FN.MIN_YEAR_GROUP)
              attributeInstance.MIN_YEAR_GROUP(attributeId, DATE_START, value, condition);
          });
        }

        // MIN_YEARS or MIN_YEARS_GROUP condition for dateEndAttribute
        if (condition.hasOwnProperty('dateEndAttribute') && this.isVisible &&
            (condition.fn === DF_VALIDATION_FN.MIN_YEARS || condition.fn === DF_VALIDATION_FN.MIN_YEAR_GROUP)) {
          const attributeId = this.formGroupId + '-' + condition.dateEndAttribute.id;
          const attributeInstance = this.dynamicFormService.getAttributeComponentInstance(attributeId) as DateAttribute;
          attributeInstance.setEndDateProperties(this.formGroupId + '-' + condition.dateStartAttribute.id);
          this.formGroup.get(attributeId).valueChanges.subscribe(value => {
            if (condition.fn === DF_VALIDATION_FN.MIN_YEARS)
              attributeInstance.MIN_YEARS(attributeId, DATE_END, value, condition);
            if (condition.fn === DF_VALIDATION_FN.MIN_YEAR_GROUP)
              attributeInstance.MIN_YEAR_GROUP(attributeId, DATE_END, value, condition);
          });
        }

        // SUBSEQUENT_DATES or SUBSEQUENT_DATES_START condition for dateStartAttribute
        if (condition.hasOwnProperty('dateStartAttribute') && this.isVisible &&
            (condition.fn === DF_VALIDATION_FN.SUBSEQUENT_DATES || condition.fn === DF_VALIDATION_FN.SUBSEQUENT_DATES_SINCE)) {
          let attributeId = this.formGroupId + '-' + condition.dateStartAttribute.id;
          let attributeInstance = this.dynamicFormService.getAttributeComponentInstance(attributeId) as DateAttribute;

          let endAttributeId = null;
          if (condition.dateEndAttribute)
            endAttributeId = this.formGroupId + '-' + condition.dateEndAttribute.id;
          attributeInstance.setStartDateProperties(endAttributeId);
          this.formGroup.get(attributeId).valueChanges.subscribe(value => {
            if (condition.fn === DF_VALIDATION_FN.SUBSEQUENT_DATES)
              attributeInstance.SUBSEQUENT_DATES(attributeId);
          });
        }

        // SUBSEQUENT_DATES or SUBSEQUENT_DATES_START condition for dateEndAttribute
        if (condition.hasOwnProperty('dateEndAttribute') && this.isVisible &&
            (condition.fn === DF_VALIDATION_FN.SUBSEQUENT_DATES || condition.fn === DF_VALIDATION_FN.SUBSEQUENT_DATES_SINCE)) {
          let attributeId = this.formGroupId + '-' + condition.dateEndAttribute.id;
          let attributeInstance = this.dynamicFormService.getAttributeComponentInstance(attributeId) as DateAttribute;
          attributeInstance.setStartDateProperties(this.formGroupId + '-' + condition.dateEndAttribute.id);
          this.formGroup.get(attributeId).valueChanges.subscribe(value => {
            if (condition.fn === DF_VALIDATION_FN.SUBSEQUENT_DATES)
              attributeInstance.SUBSEQUENT_DATES(attributeId);
          });
        }
      }
    }
  }

  public get identityVerification(): boolean {
    return this.group && this.group.attributes && this.group.attributes.find(a => a.dataType === 'IDENTITY_VERIFICATION') != null;
  }

  private get hasSubsequentDatesCondition(): boolean {
    return this.group.conditions && !!this.group.conditions.find(c => c.fn === DF_VALIDATION_FN.SUBSEQUENT_DATES);
  }

  private subscribeToDateChanges(startDateAttributeId: string, startDateAttributeInstance: DateAttribute, endDateAttributeId: string, endDateAttributeInstance: DateAttribute) {
    // populate DateConditionValue when START_DATE changes, so that gaps can be calculated later
    startDateAttributeInstance.formControl.valueChanges.subscribe(value => {
      if (!isNullOrUndefined(value) && !isNullOrUndefined(startDateAttributeInstance.endAttributeId)) {
        const startDate = value;
        const endDateAttributeInstanceTmp = this.dynamicFormService.getAttributeComponentInstance(startDateAttributeInstance.endAttributeId) as DateAttribute;
        const endDate = endDateAttributeInstanceTmp.componentDate || this.getTemporaryDate(true);
        // console.log("componentDate 1: ", endDateAttributeInstanceTmp.componentDate);

        this.dynamicFormService.setDateConditionValue(
          this.formGroupId,
          startDateAttributeId,
          startDate,
          startDateAttributeInstance.endAttributeId,
          endDate,
          this.calculateDaysRange(startDate, endDate)
        );
      }
    });

    // populate DateConditionValue when END_DATE changes, so that gaps can be calculated later
    endDateAttributeInstance.formControl.valueChanges.subscribe(value => {
      if (!isNullOrUndefined(value) && !isNullOrUndefined(endDateAttributeInstance.startAttributeId)) {
        const startDateAttributeInstanceTmp = this.dynamicFormService.getAttributeComponentInstance(endDateAttributeInstance.startAttributeId) as DateAttribute;
        const startDate = startDateAttributeInstanceTmp.componentDate || this.getTemporaryDate(false);
        // console.log("componentDate 2: ", startDateAttributeInstanceTmp.componentDate);
        const endDate = value;

        this.dynamicFormService.setDateConditionValue(
          this.formGroupId,
          endDateAttributeInstance.startAttributeId,
          startDate,
          endDateAttributeId,
          endDate,
          this.calculateDaysRange(startDate, endDate)
        );
      }
    });
  }

  private calculateDaysRange(startDate, endDate) {
    return Math.abs((Date.parse(startDate.jsdate) / 1000) - (Date.parse(endDate.jsdate) / 1000)) / 86400;
  }

  /**
   * Returns temporary date, just so that cross groups gaps can be calculated, even if in current group just start or end date is entered by user yet.
   *
   * @param inFuture - return (now + 1 month) if true, (now - 1 month) otherwise
   */
  private getTemporaryDate(inFuture: boolean) {
    return {
      date: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + (inFuture ? 1 : -1),
        day: new Date().getDate()
      },
      jsdate: new Date(),
      formatted: 'not used',
      epoc: new Date().getTime()
    } as IMyDateModel;
  }

  // public isClearButtonShown(): boolean {
  //   if (!this.hasSubsequentDatesCondition)
  //     return false;
  //
  //   if (this.formGroupId.indexOf('#') < 0)
  //     return false;
  //
  //   const repeatId = Number(this.formGroupId.substr(0, this.formGroupId.indexOf('#')));
  //   let maxRepeatId = repeatId;
  //   this.dynamicFormService.getAllGroupComponentsInstance().forEach((instance, key) => {
  //     if (_.endsWith(key, this.formGroupId.substr(this.formGroupId.indexOf('#'), this.formGroupId.length))) {
  //       const keyRepeatId = Number(key.substr(0, key.indexOf('#')));
  //       if (keyRepeatId > maxRepeatId) {
  //         maxRepeatId = keyRepeatId;
  //       }
  //     }
  //   });
  //
  //   return repeatId === maxRepeatId;
  // }

  // clear() {
  //   this.deleteDfGroup();
  // }
}
