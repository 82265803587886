import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'xavier-yoti-button',
  templateUrl: 'yoti-button.component.html',
  styleUrls: ['yoti-button.stylesheet.sass']
})
export class YotiButtonComponent {

  @Input()
  public buttonText: string;
  @Output()
  public buttonClick = new EventEmitter();

  public onClicked(): void {
    this.buttonClick.emit();
  }
}
