<div *ngIf="formGroup" class="form-group xavier-input-text screening" [formGroup]="formGroup">
  <div class="confirmation-checkbox-label">
    <label [for]="formGroupId+'-'+data.id" [innerHTML]="data.options"></label>
    <span *ngIf="data.required" class="required">&bull;</span>
    <div class="xavier-popover-info">
      <i *ngIf="data.information !== ''"
         class="material-icons"
         tooltip="{{data.information}}"
         placement="auto"
         containerClass="xavier-popover-container">&#xE8FD;</i>
    </div>
  </div>
  <div class="xavier-input-checkbox filter-checkbox">
    <input type="checkbox"
           class="form-check-input screening"
           (click)="validityCheck()"
           [id]="formGroupId+'-'+data.id"
           [checked]="initCheckbox()"
           [formControlName]="formGroupId+'-'+data.id">
    <label [for]="formGroupId+'-'+data.id" class="form-check-label"></label>
  </div>
  <xavier-error-message [control]="formControl"></xavier-error-message>
  <div class="returned-comment">{{commentMsg}}</div>
</div>
