import {AbstractControl, FormGroup} from '@angular/forms';
import {AfterViewInit, Injectable, OnDestroy} from '@angular/core';
import {DynamicFormService} from '../../../services/dynamic-form.service';
import * as _ from 'lodash';
import {isVerified} from '../../../../utils/functions.utils';
import {AttachmentDTO} from '../../../../../model/attachment/attachment.dto';

type DashboardAttributeType = { new (DynamicFormService): DashboardAttribute };

export const ATTRIBUTE_TYPES_MAP = new Map<string, DashboardAttributeType>();

@Injectable()
export class DashboardAttribute implements AfterViewInit, OnDestroy {

  public data: any;
  public formGroupId: string;
  public formGroup: FormGroup;
  public formControl: AbstractControl;
  public dashboard: boolean;
  public reference: boolean;
  public screeningStatus: string;
  public isScreeningCompleted: string;
  public subsequentDatesError: boolean;
  public context = {};
  public prefilledByYoti: boolean;
  public value: any;
  public verifiedValue: any;
  public file: AttachmentDTO;
  public verifiedFile: AttachmentDTO;

  constructor(public dynamicFormService: DynamicFormService) {

  }

  ngAfterViewInit() {
    this.dynamicFormService.setAttributeComponentInstance(this.formGroupId + '-' + this.data.id, this);
  }

  ngOnDestroy() {
    this.dynamicFormService.removeAttributeComponentInstance(this.formGroupId);
  }

  public mouseEnter(elementId, popover) {
    this.dynamicFormService.showToolbar(elementId);
    if (popover && _.isEmpty(popover.popoverContext))
      popover.popover = null;
  }

  public mouseLeave(elementId) {
    this.dynamicFormService.showToolbar('');
  }

  public setPopover(data) {
    this.context = { message: data.message };
  }

  public static getTypeFor(name: string): DashboardAttributeType {
    return ATTRIBUTE_TYPES_MAP.get(name);
  }

  public get isVerified(): boolean {
    if (this.file) {
      return this.verifiedFile && this.file.id === this.verifiedFile.id;
    }

    return isVerified(this.value, this.verifiedValue);
  }
}
