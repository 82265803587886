export class ClientData {
  address: Address;
  clientStatus: string;
  companyName: string;
  createdDate: string;
  disabled: boolean;
  entityType: string;
  id: number;
  manager: {
    activated: boolean;
    disabled: boolean;
    divisions: {
      name: string;
      id: number
    };
    email: string;
    firstName: string;
    fullName: string;
    id: number;
    lastName: string;
    password: string;
    role: {
      id: number
    };
    securityAnswer: string;
    securityQuestion: string;
  };
  mobilePhone: string;
  officePhone: string;
  officePhoneExt: string;
  registrationNumber: string;
  signatories: Array<Signatories>;
  vat: string;
  vatInEU: boolean;

  constructor(property: string, value: any) {
    constructObject(this, property, value);
  }
}

export class Signatories {
  address: Address;
  createdDate: string;
  documentType: string;
  firstName: string;
  id: number;
  idDocument: {
    createdDate: string;
    folder: string;
    id: number;
    name: string
  };
  identityStatus: string;
  jobTitle: string;
  lastName: string;
  moneyLaunderingStatus: string;

  constructor(property: string, value: any) {
    constructObject(this, property, value);
  }
}

export class Address {
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  createdDate: string;
  id: number;
  postcode: string;

  constructor(property: string, value: any) {
    constructObject(this, property, value);
  }
}

export function constructObject(dataObject: any, signature: any, value: any) {
  if (typeof signature === 'string' && signature.indexOf('.') < 0) {
    dataObject[signature] = value;
  } else {
    let properties = signature.split('.');
    let lastPropertyIndex = properties.length-1;
    for (let i = 0; i < lastPropertyIndex; ++ i) {
      let property = properties[i];
      if (!(property in dataObject))
        dataObject[property] = {};
      dataObject = dataObject[property];
    }
    dataObject[properties[lastPropertyIndex]] = value;
  }
}
