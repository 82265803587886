import {Component, Input, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {FormService} from "../../services/form.service";
import {HttpClient, HttpHeaders, HttpRequest, HttpResponse} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Title} from "@angular/platform-browser";
import {fadeIn} from "../../../../data/animations.data";
import {emailValidator} from "../../../utils/form.validators";
import {setAsTouched} from "../../../utils/functions.utils";

@Component({
  moduleId: module.id,
  selector: 'xavier-login',
  templateUrl: 'forgot.component.html',
  styleUrls: ['forgot.stylesheet.sass'],
  animations: [fadeIn]
})

export class ForgotPasswordComponent implements OnInit {

  @Input() forgot: boolean = false;
  @Input() showConfirm: boolean = false;

  public forgotPasswordData: FormGroup;
  public disabledForgot: boolean = false;
  public disabledResend: boolean = false;

  constructor(private fb: FormBuilder, private router: Router, private formService: FormService,
              private http: HttpClient, private titleService: Title) {

  }

  ngOnInit() {
    this.titleService.setTitle('Forgotten Password - Xavier Eurocom CI');

    this.forgotPasswordData = this.fb.group({
      email: ['', [Validators.required, emailValidator]]
    });
  }

  onSubmit({ value, valid } : { value: any, valid: boolean }) {
    if (!valid) {
      setAsTouched(this.forgotPasswordData);
    } else {
      this.disabledForgot = true;
      let submitButton = document.getElementById('submitButton');
      const spinnerWrap = document.createElement('div');
      spinnerWrap.classList.add('spinner-wrap');
      spinnerWrap.classList.add('button');

      submitButton.innerHTML = '';
      submitButton.appendChild(spinnerWrap);

      let headers = new HttpHeaders({ 'Content-Type': 'text/html' });
      const req = new HttpRequest(
        'POST',
        'https://'+environment.storageBucket+'/_ah/api/user/v1/forgottenPassword/' + encodeURIComponent(value.email),
        null,
        {
          headers: headers,
          withCredentials: true
        }
      );

      this.http.request(req).subscribe(event => {
        if (event instanceof HttpResponse) {
          if (event.status == 204) {
            this.forgot = true;
            this.disabledForgot = false;
          }
        }
      });
    }
  }

  resendEmail() {
    this.disabledResend = true;
    let resendButton = document.getElementById('resendButton');
    const spinnerWrap = document.createElement('div');
    spinnerWrap.classList.add('spinner-wrap');
    spinnerWrap.classList.add('button');

    resendButton.innerHTML = '';
    resendButton.appendChild(spinnerWrap);

    let headers = new HttpHeaders({ 'Content-Type': 'text/html' });
    const req = new HttpRequest(
      'POST',
      'https://'+environment.storageBucket+'/_ah/api/user/v1/forgottenPassword/' + encodeURIComponent(this.forgotPasswordData.get('email').value),
      null,
      {
        headers: headers,
        withCredentials: true
      }
    );

    this.http.request(req).subscribe(event => {
      if (event instanceof HttpResponse) {
        if (event.status == 204) {
          this.showConfirm = true;
          setTimeout(() => {
            this.showConfirm = false;
            this.disabledResend = false;
          }, 3000);
        }
      }
    });
  }

  cancel() {
    this.router.navigate(['/login']);
  }

}
