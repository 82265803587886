<div class="btn-group column-group" dropdown [isOpen]="status.isopen" (isOpenChange)="change($event)">
  <button dropdownToggle type="button" class="btn btn-block xavier-button-plain dropdown-toggle xavier-dropdown-toggle">
    <i class="material-icons visibilityIcon">&#xE8F5;</i> {{hiddenNumber}} Column{{hiddenNumber >= '2' ? 's': ''}} <span class="caret xavier-caret"></span>
  </button>
  <div *dropdownMenu class="dropdown-menu dropdown-menu-right xavier-dropdown-column-menu" role="menu">
    <div class="container">
      <div class="row">
        <div class="col-12 column-col">
          <h4>{{componentParams.title}}</h4>
        </div>
        <div *ngFor="let column of componentParams.columns" class="col-6">
          <div class="row column-row">
            <div class="col-3 xavier-input-checkbox column-checkbox">
              <input type="checkbox" class="form-check-input" id="{{column.name}}" checked="" [checked]="initCheckbox(column.name)">
              <label for="{{column.name}}" class="form-check-label"></label>
            </div>
            <div class="col-6 column-value-wrapper">
              <button class="btn xavier-button-plain column-value" (click)="clickCheckBox(column.name)">
                {{column.value}}
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 buttons-col">
          <div class="row column-row">
            <div class="col-4">
              <button type="button" class="btn btn-block xavier-button continue-step" id="saveColumns" (click)="saveColumns($event)">
                <span>Save</span>
              </button>
            </div>
            <div class="col-3">
              <button dropdownToggle type="button" class="btn btn-block xavier-button-plain">
                Cancel
              </button>
            </div>
            <div class="col-3">
              <button type="button" class="btn btn-block xavier-button-plain white" id="resetColumns" (click)="resetColumns()">
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
