<div class="xavier-steps-wrapper container">
  <div class="row">
    <div class="col-sm-4">
      <div class="xavier-steps header">
        Create Account
      </div>
      <div class="xavier-steps-component">
        <xavier-steps step="1"></xavier-steps>
      </div>
    </div>
    <div class="col-sm-8">
      <div class="xavier-register container">
        <div class="row justify-content-center">
          <!--div class="col-xs-12 col-xl-5">
            <button class="btn btn-block xavier-button google" (click)="googleSignIn()">
              <span><img src="../../../../assets/img/g-logo.png">Register with Google</span>
            </button>
          </div>
          <div class="col-xs-12 col-sm-auto xavier-element-or">
            <span>or</span>
          </div-->
          <div class="col-xs-12 col-xl-5">
            <button class="btn btn-block xavier-button email" (click)="emailSignIn()">
              <span>Register with Email</span>
            </button>
          </div>
        </div>
      </div>
      <div class="xavier-login-link container">
        <div class="row">
          <div class="col-sm-12">
            <a href="/login">Already have an account? Login here.</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
