import {AfterViewInit, Component, Input, OnInit, Output} from '@angular/core';
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest, HttpResponse} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {AbstractControl} from "@angular/forms";
import {FormService} from "../../services/form.service";
import {isNullOrUndefined} from "util";
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'xavier-file-uploader',
  templateUrl: 'file-uploader.component.html',
  styleUrls: [
    'file-uploader.stylesheet.sass',
    'file-uploader-mediaqueries.stylesheet.sass'
  ]
})

export class FileUploaderComponent implements OnInit, AfterViewInit {

  @Input() public containerIndex: number;
  @Input() public inputId: string;
  @Input() public errorClass: string;
  @Input() public fileName: string;
  @Input() public documentType: any;
  @Input() public multiSelectOptions: Array<string>;
  @Input() public progressVisible: boolean;
  @Input() public progress: number = 0;
  @Input() public uploadedFileTempPath: string;

  @Input() public filenameFormControl: AbstractControl;
  @Input() public folderFormControl: AbstractControl;
  @Input() public documentTypeFormControl: AbstractControl;

  @Output() public deleteDescription: string;
  @Output() public deleteButtonLabel: string;

  public supportedFileTypes: string[] = ['image/png', 'image/jpeg', 'application/pdf'];
  selectedFiles: File[] = [];

  constructor(private http: HttpClient, private formService: FormService) {

  }

  ngOnInit() {
    this.deleteDescription = "You have to upload Passport or Driving License. To upload %thisdocument% you have to delete the uploaded %otherdocument% document.";
    this.deleteButtonLabel = "Delete %otherdocument%";
    _.forEach(this.multiSelectOptions, ( function(element) {
      if (element.id != this.documentType.id) {
        this.deleteDescription = this.deleteDescription.replace('%thisdocument%', this.documentType.label);
        this.deleteDescription = this.deleteDescription.replace('%otherdocument%', element.label);
        this.deleteButtonLabel = this.deleteButtonLabel.replace('%otherdocument%', element.label);
      }
    }).bind(this) );
  }

  ngAfterViewInit() {
    if (this.formService.getResubmit()) {
      let adminUser = this.formService.getAll();
      if (this.containerIndex < adminUser.signatories.length &&
          adminUser.signatories[this.containerIndex].documentType === this.documentType.id) {
        this.hidePanel('fileLoaderDrop', this.documentType.id + '-' + this.containerIndex);
        this.showPanel('fileUploaded', this.documentType.id + '-' + this.containerIndex);

        setTimeout(() => this.fileName = adminUser.signatories[this.containerIndex].name, 0);

        let obj = document.createElement('object');
        obj.setAttribute("data", 'https://'+environment.storageBucket+'/gcs/' + adminUser.signatories[this.containerIndex].folder + '/' + adminUser.signatories[this.containerIndex].name);
        obj.setAttribute("width", "80");
        obj.setAttribute("height", "80");

        let emb = document.createElement('embed');
        emb.setAttribute("src", 'https://'+environment.storageBucket+'/gcs/' + adminUser.signatories[this.containerIndex].folder + '/' + adminUser.signatories[this.containerIndex].name);
        emb.setAttribute("width", "80");
        emb.setAttribute("height", "80");

        obj.appendChild(emb);

        let previewContainer = document.getElementById('fileUploadedPreview' + this.documentType.id + '-' + this.containerIndex);
        previewContainer.appendChild(obj);

        let container = document.getElementById(this.documentType.id + '-' + this.containerIndex);
        container.classList.remove('document-visibility');
      }
    }
  }

  deleteDocument(containerId: string) {
    let fileInput = <HTMLInputElement>document.getElementById('fileLoader'+containerId);
    fileInput.value = '';
    let previewContainer = document.getElementById('fileUploadedPreview' + containerId);
    previewContainer.innerHTML = '';
    this.fileName = '';
    this.progress = 0;
    this.progressVisible = false;
    this.filenameFormControl.setValue(null);
    this.folderFormControl.setValue(null);
    this.documentTypeFormControl.setValue(null);
    this.hidePanel('fileLoading', containerId);
    this.hidePanel('fileUploaded', containerId);
    this.showPanel('fileLoaderDrop', containerId);
    _.forEach(this.multiSelectOptions, ( function(element) {
      if (element.id != containerId) {
        this.hidePanel('fileDelete', element.id + '-' + this.containerIndex);
        this.showPanel('fileLoaderDrop', element.id + '-' + this.containerIndex);
      }
    }).bind(this) );
  }

  deleteOtherDocument(containerId: string) {
    _.forEach(this.multiSelectOptions, ( function(element) {
      if (element.id != containerId) {
        this.deleteDocument(element.id + '-' + this.containerIndex);
      }
    }).bind(this) );
    this.hidePanel('fileDelete', containerId);
    this.showPanel('fileLoaderDrop', containerId);
  }

  dragFileAccepted(acceptedFile: any, containerId: string) {
    this.hidePanel('fileLoaderDrop', containerId);
    this.showPanel('fileLoading', containerId);

    let file;
    if (!isNullOrUndefined(acceptedFile.file))
      file = acceptedFile.file;
    else
      file = acceptedFile;

    this.fileName = file.name;
    let headers = new HttpHeaders({ 'Content-Type': file.type });
    const req = new HttpRequest(
      'POST',
      'https://'+environment.storageBucket+'/uploadFile/?filename=' + encodeURIComponent(file.name),
      file,
      {
        headers: headers,
        reportProgress: true,
        withCredentials: true
      }
    );

    this.http.request(req).subscribe(event => {
      this.progressVisible = true;
      if (event.type === HttpEventType.UploadProgress) {
        this.progress = Math.round(100 * event.loaded / event.total);
      } else if (event instanceof HttpResponse) {
        this.filenameFormControl.setValue(event.body['name']);
        this.folderFormControl.setValue(event.body['folder']);
        let documentTypeID = containerId.split('-')[0];
        this.documentTypeFormControl.setValue(documentTypeID);

        let obj = document.createElement('object');
        obj.setAttribute("data", 'https://'+environment.storageBucket+'/gcs/' + event.body['folder'] + '/' + event.body['name']);
        obj.setAttribute("type", file.type);
        obj.setAttribute("width", "80");
        obj.setAttribute("height", "80");

        let emb = document.createElement('embed');
        emb.setAttribute("src", 'https://'+environment.storageBucket+'/gcs/' + event.body['folder'] + '/' + event.body['name']);
        emb.setAttribute("type", file.type);
        emb.setAttribute("width", "80");
        emb.setAttribute("height", "80");

        obj.appendChild(emb);

        let previewContainer = document.getElementById('fileUploadedPreview' + containerId);
        previewContainer.appendChild(obj);

        _.forEach(this.multiSelectOptions, ( function(element) {
          if (element.id != this.documentType.id) {
            // hide the Drop Zone and show the Delete tab of the document types the user is not using at the moment
            this.hidePanel('fileLoaderDrop', element.id + '-' + this.containerIndex);
            this.showPanel('fileDelete', element.id + '-' + this.containerIndex);
          }
          let dropAreaContainer = document.getElementById('fileDropArea' + element.id + '-' + this.containerIndex);
          dropAreaContainer.classList.remove('uploadBoxError');
        }).bind(this) );

        this.hidePanel('fileLoading', containerId);
        this.showPanel('fileUploaded', containerId);
      }
    });
  }

  dragFileRejected(rejectedFile: any, id) {
    let dropAreaContainer = document.getElementById(id);
    dropAreaContainer.classList.add('rejectedFile');
    setTimeout(() => {
      dropAreaContainer.classList.remove('rejectedFile');
    }, 500);
  }

  dragFileOverStart(id) {
    let dropAreaContainer = document.getElementById(id);
    dropAreaContainer.classList.add('dragOver');
  }

  dragFileOverEnd(id) {
    let dropAreaContainer = document.getElementById(id);
    dropAreaContainer.classList.remove('dragOver');
  }

  openDialog(id) {
    let fileUploader = document.getElementById(id);
    fileUploader.click();
  }

  fileChangeEvent(fileInput: Event, containerId: string) {
    if (this.supportedFileTypes.indexOf(fileInput.target['files'][0].type) >= 0)
      this.dragFileAccepted(fileInput.target['files'][0], containerId);
    else
      this.dragFileRejected(fileInput.target['files'][0], 'fileDropArea' + containerId);
  }

  public hidePanel(prefix: string, id: string) {
    let panel = document.getElementById(prefix.concat(id));
    panel.classList.add('visibility-none');
  }

  public showPanel(prefix: string, id: string) {
    let panel = document.getElementById(prefix.concat(id));
    panel.classList.remove('visibility-none');
  }

  onDragOver(event: Event) {
    event.preventDefault();
  }

  onDragLeave(event: Event) {
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    if (this.supportedFileTypes.indexOf(files[0].type) >= 0)
      this.dragFileAccepted(files[0], this.inputId);
    else
      this.dragFileRejected(files[0], 'fileDropArea' + this.inputId);
  }

}
