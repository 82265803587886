import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {HttpService} from "../../shared-services/http.service";
import {Subject} from "rxjs/Subject";
import { CreditSafeCandidateDTO } from '../../../model/credit-safe-candidate.dto';

@Injectable()

export class CandidateScreeningService {

  private eventLogEntry = new Subject<any>();
  eventLogEntry$ = this.eventLogEntry.asObservable();
  private screeningDetailsData = new Subject<any>();
  screeningDetailsData$ = this.screeningDetailsData.asObservable();

  constructor(private httpService: HttpService) {

  }

  public getMyScreening() {
    return this.get('getMyScreening', null, null);
  }

  public getMyScreeningData() {
    return this.get('getMyScreeningData', null, null);
  }

  public saveScreeningCheckData(methodName, body, candidateId, screeningId, checkId) {
    let params = [
      { 'candidateId': candidateId },
      { 'screeningId': screeningId },
      { 'checkId': checkId }
    ];
    return this.post(methodName, body, params, false);
  }

  public submitMyScreening() {
    return this.post('submitMyScreening', null, null, false);
  }

  public getCandidateScreening(candidateId) {
    let params = [
      { 'candidateId': candidateId }
    ];
    return this.get('getCandidateScreening', null, params);
  }

  public getCandidateScreeningData(candidateId) {
    let params = [
      { 'candidateId': candidateId }
    ];
    return this.get('getCandidateScreeningData', null, params);
  }

  public getCandidateDetails(candidateId) {
    let params = [
      { 'candidateId': candidateId }
    ];
    return this.get('getCandidateDetails', null, params);
  }

  public reactivateScreening(candidateId) {
    let params = [
      { 'candidateId': candidateId }
    ];
    return this.post('reactivateScreening', null, params, false);
  }

  public returnScreening(candidateId) {
    let params = [
      { 'candidateId': candidateId }
    ];
    return this.post('returnScreening', null, params, false);
  }

  public viewCertificate(candidateId) {
    return this.httpService.getDocument('/certificate?candidateId=' + candidateId + '&download=true');
  }

  public downloadReport(candidateId) {
    return this.httpService.getDocument('/screeningExport?candidateId=' + candidateId + '&download=true');
  }

  public exportScreening(candidateId) {
    return this.httpService.getDocument('/screeningZip?candidateId=' + candidateId + '&download=true');
  }

  public startCheck(candidateId, screeningId, checkId) {
    let params = [
      { 'candidateId': candidateId },
      { 'screeningId': screeningId },
      { 'checkId': checkId }
    ];
    return this.post('startCheck', null, params, false);
  }

  public passCheck(candidateId, screeningId, checkId) {
    let params = [
      { 'candidateId': candidateId },
      { 'screeningId': screeningId },
      { 'checkId': checkId }
    ];
    return this.post('passCheck', null, params, false);
  }

  public reactivateCheck(candidateId, screeningId, checkId) {
    let params = [
      { 'candidateId': candidateId },
      { 'screeningId': screeningId },
      { 'checkId': checkId }
    ];
    return this.post('reactivateCheck', null, params, false);
  }

  public delayCheck(candidateId, screeningId, checkId, comment) {
    let body = {
      'comment': comment
    };
    let params = [
      { 'candidateId': candidateId },
      { 'screeningId': screeningId },
      { 'checkId': checkId }
    ];
    return this.post('delayCheck', body, params, false);
  }

  public resumeCheck(candidateId, screeningId, checkId) {
    let params = [
      { 'candidateId': candidateId },
      { 'screeningId': screeningId },
      { 'checkId': checkId }
    ];
    return this.post('resumeCheck', null, params, false);
  }

  public editComment(candidateId, screeningId, commentId, comment) {
    let body = {
      'comment': comment
    };
    let params = [
      { 'candidateId': candidateId },
      { 'screeningId': screeningId },
      { 'commentId': commentId }
    ];
    return this.post('editComment', body, params, false);
  }

  public removeComment(candidateId, screeningId, commentId) {
    let params = [
      { 'candidateId': candidateId },
      { 'screeningId': screeningId },
      { 'commentId': commentId }
    ];
    return this.delete('removeComment', params);
  }

  public commentOnPartOfScreening(candidateId, screeningId, nodeId, comment, type) {
    let methodName;
    switch (type) {
      case 'return':
        methodName = 'returnPartOfScreening';
        break;
      case 'comment':
        methodName = 'commentPartOfScreening';
        break;
      case 'flag':
        methodName = 'flagPartOfScreening';
        break;
      case 'adverse':
        methodName = 'adversePartOfScreening';
        break;
      default:
        break;
    }
    let body = {
      'nodeId': nodeId,
      'comment': comment
    };
    let params = [
      { 'candidateId': candidateId },
      { 'screeningId': screeningId }
    ];
    return this.post(methodName, body, params, false);
  }

  public addFileComment(candidateId, nodeId, filename, file) {
    let params = [
      { 'candidateId': candidateId },
      { 'nodeId': nodeId },
      { 'filename': filename }
    ];
    return this.httpService.postFile('/addFileComment', '', file, params, false, file.type);
  }

  public completeScreening(candidateId: string, body: any) {
    let params = [
      { 'candidateId': candidateId }
    ];
    return this.post('completeScreening', body, params, false);
  }

  public terminateScreening(candidateId: string, body: any) {
    let params = [
      { 'candidateId': candidateId }
    ];
    return this.post('terminateScreening', body, params, false);
  }

  public changeScreening(body: any) {
    let params = [];
    return this.put('changeScreening', body, params, false);
  }

  public deleteScreening(candidateId: string) {
    let params = [
      { 'candidateId': candidateId }
    ];
    return this.post('deleteScreening', null, params, false);
  }

  public editScreeningDetails(candidateId: string, body: any) {
    let params = [
      { 'candidateId': candidateId }
    ];
    return this.post('editScreeningDetails', body, params, false);
  }

  public getCandidateScreeningLog(id) {
    return this.httpService.get('/_ah/api/log/v1', 'getCandidateScreeningLog', [{ userId: encodeURIComponent(id) }]);
  }

  public getCreditSafeCandidate(candidateId: string): Observable<CreditSafeCandidateDTO> {
    const params = [
      { candidateId }
    ];
    return this.get("getCreditSafeCandidate", null, params);
  }

  public startScreening(candidateId: string, data: CreditSafeCandidateDTO): Observable<void> {
    const params = [
      { candidateId }
    ];
    return this.post("startScreening", data, params, false);
  }

  public runCreditSafe(candidateId: string, data: CreditSafeCandidateDTO): Observable<void> {
    const params = [
      { candidateId }
    ];
    return this.post("runCreditSafe", data, params, false);
  }

  public updateScreeningDetailsBinding(detailsUpdate: any) {
    this.screeningDetailsData.next(detailsUpdate);
  }

  public sendEventLogEntry(entry: any) {
    this.eventLogEntry.next(entry);
  }

  public post(methodName: string, body: any, params: any, reportProgress: boolean): Observable<any> {
    return this.httpService.post('/_ah/api/candidateScreening/v1', methodName, body, params, reportProgress);
  }

  public delete(methodName: string, params: any): Observable<any> {
    return this.httpService.delete('/_ah/api/candidateScreening/v1', methodName, params);
  }

  public get(methodName: string, body: any, params: any, noMethod?: boolean): Observable<any> {
    return this.httpService.get('/_ah/api/candidateScreening/v1', methodName, params, noMethod);
  }

  public put(methodName: string, body: any, params: any, reportProgress: boolean): Observable<any> {
    return this.httpService.put('/_ah/api/candidate/v1', methodName, body, params, reportProgress)
  }

  public getIdVerifications(pageSize, pageNum, query, sortBy, sortOrder) {
    let params = [
      { 'pageSize': pageSize.toString() },
      { 'pageNum': pageNum.toString() },
      { 'sortBy': sortBy },
      { 'sortOrder': sortOrder },
      { 'query': query }
    ];
    return this.get('idVerificationList', null, params, null);
  }

  public getIdVerificationScreenData(candidateID: string) {
    let params = [
      { 'candidateID': candidateID },
    ];
    return this.get('idVerificationScreenData', null, params, null);
  }

  public submitIdVerification(data: any): Observable<void> {
    const params = [];
    return this.post("idverification/submitData", data, params, false);
  }

  public getCountersignData(pageSize, pageNum, query, sortBy, sortOrder) {
    let params = [
      { 'pageSize': pageSize.toString() },
      { 'pageNum': pageNum.toString() },
      { 'sortBy': sortBy },
      { 'sortOrder': sortOrder },
      { 'query': query }
    ];
    return this.get('counterSignatoryList', null, params);
  }

  public submitCountersignedApp(data: any): Observable<void> {
    const params = [];
    return this.post("submitCounterSignedApplications", data, params, false);
  }
}
