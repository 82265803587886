<div class="sticky-wrapper">
  <nav class="navbar navbar-expand-xl navbar-light xavier-header">
    <a class="navbar-brand" routerLink="/dashboard/candidates-overview"><img src="../../../assets/img/eurocom-horizontal.svg"></a>
    <button *ngIf="!isReferenceForm" class="navbar-toggler xavier-navbar-toggler" type="button" (click)="collapse()" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse justify-content-end xavier-navbar-collapse" [collapse]="isCollapsed">
      <ul class="nav navbar-nav">
        <li class="nav-item drop-item" routerLinkActive="active">
          <div class="btn-group" dropdown>
            <button dropdownToggle type="button" class="btn dropdown-toggle xavier-navbuttonitem">
              Apprentices <span class="caret xavier-caret"></span>
            </button>
            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right xavier-nav-dropdown" role="menu">
              <li role="menuitem">
                <a class="dropdown-item" routerLinkActive="check-visible" routerLink="/dashboard/evidence-check" (click)="collapse()">
                  Evidence Check<i class="material-icons check-invisible" >&#xE5CA;</i>
                </a>
              </li>
              <li role="menuitem">
                <a class="dropdown-item" routerLinkActive="check-visible" routerLink="/dashboard/evidence-check-quality-assurance" (click)="collapse()">
                  Evidence QA<i class="material-icons check-invisible" >&#xE5CA;</i>
                </a>
              </li>
              <li role="menuitem">
                <a class="dropdown-item" routerLinkActive="check-visible" routerLink="/dashboard/evidence-check-completed" (click)="collapse()">
                  Completed<i class="material-icons check-invisible" >&#xE5CA;</i>
                </a>
              </li>
            </ul>
          </div>
        </li>
        <li class="nav-item drop-item" routerLinkActive="active" *isAllowed="['CANDIDATE_MENU']">
          <div class="btn-group" dropdown>
            <button dropdownToggle type="button" class="btn dropdown-toggle xavier-navbuttonitem">
              Candidates <span class="caret xavier-caret"></span>
            </button>
            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right xavier-nav-dropdown" role="menu">
              <li role="menuitem">
                <a class="dropdown-item" routerLinkActive="check-visible" routerLink="/dashboard/candidates" (click)="collapse()">
                  Candidates Manager<i class="material-icons check-invisible" >&#xE5CA;</i>
                </a>
              </li>
              <li role="menuitem" *isAllowed="['CANDIDATE_STATUS_ALL', 'CANDIDATE_STATUS_COMPANY', 'CANDIDATE_STATUS_DIVISION', 'CANDIDATE_STATUS_OWN']">
                <a class="dropdown-item" routerLinkActive="check-visible" routerLink="/dashboard/candidates-overview" (click)="collapse()">
                  Candidates Overview<i class="material-icons check-invisible" >&#xE5CA;</i>
                </a>
              </li>
              <li role="menuitem" *isAllowed="['CANDIDATE_STATUS_ALL', 'CANDIDATE_STATUS_COMPANY', 'CANDIDATE_STATUS_DIVISION', 'CANDIDATE_STATUS_OWN']">
                <a class="dropdown-item" routerLinkActive="check-visible" routerLink="/dashboard/candidates-complete" (click)="collapse()">
                  Completed Candidates<i class="material-icons check-invisible" >&#xE5CA;</i>
                </a>
              </li>
              <li role="menuitem" *isAllowed="['LID_CHECK_ORDER_MENU']">
                <a class="dropdown-item" routerLinkActive="check-visible" routerLink="/dashboard/liveid-orders" (click)="collapse()">
                  Screening Orders<i class="material-icons check-invisible" >&#xE5CA;</i>
                </a>
              </li>
            </ul>
          </div>
        </li>
        <li class="nav-item" *isAllowed="['ID_VERIFICATION_MENU']">
          <a class="nav-link" routerLinkActive="active" routerLink="/dashboard/id-verification" (click)="collapse()">ID Verify</a>
        </li>
        <li class="nav-item" *ngIf="dbsCountersignatory">
          <a class="nav-link" routerLinkActive="active" routerLink="/dashboard/countersign-overview" (click)="collapse()">Countersign</a>
        </li>
        <li class="nav-item" *isAllowed="['CHECK_MENU']">
          <a class="nav-link" routerLinkActive="active" routerLink="/dashboard/checks" (click)="collapse()">Checks</a>
        </li>
        <li class="nav-item" *isAllowed="['COMPANY_MENU']">
          <a class="nav-link" routerLinkActive="active" routerLink="/dashboard/clients" (click)="collapse()">Clients</a>
        </li>
        <li *ngIf="myCompanyMenuItem">
          <a class="nav-link" routerLinkActive="active" routerLink="/dashboard/clients/edit/{{companyId}}" (click)="collapse()">My Company</a>
        </li>
        <li class="nav-item" *isAllowed="['SCREENING_MENU']">
          <a class="nav-link" routerLinkActive="active" routerLink="/dashboard/screenings" (click)="collapse()">Screenings</a>
        </li>
        <li class="nav-item drop-item">
          <div *ngIf="!isReferenceForm" class="btn-group" dropdown>
            <button dropdownToggle type="button" class="btn dropdown-toggle xavier-navbuttonitem">
              {{displayName}} <span class="caret xavier-caret"></span>
            </button>
            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right xavier-nav-dropdown" role="menu">
              <li role="menuitem">
                <a *isAllowed="['USER_MENU']" class="dropdown-item" routerLinkActive="check-visible" routerLink="users" (click)="collapse()">
                  User Access<i class="material-icons check-invisible" >&#xE5CA;</i>
                </a>
              </li>
              <li role="menuitem"><a class="dropdown-item" href="#" (click)="logout()">Logout</a></li>
            </ul>
          </div>
        </li>
      </ul>
      <div *isAllowed="['CANDIDATE_NEW_ALL', 'CANDIDATE_NEW_COMPANY', 'CANDIDATE_NEW_DIVISION']">
        <a class="nav-link active add-new-candidate" [routerLink]="['/dashboard/candidates/add']" [queryParams]="{'startRouteUrl': startRouteUrl}">
          <i class="material-icons nav-link-icon">&#xE145;</i> Add New Candidate
        </a>
      </div>
    </div>
  </nav>
</div>
