<ng-template #signaturePadPopoverTemplate><div><img src="{{data.information}}" /></div></ng-template>
<div class="form-group xavier-input-text" [formGroup]="formGroup">
  <div>
    <label [for]="formGroupId+'-'+data.id">{{data.attributeName}}</label>
    <span *ngIf="data.required" class="required">&bull;</span>
    <div class="xavier-popover-info">
      <i *ngIf="data.information !== ''"
         class="material-icons"
         [popover]="signaturePadPopoverTemplate"
         placement="auto"
         containerClass="xavier-signature-popover-container"
         triggers="mouseenter:mouseleave">&#xE8FD;</i>
    </div>
  </div>
  <div class="xavier-signature-pad-wrapper"
       [id]="'canvas-wrapper_'+formGroupId+'-'+data.id">
    <signature-field #signatureField [formControlName]="formGroupId+'-'+data.id" [attributeId]="formGroupId+'-'+data.id">
    </signature-field>
  </div>
  <xavier-error-message [control]="formControl"></xavier-error-message>
  <div class="returned-comment">{{commentMsg}}</div>
</div>
