import {AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Title} from "@angular/platform-browser";
import {Router} from "@angular/router";
import {ScreeningService} from "../../services/screening.service";
import {ToastyService} from "ng2-toasty";
import {AutocompleteSelectDropdownComponent} from "../../cmps-parts/autocomplete-select-dropdown/autocomplete-select-dropdown.component";
import {AutocompleteSelectDropdown} from "../../model/autocomplete-select-dropdown.model";
import {UserPermission} from "../../model/permissions.model";
import {UserAuth} from "../../../../core/userAuth.core";
import {fadeIn} from "../../../../data/animations.data";
import {addToaster, setAsTouched} from "../../../utils/functions.utils";
import {isNullOrUndefined} from "util";
import * as _ from 'lodash';
import { DbsService } from '../../services/dbs.service';
import { DropdownComponentCompareWithFn, DropdownComponentItemSourceFn } from '../../../shared-cmps/dropdown/dropdown.component';
import { map } from 'rxjs/operators';

@Component({
  moduleId: module.id,
  selector: 'xavier-add-screening',
  templateUrl: 'add-screening.component.html',
  styleUrls: ['add-screening.stylesheet.sass'],
  animations: [fadeIn]
})

export class AddScreeningComponent implements OnInit, AfterViewInit {

  @ViewChildren(AutocompleteSelectDropdownComponent) autocompleteDropdowns: QueryList<AutocompleteSelectDropdownComponent>;
  @ViewChild('dropdown') dropdown;

  @Input() public companySearchParams: AutocompleteSelectDropdown;
  @Input() public divisionSearchParams: AutocompleteSelectDropdown;

  public addScreeningData: FormGroup;
  public companySelected: boolean = false;
  public showDiscountInput: boolean = false;
  public showDiscountControls: boolean = false;
  public showDiscountEdit: boolean = false;
  public disabled: boolean = false;
  public minimumPriceNotice: boolean = false;
  public minimumPriceToFixed: string = '0';
  public parentComponent = this;
  public dbsCheckAdded: any;
  public loadedChecks = [];
  public isDbsEmployment: boolean = false;
  public positionItemSource = [];
  public displayValues: boolean = false;
  public displayLoader: boolean = false;
  public currentPos: number = -1;
  public displayBarredList: boolean = false;
  public selectedPosition: any;
  public saasClient: boolean = false;

  private discountPercentage: number;
  private minimumPrice: number;
  private discountedPrice: number;
  private companyId: number;
  private divisionId: number;
  private checksPriceSum: number = 0;

  constructor(private screeningService: ScreeningService, private fb: FormBuilder, private router: Router, private dbsService: DbsService,
              private toastyService: ToastyService, private titleService: Title, private userAuth: UserAuth, private _eref: ElementRef,) {

  }

  ngOnInit() {
    this.titleService.setTitle('Add New Screening - Xavier Eurocom CI');

    this.addScreeningData = this.fb.group({
      screeningName: ['', Validators.required],
      companyName: [''],
      divisionName: ['', Validators.required],
      price: [{ value: '0.00', disabled: true }],
      discountedPrice: [''],
      cost: [{ value: '0.00', disabled: true }],
      slaTarget: ['15', Validators.required],
      screeningInstructions: [""],
      idCheckType: ["", Validators.required]
    });

    this.companySearchParams = new AutocompleteSelectDropdown(
      'companyName',
      'Company',
      'Search Companies...',
      'search',
      [],
      true,
      this.addScreeningData.get('companyName')
    );

    this.divisionSearchParams = new AutocompleteSelectDropdown(
      'divisionName',
      'Division',
      'Select Division...',
      'arrow_drop_down',
      [],
      false,
      this.addScreeningData.get('divisionName')
    );

    let user = this.userAuth.getUser();
    if (user.role.name === 'HR Admin')
      this.addScreeningData.get('slaTarget').disable();
  }

  ngAfterViewInit() {
    let user = this.userAuth.getUser();
    if (UserPermission.isAllowed(['SCREENING_NEW_ALL'], user.permissionList)) {
      this.addScreeningData.get('companyName').setValidators([Validators.required]);
    } else {
      setTimeout(() => {
        this.onDropdownEntrySelected({ companyName: user.companyId, id: user.companyId });
      }, 0);
    }
  }

  showDiscountButton() {
    let addedChecks = document.getElementById('added-checks');
    if (addedChecks.children.length === 0)
      this.deleteDiscountPrice();
    return addedChecks.children.length > 0;
  }

  overridePrice() {
    this.discountedPrice = this.addScreeningData.get('discountedPrice').value;
    this.showDiscountInput = true;
    this.showDiscountControls = true;
  }

  saveDiscountPrice() {
    document.getElementById('discount-addon').classList.add('no-border');
    document.getElementById('discountedPrice').classList.add('no-border');
    this.addScreeningData.get('discountedPrice').disable();
    this.discountedPrice = this.addScreeningData.get('discountedPrice').value;
    this.showDiscountInput = true;
    this.showDiscountControls = false;
    this.showDiscountEdit = true;
  }

  deleteDiscountPrice() {
    this.addScreeningData.get('discountedPrice').setValue('');
    this.addScreeningData.get('discountedPrice').enable();
    this.showDiscountInput = false;
    this.showDiscountControls = false;
    this.showDiscountEdit = false;
  }

  cancelDiscountPrice() {
    if (this.addScreeningData.get('discountedPrice').value !== this.discountedPrice) {
      this.addScreeningData.get('discountedPrice').setValue(this.discountedPrice);
    }
    document.getElementById('discount-addon').classList.add('no-border');
    document.getElementById('discountedPrice').classList.add('no-border');
    this.addScreeningData.get('discountedPrice').disable();
    this.showDiscountInput = true;
    this.showDiscountControls = false;
    this.showDiscountEdit = true;
  }

  editDiscountPrice() {
    document.getElementById('discount-addon').classList.remove('no-border');
    document.getElementById('discountedPrice').classList.remove('no-border');
    this.addScreeningData.get('discountedPrice').enable();
    this.showDiscountEdit = false;
    this.showDiscountInput = true;
    this.showDiscountControls = true;
  }

  onDropdownEntrySelected(entry: any) {
    if (!isNullOrUndefined(entry.discountPercentage) && !isNullOrUndefined(entry.minimumPrice)) {
      this.discountPercentage = entry.discountPercentage;
      this.minimumPrice = entry.minimumPrice;
      let computedPrice = (this.checksPriceSum - (this.checksPriceSum * this.discountPercentage * 0.01));
      if (computedPrice < this.minimumPrice) {
        this.addScreeningData.get('price').setValue(this.minimumPrice.toFixed(2));
        this.minimumPriceNotice = true;
        this.minimumPriceToFixed = this.minimumPrice.toFixed(2);
      } else {
        this.addScreeningData.get('price').setValue(computedPrice.toFixed(2));
        this.minimumPriceNotice = false;
        this.minimumPriceToFixed = '0';
      }
    }
    if (entry.hasOwnProperty('companyName')) {
      this.saasClient = entry.saasClient && entry.saasClient === 'YES';
      this.companySelected = true;
      this.companyId = entry.id;
      setTimeout(() => {
        this.autocompleteDropdowns.forEach(autocompleteDropdownsInstance => {
          if (autocompleteDropdownsInstance.componentParams.id == 'divisionName') {
            // display the loader on the component appearance
            autocompleteDropdownsInstance.displayLoader = true;
            autocompleteDropdownsInstance.componentParams.formControl.setValue(+entry.id);
            // set the value to empty and don't emit event to enable the form validation on submit
            // until an actual value is selected
            autocompleteDropdownsInstance.componentParams.formControl.setValue('', { emitEvent: false });
          }
        });
      }, 0);
    }
    if (entry.hasOwnProperty('divisionName')) {
      this.divisionId = entry.id;
    }
  }

  onCheckAdded(check: any) {
    // Load array of added checks
    this.loadedChecks.push(check);
    // Get loaded dbs checks
    const loadedDbs = this.loadedChecks.filter(item => item.dbs);

    let price = !isNaN(parseFloat(check.price)) ? parseFloat(check.price) : 0;
    let minimumPrice = !isNullOrUndefined(this.minimumPrice) ? this.minimumPrice : 0;
    let discountPercentage = !isNullOrUndefined(this.discountPercentage) ? this.discountPercentage : 0;
    let cost = !isNaN(parseFloat(check.cost)) ? parseFloat(check.cost) : 0;
    this.checksPriceSum += price;
    let computedPrice = (this.checksPriceSum - (this.checksPriceSum * discountPercentage * 0.01));
    if (computedPrice < minimumPrice) {
      this.addScreeningData.get('price').setValue(minimumPrice.toFixed(2));
      this.minimumPriceNotice = true;
      this.minimumPriceToFixed = minimumPrice.toFixed(2);
    } else {
      this.addScreeningData.get('price').setValue(computedPrice.toFixed(2));
      this.minimumPriceNotice = false;
      this.minimumPriceToFixed = '0';
    }

    this.addScreeningData.get('cost').setValue(
      (parseFloat(this.addScreeningData.get('cost').value) + cost).toFixed(2)
    );

    // Display dbs forms for single dbs schcks
    loadedDbs.length > 1 ? this.removeDBSFormControls() : this.addDBSFormControls();
  }

  onCheckRemoved(check: any) {
    this.loadedChecks = this.loadedChecks.filter(loadedCheck => loadedCheck.id !== check.id);
    const loadedDbs = this.loadedChecks.filter(item => item.dbs);

    let price = !isNaN(parseFloat(check.price)) ? parseFloat(check.price) : 0;
    let minimumPrice = !isNullOrUndefined(this.minimumPrice) ? this.minimumPrice : 0;
    let discountPercentage = !isNullOrUndefined(this.discountPercentage) ? this.discountPercentage : 0;
    let cost = !isNaN(parseFloat(check.cost)) ? parseFloat(check.cost) : 0;
    this.checksPriceSum -= price;
    let computedPrice = (this.checksPriceSum - (this.checksPriceSum * discountPercentage * 0.01));
    if (computedPrice < this.minimumPrice) {
      this.addScreeningData.get('price').setValue(minimumPrice.toFixed(2));
      this.minimumPriceNotice = true;
      this.minimumPriceToFixed = minimumPrice.toFixed(2);
    } else {
      this.addScreeningData.get('price').setValue(computedPrice.toFixed(2));
      this.minimumPriceNotice = false;
      this.minimumPriceToFixed = '0';
    }

    this.addScreeningData.get('cost').setValue(
      (parseFloat(this.addScreeningData.get('cost').value) - cost).toFixed(2)
    );

    // Display dbs forms for single dbs schcks
    loadedDbs.length > 1 ? this.removeDBSFormControls() : this.addDBSFormControls();
  }

  onSubmit({ value, valid } : { value: any, valid: boolean }) {
    let addedChecks = document.getElementById('added-checks');
    let addedChecksError = document.getElementById('added-checks-error');
    if (!valid || addedChecks.children.length === 0) {
      setAsTouched(this.addScreeningData);
      if (addedChecks.children.length === 0)
        addedChecksError.classList.remove('visibility-none');
    } else {
      this.disabled = true;
      let submitButton = document.getElementById('submitButton');
      const spinnerWrap = document.createElement('div');
      spinnerWrap.classList.add('spinner-wrap');
      spinnerWrap.classList.add('button');

      submitButton.innerHTML = '';
      submitButton.appendChild(spinnerWrap);

      if (!addedChecksError.classList.contains('visibility-none'))
        addedChecksError.classList.add('visibility-none');
      let checksId = [];
      _.forEach(addedChecks.children, function(check) {
        checksId.push({ 'id': check.id });
      });
      let body = {
        'screeningName': this.addScreeningData.get('screeningName').value,
        'company': { 'id': this.companyId },
        'division': { 'id': this.divisionId },
        'slaTarget': this.addScreeningData.get('slaTarget').value,
        'checks': checksId,
        'instructions': this.addScreeningData.get('screeningInstructions').value,
        'idCheckType': this.addScreeningData.get('idCheckType').value,
      };
      if (this.addScreeningData.get('discountedPrice').value !== '')
        body['discountedPrice'] = this.addScreeningData.get('discountedPrice').value;

      if (this.dbsCheckAdded && this.saasClient) {
        if (this.dbsCheckAdded.dbsType === 'BASIC') {
          body['basicDbsCheck'] = value.basicDbsCheck;
        } else {
          const checkBody = {
            ...value.enhancedDbsCheck,
            position: this.selectedPosition,
            barringListToCheck: this.selectedPosition.barringListToCheck,
            workforceType: this.selectedPosition.workforceType
          };

          if (this.dbsCheckAdded.dbsType === 'ENHANCED') {
            body['enhancedDbsCheck'] = checkBody;
          } else {
            body['standardDbsCheck'] = checkBody;
          }
        }
      }
      this.screeningService.createScreening(body).subscribe(response => {
         if (!isNullOrUndefined(response.id)) {
           this.disabled = false;
           submitButton.innerHTML = "<span>Add New Screening</span>";
           this.router.navigate(['/dashboard/screenings'], { queryParams: { created: true }, skipLocationChange: true });
         }
      }, err => {
        this.disabled = false;
        submitButton.innerHTML = "<span>Add New Screening</span>";
        let action = 'serverError';
        if (err.status === 403)
          action = '403';
        addToaster('error', this.toastyService, action, 'errors');
      });
    }
  }

  cancelAddScreening() {
    this.router.navigate(['/dashboard/screenings']);
  }

  // implementing empty interface for finally action in the screening.service getActiveChecks
  public checksLoaded() {}

  addDBSFormControls() {
    this.dbsCheckAdded = this.loadedChecks.find(({ dbs }) => dbs);
    if (this.dbsCheckAdded && this.saasClient) {
      this.addScreeningData.removeControl('enhancedDbsCheck');
      this.addScreeningData.removeControl('basicDbsCheck');
      this.displayBarredList = false;
      if (this.dbsCheckAdded.dbsType !== 'BASIC') {
        this.addScreeningData.addControl('enhancedDbsCheck', this.fb.group({
          position: ['', Validators.required],
          organisationName: ['', Validators.required],
          barringListToCheck: [null],
          workforceType: [null],
          receivedBarredList: [null]
        }));
        this.getJobPositions();
        this.addScreeningData.get('enhancedDbsCheck.position').valueChanges.subscribe(value => {
          this.getJobPositions(value);
        })
      } else {
        this.addScreeningData.addControl('basicDbsCheck', this.fb.group({
          checkPurpose: ['', Validators.required],
          sector: ['', Validators.required],
          role: ['', Validators.required],
        }));
        const dbsRole = this.addScreeningData.get('basicDbsCheck.role');
        const dbsSector = this.addScreeningData.get('basicDbsCheck.sector');

        this.addScreeningData.get('basicDbsCheck.checkPurpose').valueChanges.subscribe(value => {
          this.isDbsEmployment = value === 'EMPLOYMENT';
          if (this.isDbsEmployment) {
            dbsRole.setValidators(Validators.required);
            dbsSector.setValidators(Validators.required);
            dbsRole.updateValueAndValidity();
            dbsSector.updateValueAndValidity();
          } else {
            dbsRole.clearValidators();
            dbsSector.clearValidators();
            dbsRole.updateValueAndValidity();
            dbsSector.updateValueAndValidity();
          }
        });
      }
    }

  }

  removeDBSFormControls() {
    addToaster('error', this.toastyService, 'Multiple DBS checks', 'errors');
    this.dbsCheckAdded = null;
  }

  public sectorItemSource: DropdownComponentItemSourceFn = () => {
    return this.dbsService.getJobSectors().pipe(map(res => res.items || []));
  }

  public compareFn: DropdownComponentCompareWithFn = (a, b) => a.id === b.id;

  public getJobPositions(searchValue?: string) {
    this.dbsService.getJobPositions(this.dbsCheckAdded.dbsType).subscribe(value => {
      if (!searchValue || searchValue === "") {
        this.positionItemSource = value;
      } else {
        this.positionItemSource = value.filter((position) =>
          position.name.toLowerCase().includes(searchValue.toLowerCase())
        );
      }
    })
  }

  onWrapClick() {
    if (this.positionItemSource.length > 0 && !this.displayValues)
      this.displayValues = true;
    else
      this.displayValues = false;
  }

  selectEntry(entry) {
    this.displayBarredList = ['ADULT', 'BOTH'].includes(entry.barringListToCheck) &&
      this.dbsCheckAdded.dbsType === 'ENHANCED';
    this.addScreeningData.get('enhancedDbsCheck.position').patchValue(entry.name);
    this.selectedPosition = entry;
    const barredList = this.addScreeningData.get('enhancedDbsCheck.receivedBarredList');
    if (this.displayBarredList) {
      barredList.setValidators(Validators.required);
      barredList.updateValueAndValidity();
    } else {
      barredList.setValue(null);
      barredList.clearValidators()
      barredList.updateValueAndValidity();
    }
    this.onWrapClick();
  }

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target) && this.displayValues === true)
      this.displayValues = !this.displayValues;
  }

  onKeyUp(event) {
    if (this.displayValues && event.code == "ArrowDown" && !(this.currentPos >= this.positionItemSource.length - 1)) {
      this.currentPos++;
      this.dropdown.nativeElement.scrollTo(0, this.currentPos * 44);
    }
    if (this.displayValues && event.code == "ArrowUp" && !(this.currentPos < 1)) {
      this.currentPos--;
    }
    if (this.displayValues && event.code == "Escape")
      this.onWrapClick();
  }

  onChange(event, allow: boolean) {
    if(!allow)
      event.preventDefault();
  }
  onKeyDown(event, allow: boolean) {
    if (!allow && (event.code === 'Backspace' || event.code === 'Delete'))
      return false;
    if (event.code === 'Enter')
      event.preventDefault();
    if (this.displayValues && this.positionItemSource[this.currentPos] && (event.code === 'Enter' || event.code === 'Tab')) {
      this.onWrapClick();
    }
  }

  allowTypingOnKeyPress(allow: boolean) {
    if (!allow)
      return false;
  }
}
