import {Component, Input} from '@angular/core';
import {ClientPropertiesComponent} from "../client-properties.component";

@Component({
  moduleId: module.id,
  selector: 'xavier-client-properties-details',
  templateUrl: 'details.component.html'
})

export class ClientPropertiesDetailsComponent {

  @Input() public details;
  @Input() public clientId;

  constructor(public cp: ClientPropertiesComponent) {

  }

}
