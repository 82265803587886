import { GtCustomComponent, GtRow } from "@angular-generic-table/core";
import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "xavier-open-ai-badge",
  templateUrl: "./open-ai-badge.component.html",
  styleUrls: ["./open-ai-badge.stylesheet.sass"],
})
export class OpenAiBadgeComponent extends GtCustomComponent<GtRow> {
  @Input() public row;

  constructor() {
    super();
  }
}
