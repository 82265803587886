import {
  candidatesOverviewDashboardColumns,
  candidatesToasterOptions,
  itemsPerPageValues,
  LIVE_ID_CHECK_ORDER_STATUSES,
  roleDependantCandidatesOverviewColumns
} from '../../../../data/variables.data';
import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {fadeIn} from '../../../../data/animations.data';
import {FormControl} from '@angular/forms';
import {GenericTableComponent, GtExpandedRow, GtInformation} from '@angular-generic-table/core';
import {CandidateService} from '../../services/candidate.service';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {TableService} from '../../services/table.service';
import {ToastyService} from 'ng2-toasty';
import {UserAuth} from '../../../../core/userAuth.core';
import {addToaster, formatDateTime, getToasterMessagesLabel} from '../../../utils/functions.utils';
import {isNullOrUndefined} from 'util';
import {StatusIconComponent} from '../../cmps-parts/status-icon/status-icon.component';
import {PercentBarComponent} from '../../cmps-parts/percent-bar/percent-bar.component';
import * as _ from 'lodash';
import {LIVE_ID_ORDERS_CONFIG} from './live-id-orders.config';
import {ScreeningOrderApi} from '../../../../api/screening-order-api.service';
import {ScreeningOrderDTO} from '../../../../model/liveid/order/screening-order.dto';
import {DownloadIconComponent} from '../../cmps-parts/download-icon/download-icon.component';
import {HttpService} from '../../../shared-services/http.service';
import { OptionsDropdownComponent } from '../../cmps-parts/options-dropdown/options-dropdown.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  moduleId: module.id,
  selector: 'app-live-id-orders',
  templateUrl: 'live-id-orders.component.html',
  styleUrls: ['live-id-orders.stylesheet.sass'],
  animations: [fadeIn]
})
export class LiveIdOrdersComponent implements OnInit, OnDestroy, AfterViewChecked {


  @ViewChild('rowLength', { static: true })
  public rowLength: any;

  // @ViewChild('search', { static: true })
  // public search: any;

  public startRouteUrl: string;
  public itemsPerPageValues = itemsPerPageValues;
  public searchControl = new FormControl();
  public data: Array<any> = [];
  public LIVE_ID_CHECK_ORDER_STATUSES = LIVE_ID_CHECK_ORDER_STATUSES;
  public roleDependantCandidatesOverviewColumns = roleDependantCandidatesOverviewColumns;
  public configObject = LIVE_ID_ORDERS_CONFIG;
  public candidateCompleted = false;

  @ViewChild('liveIdOrdersTable', { static: true })
  public liveIdOrdersTable: GenericTableComponent<any, GtExpandedRow<any>>;

  private page = 1;
  private records = 10;
  private query = '';
  private sortBy = '';
  private sortOrder = 'orderDate';
  private tableOwner: string;
  private routeSub: any;
  private searchSub: any;
  private tableOptionsSub: any;
  private tableData$: any;
  private tableOwnerSub: any;
  private toasterControls = {
    created: false
  };
  private tableInfo: GtInformation = {
    'pageNext': null,
    'pageCurrent': 1,
    'pagePrevious': null,
    'pageTotal': 1,
    'recordFrom': 1,
    'recordLength': 1,
    'recordsAfterFilter': 1,
    'recordsAfterSearch': 1,
    'recordsAll': 1
  };

  constructor(
    private candidatesService: CandidateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private tableService: TableService,
    private toastyService: ToastyService,
    private cdr: ChangeDetectorRef,
    private userAuth: UserAuth,
    private screeningOrderApi: ScreeningOrderApi,
    private httpService: HttpService,
    private modalService: BsModalService,
  ) {
    this.startRouteUrl = this.router.url;

    this.routeSub = this.activatedRoute.queryParams.subscribe(params => {
      this.toasterControls['created'] = (!isNullOrUndefined(params['created'])) ? params['created'] : false;
    });

    this.tableOwnerSub = this.tableService.currentOwner.subscribe(owner => {
      this.tableOwner = owner;
    });

    this.searchSub = this.searchControl.valueChanges
      .debounceTime(1000)
      .subscribe(newValue => {
        this.query = newValue;
        this.page = 1;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(this.page, this.liveIdOrdersTable.gtInfo.recordLength, this.query);
      });
  }

  ngOnInit() {
    this.titleService.setTitle('Dashboard - Xavier Eurocom CI');

    this.routeSub = this.router.events.subscribe((value) => {
      if (value instanceof NavigationStart)
        this.startRouteUrl = value.url;
    });

    this.tableOptionsSub = this.tableService.currentOptions.subscribe(options => {
      if (this.tableOwner === btoa(this.constructor.toString())) {
        this.page = options.page ? options.page : 1;
        this.records = options.records ? options.records : 10;
        this.query = options.query ? options.query : '';
        this.sortBy = options.sortBy ? options.sortBy : '';
        this.sortOrder = options.sortOrder ? options.sortOrder : '';
      } else {
        this.tableService.changeTableOwner(btoa(this.constructor.toString()));
        this.page = 1;
        this.records = 10;
        this.query = '';
        this.sortBy = '';
        this.sortOrder = '';
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
      }
    });

    this.tableData$ = this.tableService.tableData$.subscribe(value => {
      if (value) {
        this.getData(this.page, this.records, this.query, this.sortBy, this.sortOrder);
        this.tableService.changeGetTableDataValue(false);
      }
    })

    this.getData(this.page, this.records, this.query, this.sortBy, this.sortOrder);

    setTimeout(() => {
      this.fixTablecolumns();
    }, 0);
  }

  // Temporary solution to add CSS classes to fixed columns of the table
  private fixTablecolumns() {
    const candidateNameColumns = document.getElementsByClassName('candidate-name-column');
    candidateNameColumns[0].classList.add('candidate-name', 'sort-enable', 'no-top-border');

    const orderDateColumns = document.getElementsByClassName('order-date-column');
    orderDateColumns[0].classList.add('order-date', 'sort-enable', 'no-top-border');

    const daysRemainingColumns = document.getElementsByClassName('days-remaining-column');
    daysRemainingColumns[0].classList.add('days-remaining', 'sort-enable', 'no-top-border');

    const statusColumns = document.getElementsByClassName('status-column');
    statusColumns[0].classList.add('status', 'sort-enable', 'no-top-border');

    const optionsDropdownColumns = document.getElementsByClassName('options-dropdown-column');
    optionsDropdownColumns[0].classList.add('options-dropdown', 'sort-enable', 'no-top-border');

    const certificateColumns = document.getElementsByClassName('certificate-column');
    certificateColumns[0].classList.add('certificate', 'sort-enable', 'no-top-border');
  }

  ngAfterViewChecked() {
    const tables = document.getElementsByTagName('table');
    tables[0].classList.add('tr-no-pointer');

    _.forEach(this.toasterControls, (function (actionValue, action) {
      if (actionValue) {
        const config = candidatesToasterOptions;
        config.msg = getToasterMessagesLabel(action, 'candidateOverview');
        this.toastyService.info(config);
      }
    }).bind(this));

    this.rowLength.nativeElement.value = this.records;
    // this.search.nativeElement.value = this.query;

    this.applyWrappers(); // wrapping the table with wrappers for the horizontal scrolling

    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.roleDependantCandidatesOverviewColumns = null;
    this.routeSub.unsubscribe();
    this.searchSub.unsubscribe();
    this.tableOptionsSub.unsubscribe();
    this.tableOwnerSub.unsubscribe();
    this.tableData$.unsubscribe();
  }

  getData = (pageNum: number, pageSize: number, query: string, sortBy?: string, sortOrder?: string) => {
    this.data = [];
    const loading: Array<any> = [];
    loading.push({ candidateName: 'Loading...' });
    this.configObject.data = loading;

    const info = {
      'pageNext': null,
      'pageCurrent': 1,
      'pagePrevious': null,
      'pageTotal': 1,
      'recordFrom': 1,
      'recordLength': 1,
      'recordsAfterFilter': 1,
      'recordsAfterSearch': 1,
      'recordsAll': 1
    };
    this.configObject.info = <GtInformation> info;
    if (isNullOrUndefined(query))
      query = '';
    if (!sortBy)
      sortBy = 'orderDate';
    if (!sortOrder)
      sortOrder = 'DESC';

    this.screeningOrderApi.findAll(pageSize, pageNum, sortBy, sortOrder, query, this.candidateCompleted).subscribe(response => {
      if (!isNullOrUndefined(response.list)) {
        this.data = [];

        _.forEach(response.list, (function (order: ScreeningOrderDTO) {
          const orderStatus = order.payment.status === 'UNPAID' ? 'UNPAID' : order.status;
          const status = this.getStatusProperties(orderStatus);
          const orderObject = {
            'id': order.id,
            'candidateName': order.screeningOrderCandidate.fullName,
            'ordererName': order.orderer.fullName,
            'ordererEmail': order.orderer.email,
            'ordererPhone': order.orderer.phonePrefix + order.orderer.phone,
            'ordererCompany': order.orderer.companyName,
            'candidateEmail': order.screeningOrderCandidate.email,
            'checkPurpose': order.screeningOrderCandidate.purposeLabel,
            'orderDate': formatDateTime(order.creationTime),
            'daysRemaining': new PercentBarComponent(),
            'remainingSLA': order.remainingDays,
            'screeningSLA': order.refundDays,
            'percCompleted': (order.status === 'INIT' || order.status === 'IN_PROGRESS') && orderStatus !== 'UNPAID' ? order.remainingDays / order.refundDays * 100 : '-',
            'status': new StatusIconComponent(),
            'statusValue': orderStatus,
            'statusType': status.statusType,
            'statusColor': status.statusColor,
            'statusBorder': status.statusBorder,
            'statusFill': status.statusFill,
            'statusSize': status.statusSize,
            'optionsDropdown': new OptionsDropdownComponent(this.toastyService, this.httpService, this.modalService, this.tableService),
            'certificate': new DownloadIconComponent(this.httpService),
            'downloadIconShown': order.candidateCompleted,
            'certificateFileName': order.certificateFileName,
            'creditReportFileName': order.creditReportFileName,
            'amlReportFileName': order.amlReportFileName,
          };

          // Live ID Check
          orderObject['liveIdCheck'] =
            `<div class="filter-icon-wrapper ${order.liveIdCheck ? 'PASSED' : 'IN_PROGRESS'}">
                <i class="material-icons small">${order.liveIdCheck ? 'check' : 'clear'}</i>
             </div>`;

          // Credit Check
          orderObject['creditReport'] =
            `<div class="filter-icon-wrapper ${order.creditReport ? 'PASSED' : 'IN_PROGRESS'}">
                <i class="material-icons small">${order.creditReport ? 'check' : 'clear'}</i>
             </div>`;

          // AML Check
          orderObject['amlCheck'] =
            `<div class="filter-icon-wrapper ${order.amlCheck ? 'PASSED' : 'IN_PROGRESS'}">
                <i class="material-icons small">${order.amlCheck ? 'check' : 'clear'}</i>
             </div>`;

          // marketing
          orderObject['marketingOptIn'] =
            `<div class="filter-icon-wrapper ${order.orderer.marketingEnabled ? 'PASSED' : 'IN_PROGRESS'}">
                <i class="material-icons small">${order.orderer.marketingEnabled ? 'check' : 'clear'}</i>
             </div>`;
          // orderObject['certificate'] = `<div class="filter-icon-wrapper download" (click)="download()"><i class="material-icons small">download</i></div>`;

          this.data.push(orderObject);
        }).bind(this));
        this.configObject.data = this.data;
        const pageNext = pageNum < response['pages'] ? pageNum + 1 : null;
        const pagePrev = pageNum === 1 ? null : pageNum - 1;
        this.tableInfo = {
          'pageNext': pageNext,
          'pageCurrent': pageNum,
          'pagePrevious': pagePrev,
          'pageTotal': response['pages'],
          'recordLength': pageSize,
          'recordsAfterFilter': response['count'],
          'recordsAfterSearch': response['count'],
          'recordsAll': response['count']
        };
        this.configObject.info = <GtInformation>this.tableInfo;
      } else if (!isNullOrUndefined(response['count'])) {
        this.configObject.data = [];
        this.tableInfo = {
          'pageNext': null,
          'pageCurrent': pageNum,
          'pagePrevious': null,
          'pageTotal': response['pages'],
          'recordFrom': response['count'],
          'recordLength': pageSize,
          'recordsAfterFilter': response['count'],
          'recordsAfterSearch': response['count'],
          'recordsAll': response['count']
        };
        this.configObject.info = <GtInformation>this.tableInfo;
      }
    }, err => {
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
    }, () => {
      setTimeout(() => {
        _.forEach(candidatesOverviewDashboardColumns, (function (column) {
          if (isNullOrUndefined(this.columnConfig) || _.indexOf(this.columnConfig.columns, column.name) === -1) {
            const columnElements = document.getElementsByClassName(_.kebabCase(column.name) + '-column');
            _.forEach(columnElements, (function (elem) {
              elem.classList.add('visibility-none');
            }).bind(this));
          }
        }).bind(this));
      }, 0);
    });
  }

  trigger = ($event) => {
    switch ($event.name) {
      case 'gt-row-length-changed':
        this.records = $event.value;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(this.liveIdOrdersTable.gtInfo.pageCurrent, this.records, this.query);
        break;
      case 'gt-page-changed':
        this.page = $event.value.pageCurrent;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(this.page, this.liveIdOrdersTable.gtInfo.recordLength, this.query, this.sortBy, this.sortOrder);
        break;
      case 'gt-sorting-applied':
        let sortBy;
        let sortOrder = 'ASC';
        if ($event.value[0].charAt(0) === '-' && $event.value[0].charAt(0) !== '$') {
          sortOrder = 'DESC';
          sortBy = $event.value[0].slice(1, $event.value[0].length);
        } else if ($event.value[0].charAt(0) !== '$') {
          sortBy = $event.value[0];
        }
        this.sortBy = sortBy;
        this.sortOrder = sortOrder;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(
          this.liveIdOrdersTable.gtInfo.pageCurrent,
          this.liveIdOrdersTable.gtInfo.recordLength,
          this.query, this.sortBy, this.sortOrder
        );
        break;
      default:
        break;
    }
  }

  resetSearch() {
    this.query = '';
    this.tableService.changeOptions({
      page: this.page,
      records: this.records,
      query: this.query
    });
    this.searchControl.setValue('');
  }

  private applyWrappers() {
    // XA-1282 Upgrade Angular
    if (document.querySelector(".scrolling.overview")) return;

    const table = document.getElementsByTagName('table')[0];
    const wrapper = document.createElement('div');
    wrapper.classList.add('wrapper');
    const scrolling = document.createElement('div');
    scrolling.classList.add('scrolling');
    scrolling.classList.add('overview');
    table.parentNode.insertBefore(scrolling, table);
    scrolling.appendChild(table);
    scrolling.parentNode.insertBefore(wrapper, scrolling);
    wrapper.appendChild(scrolling);
  }

  getStatusProperties(value: string) {
    const res = {
      'statusType': '',
      'statusColor': '',
      'statusBorder': '#DEE0E0',
      'statusFill': '',
      'statusSize': 'small'
    };
    _.forEach(this.LIVE_ID_CHECK_ORDER_STATUSES, function (filter) {
      if (filter.name === value) {
        res.statusType = filter.icon;
        res.statusColor = filter.color;
        res.statusBorder = filter.border;
        res.statusFill = filter.fill;
        res.statusSize = filter.size;
      }
    });
    return res;
  }

  filterInfoToStatus(filter: any) {
    return {
      'statusValue': filter.value,
      'statusType': filter.icon,
      'statusColor': filter.color,
      'statusBorder': filter.border,
      'statusFill': filter.fill,
      'statusSize': filter.size,
    };
  }

  onCandidateCompletedChange() {
    this.candidateCompleted = !this.candidateCompleted;
    this.getData(this.liveIdOrdersTable.gtInfo.pageCurrent, this.records, this.query);
  }

  public download() {
    console.log('do');
  }
}
