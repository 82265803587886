import { Component, Input, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

type ControlOnChangeFn<T> = (value: T) => void;
type ControlOnTouchedFn = () => void;

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.sass'],
})
export class RadioComponent<T> implements ControlValueAccessor {

  constructor(
    @Optional() private ngControl: NgControl,
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  @Input()
  public name: string;

  @Input()
  public label: string;

  @Input()
  public isInvalid: boolean;

  @Input()
  public formControlName;

  @Input()
  public last = false;

  @Input()
  public value: T;

  public groupValue: T;

  private onChangeFn: ControlOnChangeFn<T>;
  private onTouchedFn: ControlOnTouchedFn;

  public getIsInvalid(): boolean {
    return this.ngControl && this.ngControl.touched && (this.ngControl.invalid || this.isInvalid );
  }

  public onInputChange(): void {
    if (this.onChangeFn) {
      this.onChangeFn(this.value);
    }
  }

  public writeValue(groupValue: T): void {
    this.groupValue = groupValue;
  }
  public registerOnChange(fn: ControlOnChangeFn<T>): void {
    this.onChangeFn = fn;
  }
  public registerOnTouched(fn: ControlOnTouchedFn): void {
    this.onTouchedFn = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }
}
