import {Component, OnInit} from "@angular/core";
import {DashboardAttribute, ATTRIBUTE_TYPES_MAP} from "../dashboard-attribute.class";
import {DynamicFormService} from "../../../../services/dynamic-form.service";
import {TEL_TYPE} from "../../../../../../data/variables.data";

@Component({
  moduleId: module.id,
  selector: 'xavier-dashboard-tel',
  templateUrl: 'dashboard-tel.component.html',
  styleUrls: ['dashboard-tel.stylesheet.sass']
})

export class DashboardTelAttribute extends DashboardAttribute implements OnInit {

  constructor(public dynamicFormService: DynamicFormService) {
    super(dynamicFormService);
  }

  ngOnInit() {
    let formGroup = this.dynamicFormService.getFormGroup(this.formGroupId);
    let formControl = this.dynamicFormService.attributeToFormControl(this.data);

    formGroup.addControl(this.formGroupId+'-'+this.data.id, formControl);

    this.formGroup = formGroup;
    this.formControl = formControl;
  }

}

ATTRIBUTE_TYPES_MAP.set(TEL_TYPE, DashboardTelAttribute);
