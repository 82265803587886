import {Component, Input, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {Router} from "@angular/router";

@Component({
  moduleId: module.id,
  selector: 'xavier-admintemplate',
  templateUrl: 'admintemplate.component.html',
  styleUrls: ['admintemplate.stylesheet.sass', 'admintemplate-mediaqueries.stylesheet.sass']
})

export class AdminTemplateComponent implements OnInit {

  @Input() displayName: string;
  @Input() candidateScreening: boolean = false;

  footerCopyrightDate = new Date();

  constructor(private titleService: Title, private router: Router) {

  }

  ngOnInit() {
    this.titleService.setTitle('Dashboard - Xavier Eurocom CI');
    if (this.router.url.indexOf('candidate-screening') >= 0)
      this.candidateScreening = true;
  }

  isReferenceForm() {
    if (this.router.url.indexOf('candidate-reference-form') >= 0)
      return true;
  }

  isCandidateScreeningForm() {
    if (this.router.url.indexOf('candidate-screening') >= 0)
      return true;
  }

  routeClass() {
    if (this.router.url.indexOf('candidate-reference-form') >= 0)
      return 'reference-form';
    if (this.router.url.indexOf('candidate-screening') >= 0 ||
        this.router.url.indexOf('candidates') >= 0 ||
        this.router.url.indexOf('checks') >= 0 ||
        this.router.url.indexOf('clients') >= 0 ||
        this.router.url.indexOf('screenings') >= 0 ||
        this.router.url.indexOf('users') >= 0 ||
        this.router.url.indexOf('liveid-orders') >= 0)
      return 'dashboard';
    if (this.router.url.indexOf('edit') >= 0)
      return 'edit';
  }
}
