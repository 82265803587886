import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastyService } from "ng2-toasty";
import { addToaster, addToasterWithMessage } from "../../../../utils/functions.utils";
import { environment } from '../../../../../environments/environment';
import { UserAuth } from "../../../../../core/userAuth.core";
import { Validators } from "@angular/forms";
import { phoneValidator } from "../../../../utils/form.validators";
import { filter, take } from "rxjs/operators";

const countryCodes = [
  {
    code: "GB",
    prefix: "+44",
  },
  {
    code: "US",
    prefix: "+1",
  },
  {
    code: "EG",
    prefix: "+20",
  },
  {
    code: "ZA",
    prefix: "+27",
  },
  {
    code: "GR",
    prefix: "+30",
  },
  {
    code: "NL",
    prefix: "+31",
  },
  {
    code: "BE",
    prefix: "+32",
  },
  {
    code: "FR",
    prefix: "+33",
  },
  {
    code: "ES",
    prefix: "+34",
  },
  {
    code: "HU",
    prefix: "+36",
  },
  {
    code: "IT",
    prefix: "+39",
  },
  {
    code: "RO",
    prefix: "+40",
  },
  {
    code: "CH",
    prefix: "+41",
  },
  {
    code: "AT",
    prefix: "+43",
  },
  {
    code: "DK",
    prefix: "+45",
  },
  {
    code: "SE",
    prefix: "+46",
  },
  {
    code: "NO",
    prefix: "+47",
  },
  {
    code: "PL",
    prefix: "+48",
  },
  {
    code: "DE",
    prefix: "+49",
  },
  {
    code: "PE",
    prefix: "+51",
  },
  {
    code: "MX",
    prefix: "+52",
  },
  {
    code: "CU",
    prefix: "+53",
  },
  {
    code: "AR",
    prefix: "+54",
  },
  {
    code: "BR",
    prefix: "+55",
  },
  {
    code: "CL",
    prefix: "+56",
  },
  {
    code: "CO",
    prefix: "+57",
  },
  {
    code: "VE",
    prefix: "+58",
  },
  {
    code: "MY",
    prefix: "+60",
  },
  {
    code: "AU",
    prefix: "+61",
  },
  {
    code: "ID",
    prefix: "+62",
  },
  {
    code: "PH",
    prefix: "+63",
  },
  {
    code: "NZ",
    prefix: "+64",
  },
  {
    code: "SG",
    prefix: "+65",
  },
  {
    code: "TH",
    prefix: "+66",
  },
  {
    code: "JP",
    prefix: "+81",
  },
  {
    code: "KR",
    prefix: "+82",
  },
  {
    code: "VN",
    prefix: "+84",
  },
  {
    code: "CN",
    prefix: "+86",
  },
  {
    code: "TR",
    prefix: "+90",
  },
  {
    code: "CY",
    prefix: "+90",
  },
  {
    code: "IN",
    prefix: "+91",
  },
  {
    code: "PK",
    prefix: "+92",
  },
  {
    code: "LK",
    prefix: "+94",
  },
  {
    code: "MN",
    prefix: "+95",
  },
  {
    code: "IR",
    prefix: "+98",
  },
  {
    code: "MA",
    prefix: "+212",
  },
  {
    code: "DZ",
    prefix: "+213",
  },
  {
    code: "TN",
    prefix: "+216",
  },
  {
    code: "LY",
    prefix: "+218",
  },
  {
    code: "GM",
    prefix: "+220",
  },
  {
    code: "SN",
    prefix: "+221",
  },
  {
    code: "MR",
    prefix: "+222",
  },
  {
    code: "ML",
    prefix: "+223",
  },
  {
    code: "GN",
    prefix: "+224",
  },
  {
    code: "BF",
    prefix: "+226",
  },
  {
    code: "NE",
    prefix: "+227",
  },
  {
    code: "TG",
    prefix: "+228",
  },
  {
    code: "BJ",
    prefix: "+229",
  },
  {
    code: "LR",
    prefix: "+231",
  },
  {
    code: "SL",
    prefix: "+232",
  },
  {
    code: "GH",
    prefix: "+233",
  },
  {
    code: "NG",
    prefix: "+234",
  },
  {
    code: "CF",
    prefix: "+236",
  },
  {
    code: "CM",
    prefix: "+237",
  },
  {
    code: "CV",
    prefix: "+238",
  },
  {
    code: "ST",
    prefix: "+239",
  },
  {
    code: "GQ",
    prefix: "+240",
  },
  {
    code: "GA",
    prefix: "+241",
  },
  {
    code: "CG",
    prefix: "+242",
  },
  {
    code: "AO",
    prefix: "+244",
  },
  {
    code: "GW",
    prefix: "+245",
  },
  {
    code: "SC",
    prefix: "+248",
  },
  {
    code: "SD",
    prefix: "+249",
  },
  {
    code: "RW",
    prefix: "+250",
  },
  {
    code: "ET",
    prefix: "+251",
  },
  {
    code: "SO",
    prefix: "+252",
  },
  {
    code: "DJ",
    prefix: "+253",
  },
  {
    code: "KE",
    prefix: "+254",
  },
  {
    code: "UG",
    prefix: "+256",
  },
  {
    code: "BI",
    prefix: "+257",
  },
  {
    code: "MZ",
    prefix: "+258",
  },
  {
    code: "ZM",
    prefix: "+260",
  },
  {
    code: "MG",
    prefix: "+261",
  },
  {
    code: "RE",
    prefix: "+262",
  },
  {
    code: "ZW",
    prefix: "+263",
  },
  {
    code: "NA",
    prefix: "+264",
  },
  {
    code: "MW",
    prefix: "+265",
  },
  {
    code: "LS",
    prefix: "+266",
  },
  {
    code: "BW",
    prefix: "+267",
  },
  {
    code: "SZ",
    prefix: "+268",
  },
  {
    code: "KM",
    prefix: "+269",
  },
  {
    code: "YT",
    prefix: "+269",
  },
  {
    code: "SH",
    prefix: "+290",
  },
  {
    code: "ER",
    prefix: "+291",
  },
  {
    code: "AW",
    prefix: "+297",
  },
  {
    code: "FO",
    prefix: "+298",
  },
  {
    code: "GL",
    prefix: "+299",
  },
  {
    code: "GI",
    prefix: "+350",
  },
  {
    code: "PT",
    prefix: "+351",
  },
  {
    code: "LU",
    prefix: "+352",
  },
  {
    code: "IE",
    prefix: "+353",
  },
  {
    code: "IS",
    prefix: "+354",
  },
  {
    code: "MT",
    prefix: "+356",
  },
  {
    code: "CY",
    prefix: "+357",
  },
  {
    code: "FI",
    prefix: "+358",
  },
  {
    code: "BG",
    prefix: "+359",
  },
  {
    code: "LT",
    prefix: "+370",
  },
  {
    code: "LV",
    prefix: "+371",
  },
  {
    code: "EE",
    prefix: "+372",
  },
  {
    code: "MD",
    prefix: "+373",
  },
  {
    code: "AM",
    prefix: "+374",
  },
  {
    code: "BY",
    prefix: "+375",
  },
  {
    code: "AD",
    prefix: "+376",
  },
  {
    code: "MC",
    prefix: "+377",
  },
  {
    code: "SM",
    prefix: "+378",
  },
  {
    code: "VA",
    prefix: "+379",
  },
  {
    code: "UA",
    prefix: "+380",
  },
  {
    code: "CS",
    prefix: "+381",
  },
  {
    code: "HR",
    prefix: "+385",
  },
  {
    code: "SI",
    prefix: "+386",
  },
  {
    code: "BA",
    prefix: "+387",
  },
  {
    code: "MK",
    prefix: "+389",
  },
  {
    code: "LI",
    prefix: "+417",
  },
  {
    code: "CZ",
    prefix: "+420",
  },
  {
    code: "SK",
    prefix: "+421",
  },
  {
    code: "FK",
    prefix: "+500",
  },
  {
    code: "BZ",
    prefix: "+501",
  },
  {
    code: "GT",
    prefix: "+502",
  },
  {
    code: "SV",
    prefix: "+503",
  },
  {
    code: "HN",
    prefix: "+504",
  },
  {
    code: "NI",
    prefix: "+505",
  },
  {
    code: "CR",
    prefix: "+506",
  },
  {
    code: "PA",
    prefix: "+507",
  },
  {
    code: "HT",
    prefix: "+509",
  },
  {
    code: "GP",
    prefix: "+590",
  },
  {
    code: "BO",
    prefix: "+591",
  },
  {
    code: "GY",
    prefix: "+592",
  },
  {
    code: "EC",
    prefix: "+593",
  },
  {
    code: "GF",
    prefix: "+594",
  },
  {
    code: "PY",
    prefix: "+595",
  },
  {
    code: "MQ",
    prefix: "+596",
  },
  {
    code: "SR",
    prefix: "+597",
  },
  {
    code: "UY",
    prefix: "+598",
  },
  {
    code: "NP",
    prefix: "+670",
  },
  {
    code: "GU",
    prefix: "+671",
  },
  {
    code: "NF",
    prefix: "+672",
  },
  {
    code: "BN",
    prefix: "+673",
  },
  {
    code: "NR",
    prefix: "+674",
  },
  {
    code: "PG",
    prefix: "+675",
  },
  {
    code: "TO",
    prefix: "+676",
  },
  {
    code: "SB",
    prefix: "+677",
  },
  {
    code: "VU",
    prefix: "+678",
  },
  {
    code: "FJ",
    prefix: "+679",
  },
  {
    code: "PW",
    prefix: "+680",
  },
  {
    code: "WF",
    prefix: "+681",
  },
  {
    code: "CK",
    prefix: "+682",
  },
  {
    code: "NU",
    prefix: "+683",
  },
  {
    code: "KI",
    prefix: "+686",
  },
  {
    code: "NC",
    prefix: "+687",
  },
  {
    code: "TV",
    prefix: "+688",
  },
  {
    code: "PF",
    prefix: "+689",
  },
  {
    code: "FM",
    prefix: "+691",
  },
  {
    code: "MH",
    prefix: "+692",
  },
  {
    code: "KP",
    prefix: "+850",
  },
  {
    code: "HK",
    prefix: "+852",
  },
  {
    code: "MO",
    prefix: "+853",
  },
  {
    code: "KH",
    prefix: "+855",
  },
  {
    code: "LA",
    prefix: "+856",
  },
  {
    code: "BD",
    prefix: "+880",
  },
  {
    code: "TW",
    prefix: "+886",
  },
  {
    code: "MV",
    prefix: "+960",
  },
  {
    code: "LB",
    prefix: "+961",
  },
  {
    code: "JO",
    prefix: "+962",
  },
  {
    code: "SY",
    prefix: "+963",
  },
  {
    code: "IQ",
    prefix: "+964",
  },
  {
    code: "KW",
    prefix: "+965",
  },
  {
    code: "SA",
    prefix: "+966",
  },
  {
    code: "YE",
    prefix: "+967",
  },
  {
    code: "OM",
    prefix: "+968",
  },
  {
    code: "YE",
    prefix: "+969",
  },
  {
    code: "AE",
    prefix: "+971",
  },
  {
    code: "IL",
    prefix: "+972",
  },
  {
    code: "BH",
    prefix: "+973",
  },
  {
    code: "QA",
    prefix: "+974",
  },
  {
    code: "BT",
    prefix: "+975",
  },
  {
    code: "MN",
    prefix: "+976",
  },
  {
    code: "NP",
    prefix: "+977",
  },
  {
    code: "TJ",
    prefix: "+992",
  },
  {
    code: "TM",
    prefix: "+993",
  },
  {
    code: "AZ",
    prefix: "+994",
  },
  {
    code: "KG",
    prefix: "+996",
  },
  {
    code: "UZ",
    prefix: "+998",
  },
  {
    code: "BS",
    prefix: "+1242",
  },
  {
    code: "BB",
    prefix: "+1246",
  },
  {
    code: "AI",
    prefix: "+1264",
  },
  {
    code: "AG",
    prefix: "+1268",
  },
  {
    code: "KY",
    prefix: "+1345",
  },
  {
    code: "BM",
    prefix: "+1441",
  },
  {
    code: "GD",
    prefix: "+1473",
  },
  {
    code: "TC",
    prefix: "+1649",
  },
  {
    code: "MS",
    prefix: "+1664",
  },
  {
    code: "SC",
    prefix: "+1758",
  },
  {
    code: "PR",
    prefix: "+1787",
  },
  {
    code: "DM",
    prefix: "+1809",
  },
  {
    code: "DO",
    prefix: "+1809",
  },
  {
    code: "TT",
    prefix: "+1868",
  },
  {
    code: "KN",
    prefix: "+1869",
  },
  {
    code: "JM",
    prefix: "+1876",
  },
  {
    code: "GE",
    prefix: "+7880",
  },
  {
    code: "CA",
    prefix: "+1",
  },
  {
    code: "VG",
    prefix: "+1",
  },
  {
    code: "VI",
    prefix: "+1",
  },
  {
    code: "RU",
    prefix: "+7",
  },
  {
    code: "KZ",
    prefix: "+7",
  },
];

@Component({
  moduleId: module.id,
  selector: "xavier-2fa-step1",
  templateUrl: "2fa-step1.component.html",
  styleUrls: ["2fa-step1.component.sass"],
})
export class TFAuthStep1Component implements OnInit {
  disabled = false;

  form: FormGroup = this.fb.group({
    phonePrefix: ["+44"],
    phone: ["", Validators.compose([Validators.required, Validators.minLength(9), phoneValidator])],
  });

  public countryCodes: { code: string; prefix: string }[] = countryCodes;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private userAuth: UserAuth,
    private toastyService: ToastyService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userAuth.phoneNumber$.pipe(
      take(1),
      filter(phoneNumber => Boolean(phoneNumber))
    ).subscribe(phoneNumber => {
      const [phonePrefix, phone] = phoneNumber.split(';');
      if (phonePrefix && phone) {
        this.form.get('phonePrefix').setValue(phonePrefix);
        this.form.get('phone').setValue(phone);
      }
    });
  }

  public onSubmit(): void {
    const [number, prefix] = [this.form.get('phone').value, this.form.get('phonePrefix').value];
    
    if (!this.form.valid || !number || !prefix) {
      return;
    }

    this.disabled = true;
    const submitButton = document.getElementById('submitButton');
    const spinnerWrap = document.createElement('div');
    spinnerWrap.classList.add('spinner-wrap');
    spinnerWrap.classList.add('button');

    submitButton.innerHTML = '';
    submitButton.appendChild(spinnerWrap);

    this.http.post(
      environment.apiUrl + '/_ah/api/user/v1/setupMobileNumber',
      {
        number: number,
        prefix: prefix
      },
      { withCredentials: true }
    ).subscribe(response => {
      this.disabled = false;
      submitButton.innerHTML = '<span>Next</span> <i class="material-icons">&#xE5C8;</i>';

      this.userAuth.phoneNumber$.next(response['prefix'] + ';' + response['number']);
      this.router.navigate(['/two-factor/2']);
    }, error => {
      this.disabled = false;
      submitButton.innerHTML = '<span>Next</span> <i class="material-icons">&#xE5C8;</i>';

      if (error && error.error && error.error.error && error.error.error.message) {
        addToasterWithMessage('error', this.toastyService, error.error.error.message);
      } else {
        addToasterWithMessage('error', this.toastyService, 'Something went wrong. Please try again');
      }
    });
  }
}
