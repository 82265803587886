import {Component, Input} from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'xavier-yoti-logo',
  templateUrl: 'yoti-logo.component.html',
  styleUrls: ['yoti-logo.stylesheet.sass']
})
export class YotiLogoComponent {

  @Input()
  public verifiedValue: string;

  @Input()
  public verified = true;

  constructor() {
  }

}
