<ng2-toasty [position]="'top-center'" class="xavier-toasty"></ng2-toasty>
<div class="xavier-candidates-overview-wrapper container full-width" [@fadeIn]>
  <form>
    <div class="row header">
      <div class="col-sm-6 col-xl-9">
        <h1>Candidates</h1>
      </div>
      <div class="col-sm-6 col-xl-3 text-right" *isAllowed="['CANDIDATE_NEW_ALL', 'CANDIDATE_NEW_COMPANY', 'CANDIDATE_NEW_DIVISION']">
        <button type="button" class="btn btn-block xavier-button continue-step" id="addCandidate" (click)="addCandidate()">
          <i class="material-icons addIcon">&#xE145;</i> <span>Add New Candidate</span>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-xl-2 xavier-dropdown-wrapper">
        <i class="material-icons dropdown-caret no-label">&#xE5C5;</i>
        <select class="form-control xavier-dropdown items-per-page" id="itemsPerPage" #rowLength (change)="candidatesOverviewTable.changeRowLength(rowLength.value)">
          <option *ngFor="let item of itemsPerPageValues" [value]="item.name">{{item.value}}</option>
        </select>
      </div>
      <div class="form-group col-sm-12 col-xl-7 xavier-input-text text-right">
        <div class="input-group">
          <span class="input-group-addon" id="search-addon"><i class="material-icons">&#xE8B6;</i></span>
          <input type="text"
                 #search
                 class="form-control"
                 id="candidatesSearchInput"
                 placeholder="Search candidates..."
                 aria-describedby="search-addon"
                 [formControl]="searchControl">
        </div>
        <i *ngIf="search.value !== ''" class="material-icons search-reset" (click)="resetSearch()">&#xE5C9;</i>
      </div>
      <div class="col-sm-12 col-xl-3 text-right form-group">
        <xavier-columns-dropdown [componentParams]="candidatesColumnsParams" [view]="'CANDIDATE_STATUSES'"></xavier-columns-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-12" [class.hideCheckColumns]="hideCheckColumns">
        <generic-table #candidatesOverviewTable
                       [gtClasses]="'table-sm table-hover candidates-overview'"
                       [gtSettings]="configObject.settings"
                       [gtFields]="configObject.fields"
                       [gtOptions]="{lazyLoad: true, stack: true, rowSelection: true}"
                       [gtInfo]="configObject.info"
                       [(gtData)]="configObject.data"
                       (gtEvent)="trigger($event)">
        </generic-table>
      </div>
      <div class="col-6">
        <gt-pagination [gtClasses]="'pagination-sm'" [genericTable]="candidatesOverviewTable"></gt-pagination>
      </div>
      <div class="col-6">
        <small><gt-table-info class="form-text text-muted mb-2 text-right" [genericTable]="candidatesOverviewTable"></gt-table-info></small>
      </div>
    </div>
  </form>
  <div class="row legend">
    <div class="col-12">
      <span class="text-muted">Statuses Legend</span>
    </div>
    <div class="col-12 icons">
      <div *ngFor="let filter of candidatesOverviewDashboardFilters" class="filter-wrapper">
        <xavier-status-icon *ngIf="filter.value !== 'Terminated'" [row]="filterInfoToStatus(filter)"></xavier-status-icon>
        <span *ngIf="filter.value !== 'Terminated'" class="filter-value">{{filter.value}}</span>
      </div>
    </div>
  </div>
</div>
