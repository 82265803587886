import {Component, Input, Output, QueryList, Renderer2, TemplateRef, ViewChild, ViewChildren} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {GenericTableComponent} from "@angular-generic-table/core";
import {DatePipe} from "@angular/common";
import {AutocompleteSelectDropdown} from "../../../model/autocomplete-select-dropdown.model";
import {AutocompleteSelectDropdownComponent} from "../../autocomplete-select-dropdown/autocomplete-select-dropdown.component";
import {EditClientService} from "../../../services/edit-client.service";
import {CompanyService} from "../../../services/company.service";
import {UserPermission} from "../../../model/permissions.model";
import {UserAuth} from "../../../../../core/userAuth.core";
import {ClientPropertiesComponent} from "../client-properties.component";
import {BsModalService} from "ngx-bootstrap/modal";
import {BsModalRef} from "ngx-bootstrap/modal/bs-modal-ref.service";
import {ToastyService} from "ng2-toasty";
import {fadeIn} from "../../../../../data/animations.data";
import {clientsToasterOptions} from "../../../../../data/variables.data";
import {addToaster, getToasterMessagesLabel, setAsTouched} from "../../../../utils/functions.utils";
import {isNullOrUndefined} from "util";
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'xavier-client-properties-divisions',
  templateUrl: 'divisions.component.html',
  animations: [fadeIn]
})

export class ClientPropertiesDivisionsComponent {

  @ViewChildren(AutocompleteSelectDropdownComponent) autocompleteDropdowns: QueryList<AutocompleteSelectDropdownComponent>;

  @Input() public defaultLineManagerSearchParams: AutocompleteSelectDropdown;
  @Input() public defaultHumanResourcesSearchParams: AutocompleteSelectDropdown;
  @Input() public clientId;
  @Output() public divisionId;

  public divisions;
  public modalType;
  public divisionModalData: FormGroup;
  public divisionModalTitlePrefix;
  public divisionModalSubmitButton;
  public showDivisionModal = false;
  public modalRef: BsModalRef;
  public disabled: boolean = false;

  @ViewChild('divisionTable', { static: true })
  private divisionTable: GenericTableComponent<any, any>;
  private autocompleteDefaultLineManager: string;
  private autocompleteDefaultHumanResources: string;
  private modalConfig = {
    animated: false,
  };

  constructor(private companyService: CompanyService, private editClientService: EditClientService, private fb: FormBuilder,
              private modalService: BsModalService, private renderer: Renderer2, private datepipe: DatePipe,
              private toastyService: ToastyService, private userAuth: UserAuth, public cp: ClientPropertiesComponent) {

  }

  ngOnInit() {
    this.getDivisionData();

    this.divisionModalData = this.fb.group({
      divisionName: ['', Validators.required],
      defaultLineManager: [''],
      defaultHumanResources: ['']
    });

    this.defaultLineManagerSearchParams = new AutocompleteSelectDropdown(
      'defaultLineManager',
      'Default Line Manager',
      'Select default line manager...',
      'arrow_drop_down',
      [],
      false,
      this.divisionModalData.get('defaultLineManager')
    );

    this.defaultHumanResourcesSearchParams = new AutocompleteSelectDropdown(
      'defaultHumanResources',
      'Default Human Resources',
      'Select default human resources...',
      'arrow_drop_down',
      [],
      false,
      this.divisionModalData.get('defaultHumanResources')
    );
  }

  openDivisionModal(type: string, division?: any) {
    this.modalType = type;
    switch (type) {
      case 'add':
        this.divisionId = 0;
        this.divisionModalTitlePrefix = 'Add New';
        this.divisionModalSubmitButton = 'Add New Division';
        break;
      case 'edit':
        this.divisionId = division.id;
        this.divisionModalTitlePrefix = 'Edit';
        this.divisionModalSubmitButton = 'Save Changes';
        this.divisionModalData.get('divisionName').setValue(division.divisionName);
        break;
      default:
        break;
    }

    setTimeout(() => {
      this.autocompleteDropdowns.forEach(autocompleteDropdownsInstance => {
        if (autocompleteDropdownsInstance.componentParams.id == 'defaultLineManager' ||
          autocompleteDropdownsInstance.componentParams.id == 'defaultHumanResources') {
          autocompleteDropdownsInstance.componentParams.formControl.setValue(+this.clientId);
          // set the value to empty and don't emit event to enable the form validation on submit
          // until an actual value is selected
          autocompleteDropdownsInstance.componentParams.formControl.setValue('', {emitEvent: false});
        }

        // set the values of the dropdown in case we're editing a division
        if (type === 'edit' && !isNullOrUndefined(division['lineManager']) && autocompleteDropdownsInstance.componentParams.id == 'defaultLineManager') {
          autocompleteDropdownsInstance.componentParams.formControl.setValue(division['lineManager'].fullName, {emitEvent: false});
          this.autocompleteDefaultLineManager = division['lineManager'].id;
        }
        if (type === 'edit' && !isNullOrUndefined(division['humanResources']) && autocompleteDropdownsInstance.componentParams.id == 'defaultHumanResources') {
          autocompleteDropdownsInstance.componentParams.formControl.setValue(division['humanResources'].fullName, {emitEvent: false});
          this.autocompleteDefaultHumanResources = division['humanResources'].id
        }
      });
    }, 0);

    document.getElementsByTagName('body').item(0).classList.add('xavier-modal-open');
    this.showDivisionModal = true;
  }

  closeDivisionModal() {
    this.divisionModalData.reset(
      { divisionName: '',
        defaultLineManager: '',
        defaultHumanResources: ''
      },
      { onlySelf: true, emitEvent: false }
    );
    document.getElementsByTagName('body').item(0).classList.remove('xavier-modal-open');
    this.showDivisionModal = false;
  }

  onSubmitDivision({ value, valid } : { value: string, valid: boolean }) {
    if (!valid) {
      setAsTouched(this.divisionModalData);
    } else {
      this.disabled = true;
      let submitButton = document.getElementById('submitButton');
      const spinnerWrap = document.createElement('div');
      spinnerWrap.classList.add('spinner-wrap');
      spinnerWrap.classList.add('button');

      submitButton.innerHTML = '';
      submitButton.appendChild(spinnerWrap);

      let body = { 'divisionName': value['divisionName'] };
      if (this.divisionModalData.get('defaultLineManager').value !== '')
        body['lineManager'] = {
          id: this.autocompleteDefaultLineManager,
          fullName: value['defaultLineManager']
        };
      if (this.divisionModalData.get('defaultHumanResources').value !== '')
        body['humanResources'] = {
          id: this.autocompleteDefaultHumanResources,
          fullName: value['defaultHumanResources']
        } ;

      // add division
      if (this.modalType === 'add') {
        this.companyService.createDivision(this.clientId, body).subscribe(response => {
          if (!isNullOrUndefined(response.id)) {
            // push in the front the event log response
            response['timestamp'] = this.cp.createFormattedTimestamp();
            this.cp.eventLog.unshift(response);

            // reload divisions table
            this.getDivisionData();

            this.closeDivisionModal();
            this.disabled = false;
            submitButton.innerHTML = "<span>Add New Division</span>";
            let config = clientsToasterOptions;
            config.msg = getToasterMessagesLabel('created', 'divisions');
            this.toastyService.info(config);
          }
        }, err => {
          this.disabled = false;
          submitButton.innerHTML = "<span>Add New Division</span>";
          let action = 'serverError';
          if (err.status === 403)
            action = '403';
          addToaster('error', this.toastyService, action, 'errors');
        });
      }

      // edit division
      if (this.modalType === 'edit') {
        body['id'] = this.divisionId;
        this.companyService.editDivision(this.clientId, body).subscribe(response => {
          if (!isNullOrUndefined(response.id)) {
            // push in the front the event log response
            response['timestamp'] = this.cp.createFormattedTimestamp();
            this.cp.eventLog.unshift(response);

            // reload divisions table
            this.getDivisionData();

            this.closeDivisionModal();
            this.disabled = false;
            submitButton.innerHTML = "<span>Save Changes</span>";
            let config = clientsToasterOptions;
            config.msg = getToasterMessagesLabel('edited', 'divisions');
            this.toastyService.info(config);
          }
        }, err => {
          this.disabled = false;
          submitButton.innerHTML = "<span>Save Changes</span>";
          let action = 'serverError';
          if (err.status === 403)
            action = '403';
          addToaster('error', this.toastyService, action, 'errors');
        });
      }
    }
  }

  openDeleteDivisionModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.modalConfig);
  }

  deleteDivision() {
    this.disabled = true;
    let deleteButton = document.getElementById('deleteButton');
    const spinnerWrap = document.createElement('div');
    spinnerWrap.classList.add('spinner-wrap');
    spinnerWrap.classList.add('button-reject');

    deleteButton.innerHTML = '';
    deleteButton.appendChild(spinnerWrap);

    this.companyService.deleteDivision(this.clientId, this.divisionId).subscribe(response => {
      if (!isNullOrUndefined(response.id)) {
        // push in the front the event log response
        response['timestamp'] = this.cp.createFormattedTimestamp();
        this.cp.eventLog.unshift(response);

        // reload divisions table
        this.getDivisionData();

        this.closeDivisionModal();
        this.modalRef.hide();
        this.disabled = false;
        deleteButton.innerHTML = "<span>Delete Division</span>";
        let config = clientsToasterOptions;
        config.msg = getToasterMessagesLabel('disabled', 'divisions');
        this.toastyService.info(config);
      }
    }, err => {
      this.disabled = false;
      deleteButton.innerHTML = "<span>Delete Division</span>";
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
    });
  }

  onDropdownEntrySelected(type: string, entry: any) {
    if (entry.hasOwnProperty('fullName') && type == 'lineManager')
      this.autocompleteDefaultLineManager = entry.id;
    if (entry.hasOwnProperty('fullName') && type == 'humanResources')
      this.autocompleteDefaultHumanResources = entry.id;
  }

  getDivisionData() {
    this.companyService.searchDivisionWithPersonnel(this.clientId, '').subscribe(response => {
      if (!isNullOrUndefined(response.items)) {
        this.divisions = response.items;
        let divisionTableData = [];
        _.forEach(response.items, function (division) {
          let divisionObject = {
            'id': division.id,
            'division_name': division.divisionName,
            'line_manager': division.lineManager ? division.lineManager.fullName : '-',
            'human_resources': division.humanResources ? division.humanResources.fullName : '-'
          };
          divisionTableData.push(divisionObject);
        });
        this.divisionTableConfigObject.data = divisionTableData;
      }
    });
  }

  trigger = function($event) {
    switch($event.name) {
      case 'gt-row-select':
      case 'gt-row-deselect':
        if (UserPermission.isAllowed(['DIVISION_EDIT_ALL', 'DIVISION_EDIT_COMPANY'], this.userAuth.getUser().permissionList))
          this.divisions.forEach(division => {
            if ($event.value.changedRow.id == division['id']) {
              this.openDivisionModal('edit', division);
              return;
            }
          });
        break;
      default:
        break;
    }
  };

  resetDivisionSearch(search: any) {
    search.value = '';
    this.divisionTable.gtSearch('');
  }

  public divisionTableConfigObject = {
    settings: [{
      objectKey: 'id',
      visible: false
    }, {
      objectKey: 'division_name',
      sortOrder: 0,
      columnOrder: 0,
      sort: 'asc'
    }, {
      objectKey: 'line_manager',
      sortOrder: 0,
      columnOrder: 1
    }, {
      objectKey: 'human_resources',
      sortOrder: 0,
      columnOrder: 2
    }],
    fields: [{
      name: 'id',
      objectKey: 'id'
    }, {
      name: 'Division Name',
      objectKey: 'division_name',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'Line Manager',
      objectKey: 'line_manager',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'Human Resources',
      objectKey: 'human_resources',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }],
    data: []
  };

}
