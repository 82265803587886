import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../shared-services/http.service';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  private countries$: Observable<any>;

  constructor(private httpService: HttpService) { }

  public get(methodName: string, body: any, params: any, noMethod?: boolean): Observable<any> {
    return this.httpService.get('/_ah/api/countries/v1', methodName, params, noMethod);
  }

  public getCountries() {
    if (!this.countries$) {
      this.countries$ = this.get("list", null, null).pipe(
        map((data) => data.items),
        shareReplay(1)
      );
    }
    return this.countries$;
  }

}
