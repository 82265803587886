import {Component, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {GenericTableComponent} from "@angular-generic-table/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ClientPropertiesComponent} from "../client-properties.component";
import {AutocompleteSelectDropdown} from "../../../model/autocomplete-select-dropdown.model";
import {FormService} from "../../../../registration/services/form.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {BsModalRef} from "ngx-bootstrap/modal/bs-modal-ref.service";
import {UserService} from "../../../services/user.service";
import {CompanyService} from "../../../services/company.service";
import {clientsToasterOptions} from "../../../../../data/variables.data";
import {UserPermission} from "../../../model/permissions.model";
import {UserAuth} from "../../../../../core/userAuth.core";
import {ToastyService} from "ng2-toasty";
import {fadeIn} from "../../../../../data/animations.data";
import {emailValidator, ValidateEmailExists} from "../../../../utils/form.validators";
import {
  addToaster, getFormGroupValidationErrors, getToasterMessagesLabel,
  setAsTouched
} from "../../../../utils/functions.utils";
import {isNullOrUndefined} from "util";
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'xavier-client-properties-users',
  templateUrl: 'users.component.html',
  animations: [fadeIn]
})

export class ClientPropertiesUsersComponent implements OnInit, OnDestroy {

  @Input() public roleSearchParams: AutocompleteSelectDropdown;
  @Input() public clientId;
  @Output() public userId;

  public users;
  public companyDivisions;
  public modalType;
  public userModalData: FormGroup;
  public userModalTitlePrefix;
  public userModalSubmitButton;
  public showUserModal = false;
  public modalRef: BsModalRef;
  public disabled: boolean = false;

  @ViewChild('userTable', { static: true })
  private userTable: GenericTableComponent<any, any>;
  private roleId;
  private divisionsSub;
  private modalConfig = {
    animated: false,
  };

  constructor(private companyService: CompanyService, private userService: UserService, private fb: FormBuilder,
              private formService: FormService, private modalService: BsModalService, private toastyService: ToastyService,
              private userAuth: UserAuth, public cp: ClientPropertiesComponent) {

  }

  ngOnInit() {
    this.getUserData();
    this.divisionsSub = this.companyService.searchDivision(this.clientId, '').subscribe(response => {
      if (!isNullOrUndefined(response.items))
        this.companyDivisions = response.items;
    });

    this.userModalData = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['',
        [Validators.required, emailValidator],
        ValidateEmailExists.createValidator(this.formService)
      ],
      roleName: ['', Validators.required]
    });

    this.roleSearchParams = new AutocompleteSelectDropdown(
      'roleName',
      'Role',
      'Select Role...',
      'arrow_drop_down',
      [],
      false,
      this.userModalData.get('roleName')
    );

    this.userModalData.get('roleName').setValue('');
  }

  ngAfterViewInit() {
    if (!UserPermission.isAllowed(['USER_EDIT_ALL', 'USER_EDIT_COMPANY', 'USER_EDIT_DIVISION'], this.userAuth.getUser().permissionList)) {
      let tables = document.getElementsByTagName('table');
      tables[0].classList.add('tr-no-pointer');
    }
  }

  ngOnDestroy() {
    this.divisionsSub.unsubscribe();
  }

  openUserModal(type: string, user?: any) {
    this.modalType = type;
    switch (type) {
      case 'add':
        this.userId = 0;
        this.userModalTitlePrefix = 'Add New';
        this.userModalSubmitButton = 'Add New User';
        break;
      case 'edit':
        this.userId = user.id;
        this.userModalTitlePrefix = 'Edit';
        this.userModalSubmitButton = 'Save Changes';
        this.userModalData.get('firstName').setValue(user.firstName);
        this.userModalData.get('lastName').setValue(user.lastName);
        this.userModalData.get('email').setValue(user.email);
        this.userModalData.get('roleName').setValue(user.role['name']);
        this.roleId = user.role['id'];
        _.forEach(user.divisions, function(division) {
          setTimeout(() => {
            let checkbox = document.getElementById('division' + division.id);
            if (!isNullOrUndefined(checkbox))
              checkbox.setAttribute('checked', 'checked');
          }, 0);
        });
        break;
      default:
        break;
    }

    document.getElementsByTagName('body').item(0).classList.add('xavier-modal-open');
    this.showUserModal = true;
  }

  onSubmitUser({ value, valid } : { value: string, valid: boolean }) {
    if (this.modalType === 'edit') {
      _.forEach(this.users, (function (user) {
        if (user.id === this.userId && user.email === this.userModalData.get('email').value) {
          let errors = getFormGroupValidationErrors(this.userModalData);
          if (errors.length == 1 && errors[0]['key'] == 'emailExists') {
            valid = true;
            return false;
          }
        }
      }).bind(this));
    }
    if (!valid) {
      setAsTouched(this.userModalData);
    } else {
      this.disabled = true;
      let submitButton = document.getElementById('submitButton');
      const spinnerWrap = document.createElement('div');
      spinnerWrap.classList.add('spinner-wrap');
      spinnerWrap.classList.add('button');

      submitButton.innerHTML = '';
      submitButton.appendChild(spinnerWrap);

      let body = {
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'company': { id: this.clientId },
        'role': { id: this.roleId },
        'divisions': []
      };
      let checkboxes = document.getElementsByClassName('form-check-input');
      _.forEach(checkboxes, function(checkbox) {
        if (checkbox.checked)
          body['divisions'].push({ id: checkbox.id.replace('division', '') });
      });

      // add user
      if (this.modalType === 'add') {
        this.userService.addUser(body).subscribe(response => {
          if (!isNullOrUndefined(response.id)) {
            // push in the front the event log response
            response['timestamp'] = this.cp.createFormattedTimestamp();
            this.cp.eventLog.unshift(response);

            // reload users table
            this.getUserData();

            this.closeUserModal();
            this.disabled = false;
            submitButton.innerHTML = "<span>Add New User</span>";
            let config = clientsToasterOptions;
            config.msg = getToasterMessagesLabel('created', 'users');
            this.toastyService.info(config);
          }
        }, err => {
          this.disabled = false;
          submitButton.innerHTML = "<span>Add New User</span>";
          let action = 'serverError';
          if (err.status === 403)
            action = '403';
          addToaster('error', this.toastyService, action, 'errors');
        });
      }

      // edit user
      if (this.modalType === 'edit') {
        let body = {
          'id': this.userId,
          'firstName': value['firstName'],
          'lastName': value['lastName'],
          'email': value['email'],
          'role': { id: this.roleId },
          'divisions': []
        };
        let checkboxes = document.getElementsByClassName('form-check-input');
        _.forEach(checkboxes, function(checkbox) {
          if (checkbox.checked)
            body['divisions'].push({ id: checkbox.id.replace('division', '') });
        });
        this.userService.editUser(body).subscribe(response => {
          if (!isNullOrUndefined(response.id)) {
            // push in the front the event log response
            response['timestamp'] = this.cp.createFormattedTimestamp();
            this.cp.eventLog.unshift(response);

            // reload users table
            this.getUserData();

            this.closeUserModal();
            this.disabled = false;
            submitButton.innerHTML = "<span>Save Changes</span>";
            let config = clientsToasterOptions;
            config.msg = getToasterMessagesLabel('edited', 'users');
            this.toastyService.info(config);
          }
        }, err => {
          this.disabled = false;
          submitButton.innerHTML = "<span>Save Changes</span>";
          let action = 'serverError';
          if (err.status === 403)
            action = '403';
          addToaster('error', this.toastyService, action, 'errors');
        });
      }
    }
  }

  deleteUser() {
    this.disabled = true;
    let deleteButton = document.getElementById('deleteButton');
    const spinnerWrap = document.createElement('div');
    spinnerWrap.classList.add('spinner-wrap');
    spinnerWrap.classList.add('button-reject');

    deleteButton.innerHTML = '';
    deleteButton.appendChild(spinnerWrap);

    this.userService.disableUser(this.userId).subscribe(response => {
      if (!isNullOrUndefined(response.id)) {
        // push in the front the event log response
        response['timestamp'] = this.cp.createFormattedTimestamp();
        this.cp.eventLog.unshift(response);

        // reload users table
        this.getUserData();

        this.closeUserModal();
        this.modalRef.hide();
        this.disabled = false;
        deleteButton.innerHTML = "<span>Delete User</span>";
        let config = clientsToasterOptions;
        config.msg = getToasterMessagesLabel('disabled', 'users');
        this.toastyService.info(config);
      }
    }, err => {
      this.disabled = false;
      deleteButton.innerHTML = "<span>Delete User</span>";
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
    });
  }

  openDeleteUserModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.modalConfig);
  }

  onDropdownEntrySelected(entry: any) {
    if (entry.hasOwnProperty('name'))
      this.roleId = entry.id;
  }

  getUserData() {
    this.companyService.getCompanyUsers(this.clientId).subscribe(response => {
      if (!isNullOrUndefined(response.items)) {
        this.users = response.items;
        let userTableData = [];
        _.forEach(response.items, function (user) {
          let divisions = _.join(_.map(user.divisions, 'divisionName'), ', ');
          let userObject = {
            'id': user.id,
            'user_name': user.fullName,
            'user_email': user.email,
            'divisions': divisions,
            'role_name': user.role.name
          };
          userTableData.push(userObject);
        });
        this.userTableConfigObject.data = userTableData;
      }
    });
  }

  resetUserSearch(search: any) {
    search.value = '';
    this.userTable.gtSearch('');
  }

  closeUserModal() {
    this.userModalData.reset(
      { firstName: '',
        lastName: '',
        email: '',
        roleName: ''
      },
      { onlySelf: true, emitEvent: false }
    );
    let checkboxes = document.getElementsByClassName('form-check-input');
    _.forEach(checkboxes, function(checkbox) {
      checkbox.checked = false;
    });
    document.getElementsByTagName('body').item(0).classList.remove('xavier-modal-open');
    this.showUserModal = false;
  }

  trigger = function($event) {
    switch($event.name) {
      case 'gt-row-select':
      case 'gt-row-deselect':
        if (UserPermission.isAllowed(['USER_EDIT_ALL', 'USER_EDIT_COMPANY', 'USER_EDIT_DIVISION'], this.userAuth.getUser().permissionList))
          this.users.forEach(user => {
            if ($event.value.changedRow.id == user['id']) {
              this.openUserModal('edit', user);
              return false;
            }
          });
        break;
      default:
        break;
    }
  };

  public userTableConfigObject = {
    settings: [{
      objectKey: 'id',
      visible: false
    }, {
      objectKey: 'user_name',
      sortOrder: 0,
      columnOrder: 0,
      sort: 'asc'
    }, {
      objectKey: 'user_email',
      sortOrder: 0,
      columnOrder: 1
    }, {
      objectKey: 'divisions',
      sortOrder: 0,
      columnOrder: 2
    }, {
      objectKey: 'role_name',
      sortOrder: 0,
      columnOrder: 3
    }],
    fields: [{
      name: 'id',
      objectKey: 'id'
    }, {
      name: 'Name',
      objectKey: 'user_name',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'Email',
      objectKey: 'user_email',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'Divisions',
      objectKey: 'divisions',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'Role',
      objectKey: 'role_name',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }],
    data: []
  };

}
