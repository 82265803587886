import {Injectable} from '@angular/core';

@Injectable()
export class WindowRefService {
  private window: Window = window;

  public get nativeWindow(): Window {
    return this.window;
  }
}
