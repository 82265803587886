<div *ngIf="confirmation" (click)="onCloseClicked()" class="modal fade" bsModal tabindex="-1" role="dialog" aria-hidden="true" [config]="{ show: true }">
  <div class="modal-dialog modal-md confirmation" (click)="$event.stopPropagation()">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title pull-left">{{confirmation.title}}</h2>
        <button type="button" class="close pull-right" aria-label="Close" (click)="onCloseClicked()" *ngIf="!confirmation.rejectLabelHidden">
          <i class="material-icons">close</i>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="form-group col-12 xavier-input-text">
              <p [innerHTML]="confirmation.message"></p>
            </div>
          </div>
          <div class="modal-button-group">
            <button type="button" class="btn xavier-button continue-step weight-normal" (click)="onAcceptClicked()" [disabled]="isAcceptButtonLoading">
              <span [class.invisible]="isAcceptButtonLoading">{{ confirmation.acceptLabel }}</span>
              <div class="button-loader" *ngIf="isAcceptButtonLoading">
                <div class="loader"></div>
              </div>
            </button>
            <button type="submit" class="btn xavier-button-plain" id='saveAsDraft' (click)="onRejectClicked()" *ngIf="!confirmation.rejectLabelHidden" [disabled]="isRejectButtonLoading">
              <span [class.invisible]="isRejectButtonLoading">{{ confirmation.rejectLabel || 'Cancel' }}</span>
              <div class="button-loader" *ngIf="isRejectButtonLoading">
                <div class="loader"></div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<div *ngIf="confirmation" (click)="onCloseClicked()" class="modal fade" bsModal tabindex="-1" role="dialog" aria-hidden="true" [config]="{ show: true }">-->
<!--  <div class="modal-dialog modal-md" (click)="$event.stopPropagation()">-->
<!--    <div class="modal-content">-->
<!--      <div class="modal-header mb-4">-->
<!--        <div class="row">-->
<!--          <div class="col-12">-->
<!--            <h3 class="modal-title pull-left">{{confirmation.title}}</h3>-->
<!--          </div>-->
<!--        </div>-->
<!--        <button type="button" class="close modal-close" aria-label="Close" (click)="onCloseClicked()" *ngIf="!confirmation.rejectLabelHidden">-->
<!--          <span aria-hidden="true"><i class="material-icons">close</i></span>-->
<!--        </button>-->
<!--      </div>-->
<!--      <div class="modal-body">-->
<!--        <ng-container *ngTemplateOutlet="confirmation.template"></ng-container>-->
<!--        <div class="row message-row mb-4">-->
<!--          <div class="col-12">-->
<!--            <p [innerHTML]="confirmation.message"></p>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="modal-buttons">-->
<!--&lt;!&ndash;          <button-loadable [isLoading]="isAcceptButtonLoading" (buttonClicked)="onAcceptClicked()">&ndash;&gt;-->
<!--&lt;!&ndash;            {{ confirmation.acceptLabel }}&ndash;&gt;-->
<!--&lt;!&ndash;          </button-loadable>&ndash;&gt;-->

<!--          <button type="button" class="button-transparent button-bordered" (click)="onRejectClicked()" *ngIf="!confirmation.rejectLabelHidden">-->
<!--            {{confirmation.rejectLabel || 'Cancel'}}-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<!--<ng-template #saveDraftModal>-->
<!--  <div class="modal-header">-->
<!--    <h2 class="modal-title pull-left">Check Not Ready</h2>-->
<!--    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">-->
<!--      <i class="material-icons">close</i>-->
<!--    </button>-->
<!--  </div>-->
<!--  <div class="modal-body">-->
<!--    <div class="container">-->
<!--      <div class="row">-->
<!--        <div class="form-group col-12 xavier-input-text">-->
<!--          You have not yet completed all the required information correctly for this check. You can save as draft, or return to correct it.-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="modal-button-group">-->
<!--        <button type="button" class="btn xavier-button continue-step weight-normal" (click)="closeModal()">-->
<!--          Return-->
<!--        </button>-->
<!--        <button type="submit" class="btn xavier-button-plain" id='saveAsDraft' [disabled]='saveAsDraft' (click)="onSaveAsDraftClick()">-->
<!--          Save as Draft-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-template>-->
