import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {SelectDropdown} from "../../model/select-dropdown.model";
import {FormService} from "../../services/form.service";

@Component({
  moduleId: module.id,
  selector: 'xavier-select-dropdown',
  templateUrl: 'select-dropdown.component.html',
  styleUrls: [
    'select-dropdown.stylesheet.sass'
  ],
  host: {
    '(document:click)': 'onClick($event)',
  },
})

export class SelectDropdownComponent implements OnInit {

  @Input() public componentParams: SelectDropdown;
  @Input() public elements: Array<string>;

  @Output() entrySelectedEmitter = new EventEmitter();
  @Output() createItemEmitter = new EventEmitter();
  @ViewChild('dropdown') dropdown;

  public currentPos: number = -1;
  public dataToDisplay = [];
  public displayValues: boolean = false;
  public displayLoader: boolean = false;

  private postcodeLookupData = [];
  private postCodeRegex = /^[a-zA-Z]{1,2}([0-9]{1,2}|[0-9][a-zA-Z])\s*[0-9][a-zA-Z]{2}$/;

  constructor(private _eref: ElementRef, private registrationFormService: FormService) {

  }

  ngOnInit() {
    this.dataToDisplay = this.componentParams.data;
    this.componentParams.formControl
      .valueChanges
      .debounceTime(1000)
      .filter((value: string) => {
        let postCodeRegExp = new RegExp(this.postCodeRegex);
        let test = postCodeRegExp.test(value);
        if (!test)
          this.dataToDisplay = [];
        return test;
      })
      .subscribe(value => {
        this.displayLoader = true;
        this.registrationFormService.postcodeLookup(value).subscribe(response => {
          if (!this.displayValues)
            this.displayValues = true;
          if (response.Addresses) {
            this.postcodeLookupData = response.Addresses;
            this.currentPos = -1;
            this.dataToDisplay = [];
            if (this.postcodeLookupData.length > 0)
              for (let item of this.postcodeLookupData)
                this.dataToDisplay.push(item);
          }
          if (this.componentParams.resubmit) {
            this.displayValues = false;
            this.componentParams.resubmit = false;
          }
          this.displayLoader = false;
        });
      });
  }

  onWrapClick() {
    if (this.dataToDisplay.length > 0 && !this.displayValues)
      this.displayValues = true;
    else
      this.displayValues = false;
  }

  selectEntry(value) {
    this.entrySelectedEmitter.emit(value);
    this.onWrapClick();
  }

  createItem() {
    this.createItemEmitter.emit();
    this.onWrapClick();
  }

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target) && this.displayValues === true)
      this.displayValues = !this.displayValues;
  }

  onKeyUp(event) {
    if (this.displayValues && event.code == "ArrowDown" && !(this.currentPos >= this.dataToDisplay.length - 1)) {
      this.currentPos++;
      this.dropdown.nativeElement.scrollTo(0, this.currentPos * 44);
    }
    if (this.displayValues && event.code == "ArrowUp" && !(this.currentPos < 1)) {
      this.currentPos--;
    }
    if (this.displayValues && event.code == "Escape")
      this.onWrapClick();
  }

  onKeyDown(event) {
    if (event.code === 'Enter')
      event.preventDefault();
    if (this.displayValues && this.dataToDisplay[this.currentPos] && (event.code === 'Enter' || event.code === 'Tab')) {
      this.entrySelectedEmitter.emit(this.dataToDisplay[this.currentPos]);
      this.onWrapClick();
    }
  }
}
