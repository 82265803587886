import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {HttpService} from "../../shared-services/http.service";
import {Observable} from "rxjs/Observable";

@Injectable()
export class EditClientService {

  private selectedPanel = new Subject<string>();
  selectedPanel$ = this.selectedPanel.asObservable();
  private clientData = new Subject<any>();
  clientData$ = this.clientData.asObservable();

  private overallStatus: string;

  constructor(private httpService: HttpService) {

  }

  public updateClientData(clientId: number, editedProperty: string, company: any) {
    let methodName = 'editClient/' + clientId;
    let body = {
      editedProperty: editedProperty,
      company: company
    };
    return this.post(methodName, body, null, false);
  }

  public updateSignatoryData(signatoryId: number, editedProperty: string, signatory: any) {
    let methodName = 'editLegalSignatory/' + signatoryId;
    let body = {
      editedProperty: editedProperty,
      signatory: signatory
    };
    return this.post(methodName, body, null, false);
  }

  public disableClient(id: number) {
    return this.post('disableClient/' + id, null, null, false);
  }

  public updateClientBinding(clientUpdate: any) {
    this.clientData.next(clientUpdate);
  }

  public getOverallStatus(): string {
    return this.overallStatus;
  }

  public setOverallStatus(value: string) {
    this.overallStatus = value;
  }

  public processApplication(method: string, clientId: number, reason?: string) {
    let methodName = method + 'Application/' + clientId;
    let body = null;
    if (reason)
      body = {
        value: reason
      };
    return this.post(methodName, body, null, false);
  }

  public selectPanel(panel: string) {
    this.selectedPanel.next(panel);
  }

  public post(methodName: string, body: any, params: any, reportProgress: boolean): Observable<any> {
    return this.httpService.post("/_ah/api/company/v1", methodName, body, params, reportProgress);
  }
}

