<div class="dropdown" (click)="toggleDropdown()">
  <div class="dropdown-btn">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
    </svg>
  </div>
  <div class="dropdown-menu-list" #menuList>
    <ul>
        <li><a [routerLink]="" (click)="copyScreeningLink()">Screening Link</a></li>
        <li><a [routerLink]="" (click)="cancelScreening()">Cancel Screening</a></li>
    </ul>
  </div>
</div>

<ng-template #cancelScreeningModal>
  <div class="modal-header">
    <h2 class="modal-title pull-left">Are you sure you want to delete this screening?</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div class="modal-body">
    You will lose all the information for this screening. This action is immediate and cannot be undone.
    <div class="modal-button-group">
      <button type="button" class="btn xavier-button deleteConfirm weight-normal" (click)="confirmDeleteScreening()">
        Cancel Screening
      </button>
      <button type="button" class="btn xavier-button-plain" (click)="closeModal()">
        Close
      </button>
    </div>
  </div>
</ng-template>
