import {Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MultiSelectButtonsComponent} from "../../cmps-parts/multi-select-buttons/multi-select-buttons.component";
import {FormService} from "../../services/form.service";
import {emailValidator, fieldsMatcher, phoneValidator, ukPostcodeValidator, ValidateEmailExists} from '../../../utils/form.validators';
import {setAsTouched} from "../../../utils/functions.utils";
import {AdminUser} from "../../model/adminuser.model";
import {SelectDropdown} from "../../model/select-dropdown.model";
import {ActivatedRoute, Router} from "@angular/router";
import {fadeIn, popInOut, slideIn} from "../../../../data/animations.data";
import {isNullOrUndefined} from "util";
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'xavier-step2',
  templateUrl: 'step2.component.html',
  styleUrls: [
    'step2.stylesheet.sass',
    'step2-mediaqueries.stylesheet.sass'
  ],
  animations: [popInOut, fadeIn, slideIn]
})

export class Step2Component implements OnInit {

  @ViewChildren(MultiSelectButtonsComponent) multiSelectButtons: QueryList<MultiSelectButtonsComponent>;

  @Input() public typeOfEntity: string;
  @Input() public elements: Array<string>;
  @Input() public showCompanyRow: boolean;
  @Input() public showPrivateRow: boolean;
  @Input() public showAddressFields: boolean = false;
  @Input() public entityFormControl: any;
  @Input() public showVatField : boolean = false;
  @Input() public addressLookupParams: SelectDropdown;
  @Input() public step2MultiSelectButtons = [
    {
      "label": "Limited Company / PLC",
      "id": "LIMITED_COMPANY"
    },
    {
      "label": "Organisation",
      "id": "ORGANISATION"
    },
    {
      "label": "Charity",
      "id": "CHARITY"
    },
    {
      "label": "Private Individual",
      "id": "PRIVATE"
    }
  ];

  public multiSelectErrorClass: string = "";
  public registrationData: FormGroup;

  private componentStep = 2;
  private token: string;

  constructor(private fb: FormBuilder, private router: Router, private registrationFormService: FormService,
              private activeRoute: ActivatedRoute) {

  }

  ngOnInit() {
    this.token = this.activeRoute.snapshot.queryParams['token'];
    if (this.token) {
      this.registrationFormService.setResubmit(true);
    }

    if (this.registrationFormService.getCompletedStep() != this.componentStep && !this.registrationFormService.getResubmit()) {
      this.router.navigate(['/registration/' + this.registrationFormService.getCompletedStep()]);
    }

    this.registrationData = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      account: this.fb.group({
        email: ['', {
          validators: [Validators.required, emailValidator],
          asyncValidators: ValidateEmailExists.createValidator(this.registrationFormService),
          updateOn: "blur"
        }],
        emailConfirm: ['', [Validators.required]]
      }, {validator: fieldsMatcher('email', 'emailConfirm')}),
      vatInEU: [''],
      vat: [{ value: '', disabled: true }, Validators.required],
      entityType: [''],
      companyName: ['', Validators.required],
      registrationNumber: ['', Validators.required],
      insuranceNumber: ['', Validators.required],
      address: this.fb.group({
        postcode: ['', [Validators.required, ukPostcodeValidator]],
        addressLine1: ['', Validators.required],
        addressLine2: [],
        city: ['', Validators.required],
        country: ['United Kingdom'],
      }),
      mobilePhone: ['', [Validators.required, phoneValidator]],
      officePhone: ['', [Validators.required, phoneValidator]],
      officePhoneExt: []
    });
    if (!this.registrationFormService.getResubmit())
      this.registrationData.patchValue(this.registrationFormService.getAll());
    else {
      this.registrationFormService.getClientDataByToken(this.token).subscribe(response => {
        if (!_.isEmpty(response)) {
          let adminUser = this.registrationFormService.convertClientData(response);
          this.registrationData.patchValue(adminUser);
          this.registrationFormService.setAdminUser(adminUser);

          this.showAddressFields = true;
          setTimeout(() => {
            this.multiSelectButtons.forEach(multiSelectButtonsInstance => {
              multiSelectButtonsInstance.setActive(adminUser.entityType);
            });
          }, 0);
          this.registrationFormService.setResubmitStatusMsg(response.statusMsg);
          this.registrationFormService.stepsStatusMsg();

          this.registrationData.get('account').disable();
          if (this.registrationData.get('vatInEU').value) {
            this.showVatField = true;
            this.registrationData.get('vat').enable();
          }
        }
      });
    }

    this.registrationData.get('entityType').valueChanges.subscribe(entity => {
      switch (entity) {
        case 'LIMITED_COMPANY': {
          this.registrationData.get('companyName').enable();
          this.registrationData.get('registrationNumber').enable();
          this.registrationData.get('insuranceNumber').setValue('');
          this.registrationData.get('insuranceNumber').disable();
          this.showCompanyRow = true;
          this.showPrivateRow = false;
          this.typeOfEntity = "Company";
          break;
        }
        case 'ORGANISATION': {
          this.registrationData.get('companyName').enable();
          this.registrationData.get('registrationNumber').enable();
          this.registrationData.get('insuranceNumber').setValue('');
          this.registrationData.get('insuranceNumber').disable();
          this.showCompanyRow = true;
          this.showPrivateRow = false;
          this.typeOfEntity = "Organisation";
          break;
        }
        case 'CHARITY': {
          this.registrationData.get('companyName').enable();
          this.registrationData.get('registrationNumber').enable();
          this.registrationData.get('insuranceNumber').setValue('');
          this.registrationData.get('insuranceNumber').disable();
          this.showCompanyRow = true;
          this.showPrivateRow = false;
          this.typeOfEntity = "Charity";
          break;
        }
        case 'PRIVATE': {
          this.registrationData.get('insuranceNumber').enable();
          this.registrationData.get('companyName').setValue('');
          this.registrationData.get('registrationNumber').setValue('');
          this.registrationData.get('companyName').disable();
          this.registrationData.get('registrationNumber').disable();
          this.showPrivateRow = true;
          this.showCompanyRow = false;
          this.typeOfEntity = "Your";
          break;
        }
      }

      this.addressLookupParams = new SelectDropdown(
        'postcode',
        this.typeOfEntity.concat(" Address"),
        "Search Postcode...",
        null,
        "search",
        [],
        this.registrationData.get('address').get('postcode'),
        "Add New Address",
        this.registrationFormService.getResubmit()
      );
    });

    if (this.registrationData.get('vatInEU').value) {
      this.showVatField = true;
      this.registrationData.get('vat').enable();
    }
  }

  onEntrySelected(value: string) {
    if (!isNullOrUndefined(value)) {
      let parsedAddress = value.split(",");
      this.displayAddressFields(parsedAddress[0], parsedAddress[1], parsedAddress[5]);
    }
  }

  displayAddressFields(line1?: string, line2?: string, city?: string) {
    if (!this.showAddressFields)
      this.showAddressFields = true;
    if (line1)
      this.registrationData.get('address.addressLine1').setValue(line1);
    if (line2)
      this.registrationData.get('address.addressLine2').setValue(line2);
    if (city)
      this.registrationData.get('address.city').setValue(city);
  }

  clickCheckbox() {
    if (this.registrationData.get('vat').enabled) {
      this.registrationData.get('vat').disable();
      this.registrationData.get('vat').setValue('');
    }
    else
      this.registrationData.get('vat').enable();
    this.showVatField = !this.showVatField;
  }

  onSubmit({ value, valid } : { value: AdminUser, valid: boolean }) {
    if (this.registrationData.get('entityType').value == '') {
      this.multiSelectErrorClass = "multiSelectError";
    }
    if (!valid) {
      setAsTouched(this.registrationData);
    } else {
      this.registrationFormService.save(value, this.componentStep);
      if (this.token)
        this.router.navigate(['/registration/' + (this.componentStep + 1)], { queryParams: { token: this.token }});
      else
        this.router.navigate(['/registration/' + (this.componentStep + 1)]);
    }
  }

  cancelRegistration() {
    this.router.navigate(['/registration/' + (this.componentStep - 1), { cancel: 1 }]);
  }

  onEnterManuallyClicked() {
    this.showAddressFields = !this.showAddressFields;

    const postcodeFc = this.registrationData.get('address.postcode');
    if (this.showAddressFields)
      postcodeFc.setValidators([Validators.required]);
    else
      postcodeFc.setValidators([Validators.required, ukPostcodeValidator]);
    postcodeFc.updateValueAndValidity();
  }
}
