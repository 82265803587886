import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {AdminUser} from "../../model/adminuser.model";
import {FormService} from "../../services/form.service";

@Component({
  moduleId: module.id,
  selector: 'xavier-step1',
  templateUrl: 'step1.component.html',
  styleUrls: [
    'step1.stylesheet.sass',
    'steps1-mediaqueries.stylesheet.sass'
  ]
})

export class Step1Component implements OnInit, OnDestroy {

  private googleSubscription;
  private componentStep = 1;
  private cancelRegistration: number;

  constructor(private registrationFormService: FormService, private router: Router,
              private activeRoute: ActivatedRoute, private titleService: Title) {
    // this.googleSubscription = this.af.authState.subscribe(user => {
    //   if (user != null) {
    //     if (this.cancelRegistration != null) {
    //       this.af.auth.signOut().then(value => {
    //         this.registrationFormService.setCompletedStep(0);
    //         this.cancelRegistration = null;
    //         this.router.navigate(['/registration/1']);
    //       });
    //     } else {
    //       let adminUser: AdminUser = new AdminUser(user.email, user.uid);
    //       this.registrationFormService.save(adminUser, this.componentStep);
    //       this.signInAndNavigate();
    //     }
    //   } else {
    //     if (this.cancelRegistration != null) {
    //       this.cancelRegistration = null;
    //       this.router.navigate(['/registration/1']);
    //     }
    //   }
    // });

    this.activeRoute.params.subscribe(params => {
      this.cancelRegistration = params['cancel'];
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Create Account - Xavier Eurocom CI');
    this.registrationFormService.reset();
  }

  ngOnDestroy() {
    // this.googleSubscription.unsubscribe();
  }

  googleSignIn() {
    // this.af.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  emailSignIn() {
    this.signInAndNavigate();
  }

  signInAndNavigate() {
    this.registrationFormService.setCompletedStep(this.componentStep + 1);
    this.router.navigate(['/registration/' + (this.componentStep + 1)]);
  }
}
