<div class="form-group col-12 file-uploader-wrapper" [id]="'fileLoaderDrop'+inputId" (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
  <input type="file" [id]="'fileLoader'+inputId" (change)="fileChangeEvent($event, inputId)"
    accept="image/*,application/pdf" required />
  <div [ngClass]="errorClass" class="xavier-file-uploader-drop-zone" [id]="'fileDropArea'+inputId"
    (click)="openDialog('fileLoader'+inputId)">
    <i class="material-icons upload">&#xE2C6;</i>
    <span>Drag & Drop or Browse to upload</span>
  </div>
  <div class="form-group col-12 uploader-small-text">
    Accepted formats: PNG, JPG, PDF. Max size 25 MB
  </div>
</div>
<div class="form-group col-12 visibility-none" [id]="'fileLoading'+inputId">
  <div class="xavier-file-uploader-loading-wrapper">
    <div class="spinner-container"><div class="spinner-wrap"></div></div>
    <div class="info">
      <span>{{fileName}}</span>
      <div *ngIf="progressVisible">
        <div class="progress-bar">
          <div class="uploaded" [ngStyle]="{'width': progress+'%'}"></div>
        </div>
        <div class="percent">{{progress}}% - Uploading</div>
      </div>
    </div>
    <div class="delete-btn" (click)="deleteDocument(inputId)">
      <i class="material-icons delete-icon">&#xE872;</i>
    </div>
  </div>
</div>
<div class="form-group col-12 visibility-none" [id]="'fileUploaded'+inputId">
  <div class="xavier-file-uploader-uploaded-wrapper">
    <div class="preview" [id]="'fileUploadedPreview'+inputId"></div>
    <div class="info">
      <span>{{fileName}}</span>
    </div>
    <div class="delete-btn" (click)="deleteDocument(inputId)">
      <i class="material-icons delete-icon">&#xE872;</i>
    </div>
  </div>
</div>
<div class="form-group col-12 visibility-none" [id]="'fileDelete'+inputId">
  <div class="xavier-file-uploader-delete-wrapper">
    <span [innerHTML]="deleteDescription"></span>
    <button type="button" (click)="deleteOtherDocument(inputId)"
            class="btn btn-block xavier-button delete" [innerHTML]="deleteButtonLabel">
    </button>
  </div>
</div>
