import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ClientPropertiesComponent} from "../client-properties.component";

@Component({
  moduleId: module.id,
  selector: 'xavier-client-properties-signatories',
  templateUrl: 'signatories.component.html'
})

export class ClientPropertiesSignatoriesComponent{

  @Input() public propSignatories;
  @Input() public clientSignatories;
  @Input() public clientId;

  constructor(public cp: ClientPropertiesComponent) {
  }
}
