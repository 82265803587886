<div class="modal fade" bsModal tabindex="-1" role="dialog" aria-hidden="true" [config]="{ show: true, backdrop: 'static' }" [class.bodyOverflow]="bodyOverflow">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title pull-left">
          <ng-content select="[modalTitle]"></ng-content>
        </h2>

        <button type="button" class="close pull-right" aria-label="Close" (click)="cancel.next()">
          <i class="material-icons">close</i>
        </button>
      </div>

      <form *ngIf="!isLoading; else loader" [formGroup]="form" class="modal-body" style="margin-top: 12px">
        <div class="modal-body-column">
          <div class="banner">
            <div>
              <img src="/assets/images/creditsafeLogo.svg">
            </div>

            Starting this screening will trigger automated checks powered by Creditsafe. Please check the data displayed that is due to be sent to Creditsafe and make any minor amendments that may be necessary before continuing.
          </div>

          <ng-container *ngIf="!onlyCreditCheck">
            <div class="column-heading" style="margin-bottom: 12px; margin-top: 24px">
              Reports Required
            </div>

            <div class="x-checkboxes">
              <ng-container *ngIf="!noUkAddresses">
                <input type="checkbox" id="consumerSearch" [formControl]="control('consumerSearch')">
                <label for="consumerSearch" class="x-checkbox-label">
                  Creditsafe Consumer Report
                </label>
              </ng-container>

              <input type="checkbox" id="aml" [formControl]="control('aml')">
              <label for="aml" class="x-checkbox-label">
                AML Report
              </label>
            </div>
          </ng-container>
        </div>

        <div class="modal-body-column">
          <div class="column-heading">
            Candidate
          </div>
          <xavier-check-fix-field *ngIf="enabled('title.value')" [hasCheck]="false">
            <label>Title</label>
            <ng-select [options]="items" [formControl]="control('title.value')" [allowClear]="false" (selected)="select.closeDropdown()" #select></ng-select>
          </xavier-check-fix-field>
          <xavier-check-fix-field *ngIf="enabled('firstName.value')">
            <label>First Name</label>
            <input type="text" [formControl]="control('firstName.value')">
          </xavier-check-fix-field>
          <xavier-check-fix-field *ngIf="enabled('middleName.value')">
            <label>Middle Name</label>
            <input type="text" [formControl]="control('middleName.value')">
          </xavier-check-fix-field>
          <xavier-check-fix-field *ngIf="enabled('lastName.value')">
            <label>Last Name</label>
            <input type="text" [formControl]="control('lastName.value')">
          </xavier-check-fix-field>

          <xavier-check-fix-field *ngIf="enabled('previousFirstName.value')">
            <label>Previous First Name</label>
            <input type="text" [formControl]="control('previousFirstName.value')">
          </xavier-check-fix-field>
          <xavier-check-fix-field *ngIf="enabled('previousLastName.value')">
            <label>Previous Last Name</label>
            <input type="text" [formControl]="control('previousLastName.value')">
          </xavier-check-fix-field>

          <xavier-check-fix-field *ngIf="enabled('dateOfBirth.value')" [hasCheck]="false">
            <label>Date of Birth</label>
            <my-date-picker [options]="dpOptions" [formControl]="control('dateOfBirth.value')" [valueTransformer]="[xdate2mydp, mydp2xdate]"></my-date-picker>
          </xavier-check-fix-field>

          <ng-container *ngIf="!onlyCreditCheck">
            <xavier-check-fix-field *ngIf="enabled('documentNumber.value')" [hasCheck]="false">
              <label>{{ control('documentType').value === 'PASSPORT' ? 'Passport MRZ' : 'Driving License No.'}}</label>
              <!-- <input type="text" [formControl]="control('documentNumber.value')"> -->
              <textarea [formControl]="control('documentNumber.value')" maxlength="255"></textarea>
            </xavier-check-fix-field>

            <xavier-check-fix-field *ngIf="enabled('documentIssuingCountry.value')" [hasCheck]="false">
              <label>Issuing Country</label>
              <ng-select [options]="countryCodes" [formControl]="control('documentIssuingCountry.value')" [allowClear]="false" (selected)="countrySelect.closeDropdown()" #countrySelect></ng-select>
            </xavier-check-fix-field>

            <xavier-check-fix-field *ngIf="enabled('documentExpiryDate.value')">
              <label>Expiry Date</label>
              <my-date-picker [options]="dpOptionsNoLimit" [formControl]="control('documentExpiryDate.value')" [valueTransformer]="[xdate2mydp, mydp2xdate]"></my-date-picker>
            </xavier-check-fix-field>

            <xavier-check-fix-field *ngIf="enabled('documentIssueDate.value')">
              <label>Date of Issue</label>
              <my-date-picker [options]="dpOptionsNoLimit" [formControl]="control('documentIssueDate.value')" [valueTransformer]="[xdate2mydp, mydp2xdate]"></my-date-picker>
            </xavier-check-fix-field>

            <xavier-check-fix-field *ngIf="enabled('documentPostcode.value')">
              <label>Postcode</label>
              <input type="text" [formControl]="control('documentPostcode.value')">
            </xavier-check-fix-field>
          </ng-container>


          <ng-container *ngIf="enabled('currentAddress')" formGroupName="currentAddress">
            <div class="column-heading">
              Current Address
            </div>

            <xavier-check-fix-field *ngIf="enabled('currentAddress.buildingName.value')">
              <label>House Name</label>
              <input type="text" [formControl]="control('currentAddress.buildingName.value')">
            </xavier-check-fix-field>
            <xavier-check-fix-field *ngIf="enabled('currentAddress.buildingNo.value')">
              <label>House Number</label>
              <input type="text" [formControl]="control('currentAddress.buildingNo.value')">
            </xavier-check-fix-field>

            <xavier-check-fix-field *ngIf="enabled('currentAddress.street.value')">
              <label>Street Name</label>
              <input type="text" [formControl]="control('currentAddress.street.value')">
            </xavier-check-fix-field>
            <xavier-check-fix-field *ngIf="enabled('currentAddress.city.value')">
              <label>Town/City</label>
              <input type="text" [formControl]="control('currentAddress.city.value')">
            </xavier-check-fix-field>
            <xavier-check-fix-field *ngIf="enabled('currentAddress.postCode.value')">
              <label>Postcode</label>
              <input type="text" [formControl]="control('currentAddress.postCode.value')">
            </xavier-check-fix-field>

            <xavier-check-fix-field *ngIf="enabled('currentAddress.startDate.value')">
              <label>Date Moved In</label>
              <my-date-picker [options]="dpOptions" [formControl]="control('currentAddress.startDate.value')" [valueTransformer]="[xdate2mydp, mydp2xdate]"></my-date-picker>
            </xavier-check-fix-field>
            <xavier-check-fix-field *ngIf="enabled('currentAddress.endDate.value')">
              <label>Date Moved Out</label>
              <my-date-picker [options]="dpOptions" [formControl]="control('currentAddress.endDate.value')" [valueTransformer]="[xdate2mydp, mydp2xdate]"></my-date-picker>
            </xavier-check-fix-field>
          </ng-container>

          <ng-container *ngIf="enabled('previousAddress')" formGroupName="previousAddress">
            <div class="column-heading">
              Previous Address
            </div>

            <xavier-check-fix-field *ngIf="enabled('previousAddress.buildingName.value')">
              <label>House Name</label>
              <input type="text" [formControl]="control('previousAddress.buildingName.value')">
            </xavier-check-fix-field>
            <xavier-check-fix-field *ngIf="enabled('previousAddress.buildingNo.value')">
              <label>House Number</label>
              <input type="text" [formControl]="control('previousAddress.buildingNo.value')">
            </xavier-check-fix-field>

            <xavier-check-fix-field *ngIf="enabled('previousAddress.street.value')">
              <label>Street Name</label>
              <input type="text" [formControl]="control('previousAddress.street.value')">
            </xavier-check-fix-field>
            <xavier-check-fix-field *ngIf="enabled('previousAddress.city.value')">
              <label>Town/City</label>
              <input type="text" [formControl]="control('previousAddress.city.value')">
            </xavier-check-fix-field>
            <xavier-check-fix-field *ngIf="enabled('previousAddress.postCode.value')">
              <label>Postcode</label>
              <input type="text" [formControl]="control('previousAddress.postCode.value')">
            </xavier-check-fix-field>

            <xavier-check-fix-field *ngIf="enabled('previousAddress.startDate.value')">
              <label>Date Moved In</label>
              <my-date-picker [options]="dpOptions" [formControl]="control('previousAddress.startDate.value')" [valueTransformer]="[xdate2mydp, mydp2xdate]"></my-date-picker>
            </xavier-check-fix-field>
            <xavier-check-fix-field *ngIf="enabled('previousAddress.endDate.value')">
              <label>Date Moved Out</label>
              <my-date-picker [options]="dpOptions" [formControl]="control('previousAddress.endDate.value')" [valueTransformer]="[xdate2mydp, mydp2xdate]"></my-date-picker>
            </xavier-check-fix-field>
          </ng-container>
        </div>
      </form>

      <!-- <pre> -->
<!-- {{ form.value|json }} -->
      <!-- </pre> -->

      <div class="modal-footer-sticky">
        <xavier-button [isLoading]="isSubmitting" (click)="onSubmit()">
          <ng-content select="[buttonTitle]"></ng-content>
        </xavier-button>

        <button type="button" class="btn xavier-button-plain" (click)="cancel.next()">Cancel</button>
      </div>
    </div>
  </div>
</div>

<ng-template #loader>
  <div class="spinner-container not-button"><div class="spinner-wrap"></div></div>
</ng-template>
