export class EventUtils {

  static preventDefaultAndStopPropagation(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  static getFiles(event): File[] {
    const fileList: FileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    const files = Array.from(fileList);

    // clear value so that the same file can be uploaded immediately again
    event.target.value = '';

    return files;
  }

  static getFile(event): File {
    const files = this.getFiles(event);
    return files.length > 0 ? files[0] : null;
  }
}
