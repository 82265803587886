/**
 * How to use the UserPermission model:
 *
 * - a directive is provided for template-type operations, described in the ./src/modules/utils/is-allowed.directive.ts file
 * syntax: *isAllowed="[<permissions-list-array>]"
 *
 * example: <div *isAllowed="['COMPANY_MENU', 'COMPANY_LIST']">...</div>
 * this element will be shown if the user has the COMPANY_MENU OR COMPANY_LIST permission
 *
 * - a static method is provided for typescript classes, described here in the constructor
 * isAllowed(permissions: string[], permissionList)
 *
 * example: UserPermission.isAllowed(['COMPANY_MENU', 'COMPANY_LIST'], this.userAuth.getUser().permissionList)
 * this call will return true if the user has the COMPANY_MENU OR COMPANY_LIST permission,
 * the permissionList is typically obtained from the UserAuth core class.
 *
 * - it's also possible to deactivate a whole route, for an example see ./src/modules/admin/admin.routing.ts
 * it will use the native CanActivate feature of Angular and the Guard class uses the aforementioned static method
**/

import * as _ from 'lodash';

export type PermissionObject = "ADMIN" | "ATTRIBUTE" | "CANDIDATE" | "CHECK" | "COMPANY" | "DIVISION" | "GROUP" | "HOLIDAY" | "ROLE" | "SCREENING" | "USER" | "LID_CHECK_ORDER" | "ID_VERIFICATION";
export type PermissionAction = "GENERAL" | "MENU" | "PERSONNEL" | "SCREENER" | "PERMISSION" | "EMAIL" | "STATUS" | "MANAGEMENT" | "COMMENT" | "FORM" | "COST" | "DETAILS" | "FILE" | "REPORT";
export type PermissionAccess = "LIST" | "VIEW" | "PREVIEW" | "NEW" | "EDIT" | "DISABLE" | "DELETE" | "ASSIGN" | "ACTIVATE" | "RESEND";
export type PermissionScope = "ALL" | "CANDIDATE" | "COMPANY" | "DIVISION" | "LIMITED" | "OWN" | "SCREENER" | "SELF";

export class UserPermission {
  constructor(public object: PermissionObject, public action: PermissionAction, public access: PermissionAccess, public scope: PermissionScope) { }

  public static isAllowed(permissions: string[], permissionList) {
    return _.intersection(permissions, permissionList).length !== 0;
  }
}

export const PermissionList = {
  // company
  COMPANY_MENU: new UserPermission("COMPANY", "MENU", "VIEW", null),
  COMPANY_LIST: new UserPermission("COMPANY", "GENERAL", "LIST", null),
  COMPANY_DETAILS_ALL: new UserPermission("COMPANY", "DETAILS", "VIEW", "ALL"),
  COMPANY_DETAILS_OWN: new UserPermission("COMPANY", "DETAILS", "VIEW", "OWN"),
  COMPANY_NEW: new UserPermission("COMPANY", "GENERAL", "NEW", null),
  COMPANY_EDIT_ALL: new UserPermission("COMPANY", "GENERAL", "EDIT", "ALL"),
  COMPANY_EDIT_OWN: new UserPermission("COMPANY", "GENERAL", "EDIT", "OWN"),
  COMPANY_DISABLE: new UserPermission("COMPANY", "GENERAL", "DISABLE", null),
  COMPANY_STATUS_EDIT: new UserPermission("COMPANY", "STATUS", "EDIT", null),

  MY_COMPANY_MENU: new UserPermission("COMPANY", "MENU", "VIEW", "OWN"),

  // Divisions
  DIVISION_MENU: new UserPermission("DIVISION", "MENU", "VIEW", null),
  DIVISION_LIST_ALL: new UserPermission("DIVISION", "GENERAL", "LIST", "ALL"),
  DIVISION_LIST_COMPANY: new UserPermission("DIVISION", "GENERAL", "LIST", "COMPANY"),
  DIVISION_DETAILS: new UserPermission("DIVISION", "DETAILS", "VIEW", null),
  DIVISION_NEW_ALL: new UserPermission("DIVISION", "GENERAL", "NEW", "ALL"),
  DIVISION_NEW_COMPANY: new UserPermission("DIVISION", "GENERAL", "NEW", "COMPANY"),
  DIVISION_EDIT_ALL: new UserPermission("DIVISION", "GENERAL", "EDIT", null),
  DIVISION_EDIT_COMPANY: new UserPermission("DIVISION", "GENERAL", "EDIT", "COMPANY"),
  DIVISION_DISABLE_ALL: new UserPermission("DIVISION", "GENERAL", "DISABLE", null),
  DIVISION_DISABLE_COMPANY: new UserPermission("DIVISION", "GENERAL", "DISABLE", "COMPANY"),

  // User
  USER_MENU: new UserPermission("USER", "MENU", "VIEW", null),
  USER_LIST_ALL: new UserPermission("USER", "GENERAL", "LIST", null),
  USER_LIST_COMPANY: new UserPermission("USER", "GENERAL", "LIST", "COMPANY"),
  USER_LIST_DIVISION: new UserPermission("USER", "GENERAL", "LIST", "DIVISION"),
  USER_DETAILS_ALL: new UserPermission("USER", "DETAILS", "VIEW", null),
  USER_DETAILS_COMPANY: new UserPermission("USER", "DETAILS", "VIEW", "COMPANY"),
  USER_NEW_ALL: new UserPermission("USER", "GENERAL", "NEW", null),
  USER_NEW_COMPANY: new UserPermission("USER", "GENERAL", "NEW", "COMPANY"),
  USER_EDIT_ALL: new UserPermission("USER", "GENERAL", "EDIT", null),
  USER_EDIT_COMPANY: new UserPermission("USER", "GENERAL", "EDIT", "COMPANY"),
  USER_ACTIVATE: new UserPermission("USER", "GENERAL", "ACTIVATE", null),
  USER_DELETE: new UserPermission("USER", "GENERAL", "DELETE", null),
  USER_DISABLE_ALL: new UserPermission("USER", "GENERAL", "DISABLE", "ALL"),
  USER_DISABLE_COMPANY: new UserPermission("USER", "GENERAL", "DISABLE", "COMPANY"),
  USER_NEW_DIVISION: new UserPermission("USER", "GENERAL", "NEW", "DIVISION"),
  USER_EDIT_DIVISION: new UserPermission("USER", "GENERAL", "EDIT", "DIVISION"),

  // Candidate
  CANDIDATE_MENU: new UserPermission("CANDIDATE", "MENU", "VIEW", null),
  CANDIDATE_LIST_ALL: new UserPermission("CANDIDATE", "GENERAL", "LIST", "ALL"),
  CANDIDATE_LIST_COMPANY: new UserPermission("CANDIDATE", "GENERAL", "LIST", "COMPANY"),
  CANDIDATE_LIST_DIVISION: new UserPermission("CANDIDATE", "GENERAL", "LIST", "DIVISION"),
  CANDIDATE_LIST_OWN: new UserPermission("CANDIDATE", "GENERAL", "LIST", "OWN"),
  CANDIDATE_DETAILS: new UserPermission("CANDIDATE", "DETAILS", "VIEW", null),
  CANDIDATE_NEW_ALL: new UserPermission("CANDIDATE", "GENERAL", "NEW", "ALL"),
  CANDIDATE_NEW_COMPANY: new UserPermission("CANDIDATE", "GENERAL", "NEW", "COMPANY"),
  CANDIDATE_NEW_DIVISION: new UserPermission("CANDIDATE", "GENERAL", "NEW", "DIVISION"),

  CANDIDATE_EDIT: new UserPermission("CANDIDATE", "GENERAL", "EDIT", null),
  CANDIDATE_EDIT_COMPANY: new UserPermission("CANDIDATE", "GENERAL", "EDIT", "COMPANY"),
  CANDIDATE_PERSONNEL: new UserPermission("CANDIDATE", "PERSONNEL", "EDIT", null),
  CANDIDATE_PERSONNEL_SELF: new UserPermission("CANDIDATE", "PERSONNEL", "EDIT", "SELF"),
  CANDIDATE_SCREENER_ASSIGN: new UserPermission("CANDIDATE", "SCREENER", "ASSIGN", null),
  CANDIDATE_SCREENER_DELETE: new UserPermission("CANDIDATE", "SCREENER", "DELETE", null),

  CANDIDATE_EMAIL: new UserPermission("CANDIDATE", "EMAIL", "NEW", null),
  CANDIDATE_STATUS_ALL: new UserPermission("CANDIDATE", "STATUS", "VIEW", "ALL"),
  CANDIDATE_STATUS_COMPANY: new UserPermission("CANDIDATE", "STATUS", "VIEW", "COMPANY"),
  CANDIDATE_STATUS_DIVISION: new UserPermission("CANDIDATE", "STATUS", "VIEW", "DIVISION"),
  CANDIDATE_STATUS_OWN: new UserPermission("CANDIDATE", "STATUS", "VIEW", "OWN"),
  CANDIDATE_STATUS_EDIT: new UserPermission("CANDIDATE", "STATUS", "EDIT", null),

  CANDIDATE_COMMENT_NEW: new UserPermission("CANDIDATE", "COMMENT", "NEW", null),
  CANDIDATE_COMMENT_VIEW: new UserPermission("CANDIDATE", "COMMENT", "VIEW", null),

  CANDIDATE_MANAGEMENT_VIEW: new UserPermission("CANDIDATE", "MANAGEMENT", "VIEW", null),
  CANDIDATE_RESEND_INVITE: new UserPermission("CANDIDATE", "EMAIL", "RESEND", null),

  // File
  CANDIDATE_FILE_LIST_CANDIDATE: new UserPermission("CANDIDATE", "FILE", "LIST", "CANDIDATE"),
  CANDIDATE_FILE_UPLOAD_CANDIDATE: new UserPermission("CANDIDATE", "FILE", "NEW", "CANDIDATE"),
  CANDIDATE_FILE_DOWNLOAD_CANDIDATE: new UserPermission("CANDIDATE", "FILE", "VIEW", "CANDIDATE"),
  CANDIDATE_FILE_DELETE_CANDIDATE: new UserPermission("CANDIDATE", "FILE", "DELETE", "CANDIDATE"),
  CANDIDATE_FILE_LIST_SCREENER: new UserPermission("CANDIDATE", "FILE", "LIST", "SCREENER"),
  CANDIDATE_FILE_UPLOAD_SCREENER: new UserPermission("CANDIDATE", "FILE", "NEW", "SCREENER"),
  CANDIDATE_FILE_DOWNLOAD_SCREENER: new UserPermission("CANDIDATE", "FILE", "VIEW", "SCREENER"),
  CANDIDATE_FILE_DELETE_SCREENER: new UserPermission("CANDIDATE", "FILE", "DELETE", "SCREENER"),

  // Report
  CANDIDATE_REPORT_VIEW_ALL: new UserPermission("CANDIDATE", "REPORT", "VIEW", "ALL"),
  CANDIDATE_REPORT_VIEW_LIMITED: new UserPermission("CANDIDATE", "REPORT", "VIEW", "LIMITED"),
  CANDIDATE_REPORT_NEW: new UserPermission("CANDIDATE", "REPORT", "NEW", null),

  // Screening
  SCREENING_MENU: new UserPermission("SCREENING", "MENU", "VIEW", null),
  SCREENING_LIST_ALL: new UserPermission("SCREENING", "GENERAL", "LIST", "ALL"),
  SCREENING_LIST_COMPANY: new UserPermission("SCREENING", "GENERAL", "LIST", "COMPANY"),
  SCREENING_LIST_DIVISION: new UserPermission("SCREENING", "GENERAL", "LIST", "DIVISION"),
  SCREENING_NEW_ALL: new UserPermission("SCREENING", "GENERAL", "NEW", "ALL"),
  SCREENING_NEW_COMPANY: new UserPermission("SCREENING", "GENERAL", "NEW", "COMPANY"),
  SCREENING_EDIT_ALL: new UserPermission("SCREENING", "GENERAL", "EDIT", "ALL"),
  SCREENING_EDIT_COMPANY: new UserPermission("SCREENING", "GENERAL", "EDIT", "COMPANY"),
  SCREENING_DISABLE_ALL: new UserPermission("SCREENING", "GENERAL", "DISABLE", "ALL"),
  SCREENING_DISABLE_COMPANY: new UserPermission("SCREENING", "GENERAL", "DISABLE", "COMPANY"),
  SCREENING_FORM_PREVIEW_ALL: new UserPermission("SCREENING", "FORM", "PREVIEW", "ALL"),
  SCREENING_FORM_PREVIEW_COMPANY: new UserPermission("SCREENING", "FORM", "PREVIEW", "COMPANY"),
  SCREENING_FORM_VIEW: new UserPermission("SCREENING", "FORM", "VIEW", null),
  SCREENING_FORM_EDIT: new UserPermission("SCREENING", "FORM", "EDIT", null),

  // CANDIDATE SCREENING
  CANDIDATE_SCREENING_VIEW_ALL: new UserPermission("CANDIDATE", "FORM", "VIEW", "ALL"),
  CANDIDATE_SCREENING_VIEW_COMPANY: new UserPermission("CANDIDATE", "FORM", "VIEW", "COMPANY"),
  CANDIDATE_SCREENING_VIEW_DIVISION: new UserPermission("CANDIDATE", "FORM", "VIEW", "DIVISION"),
  CANDIDATE_SCREENING_VIEW_OWN: new UserPermission("CANDIDATE", "FORM", "VIEW", "OWN"),

  CANDIDATE_SCREENING_EDIT: new UserPermission("CANDIDATE", "FORM", "EDIT", null),
  CANDIDATE_SCREENING_DELETE: new UserPermission("CANDIDATE", "FORM", "DELETE", null),

  // Check
  CHECK_MENU: new UserPermission("CHECK", "MENU", "VIEW", null),
  CHECK_LIST: new UserPermission("CHECK", "GENERAL", "LIST", null),
  CHECK_EDIT: new UserPermission("CHECK", "GENERAL", "EDIT", null),
  CHECK_NEW: new UserPermission("CHECK", "GENERAL", "NEW", null),
  CHECK_DELETE: new UserPermission("CHECK", "GENERAL", "DELETE", null),
  CHECK_FORM_PREVIEW: new UserPermission("CHECK", "FORM", "PREVIEW", null),
  CHECK_FORM_VIEW: new UserPermission("CHECK", "FORM", "VIEW", null),
  CHECK_FORM_EDIT: new UserPermission("CHECK", "FORM", "EDIT", null),
  CHECK_COST: new UserPermission("CHECK", "COST", "VIEW", null),

  // Group
  GROUP_MENU: new UserPermission("GROUP", "MENU", "VIEW", null), // unused
  GROUP_LIST: new UserPermission("GROUP", "GENERAL", "LIST", null),
  GROUP_PREVIEW: new UserPermission("GROUP", "GENERAL", "PREVIEW", null),
  GROUP_NEW: new UserPermission("GROUP", "GENERAL", "NEW", null),
  GROUP_EDIT: new UserPermission("GROUP", "GENERAL", "EDIT", null), // unused

  // Attribute
  ATTRIBUTE_MENU: new UserPermission("ATTRIBUTE", "MENU", "VIEW", null), // unused
  ATTRIBUTE_LIST: new UserPermission("ATTRIBUTE", "GENERAL", "LIST", null),
  ATTRIBUTE_NEW: new UserPermission("ATTRIBUTE", "GENERAL", "NEW", null), // unused
  ATTRIBUTE_EDIT: new UserPermission("ATTRIBUTE", "GENERAL", "EDIT", null), // unused

  // HOLIDAY
  HOLIDAY_MENU: new UserPermission("HOLIDAY", "MENU", "VIEW", null), // unused
  HOLIDAY_LIST: new UserPermission("HOLIDAY", "GENERAL", "LIST", null),
  HOLIDAY_NEW: new UserPermission("HOLIDAY", "GENERAL", "NEW", null),
  HOLIDAY_EDIT: new UserPermission("HOLIDAY", "GENERAL", "EDIT", null),

  PERMISSION_NEW: new UserPermission("ROLE", "PERMISSION", "NEW", null),
  PERMISSION_DELETE: new UserPermission("ROLE", "PERMISSION", "DELETE", null),

  // Admin
  ADMIN_MENU: new UserPermission("ADMIN", "MENU", "VIEW", null),

  // Live ID Check Order
  LID_CHECK_ORDER_MENU: new UserPermission("LID_CHECK_ORDER", "MENU", "VIEW", "ALL"),
  LID_CHECK_ORDER_MANAGEMENT_VIEW: new UserPermission("LID_CHECK_ORDER", "MANAGEMENT", "VIEW", "ALL"),

  // Id Verification
  ID_VERIFICATION_MENU: new UserPermission("ID_VERIFICATION", "MENU", "VIEW", "ALL"), // unused
};
