<h2>User Access</h2>
<div class="xavier-table-wrapper">
  <form>
    <div class="row">
      <div class="form-group col-sm-12 col-xl-6 xavier-input-text">
        <div class="input-group">
          <span class="input-group-addon" id="search-addon-user"><i class="material-icons">&#xE8B6;</i></span>
          <input type="text"
                 #userSearch
                 class="form-control"
                 id="userSearchInput"
                 placeholder="Search users..."
                 aria-describedby="search-addon"
                 (keyup)="userTable.gtSearch(userSearch.value)">
        </div>
        <i *ngIf="userSearch.value !== ''" class="material-icons search-reset" (click)="resetUserSearch(userSearch)">&#xE5C9;</i>
      </div>
      <div class="col-sm-12 col-xl-6 text-right" *isAllowed="['USER_NEW_ALL', 'USER_NEW_COMPANY', 'USER_NEW_DIVISION']">
        <button type="button" class="btn xavier-button continue-step weight-normal" (click)="openUserModal('add')">
          <i class="material-icons addIcon">&#xE145;</i> <span>Add New User</span>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <generic-table #userTable
                       [gtClasses]="'table-sm table-hover'"
                       [gtSettings]="userTableConfigObject.settings"
                       [gtFields]="userTableConfigObject.fields"
                       [gtOptions]="{numberOfRows: 25, stack: true, rowSelection: true}"
                       [(gtData)]="userTableConfigObject.data"
                       (gtEvent)="trigger($event)">
        </generic-table>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <gt-pagination [gtClasses]="'pagination-sm'" [genericTable]="userTable"></gt-pagination>
      </div>
      <div class="col-6">
        <small><gt-table-info class="form-text text-muted mb-2 text-right" [genericTable]="userTable"></gt-table-info></small>
      </div>
    </div>
  </form>
</div>

<!-- User Modal -->
<div *ngIf="showUserModal" class="xavier-modal-backdrop"></div>
<div *ngIf="showUserModal" class="xavier-modal" [@fadeIn]>
  <div class="modal-content container">
    <div class="modal-header">
      <h1 class="modal-title pull-left">{{userModalTitlePrefix}} User</h1>
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeUserModal()">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="userModalData" novalidate (ngSubmit)="onSubmitUser(userModalData)">
        <div class="row">
          <div class="col-sm-12 col-xl-3">
            <h4>Personal Details</h4>
          </div>
          <div class="col-sm-12 col-xl-3">
            <div class="form-group xavier-input-text">
              <label for="firstName">First Name(s)</label>
              <input type="text" class="form-control" id="firstName" formControlName="firstName" placeholder="First Name(s)">
              <xavier-error-message [control]="userModalData.get('firstName')"></xavier-error-message>
            </div>
          </div>
          <div class="col-sm-12 col-xl-3">
            <div class="form-group xavier-input-text">
              <label for="lastName">Last Name</label>
              <input type="text" class="form-control" id="lastName" formControlName="lastName" placeholder="Last Name">
              <xavier-error-message [control]="userModalData.get('lastName')"></xavier-error-message>
            </div>
          </div>
          <div class="col-sm-12 col-xl-3">
            <div class="form-group xavier-input-text">
              <label for="email">Email</label>
              <input type="text" class="form-control" id="email" formControlName="email" placeholder="Email">
              <xavier-error-message [control]="userModalData.get('email')"></xavier-error-message>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-xl-3">
            <h4>Divisions</h4>
          </div>
          <ng-container *ngFor="let division of companyDivisions; let i = index">
            <div *ngIf="i % 2 != 0 || i == 0" class="col-sm-12 col-xl-4">
              <div class="form-group xavier-input-checkbox">
                <input type="checkbox" class="form-check-input" id="division{{division.id}}">
                <label for="division{{division.id}}" class="form-check-label">{{division.divisionName}}</label>
              </div>
            </div>
            <ng-container *ngIf="i % 2 == 0 && i != 0" >
              <div class="col-sm-12 col-xl-3"></div>
              <div class="col-sm-12 col-xl-4">
                <div class="form-group xavier-input-checkbox">
                  <input type="checkbox" class="form-check-input" id="division{{division.id}}">
                  <label for="division{{division.id}}" class="form-check-label">{{division.divisionName}}</label>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <div class="row">
          <div class="col-sm-12 col-xl-3">
            <h4>Role</h4>
          </div>
          <div class="col-sm-12 col-xl-3">
            <div class="form-group xavier-input-text" [@fadeIn]>
              <label for="roleName">Role</label>
              <xavier-autocomplete-select-dropdown (dropdownEntrySelectedEmitter)="onDropdownEntrySelected($event)"
                                                   [componentParams]="roleSearchParams">
              </xavier-autocomplete-select-dropdown>
            </div>
          </div>
        </div>
        <div class="row xavier-add-user">
          <div class="col-sm-12 col-xl-3"></div>
          <div class="col-sm-12 col-xl-7">
            <button type="submit" class="btn xavier-button continue-step" id="submitButton" [disabled]="disabled">
              <span>{{userModalSubmitButton}}</span>
            </button>
            <button type="button" class="btn xavier-button-plain" (click)="closeUserModal()">
              <span>Cancel</span>
            </button>
          </div>
          <div *ngIf="userId !== 0" class="col-sm-12 col-xl-2 text-right">
            <button type="button" class="btn xavier-button-plain white" (click)="openDeleteUserModal(deleteModal)" *isAllowed="['USER_DISABLE_ALL', 'USER_DISABLE_COMPANY']">
              <span>Delete</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Delete User Modal -->
<ng-template #deleteModal>
  <div class="modal-header">
    <h2 class="modal-title pull-left">Are you sure you want to delete this user?</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div class="modal-body">
    <small>All information related to this user will be deleted. This action is immediate and cannot be undone.</small>
    <div class="modal-button-group">
      <button type="button" class="btn xavier-button reject weight-normal" (click)="deleteUser()" id="deleteButton" [disabled]="disabled">
        Delete User
      </button>
      <button type="button" class="btn xavier-button-plain" (click)="modalRef.hide()">
        <span>Close</span>
      </button>
    </div>
  </div>
</ng-template>
