import { ApplicationRef, Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { first } from "rxjs/operators";
import { Confirmation, DialogService } from "../app/dialog/dialog.service";

@Injectable()
export class SwUpdateService {

  private updateAvailable = false;

  constructor(
    private appRef: ApplicationRef,
    private swUpdate: SwUpdate,
    private dialogService: DialogService,
  ) {
    // console.log("constructor");

    if (!this.swUpdate.isEnabled) {
      return; 
    }

    // console.log("isEnabled");

    this.appRef.isStable.subscribe(isStable => {
      // console.log("isStable: ", isStable);

      this.swUpdate.available.subscribe(event => {
        // console.log("Update available: ", event);

        this.updateAvailable = true;
        this.askUserToRefresh();
      });
    });
  }

  public askUserToRefresh() {
    if (!(this.swUpdate.isEnabled && this.updateAvailable)) {
      return;
    }

    this.updateAvailable = false;

    const confirmation: Confirmation = {
      title: "Xavier Update",
      message: "An update to Xavier is available, please click Update to load the new version before continuing.",
      acceptLabel: "Update",
    };

    const action = () => this.swUpdate.activateUpdate().then(() => document.location.reload());

    this.dialogService.confirmAndDo(confirmation, action);
  }
}
