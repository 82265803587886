import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'xavier-yoti-identity-download',
  templateUrl: './yoti-identity-download.component.html',
  styleUrls: ['./yoti-identity-download.component.sass']
})
export class YotiIdentityDownloadComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
