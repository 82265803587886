import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpResponse, HttpUserEvent} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {isNullOrUndefined} from "util";

@Injectable()

export class HttpService {
  private apiURL: string;

  constructor (private http: HttpClient, private router:Router) {
    this.apiURL = environment.apiUrl;
  }

  public post(apiName: string, methodName: string, body: any, params: any, reportProgress: boolean, noMethod?: boolean): Observable<any> {
    return this.requestWithBody('POST', apiName, methodName, body, params, reportProgress, noMethod);
  }

  public postFile(apiName: string, methodName: string, body: any, params: any, reportProgress: boolean, fileType?: string): Observable<any> {
    return this.requestWithBody('POST', apiName, methodName, body, params, reportProgress, null, fileType);
  }

  public put(apiName: string, methodName: string, body: any, params: any, reportProgress: boolean): Observable<any> {
    return this.requestWithBody('PUT', apiName, methodName, body, params, reportProgress);
  }

  public get(apiName:string, methodName:string, queryParams: any, noMethod?:boolean): Observable<any> {
    return this.requestWithBody('GET', apiName, methodName, null, queryParams, false, noMethod);
  }

  public delete(apiName:string, methodName:string, params:string): Observable<any> {
    return this.requestWithBody('DELETE', apiName, methodName, null, params, null);
  }

  public getDocument(apiName:string): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=utf-8',
      'Accept': 'application/pdf'
    });
    let url = this.apiURL + apiName;
    let request = new HttpRequest(
      'GET',
      url,
      null,
      {
        headers: headers,
        responseType: 'blob',
        withCredentials: true
      }
    );
    // takeLast: https://stackoverflow.com/questions/50105311/angular-6-httpclient-request-fired-once-receives-2-responses
    return this.catchErrors(this.http.request(request).takeLast(1).map(this.extractData));
  }

  private requestWithBody(methodType: string, apiName: string, methodName: string, body: any, params: any, reportProgress: boolean, noMethod?: boolean, fileType?: string): Observable<any> {
    let headers = new HttpHeaders();
    if (!isNullOrUndefined(fileType))
      headers.set('Content-Type', fileType);
    else
      headers.set('Content-Type', 'application/json;charset=utf-8');
    let queryParams = new HttpParams();
    if (!isNullOrUndefined(params)) {
      for (let param of params) {
        for (let key of Object.keys(param)) {
          queryParams = queryParams.append(key, param[key]);
        }
      }
    }
    let url = this.apiURL + apiName + '/' + methodName;
    if (noMethod)
      url = this.apiURL + apiName;
    let request = new HttpRequest(
      methodType,
      url,
      body,
      {
        headers: headers,
        reportProgress: reportProgress,
        params: queryParams,
        withCredentials: true
      }
    );
    // takeLast: https://stackoverflow.com/questions/50105311/angular-6-httpclient-request-fired-once-receives-2-responses
    return this.catchErrors(this.http.request(request).takeLast(1).map(this.extractData));
  }

  catchErrors(observable: Observable<any>) {
    return observable.catch(err => {
      if (err.status === 401 || err.status === 403 || err.status === 409)
        return Observable.throw(err);
    });
  }

  private extractData(response: HttpUserEvent<any>) {
    if (response instanceof HttpResponse) {
      const res = response as any;
      if (res.status === 200 || res.status === 204) {
        if (res.headers.has("jsessionid"))
          document.cookie = "JSESSIONID=" + res.headers.get("jsessionid") + ";Path=/;Domain=" + environment.domain;
        return res.body;
      }
      else {
        return Observable.throw('error');
      }
    }
    return {}
  }

  unauthorised(): Observable<any> {
    this.router.navigate(['/login']);
    return Observable.of(null);
  }
}
