<ng-container>
  <div class="mb-4" *ngIf="!processing">
    <xavier-yoti-identity-download></xavier-yoti-identity-download>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="yoti-button-wrapper">
        <div id="yoti-button"></div>
      </div>
    </div>
  </div>
</ng-container>


<div class="spinner-container not-button" *ngIf="processing">
  <div class="spinner-wrap">
  </div>
</div>
