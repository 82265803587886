import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {YotiDigitalIdCallbackComponent} from './yoti-digital-id-callback/yoti-digital-id-callback.component';
import {ToastyModule} from 'ng2-toasty';

@NgModule({
  imports: [
    CommonModule,
    ToastyModule.forRoot(),
  ],
  declarations: [
    YotiDigitalIdCallbackComponent
  ],
  exports: [
    YotiDigitalIdCallbackComponent
  ]
})
export class YotiModule { }
