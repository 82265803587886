import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class TableService {

  private tableOptionsSource = new BehaviorSubject<any>({});
  currentOptions = this.tableOptionsSource.asObservable();
  private tableOwnerSource = new BehaviorSubject<string>('');
  currentOwner = this.tableOwnerSource.asObservable();
  private tableConfigColumns = new BehaviorSubject<any>([]);
  currentConfigColumns = this.tableConfigColumns.asObservable();
  private getTableData = new BehaviorSubject<any>(false);
  tableData$ = this.getTableData.asObservable();


  constructor() { }

  changeOptions(options: any) {
    this.tableOptionsSource.next(options);
  }

  changeTableOwner(component: string) {
    this.tableOwnerSource.next(component);
  }

  changeTableHiddenColumns(columns: any) {
    this.tableConfigColumns.next(columns);
  }

  changeGetTableDataValue(value: boolean) {
    this.getTableData.next(value);
  }



}
