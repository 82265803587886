import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {CompanyService} from "../../services/company.service";
import {UserService} from "../../services/user.service";
import {FormService} from "../../../registration/services/form.service";
import {AutocompleteSelectDropdown} from "../../model/autocomplete-select-dropdown.model";
import {ToastyService} from "ng2-toasty";
import {candidatesToasterOptions} from "../../../../data/variables.data";
import {fadeIn} from "../../../../data/animations.data";
import {emailValidator, ValidateEmailExists} from "../../../utils/form.validators";
import {
  addToaster,
  getFormGroupValidationErrors,
  getToasterMessagesLabel,
  setAsTouched
} from "../../../utils/functions.utils";
import {isNullOrUndefined} from "util";
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'xavier-edit-user',
  templateUrl: 'edit-user.component.html',
  styleUrls: ['edit-user.stylesheet.sass'],
  animations: [fadeIn]
})

export class EditUserComponent implements OnInit, OnDestroy {

  @Input() public companySearchParams: AutocompleteSelectDropdown;
  @Input() public roleSearchParams: AutocompleteSelectDropdown;

  public userId = 0;
  public userData;
  public companyDivisions;
  public userDataForm: FormGroup;
  public showCompanyDropdown: boolean = false;
  public disabled: boolean = false;
  public resendDisabled: boolean = false;
  public userActivated: boolean = true;

  private companyId;
  private roleId;
  private routeSub;

  constructor(private companyService: CompanyService, private userService: UserService, private formService: FormService,
              private fb: FormBuilder, private activatedRoute: ActivatedRoute, private router: Router,
              private toastyService: ToastyService, private titleService: Title) {
    this.routeSub = this.activatedRoute.params.subscribe(params => {
      this.userId = params['id'];
    });
    this.companyId = this.activatedRoute.snapshot.queryParams['companyId'];
  }

  ngOnInit() {
    this.titleService.setTitle('Add New User - Xavier Eurocom CI');

    this.userDataForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['',
        [Validators.required, emailValidator],
        ValidateEmailExists.createValidator(this.formService)
      ],
      companyName: [''],
      divisions: [''],
      roleName: ['', Validators.required]
    });

    this.companySearchParams = new AutocompleteSelectDropdown(
      'companyName',
      'Company',
      'Search Companies...',
      'search',
      [],
      true,
      this.userDataForm.get('companyName')
    );

    this.roleSearchParams = new AutocompleteSelectDropdown(
      'roleName',
      'Role',
      'Select Role...',
      'arrow_drop_down',
      [],
      false,
      this.userDataForm.get('roleName')
    );

    this.userService.getForEdit(this.userId).subscribe(response => {
      if (!isNullOrUndefined(response.id)) {
        this.userData = response;
        this.userActivated = this.userData.activated;
        this.userDataForm.get('firstName').setValue(this.userData.firstName);
        this.userDataForm.get('lastName').setValue(this.userData.lastName);
        this.userDataForm.get('email').setValue(this.userData.email);
        this.userDataForm.get('roleName').setValue(this.userData.role.name);
        this.roleId = this.userData.role.id;
        this.companyDivisions = JSON.parse(JSON.stringify(this.userData.companyDivisions));
        if (this.roleId === '4' || this.roleId === '5') {
          this.userDataForm.get('companyName').setValidators([Validators.required]);
          this.userDataForm.get('divisions').setValidators([Validators.required]);
        }
        this.userDataForm.get('companyName').setValue(this.userData.company.companyName);
        this.onDropdownEntrySelected({
          companyName: this.userData.company.companyName,
          id: this.userData.company.id
        });
        setTimeout(() => {
          _.forEach(this.userData.divisions, ( function (division) {
            let checkbox = document.getElementById('division' + division.id);
            if (!isNullOrUndefined(checkbox)) {
              checkbox.setAttribute('checked', 'checked');
              checkbox.click();
            }
          }).bind(this));
        }, 0);
      }
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  onSubmit({ value, valid } : { value: any, valid: boolean }) {
    if (this.userData.email === this.userDataForm.get('email').value) {
      let errors = getFormGroupValidationErrors(this.userDataForm);
      if (errors.length == 1 && errors[0]['key'] == 'emailExists')
        valid = true;
    }
    let company;
    let divisions = [];
    if (!isNullOrUndefined(this.companyId))
      company = { id: this.companyId };
    let checkboxes = document.getElementsByClassName('form-check-input');
    _.forEach(checkboxes, function(checkbox) {
      if (checkbox.checked)
        divisions.push({ id: checkbox.id.replace('division', '') });
    });
    if ((this.roleId === 4 || this.roleId === 5) && divisions.length === 0)
      valid = false;

    if (!valid) {
      setAsTouched(this.userDataForm);
    } else {
      this.disabled = true;
      let submitButton = document.getElementById('submitButton');
      const spinnerWrap = document.createElement('div');
      spinnerWrap.classList.add('spinner-wrap');
      spinnerWrap.classList.add('button');

      submitButton.innerHTML = '';
      submitButton.appendChild(spinnerWrap);

      let body = {
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'role': { id: this.roleId },
        'company': company,
        'divisions': divisions
      };

      body['id'] = this.userId;
      this.userService.editUser(body).subscribe(response => {
        if (!isNullOrUndefined(response.id)) {
          this.disabled = false;
          submitButton.innerHTML = "<span>Save Changes</span>";
          this.router.navigate(['/dashboard/users'], { skipLocationChange: true });
        }
      }, err => {
        this.disabled = false;
        submitButton.innerHTML = "<span>Save Changes</span>";
        let action = 'serverError';
        if (err.status === 403)
          action = '403';
        addToaster('error', this.toastyService, action, 'errors');
      });
    }
  }

  resendInvite() {
    this.resendDisabled = true;
    let resendButton = document.getElementById('resendButton');
    const spinnerWrap = document.createElement('div');
    spinnerWrap.classList.add('spinner-wrap');
    spinnerWrap.classList.add('button');

    resendButton.innerHTML = '';
    resendButton.appendChild(spinnerWrap);

    this.userService.resendInvite(this.userId).subscribe(() => {
    }, err => {
      this.resendDisabled = false;
      resendButton.innerHTML = "<span>Resend Invite</span>";
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
    }, () => {
      this.resendDisabled = false;
      resendButton.innerHTML = "<span>Resend Invite</span>";
      let config = candidatesToasterOptions;
      config.msg = getToasterMessagesLabel('resend', 'candidates');
      this.toastyService.info(config);
    });
  }

  disableUser() {
    this.disabled = true;
    let disableButton = document.getElementById('disableButton');
    const spinnerWrap = document.createElement('div');
    spinnerWrap.classList.add('spinner-wrap');
    spinnerWrap.classList.add('button-disable');

    disableButton.innerHTML = '';
    disableButton.appendChild(spinnerWrap);

    this.userService.disableUser(this.userId).subscribe(response => {
      if (!isNullOrUndefined(response.id)) {
        this.disabled = false;
        disableButton.innerHTML = "<span>Disable User</span>";
        this.router.navigate(['/dashboard/users'], { skipLocationChange: true });
      }
    }, err => {
      this.disabled = false;
      disableButton.innerHTML = "<span>Disable User</span>";
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
    });
  }

  onDropdownEntrySelected(entry: any) {
    if (entry.hasOwnProperty('name')) {
      this.roleId = entry.id;
      if (entry.id === '1' || entry.id === '2') { // Admin or Screener
        this.showCompanyDropdown = false;
      } else {
        this.showCompanyDropdown = true;
        if (!isNullOrUndefined(this.companyDivisions)) {
          setTimeout(() => {
            _.forEach(this.userData.divisions, ( function (division) {
              let checkbox = document.getElementById('division' + division.id);
              if (!isNullOrUndefined(checkbox)) {
                checkbox.setAttribute('checked', 'checked');
                checkbox.click();
              }
            }).bind(this));
          }, 0);
        }
      }
      if (entry.id === '4' || entry.id === '5') {
        this.userDataForm.get('companyName').setValidators([Validators.required]);
        this.userDataForm.get('divisions').setValidators([Validators.required]);
      }
    }
    if (entry.hasOwnProperty('companyName'))
      this.companyId = entry.id;
  }

  cancelAddUser() {
    this.router.navigate(['/dashboard/users']);
  }

}
