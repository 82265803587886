import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {YotiSessionDTO} from '../model/yoti/identity-verification/yoti-session.dto';
import {HttpService} from '../../shared-services/http.service';
import {share, shareReplay, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/Subject';
import {YotiResponseDTO} from '../model/yoti/identity-verification/yoti-response.dto';
import {YotiInfoSessionProgressDTO} from '../model/yoti/identity-verification/yoti-info-session-progress.dto';

@Injectable()
export class YotiIdentityVerificationService {

  private yotiSessionCache$: Observable<YotiSessionDTO>;
  private forceReload$ = new Subject<void>();

  constructor(
    private httpService: HttpService,
  ) {
  }

  public getSession$(candidateId: string, createNew: boolean = false): Observable<YotiSessionDTO> {
    if (createNew) {
      this.forceReload();
      this.yotiSessionCache$ = this.createSession(candidateId).pipe(
        shareReplay(1),
        takeUntil(this.forceReload$)
      );
    }

    if (!this.yotiSessionCache$) {
      this.yotiSessionCache$ = this.getOrCreateSession(candidateId).pipe(
        shareReplay(1),
        takeUntil(this.forceReload$)
      );
    }

    return this.yotiSessionCache$;
  }

  private createSession(candidateId: string): Observable<YotiSessionDTO> {
    const params = [
      { candidateId },
    ];
    return this.httpService.post('/_ah/api/yoti/v1', 'createSession', null, params, false, false).pipe(
      share()
    );
  }

  private getOrCreateSession(candidateId: string): Observable<YotiSessionDTO> {
    const params = [
      { candidateId },
    ];
    return this.httpService.post('/_ah/api/yoti/v1', 'getOrCreateSession', null, params, false, false).pipe(
      share()
    );
  }

  public infoSessionProgress(candidateId: string, yotiResponseDTO: YotiResponseDTO): Observable<YotiInfoSessionProgressDTO> {
    const params = [
      { candidateId },
    ];
    return this.httpService.post('/_ah/api/yoti/v1', 'infoSessionProgress', yotiResponseDTO, params, false, false).pipe(
      share()
    );
  }

  public forceReload() {
    this.forceReload$.next();
    this.yotiSessionCache$ = null;
  }
}
