import {Component, forwardRef, Input, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DynamicFormService} from "../../../../services/dynamic-form.service";
import {isNullOrUndefined} from "util";
import SignaturePad from "signature_pad";

@Component({
  selector: 'signature-field',
  templateUrl: 'signature-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SignatureFieldComponent),
      multi: true,
    },
  ],
})

export class SignatureFieldComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild("canvas")
  public canvas: ElementRef;

  private signaturePad: SignaturePad;

  @Input() public attributeId: string;

  public value: any = null;
  public propagateChange: Function = null;

  private canvasWidth: number = 500;
  private canvasHeight: number = 280;

  constructor(private dynamicFormService: DynamicFormService) {

  }

  // public signaturePadOptions: Object = {
  //   'minWidth': 1,
  //   'maxHeight': 280,
  //   'canvasWidth': this.canvasWidth,
  //   'canvasHeight': this.canvasHeight,
  //   'backgroundColor': 'rgb(255,255,255)'
  // };

  public ngAfterViewInit(): void {
      this.signaturePad = new SignaturePad(this.canvas.nativeElement, {
        onEnd: () => this.onSignatureChange()
      });

    let wrapperWidth = document.getElementById('canvas-wrapper_'+this.attributeId).offsetWidth;
    const canvasWidth = wrapperWidth - 24;
    this.canvas.nativeElement.width = canvasWidth;
    this.canvas.nativeElement.height = this.canvasHeight;

      // offset fix
      // const ratio = Math.max(window.devicePixelRatio || 1, 1);
      // this.canvas.nativeElement.width = this.canvas.nativeElement.offsetWidth * ratio;
      // this.canvas.nativeElement.height = this.canvas.nativeElement.offsetHeight * ratio;
      // this.canvas.nativeElement.getContext("2d").scale(ratio, ratio);

      this.value && this.signaturePad.fromDataURL(this.value);
    }

  private onSignatureChange() {
      const signature = this.signaturePad.toDataURL();

      this.propagateChange && this.propagateChange(signature);
    }

  public writeValue(value: any): void {
    if (!value)
      return;

    this.value = value;
    this.signaturePad && this.signaturePad.fromDataURL(this.value);
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(): void {
    // no-op
  }

  // public ngAfterViewInit(): void {
  //   this.redrawCanvas();
  // }

  public drawBegin() {
    document.getElementById('canvas-wrapper_'+this.attributeId).classList.remove('required');
  }

  public drawComplete(): void {
    // this.signature = this.signaturePad.toDataURL('image/png', 0.5);
  }

  undoSignature() {
    let attrib = this.dynamicFormService.getAttributeComponentInstance(this.attributeId);
    document.getElementById('canvas-wrapper_'+this.attributeId).classList.remove('required');
    attrib.formControl.reset();
    this.value = null;
    this.signaturePad.clear();
    // this.redrawCanvas();
  }

  onResize() {
    this.redrawCanvas();
  }

  onScroll() {
    // this.redrawCanvas();
  }

  private redrawCanvas() {
    let wrapperWidth = document.getElementById('canvas-wrapper_'+this.attributeId).offsetWidth;
    this.canvasWidth = wrapperWidth - 24;
    // this.signaturePad.set('canvasWidth', this.canvasWidth);

    this.signaturePad.clear();

    // if (!isNullOrUndefined(this.signature))
      // this.signaturePad.fromDataURL(this.signature, {'width':this.canvasWidth, 'height': this.canvasHeight});
  }
}
