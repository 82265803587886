import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {AdminTemplateComponent} from "./cmps/admintemplate/admintemplate.component";
import {CandidateReferenceFormComponent} from "./cmps/candidate-reference-form/candidate-reference-form.component";
import {CandidateScreeningComponent} from "./cmps/candidate-screening/candidate-screening.component";
import {CandidatesComponent} from "./cmps/candidates/candidates.component";
import {CandidatesCompleteComponent} from "./cmps/candidates-complete/candidates-complete.component";
import {CandidatesOverviewComponent} from "./cmps/candidates-overview/candidates-overview.component";
import {ChecksComponent} from "./cmps/checks/checks.component";
import {ClientsComponent} from "./cmps/clients/clients.component";
import {EditClientComponent} from "./cmps/edit-client/edit-client.component";
import {ScreeningsComponent} from "./cmps/screenings/screenings.component";
import {UsersComponent} from "./cmps/users/users.component";
import {AddScreeningComponent} from "./cmps/add-screening/add-screening.component";
import {EditCandidateComponent} from "./cmps/edit-candidate/edit-candidate.component";
import {AddUserComponent} from "./cmps/add-user/add-user.component";
import {EditUserComponent} from "./cmps/edit-user/edit-user.component";
import {PreviewScreeningComponent} from "./cmps/preview-screening/preview-screening.component";
import {PreviewCheckComponent} from "./cmps/preview-check/preview-check.component";
import {ScreeningDashboardComponent} from "./cmps/screening-dashboard/screening-dashboard.component";
import {EditScreeningComponent} from "./cmps/edit-screening/edit-screening.component";
import {CanAccessGuard} from "./can-access-guard.routing";
import {LiveIdOrdersComponent} from './cmps/live-id-orders/live-id-orders.component';
import { NewCandidateComponent } from "./cmps/new-candidate/new-candidate.component";
import { ComponentListComponent } from "../../app/component-list/component-list.component";
import { IdVerificationsComponent } from "./cmps/id-verifications/id-verifications.component";
import { CandidateIdVerificationComponent } from "./cmps/candidate-id-verification/candidate-id-verification.component";
import { CountersignOverviewComponent } from "./cmps/countersign-overview/countersign-overview.component";
import { EvidenceCheckComponent } from "./cmps/evidence-check/evidence-check.component";
import { EditEvidenceCheckComponent } from "./cmps/edit-evidence-check/edit-evidence-check.component";

const candidatesPermissions = ['CANDIDATE_LIST_ALL', 'CANDIDATE_LIST_COMPANY', 'CANDIDATE_LIST_DIVISION', 'CANDIDATE_STATUS_OWN'];
const checksPermissions = ['CHECK_LIST'];
const clientsPermissions = ['COMPANY_LIST'];
const screeningsPermissions = ['SCREENING_LIST_ALL', 'SCREENING_LIST_COMPANY', 'SCREENING_LIST_DIVISION'];
const usersPermissions = [];
const liveIdCheckOrdersPermissions = ['LID_CHECK_ORDER_MANAGEMENT_VIEW'];

const routes: Routes = [
  {
    path: 'candidate', component: AdminTemplateComponent, children: [
      { path: 'candidate-reference-form/:id', component: CandidateReferenceFormComponent },
    ]
  },
  {
    path: 'dashboard', component: AdminTemplateComponent, canActivate: [CanAccessGuard], children: [
      { path: '', redirectTo: 'clients', pathMatch: 'full' },
      { path: 'candidate-screening', component: CandidateScreeningComponent, canActivate: [CanAccessGuard]/*, data: { permissions: usersPermissions }*/ },
      { path: 'candidates', component: CandidatesComponent, canActivate: [CanAccessGuard], data: { permissions: candidatesPermissions } },
      { path: 'candidates-complete', component: CandidatesCompleteComponent, canActivate: [CanAccessGuard], data: { permissions: candidatesPermissions } },
      { path: 'candidates-overview', component: CandidatesOverviewComponent, canActivate: [CanAccessGuard], data: { permissions: candidatesPermissions } },
      { path: 'id-verification', component: IdVerificationsComponent, canActivate: [CanAccessGuard], data: { permissions: candidatesPermissions } },
      { path: 'candidates-overview/screening-dashboard/:id', component: ScreeningDashboardComponent, canActivate: [CanAccessGuard] },
      { path: 'candidates/id-verification/:id/:verification-id', component: CandidateIdVerificationComponent, canActivate: [CanAccessGuard] },
      { path: 'checks', component: ChecksComponent, canActivate: [CanAccessGuard], data: { permissions: checksPermissions } },
      { path: 'clients', component: ClientsComponent, canActivate: [CanAccessGuard], data: { permissions: clientsPermissions } },
      { path: 'clients/edit/:id', component: EditClientComponent },
      { path: 'screenings', component: ScreeningsComponent, canActivate: [CanAccessGuard], data: { permissions: screeningsPermissions } },
      { path: 'screenings/preview/:id', component: PreviewScreeningComponent },
      { path: 'users', component: UsersComponent, canActivate: [CanAccessGuard]/*, data: { permissions: usersPermissions }*/ },
      { path: 'liveid-orders', component: LiveIdOrdersComponent, canActivate: [CanAccessGuard], data: { permissions: liveIdCheckOrdersPermissions } },
      { path: 'countersign-overview', component: CountersignOverviewComponent, canActivate: [CanAccessGuard] },
      { path: 'evidence-check', component: EvidenceCheckComponent, canActivate: [CanAccessGuard], data: { permissions: candidatesPermissions }},
      { path: 'evidence-check-quality-assurance', component: EvidenceCheckComponent, canActivate: [CanAccessGuard], data: { permissions: candidatesPermissions }},
      { path: 'evidence-check-completed', component: EvidenceCheckComponent, canActivate: [CanAccessGuard], data: { permissions: candidatesPermissions }},
      { path: 'evidence-check/apprentices/:check-type/:id', component: EditEvidenceCheckComponent, canActivate: [CanAccessGuard]},
    ]
  },
  { path: 'dashboard/candidates/add', component: NewCandidateComponent, canActivate: [CanAccessGuard] },
  { path: 'dashboard/candidates/edit/:id', component: EditCandidateComponent, canActivate: [CanAccessGuard] },
  { path: 'dashboard/checks/preview/:id', component: PreviewCheckComponent, canActivate: [CanAccessGuard] },
  { path: 'dashboard/screenings/add', component: AddScreeningComponent, canActivate: [CanAccessGuard] },
  { path: 'dashboard/screenings/edit/:id', component: EditScreeningComponent, canActivate: [CanAccessGuard] },
  { path: 'dashboard/users/user', component: AddUserComponent, canActivate: [CanAccessGuard]/*, data: { permissions: usersPermissions }*/ },
  { path: 'dashboard/users/user/:id', component: EditUserComponent, canActivate: [CanAccessGuard]/*, data: { permissions: usersPermissions }*/ },
  { path: "debug/component-list", component: ComponentListComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})

export class AdminRoutingModule {

}
