<ng2-toasty [position]="'top-center'" class="xavier-toasty"></ng2-toasty>
<div class="container">
  <div *ngIf="loading" class="xavier-center-page" [@fadeIn]>
    <div class="spinner-container not-button">
      <div class="spinner-wrap"></div>
    </div>
  </div>
  <div *ngIf="!loading" [@fadeIn] class="">
    <div class="row header align-items-center">
      <div class="col-lg-6 col-12 d-flex flex-row">
        <button [routerLink]="'/dashboard/' +  evidenceCheckType.name" type="button"
          class="btn xavier-button-plain white">
          <i class="material-icons back-link">&#xE5C4;</i>
        </button>
        <div class="align-self-end">
          <div class="d-flex align-items-end justify-content-between gap-10">
            <p class="h2 m-0">{{ candidateData.fullName }}</p>
            <p class="small mb-1"><strong>Previous Name:</strong> {{ candidateData.previousName }}</p>
          </div>
          <div class="small d-flex justify-content-between gap-20">
            <span>DOB: {{ candidateData.dateOfBirth | date: 'd MMM, y' }}</span>
            <span><img src="/assets/images/calculator.svg"> {{ qualificationData.subject }}</span>
            <span class="d-flex align-items-center gap-5">
              <i *ngIf="qualificationData.provisionalDeclaration" class="material-icons check-icon">check_circle</i>
              <i *ngIf="!qualificationData.provisionalDeclaration" class="material-icons cancel-icon">cancel</i>
              Provisional Declaration Declared
            </span>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-6">
        <ng-container *ngIf="qualificationProgress">
          <span class="loader-text">Current Candidate</span>
          <div *ngIf="qualificationProgress" class="num-out-of">{{ qualificationProgress.currentIndex }} / {{
            qualificationProgress.totalRecords }}</div>
          <div class="progress">
            <div class="progress-bar bg-green" role="progressbar" [style.width]="percCompletedChecks+'%'"
              [style.height]="'4px'" [attr.aria-valuenow]="percCompletedChecks" aria-valuemin="0" aria-valuemax="100">
            </div>
          </div>
        </ng-container>
      </div>
      <div class="col-lg-3 col-7 text-lg-right">
        <ng-container *ngIf="dueDate && qualificationProgress">
          <h2 class="due-date">Due {{ dueDate.formattedDate }}</h2>
          <div class="small d-flex align-items-center justify-content-lg-end gap-5">
            <span class="ellipse" [ngClass]="dueDate.indicator"></span>
            <span>{{ dueDate.workingHoursLeft }} working hour(s) remaining</span>
          </div>
        </ng-container>
      </div>
    </div>

      <div class="row apprentice-body">
        <div class="col-lg-6 col-12 d-lg-block">
          <div class="position-relative">
            <iframe [src]="selectedDocument.documentURL" width="100%" height="510px" class="pdf-iframe"></iframe>
            <a [href]="selectedDocument.documentURL" target="_blank" class="icon-top-right">
              <i class="material-icons back-link">open_in_new</i>
            </a>
          </div>
          <div class="d-flex w-100 apprentice-files py-4 px-1">
            <button *ngFor="let item of documentsData" (click)="setActiveItem(item)"
              [class.active]="selectedDocument.documentType === item.documentType" class="btn btn-file">{{
              item.documentType }}</button>
          </div>
        </div>
        <div class="col-lg-6 col-12 d-lg-block">
          <form [formGroup]="editApprenticeData" novalidate>
            <div class="row mb-3">
              <div class="col-7 p-0">
                <a *ngIf="resistantAiData" [href]="resistantAiData.reportUrl" [ngStyle]="{'background': resistantAiData.background.fill}"
                  class="d-flex justify-content-between align-items-center resistant-score" target="_blank">
                  <img class="mx-4" src="/assets/images/resistant_ai.svg">
                  <span class="mx-auto">Resistant Score {{resistantAiData.resistantFraudResult | titlecase}}</span>
                </a>
              </div>
              <div class="col-5 p-0">
                <div class="d-flex align-items-center justify-content-around w-100 h-100">
                  <img class="" src="/assets/images/ecctis.svg">
                  <xavier-switch [control]="editApprenticeData.get('ecctisEnquiry')"></xavier-switch>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 score-details">
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">Qualification Country</p>
                  <div class="score-detail-value col-3 px-1 text-dark">
                    <img [src]="'/assets/images/'+(openAiData.updatedCountry == undefined ? 'open_ai' : 'edited')+'.svg'">
                    <xavier-editable-text-inputbox [control]="editApprenticeData.get('country')"></xavier-editable-text-inputbox>
                  </div>
                  <div class="score-detail-switch text-dark small col-4">
                    <p class="m-0 small">Document not legible</p>
                    <xavier-switch [control]="editApprenticeData.get('notLegible')"></xavier-switch>
                  </div>
                </div>
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">Candidate name</p>
                  <div class="score-detail-value col-3 px-1 text-dark">
                    <img [src]="'/assets/images/'+(openAiData.updatedFullName == undefined ? 'open_ai' : 'edited')+'.svg'">
                    <xavier-editable-text-inputbox [control]="editApprenticeData.get('fullName')"></xavier-editable-text-inputbox>
                  </div>
                  <div class="score-detail-switch text-dark small col-4">
                    <p class="m-0 small">Name discripancy</p>
                    <xavier-switch [control]="editApprenticeData.get('nameChangeDiscrepancy')"></xavier-switch>
                  </div>
                </div>
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">Candidate DOB</p>
                  <div class="score-detail-value col-3 px-1 text-dark">
                    <img [src]="'/assets/images/'+(openAiData.updatedDateOfBirth == undefined ? 'open_ai' : 'edited')+'.svg'">
                    <xavier-editable-text-inputbox type="date" [control]="editApprenticeData.get('dateOfBirth')"></xavier-editable-text-inputbox>
                  </div>
                  <div class="score-detail-switch text-dark small col-4">
                    <p class="m-0 small">DOB Discrepancy</p>
                    <xavier-switch [control]="editApprenticeData.get('dateOfBirthDiscrepancy')"></xavier-switch>
                  </div>
                </div>
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">Award Organisation / Exam Board</p>
                  <div class="score-detail-value col-7 px-1 text-dark">
                    <img [src]="'/assets/images/'+(openAiData.updatedAwardOrganisation == undefined ? 'open_ai' : 'edited')+'.svg'">
                    <xavier-editable-text-inputbox [control]="editApprenticeData.get('awardOrganisation')"></xavier-editable-text-inputbox>
                  </div>
                </div>
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">Award Date</p>
                  <div class="score-detail-value col-7 px-1 text-dark">
                    <img [src]="'/assets/images/'+(openAiData.updatedAwardDate == undefined ? 'open_ai' : 'edited')+'.svg'">
                    <xavier-editable-text-inputbox [control]="editApprenticeData.get('awardDate')"></xavier-editable-text-inputbox>
                  </div>
                </div>
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">Award date visible?</p>
                  <div class="score-detail-value col-7 px-1 text-dark">
                    <img [src]="'/assets/images/'+(openAiData.updatedAwardDateVisible == undefined ? 'open_ai' : 'edited')+'.svg'">
                    <xavier-switch [control]="editApprenticeData.get('awardDateVisible')"></xavier-switch>
                  </div>
                </div>
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">Qualification Name</p>
                  <div class="score-detail-value col-7 px-1 text-dark">
                    <img [src]="'/assets/images/'+(openAiData.updatedQualificationName == undefined ? 'open_ai' : 'edited')+'.svg'">
                    <xavier-editable-text-inputbox [control]="editApprenticeData.get('qualificationName')"></xavier-editable-text-inputbox>
                  </div>
                </div>
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">Subject Name</p>
                  <div class="score-detail-value col-7 px-1 text-dark">
                    <img [src]="'/assets/images/'+(openAiData.updatedSubjectName == undefined ? 'open_ai' : 'edited')+'.svg'">
                    <xavier-editable-text-inputbox [control]="editApprenticeData.get('subjectName')"></xavier-editable-text-inputbox>
                  </div>
                </div>
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">Subject Grade</p>
                  <div class="score-detail-value col-7 px-1 text-dark">
                    <img [src]="'/assets/images/'+(openAiData.updatedSubjectGrade == undefined ? 'open_ai' : 'edited')+'.svg'">
                    <xavier-editable-text-inputbox [control]="editApprenticeData.get('subjectGrade')"></xavier-editable-text-inputbox>
                  </div>
                </div>
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">Accept this evidence without any exceptions?</p>
                  <div class="score-detail-value col-7 px-1 text-dark">
                    <img [src]="'/assets/images/'+(openAiData.updatedMinimumQualificationMet == undefined ? 'open_ai' : 'edited')+'.svg'">
                    <xavier-switch [control]="editApprenticeData.get('minimumQualificationMet')"></xavier-switch>
                  </div>
                </div>
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">Select problems with Subject/Grade</p>
                  <div class="score-detail-value col-7 px-1 text-dark">
                    <img *ngIf="editApprenticeData.get('reasonQualificationRejected').value?.edited else notEdited" src="/assets/images/edited.svg">
                    <ng-template #notEdited>
                      <img src="/assets/images/open_ai.svg">
                    </ng-template>
                    <xavier-dropdown [itemSource]="reasonQualificationRejectedItemSource" [compareWith]="compareFn" formControlName="reasonQualificationRejected">
                      <ng-template let-item #itemTemplate>{{ item?.text | titlecase }}</ng-template>
                      <ng-template let-item #valueTemplate>{{ item?.text | titlecase }}</ng-template>
                    </xavier-dropdown>
                  </div>
                </div>
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">Translation required?</p>
                  <div class="score-detail-value col-7 px-1 text-dark">
                    <img [src]="'/assets/images/'+(openAiData.updatedRequiredTranslation == undefined ? 'open_ai' : 'edited')+'.svg'">
                    <xavier-switch [control]="editApprenticeData.get('requiredTranslation')"></xavier-switch>
                  </div>
                </div>
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">Transcript required?</p>
                  <div class="score-detail-value col-7 px-1 text-dark">
                    <img [src]="'/assets/images/'+(openAiData.updatedRequiredTranscript == undefined ? 'open_ai' : 'edited')+'.svg'">
                    <xavier-switch [control]="editApprenticeData.get('requiredTranscript')"></xavier-switch>
                  </div>
                </div>
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">All four corners of the document are not visible?</p>
                  <div class="score-detail-value col-7 px-1 text-dark">
                    <img [src]="'/assets/images/'+(openAiData.updatedMissingFourCorners == undefined ? 'open_ai' : 'edited')+'.svg'">
                    <xavier-switch [control]="editApprenticeData.get('missingFourCorners')"></xavier-switch>
                  </div>
                </div>
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">Are there official stamps or seals on the document?</p>
                  <div class="score-detail-value col-7 px-1 text-dark">
                    <img [src]="'/assets/images/'+(openAiData.updatedOfficialStamps == undefined ? 'open_ai' : 'edited')+'.svg'">
                    <xavier-switch [control]="editApprenticeData.get('officialStamps')"></xavier-switch>
                  </div>
                </div>
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">Which stamps or seals are present on the document?</p>
                  <div class="score-detail-value col-7 px-1 text-dark">
                    <img [src]="'/assets/images/'+(openAiData.updatedOfficialStampsName == undefined ? 'open_ai' : 'edited')+'.svg'">
                    <p class="m-0 h6 font-weight-bold">{{ openAiData.officialStampsName ? openAiData.officialStampsName : 'N/A' }}</p>
                  </div>
                </div>
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">Are there official signatures on the document?</p>
                  <div class="score-detail-value col-7 px-1 text-dark">
                    <img [src]="'/assets/images/'+(openAiData.updatedOfficialSig == undefined ? 'open_ai' : 'edited')+'.svg'">
                    <xavier-switch [control]="editApprenticeData.get('officialSig')"></xavier-switch>
                  </div>
                </div>
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">Are there official logos on the document?</p>
                  <div class="score-detail-value col-7 px-1 text-dark">
                    <img [src]="'/assets/images/'+(openAiData.updatedOfficialLogo == undefined ? 'open_ai' : 'edited')+'.svg'">
                    <xavier-switch [control]="editApprenticeData.get('officialLogo')"></xavier-switch>
                  </div>
                </div>
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">Which logos are present on the document?</p>
                  <div class="score-detail-value col-7 px-1 text-dark">
                    <img src="/assets/images/open_ai.svg">
                    <p class="m-0 h6 font-weight-bold">{{ openAiData.officialLogoName ? openAiData.officialLogoName : 'N/A' }}</p>
                  </div>
                </div>
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">Which organization letterhead is on the document?</p>
                  <div class="score-detail-value col-7 px-1 text-dark">
                    <img src="/assets/images/open_ai.svg">
                    <p class="m-0 h6 font-weight-bold">{{ openAiData.orgLetterhead ? openAiData.orgLetterhead : 'N/A' }}</p>
                  </div>
                </div>
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">Document type not acceptable</p>
                  <div class="score-detail-value col-7 px-1 text-dark">
                    <img [src]="'/assets/images/'+(openAiData.updatedEviAccepted == undefined ? 'open_ai' : 'edited')+'.svg'">
                    <xavier-switch [control]="editApprenticeData.get('eviAccepted')"></xavier-switch>
                  </div>
                </div>
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">Type of evidence provided – select from the following
                    dropdown</p>
                  <div class="score-detail-value col-7 px-1 text-dark">
                    <img [src]="'/assets/images/'+(openAiData.updatedDocumentType == undefined ? 'open_ai' : 'edited')+'.svg'">
                    <xavier-dropdown [itemSource]="evidenceTypeProvidedItemSource" [compareWith]="compareFn" formControlName="documentType">
                      <ng-template let-item #itemTemplate>{{ item?.value | titlecase }}</ng-template>
                      <ng-template let-item #valueTemplate>{{ item?.value | titlecase}}</ng-template>
                    </xavier-dropdown>
                  </div>
                </div>
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">Evidence could be accepted if:</p>
                  <div class="score-detail-value col-7 px-1 text-dark">
                    <img *ngIf="editApprenticeData.get('evidenceCanBeAcceptedIf').value?.edited else notEdited" src="/assets/images/edited.svg">
                    <ng-template #notEdited>
                      <img src="/assets/images/open_ai.svg">
                    </ng-template>
                    <xavier-dropdown [itemSource]="evidenceCanBeAcceptedIfItemSource" [compareWith]="compareFn" formControlName="evidenceCanBeAcceptedIf">
                      <ng-template let-item #itemTemplate>{{ item?.text | titlecase }}</ng-template>
                      <ng-template let-item #valueTemplate>{{ item?.text | titlecase }}</ng-template>
                    </xavier-dropdown>
                  </div>
                </div>
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">Ecctis database extract </p>
                  <div class="score-detail-value col-7 px-1 text-dark">
                    <img src="/assets/images/edited.svg">
                    <xavier-file-upload inputId="Ecctis-1" [control]="editApprenticeData.get('ecctisDatabaseExtract')"></xavier-file-upload>
                  </div>
                </div>
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">Ecctis correspondence</p>
                  <div class="score-detail-value col-7 px-1 text-dark">
                    <img src="/assets/images/edited.svg">
                    <xavier-file-upload inputId="Ecctis-2" [control]="editApprenticeData.get('ecctisCorrespondence')"></xavier-file-upload>
                  </div>
                </div>
                <div class="score-detail">
                  <p class="col-5 m-0 p-0 h6 font-weight-bold">Further information required</p>
                  <div class="score-detail-value col-7 px-1 text-dark">
                    <img src="/assets/images/edited.svg">
                    <xavier-dropdown [itemSource]="moreInformationRequiredItemSource" [compareWith]="compareFn" formControlName="furtherInformationRequired">
                      <ng-template let-item #itemTemplate>{{ item?.value }}</ng-template>
                      <ng-template let-item #valueTemplate>{{ item?.value }}</ng-template>
                    </xavier-dropdown>
                  </div>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
      <div *ngIf="evidenceCheckType.name !== 'evidence-check-completed'" class="row py-5">
        <div class="col-lg-6 col-12 p-0">
          <button *ngIf="prevPageData" type="button" class="btn btn-block xavier-button continue-step apprentice-button"
            [disabled]="disabled" (click)="prevEvidenceCheck()">
            <span>Previous</span>
          </button>
        </div>
        <div class="col-lg-6 col-12 text-right p-0">
          <button *ngIf="nextPageData" type="button" class="btn btn-block xavier-button-plain apprentice-button"
            [disabled]="disabled" (click)="skipEvidenceCheck()">
            <span>Skip</span>
          </button>
          <button type="button" (click)="onSubmit(editApprenticeData)" class="btn btn-block xavier-button continue-step apprentice-button" id="submitButton"
            [disabled]="disabled">
            <span>Save and Next</span>
          </button>
        </div>
      </div>
      <div *ngIf="eventLogs.length !== 0" class="row">
        <div class="col-12 event-container" id="eventLogPanel" [@fadeIn]>
          <h3>Event Log</h3>
          <div class="event-logs">
            <div class="row mx-0" *ngFor="let log of eventLogs">
              <div class="col-12 px-0">{{ log.executorName }}</div>
              <div class="col-6 px-0">{{ log.details }}</div>
              <div class="col-6 px-0 text-right">{{ log.timestamp | date: 'dd MMM y, HH:mm' }}</div>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
