<div class="form-group" [formGroup]="formGroup" *ngIf="!hidden">
  <div>
    <label [for]="formGroupId+'-'+data.id">{{data.attributeName}}</label>
    <span *ngIf="data.required" class="required">&bull;</span>
    <div class="xavier-popover-info">
      <i *ngIf="data.information !== ''"
         class="material-icons"
         tooltip="{{data.information}}"
         placement="auto"
         containerClass="xavier-popover-container">&#xE8FD;</i>
    </div>
  </div>
  <div class="btn-group xavier-button-group"
       [id]="formGroupId+'-'+data.id"
       [formControlName]="formGroupId+'-'+data.id"
       btnRadioGroup
       [(ngModel)]="radioModel">
    <button *ngFor="let option of options" [formControlName]="formGroupId+'-'+data.id" type="button" class="btn xavier-radio" btnRadio="{{option}}">{{option}}</button>
  </div>
  <xavier-error-message [control]="formControl"></xavier-error-message>
  <div class="returned-comment">{{commentMsg}}</div>
</div>
