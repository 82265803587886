import {Component, Input, OnInit} from '@angular/core';
import {EditClientService} from "../../services/edit-client.service";

@Component({
  moduleId: module.id,
  selector: 'xavier-client-side-menu',
  templateUrl: 'client-side-menu.component.html',
  styleUrls: ['client-side-menu.stylesheet.sass']
})

export class ClientSideMenuComponent implements OnInit {

  @Input() public params;
  @Input() public selectedItem;

  constructor(private editClientService: EditClientService) {

  }

  ngOnInit() {
    this.selectedItem = this.params.items[0].id;
    this.editClientService.selectPanel(this.selectedItem);
  }

  setActiveItem(event, active) {
    this.selectedItem = active;
    this.editClientService.selectPanel(active);
  }

}
