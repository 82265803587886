import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  itemsPerPageValues, screeningsDashboardStatuses, screeningsToasterOptions
} from "../../../../data/variables.data";
import {ActivatedRoute, Router} from "@angular/router";
import {StatusIconComponent} from "../../cmps-parts/status-icon/status-icon.component";
import {GenericTableComponent, GtExpandedRow, GtInformation} from "@angular-generic-table/core";
import {addToaster, formatDate, getToasterMessagesLabel} from "../../../utils/functions.utils";
import {ToastyService} from "ng2-toasty";
import {ScreeningService} from "../../services/screening.service";
import {TableService} from "../../services/table.service";
import {UserPermission} from "../../model/permissions.model";
import {UserAuth} from "../../../../core/userAuth.core";
import {Title} from "@angular/platform-browser";
import {FormControl} from "@angular/forms";
import {fadeIn} from "../../../../data/animations.data";
import {isNullOrUndefined} from "util";
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'xavier-screenings',
  templateUrl: 'screenings.component.html',
  styleUrls: ['screenings.stylesheet.sass'],
  animations: [fadeIn]
})

export class ScreeningsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('rowLength', { static: true }) public rowLength: any;
  // @ViewChild('search', { static: true }) public search: any;

  public itemsPerPageValues = itemsPerPageValues;
  public screeningsStatuses = screeningsDashboardStatuses;
  public searchControl = new FormControl();

  @ViewChild('screeningsTable', { static: true })
  private screeningsTable: GenericTableComponent<any, GtExpandedRow<any>>;
  private getScreeningDataReq: any;
  private page: number = 1;
  private records: number = 10;
  private query: string = '';
  private sortBy: string = '';
  private sortOrder: string = '';
  private tableOwner: string;
  private routeSub: any;
  private tableOptionsSub: any;
  private tableOwnerSub: any;
  private searchSub: any;
  private toasterControls = {
    created: false
  };
  private tableInfo = {
    'pageNext': null,
    'pageCurrent': 1,
    'pagePrevious': null,
    'pageTotal': 1,
    'recordFrom': 1,
    'recordLength': 1,
    'recordsAfterFilter': 1,
    'recordsAfterSearch': 1,
    'recordsAll': 1
  };

  constructor(private screeningService: ScreeningService, private router: Router, private activatedRoute: ActivatedRoute,
              private titleService: Title, private toastyService: ToastyService, private userAuth: UserAuth,
              private tableService: TableService, private cdr: ChangeDetectorRef) {
    this.routeSub = this.activatedRoute.queryParams.subscribe(params => {
      this.toasterControls['created'] = (!isNullOrUndefined(params['created'])) ? params['created'] : false;
    });

    this.tableOwnerSub = this.tableService.currentOwner.subscribe(owner => {
      this.tableOwner = owner;
    });

    this.searchSub = this.searchControl.valueChanges
      .debounceTime(1000)
      .subscribe(newValue => {
        this.query = newValue;
        this.page = 1;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(this.page, this.screeningsTable.gtInfo.recordLength, this.query);
      });
  }

  ngOnInit() {
    this.titleService.setTitle('Dashboard - Xavier Eurocom CI');

    this.tableOptionsSub = this.tableService.currentOptions.subscribe(options => {
      if (this.tableOwner === btoa(this.constructor.toString())) {
        this.page = options.page ? options.page : 1;
        this.records = options.records ? options.records : 10;
        this.query = options.query ? options.query : '';
        this.sortBy = options.sortBy ? options.sortBy : '';
        this.sortOrder = options.sortOrder ? options.sortOrder : '';
      } else {
        this.tableService.changeTableOwner(btoa(this.constructor.toString()));
        this.page = 1;
        this.records = 10;
        this.query = '';
        this.sortBy = '';
        this.sortOrder = '';
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
      }
    });

    this.getData(this.page, this.records, this.query, this.sortBy, this.sortOrder);
  }

  ngAfterViewInit() {
    _.forEach(this.toasterControls, ( function(actionValue, action) {
      if (actionValue) {
        let config = screeningsToasterOptions;
        config.msg = getToasterMessagesLabel(action, 'screenings');
        this.toastyService.info(config);
      }
    }).bind(this));

    if (!UserPermission.isAllowed(['SCREENING_FORM_PREVIEW_ALL', 'SCREENING_FORM_PREVIEW_COMPANY', 'SCREENING_FORM_PREVIEW_DIVISION'], this.userAuth.getUser().permissionList)) {
      let tables = document.getElementsByTagName('table');
      tables[0].classList.add('tr-no-pointer');
    }

    this.rowLength.nativeElement.value = this.records;
    // this.search.nativeElement.value = this.query;

    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
    this.tableOptionsSub.unsubscribe();
    this.tableOwnerSub.unsubscribe();
    this.searchSub.unsubscribe();
    this.getScreeningDataReq.unsubscribe();
  }

  getData = function(pageNum: number, pageSize: number, query: string, sortBy?: string, sortOrder?: string) {
    this.data = [];
    let loading: Array<any> = [];
    loading.push({ screeningName: 'Loading...'} );
    this.configObject.data = loading;

    if (typeof this.getScreeningDataReq !== 'undefined') {
      this.getScreeningDataReq.unsubscribe();
    }

    this.info = {
      'pageNext': null,
      'pageCurrent': 1,
      'pagePrevious': null,
      'pageTotal': 1,
      'recordFrom': 1,
      'recordLength': 1,
      'recordsAfterFilter': 1,
      'recordsAfterSearch': 1,
      'recordsAll': 1
    };
    this.configObject.info = <GtInformation>this.info;
    if (isNullOrUndefined(query))
      query = '';
    if (!sortBy)
      sortBy = 'screeningName';
    if (!sortOrder)
      sortOrder = 'ASC';
    this.getScreeningDataReq = this.screeningService.getScreeningsData(pageSize, pageNum, query, sortBy, sortOrder).subscribe(response => {
      if (!isNullOrUndefined(response.list)) {
        this.data = [];
        _.forEach(response.list, (function (screening) {
          let statusValue = (screening.disabled) ? 'DISABLED' : 'ACTIVE';
          let status = this.getStatusProperties(statusValue);
          let formattedDate = screening.lastUsed !== undefined ? formatDate(screening.lastUsed) : 'Never';
          let screeningObject = {
            'id': screening.id,
            'screeningName': screening.screeningName,
            'companyName': screening.companyName,
            'divisionName': screening.divisionName !== undefined ? screening.divisionName : '-',
            'numberOfChecks': screening.numberOfChecks,
            'price': screening.price !== undefined ? '£' + screening.price : '-',
            'cost': screening.cost !== undefined ? '£' + screening.cost : '-',
            'slaTarget': screening.slaTarget !== undefined ? screening.slaTarget : '-',
            'lastUsed': formattedDate,
            'statusComponent': new StatusIconComponent(),
            'statusValue': statusValue,
            'statusType': status.statusType,
            'statusColor': status.statusColor,
            'statusBorder': status.statusBorder,
            'statusFill': status.statusFill,
            'statusSize': status.statusSize
          };
          this.data.push(screeningObject);
        }).bind(this));
        this.configObject.data = this.data;
        let pageNext = pageNum < response.pages ? +pageNum + 1 : null;
        let pagePrev = pageNum === 1 ? null : +pageNum - 1;
        this.tableInfo = {
          'pageNext': +pageNext,
          'pageCurrent': +pageNum,
          'pagePrevious': +pagePrev,
          'pageTotal': response.pages,
          'recordLength': +pageSize,
          'recordsAfterFilter': response.count,
          'recordsAfterSearch': response.count,
          'recordsAll': response.count
        };
        this.configObject.info = <GtInformation>this.tableInfo;
      } else if (!isNullOrUndefined(response.count) && !isNullOrUndefined(response.pages)) {
        this.configObject.data = [];
        this.tableInfo = {
          'pageNext': null,
          'pageCurrent': pageNum,
          'pagePrevious': null,
          'pageTotal': response.pages,
          'recordFrom': response.count,
          'recordLength': pageSize,
          'recordsAfterFilter': response.count,
          'recordsAfterSearch': response.count,
          'recordsAll': response.count
        };
        this.configObject.info = <GtInformation>this.tableInfo;
      }
    }, err => {
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
      this.configObject.data = this.data;
    });
  };

  addScreening() {
    this.router.navigate(['/dashboard/screenings/add'], { skipLocationChange: true });
  }

  convertStatus(status: any) {
    return {
      'statusValue': status.value,
      'statusType': status.icon,
      'statusColor': status.color,
      'statusBorder': status.border,
      'statusFill': status.fill,
      'statusSize': status.size,
    };
  }

  trigger = function($event) {
    switch($event.name) {
      case 'gt-row-select':
      case 'gt-row-deselect':
        if (UserPermission.isAllowed(['SCREENING_FORM_PREVIEW_ALL', 'SCREENING_FORM_PREVIEW_COMPANY', 'SCREENING_FORM_PREVIEW_DIVISION'], this.userAuth.getUser().permissionList))
          this.router.navigate(['/dashboard/screenings/preview/' + $event.value.changedRow.id]);
        break;
      case 'gt-row-length-changed':
        this.records = $event.value;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(this.screeningsTable.gtInfo.pageCurrent, this.records, this.query, this.sortBy, this.sortOrder);
        break;
      case 'gt-page-changed':
        this.page = $event.value.pageCurrent;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(this.page, this.screeningsTable.gtInfo.recordLength, this.query, this.sortBy, this.sortOrder);
        break;
      case 'gt-sorting-applied':
        let sortBy;
        let sortOrder = 'ASC';
        if ($event.value[0].charAt(0) === '-' && $event.value[0].charAt(0) !== '$') {
          sortOrder = 'DESC';
          sortBy = $event.value[0].slice(1, $event.value[0].length);
        } else if ($event.value[0].charAt(0) !== '$') {
          sortBy = $event.value[0];
        }
        this.sortBy = sortBy;
        this.sortOrder = sortOrder;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(
          this.screeningsTable.gtInfo.pageCurrent,
          this.screeningsTable.gtInfo.recordLength,
          this.query, this.sortBy, this.sortOrder
        );
        break;
      default:
        break;
    }
  };

  resetSearch() {
    this.query = '';
    this.tableService.changeOptions({
      page: this.page,
      records: this.records,
      query: this.query
    });
    this.searchControl.setValue('');
  }

  getStatusProperties(value: string) {
    let res = {
      'statusType': '',
      'statusColor': '',
      'statusBorder': '#DEE0E0',
      'statusFill': '',
      'statusSize': 'small'
    };
    _.forEach(screeningsDashboardStatuses, function(status) {
      if (status.name === value) {
        res.statusType = status.icon;
        res.statusColor = status.color;
        res.statusBorder = status.border;
        res.statusFill = status.fill;
        res.statusSize = status.size;
      }
    });
    return res;
  }

  public configObject = {
    settings: [{
      objectKey: 'id',
      visible: false
    }, {
      objectKey: 'screeningName',
      sortOrder: 0,
      columnOrder: 0,
      sort: 'asc'
    }, {
      objectKey: 'companyName',
      sortOrder: 0,
      columnOrder: 1,
      visible: UserPermission.isAllowed(['SCREENING_LIST_ALL'], this.userAuth.getUser().permissionList)
    }, {
      objectKey: 'divisionName',
      sortOrder: 0,
      columnOrder: 2
    }, {
      objectKey: 'numberOfChecks',
      sortOrder: 0,
      columnOrder: 3
    }, {
      objectKey: 'price',
      sortOrder: 0,
      columnOrder: 4
    }, {
      objectKey: 'cost',
      sortOrder: 0,
      columnOrder: 5,
      visible: UserPermission.isAllowed(['CHECK_COST'], this.userAuth.getUser().permissionList)
    }, {
      objectKey: 'slaTarget',
      sortOrder: 0,
      columnOrder: 6
    }, {
      objectKey: 'lastUsed',
      sortOrder: 0,
      columnOrder: 7
    }, {
      objectKey: 'statusComponent',
      sort: 'disable',
      sortOrder: 0,
      columnOrder: 8
    }, {
      objectKey: 'statusValue',
      visible: false
    }, {
      objectKey: 'statusType',
      visible: false
    }, {
      objectKey: 'statusColor',
      visible: false
    }, {
      objectKey: 'statusBorder',
      visible: false
    }, {
      objectKey: 'statusFill',
      visible: false
    }, {
      objectKey: 'statusSize',
      visible: false
    }],
    fields: [{
      name: 'id',
      objectKey: 'id'
    }, {
      name: 'Screening Name',
      objectKey: 'screeningName',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'Company Name',
      objectKey: 'companyName',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'Division',
      objectKey: 'divisionName',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'Checks',
      objectKey: 'numberOfChecks',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'Price',
      objectKey: 'price',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border text-right';
        else
          return 'text-right'
      }
    }, {
      name: 'Cost',
      objectKey: 'cost',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border text-right';
        else
          return 'text-right'
      }
    }, {
      name: 'SLA',
      objectKey: 'slaTarget',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'Last Used',
      objectKey: 'lastUsed',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    },{
      name: 'Status',
      objectKey: 'statusComponent',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      },
      columnComponent: {
        type: StatusIconComponent
      }
    }],
    data: [],
    info: {}
  };

}
