import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { fadeIn } from "../../../../data/animations.data";
import { FormControl } from "@angular/forms";
import { idVerificationDashboardStatuses, itemsPerPageValues } from "../../../../data/variables.data";
import { GenericTableComponent, GtExpandedRow, GtInformation } from "@angular-generic-table/core";
import { TableService } from "../../services/table.service";
import { Title } from "@angular/platform-browser";
import { CandidateScreeningService } from "../../services/candidate-screening.service";
import { isNullOrUndefined } from "util";
import * as _ from 'lodash';
import { addToaster, formatDate } from "../../../utils/functions.utils";
import { StatusIconComponent } from "../../cmps-parts/status-icon/status-icon.component";
import { NavigationStart, Router } from "@angular/router";
import { ToastyService } from "ng2-toasty";
@Component({
  moduleId: module.id,
  selector: "xavier-id-verifications",
  templateUrl: "./id-verifications.component.html",
  styleUrls: ["./id-verifications.component.sass"],
  animations: [fadeIn],
})
export class IdVerificationsComponent implements OnInit {
  @ViewChild('rowLength', { static: true }) public rowLength: any;

  public startRouteUrl: string;
  public itemsPerPageValues = itemsPerPageValues;
  public searchControl = new FormControl();
  public verificationsStatuses = idVerificationDashboardStatuses;
  public data: any = [];

  @ViewChild("idVerificationsTable", { static: true })
  private idVerificationsTable: GenericTableComponent<any, GtExpandedRow<any>>;
  private query: string = '';
  private page: number = 1;
  private records: number = 10;
  private getIdVerificationsDataReq: any;
  private sortBy: string = '';
  private sortOrder: string = '';
  private searchSub: any;
  private tableOptionsSub: any;
  private routeSub: any;
  private tableOwnerSub: any;
  private tableOwner: string;

  private tableInfo = {
    'pageNext': null,
    'pageCurrent': 1,
    'pagePrevious': null,
    'pageTotal': 1,
    'recordFrom': 1,
    'recordLength': 1,
    'recordsAfterFilter': 1,
    'recordsAfterSearch': 1,
    'recordsAll': 1
  };

  constructor(
    private router: Router,
    private tableService: TableService,
    private titleService: Title,
    private candidateScreeningService: CandidateScreeningService,
  ) {
    this.startRouteUrl = this.router.url;
    this.tableOwnerSub = this.tableService.currentOwner.subscribe((owner) => {
      this.tableOwner = owner;
    });
    this.searchSub = this.searchControl.valueChanges
      .debounceTime(1000)
      .subscribe((newValue) => {
        this.query = newValue;
        this.page = 1;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder,
        });
        this.getData(
          this.page,
          this.idVerificationsTable.gtInfo.recordLength,
          this.query
        );
      });
  }

  ngOnInit() {
    this.titleService.setTitle('Dashboard - Xavier Eurocom CI');

    this.routeSub = this.router.events.subscribe((value) => {
      if (value instanceof NavigationStart)
        this.startRouteUrl = value.url;
    });

    this.tableOptionsSub = this.tableService.currentOptions.subscribe(options => {
      if (this.tableOwner === btoa(this.constructor.toString())) {
        this.page = options.page ? options.page : 1;
        this.records = options.records ? options.records : 10;
        this.query = options.query ? options.query : '';
        this.sortBy = options.sortBy ? options.sortBy : '';
        this.sortOrder = options.sortOrder ? options.sortOrder : '';
      } else {
        this.tableService.changeTableOwner(btoa(this.constructor.toString()));
        this.page = 1;
        this.records = 10;
        this.query = '';
        this.sortBy = '';
        this.sortOrder = '';
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
      }
    });

    this.getData(this.page, this.records, this.query, this.sortBy, this.sortOrder);
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
    this.searchSub.unsubscribe();
    this.tableOptionsSub.unsubscribe();
    this.tableOwnerSub.unsubscribe();
    this.getIdVerificationsDataReq.unsubscribe();
  }

  getData = function(pageNum: number, pageSize: number, query: string, sortBy?: string, sortOrder?: string) {
    this.data = [];
    let loading: Array<any> = [];
    loading.push({ candidateName: 'Loading...'} );
    this.configObject.data = loading;

    if (typeof this.getIdVerificationsDataReq !== 'undefined') {
      this.getIdVerificationsDataReq.unsubscribe();
    }

    this.info = {
      'pageNext': null,
      'pageCurrent': 1,
      'pagePrevious': null,
      'pageTotal': 1,
      'recordFrom': 1,
      'recordLength': 1,
      'recordsAfterFilter': 1,
      'recordsAfterSearch': 1,
      'recordsAll': 1
    };
    this.configObject.info = <GtInformation>this.info;

    if (isNullOrUndefined(query))
      query = '';
    if (!sortBy)
      sortBy = 'candidateName';
    if (!sortOrder)
      sortOrder = 'ASC';
    this.getIdVerificationsDataReq = this.candidateScreeningService
      .getIdVerifications(pageSize, pageNum, query, sortBy, sortOrder)
      .subscribe(
        (response) => {
          if (!isNullOrUndefined(response.items)) {
            this.data = [];

            _.forEach(
              response.items,
              function (verification) {
                let statusValue = verification.status;
                let status = this.getStatusProperties(statusValue);
                let verificationObject = {
                  id: verification.candidateId,
                  candidateName: verification.candidateName,
                  dbsCheckType: verification.dbsCheckType,
                  position: verification.position,
                  workForceType: verification.workForceType,
                  barringList: verification.barringList,
                  createdDate: formatDate(verification.createdDate),
                  status: verification.status,
                  statusComponent: new StatusIconComponent(),
                  statusValue,
                  statusType: status.statusType,
                  statusColor: status.statusColor,
                  statusBorder: status.statusBorder,
                  statusFill: status.statusFill,
                  statusSize: status.statusSize,
                  verificationId: verification.id,
                };
                this.data.push(verificationObject);
              }.bind(this)
            );
            this.configObject.data = this.data;
            let pageNext = pageNum < response.pages ? pageNum + 1 : null;
            let pagePrev = pageNum === 1 ? null : pageNum - 1;
            this.tableInfo = {
              pageNext: pageNext,
              pageCurrent: pageNum,
              pagePrevious: pagePrev,
              pageTotal: response.pages,
              recordLength: pageSize,
              recordsAfterFilter: response.count,
              recordsAfterSearch: response.count,
              recordsAll: response.count,
            };
            this.configObject.info = <GtInformation>this.tableInfo;
          } else if (!isNullOrUndefined(response.count) && !isNullOrUndefined(response.pages)) {
            this.configObject.data = [];
            this.tableInfo = {
              pageNext: null,
              pageCurrent: pageNum,
              pagePrevious: null,
              pageTotal: response.pages,
              recordFrom: response.count,
              recordLength: pageSize,
              recordsAfterFilter: response.count,
              recordsAfterSearch: response.count,
              recordsAll: response.count,
            };
            this.configObject.info = <GtInformation>this.tableInfo;
          }
        },
        (err) => {
          let action = "serverError";
          if (err.status === 403) action = "403";
          addToaster("error", this.toastyService, action, "errors");
          this.configObject.data = this.data;
        }
      );
  }

  trigger = function($event) {
    switch($event.name) {
      case 'gt-row-select':
      case 'gt-row-deselect':
        const candidateID = $event.value.changedRow.id;
        const verificationID = $event.value.changedRow.verificationId;
        this.router.navigate([`/dashboard/candidates/id-verification/${candidateID}/${verificationID}`]);
        break;
      case 'gt-row-length-changed':
        this.records = $event.value;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(this.idVerificationsTable.gtInfo.pageCurrent, this.records, this.query, this.sortBy, this.sortOrder);
        break;
      case 'gt-page-changed':
        this.page = $event.value.pageCurrent;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(this.page, this.idVerificationsTable.gtInfo.recordLength, this.query, this.sortBy, this.sortOrder);
        break;
      case 'gt-sorting-applied':
        let sortBy;
        let sortOrder = 'ASC';
        if ($event.value[0].charAt(0) === '-' && $event.value[0].charAt(0) !== '$') {
          sortOrder = 'DESC';
          sortBy = $event.value[0].slice(1, $event.value[0].length);
        } else if ($event.value[0].charAt(0) !== '$') {
          sortBy = $event.value[0];
        }
        this.sortBy = sortBy;
        this.sortOrder = sortOrder;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(
          this.idVerificationsTable.gtInfo.pageCurrent,
          this.idVerificationsTable.gtInfo.recordLength,
          this.query, this.sortBy, this.sortOrder
        );
        break;
      default:
        break;
    }
  };

  resetSearch() {
    this.query = '';
    this.tableService.changeOptions({
      page: this.page,
      records: this.records,
      query: this.query
    });
    this.idVerificationsTable.gtSearch("");
  }

  getStatusProperties(value: string) {
    let res = {
      'statusType': '',
      'statusColor': '',
      'statusBorder': '#DEE0E0',
      'statusFill': '',
      'statusSize': 'small'
    };
    _.forEach(idVerificationDashboardStatuses, function(status) {
      if (status.name === value) {
        res.statusType = status.icon;
        res.statusColor = status.color;
        res.statusBorder = status.border;
        res.statusFill = status.fill;
        res.statusSize = status.size;
      }
    });
    return res;
  }

  convertStatus(status: any) {
    return {
      'statusValue': status.value,
      'statusType': status.icon,
      'statusColor': status.color,
      'statusBorder': status.border,
      'statusFill': status.fill,
      'statusSize': status.size,
    };
  }

  public configObject = {
    settings: [
      {
        objectKey: 'id',
        visible: false
      },
      {
        objectKey: "candidateName",
        sortOrder: 0,
        columnOrder: 0,
        sort: "asc",
      },
      {
        objectKey: "dbsCheckType",
        sortOrder: 0,
        columnOrder: 1,
      },
      {
        objectKey: "position",
        sortOrder: 0,
        columnOrder: 2,
      },
      {
        objectKey: "workForceType",
        sortOrder: 0,
        columnOrder: 3,
      },
      {
        objectKey: "barringList",
        sortOrder: 0,
        columnOrder: 4,
      },
      {
        objectKey: "createdDate",
        sortOrder: 0,
        columnOrder: 5,
      },
      {
        objectKey: 'statusComponent',
        sort: 'disable',
        sortOrder: 0,
        columnOrder: 6
      }, {
        objectKey: 'statusValue',
        visible: false
      }, {
        objectKey: 'statusType',
        visible: false
      }, {
        objectKey: 'statusColor',
        visible: false
      }, {
        objectKey: 'statusBorder',
        visible: false
      }, {
        objectKey: 'statusFill',
        visible: false
      }, {
        objectKey: 'statusSize',
        visible: false
      }
    ],
    fields: [
      {
        name: 'id',
        objectKey: 'id'
      },
      {
        name: "Candidate Name",
        objectKey: "candidateName",
        columnClass: (row, col) => {
          if (row == undefined) return "no-top-border";
        },
      },
      {
        name: "DBS Check",
        objectKey: "dbsCheckType",
        columnClass: (row, col) => {
          if (row == undefined) return "no-top-border";
        },
      },
      {
        name: "Position",
        objectKey: "position",
        columnClass: (row, col) => {
          if (row == undefined) return "no-top-border";
        },
      },
      {
        name: "Workforce Type",
        objectKey: "workForceType",
        columnClass: (row, col) => {
          if (row == undefined) return "no-top-border";
        },
      },
      {
        name: "Barring List",
        objectKey: "barringList",
        columnClass: (row, col) => {
          if (row == undefined) return "no-top-border";
        },
      },
      {
        name: "Date",
        objectKey: "createdDate",
        columnClass: (row, col) => {
          if (row == undefined) return "no-top-border";
        },
      },
      {
        name: 'Status',
        objectKey: 'statusComponent',
        columnClass: (row, col) => {
          if (row == undefined)
            return 'no-top-border'
        },
        columnComponent: {
          type: StatusIconComponent
        }
      }
    ],
    data: [],
    info: {}
  };
}
