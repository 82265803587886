<div class="row">
  <div class="col-lg-7 col-md-12">
    <div class="stepper d-flex flex-column mt-4">
      <div class="d-flex mb-1">
        <div class="d-flex flex-column pr-3 align-items-center">
          <div class="rounded-circle px-3 py-2 circles">1</div>
          <div class="line h-100"></div>
        </div>
        <div>
          <h4 class="text-dark">Please have your passport ready</h4>
          <p class="text-muted pb-3"></p>
        </div>
      </div>
      <div class="d-flex mb-1">
        <div class="d-flex flex-column pr-3 align-items-center">
          <div class="rounded-circle px-3 py-2 circles">2</div>
          <div class="line h-100"></div>
        </div>
        <div>
          <h4 class="text-dark">Download the free Yoti app</h4>
          <p class="text-dark">And create a Yoti account</p>
          <div class="row mx-0 align-self-center">
            <div class="w-50">
              <a href="https://play.google.com/store/apps/details?id=com.yoti.mobile.android.live" target="_blank">
                <img src="assets/img/google-play-badge.png" alt="" class="google-badge">
              </a>
            </div>
            <div class="w-50">
              <a href="https://apps.apple.com/gb/app/yoti-your-digital-identity/id983980808" target="_blank">
                <img src="assets/img/app-store-badge.png" alt="" class="apple-badge">
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex mb-1">
        <div class="d-flex flex-column pr-3 align-items-center">
          <div class="rounded-circle px-3 py-2 circles">3</div>
          <div class="line h-100 d-none"></div>
        </div>
        <div>
          <h4 class="text-dark">Add your passport to Yoti</h4>
          <p class="text-dark">It should be verified within minutes</p>
        </div>
      </div>
    </div>
  </div>
</div>
