import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnDestroy,
  Output,
  Renderer2, TemplateRef,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DatePipe} from '@angular/common'
import {Subject} from "rxjs";
import {DynamicFormDirective} from "../../../directives/dynamic-form.directive";
import {DynamicFormService} from "../../services/dynamic-form.service";
import {CandidateService} from "../../services/candidate.service";
import {CandidateScreeningService} from "../../services/candidate-screening.service";
import {ReferenceFormService} from "../../services/reference-form.service";
import {BsModalRef} from "ngx-bootstrap/modal/bs-modal-ref.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {UserAuth} from "../../../../core/userAuth.core";
import {ToastyService} from "ng2-toasty";
import {environment} from "../../../../environments/environment";
import {UserPermission} from "../../model/permissions.model";
import {candidatesOverviewDashboardFilters, referencesOverviewDashboardFilters} from "../../../../data/variables.data";
import {fadeIn, slideLeftFromHidden, slideRightFromHidden} from "../../../../data/animations.data";
import {emailValidator} from "../../../utils/form.validators";
import {addToaster, getEventLogActivityTypeLabel, parseDate, setAsTouched} from "../../../utils/functions.utils";
import {isNullOrUndefined} from "util";
import * as _ from 'lodash';
import {takeUntil} from 'rxjs/operators';

function toJsDate(value: string): Date {
  // yyyy-mm-dd
  const datePattern = /^(\d{4})-(\d{2})-(\d{2})$/;
  const [, year, month, day] = datePattern.exec(value);
  return new Date(year as any, (month as any)-1, day as any);
}

@Component({
  moduleId: module.id,
  selector: 'xavier-dashboard-dynamic-form',
  templateUrl: 'dashboard-dynamic-form.component.html',
  styleUrls: [
    'dashboard-dynamic-form.stylesheet.sass',
    'dashboard-dynamic-form-mediaqueries.stylesheet.sass'
  ],
  animations: [fadeIn, slideLeftFromHidden, slideRightFromHidden]
})

export class DashboardDynamicFormComponent implements AfterViewInit, OnDestroy {

  @ViewChild(DynamicFormDirective) dnForm: DynamicFormDirective;
  @ViewChild('referenceChangeModal', { static: true }) referenceChangeModal: TemplateRef<any>;
  @ViewChild('referenceCancelModal', { static: true }) referenceCancelModal: TemplateRef<any>;

  @Input() public formModel: any;
  @Input() public formData: any;
  @Input() public formComments: any;
  @Input() public details: any;
  @Input() public preview: boolean;
  @Input() public dashboard: boolean;
  @Input() public candidateId: string;
  @Input() public screeningId: string;
  @Input() public globalValidationMsg: boolean = false;
  @Input() public screeningStatus: string;
  @Input() public isScreeningCompleted: boolean = false;
  @Input() public selectedItem;
  @Input() public sidePanel = true;
  @Input() public checkStatus: any = {
    statusBorder: "",
    statusFill: "",
    statusColor: "",
    statusSize: "",
    statusType: ""
  };
  @Input() public eventLogLoading: boolean = true;

  @Output() public submitted = new EventEmitter<boolean>();
  @Output() public dataChanged = new EventEmitter<void>();

  public checkStatusValue;
  public eventLog;
  public startCheckVisibility: boolean = false;
  public startCheckDisabled: boolean = false;
  public approveCheckVisibility: boolean = false;
  public approveCheckDisabled: boolean = false;
  public reactivateCheckVisibility: boolean = false;
  public reactivateCheckDisabled: boolean = false;
  public returnCheckVisibility: boolean = false;
  public returnCheckDisabled: boolean = false;
  public delayCheckVisibility: boolean = false;
  public delayCheckDisabled: boolean = false;
  public resumeCheckVisibility: boolean = false;
  public resumeCheckDisabled: boolean = false;
  public flagCheckVisibility: boolean = false;
  public adverseCheckVisibility: boolean = false;
  public institutionVisibility: boolean = false;
  public institution: string;
  public saveChangeReferenceConfirm: boolean = false;
  public changeReferenceModalData: FormGroup;
  public changeReferenceEmail: string;
  public changeReferenceStatus: string;
  public modalRef: BsModalRef;

  public saveCancelReferenceConfirm = false;
  public cancelReferenceModalData: FormGroup;

  private modalReference: any;
  private modalReferenceId: string;
  private globalValidationMsgSub;
  private panelSub;
  private dfContent;
  private dfMenu;
  private formGroupsFromData = [];
  private eventLogSub;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private dynamicFormService: DynamicFormService, private cdr: ChangeDetectorRef,
              private candidateService: CandidateService, private candidateScreeningService: CandidateScreeningService,
              private toastyService: ToastyService, private userAuth: UserAuth, private renderer: Renderer2,
              private datepipe: DatePipe, private modalService: BsModalService, private fb: FormBuilder,
              private referenceFormService: ReferenceFormService) {
    this.globalValidationMsgSub = this.dynamicFormService.globalValidationMsg$.subscribe(value => {
      this.globalValidationMsg = value;
    });
    this.panelSub = this.dynamicFormService.selectedPanel$.subscribe(panel => {
      this.selectedItem = panel;
    });

    this.eventLogSub = this.candidateScreeningService.eventLogEntry$.takeUntil(this.ngUnsubscribe).subscribe(entry => {
      let eventPanel = document.getElementById('eventLogPanel');
      let newEntry = document.createElement('div');
      newEntry.classList.add('row');
      let executor = document.createElement('div');
      executor.classList.add('col-8');
      executor.classList.add('executor');
      executor.innerText = entry.executorName + ', ' + entry.executorRole;
      let timestamp = document.createElement('div');
      timestamp.classList.add('col-4');
      timestamp.classList.add('timestamp');
      timestamp.classList.add('text-right');
      timestamp.innerText = this.datepipe.transform(new Date(), 'dd MMM yyyy, HH:mm');
      let activity = document.createElement('div');
      activity.classList.add('col-12');
      activity.classList.add('activity');
      activity.innerText = getEventLogActivityTypeLabel(entry.activityType);
      if (entry.details !== '')
        activity.innerText += ' - ' + entry.details;

      newEntry.appendChild(executor);
      newEntry.appendChild(timestamp);
      newEntry.appendChild(activity);

      setTimeout(() => {
        if (!isNullOrUndefined(eventPanel) && !isNullOrUndefined(eventPanel.firstChild))
          this.renderer.insertBefore(eventPanel, newEntry, eventPanel.firstChild);
      }, 0);
    });
  }

  ngOnInit() {
    this.dfMenu = document.getElementById('dfMenu');
    this.dfContent = document.getElementById('dfContent');
    // select the first check
    // or select the first non-submitted check if in candidate mode and desktop view
    let width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;
    if (width > 1200) {
      let active = this.searchNextCheckToActivate(0);
      if (active !== 0)
        this.selectedItem = active;
      else
        this.selectedItem = !isNullOrUndefined(this.formModel.checks) ? this.formModel.checks[0].id : '';
    }

    this.updateFormGroupsFromData();

    this.eventLogSub = this.candidateScreeningService.getCandidateScreeningLog(this.candidateId).subscribe(eventLog => {
      if (!isNullOrUndefined(eventLog.items)) {
        this.eventLog = eventLog.items;
        this.eventLogLoading = false;
      }
    });

    this.changeReferenceModalData = this.fb.group({
      contactName: [''],
      institution: [''],
      phone: [''],
      email: ['', [Validators.required, emailValidator]],
      changeReferenceConsent: [false],
    });

    this.changeReferenceModalData.get('email').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
    ).subscribe(() => {
      const consent = this.changeReferenceModalData.get('changeReferenceConsent');
      if (this.showChangeReferenceConsent) {
        consent.setValidators(Validators.requiredTrue);
      } else {
        consent.clearValidators();
      }
      consent.updateValueAndValidity();
    });

    this.cancelReferenceModalData = this.fb.group({
      reason: [null, [Validators.required]],
      notes: [null],
    });
  }

  ngAfterViewInit() {
    if (this.preview && this.dashboard) {
      setTimeout(() => {
        this.createStructure();
      }, 0);
      setTimeout(() => {
        this.patchData();
      }, 500);
    }

    this.checkStatusControlsVisibility();

    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.globalValidationMsgSub.unsubscribe();
    this.panelSub.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public checkStatusControlsVisibility() {
    this.startCheckVisibility = false;
    this.approveCheckVisibility = false;
    this.returnCheckVisibility = false;
    this.delayCheckVisibility = false;
    this.resumeCheckVisibility = false;
    this.flagCheckVisibility = false;
    this.adverseCheckVisibility = false;
    this.reactivateCheckVisibility = false;
    _.forEach(this.formModel.checks, ( function(check) {
      const isIdentityVerification = this.isIdentityVerification(check);

      if (this.selectedItem === check.id && check.checkStatus === 'CANDIDATE_SAVED' &&
        (this.formModel.screeningStatus === 'IN_PROGRESS' || this.formModel.screeningStatus === 'DELAYED')) {
        this.startCheckDisabled = false;
        this.startCheckVisibility = true;
      }
      if (this.selectedItem === check.id && check.checkStatus === 'IN_PROGRESS') {
        this.delayCheckVisibility = !isIdentityVerification;
        this.approveCheckVisibility = true;
        this.returnCheckVisibility = !isIdentityVerification;
        this.flagCheckVisibility = true;
        this.adverseCheckVisibility = true;
        return false;
      }
      if (this.selectedItem === check.id && check.checkStatus === 'DELAYED') {
        this.delayCheckVisibility = false;
        this.approveCheckVisibility = false;
        this.returnCheckVisibility = false;
        this.resumeCheckDisabled = false;
        this.resumeCheckVisibility = true;
        return false;
      }
      if (this.selectedItem === check.id && check.checkStatus === 'PASSED' && !this.isScreeningCompleted) {
        this.reactivateCheckVisibility = true;
        return false;
      }
      if (this.selectedItem === check.id && check.checkStatus === 'WITH_CANDIDATE' && isIdentityVerification) {
        this.approveCheckVisibility = true;
        this.flagCheckVisibility = true;
        this.adverseCheckVisibility = true;
        return false;
      }
      if (this.selectedItem === check.id
        && (check.checkStatus !== 'RETURNED' && check.checkStatus !== 'FLAGGED' && check.checkStatus !== 'ADVERSE')
        && !this.isScreeningCompleted) {
        this.returnCheckVisibility = true;
      }
    }).bind(this));
  }

  public isIdentityVerification(check): boolean {
    return check && check.groups && check.groups.length > 0 && check.groups[0].attributes && check.groups[0].attributes.find(a => a.dataType === 'IDENTITY_VERIFICATION') != null;
  }

  public getIdentityVerificationCheckId(): string {
    const identityVerificationCheck = this.formModel.checks.find(ch => this.isIdentityVerification(ch));
    return identityVerificationCheck ? identityVerificationCheck.id : null;
  }

  createStructure() {
    // the first pass will create in order the top level groups, level 1, so the children have a parent to be attached to
    // it relies on the the ordering of the data from the backend ! IMPORTANT !
    _.forEach(this.formGroupsFromData, ( function(groupId) {
      setTimeout(() => {
        let checkId = this.getCheckIdFromGroupId(groupId);
        let groupComponentInstance = this.dynamicFormService.getGroupComponentInstance(groupId);
        if (groupComponentInstance === undefined && _.includes(this.formGroupsFromData, groupId) &&
          groupId.indexOf('#') !== -1 && this.selectedItem === checkId) {
          let parentNode = _.find(this.formData, function (o) {
            if (o.hasOwnProperty('parentNodeId') && o.nodeId.substring(0, o.nodeId.lastIndexOf('-')) === groupId)
              return o;
          });
          // if parentNode is null this is a top level group, level 1
          if (isNullOrUndefined(parentNode)) {
            let firstElement = <HTMLElement>(document.querySelector('xavier-dashboard-df-group > div'));
            if (firstElement.id.indexOf('#') !== -1) {
              let tempInstance = this.dynamicFormService.getGroupComponentInstance(firstElement.id);
              if (!isNullOrUndefined(tempInstance))
                groupComponentInstance = tempInstance.addDfGroup(null, groupId.substring(0, groupId.indexOf('#')+1));
            } else {
              let temp = groupId.split('#');
              let parentElementIdPrefix = '1#' + temp[1];
              let tempInstance = this.dynamicFormService.getGroupComponentInstance(parentElementIdPrefix);
              if (!isNullOrUndefined(tempInstance))
                groupComponentInstance = tempInstance.addDfGroup(null, groupId.substring(0, groupId.indexOf('#')+1));
            }
          } else {
            // else this is a child, it might be attached to the wrong father for the time being
            // it's checked and amended in the second pass
            let parentElem = <HTMLElement>document.getElementById(parentNode.parentNodeId);
            if (!isNullOrUndefined(parentElem)) {
              const idWithoutGroupPrefix = groupId.split("#")[1];
              let siblingElem = <HTMLElement>(<HTMLElement>parentElem.querySelector(`xavier-dashboard-df-group > div[id$='#${idWithoutGroupPrefix}']`));
              let tempInstance = this.dynamicFormService.getGroupComponentInstance(siblingElem.id);
              if (!isNullOrUndefined(tempInstance))
                groupComponentInstance = tempInstance.addDfGroup(parentNode.parentNodeId, groupId.substring(0, groupId.indexOf('#') + 1));
            }
          }
        }
        if (groupComponentInstance !== undefined && _.includes(this.formGroupsFromData, groupId) &&
          groupComponentInstance.isVisible === false && this.selectedItem === checkId) {
          // console.log('18 visibility check: ' + groupId + ' ' + true);
          groupComponentInstance.visibilityCheck(true);
        }
      }, 0);
    }).bind(this));

    // this second pass is needed to amend children appended in the wrong place
    // and to fill the parentNodeId field in the group instance
    _.forEach(this.formGroupsFromData, ( function(groupId) {
      setTimeout(() => {
        let checkId = this.getCheckIdFromGroupId(groupId);
        let groupComponentInstance = this.dynamicFormService.getGroupComponentInstance(groupId);
        if (groupComponentInstance !== undefined && _.includes(this.formGroupsFromData, groupId) &&
          groupComponentInstance.isVisible === true && this.selectedItem === checkId) {
          let parentNode = _.find(this.formData, function (o) {
            if (o.hasOwnProperty('parentNodeId') && o.nodeId.substring(0, o.nodeId.lastIndexOf('-')) === groupId)
              return o;
          });
          if (!isNullOrUndefined(parentNode) && groupComponentInstance.parentNodeId !== parentNode.parentNodeId) {
            groupComponentInstance.parentNodeId = parentNode.parentNodeId;
            let containerElem = <HTMLElement>(document.getElementById(groupId)).parentElement.parentElement;
            if (parentNode.parentNodeId !== '' && !isNullOrUndefined(document.getElementById(parentNode.parentNodeId)) && containerElem.id !== parentNode.parentNodeId)
              document.getElementById(parentNode.parentNodeId).appendChild(document.getElementById(groupId).parentElement);
          }
        }
      }, 0);
    }).bind(this));
  }

  patchData() {
    // display Comments
    _.forEach(this.formComments, ( function(comments) {
      setTimeout(() => {
        this.appendComments(comments.nodeId);
      }, 0);
    }).bind(this));

    let allGroups = this.dynamicFormService.getAllFormGroups();
    _.forEach(this.formGroupsFromData, ( function(groupId) {
      let groupComponentInstance = this.dynamicFormService.getGroupComponentInstance(groupId);
      if (groupComponentInstance !== undefined && _.includes(Array.from(allGroups.keys()), groupId)) {
        let checkId = this.getCheckIdFromGroupId(groupId);
        _.forEach(this.formData, ( function(node) {
          if (_.startsWith(node.nodeId, groupId) && this.selectedItem === checkId) {
            // display FIXED attribute class and popover
            if (node.fixed) {
              let elem = document.getElementById('db-container_'+node.nodeId);
              if (!isNullOrUndefined(elem))
                elem.classList.add('fixed');
              if (!isNullOrUndefined(node.previousValue))
                this.dynamicFormService.getAttributeComponentInstance(node.nodeId).setPopover({
                  message: 'Old Value - ' + node.previousValue
                });
            } else {
              let attr = document.getElementById(node.nodeId);
              if (!isNullOrUndefined(attr))
                attr.setAttribute('disabled', 'disabled');
            }

            // display attribute Value
            if (node.hasOwnProperty('file')) { // fileupload or signature image
              this.addFile(node.nodeId, node.file);
            }
            else if (parseDate(node.value)) { // date value
              let attr = document.getElementById(node.nodeId);

              if (!isNullOrUndefined(attr)) {
                attr.innerText = toJsDate(node.value).toDateString();
              }
            }
            else {
              let attr = document.getElementById(node.nodeId);
              if (!isNullOrUndefined(attr))
                attr.innerText = node.value;
            }
          }

          const component = this.dynamicFormService.getAttributeComponentInstance(node.nodeId);
          if (!isNullOrUndefined(component)) {
            component.prefilledByYoti = node.prefilled && !node.returned;
            component.verifiedValue = component.prefilledByYoti && node.verifiedValue;
            component.value = node.value;
            component.file = node.file;
            component.verifiedFile = node.verifiedFile;
          }
        }).bind(this));
      }
    }).bind(this));
  }

  private addFile(nodeId: string, file) {
    let img = document.createElement('img');

    let fileName;
    let previewFileName;
    if (file.hasOwnProperty('thumbnail')) {
      previewFileName = 'https://' + environment.storageBucket + '/gcs/' + file.folder + '/' +
        file.thumbnail.replace('@xN', '@x3');
    } else {
      previewFileName = 'https://' + environment.storageBucket + '/gcs/' + file.folder + '/' + file.name;
    }
    img.setAttribute('style', 'max-height: 200px; max-width: 280px;');
    fileName = 'https://' + environment.storageBucket + '/gcs/' + file.folder + '/' + file.name;

    if (_.endsWith(previewFileName, '.pdf')) {
      img.setAttribute('src', 'assets/img/pdf-icon.jpg');
      img.setAttribute('width', '50px');
    } else {
      img.setAttribute('src', previewFileName);
    }

    let fileHref = document.createElement('a');
    fileHref.classList.add('pointer');
    fileHref.setAttribute('href', fileName + '?download=true');
    fileHref.setAttribute('download', fileName);
    fileHref.appendChild(img);

    let container = document.getElementById(nodeId);
    container.appendChild(fileHref);
    container.parentElement.classList.add('file-container');
  }

  startCheck(checkId) {
    let startCheck = document.getElementById('startCheck');
    this.startCheckDisabled = true;
    const spinnerWrap = document.createElement('div');
    spinnerWrap.classList.add('spinner-wrap');
    spinnerWrap.classList.add('button', 'grey');

    startCheck.innerHTML = '';
    startCheck.appendChild(spinnerWrap);

    this.candidateScreeningService.startCheck(this.candidateId, this.screeningId, checkId).subscribe(result => {
      if (!isNullOrUndefined(result.id)) {
        this.candidateScreeningService.sendEventLogEntry(result);
        _.forEach(this.formModel.checks, ( function(check, index) {
          if (checkId === check.id) {
            this.formModel.checks[index].checkStatus = 'IN_PROGRESS';
            return false;
          }
        }).bind(this));
      }
    }, err => {
      startCheck.innerHTML = '<span>Start</span>';
      this.startCheckDisabled = false;
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
    }, () => {
      startCheck.innerHTML = '<span>Start</span>';
      this.startCheckDisabled = true;
      this.startCheckVisibility = false;
      this.approveCheckVisibility = true;
      this.returnCheckVisibility = true;
      this.delayCheckVisibility = true;
      this.flagCheckVisibility = true;
      this.adverseCheckVisibility = true;
      let fixed = document.getElementsByClassName('fixed');
      if (fixed.length > 0)
        _.forEach(fixed, function(element) {
            setTimeout(() => {
              if (!isNullOrUndefined(element))
                element.classList.remove('fixed');
            }, 0);
        });
    });
  }

  approveCheck(checkId) {
    let approveCheck = document.getElementById('approveCheck');
    this.approveCheckDisabled = true;
    const spinnerWrap = document.createElement('div');
    spinnerWrap.classList.add('spinner-wrap');
    spinnerWrap.classList.add('button');

    approveCheck.innerHTML = '';
    approveCheck.appendChild(spinnerWrap);

    this.candidateScreeningService.passCheck(this.candidateId, this.screeningId, checkId).subscribe(result => {
      if (!isNullOrUndefined(result.id)) {
        this.candidateScreeningService.sendEventLogEntry(result);
        _.forEach(this.formModel.checks, ( function(check, index) {
          if (checkId === check.id) {
            this.formModel.checks[index].checkStatus = 'PASSED';
            return false;
          }
        }).bind(this));

        let canCompleteScreening = !this.isScreeningCompleted;
        _.forEach(this.formModel.checks, ( function(check) {
          if (check.checkStatus === 'IN_PROGRESS' ||
            check.checkStatus === 'WITH_CANDIDATE' ||
            check.checkStatus === 'CANDIDATE_SAVED' ||
            check.checkStatus === 'RETURNED') {
            canCompleteScreening = false;
            return false;
          }
        }).bind(this));
        if (this.formModel.hasOwnProperty('references')) {
          _.forEach(this.formModel.references, ( function(reference) {
            if (reference.checkStatus === 'WITH_CANDIDATE') {
              canCompleteScreening = false;
              return false;
            }
          }).bind(this));
        }
        if (canCompleteScreening) {
          this.dynamicFormService.canCompleteScreeningNotification(canCompleteScreening);
        }
      }
      const identityVerificationCheckId = this.getIdentityVerificationCheckId();
      if (identityVerificationCheckId === this.selectedItem) {
        this.dataChanged.emit();
      }
    }, err => {
      approveCheck.innerHTML = '<i class="material-icons">&#xE876;</i>';
      this.startCheckDisabled = false;
      this.approveCheckDisabled = false;
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
    }, () => {
      approveCheck.innerHTML = '<i class="material-icons">&#xE876;</i>';
      this.startCheckDisabled = false;
      this.approveCheckDisabled = false;
      this.dynamicFormService.checksCompletedNotification(true);
      this.checkStatusControlsVisibility();
    });
  }

  reactivateCheck(checkId) {
    let reactivateCheck = document.getElementById('reactivateCheck');
    this.reactivateCheckDisabled = true;
    const spinnerWrap = document.createElement('div');
    spinnerWrap.classList.add('spinner-wrap');
    spinnerWrap.classList.add('button');

    reactivateCheck.innerHTML = '';
    reactivateCheck.appendChild(spinnerWrap);

    this.candidateScreeningService.reactivateCheck(this.candidateId, this.screeningId, checkId).subscribe(result => {
      if (!isNullOrUndefined(result.id)) {
        this.candidateScreeningService.sendEventLogEntry(result);
        _.forEach(this.formModel.checks, ( function(check, index) {
          if (checkId === check.id) {
            this.formModel.checks[index].checkStatus = 'IN_PROGRESS';
            return false;
          }
        }).bind(this));
        this.checkStatusControlsVisibility();
      }
    }, err => {
      reactivateCheck.innerHTML = '<i class="material-icons">sync</i>';
      this.reactivateCheckDisabled = false;
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
    }, () => {
      reactivateCheck.innerHTML = '<i class="material-icons">sync</i>';
      this.reactivateCheckDisabled = false;
      this.dynamicFormService.checksCompletedNotification(false);
      this.checkStatusControlsVisibility();
    });
  }

  returnCheck(checkId) {
    let modalData = {
      title: 'Enter the reason why you\'re returning',
      submitButtonIcon: 'keyboard_return',
      submitButtonLabel: 'Return',
      type: 'return',
      elementId: checkId
    };
    this.dynamicFormService.toolbarModalNotification(modalData);
  }

  delayCheck(checkId) {
    let modalData = {
      title: 'Enter the reason why you\'re delaying',
      submitButtonIcon: 'hourglass_full',
      submitButtonLabel: 'Delay',
      type: 'delayCheck',
      elementId: checkId
    };
    this.dynamicFormService.toolbarModalNotification(modalData);
  }

  resumeCheck(checkId) {
    let resumeCheck = document.getElementById('resumeCheck');
    this.resumeCheckDisabled = true;
    const spinnerWrap = document.createElement('div');
    spinnerWrap.classList.add('spinner-wrap');
    spinnerWrap.classList.add('button');
    spinnerWrap.classList.add('resume-bg');

    resumeCheck.innerHTML = '';
    resumeCheck.appendChild(spinnerWrap);

    this.candidateScreeningService.resumeCheck(this.candidateId, this.screeningId, checkId).subscribe(result => {
      if (result.hasOwnProperty('newScreeningStatus'))
        this.dynamicFormService.screeningStatus(result.newScreeningStatus);

      if (result.hasOwnProperty('newCheckStatus')) {
        _.forEach(this.formModel.checks, ( function(check, index) {
          if (checkId === check.id) {
            this.formModel.checks[index].checkStatus = result.newCheckStatus;
            return false;
          }
        }).bind(this));
        this.checkStatusControlsVisibility();
      }

      if (!isNullOrUndefined(result.log))
        this.candidateScreeningService.sendEventLogEntry(result.log);
    }, err => {
      resumeCheck.innerHTML = '<i class="material-icons">play_arrow</i>';
      this.resumeCheckDisabled = false;
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
    }, () => {
      resumeCheck.innerHTML = '<i class="material-icons">play_arrow</i>';
      this.approveCheckVisibility = true;
      this.delayCheckVisibility = true;
      this.resumeCheckVisibility = false;
    });
  }

  flagCheck(checkId) {
    let modalData = {
      title: 'Enter the reason why you\'re flagging',
      submitButtonIcon: 'flag',
      submitButtonLabel: 'Flag',
      type: 'flag',
      elementType: 'check',
      elementId: checkId
    };
    this.dynamicFormService.toolbarModalNotification(modalData);
  }

  adverseCheck(checkId) {
    let modalData = {
      title: 'Comment on why the information is marked as adverse',
      submitButtonIcon: 'close',
      submitButtonLabel: 'Adverse',
      type: 'adverse',
      elementType: 'check',
      elementId: checkId
    };
    this.dynamicFormService.toolbarModalNotification(modalData);
  }

  commentCheck(checkId) {
    let modalData = {
      title: 'Enter your comment below',
      submitButtonIcon: 'comment',
      submitButtonLabel: 'Comment',
      type: 'comment',
      elementType: 'check',
      elementId: checkId
    };
    this.dynamicFormService.toolbarModalNotification(modalData);
  }

  addFileCheck(checkId) {
    document.getElementById('fileUploader').click();
    let modalData = {
      type: 'upload',
      elementType: 'check',
      elementId: checkId
    };
    this.dynamicFormService.toolbarModalNotification(modalData);
  }

  commentReference(referenceId) {
    let modalData = {
      title: 'Enter your comment below',
      submitButtonIcon: 'comment',
      submitButtonLabel: 'Comment',
      type: 'comment',
      elementType: 'reference',
      elementId: referenceId
    };
    this.dynamicFormService.toolbarModalNotification(modalData);
  }

  addFileReference(referenceId) {
    document.getElementById('fileUploader').click();
    let modalData = {
      type: 'upload',
      elementType: 'reference',
      elementId: referenceId
    };
    this.dynamicFormService.toolbarModalNotification(modalData);
  }

  updateFormGroupsFromData() {
    let unique = [];
    let repeated = [];
    _.forEach(this.formData, (function (node) {
      let formGroupId = node.nodeId.substring(0, node.nodeId.lastIndexOf('-'));
      if (!_.includes(repeated, formGroupId) && !_.includes(unique, formGroupId)) {
        if (formGroupId.indexOf('#') !== -1)
          repeated.push(formGroupId);
        else
          unique.push(formGroupId);
      }
    }).bind(this));
    this.formGroupsFromData = unique.concat(repeated);
  }

  searchNextCheckToActivate(checkId) {
    let result = 0;
    _.forEach(this.formModel.checks, function(check) {
      if (check.checkStatus !== 'CANDIDATE_SAVED' &&
          check.id !== checkId) {
        result = check.id;
        return false;
      }
    });
    return result;
  }

  setActiveItem(event, active) {
    if (this.selectedItem !== active) {
      this.dfContent.classList.remove('d-none');
      this.dfMenu.classList.add('d-none');
      let width = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
      if (!this.preview && width <= 1200)
        this.sidePanel = false;

      this.selectedItem = active;
      this.checkStatusControlsVisibility();

      if (this.selectedItem !== active)
        this.globalValidationMsg = false;

      this.dynamicFormService.selectPanel(active);

      setTimeout(() => {
        this.createStructure();
      }, 0);
      setTimeout(() => {
        this.patchData();
      }, 500);
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  appendComments(nodeId: string) {
    let comments = _.find(this.formComments, ['nodeId', nodeId]);
    if (!isNullOrUndefined(comments)) {
      let dbCommentsContainer = document.getElementById('db-comments_'+nodeId);
      if (!dbCommentsContainer) return;
      while (dbCommentsContainer.lastChild) dbCommentsContainer.removeChild(dbCommentsContainer.lastChild);

      _.forEach(comments.comments, ( function(comment) {
        if (!isNullOrUndefined(dbCommentsContainer)) {
          let commentContainer = document.createElement('div');
          commentContainer.setAttribute('id', 'comment_' + nodeId + '_' + comment.id);
          commentContainer.classList.add('comment');
          let commentIcon = document.createElement('i');
          commentIcon.classList.add('material-icons');
          let commentText = document.createElement('span');
          commentText.setAttribute('id', 'commentText_' + nodeId + '_' + comment.id);
          commentText.classList.add('text');
          if (comment.type === 'FILE')
            commentText.innerText = comment.file.name;
          else
            commentText.innerText = comment.comment;
          let commentDate = document.createElement('span');
          commentDate.classList.add('date');
          commentDate.innerText = this.datepipe.transform(new Date(comment.createdDate), 'dd MMM yyyy, HH:mm');

          switch (comment.type) {
            case 'COMMENT':
              commentIcon.innerText = 'comment';
              break;
            case 'FILE':
              commentIcon.innerText = 'file_download';
              commentContainer.classList.add('pointer');
              break;
            case 'RETURN':
              commentIcon.innerText = 'keyboard_return';
              break;
            case 'FLAG':
              commentIcon.innerText = 'flag';
              commentIcon.classList.add('flag');
              break;
            case 'ADVERSE':
              commentIcon.innerText = 'close';
              commentIcon.classList.add('adverse');
              break;
            default:
              break;
          }

          if (comment.type === 'FILE') {
            let commentHref = document.createElement('a');
            let file = 'https://' + environment.storageBucket + '/gcs/' + comment.file.folder + '/' + comment.file.name + '?download=true';
            commentHref.setAttribute('href', file);
            commentHref.setAttribute('download', comment.file.name);
            commentHref.appendChild(commentIcon);
            commentHref.appendChild(commentText);
            commentHref.appendChild(commentDate);
            commentContainer.appendChild(commentHref);
          } else {
            commentContainer.appendChild(commentIcon);
            commentContainer.appendChild(commentText);
            commentContainer.appendChild(commentDate);
          }

          // add the buttons to the comment, file etc.
          if (!this.isScreeningCompleted
            && UserPermission.isAllowed(['CANDIDATE_SCREENING_EDIT'], this.userAuth.getUser().permissionList)) {
            let buttonsContainer = document.createElement('div');
            buttonsContainer.classList.add('buttons-container');
            buttonsContainer.classList.add('d-none');

            let editButton;
            let modalData;
            if (comment.type !== 'FILE') {
              editButton = document.createElement('button');
              editButton.setAttribute('type', 'button');
              editButton.classList.add('btn');
              editButton.classList.add('xavier-button-plain');
              editButton.classList.add('commentButton');
              editButton.innerHTML = '<i class="material-icons">edit</i>';
              modalData = {
                title: 'Edit comment',
                submitButtonIcon: 'edit',
                submitButtonLabel: 'Edit',
                type: comment.type,
                elementId: nodeId,
                text: commentText.innerText,
                edit: true,
                id: comment.id
              };
              editButton.addEventListener('click', (function () {
                this.dynamicFormService.toolbarModalNotification(modalData);
              }).bind(this));
            }

            let deleteModalData = {
              elementId: nodeId,
              deleteComment: true,
              commentContainerId: 'comment_' + nodeId + '_' + comment.id,
              commentId: comment.id
            };
            let deleteButton = document.createElement('button');
            deleteButton.setAttribute('type', 'button');
            deleteButton.classList.add('btn');
            deleteButton.classList.add('xavier-button-plain');
            deleteButton.classList.add('commentButton');
            deleteButton.innerHTML = '<i class="material-icons">delete</i>';
            deleteButton.addEventListener('click', (function () {
              this.dynamicFormService.confirmDeleteModalNotification(deleteModalData);
            }).bind(this));

            buttonsContainer.appendChild(deleteButton);
            if (comment.type !== 'FILE')
              buttonsContainer.appendChild(editButton);
            commentContainer.appendChild(buttonsContainer);
            commentContainer.addEventListener('mouseenter', function () {
              buttonsContainer.classList.remove('d-none');
              deleteButton.classList.remove('d-none');
            });
            commentContainer.addEventListener('mouseleave', function () {
              buttonsContainer.classList.add('d-none');
              deleteButton.classList.add('d-none');
            });
          }
          dbCommentsContainer.appendChild(commentContainer);
        }
      }).bind(this));
    }
  }

  getCheckStatus(checkStatus) {
    _.forEach(candidatesOverviewDashboardFilters, ( function(status) {
      if (status.name === checkStatus) {
        this.checkStatus.statusType = status.icon;
        this.checkStatus.statusColor = status.color;
        this.checkStatus.statusBorder = status.border;
        this.checkStatus.statusFill = status.fill;
        this.checkStatus.statusSize = status.size;
      }
    }).bind(this));
    return this.checkStatus;
  }

  getCheckStatusValue(checkStatus) {
    _.forEach(candidatesOverviewDashboardFilters, ( function(status) {
      if (status.name === checkStatus)
        this.checkStatusValue = status.value;
    }).bind(this));
    return this.checkStatusValue;
  }

  getReferenceStatus(referenceStatus) {
    _.forEach(referencesOverviewDashboardFilters, ( function(status) {
      if (status.name === referenceStatus) {
        this.checkStatus.statusType = status.icon;
        this.checkStatus.statusColor = status.color;
        this.checkStatus.statusBorder = status.border;
        this.checkStatus.statusFill = status.fill;
        this.checkStatus.statusSize = status.size;
      }
    }).bind(this));
    return this.checkStatus;
  }

  getReferenceStatusValue(referenceStatus) {
    _.forEach(referencesOverviewDashboardFilters, ( function(status) {
      if (status.name === referenceStatus)
        this.checkStatusValue = status.value;
    }).bind(this));
    return this.checkStatusValue;
  }

  openChangeReferenceModal(reference: any) {
    this.modalReference = reference;
    this.modalReferenceId = reference.referenceId;
    if (reference.hasOwnProperty('institution')) {
      this.institutionVisibility = true;
      this.institution = reference.institution;
    } else {
      this.institutionVisibility = false;
    }

    this.changeReferenceEmail = reference.email;
    this.changeReferenceStatus = reference.checkStatus;

    this.changeReferenceModalData.get('contactName').setValue(reference.contactName);
    this.changeReferenceModalData.get('institution').setValue(reference.institution);
    this.changeReferenceModalData.get('phone').setValue(reference.phone);
    this.changeReferenceModalData.get('email').setValue(reference.email);
    this.modalRef = this.modalService.show(
      this.referenceChangeModal,
      Object.assign({}, { 'class': 'change-reference'} )
    );
  }

  public get showChangeReferenceConsent(): boolean {
    return this.changeReferenceStatus === 'CANDIDATE_SAVED' && this.changeReferenceModalData.get('email').value !== this.changeReferenceEmail;
  }

  onCompleteChangeReference({ value, valid }: { value: any, valid: boolean }) {
    if (!valid) {
      setAsTouched(this.changeReferenceModalData);
    } else {
      this.saveChangeReferenceConfirm = true;
      let submitButton = document.getElementById('saveChangeReferenceConfirm');
      const spinnerWrap = document.createElement('div');
      spinnerWrap.classList.add('spinner-wrap');
      spinnerWrap.classList.add('button');

      submitButton.innerHTML = '';
      submitButton.appendChild(spinnerWrap);

      this.referenceFormService.editReferenceData(this.candidateId, this.modalReferenceId, value).subscribe(response => {
        if (response.log && !isNullOrUndefined(response.log.id)) {
          this.candidateScreeningService.sendEventLogEntry(response.log);
          _.forEach(this.formModel.references, ( function(reference, index) {
            if (this.modalReference.id === reference.id) {
              if (!isNullOrUndefined(value.contactName))
                this.formModel.references[index].contactName = value.contactName;
              if (!isNullOrUndefined(value.institution))
                this.formModel.references[index].institution = value.institution;
              if (!isNullOrUndefined(value.phone))
                this.formModel.references[index].phone = value.phone;
              if (!isNullOrUndefined(value.email))
                this.formModel.references[index].email = value.email;
              return false;
            }
          }).bind(this));
        }
        if (response.newReferenceStatus) {
          _.find(this.formModel.references, {id: this.selectedItem}).checkStatus = response.newReferenceStatus;
        }
        this.closeModal();
        this.saveChangeReferenceConfirm = false;
        submitButton.innerHTML = 'Save Changes';
        this.dataChanged.emit();
      }, err => {
        let action = 'serverError';
        if (err.status === 403)
          action = '403';
        addToaster('error', this.toastyService, action, 'errors');
        this.saveChangeReferenceConfirm = false;
        submitButton.innerHTML = 'Save Changes';
      });
    }
  }

  public openCancelReferenceModal(reference: any) {
    this.modalReference = reference;
    this.modalReferenceId = reference.referenceId;

    this.modalRef = this.modalService.show(
      this.referenceCancelModal,
      Object.assign({}, { 'class': 'cancel-reference'} )
    );
  }

  public onCompleteCancelReference({ value, valid }: { value: any, valid: boolean }) {
    if (!valid) {
      setAsTouched(this.cancelReferenceModalData);
    } else {
      this.saveCancelReferenceConfirm = true;
      const submitButton = document.getElementById('saveCancelReferenceConfirm');
      const spinnerWrap = document.createElement('div');
      spinnerWrap.classList.add('spinner-wrap');
      spinnerWrap.classList.add('button');

      submitButton.innerHTML = '';
      submitButton.appendChild(spinnerWrap);

      this.referenceFormService.cancelReference(this.candidateId, this.modalReferenceId, value).subscribe(response => {
        if (response.log && !isNullOrUndefined(response.log.id)) {
          this.candidateScreeningService.sendEventLogEntry(response.log);
        }
        if (response.newReferenceStatus) {
          _.find(this.formModel.references, {id: this.selectedItem}).checkStatus = response.newReferenceStatus;
        }
        this.closeModal();
        this.saveCancelReferenceConfirm = false;
        submitButton.innerHTML = 'Save';
      }, err => {
        let action = 'serverError';
        if (err.status === 403)
          action = '403';
        addToaster('error', this.toastyService, action, 'errors');
        this.saveCancelReferenceConfirm = false;
        submitButton.innerHTML = 'Save';
      });
    }
  }

  getCheckIdFromGroupId (groupId) {
    let checkId;
    if (groupId.indexOf('#') !== -1) {
      let temp = groupId.split('#');
      checkId = temp[1].split('-')[0];
    } else {
      checkId = groupId.split('-')[0];
    }
    return checkId;
  }

  closeModal() {
    this.modalRef.hide();
  }

  backToMenu() {
    this.dfContent.classList.add('d-none');
    this.dfMenu.classList.remove('d-none');

    let width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;
    if (width <= 1200) {
      this.sidePanel = true;
      this.selectedItem = 0;
    }
  }

}
