<ng2-toasty [position]="'top-center'" class="xavier-toasty"></ng2-toasty>
<div class="xavier-screening-dashboard-wrapper container">
  <div *ngIf="loading" class="xavier-center-page" [@fadeIn]>
    <div class="spinner-container not-button"><div class="spinner-wrap"></div></div>
  </div>
  <div *ngIf="!loading" [@fadeIn]>
    <div class="row header">
      <div class="col-1 text-center">
        <button type="button" class="btn xavier-button-plain white" (click)="backToTable()">
          <i class="material-icons back-link">&#xE5C4;</i>
        </button>
      </div>
      <div class="col-5">
        <h1>{{screening?.candidate.fullName}}</h1>
        <p>{{screening?.companyName}}</p>
      </div>
      <div class="col-sm-7 col-xl-6 edit-group text-right">
        <div class="btn-group" dropdown>
          <button *ngIf="!resendInviteVisible || emailCandidateVisible || reactivateScreeningVisible || deleteCandidateVisible || terminateScreeningVisible || viewExportScreening"
                  dropdownToggle type="button"
                  class="btn btn-block xavier-button-plain dropdown-toggle xavier-dropdown-toggle weight-normal">
            More <span class="caret xavier-caret"></span>
          </button>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-right xavier-dropdown-edit-more-menu" role="menu">
            <li *ngIf="!resendInviteVisible" role="menuitem">
              <a class="dropdown-item text-center" (click)="resendInvite()">
                Resend Invite
              </a>
            </li>
            <li *ngIf="emailCandidateVisible" role="menuitem">
              <a class="dropdown-item text-center" (click)="emailCandidate()">
                Email Candidate
              </a>
            </li>
            <li *ngIf="reactivateScreeningVisible" role="menuitem">
              <a class="dropdown-item text-center" (click)="reactivateScreening()">
                Reactivate Screening
              </a>
            </li>
            <li *ngIf="terminateScreeningVisible" role="menuitem">
              <a class="dropdown-item text-center" (click)="terminateScreening()">
                Terminate Screening
              </a>
            </li>
            <li *ngIf="changeScreeningVisible" role="menuitem">
              <a class="dropdown-item text-center" (click)="changeScreening()">
                Change Screening
              </a>
            </li>

            <li *ngIf="runCreditsafeVisible" role="menuitem">
              <a class="dropdown-item text-center" (click)="onRunCreditSafeClicked()">
                Run Creditsafe
              </a>
            </li>

            <li *ngIf="viewExportScreening" role="menuitem">
              <a class="dropdown-item text-center" (click)="exportScreening()">
                Export Screening
              </a>
            </li>
            <li *ngIf="downloadDeclarationVisible" role="menuitem">
              <span [ngClass]="downloadDeclarationHrefDisabled">
                <a class="dropdown-item text-center" [ngClass]="downloadDeclarationHrefDisabled" [href]="downloadDeclarationHref">
                  Download Declaration
                </a>
              </span>
            </li>
            <li *ngIf="downloadInternationalDeclarationVisible" role="menuitem">
              <span>
                <a class="dropdown-item text-center" [href]="downloadInternationalDeclarationHref">
                  International Consent - Owens
                </a>
              </span>
            </li>
            <li *ngIf="downloadInternationalDeclarationVisible2" role="menuitem">
              <span>
                <a class="dropdown-item text-center" [href]="downloadInternationalDeclarationHref2">
                  International Consent - Info Cubic
                </a>
              </span>
            </li>
            <li *ngIf="loginAsCandidateVisible" role="menuitem">
              <a class="dropdown-item text-center" (click)="loginAsCandidate()">
                Login As Candidate
              </a>
            </li>
            <li *ngIf="deleteCandidateVisible" role="menuitem">
              <a class="dropdown-item delete text-center" (click)="deleteCandidate()">
                Delete Candidate
              </a>
            </li>
          </div>
        </div>
        <ng-container *isAllowed="['CANDIDATE_SCREENING_EDIT']">
          <button *ngIf="completeVisible" [disabled]="completeDisabled" type="button" class="btn xavier-button continue-step weight-normal"
                  id="completeScreening" (click)="openCompleteModal()">
            <span>Complete Screening</span>
          </button>
          <button *ngIf="startVisible" [disabled]="startDisabled" type="button" class="btn xavier-button continue-step weight-normal"v
                  id="startScreening" (click)="startScreening()">
            <span>Start Screening</span>
          </button>
          <button *ngIf="returnVisible" [disabled]="returnDisabled" type="button" class="btn xavier-button continue-step weight-normal"
                  id="returnScreening" (click)="returnScreening()">
            <span>Return Screening</span>
          </button>
        </ng-container>
        <ng-container *isAllowed="['CANDIDATE_REPORT_VIEW_ALL', 'CANDIDATE_REPORT_VIEW_LIMITED']">
          <button *ngIf="viewCertificateVisible" [disabled]="viewCertificateDisabled" type="button" class="btn xavier-button continue-step weight-normal"
                  id="viewCertificate" (click)="viewCertificate()">
            <span><i class="material-icons">visibility</i> View Certificate</span>
          </button>
          <button *ngIf="downloadReportVisible" [disabled]="downloadReportDisabled" type="button" class="btn xavier-button continue-step weight-normal"
                  id="downloadReport" (click)="downloadReport()">
            <span><i class="material-icons">file_download</i> Download Report</span>
          </button>
        </ng-container>
      </div>
    </div>
    <div class="row screening-status">
      <div class="col-sm-12 col-xl-2">
        Completed
        <div class="num-out-of">{{screening?.checksCompleted}} / {{screening?.checksCount}}</div>
        <div class="progress">
          <div class="progress-bar bg-gray" role="progressbar" [style.width]="percCompletedChecks+'%'" [style.height]="'4px'" [attr.aria-valuenow]="percCompletedChecks" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
      <div *ngIf="screening.screeningStatus !== 'PASSED'" class="col-sm-12 col-xl-3">
        SLA Days Remaining
        <div class="num-out-of">{{screening.screeningStatus !== 'WITH_CANDIDATE' || screening.remainingSLA ? screening.remainingSLA : '-'}} / {{screening.screeningStatus !== 'WITH_CANDIDATE' || screening.remainingSLA ? screening.screeningSLA : '-'}}</div>
        <div class="progress">
          <div class="progress-bar bg-gray" role="progressbar" [style.width]="percCompletedSLA+'%'" [style.height]="'4px'" [attr.aria-valuenow]="percCompletedSLA" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
      <div class="col-sm-12 col-xl-6">
        <div>Overall Status</div>
        <div class="status-icon"><xavier-status-icon [row]="screeningStatus"></xavier-status-icon></div><div class="status-text">{{statusValue}}</div>
      </div>
    </div>
    <xavier-dashboard-dynamic-form #dashboardDynamicForm
                                   [formModel]="dynamicForm"
                                   [formData]="dynamicFormData"
                                   [formComments]="dynamicFormComments"
                                   [details]="details"
                                   [preview]="true"
                                   [dashboard]="true"
                                   [screeningStatus]="screening.screeningStatus"
                                   [isScreeningCompleted]="screening.screeningStatus === 'COMPLETED' || screening.screeningStatus === 'PASSED' || screening.screeningStatus === 'FLAGGED' || screening.screeningStatus === 'ADVERSE' || screening.screeningStatus === 'TERMINATED'"
                                   [screeningId]="screening.screening.id"
                                   [candidateId]="id"
                                   (dataChanged)="onDataChanged()">
    </xavier-dashboard-dynamic-form>
    <input #fileInput type="file" id="fileUploader" (click)="fileInput.value = null" value="" (change)="fileUpload($event)" />
  </div>
</div>

<xavier-start-screening-dialog *ngIf="isStartDialogVisible" [noUkAddresses]="noUkAddresses" (success)="onScreeningStarted($event)" (cancel)="isStartDialogVisible = false">
  <span modalTitle>Start Screening</span>
  <span buttonTitle>Start Screening</span>
</xavier-start-screening-dialog>
<xavier-start-screening-dialog *ngIf="isRunCreditsafeVisible" [onlyCreditsafe]="true" [noUkAddresses]="noUkAddresses" (success)="isRunCreditsafeVisible = false" (cancel)="isRunCreditsafeVisible = false">
  <span modalTitle>Run Creditsafe</span>
  <span buttonTitle>Run Creditsafe</span>
</xavier-start-screening-dialog>

<ng-template #toolbarModal>
  <div class="modal-header">
    <h2 class="modal-title pull-left">{{modalData?.title}}</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="toolbarModalData" novalidate (ngSubmit)="onSubmitModal(toolbarModalData)">
      <textarea class="modal-textarea" placeholder="{{modalData?.placeholder}}" id="modalTextArea" formControlName="modalTextArea"
                rows="3" cols="59" spellcheck="true" autofocus></textarea>
      <xavier-error-message [control]="toolbarModalData.get('modalTextArea')"></xavier-error-message>
      <div class="modal-button-group">
        <button type="submit" id="toolbarModalSubmitButton" [disabled]="toolbarModalSubmitButtonDisabled"
                class="btn xavier-button continue-step weight-normal">
          <i class="material-icons">{{modalData?.submitButtonIcon}}</i> {{modalData?.submitButtonLabel}}
        </button>
        <button type="button" class="btn xavier-button-plain" (click)="closeModal()">
          Cancel
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #confirmDeleteModal>
  <div class="modal-header">
    <h2 class="modal-title pull-left">Are you sure you want to delete this element?</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeDeleteModal()">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div class="modal-body">
    It will still exist in the Event Log for this check.
    <div class="modal-button-group">
      <button type="button" class="btn xavier-button deleteConfirm weight-normal" (click)="confirmDeleteComment()">
        Delete
      </button>
      <button type="button" class="btn xavier-button-plain" (click)="closeDeleteModal()">
        Close
      </button>
    </div>
  </div>
</ng-template>

<ng-template #emailCandidateModal>
  <div class="modal-header">
    <h2 class="modal-title pull-left">Email Candidate</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeEmailModal()">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="emailCandidateModalData" novalidate (ngSubmit)="onEmailCandidate(emailCandidateModalData)">
      <div class="row">
        <div class="form-group col-9 xavier-input-text">
          <label for="emailSubject">Subject</label>
          <input type="text" class="form-control modal-input" id="emailSubject" formControlName="emailSubject" placeholder="Subject">
          <xavier-error-message [control]="emailCandidateModalData.get('emailSubject')"></xavier-error-message>
        </div>
        <div class="form-group col-9 xavier-input-text">
          <label for="emailBody">Body</label>
          <textarea class="modal-textarea" placeholder="Body" id="emailBody" formControlName="emailBody"
                    rows="3" cols="58" spellcheck="true"></textarea>
          <xavier-error-message [control]="emailCandidateModalData.get('emailBody')"></xavier-error-message>
        </div>
        <div class="form-group col-9">
          <input #emailAttachment class="email-attachment" type="file" id="emailAttachment" formControlName="emailAttachment"
                 value="" (click)="emailAttachment.value = null" (change)="attachmentChange($event)" />
          <xavier-error-message [control]="emailCandidateModalData.get('emailAttachment')"></xavier-error-message>
        </div>
      </div>
      <div class="modal-button-group">
        <button type="submit" id='emailCandidateConfirm' [disabled]='emailCandidateConfirmDisabled'
                class="btn xavier-button continue-step weight-normal">
          Send Email
        </button>
        <button type="button" class="btn xavier-button-plain" (click)="closeEmailModal()">
          Cancel
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #terminateScreeningModal>
  <div class="modal-header">
    <h2 class="modal-title pull-left">Terminate Screening</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="terminateScreeningModalData" novalidate (ngSubmit)="onTerminateScreening(terminateScreeningModalData)">
      <div class="row">
        <div class="form-group col-5 xavier-input-text">
          <label for="additionalCost">Additional Cost</label>
          <div class="input-group">
            <span class="input-group-addon">£</span>
            <input type="text" class="form-control" id="additionalTerminateCost" formControlName="additionalTerminateCost"
                   placeholder="Additional Cost" spellcheck="true">
          </div>
          <xavier-error-message [control]="terminateScreeningModalData.get('additionalTerminateCost')"></xavier-error-message>
        </div>
        <div class="form-group col-9 xavier-input-text">
          <label for="additionalCost">Closing Comment</label>
          <input type="text" class="form-control" id="closingTerminateComment" formControlName="closingTerminateComment" placeholder="Closing Comment">
          <xavier-error-message [control]="terminateScreeningModalData.get('closingTerminateComment')"></xavier-error-message>
        </div>
      </div>
      <div class="modal-button-group">
        <button type="submit" id='terminateScreeningConfirm' [disabled]='terminateScreeningConfirmDisabled'
                class="btn xavier-button continue-step weight-normal">
          Terminate Screening
        </button>
        <button type="button" class="btn xavier-button-plain" (click)="closeModal()">
          Cancel
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #changeScreeningModal>
  <div class="modal-header">
    <h2 class="modal-title pull-left">Change Screening</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div class="modal-body">
    <p class="mr-5">Changing the screening will delete any data entered by the candidate so far, and send them a new request.</p>
    <form [formGroup]="changeScreeningModalData" novalidate (ngSubmit)="onChangeScreening(changeScreeningModalData)">
      <div class="row">
        <div class="form-group col-sm-9 xavier-input-text">
          <label for="screeningName">New screening type</label>
          <div>
            <input #input type="text" class="form-control dropdown-shadow" placeholder="Select screening"
              formControlName="newScreeningName" autocomplete="one-time-code" (focus)="onWrapClick()" (keyup)="onKeyUp($event)"
              (paste)="onChange($event, componentParams.allowTyping)" (keydown)="onKeyDown($event, componentParams.allowTyping)"
              (keypress)="allowTypingOnKeyPress(componentParams.allowTyping)">
            <img *ngIf="displayLoader" class="loaderIcon" src="../../../../assets/img/loader.gif" />
          </div>

          <div *ngIf="displayValues" class="dropdown-values-wrapper">
            <div class="display-data" #dropdown>
              <ng-container *ngIf="screeningItemSource?.length != 0">
                <div *ngFor="let entry of screeningItemSource; let i = index" (click)="selectEntry(entry)" class="entry"
                  [class.selected]="i === currentPos" (mouseenter)="currentPos = -1">
                  {{ entry.screeningName }}
                </div>
              </ng-container>
              <ng-container *ngIf="screeningItemSource?.length == 0 && displayValues">
                <div class="entry-no-results">
                  No results
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-button-group">
        <button type="submit" id='changeScreeningConfirm' [disabled]='changeScreeningConfirmDisabled'
                class="btn xavier-button continue-step weight-normal">
                Delete and Resend
        </button>
        <button type="button" class="btn xavier-button-plain" (click)="closeModal()">
          Cancel
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #deleteCandidateModal>
  <div class="modal-header">
    <h2 class="modal-title pull-left">Are you sure you want to delete this candidate?</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div class="modal-body">
    You will lose all the information for this candidate. This action is immediate and cannot be undone.
    <div class="modal-button-group">
      <button type="button" class="btn xavier-button deleteConfirm weight-normal" (click)="confirmDeleteCandidate()">
        Delete Candidate
      </button>
      <button type="button" class="btn xavier-button-plain" (click)="closeModal()">
        Close
      </button>
    </div>
  </div>
</ng-template>

<ng-template #completeScreeningModal>
  <div class="modal-header">
    <h2 class="modal-title pull-left">Complete Screening</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="completeScreeningModalData" novalidate (ngSubmit)="onCompleteScreening(completeScreeningModalData)">
      <div class="row">
        <div class="form-group col-5 xavier-input-text">
          <label for="additionalCost">Additional Cost</label>
          <div class="input-group">
            <span class="input-group-addon">£</span>
            <input type="text" class="form-control" id="additionalCost" formControlName="additionalCost" placeholder="Additional Cost" autofocus>
          </div>
          <xavier-error-message [control]="completeScreeningModalData.get('additionalCost')"></xavier-error-message>
        </div>
        <div class="form-group col-9 xavier-input-text">
          <label for="additionalCost">Closing Comment</label>
          <input type="text" class="form-control" id="closingComment" formControlName="closingComment"
                 placeholder="Closing Comment" spellcheck="true">
          <xavier-error-message [control]="completeScreeningModalData.get('closingComment')"></xavier-error-message>
        </div>
      </div>
      <div class="modal-button-group">
        <button type="submit" id='completeScreeningConfirm' [disabled]='completeScreeningConfirmDisabled'
                class="btn xavier-button continue-step weight-normal">
          Complete Screening
        </button>
        <button type="button" class="btn xavier-button-plain" (click)="closeModal()">
          Cancel
        </button>
      </div>
    </form>
  </div>
</ng-template>
