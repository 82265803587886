import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {UserAuth} from "../../../../core/userAuth.core";
import {ToastyService} from "ng2-toasty";
import {CandidateService} from "../../services/candidate.service";
import {CandidateScreeningService} from "../../services/candidate-screening.service";
import {DynamicFormService} from "../../services/dynamic-form.service";
import {BsModalService} from 'ngx-bootstrap/modal';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {forkJoin} from "rxjs/observable/forkJoin";
import {addToaster} from "../../../utils/functions.utils";
import {isNullOrUndefined} from "util";
import {fadeIn, fadeOut} from "../../../../data/animations.data";
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'xavier-candidate-screening',
  templateUrl: 'candidate-screening.component.html',
  styleUrls: ['candidate-screening.stylesheet.sass'],
  animations: [fadeIn, fadeOut]
})

export class CandidateScreeningComponent implements OnInit, OnDestroy {

  @ViewChild('welcomeModal') welcomeModal: BsModalRef;
  @ViewChild('submitModal', { static: true }) submitModal: TemplateRef<any>;

  @Input() public loading: boolean = true;
  @Input() public submitted: boolean = false;
  @Input() public serverError: boolean = false;
  @Input() public modalConfig: any;
  @Input() public submitModalConfig: any;
  @Input() public companyName: string = '';
  @Input() public userName: string = '';
  @Input() public dynamicForm: any;
  @Input() public dynamicFormData: any;
  @Input() public dynamicFormComments: any;
  @Input() public preview: boolean = false;
  @Input() public canSubmitScreening: boolean = false;
  @Input() public modalSubmitButtonDisabled: boolean = false;

  footerCopyrightDate = new Date();

  public modalRef: BsModalRef;

  private canSubmitScreeningSub;

  constructor(private dynamicFormService: DynamicFormService, private candidateService: CandidateService,
              private candidateScreeningService: CandidateScreeningService, private titleService: Title,
              private modalService: BsModalService, private userAuth: UserAuth, private toastyService: ToastyService) {
    this.canSubmitScreeningSub = this.dynamicFormService.canSubmitScreening$.subscribe(value => {
      this.canSubmitScreening = value;
      if (value)
        this.modalRef = this.modalService.show(this.submitModal, this.submitModalConfig);
    });
  }

  ngOnInit() {
    this.titleService.setTitle('My Screening - Xavier Eurocom CI');
    this.modalConfig = { show: (!this.submitted && !this.serverError) };

    let user = this.userAuth.getUser();
    this.userName = user.firstName;
    this.companyName = user.companyName;

    forkJoin([
      this.candidateScreeningService.getMyScreening(),
      this.candidateScreeningService.getMyScreeningData()
    ]).subscribe(results => {
      if (!isNullOrUndefined(results[0].screeningName)) {
        this.dynamicForm = results[0];
        this.dynamicFormService.dynamicForm = this.dynamicForm;
        let canSubmitScreening = true;
        this.dynamicForm.checks.forEach(check => {
          if (check.checkStatus === 'WITH_CANDIDATE')
            check.isPristine = true;
          else
            check.isPristine = false;
        });
        const identityVerificationCheck = this.getIdentityVerification();
        _.forEach(this.dynamicForm.checks, ( function(check) {
          if (check.checkStatus === 'FAILED'
            || check.checkStatus === 'WITH_CANDIDATE'
            || check.checkStatus === 'RETURNED'
            || (identityVerificationCheck && check.id === identityVerificationCheck.id && identityVerificationCheck.checkStatus === 'IN_PROGRESS')) {
            canSubmitScreening = false;
            return false;
          }}));
        if (this.dynamicForm.screeningStatus === 'CANDIDATE_SAVED' && canSubmitScreening)
          canSubmitScreening = false;
        this.canSubmitScreening = canSubmitScreening;
        this.loading = false;
      }

      // results[1] = screening data
      if (results[1].checksValues !== []) {
        this.dynamicFormData = results[1].checksValues;
        this.dynamicFormService.dynamicFormData = this.dynamicFormData;
      }
      if (results[1].comments !== [])
        this.dynamicFormComments = results[1].comments;
    }, err => {
      this.loading = false;
      this.serverError = true;
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
    });
  }

  ngOnDestroy() {
    this.canSubmitScreeningSub.unsubscribe();
  }

  submitScreening() {
    this.canSubmitScreening = false;
    let modalSubmitButton = document.getElementById('modalSubmitButton');
    if (!isNullOrUndefined(modalSubmitButton)) {
      this.modalSubmitButtonDisabled = true;
      const spinnerWrap = document.createElement('div');
      spinnerWrap.classList.add('spinner-wrap');
      spinnerWrap.classList.add('button');

      modalSubmitButton.innerHTML = '';
      modalSubmitButton.appendChild(spinnerWrap);
    }

    this.candidateScreeningService.submitMyScreening().subscribe(() => {
    }, err => {
      this.canSubmitScreening = true;
      if (!isNullOrUndefined(modalSubmitButton)) {
        modalSubmitButton.innerHTML = 'Submit Screening';
        this.modalSubmitButtonDisabled = false;
      }
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
    }, () => {
      this.submitted = true;
      if (!isNullOrUndefined(modalSubmitButton)) {
        this.closeModal();
        modalSubmitButton.innerHTML = 'Submit Screening';
        this.modalSubmitButtonDisabled = false;
      }
    });
  }

  submittedEvent(event) {
    if (event)
      this.submitScreening();
  }

  hideModal(): void {
    this.welcomeModal.hide();
  }

  closeModal() {
    this.modalRef.hide();
  }

  openReview() {
    window.open("https://www.trustistreviewer.com/surveyapp/qev_t45y/azuwje3x","_self");
  }

  public getIdentityVerification() {
    return this.dynamicForm && this.dynamicForm.checks && this.dynamicForm.checks.find(ch => this.isIdentityVerification(ch));
  }

  private isIdentityVerification(check): boolean {
    return check && check.groups && check.groups.length > 0 && check.groups[0].attributes && check.groups[0].attributes.find(a => a.dataType === 'IDENTITY_VERIFICATION') != null;
  }

}
