import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CandidateScreeningService } from '../../../services/candidate-screening.service';

@Component({
  selector: 'xavier-dashboard-id-verification-details',
  templateUrl: './dashboard-id-verification-details.component.html',
  styleUrls: ['./dashboard-id-verification-details.component.sass']
})
export class DashboardIdVerificationDetailsComponent implements OnInit {
  public candidateInfo;
  public addressHistory;
  public nameHistory;

  private verificationScreenData: any;
  private id: string;
  private routeSub: any;

  constructor(private activatedRoute: ActivatedRoute, private candidatesScreeeningService: CandidateScreeningService,) {
    this.routeSub = this.activatedRoute.params.subscribe((params) => {
      this.id = params["id"];
    });
  }

  ngOnInit() {
    this.verificationScreenData = this.candidatesScreeeningService
      .getIdVerificationScreenData(this.id)
      .subscribe((verificationScreenData) => {
        if (verificationScreenData) {
          this.candidateInfo = verificationScreenData.candidateInfo;
          this.addressHistory = verificationScreenData.addressHistory;
          this.nameHistory = verificationScreenData.nameHistory;
        }
      }, err => console.log(err));
  }

  ngOnDestroy() {
    this.verificationScreenData.unsubscribe();
    this.routeSub.unsubscribe();
  }
}
