import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationStart, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {FormControl} from "@angular/forms";
import {GenericTableComponent, GtExpandedRow, GtInformation} from "@angular-generic-table/core";
import {
  candidatesCompleteDashboardColumns,
  candidatesCompleteDashboardFilters, candidatesOverviewDashboardColumns,
  itemsPerPageValues, noticeToasterOptions, roleDependantCandidatesOverviewColumns
} from "../../../../data/variables.data";
import {UserAuth} from "../../../../core/userAuth.core";
import {configObject} from "./candidates-complete.data";
import {StatusIconComponent} from "../../cmps-parts/status-icon/status-icon.component";
import {ToastyService} from "ng2-toasty";
import {CandidateService} from "../../services/candidate.service";
import {CandidateScreeningService} from "../../services/candidate-screening.service";
import {TableService} from "../../services/table.service";
import {fadeIn} from "../../../../data/animations.data";
import {forkJoin} from "rxjs/observable/forkJoin";
import {addToaster, formatDate, getToasterMessagesLabel} from "../../../utils/functions.utils";
import {isNullOrUndefined} from "util";
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'xavier-candidates-complete',
  templateUrl: 'candidates-complete.component.html',
  styleUrls: ['candidates-complete.stylesheet.sass'],
  animations: [fadeIn]
})

export class CandidatesCompleteComponent implements OnInit, OnDestroy, AfterViewChecked {

  @ViewChild('rowLength', { static: true }) public rowLength: any;
  // @ViewChild('search', { static: true }) public search: any;

  public startRouteUrl: string;
  public itemsPerPageValues = itemsPerPageValues;
  public candidatesCompleteDashboardColumns = candidatesCompleteDashboardColumns;
  public candidatesColumnsParams = {
    title: "Show/Hide Columns",
    columns: null,
    columnConfig: null
  };
  public searchControl = new FormControl();
  public data: Array<any> = [];
  public candidatesCompleteDashboardFilters = candidatesCompleteDashboardFilters;
  public roleDependantCandidatesOverviewColumns = roleDependantCandidatesOverviewColumns;

  @ViewChild('candidatesCompleteTable', { static: true })
  private candidatesCompleteTable: GenericTableComponent<any, GtExpandedRow<any>>;
  private page: number = 1;
  private records: number = 10;
  private query: string = '';
  private sortBy: string = '';
  private sortOrder: string = '';
  private tableOwner: string;
  private columnConfig: any = {};
  private getCandidatesDataReq: any;
  private routeSub: any;
  private searchSub: any;
  private tableOptionsSub: any;
  private tableOwnerSub: any;
  private tableColumnsSub: any;
  private tableInfo = {
    'pageNext': null,
    'pageCurrent': 1,
    'pagePrevious': null,
    'pageTotal': 1,
    'recordFrom': 1,
    'recordLength': 1,
    'recordsAfterFilter': 1,
    'recordsAfterSearch': 1,
    'recordsAll': 1
  };

  constructor(private candidatesService: CandidateService, private candidateScreeningService: CandidateScreeningService,
              private router: Router, private activatedRoute: ActivatedRoute, private userAuth: UserAuth,
              private titleService: Title, private tableService: TableService, private toastyService: ToastyService,
              private cdr: ChangeDetectorRef) {
    this.startRouteUrl = this.router.url;

    this.routeSub = this.activatedRoute.queryParams.subscribe(params => {
      if (params instanceof NavigationStart)
        this.startRouteUrl = params.url;
    });

    this.tableOwnerSub = this.tableService.currentOwner.subscribe(owner => {
      this.tableOwner = owner;
    });

    this.tableColumnsSub = this.tableService.currentConfigColumns.subscribe(config => {
      if (config.length !== 0) {
        this.columnConfig['columns'] = config;

        // show columns that are selected in the dropdown
        _.forEach(this.columnConfig.columns, (function (column) {
          let columnElements = document.getElementsByClassName(_.kebabCase(column) + '-column');
          _.forEach(columnElements, (function (elem) {
            elem.classList.remove('visibility-none');
          }).bind(this));
        }).bind(this));

        // hide all the rest of the columns that are not selected in the dropdown
        _.forEach(candidatesCompleteDashboardColumns, (function (column) {
          if (isNullOrUndefined(this.columnConfig) || _.indexOf(this.columnConfig.columns, column.name) === -1) {
            let columnElements = document.getElementsByClassName(_.kebabCase(column.name) + '-column');
            _.forEach(columnElements, (function (elem) {
              elem.classList.add('visibility-none');
            }).bind(this));
          }
        }).bind(this));
      }
    });

    this.searchSub = this.searchControl.valueChanges
      .debounceTime(1000)
      .subscribe(newValue => {
        this.query = newValue;
        this.page = 1;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(this.page, this.candidatesCompleteTable.gtInfo.recordLength, this.query);
      });

    if (this.userAuth.getUser().role.name !== 'Admin' && this.userAuth.getUser().role.name !== 'Screener') {
      let i = this.configObject.settings.length;
      while (i--)
        if (this.roleDependantCandidatesOverviewColumns.includes(this.configObject.settings[i].objectKey))
          this.configObject.settings.splice(i, 1);
      i = this.configObject.fields.length;
      while (i--)
        if (this.roleDependantCandidatesOverviewColumns.includes(this.configObject.fields[i].objectKey))
          this.configObject.fields.splice(i, 1);
      i = this.candidatesCompleteDashboardColumns.length;
      while (i--)
        if (this.roleDependantCandidatesOverviewColumns.includes(this.candidatesCompleteDashboardColumns[i].name))
          this.candidatesCompleteDashboardColumns.splice(i, 1);
    }
    this.candidatesColumnsParams.columns = this.candidatesCompleteDashboardColumns;
  }

  ngOnInit() {
    this.titleService.setTitle('Dashboard - Xavier Eurocom CI');

    this.routeSub = this.router.events.subscribe((value) => {
      if (value instanceof NavigationStart)
        this.startRouteUrl = value.url;
    });

    this.tableOptionsSub = this.tableService.currentOptions.subscribe(options => {
      if (this.tableOwner === btoa(this.constructor.toString())) {
        this.page = options.page ? options.page : 1;
        this.records = options.records ? options.records : 10;
        this.query = options.query ? options.query : '';
        this.sortBy = options.sortBy ? options.sortBy : '';
        this.sortOrder = options.sortOrder ? options.sortOrder : '';
      } else {
        this.tableService.changeTableOwner(btoa(this.constructor.toString()));
        this.page = 1;
        this.records = 10;
        this.query = '';
        this.sortBy = '';
        this.sortOrder = '';
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
      }
    });

    this.getData(this.page, this.records, this.query, this.sortBy, this.sortOrder);

    setTimeout(() => {
      this.fixTablecolumns();
    }, 0);
  }

  private fixTablecolumns() {
    const screeningStatusColumns = document.getElementsByClassName('screening-status-column');
    screeningStatusColumns[0].classList.add('screening-status', 'sort-enable', 'no-top-border');
  }

  ngAfterViewChecked() {
    this.rowLength.nativeElement.value = this.records;
    // this.search.nativeElement.value = this.query;

    this.applyWrappers(); // wrapping the table with wrappers for the horizontal scrolling

    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.columnConfig = null;
    this.roleDependantCandidatesOverviewColumns = null;
    this.routeSub.unsubscribe();
    this.searchSub.unsubscribe();
    this.tableOptionsSub.unsubscribe();
    this.tableOwnerSub.unsubscribe();
    this.tableColumnsSub.unsubscribe();
  }

  getData = function(pageNum: number, pageSize: number, query: string, sortBy?: string, sortOrder?: string) {
    this.data = [];
    let loading: Array<any> = [];
    loading.push({ candidate: 'Loading...'} );
    this.configObject.data = loading;

    if (typeof this.getCandidatesDataReq !== 'undefined') {
      this.getCandidatesDataReq.unsubscribe();
    }

    this.info = {
      'pageNext': null,
      'pageCurrent': 1,
      'pagePrevious': null,
      'pageTotal': 1,
      'recordFrom': 1,
      'recordLength': 1,
      'recordsAfterFilter': 1,
      'recordsAfterSearch': 1,
      'recordsAll': 1
    };
    this.configObject.info = <GtInformation>this.info;
    if (isNullOrUndefined(query))
      query = '';
    if (!sortBy)
      sortBy = 'completedTime';
    if (!sortOrder)
      sortOrder = 'DESC';

    forkJoin([
      this.candidatesService.getCompletedCandidates(pageSize, pageNum, query, sortBy, sortOrder),
      this.candidatesService.getViewConfig('COMPLETED_CANDIDATES')
    ]).subscribe(results => {
      let statuses = <any>results[0];
      let config = <any>results[1];
      this.data = [];
      if (!isNullOrUndefined(statuses) && statuses.hasOwnProperty('list')) {
        // set all columns that are checks to visible false, reset
        _.forEach(this.configObject.settings, (function (check, index) {
          if (_.startsWith(check.objectKey, 'checkId')) {
            this.configObject.settings[index]['visible'] = false;
          }
        }).bind(this));
        // set all columns that are checks AND that are returned from the API call to be visible true
        _.forEach(statuses['checks'], (function (check) {
          let index = this.configObject.settings.findIndex(x => x.objectKey == 'checkId'+check.id);
          if (index > 0)
            this.configObject.settings[index]['visible'] = true;
        }).bind(this));
        // redraw the table, necessary
        this.candidatesCompleteTable.redraw();

        _.forEach(statuses['list'], ( function (candidate) {
          let status = this.getStatusProperties(candidate.screeningStatus);
          let candidateObject = {
            'id': candidate.candidateId,
            'candidate': candidate.candidate,
            'company.companyName': candidate.company,
            'division.divisionName': candidate.division,
            'screening.screeningName': candidate.screening,
            'user.activated': candidate.candidateActivated ? 'Active' : 'Inactive',
            'initiator': candidate.initiator ? candidate.initiator : '-',
            'screener': candidate.screener ? candidate.screener : '-',
            'lineManager': candidate.lineManager ? candidate.lineManager : '-',
            'humanResources': candidate.humanResources ? candidate.humanResources : '-',
            'profit': candidate.profit ? '£ ' + candidate.profit : '-',
            'assignedTime': candidate.assigned ? formatDate(candidate.assigned) : '-',
            'submittedTime': candidate.submitted ? formatDate(candidate.submitted) : '-',
            'completedTime': candidate.completed ? formatDate(candidate.completed) : '-',
            'invoicedDate': candidate.invoiceDate ? formatDate(candidate.invoiceDate) : '-',
            'priceAdjustment': candidate.additionalCost ? '£  ' + candidate.additionalCost : '-',
            'chaserEmails': candidate.chaserEmails ? candidate.chaserEmails : '-',
            'screeningStatus': new StatusIconComponent(),
            'statusValue': candidate.screeningStatus,
            'statusType': status.statusType,
            'statusColor': status.statusColor,
            'statusBorder': status.statusBorder,
            'statusFill': status.statusFill,
            'statusSize': status.statusSize
          };
          _.forEach(candidate.checkStatuses, (function (status) {
            if (status.hasOwnProperty('checkStatus')) {
              let statusIconValues = this.getStatusProperties(status.checkStatus);
              candidateObject['checkId'+status.checkId] = `<div class="filter-icon-wrapper `+status.checkStatus+`"><i class="material-icons small">`+statusIconValues.statusType+`</i></div>`;
            }
            else {
              candidateObject['checkId'+status.checkId] = ' ';
            }
          }).bind(this));
          this.data.push(candidateObject);
        }).bind(this));
        this.configObject.data = this.data;
        let pageNext = pageNum < statuses['pages'] ? pageNum + 1 : null;
        let pagePrev = pageNum === 1 ? null : pageNum - 1;
        this.tableInfo = {
          'pageNext': pageNext,
          'pageCurrent': pageNum,
          'pagePrevious': pagePrev,
          'pageTotal': statuses['pages'],
          'recordLength': pageSize,
          'recordsAfterFilter': statuses['count'],
          'recordsAfterSearch': statuses['count'],
          'recordsAll': statuses['count']
        };
        this.configObject.info = <GtInformation>this.tableInfo;
      } else if (!isNullOrUndefined(statuses) && !isNullOrUndefined(statuses['count']) && !isNullOrUndefined(statuses['pages'])) {
        this.configObject.data = [];
        this.tableInfo = {
          'pageNext': null,
          'pageCurrent': pageNum,
          'pagePrevious': null,
          'pageTotal': statuses['pages'],
          'recordFrom': statuses['count'],
          'recordLength': pageSize,
          'recordsAfterFilter': statuses['count'],
          'recordsAfterSearch': statuses['count'],
          'recordsAll': statuses['count']
        };
        this.configObject.info = <GtInformation>this.tableInfo;
      }

      if (!isNullOrUndefined(config) && config.hasOwnProperty('columns')) {
        this.columnConfig['columns'] = Object.assign({}, config.columns);
        this.tableService.changeTableHiddenColumns(config.columns);
      } else {
        let defaultConfigColumns = [
          "division",
          "screening",
          "assignedDate",
          "submittedDate",
          "completedDate",
          "invoiceDate",
          "lineManager",
          "additionalCost"
        ];
        this.columnConfig['columns'] = defaultConfigColumns;
        this.tableService.changeTableHiddenColumns(defaultConfigColumns);
      }
    }, err => {
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
    }, () => {
      setTimeout(() => {
        _.forEach(candidatesCompleteDashboardColumns, ( function(column) {
          if (isNullOrUndefined(this.columnConfig) || _.indexOf(this.columnConfig.columns, column.name) === -1) {
            let columnElements = document.getElementsByClassName(_.kebabCase(column.name)+'-column');
            _.forEach(columnElements, ( function(elem) {
              elem.classList.add('visibility-none');
            }).bind(this));
          }
        }).bind(this));
      }, 0);
    });
  };

  trigger = function($event) {
    switch($event.name) {
      case 'gt-row-select':
      case 'gt-row-deselect':
        if (this.userAuth.getUser().role.name === 'Line Manager') {
          this.candidateScreeningService.viewCertificate($event.value.changedRow.id).subscribe(result => {
            if (!isNullOrUndefined(result.size) && result.size > 0) {
              let linkElement = document.createElement("a");
              linkElement.setAttribute('id','downloadCertificateLink');
              document.body.appendChild(linkElement);
              linkElement.classList.add('visibility-none');
              let file = new Blob([result], {type: 'application/pdf'});
              let fileURL = window.URL.createObjectURL(file);
              linkElement.href = fileURL;
              linkElement.download = 'certificate.pdf';
              linkElement.click();
              window.URL.revokeObjectURL(fileURL);
              linkElement.parentNode.removeChild(linkElement);
            }
          }, err => {
            this.viewCertificateDisabled = false;
            let action = 'serverError';
            if (err.status === 403) {
              action = '403';
              let config = noticeToasterOptions;
              config.msg = getToasterMessagesLabel(action, 'errors');
              this.toastyService.error(config);
            }
            if (err.status === 409) {
              action = 'conflictCertificate';
              let config = noticeToasterOptions;
              config.msg = getToasterMessagesLabel(action, 'notices');
              this.toastyService.warning(config);
            }
          });
        }
        else
          this.router.navigate(['/dashboard/candidates-overview/screening-dashboard/' + $event.value.changedRow.id], { queryParams: { startRouteUrl: this.startRouteUrl } });
        break;
      case 'gt-row-length-changed':
        this.records = $event.value;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(this.candidatesCompleteTable.gtInfo.pageCurrent, this.records, this.query, this.sortBy, this.sortOrder);
        break;
      case 'gt-page-changed':
        this.page = $event.value.pageCurrent;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(this.page, this.candidatesCompleteTable.gtInfo.recordLength, this.query, this.sortBy, this.sortOrder);
        break;
      case 'gt-sorting-applied':
        let sortBy;
        let sortOrder = 'ASC';
        if ($event.value[0].charAt(0) === '-' && $event.value[0].charAt(0) !== '$') {
          sortOrder = 'DESC';
          sortBy = $event.value[0].slice(1, $event.value[0].length);
        } else if ($event.value[0].charAt(0) !== '$') {
          sortBy = $event.value[0];
        }
        this.sortBy = sortBy;
        this.sortOrder = sortOrder;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(
          this.candidatesCompleteTable.gtInfo.pageCurrent,
          this.candidatesCompleteTable.gtInfo.recordLength,
          this.query, this.sortBy, this.sortOrder
        );
        break;
      default:
        break;
    }
  };

  resetSearch() {
    this.query = '';
    this.tableService.changeOptions({
      page: this.page,
      records: this.records,
      query: this.query
    });
    this.searchControl.setValue('');
  }

  private applyWrappers() {
    // XA-1282 Upgrade Angular
    if (document.querySelector(".scrolling.complete")) return;

    let table = document.getElementsByTagName('table')[0];
    let wrapper = document.createElement('div');
    wrapper.classList.add('wrapper');
    let scrolling = document.createElement('div');
    scrolling.classList.add('scrolling');
    scrolling.classList.add('complete');
    table.parentNode.insertBefore(scrolling, table);
    scrolling.appendChild(table);
    scrolling.parentNode.insertBefore(wrapper, scrolling);
    wrapper.appendChild(scrolling);
  }

  getStatusProperties(value: string) {
    let res = {
      'statusType': '',
      'statusColor': '',
      'statusBorder': '#DEE0E0',
      'statusFill': '',
      'statusSize': 'small'
    };
    _.forEach(this.candidatesCompleteDashboardFilters, function(filter) {
      if (filter.name === value) {
        res.statusType = filter.icon;
        res.statusColor = filter.color;
        res.statusBorder = filter.border;
        res.statusFill = filter.fill;
        res.statusSize = filter.size;
      }
    });
    return res;
  }

  filterInfoToStatus(filter: any) {
    return {
      'statusValue': filter.value,
      'statusType': filter.icon,
      'statusColor': filter.color,
      'statusBorder': filter.border,
      'statusFill': filter.fill,
      'statusSize': filter.size,
    };
  }

  public configObject = configObject;

}
