<ng2-toasty [position]="'top-center'" class="xavier-toasty"></ng2-toasty>
<div class="row screening-form" [ngClass]="preview ? 'preview' : 'candidate'">

  <!-- Side menu container -->
  <div class="col-sm-12 col-xl-4 d-xl-block" id="dfMenu">
    <!-- Side menu -->
    <div *ngIf="sidePanel" [@slideRightFromHidden]>
      <div *ngFor="let check of formModel.checks"
           [id]="check.id"
           class="row side-menu-item"
           [ngClass]="{'active': selectedItem == check.id}"
           (click)="changeTabModal($event, check.id)">
        <div *ngIf="!preview" class="col-2 text-center">
          <i *ngIf="check.checkStatus === 'WITH_CANDIDATE'" class="material-icons with">&#xE40C;</i>
          <i *ngIf="check.checkStatus === 'CANDIDATE_SAVED'" class="material-icons saved">&#xE876;</i>
          <i *ngIf="check.checkStatus === 'FAILED'" class="material-icons saved">&#xE876;</i>
          <i *ngIf="!isIdentityVerification(check) && check.checkStatus === 'IN_PROGRESS'" class="material-icons saved">&#xE876;</i>
          <i *ngIf="isIdentityVerification(check) && check.checkStatus === 'IN_PROGRESS'" class="material-icons with">&#xE876;</i>
          <i *ngIf="check.checkStatus === 'RETURNED'" class="material-icons returned">error_outline</i>
          <i *ngIf="check.checkStatus === 'PASSED'" class="material-icons passed">&#xE876;</i>
          <i *ngIf="check.checkStatus === 'DELAYED'" class="material-icons saved">&#xE876;</i>
          <i *ngIf="check.checkStatus === 'FLAGGED'" class="material-icons saved">&#xE876;</i>
          <i *ngIf="check.checkStatus === 'ADVERSE'" class="material-icons saved">&#xE876;</i>
          <i *ngIf="check.checkStatus === 'DRAFT'" class="material-icons draft">&#xE40C;</i>
        </div>
        <div class="item" [ngClass]="!preview ? 'col-8' : 'col-10'">
          {{check.checkName}}
        </div>
        <div class="col-2 text-right">
          <i class="material-icons right-arrow">&#xE5CC;</i>
        </div>
      </div>
    </div>

    <!-- Alternative footer for mobile view -->
    <div *ngIf="!preview" class="col-sm-12 d-sm-block d-xl-none candidate-screening-footer candidate-screening-footer-adjust-mobile">
      <ul>
        <li>Copyright © <span>{{footerCopyrightDate | date:'y'}}</span> Xavier. All Rights Reserved.</li>
        <li>
          <a href="https://www.eurocomci.co.uk/legal.html">Terms of Service</a> /
          <a href="https://www.eurocomci.co.uk/privacy.html">Privacy Policy</a> /
          <a href="https://www.eurocomci.co.uk/contact.html">Help</a>
        </li>
      </ul>
    </div>

    <!-- Alternative Submit Screening button for mobile view -->
    <div *ngIf="!preview" class="col-sm-12 submit-button-container fixed-bottom d-sm-block d-xl-none">
      <button type="button" class="btn-block btn xavier-button passed" [disabled]="!canSubmitScreening" (click)="submitScreening()">
        Submit Screening
      </button>
    </div>

  </div>
  <!-- end of Side menu container -->

  <!-- Content container -->
  <div class="col-sm-12 col-xl-8 d-none d-xl-block" id="dfContent">
    <!-- Content sliding panels -->
    <ng-container *ngFor="let check of formModel.checks">
      <div *ngIf="selectedItem === check.id" class="form-panel details"
           [ngClass]="!preview ? 'candidate-screening-footer-adjust' : ''"
           [@slideLeftFromHidden]>
        <button *ngIf="!preview" (click)="changeTabModal(null, null)" type="button"
                class="btn xavier-button-plain white back-button d-lg-block d-xl-none">
          <i class="material-icons">&#xE5C4;</i>
        </button>
        <h2 [ngClass]="!preview ? 'check-header' : ''">{{check.checkName}}</h2>
        <div *ngIf="!preview && globalValidationMsg" class="global-validation-msg">
          Please correct the errors below Save the check and Submit the screening.
        </div>
        <div class="returned-comment" id="checkCommentMsg"></div>
        <div class="check-info" [innerHTML]="check.information"></div>
        <ng-container *ngFor="let group of check.groups">
          <xavier-df-group [isPristine]="check.isPristine" [group]="group" [checkId]="check.id" [preview]="preview"></xavier-df-group>
        </ng-container>
        <div *ngIf="check.footer" class="check-footer" [innerHTML]="check.footer"></div>
      </div>
    </ng-container>

    <!-- footer replacement to accomodate the sticky submit button -->
    <div *ngIf="!preview" class="fixed-bottom candidate-screening-footer">
      <div class="container">
        <div class="row">
          <div class="col-xl-4 d-none d-xl-block">
            <ul>
              <li>Copyright © <span>{{footerCopyrightDate | date:'y'}}</span> Xavier. All Rights Reserved.</li>
              <li>
                <a href="https://www.eurocomci.co.uk/legal.html">Terms of Service</a> /
                <a href="https://www.eurocomci.co.uk/privacy.html">Privacy Policy</a> /
                <a href="https://www.eurocomci.co.uk/contact.html">Help</a>
              </li>
            </ul>
          </div>
          <div class="col-sm-12 col-xl-8">
            <button type="button" class="col-sm-12 col-lg-3 btn-block btn xavier-button save-check"
                    id="submitCheck" [disabled]="submitCheckDisabled" *ngIf="!isIdentityVerificationCheck()"
                    (click)="submitActiveCheck()">
              {{saveContinueLabel}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end footer -->

  </div>
  <!-- end of Content container -->

</div>
