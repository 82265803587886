import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Location } from '@angular/common';
import {Title} from "@angular/platform-browser";
import {FormControl} from "@angular/forms";
import {evidenceCheckDashboardFilters, itemsPerPageValues, evidenceCheckDashboardBadges, errorsToasterOptions, evidenceCheckDashboardTypes} from "../../../../data/variables.data";
import {addToaster, formatDate, formatDueDate, getToasterMessagesLabel} from "../../../utils/functions.utils";
import {GenericTableComponent, GtExpandedRow} from "@angular-generic-table/core";
import { EvidenceRowData } from '../../model/evidence.interface.model';
import {TableService} from "../../services/table.service";
import {ActivatedRoute, Router} from "@angular/router";
import {UserPermission} from "../../model/permissions.model";
import {UserAuth} from "../../../../core/userAuth.core";
import {ToastyService} from 'ng2-toasty';
import {fadeIn} from "../../../../data/animations.data";
import {isNullOrUndefined} from "util";
import * as _ from 'lodash';
import { OpenAiBadgeComponent } from '../../cmps-parts/open-ai-badge/open-ai-badge.component';
import { ResistantAiBadgeComponent } from '../../cmps-parts/resistant-ai-badge/resistant-ai-badge.component';
import { MultiverseService } from '../../services/multiverse.service';

@Component({
  moduleId: module.id,
  selector: 'xavier-evidence-check',
  templateUrl: './evidence-check.component.html',
  styleUrls: ['./evidence-check.stylesheet.sass'],
  animations: [fadeIn]
})
export class EvidenceCheckComponent implements OnInit, OnDestroy, AfterViewInit  {
  @Input() displayName: string;
  @ViewChild('rowLength', { static: true }) public rowLength: any;

  public itemsPerPageValues = itemsPerPageValues;
  public searchControl = new FormControl();
  public data: Array<EvidenceRowData> = [];
  public pageName: string = 'Evidence Check';
  public evidenceCheckType;

  @ViewChild('evidenceCheckTable', { static: true })
  private evidenceCheckTable: GenericTableComponent<any, GtExpandedRow<any>>;
  private page: number = 1;
  private records: number = 10;
  private query: string = '';
  private tableOwner: string;
  private getEvidenceCheckDataReq;
  private searchSub: any;
  private tableOptionsSub: any;
  private tableOwnerSub: any;
  private toasterControls = {
    created: false
  };

  constructor(private multiverseService: MultiverseService, private router: Router, private activatedRoute: ActivatedRoute,
              private titleService: Title, private toastyService: ToastyService, private userAuth: UserAuth,
              private tableService: TableService, private cdr: ChangeDetectorRef, private location: Location) {
    this.tableOwnerSub = this.tableService.currentOwner.subscribe(owner => {
      this.tableOwner = owner;
    });

    this.searchSub = this.searchControl.valueChanges
      .debounceTime(1000)
      .subscribe(newValue => {
        this.query = newValue;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query
        });
      });
  }

  ngOnInit() {
    this.titleService.setTitle('Dashboard - Xavier Eurocom CI');

    this.tableOptionsSub = this.tableService.currentOptions.subscribe(options => {
      if (this.tableOwner === btoa(this.constructor.toString())) {
        this.page = options.page ? options.page : 1;
        this.records = options.records ? options.records : 10;
        this.query = options.query ? options.query : '';
      } else {
        this.tableService.changeTableOwner(btoa(this.constructor.toString()));
        this.page = 1;
        this.records = 10;
        this.query = '';
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query
        });
      }
    });

    this.getData(1, 10000);
  }

  ngAfterViewInit() {
    _.forEach(this.toasterControls, ( function(actionValue, action) {
      if (actionValue) {
        let config = errorsToasterOptions;
        config.msg = getToasterMessagesLabel(action, 'evidence checks');
        this.toastyService.info(config);
      }
    }).bind(this));
    if (!UserPermission.isAllowed(['COMPANY_DETAILS_ALL', 'COMPANY_DETAILS_OWN'], this.userAuth.getUser().permissionList)) {
      let tables = document.getElementsByTagName('table');
      tables[0].classList.add('tr-no-pointer');
    }

    this.evidenceCheckTable.gtInfo.recordLength = +this.records;
    this.rowLength.nativeElement.value = this.records;
    if (this.query !== '') {
      this.evidenceCheckTable.gtSearch(this.query);
    }

    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.searchSub.unsubscribe();
    this.tableOptionsSub.unsubscribe();
    this.tableOwnerSub.unsubscribe();
  }

  getData = function(pageNum, pageSize) {
    const evidenceCheck = this.location.path().split('/')[2];
    this.pageName = evidenceCheck.split('-').join(' ');
    this.data = [];
    let loading: Array<any> = [];
    loading.push({ candidateName: 'Loading...'} );
    this.configObject.data = loading;
    this.evidenceCheckType = evidenceCheckDashboardTypes.find(
      (item) => item.name === evidenceCheck
    );

    if (typeof this.getEvidenceCheckDataReq !== 'undefined') {
      this.getEvidenceCheckDataReq.unsubscribe();
    }

    this.getEvidenceCheckDataReq = this.multiverseService.getEvidenceChecks(this.evidenceCheckType.apiPath).subscribe((response) => {
      this.data = [];
      _.forEach(response.items, ( function(check) {
        let openAiBadge = this.getBadgeProperties(check.openAIStatus);
        let resistantAiBadge = this.getBadgeProperties(check.resistantAIStatus);
        let dueDate = formatDueDate(check.sla);
        let checkObject = {
          id: check.id,
          candidateName: check.candidateName,
          subject: check.subject,
          international: check.international,
          resistantAIStatus: resistantAiBadge.value,
          openAIStatus: openAiBadge.value,
          dateCreated: formatDate(check.dateCreated),
          resistantAiFill: resistantAiBadge.badgeFill,
          openAiFill: openAiBadge.badgeFill,
          sla: check.sla ? `Due ${dueDate.formattedDate} - ${dueDate.workingHoursLeft} working hour(s) left` : ''
        };
        this.data.push(checkObject);
      }).bind(this));
      this.configObject.data = this.data;
    }, err => {
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
      this.configObject.data = [];
    }, () => {
      this.evidenceCheckTable.goToPage(+this.page);
    });
  };

  trigger = function($event) {
    switch($event.name) {
      case 'gt-row-select':
      case 'gt-row-deselect':
        const allowUser = UserPermission.isAllowed(['CANDIDATE_LIST_ALL'], this.userAuth.getUser().permissionList);
        const allowStatus = $event.value.changedRow['openAIStatus'] === 'Completed' && $event.value.changedRow['resistantAIStatus'] !== 'Pending';
        if (allowStatus && allowUser)
          this.router.navigate([`/dashboard/evidence-check/apprentices/${this.evidenceCheckType.name}/${$event.value.changedRow.id}`]);
        break;
      case 'gt-row-length-changed':
        this.records = $event.value;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query
        });
        break;
      case 'gt-page-changed':
        if (this.page !== $event.value.pageCurrent && this.configObject.data.length !== 0) {
          this.page = $event.value.pageCurrent;
          this.tableService.changeOptions({
            page: this.page,
            records: this.records,
            query: this.query
          });
        }
        break;
      default:
        break;
    }
  };

  resetSearch(search: any) {
    search.value = '';
    this.query = '';
    this.tableService.changeOptions({
      page: this.page,
      records: this.records,
      query: this.query
    });
    this.evidenceCheckTable.gtSearch('');
  }

  getBadgeProperties(value: string) {
    let res = { 'badgeFill': '', 'value': '' };
    _.forEach(evidenceCheckDashboardBadges, function(badge) {
      if (badge.name === value || badge.value === value) {
        res.badgeFill = badge.fill;
        res.value = badge.value;
      }
    });
    return res;
  }

  public configObject = {
    settings: [{
      objectKey: 'id',
      visible: false
    }, {
      objectKey: 'candidateName',
      sortOrder: 0,
      columnOrder: 0,
    }, {
      objectKey: 'subject',
      sortOrder: 0,
      columnOrder: 1
    }, {
      objectKey: 'international',
      sortOrder: 0,
      columnOrder: 2
    }, {
      objectKey: 'resistantAIStatus',
      sortOrder: 0,
      columnOrder: 3
    }, {
      objectKey: 'openAIStatus',
      sortOrder: 0,
      columnOrder: 4
    },  {
      objectKey: 'dateCreated',
      sortOrder: 0,
      columnOrder: 5,
      sort: 'desc'
    }, {
      objectKey: 'sla',
      sortOrder: 0,
      columnOrder: 6
    }],
    fields: [{
      name: 'id',
      objectKey: 'id'
    }, {
      name: 'Candidate Name',
      objectKey: 'candidateName',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'Subject',
      objectKey: 'subject',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'International',
      objectKey: 'international',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      },
      render: (row) => {
        return `<div class="d-flex align-items-center" style="color: ${row.international ? '#8FBC71' : '#EA6962'}">
                  <i class="material-icons" style="font-weight: bold">${row.international ? 'done' : 'close'}</i>
                </div>`
      }
    }, {
      name: 'Resistant AI',
      objectKey: 'resistantAIStatus',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      },
      columnComponent: {
        type: ResistantAiBadgeComponent
      }
    }, {
      name: 'Open AI',
      objectKey: 'openAIStatus',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      },
      columnComponent: {
        type: OpenAiBadgeComponent
      }
    }, {
      name: 'Date created',
      objectKey: 'dateCreated',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      },
    }, {
      name: 'SLA',
      objectKey: 'sla',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      },
    }],
    data: []
  };

}
