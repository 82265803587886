import {ComponentFactoryResolver, Directive, Input, OnInit, ViewContainerRef} from '@angular/core';
import {Attribute} from '../admin/cmps/dynamic-form/model/attribute.class';
import {DashboardAttribute} from '../admin/cmps/dashboard-dynamic-form/model/dashboard-attribute.class';

@Directive({
  selector: '[df-attribute]'
})
export class DynamicFormDirective implements OnInit {

  @Input() public component: any;
  @Input() public formGroupId: string;
  @Input() public dashboard: boolean;
  @Input() public reference: boolean;
  @Input() public screeningStatus: string;
  @Input() public isScreeningCompleted: boolean;
  @Input() public dateNotSubsequent: boolean = false;
  @Input() public commentMsg: string;

  private componentRef;
  private init = false;

  constructor(
    private resolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
  ) {

  }

  ngOnInit() {
    if (!this.component || this.init)
      return;

    let type;
    if (!this.dashboard)
      type = Attribute.getTypeFor(this.component.dataType);
    else
      type = DashboardAttribute.getTypeFor(this.component.dataType);

    this.componentRef = this.viewContainerRef.createComponent(
      this.resolver.resolveComponentFactory(type)
    );
    this.init = true;

    this.componentRef.instance.data = this.component;
    this.componentRef.instance.dashboard = this.dashboard;
    this.componentRef.instance.reference = this.reference;
    this.componentRef.instance.screeningStatus = this.screeningStatus;
    this.componentRef.instance.isScreeningCompleted = this.isScreeningCompleted;
    this.componentRef.instance.formGroupId = this.formGroupId;
  }

  public ngOnDestroy(){
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }

}
