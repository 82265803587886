import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {HttpService} from '../../shared-services/http.service';
import {share, shareReplay, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/Subject';
import {YotiShareUrlDTO} from '../model/yoti/digital-id/yoti-share-url.dto';

@Injectable()
export class YotiDigitalIdService {

  private yotiShareUrlCache$: Observable<YotiShareUrlDTO>;
  private forceReload$ = new Subject<void>();

  constructor(
    private httpService: HttpService,
  ) {
  }

  public createShareUrl$(candidateId: string, createNew: boolean = false): Observable<YotiShareUrlDTO> {
    if (createNew) {
      this.forceReload();
    }

    if (!this.yotiShareUrlCache$) {
      this.yotiShareUrlCache$ = this.createShareUrl(candidateId).pipe(
        shareReplay(1),
        takeUntil(this.forceReload$)
      );
    }

    return this.yotiShareUrlCache$;
  }

  private createShareUrl(candidateId: string): Observable<YotiShareUrlDTO> {
    const params = [
      { candidateId },
    ];
    return this.httpService.post('/_ah/api/yoti/v1', 'candidateCreateShareUrl', null, params, false, false).pipe(
      share()
    );
  }

  public digitalIdCallback(token: string, candidateId: string): Observable<void> {
    const params = [
      { token, candidateId },
    ];
    return this.httpService.post('/_ah/api/yoti/v1', 'candidateDigitalIdCallback', null, params, false, false).pipe(
      share()
    );
  }

  public forceReload() {
    this.forceReload$.next();
    this.yotiShareUrlCache$ = null;
  }
}
