import { Component, Input } from "@angular/core";
import { AbstractControl } from "@angular/forms";

@Component({
  selector: "xavier-editable-text-inputbox",
  templateUrl: "./editable-text-inputbox.component.html",
  styleUrls: ["./editable-text-inputbox.stylesheet.sass"],
})
export class EditableTextInputboxComponent {
  @Input() public control: AbstractControl;
  @Input() public type: string;

  isBeenEdited: boolean = false;

  ngOnInit() {}

  enableEditing() {
    this.isBeenEdited = true;
  }

  disableEditing() {
    this.isBeenEdited = false;
  }

  isDateField(value?: string) {
    if (value) {
      const date = new Date(value);
      return !isNaN(date.getTime())
    }
    return this.type && this.type === 'date';
  }
}
