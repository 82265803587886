import {AbstractControl, ValidationErrors} from '@angular/forms';
import {FormService} from '../registration/services/form.service';
import {minimumPasswordRegex} from '../../data/variables.data';
import { of } from 'rxjs';

const emailRegEx = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const  postCodeRegEx = new RegExp(/^[a-zA-Z]{1,2}([0-9]{1,2}|[0-9][a-zA-Z])\s*[0-9][a-zA-Z]{2}$/);

export function fieldsMatcher (field1: string, field2: string) {
  return (control: AbstractControl) => {
    const field1Value = control.get(field1);
    const field2Value = control.get(field2);
    if (!field1Value || !field2Value)
      return null;
    else
      if (field1Value.value === field2Value.value)
        return null;
      else {
        field2Value.setErrors({ invalid: true });
        return { nomatch: true }
      }
  };
}

export function emailValidator (control: AbstractControl) {
  let test = emailRegEx.test(control.value);
  if (test)
    return null;
  return { email: true };
}

export function phoneValidator (control: AbstractControl) {
  let regExp = new RegExp('^([\+]?|0\d)[0-9() ]{9,}$');
  let test = regExp.test(control.value);
  if (test)
    return null;
  return { phone: true };
}

export function dobValidator (control: AbstractControl) {
  const today = new Date();
  const selected = control.value?.jsdate ? new Date(control.value.jsdate) : null;

  // Calculate the minimum and maximum birth dates
  const minBirthDate = new Date(today);
  minBirthDate.setFullYear(minBirthDate.getFullYear() - 110);

  const maxBirthDate = new Date(today);
  maxBirthDate.setFullYear(maxBirthDate.getFullYear() - 16);

  const selectedBirthDate = new Date(selected);

  if (selected && !(selectedBirthDate >= minBirthDate && selectedBirthDate <= maxBirthDate))
    return { nomatch: true }

  return null
}

export function onlyPastDobValidator (formData: any) {
  let userDob = formData && formData.find(data => data.nodeId === "1-1-10").value;
  return (control: AbstractControl): ValidationErrors | null => {
    const selected = control.value?.jsdate ? new Date(control.value.jsdate) : null;
    if (!selected) {
      return null;
    }
    let selectedBirthDate = new Date(selected);
    let userBirthDate = new Date(userDob);

    return selectedBirthDate <= userBirthDate || !userDob ? { nomatch: true } : null;
  }
}

export function candidateDobValidator (date: any) {
  return (control: AbstractControl): ValidationErrors | null => {
    const selected = control.value;
    if (!selected || !date) {
      return null;
    }
    let selectedBirthDate = new Date(selected).getTime();
    let userBirthDate = new Date(date).getTime();

    return selectedBirthDate !== userBirthDate ? { nomatch: true } : null;
  }
}

export function issueDateValidator (date: any) {
  return (control: AbstractControl): ValidationErrors | null => {
    const selected = control.value;
    if (!selected || !date) {
      return null;
    }
    const selectedDate = new Date(selected);
    const userBirthDate = new Date(date);
    const oneYearAfterBirth = new Date(userBirthDate);
    oneYearAfterBirth.setFullYear(userBirthDate.getFullYear() + 1);

    return selectedDate >= userBirthDate && selectedDate <= oneYearAfterBirth  ? null : { nomatch: true };
  }
}

export function attributeRegexValidator (regEx: string, formData?: any) {
  let attachedControl;
  if (formData) {
    formData.forEach(e => {
      if (e.data.attributeName && e.data.attributeName === 'Country') {
        attachedControl = e.formControl;
      }
    });
  }
  let regExp = new RegExp(regEx);
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }
    if (attachedControl && attachedControl.value !== "United Kingdom")
      return null;
    return regExp.test(value) ? null : { nomatch: true }
  }
}

export function passwordValidator (control: AbstractControl) {
  let test = minimumPasswordRegex.test(control.value);
  if (test)
    return null;
  return { password: true };
}

export function ukPostcodeValidator (control: AbstractControl) {
  let test = postCodeRegEx.test(control.value);
  if (test)
    return null;
  return { postcode: true };
}

export function drivingLicenceValidator(data: any) {
  return (control: AbstractControl): ValidationErrors | null => {
    const licenseNumber = control.value;
    if (!licenseNumber || licenseNumber.length < 16) {
      return null;
    }

    const isValid = checkSurnames(licenseNumber, data) && checkDobAndGender(licenseNumber, data) && checkFirstName(licenseNumber, data) && checkMiddleName(licenseNumber, data);

    return isValid ? null : { nomatch: true };
  }
}

const checkSurnames = (lice, data) =>  {
  const licenceName = lice.substring(0, 5);
  let surname = data.surname.toUpperCase().replace("'", "");
  surname = surname.startsWith("MAC") ? `MC${surname.slice(3)}` : surname;
  if (surname.length < 5)
    surname = surname.padEnd(5, "9");
  return licenceName === surname.substring(0,5);
}

const checkDobAndGender = (lice, data) => {
  const dateOfBirth = data.dateOfBirth;
  const licenceYearOfBirth = `${lice[5]}${lice[10]}`;
  const licenceMonthOfBirth = lice[6] < '5' ? `${lice[6]}${lice[7]}` : (parseInt(lice[6]) - 5) + lice[7];
  const licenceDayOfBirth = `${lice[8]}${lice[9]}`;
  const gender = data.gender.toLowerCase();

  return licenceYearOfBirth === dateOfBirth.substring(2, 4) && licenceMonthOfBirth === dateOfBirth.substring(5, 7) && licenceDayOfBirth === dateOfBirth.substring(8, 10) &&
           (gender === 'male' && lice[6] < '5' || gender === 'female' && lice[6] >= '5');

}

const checkFirstName = (lice, data) => {
  const licenceFirstName = lice[11];
  return licenceFirstName.toLowerCase() === data.firstName[0].toLowerCase();
}

const checkMiddleName = (lice, data) => {
  const licenceMiddleName = lice[12];
  if (data.middleName.length > 0)
    return licenceMiddleName.toLowerCase() === data.middleName[0].toLowerCase();
  else
    return licenceMiddleName === '9';
}

export class ValidateEmailExists {
  static createValidator(registrationService: FormService) {
    return (control: AbstractControl) => {
      return registrationService.emailExists(control.value).map(res => {
        if (!res.value)
          return null;
        return { emailExists: true };
      });
    };
  }
}
