<div class="xavier-preview-check-wrapper container" [@fadeIn]>
  <div *ngIf="loading" class="xavier-center-page" [@fadeIn]>
    <div class="spinner-container not-button"><div class="spinner-wrap"></div></div>
  </div>
  <div *ngIf="!loading" [@fadeIn]>
    <div class="row header">
      <div class="col-11">
        <h1>Preview Check</h1>
      </div>
      <div class="col-1 text-right">
        <button type="button" class="close close-preview-check" aria-label="Close" (click)="closePreviewCheck()">
          <i class="material-icons">close</i>
        </button>
      </div>
    </div>
    <div class="row">
      <div *ngIf="group" class="col-12">
        <h2>{{group.checkName}}</h2>
        <div [innerHTML]="group.information"></div>
      </div>
      <div *ngIf="group" class="col-12">
        <xavier-df-group [group]="group" [checkId]="checkId" [preview]="preview" [isCheck]="true"></xavier-df-group>
      </div>
    </div>
  </div>
</div>
