import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {ErrorMessageComponent} from '../shared-cmps/error-message/error-message.component';
import {TranslateActivityPipe} from '../utils/pipes/eventlog-activity-type.pipe';
import {AddressLookupPipe} from '../utils/pipes/address-lookup.pipe';
import {ValidateOnBlurDirective} from '../directives/validate-onblur.directive';
import {DatePipe} from '@angular/common';
import { AutoexpandTextareaComponent } from '../shared-cmps/autoexpand-textarea/autoexpand-textarea.component';
import {SafePipe} from '../utils/pipes/safe.pipe';
import {RadioComponent} from '../shared-cmps/radio/radio.component';
import {RadioGroupComponent} from '../shared-cmps/radio/radio-group/radio-group.component';
import {FormsModule} from '@angular/forms';
import { DropdownComponent } from '../shared-cmps/dropdown/dropdown.component';
import { DebugFormComponent } from '../shared-cmps/debug-form/debug-form.component';
import { SpinnerComponent } from '../shared-cmps/spinner/spinner.component';
import { CheckFixFieldComponent } from '../../app/check-fix-field/check-fix-field.component';
import { ButtonComponent } from '../shared-cmps/button/button.component';

const exported = [
  AutoexpandTextareaComponent,
  RadioComponent,
  RadioGroupComponent,
  DropdownComponent,
  DebugFormComponent,
  SpinnerComponent,
  CheckFixFieldComponent,
  ButtonComponent,
];

@NgModule({
  imports: [
    BrowserModule,
    FormsModule
  ],
  declarations: [
    ErrorMessageComponent,
    AddressLookupPipe,
    TranslateActivityPipe,
    SafePipe,
    ValidateOnBlurDirective,
    ...exported,
  ],
  exports: [
    ErrorMessageComponent,
    AddressLookupPipe,
    TranslateActivityPipe,
    SafePipe,
    ValidateOnBlurDirective,
    ...exported,
  ],
  providers: [
    DatePipe,
  ]
})
export class SharedModule {}
