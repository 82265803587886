<div [formGroup]="form">
  <div class="container">
    <ng-container *ngIf="mode === 'COMPLETED'">
      <div class="row">
        <div class="col-12 heading-wrapper">
          <h3>Verify Your Identity</h3>
        </div>
      </div>
      <div class="row message">
        <div class="col-12 success">
          <span class="material-icons">check_circle</span> This step has been completed. Please continue with the next steps of your application.
        </div>
      </div>
    </ng-container>

    <!-- <div class="row" *ngIf="mode !== 'COMPLETED' && mode !== 'IDENTITY_VERIFICATION_ONLY'">
      <div class="form-group xavier-input-text">
        <label for="hasPassport">Is your current valid passport or driving license issued in the UK or Ireland?</label>
        <app-radio-group name="has-passport" id="hasPassport">
          <app-radio formControlName="mode" [value]="'DIGITAL_ID'" label="Yes"></app-radio>
          <app-radio formControlName="mode" [value]="'IDENTITY_VERIFICATION'" label="No"></app-radio>
        </app-radio-group>
      </div>
    </div> -->
  </div>

  <!-- Yoti iFrame -->
  <!-- <xavier-yoti-identity-verification *ngIf="form.get('mode').value === 'IDENTITY_VERIFICATION' || mode === 'IDENTITY_VERIFICATION_ONLY'" (backToMenu)="onBackToMenuFromIdentityVerification()" [buttonText]="YOTI_BUTTON_TEXT" [identityVerificationOnly]="mode === 'IDENTITY_VERIFICATION_ONLY'"></xavier-yoti-identity-verification> -->

  <!-- Yoti App modal window -->
  <xavier-yoti-digital-id [buttonText]="YOTI_BUTTON_TEXT" (backToMenu)="onBackToMenuFromDigitalId()"></xavier-yoti-digital-id>
</div>
