import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GenericTableComponent, GtExpandedRow} from "@angular-generic-table/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {FormControl} from "@angular/forms";
import {ToastyService} from 'ng2-toasty';
import {UserPermission} from "../../model/permissions.model";
import {UserAuth} from "../../../../core/userAuth.core";
import {itemsPerPageValues} from "../../../../data/variables.data";
import {ScreeningService} from "../../services/screening.service";
import {TableService} from "../../services/table.service";
import {fadeIn} from "../../../../data/animations.data";
import {addToaster} from "../../../utils/functions.utils";
import {isNullOrUndefined} from "util";
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'xavier-checks',
  templateUrl: 'checks.component.html',
  styleUrls: ['checks.stylesheet.sass'],
  animations: [fadeIn]
})

export class ChecksComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() displayName: string;
  @ViewChild('rowLength', { static: true }) public rowLength: any;
  // @ViewChild('search', { static: true }) public search: any;

  public itemsPerPageValues = itemsPerPageValues;
  public data: any = [];
  public searchControl = new FormControl();

  @ViewChild('checksTable', { static: true })
  private checksTable: GenericTableComponent<any, GtExpandedRow<any>>;
  private page: number = 1;
  private records: number = 10;
  private query: string = '';
  private tableOwner: string;
  private getChecksDataReq;
  private searchSub: any;
  private tableOptionsSub: any;
  private tableOwnerSub: any;

  constructor(private screeningService: ScreeningService, private router: Router, private activatedRoute: ActivatedRoute,
              private titleService: Title, private toastyService: ToastyService, private userAuth: UserAuth,
              private tableService: TableService, private cdr: ChangeDetectorRef) {
    this.tableOwnerSub = this.tableService.currentOwner.subscribe(owner => {
      this.tableOwner = owner;
    });

    this.searchSub = this.searchControl.valueChanges
      .debounceTime(1000)
      .subscribe(newValue => {
        this.query = newValue;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query
        });
      });
  }

  ngOnInit() {
    this.titleService.setTitle('Dashboard - Xavier Eurocom CI');

    this.tableOptionsSub = this.tableService.currentOptions.subscribe(options => {
      if (this.tableOwner === btoa(this.constructor.toString())) {
        this.page = options.page ? options.page : 1;
        this.records = options.records ? options.records : 10;
        this.query = options.query ? options.query : '';
      } else {
        this.tableService.changeTableOwner(btoa(this.constructor.toString()));
        this.page = 1;
        this.records = 10;
        this.query = '';
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query
        });
      }
    });

    this.getData();
  }

  ngAfterViewInit() {
    if (!UserPermission.isAllowed(['CHECK_FORM_PREVIEW'], this.userAuth.getUser().permissionList)) {
      let tables = document.getElementsByTagName('table');
      tables[0].classList.add('tr-no-pointer');
    }

    this.checksTable.goToPage(+this.page);
    this.checksTable.gtInfo.recordLength = +this.records;
    this.rowLength.nativeElement.value = this.records;
    if (this.query !== '') {
      this.checksTable.gtSearch(this.query);
      // this.search.nativeElement.value = this.query;
    }

    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.getChecksDataReq.unsubscribe();
    this.searchSub.unsubscribe();
    this.tableOptionsSub.unsubscribe();
    this.tableOwnerSub.unsubscribe();
  }

  getData = function() {
    if (typeof this.getChecksDataReq !== 'undefined') {
      this.getChecksDataReq.unsubscribe();
    }

    this.getChecksDataReq = this.screeningService.getAllChecksOverview().subscribe(response => {
      if (!isNullOrUndefined(response.items)) {
        this.data = [];
        _.forEach(response.items, (function (check) {
          let checkObject = {
            'id': check.id,
            'check_name': check.name,
            'price': check.price !== undefined ? '£' + check.price : '-',
            'cost': check.cost !== undefined ? '£' + check.cost : '-',
            'information': check.information !== undefined ? check.information : '-',
            'groups': check.groups !== undefined ? check.groups : '-'
          };
          this.data.push(checkObject);
        }).bind(this));
        this.configObject.data = this.data;
      }
    }, err => {
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
      this.configObject.data = [];
    });
  };

  trigger = function($event) {
    switch($event.name) {
      case 'gt-row-select':
      case 'gt-row-deselect':
        if (UserPermission.isAllowed(['CHECK_FORM_PREVIEW'], this.userAuth.getUser().permissionList))
          this.router.navigate(['/dashboard/checks/preview/' + $event.value.changedRow.id]);
        break;
      case 'gt-row-length-changed':
        this.records = $event.value;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query
        });
        break;
      case 'gt-page-changed':
        this.page = $event.value.pageCurrent;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query
        });
        break;
      default:
        break;
    }
  };

  resetSearch(search: any) {
    search.value = '';
    this.query = '';
    this.tableService.changeOptions({
      page: this.page,
      records: this.records,
      query: this.query
    });
    this.checksTable.gtSearch('');
  }

  public configObject = {
    settings: [{
      objectKey: 'id',
      visible: false
    }, {
      objectKey: 'check_name',
      sortOrder: 0,
      columnOrder: 0,
      sort: 'asc'
    }, {
      objectKey: 'information',
      sortOrder: 0,
      columnOrder: 1
    }, {
      objectKey: 'groups',
      sortOrder: 0,
      columnOrder: 2
    }, {
      objectKey: 'price',
      sortOrder: 0,
      columnOrder: 3
    }, {
      objectKey: 'cost',
      sortOrder: 0,
      columnOrder: 4,
      visible: UserPermission.isAllowed(['CHECK_COST'], this.userAuth.getUser().permissionList)
    }],
    fields: [{
      name: 'id',
      objectKey: 'id'
    }, {
      name: 'Check Name',
      objectKey: 'check_name',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'Information',
      objectKey: 'information',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'Groups',
      objectKey: 'groups',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'Price',
      objectKey: 'price',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border text-right';
        else
          return 'text-right'
      }
    }, {
      name: 'Cost',
      objectKey: 'cost',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border text-right';
        else
          return 'text-right'
      }
    }],
    data: []
  };
}
