import {EventEmitter, Injectable} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Subject} from "rxjs/Subject";
import {CONFIRMATION_TYPE, DF_VALIDATION_FN, EMAIL_TYPE, TEL_TYPE} from "../../../data/variables.data";
import {IMyDateModel} from "mydatepicker";
import {attributeRegexValidator, dobValidator, emailValidator, onlyPastDobValidator, phoneValidator} from "../../utils/form.validators";
import {isNullOrUndefined} from "util";
import * as _ from 'lodash';
import {YotiInfoSessionProgressDTO} from '../model/yoti/identity-verification/yoti-info-session-progress.dto';

@Injectable()

export class DynamicFormService {

  public canSubmitScreeningSource = new Subject<boolean>();
  canSubmitScreening$ = this.canSubmitScreeningSource.asObservable();
  public canCompleteScreeningSource = new Subject<boolean>();
  canCompleteScreening$ = this.canCompleteScreeningSource.asObservable();
  public checksCompletedSource = new Subject<boolean>();
  checksCompleted$ = this.checksCompletedSource.asObservable();
  public globalValidationMsgSource = new Subject<boolean>();
  globalValidationMsg$ = this.globalValidationMsgSource.asObservable();
  private selectedPanel = new Subject<string>();
  selectedPanel$ = this.selectedPanel.asObservable();
  public screeningStatusSource = new Subject<string>();
  screeningStatus$ = this.screeningStatusSource.asObservable();
  public showToolbarSource = new Subject<string>();
  showToolbar$ = this.showToolbarSource.asObservable();
  public toolbarModalSource = new Subject<boolean>();
  toolbarModal$ = this.toolbarModalSource.asObservable();
  public confirmDeleteModalSource = new Subject<boolean>();
  confirmDeleteModal$ = this.confirmDeleteModalSource.asObservable();
  public identityVerificationCompleted = new Subject<YotiInfoSessionProgressDTO>();
  public identityVerificationRestarted = new Subject<void>();
  public identityVerificationBackToMenu = new Subject<void>();

  private dynamicFormGroups = new Map<string, FormGroup>();
  private dfGroupInstances = new Map<string, any>();
  private dfAttributeInstances = new Map<string, any>();
  private dateConditionValues = new Map<string, any>();
  public dynamicFormData: any;
  public dynamicForm: any;

  constructor() {

  }

  // DateConditionValues - begin
  public setDateConditionValue(formGroupId: string, dateStartId: string, dateStartValue: IMyDateModel,
                               dateEndId: string, dateEndValue: IMyDateModel, range: number) {
    // console.log("setDateConditionValue: ", [...arguments]);
    let value = {
      'dateStartId': dateStartId,
      'dateStartValue': dateStartValue,
      'dateEndId': dateEndId,
      'dateEndValue': dateEndValue,
      'range': range
    };

    this.dateConditionValues.set(formGroupId, value);
  }

  public getAllDateConditionValues() {
    return this.dateConditionValues;
  }

  public removeDateConditionKey(formGroupId) {
    if (Array.from(this.dateConditionValues.keys()).includes(formGroupId))
      this.dateConditionValues.delete(formGroupId);
  }

  public removeRepeatedDateConditionValues(repeatedFormGroupId) {
    this.dateConditionValues.forEach((value, key) => {
      if (_.endsWith(key, repeatedFormGroupId))
        this.dateConditionValues.delete(key);
    });
  }

  public removeAllDateConditionValues () {
    this.dateConditionValues.clear();
  }
  // DateConditionValues - end

  // GroupComponent - begin
  public setGroupComponentInstance(formGroupId: string, groupInstance: any) {
    this.dfGroupInstances.set(formGroupId, groupInstance);
  }

  public getGroupComponentInstance(formGroupId) {
    return this.dfGroupInstances.get(formGroupId);
  }

  public getAllGroupComponentsInstance() {
    return this.dfGroupInstances;
  }

  public removeGroupComponentInstance(formGroupId) {
    this.dfGroupInstances.delete(formGroupId);
  }

  public removeRepeatedGroupComponentsInstances(repeatedFormGroupId) {
    this.dfGroupInstances.forEach((value, key) => {
      if (_.endsWith(key, repeatedFormGroupId))
        this.dfGroupInstances.delete(key);
    });
  }
  // GroupComponent - end

  // AttributeComponent - begin
  public setAttributeComponentInstance(attributeId: string, attributeInstance: any) {
    // console.log('Setting: ' + attributeId);
    this.dfAttributeInstances.set(attributeId, attributeInstance);
  }

  public getAttributeComponentInstance(attributeId) {
    return this.dfAttributeInstances.get(attributeId);
  }

  public getAllAttributeComponentsInstance() {
    return this.dfAttributeInstances;
  }

  public removeAttributeComponentInstance(attributeId) {
    // console.log('Removing: ' + attributeId);
    this.dfAttributeInstances.delete(attributeId);
  }

  public removeRepeatedAttributeComponentsInstances(repeatedAttributeId) {
    this.dfAttributeInstances.forEach((value, key) => {
      if (_.endsWith(key, repeatedAttributeId))
        this.dfAttributeInstances.delete(key);
    });
  }
  // AttributeComponent - end

  // FormGroup - begin
  public setFormGroup(formGroupId: string, formGroup: FormGroup) {
    this.dynamicFormGroups.set(formGroupId, formGroup);
  }

  public getFormGroup(formGroupId) {
    return this.dynamicFormGroups.get(formGroupId);
  }

  public getAllFormGroups() {
    return this.dynamicFormGroups;
  }

  public removeFormGroup(formGroupId) {
    this.dynamicFormGroups.delete(formGroupId);
  }

  public removeRepeatedFormGroups(repeatedFormGroupId) {
    this.dynamicFormGroups.forEach((value, key) => {
      if (_.endsWith(key, repeatedFormGroupId))
        this.dynamicFormGroups.delete(key);
    });
  }

  public getFormGroupRepeatedIndex(formGroupId) {
    let index = 1;
    let max = 1;

    this.dynamicFormGroups.forEach((value, key) => {
      if (_.endsWith(key, '#' + formGroupId)) {
        index = parseInt(key.split('#')[0]) + 1;
        if (index > max)
          max = index;
      }
    });
    return max;
  }

  public countFormGroupRepeatedElements(formGroupId, level?) {
    let formElement = document.getElementById(formGroupId);
    if (isNullOrUndefined(level))
      level = this.getGroupComponentInstance(formGroupId).group.level;
    if (!isNullOrUndefined(formElement)) {
      if (level === 1) {
        let formGroups = formElement.parentElement.parentElement.querySelectorAll('xavier-df-group > div:not(.panel-noshadow)');
        if (formGroups.length >= 1 && formGroups[0].id.indexOf('#') === -1)
          return formGroups.length - 1;
        else
          return formGroups.length;
      } else {
        return formElement.parentElement.parentElement.querySelectorAll('xavier-df-group > div').length;
      }
    }
  }

  public deleteGroupDisabled(formGroupId, level?) {
    let formElement = document.getElementById(formGroupId);
    if (isNullOrUndefined(level))
      level = this.getGroupComponentInstance(formGroupId).group.level;
    if (!isNullOrUndefined(formElement)) {
      if (level === 1) {
        let formGroups = formElement.parentElement.parentElement.querySelectorAll('xavier-df-group > div:not(.panel-noshadow)');
        if (!isNullOrUndefined(formGroups[0]))
          return formGroupId !== formGroups[0].id;
      } else {
        let formGroups = formElement.parentElement.parentElement.querySelectorAll('xavier-df-group > div.panel-noshadow');
        if (!isNullOrUndefined(formGroups[0]))
          return formGroupId !== formGroups[0].id;
      }
    }
  }

  public isEmptyFormGroup(formGroup) {
    let formGroupIsEmpty = true;
    _.forEach(formGroup.getRawValue(), function(value, key) {
      if (!isNullOrUndefined(value) && value !== '')
        formGroupIsEmpty = false;
    });

    return formGroupIsEmpty;
  }
  // FormGroup - end

  public attributeToFormControl(attribute: any) {
    let validators = [];
    let asyncValidators = [];


    if (!isNullOrUndefined(attribute) && attribute.required)
      validators.push(Validators.required);
    if (!isNullOrUndefined(attribute) && attribute.dataType === CONFIRMATION_TYPE)
      validators.push(Validators.required);
    if (!isNullOrUndefined(attribute) && attribute.dataType === EMAIL_TYPE)
      validators.push(emailValidator);
    if (!isNullOrUndefined(attribute) && attribute.dataType === TEL_TYPE)
      validators.push(phoneValidator);
    if (!isNullOrUndefined(attribute) && attribute.regEx) {
      if (attribute.attributeName === 'Post Code') {
        const allAttributesInsteance = this.getAllAttributeComponentsInstance();
        validators.push(attributeRegexValidator(attribute.regEx, allAttributesInsteance));
      } else {
        validators.push(attributeRegexValidator(attribute.regEx));
      }
    }
    if (!isNullOrUndefined(attribute) && attribute.validation === DF_VALIDATION_FN.DOB)
      validators.push(dobValidator);
    if (!isNullOrUndefined(attribute) && attribute.validation === DF_VALIDATION_FN.ONLY_PAST_AND_AFTER_DOB)
      validators.push(onlyPastDobValidator(this.dynamicFormData))


    const control = new FormControl('', validators, asyncValidators);

    return control;
  }

  public canSubmitScreeningNotification(value: boolean) {
    this.canSubmitScreeningSource.next(value);
  }

  public canCompleteScreeningNotification(value: boolean) {
    this.canCompleteScreeningSource.next(value);
  }

  public checksCompletedNotification(value: boolean) {
    this.checksCompletedSource.next(value);
  }

  public globalValidationMsgNotification(value: boolean) {
    this.globalValidationMsgSource.next(value);
  }

  public selectPanel(panel: string) {
    return this.selectedPanel.next(panel);
  }

  public screeningStatus(status: string) {
    return this.screeningStatusSource.next(status);
  }

  public showToolbar(elementId: string) {
    return this.showToolbarSource.next(elementId);
  }

  public toolbarModalNotification(modalData: any) {
    this.toolbarModalSource.next(modalData);
  }

  public confirmDeleteModalNotification(modalData: any) {
    this.confirmDeleteModalSource.next(modalData);
  }

}
