<ng2-toasty [position]="'top-center'" class="xavier-toasty"></ng2-toasty>
<div class="xavier-steps-wrapper container">
  <div class="d-flex flex-column justify-content-center align-items-center">
    
    <div class="col-xs-12 col-md-8 col-lg-5 d-flex justify-content-center flex-column">

      <div class="xavier-steps header">Verify it's you</div>
      <div class="flex-wrap">Please enter the 6 digit code we have sent to <span *ngIf="userAuth.phoneNumber$ | async as phone">{{ phone.replace(';', '') }}</span>. This will expire in 10 mins.</div>


      <div class="pt-4 w-100 d-flex align-items-center">
        <xavier-code-input (codeEnter)="handleCodeInput($event)" [disabled]="isDisabled" [requesting]="isDisabled"></xavier-code-input>
      </div>

      <div class="mt-4 d-flex justify-content-center color-grey">Didn’t get a code? Please wait 30 seconds before requesting a new code. </div>
      <div class="mt-4 d-flex justify-content-center color-def"><span class="cursor-pointer" [ngClass]="pressed" (click)="requestNewCode($event)">Request new code</span></div>
      <div *ngIf="isRequesting" class="mt-4">
        <ng-container *ngTemplateOutlet="loading"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
    <div class="spinner-container not-button"><div class="spinner-wrap"></div></div>
</ng-template>
