import {Component, OnDestroy, OnInit} from '@angular/core';
import {ToastyService} from 'ng2-toasty';
import {ActivatedRoute, Router} from '@angular/router';
import {addToasterWithMessage} from '../../../utils/functions.utils';
import {YotiDigitalIdService} from '../../../admin/services/yoti-digital-id.service';
import {UserAuth} from '../../../../core/userAuth.core';


@Component({
  moduleId: module.id,
  selector: 'xavier-yoti-digital-id-callback',
  templateUrl: 'yoti-digital-id-callback.component.html',
  styleUrls: ['yoti-digital-id-callback.stylesheet.sass'],
})
export class YotiDigitalIdCallbackComponent implements OnInit, OnDestroy {

  public message = 'Processing ID verification. Please don\'t close the window, you\'ll be redirected to your screening shortly...';

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastyService: ToastyService,
    private digitalIdService: YotiDigitalIdService,
    private userAuth: UserAuth,
  ) {
  }

  ngOnInit(): void {
    console.log(window.location.href);
    this.processAndRedirectToScreening();
  }

  // public get yotiShareUrl$(): Observable<YotiShareUrlDTO> {
  //   return this.yotiDigitalIdService.createShareUrl$(this.userAuth.getUser().id);
  // }

  public ngOnDestroy(): void {
  }

  public get token(): string {
    return this.activatedRoute.snapshot.queryParams['token'];
  }

  public get candidateId(): string {
    return this.activatedRoute.snapshot.queryParams['candidateId'] || this.userAuth.getUser().candidateId;
  }

  private processAndRedirectToScreening(): void {
    if (this.areMissingQueryParams()) {
      console.log('Missing params');
      console.log('Token ' + this.token);
      console.log('Candidate ID ' + this.candidateId);
      console.log('Candidate ID logged in user ' + this.userAuth.getUser().candidateId);
      this.printError();
      this.goToCandidateScreening();
    } else {
      console.log('Calling callback ');
      console.log('Token ' + this.token);
      console.log('Candidate ID ' + this.candidateId);
      this.digitalIdService.digitalIdCallback(this.token, this.candidateId).subscribe(() => {
        console.log('Calling callback success');
        this.printSuccess();
        this.goToCandidateScreening();
      }, error => {
        console.log('Calling callback error');
        this.printError();
        this.goToCandidateScreening();
      });
    }
  }

  private printError() {
    window.setTimeout(() => {
      addToasterWithMessage('error', this.toastyService, 'Failed to process ID verification.');
    }, 100);
  }

  private printSuccess() {
    window.setTimeout(() => {
      addToasterWithMessage('success', this.toastyService, 'ID verification processed.');
    }, 100);
  }

  private goToCandidateScreening() {
    window.setTimeout(() => {
      this.message = 'Redirecting...';
    }, 2000);
    window.setTimeout(() => {
      this.router.navigate(['/dashboard/candidate-screening']);
    }, 4000);
  }

  private areMissingQueryParams(): boolean {
    return !this.token || !this.candidateId;
  }
}
