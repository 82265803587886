import {ActivatedRouteSnapshot, CanActivate, Router} from "@angular/router";
import {UserAuth} from "../../core/userAuth.core";
import {Injectable} from "@angular/core";
import {User} from "./model/user.model";
import {isNullOrUndefined} from "util";
import { MultiFactorStatus } from "../registration/model/two-factor-auth.model";

@Injectable()
export class CanAccessGuard implements CanActivate {

  constructor(private userAuth: UserAuth, private router: Router) {

  }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean>|boolean {
    const user = this.userAuth.getUser();

    if (route.data['permissions']) {
      if (!this.userAuth.isAuthorisedAccess(route.data['permissions'])) {
        if (!isNullOrUndefined(user)) {
          if (user.role.name === 'Candidate')
            this.router.navigate(['/dashboard/candidate-screening']);
          else
            this.router.navigate(['/dashboard/candidates-overview']);
        } else {
          this.router.navigate(['/login']);
          return false;
        }
      }
    }

    if (user == null) {
      return this.userAuth.checkLoggedIn().toPromise().then(u => {
        const appUser = new User(u.id, u.firstName, u.lastName, u.fullName,
          u.lastLogin, u.company.id, u.company.companyName, u.role, u.candidateId, u.permissions, u.email, u.company.saasClient, u.dbsCountersignatory);
        this.userAuth.setUser(appUser);

        if (u.multiFactorStatus === MultiFactorStatus.SETUP_REQUIRED) {
          this.router.navigate(['/two-factor/1']);
          return false;
        } else if (u.multiFactorStatus === MultiFactorStatus.OTP_SENT) {
          this.router.navigate(['/two-factor/otp-code']);
          return false;
        }

        return true;
      }).catch(() => {
        this.router.navigate(['/login']);
        return false;
      });
    }

    return true;
  }

}
