import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserAuth} from '../../../../../../../core/userAuth.core';
import {YotiIdentityVerificationService} from '../../../../../services/yoti-identity-verification.service';
import {YotiSessionDTO} from '../../../../../model/yoti/identity-verification/yoti-session.dto';
import {delay, first} from 'rxjs/operators';
import {DynamicFormService} from '../../../../../services/dynamic-form.service';
import {fromEvent} from 'rxjs/observable/fromEvent';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../../../../../../environments/environment';

// time to wait till start listening to yoti iframe messages (initial messages when iframe is being initialised must be ignored, as they would wrongly try to complete session)
const LISTEN_TO_YOTI_IFRAME_MESSAGES_TIMEOUT = 10000;

// time to wait till first asked for info about session progress
const YOTI_INFO_SESSION_PROGRESS_TIMEOUT = 4000;

// time to wait till asked for info about session progress again
const YOTI_INFO_SESSION_PROGRESS_DELAY = 1500;

// yoti origin of iframe messages
const YOTI_ORIGIN = 'https://api.yoti.com';

@Component({
  moduleId: module.id,
  selector: 'xavier-yoti-identity-verification',
  templateUrl: 'yoti-identity-verification.component.html',
  styleUrls: ['yoti-identity-verification.stylesheet.sass']
})
export class YotiIdentityVerificationComponent implements OnInit {
  @Input()
  public identityVerificationOnly: boolean;
  @Input()
  public buttonText: string;
  @Output()
  public backToMenu: EventEmitter<boolean> = new EventEmitter();

  public yotiSession: YotiSessionDTO;
  public startingAgain = false;
  private yotiMessagesActive = false;
  public processing = false;
  public showModal = false;

  constructor(
    private userAuth: UserAuth,
    private yotiService: YotiIdentityVerificationService,
    private dynamicFormService: DynamicFormService,
  ) {
  }

  private startSubscribingToYotiIframeMessages() {
    window.setTimeout(() => {
      fromEvent<MessageEvent>(window, 'message').pipe(
        first(event => event.origin === YOTI_ORIGIN)
      ).subscribe(event => {
        this.processing = true;
        window.setTimeout(() => {
          this.processYotiMessage(event);
        }, YOTI_INFO_SESSION_PROGRESS_TIMEOUT);
      });
    }, LISTEN_TO_YOTI_IFRAME_MESSAGES_TIMEOUT);
  }

  public async ngOnInit(): Promise<void> {
    this.yotiSession = await this.yotiSession$.toPromise();
    this.startSubscribingToYotiIframeMessages();
  }

  private get yotiSession$(): Observable<YotiSessionDTO> {
    return this.yotiService.getSession$(this.userAuth.getUser().id);
  }

  public async startAgain(): Promise<void> {
    this.yotiMessagesActive = false;
    this.startingAgain = true;
    try {
      this.yotiSession = await this.yotiService.getSession$(this.userAuth.getUser().id, true).toPromise();
      this.dynamicFormService.identityVerificationRestarted.next();
      this.startSubscribingToYotiIframeMessages();
    } finally {
      this.startingAgain = false;
    }
  }

  public get url() {
    return `${environment.yotiIdentityVerificationUrl}?sessionID=${this.yotiSession.sessionId}&sessionToken=${this.yotiSession.clientSessionToken}`;
  }

  public async processYotiMessage(event: MessageEvent): Promise<void> {
    while (this.processing) {
      try {
        const sessionProgress = await this.yotiService.infoSessionProgress(this.userAuth.getUser().id, event.data).pipe(
          delay(YOTI_INFO_SESSION_PROGRESS_DELAY),
        ).toPromise();
        // process only if session completed or failed
        if (sessionProgress != null
          && sessionProgress.yotiSession != null
          && sessionProgress.yotiSession.state != null
          && sessionProgress.yotiSession.state !== 'ONGOING') {
          this.processing = false;
          this.yotiSession = sessionProgress.yotiSession;
          this.yotiService.forceReload();

          if (sessionProgress.yotiSession.state === 'COMPLETED') {
            this.dynamicFormService.dynamicForm.yotiCompleted = true;
            this.dynamicFormService.identityVerificationCompleted.next(sessionProgress);
          }
        }
      } catch (err) {
        this.processing = false;
        this.yotiSession = {
          state: 'FAILED'
        };
      }
    }
  }

  onBackToMenuClick() {
    this.dynamicFormService.identityVerificationBackToMenu.next();
    this.backToMenu.next(true);
    this.yotiService.forceReload();
    this.showModal = false;
  }
}
