import { Component, OnInit } from '@angular/core';
import { IMyDpOptions } from 'mydatepicker';

@Component({
  selector: 'xavier-component-list',
  templateUrl: './component-list.component.html',
  styleUrls: ['./component-list.component.sass']
})
export class ComponentListComponent {

  public dpOptions: IMyDpOptions = {
    dateFormat: 'dd mmm yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    showClearDateBtn: true,
  };

  public items = [
    { value: "one", label: "One" },
    { value: "two", label: "Two" },
  ];
}
