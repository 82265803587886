<ng2-toasty [position]="'top-center'" class="xavier-toasty"></ng2-toasty>
<div class="row screening-form preview">

  <!-- Side menu container -->
  <div class="col-sm-12 col-xl-4 d-xl-block" id="dfMenu">
    <!-- Side menu -->
    <div *ngIf="sidePanel" [@slideRightFromHidden]>
      <h3 *ngIf="dashboard" class="side-menu-heading">Screening</h3>
      <div *ngFor="let check of formModel.checks"
           [id]="check.id"
           class="row side-menu-item"
           [ngClass]="{'active': selectedItem == check.id}"
           (click)="setActiveItem($event, check.id)">
        <div class="col-2 text-center">
          <i *ngIf="check.checkStatus === 'WITH_CANDIDATE'" class="material-icons with">&#xE40C;</i>
          <i *ngIf="check.checkStatus === 'CANDIDATE_SAVED'" class="material-icons saved">&#xE8F4;</i>
          <i *ngIf="check.checkStatus === 'FAILED'" class="material-icons failed">&#xE001;</i>
          <i *ngIf="check.checkStatus === 'IN_PROGRESS'" class="material-icons inprogress">&#xE627;</i>
          <i *ngIf="check.checkStatus === 'RETURNED'" class="material-icons returned">&#xE31B;</i>
          <i *ngIf="check.checkStatus === 'PASSED'" class="material-icons passed">&#xE876;</i>
          <i *ngIf="check.checkStatus === 'DELAYED'" class="material-icons delayed">&#xE88C;</i>
          <i *ngIf="check.checkStatus === 'FLAGGED'" class="material-icons flagged">&#xE153;</i>
          <i *ngIf="check.checkStatus === 'ADVERSE'" class="material-icons adverse">&#xE5CD;</i>
          <i *ngIf="check.checkStatus === 'DRAFT'" class="material-icons draft">&#xE40C;</i>
        </div>
        <div class="item col-8">
          {{check.checkName}}
        </div>
        <div class="col-2 text-right">
          <i class="material-icons right-arrow">&#xE5CC;</i>
        </div>
      </div>
      <h3 *ngIf="dashboard && formModel.references !== undefined" class="side-menu-heading">References</h3>
      <div *ngFor="let reference of formModel.references"
           [id]="reference.id"
           class="row side-menu-item"
           [ngClass]="{'active': selectedItem == reference.id}"
           (click)="setActiveItem($event, reference.id)">
        <div class="col-2 text-center">
          <i *ngIf="reference.checkStatus === 'WITH_CANDIDATE'" class="material-icons reference-with">&#xE40C;</i>
          <i *ngIf="reference.checkStatus === 'CANDIDATE_SAVED'" class="material-icons reference-saved">&#xE876;</i>
          <i *ngIf="reference.checkStatus === 'TERMINATED'" class="material-icons reference-terminated">close</i>
          <i *ngIf="reference.checkStatus === 'NOT_DELIVERED'" class="material-icons reference-terminated">&#xe0b4;</i>
          <i *ngIf="reference.checkStatus === 'NOT_REQUIRED'" class="material-icons reference-not-required">do_not_disturb_alt</i>
          <i *ngIf="reference.checkStatus === 'OBTAINED'" class="material-icons reference-obtained">playlist_add_check</i>
        </div>
        <div class="item col-8">
          {{reference.checkName}}
        </div>
        <div class="col-2 text-right">
          <i class="material-icons right-arrow">&#xE5CC;</i>
        </div>
      </div>
      <h3 *ngIf="dashboard" class="side-menu-heading">Details</h3>
      <div id="details"
           class="row side-menu-item"
           [ngClass]="{'active': selectedItem == 'details'}"
           (click)="setActiveItem($event, 'details')">
        <div class="item col-10">
          Screening Details
        </div>
        <div class="col-2 text-right">
          <i class="material-icons right-arrow">&#xE5CC;</i>
        </div>
      </div>
      <div id="id-verification"
           class="row side-menu-item"
           [ngClass]="{'active': selectedItem == 'id-verification'}"
           (click)="setActiveItem($event, 'id-verification')">
        <div class="item col-10">
          ID Verification
        </div>
        <div class="col-2 text-right">
          <i class="material-icons right-arrow">&#xE5CC;</i>
        </div>
      </div>
    </div>
  </div>
  <!-- end of Side menu container -->

  <!-- Content container -->
  <div class="col-sm-12 col-xl-8 d-none d-xl-block" id="dfContent">
    <div *ngIf="details.user.emailBouncing" class="global-validation-msg">
      Emails to this candidate are bouncing, please check and correct it
      <a [routerLink]="['/dashboard/candidates/edit/', details.id ]">here</a>
    </div>
    <!-- Content sliding panels -->
    <!-- Checks -->
    <ng-container *ngFor="let check of formModel.checks">
      <div *ngIf="selectedItem === check.id" class="form-panel details"
           [@slideLeftFromHidden]>
        <button (click)="backToMenu()" type="button"
                class="btn xavier-button-plain white back-button d-lg-block d-xl-none">
          <i class="material-icons">&#xE5C4;</i>
        </button>
        <h2 [ngClass]="!preview ? 'check-header' : ''">{{check.checkName}}</h2>
        <div *ngIf="!preview && globalValidationMsg" class="global-validation-msg">
          Please correct the errors below Save the check and Submit the screening.
        </div>
        <div *ngIf="dashboard" class="row">
          <div class="col-4">
            <div class="status-icon"><xavier-status-icon [row]="getCheckStatus(check.checkStatus)"></xavier-status-icon></div><div class="status-text">{{getCheckStatusValue(check.checkStatus)}}</div>
          </div>
          <div class="col-8">
            <div *isAllowed="['CANDIDATE_SCREENING_EDIT']" class="return-menu">
              <button *ngIf="startCheckVisibility" type="button" class="btn-block btn xavier-button start-check"
                      [disabled]="startCheckDisabled" id="startCheck" (click)="startCheck(check.id)">
                <span>Start</span>
              </button>
              <button *ngIf="approveCheckVisibility" type="button" class="btn-block btn xavier-button approve-check"
                      [disabled]="approveCheckDisabled" id="approveCheck" (click)="approveCheck(check.id)"
                      tooltip="Pass" placement="top" container="body" containerClass="xavier-popover-dashboard-container">
                <i class="material-icons">&#xE876;</i>
              </button>
              <button *ngIf="reactivateCheckVisibility" type="button" class="btn-block btn xavier-button reactivate-check"
                      [disabled]="reactivateCheckDisabled" id="reactivateCheck" (click)="reactivateCheck(check.id)"
                      tooltip="Reactivate" placement="top" container="body" containerClass="xavier-popover-dashboard-container">
                <i class="material-icons">sync</i>
              </button>
              <ng-container *ngIf="check.hasOwnProperty('groups')">
                <button *ngIf="returnCheckVisibility" type="button" class="btn-block btn xavier-button return-check"
                        [disabled]="returnCheckDisabled" id="returnCheck" (click)="returnCheck(check.id)"
                        tooltip="Return" placement="top" container="body" containerClass="xavier-popover-dashboard-container">
                  <i class="material-icons">keyboard_return</i>
                </button>
              </ng-container>
              <button *ngIf="delayCheckVisibility" type="button" class="btn-block btn xavier-button delay-check"
                      [disabled]="delayCheckDisabled" id="delayCheck" (click)="delayCheck(check.id)"
                      tooltip="Delay" placement="top" container="body" containerClass="xavier-popover-dashboard-container">
                <i class="material-icons">&#xE88C;</i>
              </button>
              <button *ngIf="resumeCheckVisibility" type="button" class="btn-block btn xavier-button resume-check"
                      [disabled]="resumeCheckDisabled" id="resumeCheck" (click)="resumeCheck(check.id)"
                      tooltip="Resume" placement="top" container="body" containerClass="xavier-popover-dashboard-container">
                <i class="material-icons">play_arrow</i>
              </button>
              <button *ngIf="flagCheckVisibility" type="button" class="btn-block btn xavier-button flag-check"
                      id="flagCheck" (click)="flagCheck(check.id)"
                      tooltip="Flag" placement="top" container="body" containerClass="xavier-popover-dashboard-container">
                <i class="material-icons">&#xE153;</i>
              </button>
              <button *ngIf="adverseCheckVisibility" type="button" class="btn-block btn xavier-button adverse-check"
                      id="adverseCheck" (click)="adverseCheck(check.id)"
                      tooltip="Adverse" placement="top" container="body" containerClass="xavier-popover-dashboard-container">
                <i class="material-icons">&#xE5CD;</i>
              </button>
              <button type="button" class="btn-block btn xavier-button-plain commentIcon"
                      id="commentCheck" (click)="commentCheck(check.id)"
                      container="body"
                      tooltip="Comment">
                <i class="material-icons">&#xE0B9;</i>
              </button>
              <button type="button" class="btn-block btn xavier-button-plain uploadIcon"
                      id="addFileCheck" (click)="addFileCheck(check.id)"
                      tooltip="Upload File" placement="top" container="body" containerClass="xavier-popover-dashboard-container">
                <i class="material-icons">&#xE2C6;</i>
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 dashboard-comments" [id]="'db-comments_'+check.id"></div>
        </div>
        <div class="check-info" [innerHTML]="check.information"></div>
        <ng-container *ngFor="let group of check.groups">
          <xavier-dashboard-df-group [group]="group"
                                     [checkId]="check.id"
                                     [preview]="preview"
                                     [dashboard]="dashboard"
                                     [screeningStatus]="screeningStatus"
                                     [isScreeningCompleted]="isScreeningCompleted"></xavier-dashboard-df-group>
        </ng-container>
      </div>
    </ng-container>

    <!-- References -->
    <ng-container *ngFor="let reference of formModel.references">
      <div *ngIf="selectedItem === reference.id" class="form-panel details"
           [@slideLeftFromHidden]>
        <button *ngIf="!preview" (click)="backToMenu()" type="button"
                class="btn xavier-button-plain white back-button d-lg-block d-xl-none">
          <i class="material-icons">&#xE5C4;</i>
        </button>
        <h2 [ngClass]="!preview ? 'check-header' : ''">{{reference.checkName}}</h2>
        <div *ngIf="!preview && globalValidationMsg" class="global-validation-msg">
          Please correct the errors below Save the check and Submit the screening.
        </div>
        <div *ngIf="dashboard" class="row">
          <div class="col-4">
            <div class="status-icon"><xavier-status-icon [row]="getReferenceStatus(reference.checkStatus)"></xavier-status-icon></div><div class="status-text">{{getReferenceStatusValue(reference.checkStatus)}}</div>
          </div>
          <div class="col-8">
            <div *isAllowed="['CANDIDATE_SCREENING_EDIT']" class="return-menu">
              <button type="button" class="btn-block btn xavier-button-plain commentIcon"
                      id="commentReference" (click)="commentReference(reference.id)"
                      tooltip="Comment" placement="top" container="body" containerClass="xavier-popover-dashboard-container">
                <i class="material-icons">&#xE0B9;</i>
              </button>
              <button type="button" class="btn-block btn xavier-button-plain uploadIcon"
                      id="addFileReference" (click)="addFileReference(reference.id)"
                      tooltip="Upload File" placement="top" container="body" containerClass="xavier-popover-dashboard-container">
                <i class="material-icons">&#xE2C6;</i>
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 dashboard-comments" [id]="'db-comments_'+reference.id"></div>
        </div>
        <span class="check-info">{{reference.information}}</span>
        <div *isAllowed="['CANDIDATE_SCREENING_EDIT']" class="reference-change-wrapper">
          <ng-container *ngIf="reference.checkStatus !== 'OBTAINED' && reference.checkStatus !== 'NOT_REQUIRED'">
            <ng-container *ngIf="reference.wasSent">Reference request has been sent to {{reference.contactName}} {{reference.email}}</ng-container>
            <ng-container *ngIf="!reference.wasSent">Reference request will be sent to {{reference.contactName}} {{reference.email}}</ng-container>
          </ng-container>
          <ng-container *ngIf="reference.checkStatus === 'OBTAINED' || reference.checkStatus === 'NOT_REQUIRED'">This reference will not be collected by Xavier</ng-container>
          <div class="text-right">
            <button *ngIf="!isScreeningCompleted" class="xavier-button returned small cancel-button" (click)="openCancelReferenceModal(reference)">Cancel</button>
            <button *ngIf="!isScreeningCompleted" class="xavier-button continue-step small change-button" (click)="openChangeReferenceModal(reference)">Change</button>
          </div>
        </div>
        <ng-container *ngFor="let group of reference.groups">
          <xavier-dashboard-df-group [group]="group"
                                     [checkId]="reference.id"
                                     [preview]="preview"
                                     [dashboard]="dashboard"
                                     [reference]="true"
                                     [isScreeningCompleted]="isScreeningCompleted"></xavier-dashboard-df-group>
        </ng-container>
      </div>
    </ng-container>

    <!-- Details -->
    <div *ngIf="selectedItem === 'details'" class="form-panel details"
         [@slideLeftFromHidden]>
      <button *ngIf="!preview" (click)="backToMenu()" type="button"
              class="btn xavier-button-plain white back-button d-lg-block d-xl-none">
        <i class="material-icons">&#xE5C4;</i>
      </button>
      <h2 [ngClass]="!preview ? 'check-header' : ''">Screening Details</h2>
      <xavier-dashboard-screening-details [details]="details"></xavier-dashboard-screening-details>
    </div>

    <!-- ID Verification -->
    <div *ngIf="selectedItem === 'id-verification'" class="form-panel id-verification"
         [@slideLeftFromHidden]>
      <button *ngIf="!preview" (click)="backToMenu()" type="button"
              class="btn xavier-button-plain white back-button d-lg-block d-xl-none">
        <i class="material-icons">&#xE5C4;</i>
      </button>
      <h2 [ngClass]="!preview ? 'check-header' : ''">ID Verification</h2>
      <xavier-dashboard-id-verification-details></xavier-dashboard-id-verification-details>
    </div>

    <!-- Event Log -->
    <div *ngIf="dashboard" class="eventlog-panel">
      <h4>Event Log</h4>
      <div *ngIf="eventLogLoading" [@fadeIn]>
        <div class="spinner-container not-button"><div class="spinner-wrap"></div></div>
      </div>
      <div *ngIf="!eventLogLoading" class="container panel" id="eventLogPanel" [@fadeIn]>
        <div *ngFor="let entry of eventLog" class="row">
          <div class="col-8 executor">
            <ng-container *ngIf="entry.executorName != null">{{entry.executorName}}, {{entry.executorRole}}</ng-container>
            <ng-container *ngIf="entry.executorName == null">Xavier Automation</ng-container>
          </div>
          <div class="col-4 timestamp text-right">
            {{entry.timestamp | date:'dd MMM yyyy, HH:mm'}}
          </div>
          <div class="col-12 activity">
            {{entry.activityType | translateActivity}}{{entry.details !== '' ? ' - ' + entry.details : ''}}
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- end of Content container -->

</div>

<ng-template #referenceChangeModal>
  <div class="modal-header">
    <h2 class="modal-title pull-left">Change Candidate Reference Contact</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="changeReferenceModalData" novalidate (ngSubmit)="onCompleteChangeReference(changeReferenceModalData)">
      <div class="container">
        <div class="row">
          <div class="col-12">Reference Contact</div>
          <div *ngIf="institutionVisibility" class="col-12"><b>{{institution}}</b></div>
        </div>
        <div class="row">
          <div class="form-group col-5 xavier-input-text">
            <label for="contactName">Contact Name</label>
            <input type="text" class="form-control" id="contactName" formControlName="contactName"
                   placeholder="Contact Name" spellcheck="true">
            <xavier-error-message [control]="changeReferenceModalData.get('contactName')"></xavier-error-message>
          </div>
          <div class="form-group col-5 xavier-input-text">
            <label for="institution">Institution</label>
            <input type="text" class="form-control" id="institution" formControlName="institution"
                   placeholder="Institution" spellcheck="true">
            <xavier-error-message [control]="changeReferenceModalData.get('institution')"></xavier-error-message>
          </div>
          <div class="form-group col-5 xavier-input-text">
            <label for="phone">Contact Telephone Number</label>
            <input type="text" class="form-control" id="phone" formControlName="phone"
                   placeholder="Contact Telephone Number" spellcheck="true">
            <xavier-error-message [control]="changeReferenceModalData.get('phone')"></xavier-error-message>
          </div>
          <div class="form-group col-5 xavier-input-text">
            <label for="email">Contact Email Address</label>
            <span class="required">&bull;</span>
            <input type="text" class="form-control" id="email" formControlName="email"
                   placeholder="Contact Email Address" spellcheck="true">
            <xavier-error-message [control]="changeReferenceModalData.get('email')"></xavier-error-message>
          </div>
        </div>
        <div class="row change-reference-consent" *ngIf="showChangeReferenceConsent">
          <div class="col-12">
            <div class="form-group">
              <input type="checkbox" class="form-check-input" id="changeReferenceConsent" formControlName="changeReferenceConsent">
              <label for="changeReferenceConsent" class="form-check-label">I understand this will delete the data already provided and cannot be undone</label>
            </div>
          </div>
          <div class="error-text" *ngIf="changeReferenceModalData.get('changeReferenceConsent').touched && changeReferenceModalData.get('changeReferenceConsent').invalid">Please confirm before continuing</div>
        </div>
        <div class="modal-button-group">
          <button type="submit" id='saveChangeReferenceConfirm' [disabled]='saveChangeReferenceConfirm'
                  class="btn xavier-button continue-step weight-normal">
            Save Changes
          </button>
          <button type="button" class="btn xavier-button-plain" (click)="closeModal()">
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #referenceCancelModal>
  <div class="modal-header">
    <h2 class="modal-title pull-left">Cancel Reference</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="cancelReferenceModalData" novalidate (ngSubmit)="onCompleteCancelReference(cancelReferenceModalData)">
      <div class="container">
        <div class="row">
          <div class="form-group col-12 xavier-input-text">
            <label for="reason">Reason for cancelling automated reference collection</label>
            <app-radio-group name="cancel-reference-reason" id="reason">
              <app-radio formControlName="reason" [value]="'NOT_REQUIRED'" label="Not Required"></app-radio>
              <app-radio formControlName="reason" [value]="'OBTAINED'" label="Obtained Already"></app-radio>
            </app-radio-group>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12 xavier-input-text">
            <label for="notes">Notes</label>
            <textarea class="modal-textarea" id="notes" formControlName="notes" rows="3" cols="58" spellcheck="true"></textarea>
          </div>
        </div>
        <div class="modal-button-group">
          <button type="submit" id='saveCancelReferenceConfirm' [disabled]='saveCancelReferenceConfirm'
                  class="btn xavier-button continue-step weight-normal">
            Save
          </button>
          <button type="button" class="btn xavier-button-plain" (click)="closeModal()">
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
