import {Pipe, PipeTransform} from '@angular/core';
import {getEventLogActivityTypeLabel} from "../functions.utils";

@Pipe({name: 'translateActivity'})
export class TranslateActivityPipe implements PipeTransform {
  transform(value: string, args: string[]): any {
    if (!value) return value;
    return getEventLogActivityTypeLabel(value);
  }
}
