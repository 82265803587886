import { Component, ElementRef, forwardRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const MIN_HEIGHT = 76;

@Component({
  selector: 'xavier-autoexpand-textarea',
  templateUrl: './autoexpand-textarea.component.html',
  styleUrls: ['./autoexpand-textarea.component.sass'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AutoexpandTextareaComponent), multi: true }]
})
export class AutoexpandTextareaComponent implements ControlValueAccessor {
  @ViewChild('textarea', { static: true })
  public textarea: ElementRef;

  public height = MIN_HEIGHT;

  public onTextChange($event) {
    this.resize();

    if (!this.onChange) {
      return;
    }

    const cured = $event.target.value && $event.target.value.trim();

    this.onChange && this.onChange(cured);
  }

  public writeValue(obj: any): void {
    if (!obj) {
      return;
    }

    this.textarea.nativeElement.value = obj;
    this.resize();
  }

  private resize() {
    const target = this.textarea.nativeElement;
    const height = Math.max(MIN_HEIGHT, this.height, target.scrollHeight);

    if (height !== this.height) {
      this.height = height;
      target.style.minHeight = `${this.height}px`;
    }
  }

  private onChange: (value: string) => void;

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    // throw new Error('Method not implemented.');
  }
  public setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }
}
