<h2>Legal Signatories</h2>
<div class="container panel">
  <div *ngFor="let sign of clientSignatories; let i=index">
    <div class="row">
      <div class="col-12 col"><h3>Legal Signatory {{i+1}}</h3></div>
    </div>
    <ng-container *ngFor="let signatory of propSignatories">
      <div *ngIf="signatory.id !== 'signatoryAddress'" class="row">
        <div class="col-3 col" [ngClass]="cp.isACheck(signatory.id) ? 'check' : ''">
          {{signatory.label}}
        </div>
        <div *ngIf="cp.isSimpleTextField(signatory.id)" class="col-6 col inline-hover" inlineEdit
             [inlineElementId]="signatory.id"
             [inlineObjectSignature]="signatory.objectSignature"
             [inlineObjectId]="sign.id"
             [inlinePropertyType]="'signatory'"
             [inlineSignatoryArrayIndex]="i">
          <b>{{cp.getValueFromProperty(signatory.id, i)}}</b>
        </div>
        <div *ngIf="signatory.id === 'identification'" class="col-9 col">
          <a download="{{cp.getDocumentSrcFromProperty(signatory.id, i)}}"
             href="{{cp.getDocumentSrcFromProperty(signatory.id, i)}}"
             title="{{cp.getValueFromProperty(signatory.id, i)}}"
             target="_blank">
              <img class="document-thumb" src="{{cp.getDocumentSrcFromProperty(signatory.id, i, true)}}">
          </a>
          {{cp.getValueFromProperty(signatory.id, i)}}
        </div>
        <div *ngIf="cp.isACheck(signatory.id)" class="col-9 col">
          <xavier-check [params]="cp.getCheckParams(signatory.id, i)"></xavier-check>
        </div>
      </div>
      <ng-container *ngIf="signatory.id === 'signatoryAddress'">
        <div *ngFor="let addressDetail of signatory.elements" class="row">
          <div class="col-3 col">
            {{addressDetail.label}}
          </div>
          <div class="col-6 col inline-hover" inlineEdit
               [inlineElementId]="addressDetail.id"
               [inlineObjectSignature]="addressDetail.objectSignature"
               [inlineObjectId]="sign.id"
               [inlinePropertyType]="'signatory'"
               [inlineSignatoryArrayIndex]="i">
            <b>{{cp.getValueFromProperty(addressDetail.id, i)}}</b>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
