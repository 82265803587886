import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl} from "@angular/forms";
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'xavier-multi-select-buttons',
  templateUrl: 'multi-select-buttons.component.html',
  styleUrls: [
    'multi-select-buttons.stylesheet.sass',
    'multi-select-buttons-mediaqueries.stylesheet.sass'
  ]
})

export class MultiSelectButtonsComponent implements OnInit {

  @Input() public title: string;
  @Input() public id: string;
  @Input() public elements: Array<string>;
  @Input() public errorClass: string;
  @Input() public parentFormControl: AbstractControl;

  private selectedElement: string;
  private formArrayPattern: string = '[a-zA-Z0-9]*-[0-9]*';
  private regExp: RegExp;
  private test: boolean;

  constructor() {

  }

  ngOnInit() {
    if (this.parentFormControl.value != '') {
      this.onClick(this.parentFormControl.value);
    }
  }

  onClick(elementId: string, containerId?: string) {
    let formArrayId = this.getFormArrayId(containerId);
    if (formArrayId) {
      // if the multiselect is part of a dynamic form we have to check the formGroup for the right element
      let documentContainer = document.getElementById(elementId+'-'+formArrayId);
      documentContainer.classList.remove('document-visibility');
      _.forEach(this.elements, function(element) {
        if (element.id != elementId) {
          // hide the containers the user didn't click
          let documentContainer = document.getElementById(element.id + '-' + formArrayId);
          documentContainer.classList.add('document-visibility');
        } else {
          // hide the address fields if present
          let addressContainer = document.getElementById(elementId+'-'+formArrayId+'-Address');
          if (addressContainer)
           addressContainer.classList.add('address-visibility');
        }
      });
    }
    this.errorClass = "";
    this.selectedElement = elementId;
    this.parentFormControl.setValue(elementId);
  }

  isActive(element) {
    return this.selectedElement === element;
  }

  public setActive(element) {
    this.selectedElement = element;
    this.parentFormControl.setValue(element);
  }

  private getFormArrayId(containerId) {
    this.regExp = new RegExp(this.formArrayPattern);
    this.test = this.regExp.test(containerId);
    if (this.test) {
      let splitted = containerId.split("-");
      return splitted[1];
    }
    return null;
  }
}
