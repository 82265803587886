import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {HttpService} from '../../shared-services/http.service';
import {ValidateCandidateEmailResultDTO} from '../../../model/candidate/validate-candidate-email-result.dto';
import {of} from 'rxjs/observable/of';

@Injectable()

export class CandidateService {

  private eventLogEntry = new Subject<any>();
  eventLogEntry$ = this.eventLogEntry.asObservable();

  constructor(private httpService: HttpService) {

  }

  public createCandidate(body) {
    return this.put('createCandidate', body, null, false);
  }

  public getCandidatesData(pageSize, pageNum, query, sortBy, sortOrder) {
    let params = [
      { 'pageSize': pageSize.toString() },
      { 'pageNum': pageNum.toString() },
      { 'sortBy': sortBy },
      { 'sortOrder': sortOrder },
      { 'query': query }
    ];
    return this.get('getCandidatesList', null, params);
  }

  public getCandidateStatuses(pageSize, pageNum, query, sortBy, sortOrder, filter) {
    let params = [
      { 'pageSize': pageSize.toString() },
      { 'pageNum': pageNum.toString() },
      { 'sortBy': sortBy },
      { 'sortOrder': sortOrder },
      { 'query': query },
      { 'filter': filter }
    ];
    return this.post('getCandidateStatuses', null, params, null);
  }

  public getCompletedCandidates(pageSize, pageNum, query, sortBy, sortOrder) {
    let params = [
      { 'pageSize': pageSize.toString() },
      { 'pageNum': pageNum.toString() },
      { 'sortBy': sortBy },
      { 'sortOrder': sortOrder },
      { 'query': query }
    ];
    return this.post('getCompletedCandidates', null, params, null);
  }

  public getDivisionBasedInfo(companyId, divisionId) {
    return this.get('getDivisionBasedInfo/' + companyId + '/' + divisionId, null, null);
  }

  public getForEdit(candidateId) {
    return this.get('getForEdit/' + candidateId, null, null);
  }

  public editCandidate(body, id) {
    return this.post('editCandidate/' + id, body, null, false);
  }

  public resendInvite(candidateId) {
    return this.post('resendInvite/' + candidateId, null, null, null);
  }

  public loginAsCandidate(userId) {
    return this.httpService.get('/_ah/api/user/v1', 'logInAs', [{ userId }])
  }

  public emailCandidate(candidateId: string, body: any) {
    return this.post('emailCandidate/' + candidateId, body, null, false);
  }

  public validateEmail(email: string, candidateId: string, companyId: string): Observable<ValidateCandidateEmailResultDTO> {
    const params = {};
    if (candidateId) {
      params['candidateId'] = candidateId;
    }
    if (companyId) {
      params['companyId'] = companyId;
    }

    try {
      return this.get(`validateEmail/${encodeURIComponent(email)}`, null, [params]);
    } catch (e) {
      console.error(e);
      return of({
        valid: true,
        errorType: undefined,
      });
    }
  }

  public getViewConfig(view: string) {
    return this.httpService.get('/_ah/api/config/v1', 'getViewConfig', [{ view: view }]);
  }

  public setViewConfig(view: string, columns: any) {
    return this.httpService.post('/_ah/api/config/v1', 'setViewConfig', { columns: columns }, [{ view: view }], false);
  }

  public getEventLog(id) {
    return this.httpService.get('/_ah/api/log/v1', 'getCandidateLog', [{ candidateId: encodeURIComponent(id) }]);
  }

  public sendEventLogEntry(entry: any) {
    this.eventLogEntry.next(entry);
  }

  public post(methodName: string, body: any, params: any, reportProgress: boolean): Observable<any> {
    return this.httpService.post('/_ah/api/candidate/v1', methodName, body, params, reportProgress);
  }

  public put(methodName: string, body: any, params: any, reportProgress: boolean): Observable<any> {
    return this.httpService.put('/_ah/api/candidate/v1', methodName, body, params, reportProgress);
  }

  public get(methodName: string, body: any, params: any): Observable<any> {
    return this.httpService.get("/_ah/api/candidate/v1", methodName, params);
  }
}
