import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {CompanyService} from "../../services/company.service";
import {
  clientsDashboardFilters, FOR_REVIEW, IN_PROGRESS, PASSED,
  REJECTED
} from '../../../../data/variables.data';
import {Title} from "@angular/platform-browser";
import {EditClientService} from "../../services/edit-client.service";
import {BsModalService} from 'ngx-bootstrap/modal';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {UserPermission} from "../../model/permissions.model";
import {UserAuth} from "../../../../core/userAuth.core";
import {UserService} from "../../services/user.service";
import {ToastyService} from "ng2-toasty";
import {fadeIn} from "../../../../data/animations.data";
import {isNullOrUndefined} from "util";
import {addToaster, setAsTouched} from "../../../utils/functions.utils";
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'xavier-edit-client',
  templateUrl: 'edit-client.component.html',
  styleUrls: ['edit-client.stylesheet.sass'],
  animations: [fadeIn],
  providers: [EditClientService]
})

export class EditClientComponent implements OnInit, OnDestroy {

  @Input() loading: boolean = true;
  @Input() public filter: any = {
    statusBorder: "",
    statusFill: "",
    statusColor: "",
    statusSize: "",
    statusType: ""
  };

  public sideMenuParams = { items: [] };
  public propertiesParams;
  public clientData;
  public completedChecks;
  public totalChecks;
  public percCompleted;
  public statusValue;
  public showReturn: boolean = false;
  public showReject: boolean = false;
  public showApprove: boolean = false;
  public modalRef: BsModalRef;
  public returnApplicationData: FormGroup;
  public rejectApplicationData: FormGroup;

  private id: number;
  private routeSub: any;
  private clientDataSub;
  private clientDataObjectSub;
  private modalConfig = {
    animated: false,
  };

  constructor(private companyService: CompanyService, private activatedRoute: ActivatedRoute, private router: Router,
              private titleService: Title, private editClientService: EditClientService, private userService: UserService,
              private modalService: BsModalService, private fb: FormBuilder, private toastyService: ToastyService,
              private userAuth: UserAuth) {
    this.routeSub = this.activatedRoute.params.subscribe(params => {
      this.id = +params['id'];
    });

    this.clientDataObjectSub = this.editClientService.clientData$.subscribe(
      update => {
        if (isNullOrUndefined(update.signatoryArrayIndex)) {
          _.set(this.clientData, update.signature, update.value);
          if (update.signature === 'clientStatus') {
            _.forEach(clientsDashboardFilters, ( function(filter) {
              if (filter.name === update.value) {
                this.statusValue = filter.value;
                this.filter.statusType = filter.icon;
                this.filter.statusColor = filter.color;
                this.filter.statusBorder = filter.border;
                this.filter.statusFill = filter.fill;
                this.filter.statusSize = filter.size;
                return;
              }
            }).bind(this));
          }
        }
        else {
          _.set(this.clientData.signatories[update.signatoryArrayIndex], update.signature, update.value);
          this.applicationButtonsVisibility();
          this.completedChecksUpdate();
        }
      });

    // side menu parameters computation based on permissions
    this.sideMenuParams.items.push({ id: 'details', title: 'Company Details'});

    if (UserPermission.isAllowed(['USER_LIST_ALL', 'USER_LIST_COMPANY', 'USER_LIST_DIVISION'], this.userAuth.getUser().permissionList))
      this.sideMenuParams.items.push({ id: 'users', title: 'User Access'});

    if (UserPermission.isAllowed(['COMPANY_EDIT_ALL'], this.userAuth.getUser().permissionList))
      this.sideMenuParams.items.push({ id: 'signatories', title: 'Legal Signatories'});

    if (UserPermission.isAllowed(['DIVISION_LIST_ALL', 'DIVISION_LIST_COMPANY'], this.userAuth.getUser().permissionList))
      this.sideMenuParams.items.push({ id: 'divisions', title: 'Divisions'});

    if (UserPermission.isAllowed(['COMPANY_EDIT_ALL'], this.userAuth.getUser().permissionList))
      this.sideMenuParams.items.push({ id: 'settings', title: 'Settings'});

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd))
        return;
      window.scrollTo(0, 0)
    });
  }

  ngOnInit() {
    this.returnApplicationData = this.fb.group({
      returnReason: ['', Validators.required]
    });
    this.rejectApplicationData = this.fb.group({
      rejectReason: ['', Validators.required]
    });

    this.clientDataSub = this.companyService.getClientData(this.id).subscribe(client => {
      if (!isNullOrUndefined(client.company)) {
        this.propertiesParams = {
          items: this.sideMenuParams.items,
          company: client.company
        };
        this.completedChecks = client.completedChecks;
        this.totalChecks = client.totalChecks;
        this.percCompleted = (this.completedChecks / this.totalChecks) * 100;
        this.clientData = client.company;
        this.editClientService.setOverallStatus(client.company.clientStatus);

        _.forEach(clientsDashboardFilters, ( function(filter) {
          if (filter.name === client.company.clientStatus) {
            this.statusValue = filter.value;
            this.filter.statusType = filter.icon;
            this.filter.statusColor = filter.color;
            this.filter.statusBorder = filter.border;
            this.filter.statusFill = filter.fill;
            this.filter.statusSize = filter.size;
            return;
          }
        }).bind(this));

        if (client.company.clientStatus === FOR_REVIEW || client.company.clientStatus === IN_PROGRESS) {
          this.showReturn = true;
        }
        this.applicationButtonsVisibility();
        let title = 'Edit ' + client.company.companyName;
        if (!isNullOrUndefined(client.company.manager))
          title += ' ' + client.company.manager.firstName + ' ' + client.company.manager.lastName;
        title += ' - Xavier Eurocom CI';
        this.titleService.setTitle(title);
        this.loading = false;
      }
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
    this.clientDataSub.unsubscribe();
    this.clientDataObjectSub.unsubscribe();
  }

  approveApplication() {
    this.editClientService.processApplication('approve', this.id).toPromise().then(response => {
      // return to dashboard and show message
      this.router.navigate(['/dashboard/clients'], { queryParams: { approved: true }, skipLocationChange: true });
    });
  }

  disableClient() {
    this.editClientService.disableClient(this.id).toPromise().then(response => {
      // return to dashboard and show message
      this.router.navigate(['/dashboard/clients'], { queryParams: { clientDisabled: true }, skipLocationChange: true });
    });
  }

  logInAs() {
    this.userService.logInAs(this.id).toPromise().then(response => {
      this.userAuth.setUser(response);
      location.reload();
    }).catch(err => {
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.modalConfig);
  }

  onSubmitReject({ value, valid } : { value: string, valid: boolean }) {
    if (!valid) {
      setAsTouched(this.rejectApplicationData);
    } else {
      this.editClientService.processApplication('reject', this.id, value['rejectReason']).toPromise().then(response => {
        // return to dashboard and show message
        this.modalRef.hide();
        this.router.navigate(['/dashboard/clients'], { queryParams: { rejected: true }, skipLocationChange: true });
      });
    }
  }

  onSubmitReturn({ value, valid } : { value: string, valid: boolean }) {
    if (!valid) {
      setAsTouched(this.returnApplicationData);
    } else {
      this.editClientService.processApplication('return', this.id, value['returnReason']).toPromise().then(response => {
        // return to dashboard and show message
        this.modalRef.hide();
        this.router.navigate(['/dashboard/clients'], { queryParams: { returned: true }, skipLocationChange: true });
      });
    }
  }

  applicationButtonsVisibility() {
    let canBeApproved = true;
    let canBeRejected = false;
    let inProgress = false;
    let isApproved = false;
    if (this.clientData.clientStatus === PASSED)
      isApproved = true;
    _.forEach(this.clientData.signatories, ( function(signatory) {
      if (signatory.identityStatus === REJECTED || signatory.moneyLaunderingStatus === REJECTED) {
        this.showReturn = false;
        this.showApprove = false;
        this.showReject = true;
        canBeApproved = false;
        canBeRejected = true;
        inProgress = false;
        return;
      }
      if (signatory.identityStatus !== PASSED || signatory.moneyLaunderingStatus !== PASSED) {
        canBeApproved = false;
      }
      if (signatory.identityStatus === IN_PROGRESS || signatory.moneyLaunderingStatus === IN_PROGRESS) {
        inProgress = true;
      }
    }).bind(this));
    if (inProgress) {
      this.showReturn = true;
      this.showReject = false;
      this.showApprove = false;
    }
    if (canBeApproved) {
      this.showReturn = false;
      this.showReject = false;
      this.showApprove = true;
    }
    if (canBeRejected) {
      this.showReturn = false;
      this.showReject = true;
      this.showApprove = false;
    }
    if (isApproved) {
      this.showReturn = false;
      this.showReject = false;
      this.showApprove = false;
    }
  }

  completedChecksUpdate() {
    let completedChecks = 0;
    let totalChecks = 0;
    _.forEach(this.clientData.signatories, function(signatory) {
      if (signatory.identityStatus === PASSED)
        completedChecks++;
      if (signatory.moneyLaunderingStatus === PASSED)
        completedChecks++;
      totalChecks = totalChecks + 2;
    });
    this.completedChecks = completedChecks;
    this.totalChecks = totalChecks;
    this.percCompleted = (this.completedChecks / this.totalChecks) * 100;
  }

}
