import {Injectable} from '@angular/core';
import {CandidateService} from '../admin/services/candidate.service';
import {AbstractControl} from '@angular/forms';

@Injectable()
export class CandidateEmailValidator {

  public ignoredEmail: string;
  public companyId: string;
  public candidateId: string;

  private emailTimeout: any;

  constructor(
    private candidateService: CandidateService,
  ) {
  }

  public validateEmail = () => {
    return (control: AbstractControl) => {
      if (!control.value) {
        return new Promise(resolve => resolve(null));
      }
      clearTimeout(this.emailTimeout);
      return new Promise(resolve => {
        if (this.ignoredEmail && control.value === this.ignoredEmail) {
          resolve(null);
          return;
        }
        this.emailTimeout = setTimeout(() => {
          try {
            this.candidateService.validateEmail(control.value, this.candidateId, this.companyId)
              .subscribe(result => {
                if (!result.valid) {
                  resolve({ candidateEmail: result.errorType });
                  return;
                }
                resolve(null);
              }, error => {
                resolve(null);
              });
          } catch (error) {
            resolve(null);
          }
        }, 400);
      });
    };
  }
}
