<div *ngIf="isVisible" [id]="formGroupId" class="container panel" [ngClass]="group.level > 1 ? 'panel-noshadow' : ''" [@fadeIn]>
  <div class="row">
    <div class="col-9" [ngClass]="!dashboard && group.repeated === true && group.repeatAutomatic === false ? 'col-9' : 'col-12 hover-return'"
         (mouseenter)="mouseEnter(formGroupId)" (mouseleave)="mouseLeave(formGroupId)" [id]="'db-container_'+formGroupId">
      <div class="heading-wrapper">
        <h3 *ngIf="group.level == 1">{{group.groupName}}</h3>
        <h4 *ngIf="group.level > 1">{{group.groupName}}</h4>
      </div>
      <div *ngIf="dashboard" class="dashboard-container">
        <div *ngIf="!isScreeningCompleted" class="dashboard-toolbar text-right">
          <xavier-dashboard-toolbar [elementId]="formGroupId" [screeningStatus]="screeningStatus"
                                    [reference]="reference">
          </xavier-dashboard-toolbar>
        </div>
      </div>
    </div>
    <div *ngIf="group.repeated === true && group.repeatAutomatic === false" class="col-3 text-right">
      <i *ngIf="deleteGroupIsVisible && !dashboard" class="material-icons df-repeated-icon delete" (click)="deleteDfGroup()">&#xE872;</i>
      <i *ngIf="!dashboard" class="material-icons df-repeated-icon add" (click)="addDfGroup()">&#xE147;</i>
    </div>
  </div>
  <div class="row">
    <div class="col-12 dashboard-comments" [id]="'db-comments_'+formGroupId"></div>
  </div>
  <form [formGroup]="formGroup" novalidate>
    <div class="row">
      <ng-container *ngFor="let attribute of group.attributes">
        <div 
         [ngClass]="{ 'col-12': preview || attribute.fullwidth || dashboard, 'col-sm-12 col-xl-6': !(attribute.fullwidth || dashboard) } ">
          <ng-template df-attribute [component]="attribute"
                       [formGroupId]="formGroupId"
                       [dashboard]="dashboard"
                       [reference]="reference"
                       [screeningStatus]="screeningStatus"
                       [isScreeningCompleted]="isScreeningCompleted"></ng-template>
        </div>
      </ng-container>
    </div>
  </form>
  <ng-container *ngFor="let group of group.groups">
    <xavier-dashboard-df-group [group]="group"
                     [checkId]="checkId"
                     [preview]="preview"
                     [dashboard]="dashboard"
                     [repeatedParentPrefix]="repeatedParentPrefix"
                     [reference]="reference"
                     [screeningStatus]="screeningStatus"
                     [isScreeningCompleted]="isScreeningCompleted"></xavier-dashboard-df-group>
  </ng-container>
</div>
