import { Component, Input, OnInit } from "@angular/core";
import { fadeIn, slideLeftFromHidden, slideRightFromHidden } from "../../../../data/animations.data";
import { Title } from "@angular/platform-browser";
import { CandidateScreeningService } from "../../services/candidate-screening.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IMyDpOptions } from "mydatepicker";
import { addToaster, setAsTouched } from "../../../utils/functions.utils";
import { ToastyService } from "ng2-toasty";
import { attributeRegexValidator, candidateDobValidator, drivingLicenceValidator, issueDateValidator } from "../../../utils/form.validators";

@Component({
  moduleId: module.id,
  selector: "xavier-candidate-id-verification",
  templateUrl: "./candidate-id-verification.component.html",
  styleUrls: ["./candidate-id-verification.component.sass"],
  animations: [fadeIn, slideLeftFromHidden, slideRightFromHidden],
})
export class CandidateIdVerificationComponent implements OnInit {
  public candidateInfo;
  public addressHistory;
  public nameHistory;
  public displayGroup2Checks = false;
  public displayVerificationChecks = false;
  public form: FormGroup = this.getIdVerificationFg();
  public verificationData;
  public group1Checkboxes = {};
  public group2Checkboxes = {};
  public disabled = true;
  public submitLoading: boolean;

  public routeName: any;
  public routeDetails: any;

  public dpOptions: IMyDpOptions = {
    dateFormat: "dd mmm yyyy",
    editableDateField: false,
    openSelectorOnInputClick: true,
    showClearDateBtn: true,
  };

  public control(name: string) {
    return this.form.get(name);
  }

  private verificationScreenData: any;
  private id: string;
  private verificationId: string;
  private routeSub: any;

  @Input() public loading: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private candidatesScreeeningService: CandidateScreeningService,
    private fb: FormBuilder,
    private toastyService: ToastyService,
  ) {
    this.routeSub = this.activatedRoute.params.subscribe((params) => {
      this.id = params["id"];
      this.verificationId = params['verification-id'];
    });
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) return;
      window.scrollTo(0, 0);
    });
  }

  ngOnInit() {
    this.titleService.setTitle("Dashboard - Xavier Eurocom CI");
    this.getVerificationScreenData();
  }

  ngOnDestroy() {
    this.verificationScreenData.unsubscribe();
    this.routeSub.unsubscribe();
  }

  getVerificationScreenData() {
    this.verificationScreenData = this.candidatesScreeeningService
      .getIdVerificationScreenData(this.id)
      .subscribe((verificationScreenData) => {
        if (verificationScreenData) {
          this.candidateInfo = verificationScreenData.candidateInfo;
          this.addressHistory = verificationScreenData.addressHistory;
          this.nameHistory = verificationScreenData.nameHistory;

          this.verificationData = verificationScreenData;

          this.titleService.setTitle(
            `ID Verification - ${this.candidateInfo.currentName}`
          );

          const isBasicDbs = this.candidateInfo.dbsType === 'BASIC';

          this.form.get("ukNational").valueChanges.subscribe((val) => {
            this.routeName = val ? "ROUTE_1" : "ROUTE_1A"
            if (this.candidateInfo.dbsType === 'BASIC') {
              this.routeDetails = [
                "At least one document from Group 1 is required.",
                `${isBasicDbs ? 1 : 2} further document from either Group 1, Group 2A or Group 2B is required (One of which must verify their current address)`,
              ];
            } else {

            }
            this.group1Fg.get('noCandidateCheck').patchValue({
              isChecked: false,
              group: "no-group",
            });
            this.createAllGroupsDocumentFg();
          });

          this.loading = false;
        }
      });
  }

  createIdVerificationQuestionSetForm(questionSet: string, regEx: string) {
    const nestedFormConfig = {
      date_of_birth: [null, [Validators.required, candidateDobValidator(this.candidateInfo.dateOfBirth)]]
    };
    if (questionSet === "PASSPORT") {
      nestedFormConfig["passport_number"] = [null, [Validators.required, attributeRegexValidator(regEx)]];
      nestedFormConfig["nationality"] = [null, Validators.required];
      nestedFormConfig["date_of_issue"] = [null, Validators.required];
    } else if (questionSet === "DRIVERS_LICENCE") {
      nestedFormConfig["driving_licence_number"] = [null, [Validators.required, drivingLicenceValidator(this.candidateInfo)]];
      nestedFormConfig["country_of_issue"] = [null, Validators.required];
      nestedFormConfig["valid_from"] = [null, Validators.required];
    } else {
      nestedFormConfig["date_of_issue"] = [null, [Validators.required, issueDateValidator(this.candidateInfo.dateOfBirth)]];
    }

    return this.fb.group(nestedFormConfig);
  }

  getIdVerificationFg(): FormGroup {
    return this.fb.group({
      ukNational: [null, Validators.required],
      confirmVerification1: [null, Validators.required],
      confirmVerification2: [null, Validators.required],
      group1Fg: this.fb.group({
        documentsArray: this.fb.array([]),
        noCandidateCheck: this.fb.group({
          isChecked: [null],
          group: ["no-group"],
        }),
      }),
      group1AFg: this.fb.group({ documentsArray: this.fb.array([]) }),
      group2AFg: this.fb.group({ documentsArray: this.fb.array([]) }),
      group2BFg: this.fb.group({ documentsArray: this.fb.array([]) }),
    });
  }

  createGroupsDocumentFg(groups: any[], form: any) {
    (form.controls.documentsArray as FormArray).reset();
    groups.forEach((group) => {
      const checkboxGroup = this.fb.group({
        isChecked: [false],
        group: [group],
      });

      (form.controls.documentsArray as FormArray).push(checkboxGroup);
    });
  }

  createAllGroupsDocumentFg() {
    this.group1Checkboxes = {};
    this.group2Checkboxes = {};
    this.displayGroup2Checks = false;
    this.displayVerificationChecks = false;
    this.createGroupsDocumentFg(this.verificationData.group1, this.group1Fg);
    this.createGroupsDocumentFg(this.verificationData.group1A, this.group1AFg);
    this.createGroupsDocumentFg(this.verificationData.group2A, this.group2AFg);
    this.createGroupsDocumentFg(this.verificationData.group2B, this.group2BFg);
  }

  onCheckboxChange(item: AbstractControl) {
    const groupItem = item.value.group;
    const groupItemChecked = item.value.isChecked;
    const groupName = groupItem.group.name;
    const group1Check = groupName && ["GROUP_1", "GROUP_1A"].includes(groupName);
    const group2Check = groupName && ["GROUP_2B", "GROUP_2A"].includes(groupName);
    const checkBoxes = group1Check ? this.group1Checkboxes : group2Check ? this.group2Checkboxes : {};

    if (groupItem.id) {
      if (groupItemChecked) {
        checkBoxes[groupItem.id] = item.value;
      } else {
        delete checkBoxes[groupItem.id];
      }
    }

    const group1Total = Object.keys(this.group1Checkboxes).length;
    const group2Total = Object.keys(this.group2Checkboxes).length;
    const isBasicDbs = this.candidateInfo.dbsType === 'BASIC';

    this.displayGroup2Checks = group1Total > 0;

    if (groupItem === "no-group") {
      if (groupItemChecked) {
        this.createAllGroupsDocumentFg();
        (this.group1Fg.controls.documentsArray as FormArray).disable();
        this.routeName = 'ROUTE_2';
        this.routeDetails = [
          "External ID check billed separately £12.",
          "At least one document from Group 2a is required",
          "2 further documents from either Group 2A or Group 2B are required (One of which must verify their current address)",
        ];
        this.displayGroup2Checks = this.candidateInfo.creditSafeStatus === 'SUCCESSFUL';
      } else {
        this.displayGroup2Checks = false;
        (this.group1Fg.controls.documentsArray as FormArray).enable();
      }
    } else {
      const questionSet = groupItem.questionSet;
      const questionSetLC = questionSet.toLowerCase();


      if (questionSet !== "NONE" && groupItemChecked) {
        const questionSetForm = this.createIdVerificationQuestionSetForm(questionSet, groupItem.regEx);
        (item as FormGroup).addControl(`${questionSetLC}`, questionSetForm);
      } else {
        (item as FormGroup).removeControl(`${questionSetLC}`);
      }

      if (this.displayGroup2Checks) {
        this.routeDetails = [`${isBasicDbs ? 1 : 2} further document from either Group 1, Group 2A or Group 2B is required (One of which must verify their current address)`];
      }

      if ((group1Total > 1 || group1Total + group2Total > 1) && isBasicDbs) {
        this.displayVerificationChecks = true;
        this.routeDetails = null;
      } else if (((group1Total > 2 || group1Total + group2Total > 2) && !isBasicDbs)) {
        this.displayVerificationChecks = true;
        this.routeDetails = null;
      } else {
        this.displayVerificationChecks = false;
        this.form.get('confirmVerification1').reset();
        this.form.get('confirmVerification2').reset();
      }
    }
  }

  runIdCheckCompleted(result) {
    this.getVerificationScreenData();
    this.displayGroup2Checks = true;
  }

  onSubmit(form: FormGroup) {
    if (!this.form.valid) {
      setAsTouched(this.form);
      return;
    }

    const body = {
      verification_id: this.verificationId,
      route: this.routeName,
      is_uk_national: form.value.ukNational,
      documents: []
    }
    Object.keys(form.value).forEach(key => {
      const docArray = form.value[key]['documentsArray'];
      if (docArray) {
        docArray.forEach(doc => {
          const document = {};
          if (doc.isChecked) {
            document['document_id'] = doc.group.id;

            if (doc.group.questionSet !== 'NONE') {
              const questionSet = doc.group.questionSet.toLowerCase();
              document[questionSet] = doc[questionSet];
            }
            body.documents.push(document);
          }
        })
      }
    });
    this.submitLoading = true;
    this.candidatesScreeeningService.submitIdVerification(body).subscribe(
      (response: any) => {
        if (response && response.items) {
          this.router.navigate(['/dashboard/id-verification'], { queryParams: { created: true }, skipLocationChange: true });
        }
      },
      err => {
        addToaster('error', this.toastyService, err.status === 403 ? "403" : "serverError", 'errors');
        this.submitLoading = false;
      },
      () => this.submitLoading = false
    );
  }

  public get group1Fg(): FormGroup {
    return this.form.get("group1Fg") as FormGroup;
  }

  public get group1AFg(): FormGroup {
    return this.form.get("group1AFg") as FormGroup;
  }

  public get group2AFg(): FormGroup {
    return this.form.get("group2AFg") as FormGroup;
  }

  public get group2BFg(): FormGroup {
    return this.form.get("group2BFg") as FormGroup;
  }
}
