<ng2-toasty [position]="'top-center'" class="xavier-toasty"></ng2-toasty>
<div class="xavier-candidate-id-verification-wrapper container" [@fadeIn]>
  <div *ngIf="loading" class="xavier-center-page" [@fadeIn]>
    <div class="spinner-container not-button">
      <div class="spinner-wrap"></div>
    </div>
  </div>
  <div *ngIf="!loading" [@fadeIn]>
    <div class="row header">
      <div class="col-1 text-center">
        <button routerLink="/dashboard/id-verification" type="button" class="btn xavier-button-plain white">
          <i class="material-icons back-link">&#xE5C4;</i>
        </button>
      </div>
      <div class="col-3">
        <h1>{{ candidateInfo.currentName }}</h1>
        <small></small>
      </div>
      <div *ngIf="routeName && routeDetails" class="col-8 container-fluid banner">
        <div class="row">
          <div class="col-2 d-flex align-items-center justify-content-center">
            <h4 class="text-center font-weight-bold text-white">{{ routeName }}</h4>
          </div>
          <div class="col-10 d-flex align-items-center py-2">
            <ul>
              <li *ngFor="let item of routeDetails">{{ item }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row id-verification-form">
      <div class="col-sm-12 col-xl-5 d-xl-block">
        <h3>Candidates Info</h3>
        <div *ngIf="candidateInfo">
          <table class="table">
            <tbody>
              <tr>
                <th>Current Name</th>
                <td>{{ candidateInfo.currentName }}</td>
              </tr>
              <tr>
                <th>Date of Birth</th>
                <td>{{ candidateInfo.dateOfBirth | date: 'dd/MM/yyyy' }}</td>
              </tr>
              <tr>
                <th>Gender</th>
                <td>{{ candidateInfo.gender }}</td>
              </tr>
              <tr>
                <th>Position Details</th>
                <td>{{ candidateInfo.positionDetails }}</td>
              </tr>
              <tr>
                <th>Place of birth</th>
                <td>{{ candidateInfo.placeOfBirth }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br>
        <h3>Address History</h3>
        <div *ngIf="addressHistory">
          <table class="table">
            <tbody>
              <tr>
                <th>Current Address</th>
                <td>{{ addressHistory.currentAddress.address }}</td>
              </tr>
              <tr>
                <th>Dates</th>
                <td>
                  {{ addressHistory.currentAddress.dateFrom | date: 'dd/MM/yyyy' }} - {{
                  addressHistory.currentAddress.dateUntil | date: 'dd/MM/yyyy' }}
                </td>
              </tr>
              <tr>
                <th>Previous Address</th>
                <td>{{ addressHistory.previousAddress.address }}</td>
              </tr>
              <tr>
                <th>Dates</th>
                <td>
                  {{ addressHistory.previousAddress.dateFrom | date: 'dd/MM/yyyy' }} - {{
                  addressHistory.previousAddress.dateUntil | date: 'dd/MM/yyyy' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br>
        <h3>Name History</h3>
        <div *ngIf="nameHistory">
          <table class="table">
            <tbody>
              <tr>
                <th>Current Name</th>
                <td>{{ nameHistory.currentName.name }}</td>
              </tr>
              <tr>
                <th>Dates</th>
                <td>
                  {{ nameHistory.currentName.nameDateFrom | date: 'dd/MM/yyyy' }} - {{
                  nameHistory.currentName.nameDateUntil | date: 'dd/MM/yyyy' }}
                </td>
              </tr>
              <tr>
                <th>Birth Name</th>
                <td>{{ nameHistory.birthName.name }}</td>
              </tr>
              <tr>
                <th>Dates</th>
                <td>
                  {{ nameHistory.birthName.nameDateFrom | date: 'dd/MM/yyyy' }} - {{
                  nameHistory.birthName.nameDateUntil | date: 'dd/MM/yyyy' }}
                </td>
              </tr>
              <tr>
                <th>Previous Name</th>
                <td>{{ nameHistory.previousName.name }}</td>
              </tr>
              <tr>
                <th>Dates</th>
                <td>
                  {{ nameHistory.previousName.nameDateFrom | date: 'dd/MM/yyyy' }} - {{
                  nameHistory.previousName.nameDateUntil | date: 'dd/MM/yyyy' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br>
      </div>

      <div class="col-sm-12 col-xl-7 d-xl-block">

        <form [formGroup]="form" (ngSubmit)="onSubmit(form)">
          <!-- National Declaration -->
          <div class="row mb-5">
            <div class="col">
              <div class="form-panel details" [@slideLeftFromHidden]>
                <h3>National Declaration</h3>
              </div>
              <div class="container panel">
                <h4 class="text-center font-weight-bold">Is the applicant a United Kingdom National?*</h4>
                <div class="col-sm-12 col-xl-6 text-center mx-auto my-3">
                  <div class="form-group xavier-input-text">
                    <app-radio-group name="ukNational" id="ukNational">
                      <app-radio formControlName="ukNational" [value]="true" label="Yes"></app-radio>
                      <app-radio formControlName="ukNational" [value]="false" label="No"></app-radio>
                    </app-radio-group>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Group 1 -->
          <div *ngIf="form.get('ukNational').value === true" [@slideLeftFromHidden]>
            <xavier-candidate-id-verification-group [candidateInfo]="candidateInfo" groupName="Group 1" [form]="group1Fg"
              (onCheckboxClicked)="onCheckboxChange($event)" (onRunIDCheckCompleted)="runIdCheckCompleted($event)"></xavier-candidate-id-verification-group>
          </div>


          <!-- Group 1A -->
          <div *ngIf="form.get('ukNational').value === false" [formGroup]="group1AFg" [@slideLeftFromHidden]>
            <xavier-candidate-id-verification-group groupName="Group 1A" [form]="group1AFg"
              (onCheckboxClicked)="onCheckboxChange($event)"></xavier-candidate-id-verification-group>
          </div>

          <ng-container *ngIf="displayGroup2Checks">
            <!-- Group 2A -->
            <div [formGroup]="group2AFg" [@slideLeftFromHidden]>
              <xavier-candidate-id-verification-group groupName="Group 2A" [form]="group2AFg"
                (onCheckboxClicked)="onCheckboxChange($event)"></xavier-candidate-id-verification-group>
            </div>

            <!-- Group 2B -->
            <div [formGroup]="group2BFg" [@slideLeftFromHidden]>
              <xavier-candidate-id-verification-group groupName="Group 2B" [form]="group2BFg"
                (onCheckboxClicked)="onCheckboxChange($event)"></xavier-candidate-id-verification-group>
            </div>
          </ng-container>


          <!-- Verification -->
          <div *ngIf="displayVerificationChecks" class="row mb-5" [@slideLeftFromHidden]>
            <div class="col">
              <div class="form-panel details">
                <h3>Verification</h3>
              </div>
              <div class="container panel">
                <div class="form-group row">
                  <div class="col-1">
                    <input type="checkbox" class="form-check-input" id="verification1" formControlName="confirmVerification1">
                    <label for="verification1"></label>
                  </div>
                  <div class="col-11 pr-0">
                    <label for="verification1" class="h6">I can confirm that I have checked the current address, date of
                      birth, name and address history.</label>
                    <p><small>You must ensure that at least one document given by the applicant has the applicant’s
                        current address in it</small></p>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-1">
                    <input type="checkbox" class="form-check-input" id="verification2" formControlName="confirmVerification2">
                    <label for="verification2"></label>
                  </div>
                  <div class="col-11 pr-0">
                    <label for="verification2" class="h6">
                      I can confirm that the information that I have provided in support of this application is complete
                      and true and understand that knowingly making a false statement for this purpose is a criminal
                      offence.
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Submit Button -->
          <div class="row mb-5">
            <div *ngIf="control('confirmVerification1').value && control('confirmVerification2').value" class="col text-right">
              <xavier-button [isLoading]="submitLoading">
                Save and Submit
              </xavier-button>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>
