import {Component, OnInit} from "@angular/core";
import {Attribute, ATTRIBUTE_TYPES_MAP} from "../attribute.class";
import {DynamicFormService} from "../../../../services/dynamic-form.service";
import {SELECT_TYPE} from "../../../../../../data/variables.data";
import {isNullOrUndefined} from "util";
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'xavier-select',
  templateUrl: 'select.component.html',
  styleUrls: ['select.stylesheet.sass']
})

export class SelectAttribute extends Attribute implements OnInit {

  public options: Array<any> = [];

  constructor(public dynamicFormService: DynamicFormService) {
    super(dynamicFormService);
  }

  ngOnInit() {
    let formGroup = this.dynamicFormService.getFormGroup(this.formGroupId);
    let formControl = this.dynamicFormService.attributeToFormControl(this.data);

    formGroup.addControl(this.formGroupId+'-'+this.data.id, formControl);

    this.formGroup = formGroup;
    this.formControl = formControl;

    let tempOptions = this.data.options.split(',');
    _.forEach(tempOptions, ( function(value) {
      this.options.push({
        value: value,
        label: value
      });
    }).bind(this));
  }

  ngAfterViewInit() {
    if (!isNullOrUndefined(this.data.conditions))
      for (let condition of this.data.conditions) {
        this.formControl.valueChanges.subscribe(value => this.MATCH_SELECT(this.formGroupId, value, condition));
      }
    super.ngAfterViewInit();
  }

}

ATTRIBUTE_TYPES_MAP.set(SELECT_TYPE, SelectAttribute);
