<div class="xavier-content-wrapper">
  <div class="xavier-header">
    <div class="xavier-header-container">
      <div class="logo">
        <img src="../../../assets/img/eurocom-horizontal.svg" routerLink="/login">
      </div>
    </div>
  </div>
  <div class="xavier-content">
    <router-outlet></router-outlet>
  </div>
</div>
<footer class="xavier-footer" [ngClass]="routeClass()">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ul>
          <li>Copyright © <span id="copyright">{{footerCopyrightDate | date:'y'}}</span> Xavier. All Rights Reserved.</li>
          <li>
            <a href="https://www.eurocomci.co.uk/legal.html">Terms of Service</a> /
            <a href="https://www.eurocomci.co.uk/privacy.html">Privacy Policy</a> /
            <a href="https://www.eurocomci.co.uk/contact.html">Help</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
