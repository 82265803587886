import { Component, OnInit, Output, EventEmitter, ElementRef, Input, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CandidateScreeningService } from '../../modules/admin/services/candidate-screening.service';
import { ActivatedRoute } from '@angular/router';
import { IMyDpOptions, IMyDate } from 'mydatepicker';
import { CreditSafeCandidateDTO } from '../../model/credit-safe-candidate.dto';
import * as _ from 'lodash';
import differenceInMonths from 'date-fns/differenceInMonths'
import { codes } from '../../data/country-codes.data';
import { ToastyService } from 'ng2-toasty';
import { SelectComponent } from 'ng-select';

const xdate2mydp = (date: string) => {
  if (!date) {
    return undefined;
  }

  const parts = date.split("-");
  return {
    date: {
      year: +parts[0],
      month: +parts[1],
      day: +parts[2],
    }
  }
}

const mydp2xdate = (mydp) => {
  if (!mydp) {
    return null;
  }
  const date = mydp.date

  return [date.year, date.month, date.day]
    .map(p => "" + p)
    .map((p, i) => i == 0 ? p : p.padStart(2, "0"))
    .join("-");
}

const titles = [
  "Mr",
  "Mrs",
  "Miss",
  "Ms",
  "Professor",
  "Dr",
  "Sir",
  "Lord",
  "Lady",
  "Baron",
  "Baroness",
  "Brigadier",
  "Canon",
  "Captain",
  "Duchess",
  "Duke",
  "Esq",
  "Father",
  "Hon",
  "Inspector",
  "Lt Col",
  "Major",
  "Most Rever",
  "Pastor",
  "Rabbi",
  "Rev Dr",
  "Reverend",
  "Rt Reverend",
  "Sister",
  "Squadron L",
  "WG CDR",
];
const items = titles.map(t => ({ value: t, label: t }));

const paths = [
  "title",
  "firstName",
  "middleName",
  "lastName",
  "previousFirstName",
  "previousLastName",
  "dateOfBirth",

  "documentNumber",
  "documentIssuingCountry",
  "documentExpiryDate",
  "documentIssueDate",
  "documentPostcode",

  "currentAddress",
  "currentAddress.buildingNo",
  "currentAddress.buildingName",
  "currentAddress.street",
  "currentAddress.city",
  "currentAddress.postCode",
  "currentAddress.startDate",
  "currentAddress.endDate",
  "previousAddress",
  "previousAddress.buildingNo",
  "previousAddress.buildingName",
  "previousAddress.street",
  "previousAddress.city",
  "previousAddress.postCode",
  "previousAddress.startDate",
  "previousAddress.endDate",

  "aml",
  "consumerSearch",
];

const date2MyDate = (date: Date): IMyDate => {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate()}
};

@Component({
  selector: "xavier-start-screening-dialog",
  templateUrl: "./start-screening-dialog.component.html",
  styleUrls: ["./start-screening-dialog.component.sass"],
})
export class StartScreeningDialogComponent implements OnInit {
  @Input()
  public noUkAddresses: boolean;

  @Input()
  public onlyCreditsafe: boolean;

  @Input() onlyCreditCheck: boolean;

  @Output()
  public success = new EventEmitter<any>();

  @Output()
  public cancel = new EventEmitter();

  @ViewChildren(SelectComponent)
  public elements: QueryList<SelectComponent>;

  public form = this.fb.group({
    title: this.fb.group({ value: [null], nodeId: [null] }),
    firstName: this.fb.group({ value: [null], nodeId: [null] }),
    middleName: this.fb.group({ value: [null], nodeId: [null] }),
    lastName: this.fb.group({ value: [null], nodeId: [null] }),
    previousFirstName: this.fb.group({ value: [null], nodeId: [null] }),
    previousLastName: this.fb.group({ value: [null], nodeId: [null] }),
    dateOfBirth: this.fb.group({ value: [null], nodeId: [null] }),

    documentNumber: this.fb.group({ value: [null], nodeId: [null] }),
    documentType: [null],
    documentIssuingCountry: this.fb.group({ value: [null], nudeId: [null] }),
    documentExpiryDate: this.fb.group({ value: [null], nudeId: [null] }),
    documentIssueDate: this.fb.group({ value: [null], nudeId: [null] }),
    documentPostcode: this.fb.group({ value: [null], nudeId: [null] }),

    currentAddress: this.fb.group({
      buildingNo: this.fb.group({ value: [null], nodeId: [null] }),
      buildingName: this.fb.group({ value: [null], nodeId: [null] }),
      street: this.fb.group({ value: [null], nodeId: [null] }),
      city: this.fb.group({ value: [null], nodeId: [null] }),
      postCode: this.fb.group({ value: [null], nodeId: [null] }),
      startDate: this.fb.group({ value: [null], nodeId: [null] }),
      endDate: this.fb.group({ value: [null], nodeId: [null] }),
    }),
    previousAddress: this.fb.group({
      buildingNo: this.fb.group({ value: [null], nodeId: [null] }),
      buildingName: this.fb.group({ value: [null], nodeId: [null] }),
      street: this.fb.group({ value: [null], nodeId: [null] }),
      city: this.fb.group({ value: [null], nodeId: [null] }),
      postCode: this.fb.group({ value: [null], nodeId: [null] }),
      startDate: this.fb.group({ value: [null], nodeId: [null] }),
      endDate: this.fb.group({ value: [null], nodeId: [null] }),
    }),

    aml: [null],
    consumerSearch: [null],
  });

  public control(name: string) {
    return this.form.get(name);
  }
  public enabled(name: string) {
    return this.form.get(name).enabled;
  }
  private get candidateId() {
    return this.route.snapshot.params["id"];
  }

  public xdate2mydp = xdate2mydp;
  public mydp2xdate = mydp2xdate;
  public isLoading = true;
  public isSubmitting = false;
  public bodyOverflow = false;

  public dpOptions: IMyDpOptions = {
    dateFormat: "dd mmm yyyy",
    editableDateField: false,
    openSelectorOnInputClick: true,
    showClearDateBtn: true,
    disableSince: date2MyDate(new Date()),
  };

  public dpOptionsNoLimit: IMyDpOptions = {
    dateFormat: "dd mmm yyyy",
    editableDateField: false,
    openSelectorOnInputClick: true,
    showClearDateBtn: true,
  };

  public items = items;
  public countryCodes = codes;

  constructor(
    private fb: FormBuilder,
    private el: ElementRef,
    private route: ActivatedRoute,
    private candidateScreeningService: CandidateScreeningService,
    private toastyService: ToastyService
  ) {}

  ngOnInit(): void {
    this.candidateScreeningService
      .getCreditSafeCandidate(this.candidateId)
      .subscribe(
        (data) => {
          if (Object.keys(data).length === 0) return;

          this.setupForm(data);
          this.checkOverflow();

          setTimeout(() => {
            data.aml = true;
            data.consumerSearch = true;
            this.form.patchValue(data);
          });

          this.isLoading = false;
        },
        (e) => {
          this.isLoading = false;
        }
      );
  }

  public onSubmit() {
    this.isSubmitting = true;
    const submit = this.onlyCreditsafe
      ? this.candidateScreeningService.runCreditSafe(
          this.candidateId,
          this.form.value
        )
      : this.candidateScreeningService.startScreening(
          this.candidateId,
          this.form.value
        );

    submit.subscribe(
      (data) => {
        this.onlyCreditsafe &&
          this.toastyService.info(
            "Creditsafe running, refresh shortly for results."
          );

        this.success.next(data);
        this.isSubmitting = false;
      },
      (e) => {
        this.toastyService.error("Failed to submit");
        this.isSubmitting = false;
      }
    );
  }

  private enablePreviousAddress(enable: boolean) {
    const paths = [
      "previousAddress",
      "previousAddress.buildingNo",
      "previousAddress.buildingName",
      "previousAddress.street",
      "previousAddress.city",
      "previousAddress.postCode",
      "previousAddress.startDate",
      "previousAddress.endDate",
    ];

    paths.forEach((p) =>
      enable ? this.control(p).enable() : this.control(p).disable()
    );
  }

  private setupForm(data: CreditSafeCandidateDTO) {
    paths.forEach((path) => {
      const enabled = _.has(data, path);
      // console.log("enabled ", path, enabled);
      enabled || this.control(path).disable();
    });

    // this.control("documentIssuingCountry").enable();

    this.control("currentAddress.startDate.value").valueChanges.subscribe(
      (startDate) => {
        // console.log("startDate:", startDate);
        if (!startDate) return;

        const parts = startDate.split("-");
        const date = new Date(parts[0], parts[1] - 1, parts[2], 0, 0, 0);
        const months = differenceInMonths(new Date(), date);

        setTimeout(() => {
          this.enablePreviousAddress(months < 6);
        });

        // console.log("months diff", months);
        this.checkOverflow();
      }
    );
  }

  private checkOverflow() {
    setTimeout(() => {
      const body = this.el.nativeElement.querySelector(".modal");

      if (body.scrollHeight > body.clientHeight) {
        this.bodyOverflow = true;
      }
    });
  }
}
