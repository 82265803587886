import {Component, OnDestroy, OnInit} from '@angular/core';


@Component({
  moduleId: module.id,
  selector: 'xavier-integration',
  templateUrl: 'integration.component.html',
  styleUrls: ['integration.stylesheet.sass']
})
export class IntegrationComponent implements OnInit, OnDestroy {

  constructor(
  ) {
  }

  ngOnInit(): void {
  }

  public ngOnDestroy(): void {
  }
}
