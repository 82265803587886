import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {FormService} from '../../services/form.service';
import {UserService} from '../../services/user.service';
import {User} from '../../../admin/model/user.model';
import {UserAuth} from '../../../../core/userAuth.core';
import {ToastyService} from 'ng2-toasty';
import {securityQuestions} from '../../../../data/variables.data';
import {environment} from '../../../../environments/environment';
import {fadeIn} from '../../../../data/animations.data';
import {fieldsMatcher, passwordValidator} from '../../../utils/form.validators';
import {addToaster, setAsTouched} from '../../../utils/functions.utils';
import {isNullOrUndefined} from 'util';
import { MultiFactorStatus } from '../../model/two-factor-auth.model';

@Component({
  moduleId: module.id,
  selector: 'xavier-signup',
  templateUrl: 'signup.component.html',
  styleUrls: ['signup.stylesheet.sass'],
  animations: [fadeIn]
})

export class SignUpComponent implements OnInit {

  @Input() loading = true;
  @Input() submitForm = false;
  @Input() wrongToken = false;

  public email: string;
  public firstName: string;
  public companyName: string;
  public message: string;
  public registrationData: FormGroup;
  public securityQuestions = securityQuestions;
  public disabled = false;

  private signUpSub;
  private token;

  constructor(private fb: FormBuilder, private router: Router, private activeRoute: ActivatedRoute, private titleService: Title,
              private registrationFormService: FormService, private userService: UserService, private userAuth: UserAuth,
              private toastyService: ToastyService) {

  }

  ngOnInit() {
    this.titleService.setTitle('Complete Registration - Xavier Eurocom CI');

    this.registrationData = this.fb.group({
      manager: this.fb.group({
        password: ['', [Validators.required, passwordValidator]],
        passwordConfirm: ['', [Validators.required]]
      }, {validator: fieldsMatcher('password', 'passwordConfirm')}),
      securityQuestion: [null, Validators.required],
      securityAnswer: ['', Validators.required]
    });

    this.token = this.activeRoute.snapshot.queryParams['token'];

    if (isNullOrUndefined(this.token)) {
      this.loading = false;
      this.submitForm = false;
      this.wrongToken = true;
    } else {
      this.signUpSub = this.userService.getSignUpData(this.token).subscribe(response => {
        if (!isNullOrUndefined(response.firstName)) {
          this.email = response.email;
          this.firstName = response.firstName;
          this.companyName = response.companyName;
          this.message = response.inviteMessage !== null ? response.inviteMessage : '';

          this.loading = false;
          this.submitForm = true;
        }
      },
      error => {
        this.loading = false;
        this.submitForm = false;
        this.wrongToken = true;
      });
    }
  }

  onSubmit({ value, valid }: { value: any, valid: boolean }) {
    if (!valid) {
      setAsTouched(this.registrationData);
    } else {
      this.disabled = true;
      const submitButton = document.getElementById('submitButton');
      const spinnerWrap = document.createElement('div');
      spinnerWrap.classList.add('spinner-wrap');
      spinnerWrap.classList.add('button');

      submitButton.innerHTML = '';
      submitButton.appendChild(spinnerWrap);

      const body = {
        'password': value.manager['password'],
        'securityQuestion': value['securityQuestion'],
        'securityAnswer': value['securityAnswer']
      };

      this.userService.signUp(body, this.token).subscribe(user => {
        if (!isNullOrUndefined(user.id)) {
          this.disabled = false;
          submitButton.innerHTML = '<span>Submit</span>';
          const appUser = new User(user.id, user.firstName, user.lastName, user.fullName,
            user.lastLogin, user.company.id, user.company.companyName, user.role, user.candidateId, user.permissions, user.email);
          this.userAuth.setUser(appUser);
          this.userAuth.checkLoggedIn();

          if (user.multiFactorStatus === MultiFactorStatus.SETUP_REQUIRED) {
            this.router.navigate(['/two-factor/1']);
          } else if (user.multiFactorStatus === MultiFactorStatus.OTP_SENT) {
            this.router.navigate(['/two-factor/otp-code']);
          }

          if (user.multiFactorStatus === MultiFactorStatus.VERIFIED && user.role['name'] === 'Candidate')
            this.router.navigate(['/dashboard/candidate-screening']);
          else if (user.multiFactorStatus === MultiFactorStatus.VERIFIED)
            this.router.navigate(['/dashboard/candidates-overview']);
        }
      }, err => {
        this.disabled = false;
        submitButton.innerHTML = '<span>Submit</span>';
        let action = 'serverError';
        if (err.status === 403)
          action = '403';
        addToaster('error', this.toastyService, action, 'errors');
      });
    }
  }

  onLogin() {
    this.router.navigate(['/login']);
  }

}
