import {AbstractControl} from "@angular/forms";

export class AutocompleteSelectDropdown {
  id: string;
  title: string;
  placeholder: string;
  icon: string;
  data: any;
  allowTyping: boolean;
  formControl: AbstractControl;

  constructor (id: string, title: string, placeholder: string, icon: string, data: any, allowTyping: boolean, formControl: any) {
    this.id = id;
    this.title = title;
    this.placeholder = placeholder;
    this.icon = icon;
    this.data = data;
    this.allowTyping = allowTyping;
    this.formControl = formControl;
  }
}
