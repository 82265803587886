import {ToastOptions} from "ng2-toasty";
import {ValidateCandidateEmailErrorType} from '../model/candidate/types/validate-candidate-email-error.type';

/** REGEX PASSWORD **/
export const strongPasswordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\£\$%\^&\*-_=+])(?=.{12,})");
export const goodPasswordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\£\$%\^&\*-_=+])(?=.{8,})");
export const weakPasswordRegex = new RegExp("^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))(?=.{8,})");
export const minimumPasswordRegex = new RegExp("^((?=.*[a-z])(?=.*[0-9]))(?=.{8,})");

/** ERROR MESSAGES OBJECT **/
export const errorMessages = {
  required: () => 'This field is required',
  emailExists: () => 'This email address is already in use',
  nomatch: () => 'The fields don\'t match or are invalid',
  email: () => 'Invalid email address',
  postcode: () => 'Invalid postcode',
  phone: () => 'Phone number not valid',
  password: () => 'Password too weak',
  emailPass: () => 'The email or password you have entered is invalid or your account is not active',
  secretAnswer: () => 'The answer you have entered is wrong',
  serverError: () => 'An error has occurred, please try again later',
  minlength: () => 'Length too short',
  candidateEmail: errorType => candidateEmailErrors.get(errorType),
};

export const candidateEmailErrors = new Map<ValidateCandidateEmailErrorType, string>([
  ['DIFFERENT_COMPANY', 'Candidate belongs to different company'],
  ['SCREENING_NOT_COMPLETED', 'Screening is not yet completed for given candidate'],
  ['EMAIL_EXISTS', 'Email already exists']
]);

/** TOASTER CONFIG OBJECTS AND MESSAGES **/
export const genericToasterOptions: ToastOptions = {
  title: "",
  msg: "",
  showClose: false,
  timeout: 3000,
  theme: 'default'
};

export const candidatesToasterOptions: ToastOptions = {
  title: "",
  msg: "",
  showClose: true,
  timeout: 3000,
  theme: 'default'
};

export const clientsToasterOptions: ToastOptions = {
  title: "",
  msg: "",
  showClose: true,
  timeout: 3000,
  theme: 'default'
};

export const screeningsToasterOptions: ToastOptions = {
  title: "",
  msg: "",
  showClose: true,
  timeout: 3000,
  theme: 'default'
};

export const candidateScreeningToasterOptions: ToastOptions = {
  title: "",
  msg: "",
  showClose: true,
  timeout: 3000,
  theme: 'default'
};

export const errorsToasterOptions: ToastOptions = {
  title: "",
  msg: "",
  showClose: true,
  timeout: 3000,
  theme: 'default'
};

export const noticeToasterOptions: ToastOptions = {
  title: "",
  msg: "",
  showClose: true,
  timeout: 3000,
  theme: 'default'
};

export const toasterMessages = {
  candidates: [
    { "label": "New Candidate Created", "id": "created" },
    { "label": "Invitation email sent", "id": "resend" }
  ],
  candidateScreening: [
    { "label": "Check Saved", "id": "checksaved" },
    { "label": "Email Sent Successfully", "id": "emailSent" },
    { "label": "File Uploaded", "id": "fileUploaded" },
    { "label": "You've completed your screening! Please check it and submit.", "id": "submitScreening" },
    { "label": "Screening Returned", "id": "screeningReturned" },
    { "label": "Screening Started", "id": "screeningStarted" }
  ],
  clients: [
    { "label": "Client Application Approved", "id": "approved" },
    { "label": "Client Application Disabled", "id": "disabled" },
    { "label": "Client Application Rejected", "id": "rejected" },
    { "label": "Client Application Returned", "id": "returned" },
    { "label": "Client Disabled", "id": "clientDisabled" }
  ],
  divisions: [
    { "label": "New Division Created", "id": "created" },
    { "label": "Division Edited", "id": "edited" },
    { "label": "Division Disabled", "id": "disabled" }
  ],
  screenings: [
    { "label": "New Screening Created", "id": "created" }
  ],
  users: [
    { "label": "New User Created", "id": "created" },
    { "label": "User Edited", "id": "edited" },
    { "label": "User Disabled", "id": "disabled" }
  ],
  notices: [
    { "label": "Certificate is being generated at the moment, please try again", "id": "conflictCertificate"},
    { "label": "Screening export is being generated at the moment, please try again", "id": "conflictExport"},
    { "label": "You do not have authorisation to perform this action", "id": "403"},
    { "label": "There are errors in your submission, please review the form", "id": "validationWarning"},
    { "label": "Form not complete. Please check for red fields and ensure all files have finished uploading.", "id": "uploadingWarning"},
    { "label": "There are errors in your submission, check the consistency of the date fields", "id": "dateValidationWarning"}
  ],
  errors: [
    { "label": "Your Account has been disabled", "id": "login"},
    { "label": "You do not have authorisation to perform this action", "id": "403"},
    { "label": "This screening has not been submitted by candidate yet!", "id": "wrongStatus"},
    { "label": "An error occurred, please try again later", "id": "serverError"}
  ]
};

/** DOCUMENT TYPES ENUM **/
export const documentTypes = [
  { "label": "Passport", "id": "PASSPORT" },
  { "label": "Driving License", "id": "DRIVING_LICENSE" }
];

/** SECURITY QUESTIONS ENUM **/
export const securityQuestions = [
  { id: "FIRST_PET", label: "What was the name of your first pet?" },
  { id: "STREET_GREW_UP", label: "What was name of the street where you grew up?" },
  { id: "MOTHER_MAIDEN", label: "What is your mother's maiden name?" }
];

/** ITEMS PER PAGE SELECT ENUM **/
export const itemsPerPageValues = [
  { name: "10", value: "10 per page" },
  { name: "25", value: "25 per page" },
  { name: "50", value: "50 per page" }
];

/** CLIENTS DASHBOARD FILTERS **/
export const clientsDashboardFilters = [
  { name: "REQUIRED",    value: "Required",    icon: "",                color: "",        fill: "",        border: "#DEE0E0", size: "small" },
  { name: "PASSED",      value: "Passed",      icon: "done",            color: "#FFFFFF", fill: "#8FBC71", border: "#8FBC71", size: "small" },
  { name: "FOR_REVIEW",  value: "For Review",  icon: "visibility",      color: "#FFFFFF", fill: "#739EC6", border: "#739EC6", size: "small" },
  { name: "REJECTED",    value: "Rejected",    icon: "not_interested",  color: "#FFFFFF", fill: "#EA6962", border: "#EA6962", size: "small" },
  { name: "IN_PROGRESS", value: "In Progress", icon: "sync",            color: "#FFFFFF", fill: "#B4B8B7", border: "#B4B8B7", size: "small" },
  { name: "DISABLED",    value: "Disabled",    icon: "close",           color: "#A0A3A3", fill: "",        border: "#FFFFFF", size: "small" },
  { name: "RETURNED",    value: "Returned",    icon: "keyboard_return", color: "#A0A3A3", fill: "",        border: "#DEE0E0", size: "small" }
];

/** CANDIDATES COMPLETE DASHBOARD FILTERS **/
export const candidatesCompleteDashboardFilters = [
  { name: "PASSED",          value: "Clear",                   icon: "done",            color: "#FFFFFF", fill: "#8FBC71", border: "#8FBC71", size: "small" },
  { name: "FLAGGED",         value: "Flagged",                 icon: "flag",            color: "#FFFFFF", fill: "#EA915D", border: "#EA915D", size: "small" },
  { name: "ADVERSE",         value: "Adverse Information",     icon: "close",           color: "#FFFFFF", fill: "#EA6962", border: "#EA6962", size: "small" },
  { name: "TERMINATED",      value: "Terminated",              icon: "stop",            color: "#FFFFFF", fill: "#B4B8B7", border: "#B4B8B7", size: "small" }
];

/** CANDIDATES OVERVIEW DASHBOARD FILTERS **/
export const candidatesOverviewDashboardFilters = [
  { name: "WITH_CANDIDATE",  value: "Required from Candidate", icon: "",                color: "",        fill: "",        border: "#DEE0E0", size: "small" },
  { name: "CANDIDATE_SAVED", value: "For Review",              icon: "visibility",      color: "#FFFFFF", fill: "#739EC6", border: "#739EC6", size: "small" },
  { name: "IN_PROGRESS",     value: "In Progress",             icon: "sync",            color: "#FFFFFF", fill: "#B4B8B7", border: "#B4B8B7", size: "small" },
  { name: "RETURNED",        value: "Returned to Candidate",   icon: "keyboard_return", color: "#A0A3A3", fill: "",        border: "#DEE0E0", size: "small" },
  { name: "PASSED",          value: "Clear",                   icon: "done",            color: "#FFFFFF", fill: "#8FBC71", border: "#8FBC71", size: "small" },
  { name: "DELAYED",         value: "Delayed",                 icon: "hourglass_full",  color: "#FFFFFF", fill: "#E8CB66", border: "#E8CB66", size: "small" },
  { name: "FLAGGED",         value: "Flagged",                 icon: "flag",            color: "#FFFFFF", fill: "#EA915D", border: "#EA915D", size: "small" },
  { name: "ADVERSE",         value: "Adverse Information",     icon: "close",           color: "#FFFFFF", fill: "#EA6962", border: "#EA6962", size: "small" },
  { name: "TERMINATED",      value: "Terminated",              icon: "stop",            color: "#FFFFFF", fill: "#B4B8B7", border: "#B4B8B7", size: "small" }
];

/** REFERENCES OVERVIEW DASHBOARD FILTERS **/
export const referencesOverviewDashboardFilters = [
  { name: "WITH_CANDIDATE",  value: "Waiting for response", icon: "",                   color: "",        fill: "",        border: "#DEE0E0", size: "small" },
  { name: "CANDIDATE_SAVED", value: "Responded",            icon: "done",               color: "#FFFFFF", fill: "#8FBC71", border: "#8FBC71", size: "small" },
  { name: "IN_PROGRESS",     value: "In Progress",          icon: "sync",               color: "#FFFFFF", fill: "#B4B8B7", border: "#B4B8B7", size: "small" },
  { name: "TERMINATED",      value: "Not Obtainable",       icon: "close",              color: "#FFFFFF", fill: "#EA6962", border: "#EA6962", size: "small" },
  { name: "NOT_DELIVERED",   value: "Not Delivered",        icon: "call_missed",        color: "#FFFFFF", fill: "#EA6962", border: "#EA6962", size: "small" },
  { name: "NOT_REQUIRED",    value: "Not Required",         icon: "do_not_disturb_alt", color: "#FFFFFF", fill: "#B4B8B7", border: "#B4B8B7", size: "small" },
  { name: "OBTAINED",        value: "Obtained outside",     icon: "playlist_add_check", color: "#FFFFFF", fill: "#8FBC71", border: "#8FBC71", size: "small" }
];

/** CANDIDATES COMPLETE DASHBOARD COLUMNS **/
export const candidatesCompleteDashboardColumns = [
  { name: "company.companyName",     value: "Company" },
  { name: "humanResources",          value: "Human Resources" },
  { name: "division.divisionName",   value: "Division" },
  { name: "profit",                  value: "Profit (Ex. VAT)" },
  { name: "screening.screeningName", value: "Screening Name" },
  { name: "assignedTime",            value: "Assigned Date" },
  { name: "candidateStatus",         value: "Candidate Status" },
  { name: "submittedTime",           value: "Submitted Date" },
  { name: "initiator",               value: "Initiator" },
  { name: "completedTime",           value: "Completed Date" },
  { name: "screener",                value: "Screener" },
  { name: "invoicedDate",            value: "Invoice Date" },
  { name: "lineManager",             value: "Line Manager" },
  { name: "priceAdjustment",         value: "Additional Cost" },
  { name: "chaserEmails",            value: "Chaser Emails" }
];

/** CANDIDATES OVERVIEW DASHBOARD COLUMNS **/
export const candidatesOverviewDashboardColumns = [
  { name: "company.companyName",     value: "Company" },
  { name: "humanResources",          value: "Human Resources" },
  { name: "division.divisionName",   value: "Division" },
  { name: "profit",                  value: "Profit (Ex. VAT)" },
  { name: "screening.screeningName", value: "Screening Name" },
  { name: "user.activated",          value: "Candidate Status" },
  { name: "submittedTime",           value: "Submitted Date" },
  { name: "initiator",               value: "Initiator" },
  { name: "completedTime",           value: "Completed Date" },
  { name: "screener",                value: "Screener" },
  { name: "invoicedDate",            value: "Invoice Date" },
  { name: "lineManager",             value: "Line Manager" },
  { name: "priceAdjustment",         value: "Additional Cost" },
  { name: "chaserEmails",            value: "Chaser Emails" }
];

export const roleDependantCandidatesOverviewColumns = ['profit'];

export const REQUIRED    = "REQUIRED";
export const PASSED      = "PASSED";
export const FOR_REVIEW  = "FOR_REVIEW";
export const REJECTED    = "REJECTED";
export const IN_PROGRESS = "IN_PROGRESS";
export const DISABLED    = "DISABLED";
export const RETURNED    = "RETURNED";

export const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

/** SCREENINGS DASHBOARD STATUSES **/
export const screeningsDashboardStatuses = [
  { name: "ACTIVE",      value: "Active",      icon: "done",            color: "#FFFFFF", fill: "#8FBC71", border: "#8FBC71", size: "small" },
  { name: "DISABLED",    value: "Disabled",    icon: "close",           color: "#A0A3A3", fill: "",        border: "#FFFFFF", size: "small" },
];

/** ENTITIES ENUM **/
export const entities = [
  { "id": "LIMITED_COMPANY", "label": "Limited Company / PLC" },
  { "id": "ORGANISATION",    "label": "Organisation" },
  { "id": "CHARITY",         "label": "Charity" },
  { "id": "PRIVATE",         "label": "Private Individual" }
];

/** ENTITIES ENUM **/
export const retentionLengths = [
  {'id': 'MONTHS_3', 'label': '3 months'},
  {'id': 'MONTHS_6', 'label': '6 months'},
  {'id': 'MONTHS_12', 'label': '12 months'},
  {'id': 'MONTHS_18', 'label': '18 months'},
  {'id': 'YEARS_2', 'label': '2 years'},
  {'id': 'YEARS_4', 'label': '4 years'},
  {'id': 'YEARS_6', 'label': '6 years'},
  {'id': 'YEARS_8', 'label': '8 years'},
  {'id': 'INDEFINITELY', 'label': 'Indefinitely'},
];

export const summaryEmailDuration = [
  {'id': 'STANDARD_WEEKLY', 'label': 'Standard Weekly'},
  {'id': 'DISABLED', 'label': 'Disabled'}
];

export const saasClient = [
  {'id': 'YES', 'label': 'Yes'},
  {'id': 'NO', 'label': 'No'}
];

/** EVENT_LOG ENUM **/
export const eventLogActivityTypes = [
  { "id": "REGISTRATION_COMPLETED",                  "label": "Registration submitted" },
  { "id": "REGISTRATION_VERIFIED",                   "label": "Registration verified" },
  { "id": "COMPANY_ADDED",                           "label": "Client manually added" },
  { "id": "COMPANY_DISABLED",                        "label": "Client disabled" },
  { "id": "COMPANY_REJECTED",                        "label": "Client request rejected" },
  { "id": "COMPANY_APPROVED",                        "label": "Client request approved" },
  { "id": "COMPANY_RETURNED",                        "label": "Client request returned" },
  { "id": "COMPANY_EDIT",                            "label": "Client edited" },
  { "id": "COMPANY_SIGNATORY_EDIT",                  "label": "Client signatory edited" },
  { "id": "COMPANY_FIXED",                           "label": "Client application fixed" },
  { "id": "DIVISION_ADDED",                          "label": "Division created" },
  { "id": "DIVISION_EDIT",                           "label": "Division edited" },
  { "id": "DIVISION_DISABLED",                       "label": "Division deleted" },
  { "id": "USER_ADDED",                              "label": "User created" },
  { "id": "USER_EDIT",                               "label": "User edited" },
  { "id": "USER_DISABLED",                           "label": "User disabled" },
  { "id": "CANDIDATE_CREATED",                       "label": "Candidate created" },
  { "id": "CANDIDATE_CHASED",                        "label": "Candidate chased" },
  { "id": "CANDIDATE_INVITE_RESENT",                 "label": "Invitation email re-sent" },
  { "id": "CANDIDATE_EDIT",                          "label": "Candidate edited" },
  { "id": "CANDIDATE_SIGNED_UP",                     "label": "Candidate has signed up" },
  { "id": "CANDIDATE_EMAILED",                       "label": "Candidate has been sent an Email" },
  { "id": "CANDIDATE_REFERENCE_CHASED",              "label": "Candidate reference has been chased" },
  { "id": "CANDIDATE_REFERENCE_EDITED",              "label": "Reference edited"},
  { "id": "CANDIDATE_REFERENCE_UNOBTAINABLE",        "label": "Reference unobtainable"},
  { "id": "CANDIDATE_REFERENCE_CANCELLED",           "label": "Reference cancelled"},
  { "id": "CANDIDATE_SCREENING_SUBMITTED",           "label": "Candidate screening submitted"},
  { "id": "CANDIDATE_SCREENING_SLA_STARTED",         "label": "Candidate screening SLA started"},
  { "id": "CANDIDATE_SCREENING_SLA_STOPPED",         "label": "Candidate screening SLA stopped"},
  { "id": "CANDIDATE_SCREENING_STARTED",             "label": "Candidate screening started"},
  { "id": "CANDIDATE_SCREENING_CHECK_STARTED",       "label": "Candidate screening check started"},
  { "id": "CANDIDATE_SCREENING_PART_RETURNED",       "label": "Part of screening returned"},
  { "id": "CANDIDATE_SCREENING_RETURNED",            "label": "Candidate screening returned"},
  { "id": "CANDIDATE_SCREENING_NO_RESPONSE",         "label": "Candidate screening no response"},
  { "id": "CANDIDATE_SCREENING_COMMENT_ADDED",       "label": "Candidate screening comment added"},
  { "id": "CANDIDATE_SCREENING_FILE_ADDED",          "label": "Candidate screening file added"},
  { "id": "CANDIDATE_SCREENING_PART_FLAGGED",        "label": "Part of screening flagged"},
  { "id": "CANDIDATE_SCREENING_PART_ADVERSE",        "label": "Part of screening adverse"},
  { "id": "CANDIDATE_SCREENING_COMMENT_EDITED",      "label": "Comment edited"},
  { "id": "CANDIDATE_SCREENING_COMMENT_REMOVED",     "label": "Comment removed"},
  { "id": "CANDIDATE_SCREENING_REFERENCE_SUBMITTED", "label": "Candidate screening reference submitted"},
  { "id": "CANDIDATE_SCREENING_CHECK_PASSED",        "label": "Candidate screening check passed"},
  { "id": "CANDIDATE_SCREENING_CHECK_REACTIVATED",   "label": "Candidate screening check re-activated"},
  { "id": "CANDIDATE_SCREENING_CHECK_COMPLETED",     "label": "Candidate screening check completed"},
  { "id": "CANDIDATE_SCREENING_CHECK_DELAYED",       "label": "Candidate screening check delayed"},
  { "id": "CANDIDATE_SCREENING_CHECK_RESUMED",       "label": "Candidate screening check resumed"},
  { "id": "CANDIDATE_SCREENING_COMPLETED",           "label": "Candidate screening completed"},
  { "id": "CANDIDATE_SCREENING_REACTIVATED",         "label": "Candidate screening re-activated"},
  { "id": "CANDIDATE_SCREENING_RETURN_REVERTED",     "label": "Candidate screening return reverted"},
  { "id": "CANDIDATE_SCREENING_TERMINATED",          "label": "Candidate screening not obtainable"},
  { "id": "CANDIDATE_SCREENING_DETAILS_EDIT",        "label": "Candidate screening details edited"},
  { "id": "FAILED_TO_COMPLETE_YOTI_IFRAME",          "label": "Candidate failed to finish Yoti iframe"},
  { "id": "CREDITSAFE_AML_SUCCESS",                  "label": "Creditsafe AML Check Successful"},
  { "id": "CREDITSAFE_AML_FAIL",                     "label": "Creditsafe AML Check Failed"},
  { "id": "CREDITSAFE_CONSUMER_SUCCESS",             "label": "Creditsafe Consumer Report Successful"},
  { "id": "CREDITSAFE_CONSUMER_FAIL",                "label": "Creditsafe Consumer Report Failed"},
  { "id": "CREDITSAFE_REVALIDATION_SUCCESS",         "label": "Creditsafe Document Check Successful"},
  { "id": "CREDITSAFE_REVALIDATION_FAIL",            "label": "Creditsafe Document Check Failed"},
];

/** CLIENTS PROPERTIES FOR EACH EDIT PANELS **/
export const clientPropertiesEditPanels = {
  details: [
    { id: 'companyName', label: 'Account Name', objectSignature: 'companyName' },
    { id: 'firstName', label: 'Contact First Name', objectSignature: 'manager.firstName' },
    { id: 'lastName', label: 'Last Name', objectSignature: 'manager.lastName' },
    { id: 'entityType', label: 'Entity Type', objectSignature: 'entityType' },
    { id: 'vat', label: 'VAT Number', objectSignature: 'vat' },
    {
      id: 'address', elements: [
        { id: 'postcode', label: 'Postcode', objectSignature: 'address.postcode' },
        { id: 'addressLine1', label: 'Address Line 1', objectSignature: 'address.addressLine1' },
        { id: 'addressLine2', label: 'Address Line 2', objectSignature: 'address.addressLine2' },
        { id: 'city', label: 'City', objectSignature: 'address.city' }
      ]
    },
    { id: 'officePhone', label: 'Office Phone', objectSignature: 'officePhone' },
    { id: 'mobilePhone', label: 'Mobile Phone', objectSignature: 'mobilePhone' },
    { id: 'email', label: 'Email Address', objectSignature: 'manager.email' }
  ],
  signatories: [
    { id: 'signatoryFirstName', label: 'First Name', objectSignature: 'firstName' },
    { id: 'signatoryLastName', label: 'Last Name', objectSignature: 'lastName' },
    { id: 'jobTitle', label: 'Job Title', objectSignature: 'jobTitle' },
    { id: 'identification', label: 'Identification' },
    {
      id: 'signatoryAddress', elements: [
        { id: 'signatoryPostcode', label: 'Postcode', objectSignature: 'address.postcode' },
        { id: 'signatoryAddressLine1', label: 'Address Line 1', objectSignature: 'address.addressLine1' },
        { id: 'signatoryAddressLine2', label: 'Address Line 2', objectSignature: 'address.addressLine2' },
        { id: 'signatoryCity', label: 'City', objectSignature: 'address.city' }
      ]
    },
    { id: 'identityStatus', label: 'Identity Check', objectSignature: 'identityStatus' },
    { id: 'moneyLaunderingStatus', label: 'Anti Money Laundering Check', objectSignature: 'moneyLaunderingStatus' }
  ],
  security: [
    { id: 'googleToken', label: 'Authentication Type' },
    { id: 'securityQuestion', label: 'Secret Question' },
    { id: 'securityAnswer', label: 'Secret Answer' }
  ],
  divisions: [
    { id: 'divisions', label: 'Divisions', objectSignature: 'divisions' }
  ],
  settings: [
    { id: 'discountPercentage', label: 'Discount', objectSignature: 'discountPercentage' },
    { id: 'minimumPrice', label: 'Minimum Screening Price', objectSignature: 'minimumPrice' },
    { id: 'retentionLength', label: 'Retain Data For', objectSignature: 'retentionLength' },
    { id: 'summaryEmailDuration', label: 'Weekly Summary Email', objectSignature: 'summaryEmailDuration' },
    { id: 'saasClient', label: 'SAAS Client', objectSignature: 'saasClient' }
  ]
};

/** DYNAMIC FORM ATTRIBUTES, MAPS, ETC.. **/

export const CHECKBOX_TYPE = "CHECKBOX";
export const CONFIRMATION_TYPE = "CONFIRMATION";
export const DATE_TYPE = "DATE";
export const EMAIL_TYPE = "EMAIL";
export const FILEUPLOAD_TYPE = "FILEUPLOAD";
export const RADIO_TYPE = "RADIO";
export const SELECT_TYPE = "SELECT";
export const SIGNATURE_TYPE = "SIGNATURE";
export const STRING_TYPE = "STRING";
export const TEL_TYPE = "TEL";
export const TEXTAREA_TYPE = "TEXTAREA";
export const IDENTITY_VERIFICATION_TYPE = "IDENTITY_VERIFICATION";

export const DATE_END = 'end';
export const DATE_START = 'start';

export const DF_VALIDATION_FN = {
  DETECT_GAPS: 'DETECT_GAPS',
  MATCH_RADIO: 'MATCH_RADIO',
  MATCH_SELECT: 'MATCH_SELECT',
  MIN_YEAR_GROUP: 'MIN_YEAR_GROUP',
  MIN_YEARS: 'MIN_YEARS',
  ONLY_PAST: 'onlyPast',
  ONLY_PAST_AND_AFTER_DOB: 'onlyPastAndAfterDob',
  ONLY_FUTURE: 'onlyFuture',
  SUBSEQUENT_DATES: 'SUBSEQUENT_DATES',
  SUBSEQUENT_DATES_SINCE: 'SUBSEQUENT_DATES_SINCE',
  REPEAT_MATCH_GROUP: 'REPEAT_MATCH_GROUP',
  REPEAT_MATCH: 'REPEAT_MATCH',
  EMPLOYMENT_HISTORY_MORE: 'EMPLOYMENT_HISTORY_MORE',
  CURRENT_EMPLOYMENT_STATUS: 'CURRENT_EMPLOYMENT_STATUS',
  DOB: 'dob',
};

/** LIVE ID CHECK ORDER STATUSES **/
export const LIVE_ID_CHECK_ORDER_STATUSES = [
  { name: "UNPAID",          value: "Not Paid",                icon: "money_off",       color: "#FFFFFF", fill: "#EA6962", border: "#EA6962", size: "small" },
  { name: "INIT",            value: "Requested",               icon: "",                color: "",        fill: "",        border: "#DEE0E0", size: "small" },
  { name: "IN_PROGRESS",     value: "In Progress",             icon: "sync",            color: "#FFFFFF", fill: "#B4B8B7", border: "#B4B8B7", size: "small" },
  { name: "COMPLETED",       value: "Completed",               icon: "done",            color: "#FFFFFF", fill: "#8FBC71", border: "#8FBC71", size: "small" },
  { name: "REFUNDED",        value: "Cancelled",               icon: "close",           color: "#FFFFFF", fill: "#EA6962", border: "#EA6962", size: "small" },
];

/** SCREENINGS DASHBOARD STATUSES **/
export const idVerificationDashboardStatuses = [
  { name: "PENDING",      value: "Pending",      icon: "sync",            color: "#FFFFFF", fill: "#B4B8B7", border: "#B4B8B7", size: "small" },
];

/** COUNTERSIGN DASHBOARD STATUSES **/
export const countersignDashboardStatuses = [
  { name: "FAILED",         value: "Failed",     icon: "close",           color: "#FFFFFF", fill: "#EA6962", border: "#EA6962", size: "small" },
];


/** EVIDENCE CHECK DASHBOARD FILTERS **/
export const evidenceCheckDashboardFilters = [
  { name: "FOR_REVIEW",  value: "For Review",  icon: "visibility",      color: "#FFFFFF", fill: "#739EC6", border: "#739EC6", size: "small" },
  { name: "IN_PROGRESS", value: "In Progress", icon: "sync",            color: "#FFFFFF", fill: "#B4B8B7", border: "#B4B8B7", size: "small" },
  { name: "PASSED",      value: "Passed",      icon: "done",            color: "#FFFFFF", fill: "#8FBC71", border: "#8FBC71", size: "small" },
  { name: "DELAYED",     value: "Delayed",     icon: "hourglass_full",  color: "#FFFFFF", fill: "#E8CB66", border: "#E8CB66", size: "small" },
  { name: "FLAGGED",     value: "Flagged",     icon: "flag",            color: "#FFFFFF", fill: "#EA915D", border: "#EA915D", size: "small" },
  { name: "REJECTED",    value: "Rejected",    icon: "not_interested",  color: "#FFFFFF", fill: "#EA6962", border: "#EA6962", size: "small" },
];

/** EVIDENCE CHECK DASHBOARD BADGES **/
export const evidenceCheckDashboardBadges = [
  { name: "PENDING",    value: "Pending",     fill: "#BAB8B5",      border: "#BAB8B5" },
  { name: "TRUSTED",    value: "Trusted",     fill: "#8FBC71",      border: "#8FBC71" },
  { name: "NORMAL",     value: "Normal",      fill: "#5AB7B2",      border: "#5AB7B2" },
  { name: "COMPLETED",  value: "Completed",   fill: "#8FBC71",      border: "#8FBC71" },
  { name: "WARNING",    value: "Warning",     fill: "#FFBF45",      border: "#FFBF45" },
  { name: "HIGH_RISK",  value: "High Risk",   fill: "#EA6962",      border: "#EA6962" },
];

/** EVIDENCE CHECK DASHBOARD STATUSES **/
export const evidenceCheckDashboardStatuses = [
  { name: "TRUE",     value: true,     icon: "done",      color: "#EA6962", fill: "",     border: "", size: "h3" },
  { name: "FALSE",    value: false,    icon: "close",     color: "#8FBC71", fill: "",     border: "", size: "h3" },
];

/** EVIDENCE CHECK DASHBOARD TYPES **/
export const evidenceCheckDashboardTypes = [
  { name: "evidence-check",                   apiPath: 'getManualQualificationRecords',    pageName: "Evidence Check",     progressBarApiPath: 'getEvidenceCheckProgress', nextPageApiPath: 'getNextManualQualificationRecord', prevPageApiPath: 'getPreviousManualQualificationRecord'},
  { name: "evidence-check-quality-assurance", apiPath: 'getQAQualificationRecords',        pageName: "Evidence QA",        progressBarApiPath: 'getQACheckProgress',       nextPageApiPath: 'getNextQAQualificationRecord',     prevPageApiPath: 'getPreviousQAQualificationRecord' },
  { name: "evidence-check-completed",         apiPath: 'getCompletedQualificationRecords', pageName: "Evidence Completed" },
];

/** EVIDENCE CHECK ACCEPTANCE CONDITIONS */
export const evidenceAcceptanceConditons = [
  {id: 1, name:'plrawardDate', updatedName: 'updatedPlrawardDate', text: 'Evidence Has No Award', value: "ALL_EVI_NO_AWARD_DATE", selected: false, edited: false},
  {id: 2, name:'provisionalDeclaration', updatedName: 'updatedProvisionalDeclaration', text: 'Provisional Results Declaration Provided', value: "UK_EVI_REQUIRED_PROV_RESULTS_DECL", selected: false, edited: false},
  {id: 3, name:'simsstampSign', updatedName: 'updatedSimsstampSign', text: 'Signed Sims Report', value: "UK_EVI_REQUIRED_SIGNED_SIMS_REPORT", selected: false, edited: false},
]

/** EVIDENCE CHECK REJECTION REASONS */
export const evidenceRejectionReasons = [
  {id: 1, name: 'englishStudiedAsSecondLanguage', updatedName: 'updatedEnglishStudiedAsSecondLanguage',  value: "INT_EVI_ENGLISH_STUDIED_SECOND_LANG", selected: false, text: 'International Evidence - English is studied as a second language', edited: false},
  {id: 2, name: 'ukEvidenceNotEsfa', updatedName: 'updatedUkEvidenceNotEsfa',  value: "UK_EVI_NOT_ESFA", selected: false, text: 'UK Evidence - Not on the ESFA list', edited: false},
  {id: 3, name: 'gradeNotAcceptable', updatedName: 'updatedGradeNotAcceptable',  value: "UK_EVI_GRADE_NOT_ACCEPTABLE", selected: false, text: 'International Evidence - Grade is not acceptable', edited: false},
  {id: 4, name: 'gradeNotComparable', updatedName: 'updatedGradeNotComparable',  value: "INT_EVI_GRADE_NOT_COMPARABLE", selected: false, text: 'International Evidence - Grade is not comparable', edited: false},
  {id: 5, name: 'ukEvidenceNotLevelTwo', updatedName: 'updatedUkEvidenceNotLevelTwo',  value: "ALL_EVI_SUBJECT_NOT_LEVEL_TWO", selected: false, text: 'All Evidence - Subject is not level two', edited: false},
  {id: 6, name: 'degreeNotAcceptable', updatedName: 'updatedDegreeNotAcceptable',  value: "ALL_EVI_DEGREE_NOT_ACCEPTABLE", selected: false, text: 'All Evidence - Degrees not acceptable', edited: false},
]

/** EVIDENCE CHECK REJECTION REASONS */
export const moreInformationRequired = [
  {id: 1, value: "Evidence From Exam Board", name: "INT_EVI_NOT_EXAM_BOARD_DOC"},
  {id: 2, value: "Original Award Certification NARIC", name: "INT_NARIC_ORIGINAL_CERT"},
]

/** EVIDENCE CHECK REJECTION REASONS */
export const evidenceTypes = [
  {id: 1,  value: 'FINAL CERTIFICATE', name: "FINAL_CERTIFICATE", selected: false},
  {id: 2,  value: 'NARIC', name: "NARIC", selected: false},
  {id: 3,  value: 'TRANSCRIPT', name: "TRANSCRIPT", selected: false},
  {id: 4,  value: 'NAME CHANGE', name: "NAME_CHANGE", selected: false},
  {id: 5,  value: 'PROVISIONAL STATEMENT FROM AWARDING ORG', name: "PROVISIONAL_STATEMENT_FROM_AWARDING_ORG", selected: false},
  {id: 6,  value: 'STATEMENT FROM AWARDING ORG', name: "STATEMENT_FROM_AWARDING_ORG", selected: false},
  {id: 7,  value: 'EMAIL FROM AWARDING ORG', name: "EMAIL_FROM_AWARDING_ORG", selected: false},
  {id: 8,  value: 'PERSONAL LEARNING RECORD', name: "PERSONAL_LEARNING_RECORD", selected: false},
  {id: 9,  value: 'CMIS REPORT', name: "CMIS_REPORT", selected: false},
  {id: 10, value: 'SIMS REPORT', name: "SIMS_REPORT", selected: false},
  {id: 11, value: 'NATIONAL RECORD ACHIEVEMENT', name: "NATIONAL_RECORD_ACHIEVEMENT", selected: false},
  {id: 12, value: 'TRANSLATION', name: "TRANSLATION", selected: false},
  {id: 13, value: 'OTHER', name: "OTHER", selected: false},
]
