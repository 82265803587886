<h2>Settings</h2>
<div class="container panel">
  <div *ngFor="let setting of settings" class="row">
    <div class="col-3 col">
      {{setting.label}}
    </div>
    <div class="col-2 col inline-hover" [class.col-6]="setting.id === 'summaryEmailDuration' " [ngClass]="setting.id" inlineEdit
         [inlineElementId]="setting.id"
         [inlineObjectSignature]="setting.objectSignature"
         [inlineObjectId]="clientId">
      <b>{{cp.getValueFromProperty(setting.id)}}</b>
    </div>
  </div>
</div>
