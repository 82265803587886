import {AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ToastyService} from 'ng2-toasty';
import {attributeRegexValidator, emailValidator} from '../../../utils/form.validators';
import {CandidateService} from '../../services/candidate.service';
import {FormService} from '../../../registration/services/form.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {fadeIn} from '../../../../data/animations.data';
import {UserPermission} from '../../model/permissions.model';
import {UserAuth} from '../../../../core/userAuth.core';
import {addToaster, setAsTouched} from '../../../utils/functions.utils';
import {DropdownComponentCompareWithFn, DropdownComponentItemSourceFn} from '../../../shared-cmps/dropdown/dropdown.component';
import {CompanyService} from '../../services/company.service';
import {map, takeUntil} from 'rxjs/operators';
import {UserService} from '../../services/user.service';
import {CandidateEmailValidator} from '../../../utils/candidate-email.validator';
import {Subject} from 'rxjs/Subject';
import { AutocompleteSelectDropdown } from '../../model/autocomplete-select-dropdown.model';
import { AutocompleteSelectDropdownComponent } from '../../cmps-parts/autocomplete-select-dropdown/autocomplete-select-dropdown.component';
import { isNullOrUndefined } from 'util';

@Component({
  moduleId: module.id,
  selector: 'xavier-new-candidate',
  templateUrl: 'new-candidate.component.html',
  styleUrls: ['new-candidate.component.scss'],
  animations: [fadeIn],
  providers: [CandidateEmailValidator],
})

export class NewCandidateComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren(AutocompleteSelectDropdownComponent) autocompleteDropdowns: QueryList<AutocompleteSelectDropdownComponent>;

  public form = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', { validators: [Validators.required, emailValidator], updateOn: "blur" }],
    phonePrefix: ['+44', Validators.required],
    phone: ['', Validators.compose([Validators.required, Validators.minLength(10)])],
    company: [null, Validators.required],
    division: [null, Validators.required],
    positionApplied: ['', [Validators.required, attributeRegexValidator('^[a-zA-Z ]{1,60}$')]],
    purchaseOrderNumber: [''],
    lineManager: [null],
    humanResources: [null],
    screening: [null, Validators.required],
    screener: [null],
    inviteMessage: [''],
    idVerifier: [null, Validators.required],
    workAtHomeAddress: [null, Validators.required],
    receivePaperCertificate: [null],
    receivePaperCertificateAtCurrentAddress: [null],
    addressToReceivePaperCertificate: [null],
    nameOfEmployerOnCertificate: [null, [Validators.maxLength(60)]],
    confirmTermsAndConditions: [null, Validators.required],
  });

  @ViewChild('createdCandidateModal', { static: true })
  public createdCandidateModal;

  @ViewChild('termsAndConditonsModal', { static: true }) public termsAndConditonsModal;

  public isSubmitting = false;
  public modalRef: BsModalRef;
  public response: any;
  public companyHiddenPadding = false;
  public lineManagerSearchParams: AutocompleteSelectDropdown;
  public humanResourcesSearchParams: AutocompleteSelectDropdown;
  public divisionData;
  public saasClient;
  public displayIdVerification: boolean = false;
  public displayWorkAtHomeAddress: boolean = false;
  public selectedSceening: any;

  private destroy$ = new Subject();
  private divisionId: number;
  private lineManagerId: number = null;
  private humanResourcesId: number = null;
  private lineManagersName: string = null;
  private humanResourceName: string = null


  public get value() {
    return this.form.value;
  }

  public get companyId(): string {
    if (this.form && this.value && this.value.company)
      return this.value.company.id;

    return null;
  }

  public control(name: string) {
    return this.form.get(name);
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private formService: FormService,
    private userAuth: UserAuth,
    private companyService: CompanyService,
    private candidateService: CandidateService,
    private userService: UserService,
    private modalService: BsModalService,
    private toastyService: ToastyService,
    private candidateEmailValidator: CandidateEmailValidator,
  ) {
    this.setupEvents();
    this.setup();
  }

  public ngOnInit(): void {
    this.candidateEmailValidator.ignoredEmail = null;
    this.form.get('email').setAsyncValidators(this.candidateEmailValidator.validateEmail());
    this.form.get('company').valueChanges.pipe(
      takeUntil(this.destroy$),
    ).subscribe(value => {
      this.saasClient = value && value.saasClient === 'YES';
      if (value)
        this.candidateEmailValidator.companyId = value.id;
      else
        this.candidateEmailValidator.candidateId = null;

      this.form.get('email').updateValueAndValidity();
    });

    this.form.get('division').valueChanges.subscribe(() => {
      this.initializeDropdowns();
    })

    this.lineManagerSearchParams = new AutocompleteSelectDropdown(
      'lineManagerName',
      'Line Manager',
      'Select Line Manager...',
      'arrow_drop_down',
      [],
      true,
      this.form.get('lineManager')
    );

    this.humanResourcesSearchParams = new AutocompleteSelectDropdown(
      'humanResourcesName',
      'Human Resources',
      'Select Human Resources...',
      'arrow_drop_down',
      [],
      true,
      this.form.get('humanResources')
    );
  }

  ngAfterViewInit(): void {
    this.autocompleteDropdowns.changes.subscribe(item => {
      if (item.length) {
        this.initializeDropdowns();
      }
    })
}

  public companyItemSource: DropdownComponentItemSourceFn = term => {
    return this.companyService.searchCompany(term).pipe(map(res => res.items || []));
  }

  public idVerifierItemSource: DropdownComponentItemSourceFn = term => {
    const companyId = this.value.company.id;
    return this.companyService.getCompanyIdVerifiers(companyId).pipe(map(res => res.items || []));
  }

  public divisionItemSource: DropdownComponentItemSourceFn = () => {
    const companyId = this.value.company.id;
    return this.companyService.searchDivisionWithPersonnel(companyId, null).pipe(map(res => res.items || []));
  }

  public lineManagerItemSource: DropdownComponentItemSourceFn = () => {
    const companyAndDivisionId = this.value.company.id + "/" + this.value.division.id;
    return this.companyService.lineManagersDivision(companyAndDivisionId).pipe(map(res => res.items || []));
  }

  public humanResourcesItemSource: DropdownComponentItemSourceFn = () => {
    const companyAndDivisionId = this.value.company.id + "/" + this.value.division.id;
    return this.companyService.humanResourcesDivision(companyAndDivisionId).pipe(map(res => res.items || []));
  }

  public screeningItemSource: DropdownComponentItemSourceFn = () => {
    const companyAndDivisionId = this.value.company.id + "/" + this.value.division.id;
    return this.candidateService.getDivisionBasedInfo(this.value.company.id, this.value.division.id).pipe(map(res => res.screenings || []));
  }

  public screenerItemSource: DropdownComponentItemSourceFn = () => {
    const companyAndDivisionId = this.value.company.id + "/" + this.value.division.id;
    return this.userService.getScreeners().pipe(map(res => res.items || []));
  }

  public compareFn: DropdownComponentCompareWithFn = (a, b) => a.id === b.id;

  private setup() {
    const user = this.userAuth.getUser();
    this.saasClient = user && user.saasClient === 'YES';
    if (!UserPermission.isAllowed(['CANDIDATE_NEW_ALL'], user.permissionList)) {
      this.form.patchValue({ company: { id: user.companyId }});
      this.companyHiddenPadding = true;
    }
  }

  private setupEvents() {
    const resetField = (fieldName: string) => {
      this.value[fieldName] && this.form.patchValue({ [fieldName]: null });
    };

    this.control("company").valueChanges.subscribe(() => {
      resetField("division");
    });
    this.control("division").valueChanges.subscribe(() => {
      const fields = ["lineManager", "humanResources", "screening", "screener"];
      fields.forEach(field => resetField(field));
    });
    this.control("screening").valueChanges.subscribe((screeningItem => {
      const idVerifier = this.control("idVerifier");
      const workAtHomeAddress = this.control("workAtHomeAddress");
      const receivePaperCertificate = this.control("workAtHomeAddress");
      const confirmTermsAndConditions = this.control("confirmTermsAndConditions");
      const nameOfEmployerOnCertificate = this.control("nameOfEmployerOnCertificate");
      idVerifier.setValue(null);
      workAtHomeAddress.setValue(null);
      receivePaperCertificate.setValue(null);
      confirmTermsAndConditions.setValue(null);
      nameOfEmployerOnCertificate.setValue(null);
      this.selectedSceening = screeningItem;
      this.displayIdVerification = this.saasClient && screeningItem && screeningItem.dbs;
      this.displayWorkAtHomeAddress = this.displayIdVerification && screeningItem.dbsCheckType === 'ENHANCED';
      if (this.displayIdVerification) {
        idVerifier.setValidators(Validators.required);
        receivePaperCertificate.setValidators(Validators.required);
        confirmTermsAndConditions.setValidators(Validators.required);
        idVerifier.updateValueAndValidity();
        receivePaperCertificate.updateValueAndValidity()
        confirmTermsAndConditions.updateValueAndValidity();
        nameOfEmployerOnCertificate.setValidators(Validators.required);
        nameOfEmployerOnCertificate.updateValueAndValidity();

        if (this.displayWorkAtHomeAddress) {
          workAtHomeAddress.setValidators(Validators.required);
          workAtHomeAddress.updateValueAndValidity();
        } else {
          workAtHomeAddress.clearValidators();
          workAtHomeAddress.updateValueAndValidity();
        }
      } else {
        idVerifier.clearValidators();
        receivePaperCertificate.clearValidators();
        confirmTermsAndConditions.clearValidators();
        idVerifier.updateValueAndValidity();
        receivePaperCertificate.updateValueAndValidity()
        confirmTermsAndConditions.updateValueAndValidity();
        nameOfEmployerOnCertificate.clearValidators();
        nameOfEmployerOnCertificate.updateValueAndValidity();

      }
    }));
    this.control("receivePaperCertificate").valueChanges.subscribe((receiveCertifcate => {
      const receiveAtCurrentAddress = this.control("receivePaperCertificateAtCurrentAddress");
      receiveAtCurrentAddress.setValue(null);
      if (receiveCertifcate) {
        receiveAtCurrentAddress.setValidators(Validators.required);
        receiveAtCurrentAddress.updateValueAndValidity();
      } else {
        receiveAtCurrentAddress.clearValidators();
        receiveAtCurrentAddress.updateValueAndValidity();
      }
    }));
    this.control("receivePaperCertificateAtCurrentAddress").valueChanges.subscribe((receiveAtCurrentAddress => {
      const currentAddress = this.control("addressToReceivePaperCertificate");
      currentAddress.setValue(null);
      if (receiveAtCurrentAddress === false) {
        currentAddress.setValidators(Validators.required);
        currentAddress.updateValueAndValidity()
      } else {
        currentAddress.clearValidators();
        currentAddress.updateValueAndValidity()
      }
    }));
  }

  public onSubmit() {
    if (!this.form.valid) {
      setAsTouched(this.form);
      return;
    }

    this.isSubmitting = true;

    let humanResources = null;
    let lineManager = null;
    let division = null;
    if (!isNullOrUndefined(this.divisionData))
      division = { id: this.divisionData.id, divisionName: this.divisionData.divisionName };

    if (!isNullOrUndefined(this.lineManagerId) && !isNullOrUndefined(this.lineManagersName))
      lineManager = { id: this.lineManagerId, fullName: this.lineManagersName };

    if (!isNullOrUndefined(this.humanResourcesId) && !isNullOrUndefined(this.humanResourceName))
      humanResources = { id: this.humanResourcesId, fullName: this.humanResourceName };

    let body = { ...this.value, humanResources, lineManager, division }

    this.candidateService.createCandidate(body).subscribe(
      response => {
        this.response = response;
        this.modalRef = this.modalService.show(this.createdCandidateModal, { animated: false, backdrop: "static", class: 'add-candidate-modal' });
      },
      err => {
        addToaster('error', this.toastyService, err.status === 403 ? "403" : "serverError", 'errors');
        this.isSubmitting = false;
      },
      () => this.isSubmitting = false
    );
  }

  public cancelAddCandidate() {
    if (this.modalService.getModalsCount() > 0) {
      this.modalRef.hide();
    }

    this.router.navigate(["/dashboard/candidates"]);
  }

  public addAnotherCandidate() {
    this.modalRef.hide();
    this.form.reset({ phonePrefix: "+44" });
    this.response = null;
    this.setup();
  }

  public viewCandidate() {
    this.modalRef.hide();
    this.router.navigate(['dashboard/candidates-overview/screening-dashboard/' + this.response.id]);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  onDropdownEntrySelected(entry: any, type?: string) {
    if (entry && entry.fullName && type === 'lineManagerName') {
      this.lineManagerId = entry.id;
      this.lineManagersName = entry.fullName;
    }

    if (entry && entry.fullName && type === 'humanResourcesName') {
      this.humanResourcesId = entry.id;
      this.humanResourceName = entry.fullName;
    }

  }

  initializeDropdowns() {
    this.divisionData = this.form.get('division').value;
    this.divisionId = this.divisionData.id;
    this.autocompleteDropdowns.forEach(autocompleteDropdownsInstance => {
      switch (autocompleteDropdownsInstance.componentParams.id) {
        case 'lineManagerName':
          if (!isNullOrUndefined(this.divisionData.lineManager)) {
            autocompleteDropdownsInstance.componentParams.formControl.setValue(this.companyId + '/' + this.divisionId);
            autocompleteDropdownsInstance.componentParams.formControl.setValue(this.divisionData.lineManager.fullName, {emitEvent: false});
            this.lineManagerId = this.divisionData.lineManager.id;
            this.lineManagersName = this.divisionData.lineManager.fullName;
          } else if (!isNullOrUndefined(this.companyId) && this.divisionId) {
            autocompleteDropdownsInstance.componentParams.formControl.setValue(this.companyId + '/' + this.divisionId);
            autocompleteDropdownsInstance.componentParams.formControl.setValue('-', {emitEvent: false});
          } else {
            autocompleteDropdownsInstance.componentParams.formControl.setValue('-', {emitEvent: false});
          }
          break;
        case 'humanResourcesName':
          if (!isNullOrUndefined(this.divisionData.humanResources)) {
            autocompleteDropdownsInstance.componentParams.formControl.setValue(this.companyId + '/' + this.divisionId);
            autocompleteDropdownsInstance.componentParams.formControl.setValue(this.divisionData.humanResources.fullName, {emitEvent: false});
            this.humanResourcesId = this.divisionData.humanResources.id;
            this.humanResourceName = this.divisionData.humanResources.fullName;
          } else if (!isNullOrUndefined(this.companyId) && this.divisionId) {
            autocompleteDropdownsInstance.componentParams.formControl.setValue(this.companyId + '/' + this.divisionId);
            autocompleteDropdownsInstance.componentParams.formControl.setValue('-', {emitEvent: false});
          } else {
            autocompleteDropdownsInstance.componentParams.formControl.setValue('-', {emitEvent: false});
          }
          break;
        default:
          break;
      }
    });
  }

  openTermsAndCondition() {
    this.modalRef = this.modalService.show(this.termsAndConditonsModal, { animated: false, backdrop: false, class: 'terms-and-condition-modal' });
  }
}
