import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TemplateComponent} from './cmps/template/template.component';
import {Step1Component} from './cmps/step1/step1.component';
import {Step2Component} from './cmps/step2/step2.component';
import {Step3Component} from './cmps/step3/step3.component';
import {Step4Component} from './cmps/step4/step4.component';
import {Step5Component} from './cmps/step5/step5.component';
import {LoginComponent} from './cmps/login/login.component';
import {ForgotPasswordComponent} from './cmps/forgot/forgot.component';
import {ResetPasswordComponent} from './cmps/reset/reset.component';
import {ActivationComponent} from './cmps/activation/activation.component';
import {ApplicationReviewComponent} from './cmps/application-review/application-review.component';
import {SignUpComponent} from './cmps/signup/signup.component';
import {YotiDigitalIdCallbackComponent} from '../integration/yoti/yoti-digital-id-callback/yoti-digital-id-callback.component';
import {IntegrationComponent} from '../integration/integration.component';
import { TFAuthStep1Component } from './cmps/2fa/2fa-step1/2fa-step1.component';
import { TFAuthStep2Component } from './cmps/2fa/2fa-step2/2fa-step2.component';
import { TFAuthLoginComponent } from './cmps/2fa/2fa-login/2fa-login.component';

const routes: Routes = [
  {
    path: '', component: TemplateComponent, children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
      { path: 'forgot', component: ForgotPasswordComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'activation', component: ActivationComponent },
      { path: 'application-review', component: ApplicationReviewComponent },
      { path: 'sign-up', component: SignUpComponent }
    ],
  },
  {
    path: 'registration', component: TemplateComponent, children: [
      { path: '1', component: Step1Component },
      { path: '2', component: Step2Component },
      { path: '3', component: Step3Component },
      { path: '4', component: Step4Component },
      { path: '5', component: Step5Component }
    ]
  },
  {
    path: 'two-factor', component: TemplateComponent, children: [
      { path: '1', component: TFAuthStep1Component },
      { path: '2', component: TFAuthStep2Component },
      { path: 'otp-code', component: TFAuthLoginComponent },
    ]
  },
  {
    path: 'integration',
    component: IntegrationComponent,
    children: [
      {
        path: 'yoti',
        children: [
          {
            path: 'digital-id-callback',
            pathMatch: 'full',
            component: YotiDigitalIdCallbackComponent,
          },
          {
            path: '**',
            pathMatch: 'full',
            redirectTo: '/login',
          },
        ]
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: '/login',
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})

export class RegistrationRoutingModule {

}
