import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {AdminTemplateComponent} from './cmps/admintemplate/admintemplate.component';
import {AdminRoutingModule} from './admin.routing';
import {NavbarComponent} from './cmps/navbar/navbar.component';
import {CandidateReferenceFormComponent} from './cmps/candidate-reference-form/candidate-reference-form.component';
import {CandidateScreeningComponent} from './cmps/candidate-screening/candidate-screening.component';
import {CandidatesComponent} from './cmps/candidates/candidates.component';
import {CandidatesCompleteComponent} from './cmps/candidates-complete/candidates-complete.component';
import {CandidatesOverviewComponent} from './cmps/candidates-overview/candidates-overview.component';
import {ChecksComponent} from './cmps/checks/checks.component';
import {ClientsComponent} from './cmps/clients/clients.component';
import {EditClientComponent} from './cmps/edit-client/edit-client.component';
import {ScreeningsComponent} from './cmps/screenings/screenings.component';
import {UsersComponent} from './cmps/users/users.component';
import {AddScreeningComponent} from './cmps/add-screening/add-screening.component';
import {EditCandidateComponent} from './cmps/edit-candidate/edit-candidate.component';
import {AddUserComponent} from './cmps/add-user/add-user.component';
import {EditUserComponent} from './cmps/edit-user/edit-user.component';
import {PreviewScreeningComponent} from './cmps/preview-screening/preview-screening.component';
import {PreviewCheckComponent} from './cmps/preview-check/preview-check.component';
import {ScreeningDashboardComponent} from './cmps/screening-dashboard/screening-dashboard.component';
import {EditScreeningComponent} from './cmps/edit-screening/edit-screening.component';
import {ColumnsDropdownComponent} from './cmps-parts/columns-dropdown/columns-dropdown.component';
import {FiltersDropdownComponent} from './cmps-parts/filters-dropdown/filters-dropdown.component';
import {PercentBarComponent} from './cmps-parts/percent-bar/percent-bar.component';
import {StatusIconComponent} from './cmps-parts/status-icon/status-icon.component';
import {ClientSideMenuComponent} from './cmps-parts/client-side-menu/client-side-menu.component';
import {ClientPropertiesComponent} from './cmps-parts/client-properties/client-properties.component';
import {ClientPropertiesDetailsComponent} from './cmps-parts/client-properties/details/details.component';
import {ClientPropertiesUsersComponent} from './cmps-parts/client-properties/users/users.component';
import {ClientPropertiesSignatoriesComponent} from './cmps-parts/client-properties/signatories/signatories.component';
import {ClientPropertiesSecurityComponent} from './cmps-parts/client-properties/security/security.component';
import {ClientPropertiesDivisionsComponent} from './cmps-parts/client-properties/divisions/divisions.component';
import {ClientPropertiesSettingsComponent} from './cmps-parts/client-properties/settings/settings.component';
import {CheckComponent} from './cmps-parts/check/check.component';
import {AutocompleteSelectDropdownComponent} from './cmps-parts/autocomplete-select-dropdown/autocomplete-select-dropdown.component';
import {ChecksSelectorComponent} from './cmps-parts/checks-selector/checks-selector.component';
import {InlineEditDirective} from '../directives/inline-edit.directive';
import {IsAllowedDirective} from '../directives/is-allowed.directive';
import {CanAccessGuard} from './can-access-guard.routing';
import {HttpClientModule} from '@angular/common/http';
import {SelectModule} from 'ng-select';
import {Title} from '@angular/platform-browser';
import {UserAuth} from '../../core/userAuth.core';
import {HttpService} from '../shared-services/http.service';
import {UserService} from './services/user.service';
import {CandidateService} from './services/candidate.service';
import {CandidateScreeningService} from './services/candidate-screening.service';
import {CompanyService} from './services/company.service';
import {ReferenceFormService} from './services/reference-form.service';
import {ScreeningService} from './services/screening.service';
import {TableService} from './services/table.service';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {ToastyModule} from 'ng2-toasty';

/* Import our Dynamic Form 'package' */
import {DashboardDynamicFormComponent} from './cmps/dashboard-dynamic-form/dashboard-dynamic-form.component';
import {DynamicFormComponent} from './cmps/dynamic-form/dynamic-form.component';
import {DynamicFormService} from './services/dynamic-form.service';
import {DynamicFormDirective} from '../directives/dynamic-form.directive';
import {DashboardDfGroupComponent} from './cmps/dashboard-dynamic-form/dashboard-df-group/dashboard-df-group.component';
import {DfGroupComponent} from './cmps/dynamic-form/df-group/df-group.component';
import {DashboardScreeningDetailsComponent} from './cmps/dashboard-dynamic-form/dashboard-screening-details/dashboard-screening-details.component';
import {DashboardToolbarAttribute} from './cmps/dashboard-dynamic-form/model/dashboard-toolbar/dashboard-toolbar.component';
import {DashboardCheckboxAttribute} from './cmps/dashboard-dynamic-form/model/checkbox/dashboard-checkbox.component';
import {CheckboxAttribute} from './cmps/dynamic-form/model/checkbox/checkbox.component';
import {DashboardConfirmationAttribute} from './cmps/dashboard-dynamic-form/model/confirmation/dashboard-confirmation.component';
import {ConfirmationAttribute} from './cmps/dynamic-form/model/confirmation/confirmation.component';
import {DashboardDateAttribute} from './cmps/dashboard-dynamic-form/model/date/dashboard-date.component';
import {DateAttribute} from './cmps/dynamic-form/model/date/date.component';
import {DashboardEmailAttribute} from './cmps/dashboard-dynamic-form/model/email/dashboard-email.component';
import {EmailAttribute} from './cmps/dynamic-form/model/email/email.component';
import {DashboardFileuploadAttribute} from './cmps/dashboard-dynamic-form/model/fileupload/dashboard-fileupload.component';
import {FileuploadAttribute} from './cmps/dynamic-form/model/fileupload/fileupload.component';
import {DashboardRadioAttribute} from './cmps/dashboard-dynamic-form/model/radio/dashboard-radio.component';
import {RadioAttribute} from './cmps/dynamic-form/model/radio/radio.component';
import {DashboardSelectAttribute} from './cmps/dashboard-dynamic-form/model/select/dashboard-select.component';
import {SelectAttribute} from './cmps/dynamic-form/model/select/select.component';
import {DashboardSignatureAttribute} from './cmps/dashboard-dynamic-form/model/signature/dashboard-signature.component';
import {SignatureAttribute} from './cmps/dynamic-form/model/signature/signature.component';
import {SignatureFieldComponent} from './cmps/dynamic-form/model/signature/signature-field.component';
import {DashboardStringAttribute} from './cmps/dashboard-dynamic-form/model/string/dashboard-string.component';
import {StringAttribute} from './cmps/dynamic-form/model/string/string.component';
import {DashboardTelAttribute} from './cmps/dashboard-dynamic-form/model/tel/dashboard-tel.component';
import {TelAttribute} from './cmps/dynamic-form/model/tel/tel.component';
import {DashboardTextareaAttribute} from './cmps/dashboard-dynamic-form/model/textarea/dashboard-textarea.component';
import {TextareaAttribute} from './cmps/dynamic-form/model/textarea/textarea.component';

/* Import generic table module
   documentation at https://hjalmers.github.io/angular-generic-table/start */
import {GenericTableModule} from '@angular-generic-table/core';
import {ColumnSettingsModule} from '@angular-generic-table/column-settings';

/* Import dragula module
   documentation at https://github.com/valor-software/ng2-dragula */
import {DragulaModule} from 'ng2-dragula';

import {YotiIdentityVerificationService} from './services/yoti-identity-verification.service';
import {IdentityVerificationAttribute} from './cmps/dynamic-form/model/identity-verification/identity-verification.component';
import {DashboardIdentityVerificationAttribute} from './cmps/dashboard-dynamic-form/model/idetity-verification/dashboard-identity-verification.component';
import {YotiIdentityVerificationComponent} from './cmps/dynamic-form/model/identity-verification/yoti-identity-verification/yoti-identity-verification.component';
import {YotiLogoComponent} from './cmps/dashboard-dynamic-form/yoti-logo/yoti-logo.component';
import {LiveIdOrdersComponent} from './cmps/live-id-orders/live-id-orders.component';
import {ScreeningOrderApi} from '../../api/screening-order-api.service';
import {DownloadIconComponent} from './cmps-parts/download-icon/download-icon.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {NewCandidateComponent} from './cmps/new-candidate/new-candidate.component';
import {ComponentListComponent} from '../../app/component-list/component-list.component';
import {StartScreeningDialogComponent} from '../../app/start-screening-dialog/start-screening-dialog.component';
import {ValueTransformerDirective} from '../directives/value-transformer.directive';
import {StartScreeningNoCreditsafeDialogComponent} from '../../app/start-screening-no-creditsafe-dialog/start-screening-no-creditsafe-dialog.component';
import {YotiDigitalIdComponent} from './cmps/dynamic-form/model/identity-verification/yoti-digital-id/yoti-digital-id.component';
import {WindowRefService} from '../shared-services/window-ref.service';
import {YotiDigitalIdService} from './services/yoti-digital-id.service';
import {YotiButtonComponent} from './cmps/dynamic-form/model/identity-verification/yoti-identity-verification/yoti-button/yoti-button.component';
import { MyDatePickerModule } from 'mydatepicker';
import { YotiIdentityDownloadComponent } from './cmps/dynamic-form/model/identity-verification/yoti-identity-download/yoti-identity-download.component';
import { OptionsDropdownComponent } from './cmps-parts/options-dropdown/options-dropdown.component';
import { DbsService } from './services/dbs.service';
import { IdVerificationsComponent } from './cmps/id-verifications/id-verifications.component';
import { CandidateIdVerificationComponent } from './cmps/candidate-id-verification/candidate-id-verification.component';
import { CandidateIdVerificationGroupComponent } from './cmps/candidate-id-verification-group/candidate-id-verification-group.component';
import { DashboardIdVerificationDetailsComponent } from './cmps/dashboard-dynamic-form/dashboard-id-verification-details/dashboard-id-verification-details.component';
import { CountersignOverviewComponent } from './cmps/countersign-overview/countersign-overview.component';
import { CountryService } from './services/country.service';
import { EvidenceCheckComponent } from './cmps/evidence-check/evidence-check.component';
import { EditEvidenceCheckComponent } from './cmps/edit-evidence-check/edit-evidence-check.component';
import { SwitchComponent } from './cmps-parts/switch/switch.component';
import { FileUploadComponent } from './cmps-parts/file-upload/file-upload.component';
import { EditableTextInputboxComponent } from './cmps-parts/editable-text-inputbox/editable-text-inputbox.component';

@NgModule({
  declarations: [
    AdminTemplateComponent,
    NavbarComponent,
    CandidateReferenceFormComponent,
    CandidateScreeningComponent,
    CandidatesComponent,
    CandidatesCompleteComponent,
    CandidatesOverviewComponent,
    ChecksComponent,
    ClientsComponent,
    EditClientComponent,
    ScreeningsComponent,
    UsersComponent,
    AddScreeningComponent,
    NewCandidateComponent,
    EditCandidateComponent,
    AddUserComponent,
    EditUserComponent,
    PreviewScreeningComponent,
    PreviewCheckComponent,
    ScreeningDashboardComponent,
    EditScreeningComponent,
    DashboardDynamicFormComponent,
    DynamicFormComponent,
    DashboardDfGroupComponent,
    DfGroupComponent,
    DashboardScreeningDetailsComponent,
    DashboardToolbarAttribute,
    DashboardCheckboxAttribute,
    CheckboxAttribute,
    DashboardConfirmationAttribute,
    ConfirmationAttribute,
    DashboardDateAttribute,
    DateAttribute,
    DashboardEmailAttribute,
    EmailAttribute,
    DashboardFileuploadAttribute,
    FileuploadAttribute,
    DashboardRadioAttribute,
    RadioAttribute,
    DashboardSelectAttribute,
    SelectAttribute,
    DashboardSignatureAttribute,
    SignatureAttribute,
    SignatureFieldComponent,
    DashboardStringAttribute,
    StringAttribute,
    DashboardTelAttribute,
    TelAttribute,
    DashboardTextareaAttribute,
    TextareaAttribute,
    ColumnsDropdownComponent,
    FiltersDropdownComponent,
    PercentBarComponent,
    StatusIconComponent,
    ClientSideMenuComponent,
    ClientPropertiesComponent,
    ClientPropertiesDetailsComponent,
    ClientPropertiesUsersComponent,
    ClientPropertiesSignatoriesComponent,
    ClientPropertiesSecurityComponent,
    ClientPropertiesDivisionsComponent,
    ClientPropertiesSettingsComponent,
    CheckComponent,
    DynamicFormDirective,
    InlineEditDirective,
    IsAllowedDirective,
    AutocompleteSelectDropdownComponent,
    ChecksSelectorComponent,
    IdentityVerificationAttribute,
    DashboardIdentityVerificationAttribute,
    YotiButtonComponent,
    YotiDigitalIdComponent,
    YotiIdentityVerificationComponent,
    YotiLogoComponent,
    YotiIdentityDownloadComponent,
    LiveIdOrdersComponent,
    DownloadIconComponent,
    ComponentListComponent,
    ValueTransformerDirective,
    StartScreeningDialogComponent,
    StartScreeningNoCreditsafeDialogComponent,
    OptionsDropdownComponent,
    IdVerificationsComponent,
    CandidateIdVerificationComponent,
    CandidateIdVerificationGroupComponent,
    DashboardIdVerificationDetailsComponent,
    CountersignOverviewComponent,
    EvidenceCheckComponent,
    EditEvidenceCheckComponent,
    SwitchComponent,
    FileUploadComponent,
    EditableTextInputboxComponent
  ],

  imports: [
    ReactiveFormsModule,
    AdminRoutingModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    SelectModule,
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    GenericTableModule,
    ColumnSettingsModule,
    DragulaModule,
    MyDatePickerModule,
    SharedModule,
    ToastyModule.forRoot()
  ],
  entryComponents: [
    DashboardDfGroupComponent,
    DfGroupComponent,
    PercentBarComponent,
    StatusIconComponent,
    DownloadIconComponent,
    DashboardScreeningDetailsComponent,
    DashboardToolbarAttribute,
    DashboardCheckboxAttribute,
    CheckboxAttribute,
    DashboardConfirmationAttribute,
    ConfirmationAttribute,
    DashboardDateAttribute,
    DateAttribute,
    DashboardEmailAttribute,
    EmailAttribute,
    DashboardFileuploadAttribute,
    FileuploadAttribute,
    DashboardRadioAttribute,
    RadioAttribute,
    DashboardSelectAttribute,
    SelectAttribute,
    DashboardSignatureAttribute,
    SignatureAttribute,
    DashboardStringAttribute,
    StringAttribute,
    DashboardTelAttribute,
    TelAttribute,
    DashboardTextareaAttribute,
    TextareaAttribute,
    IdentityVerificationAttribute,
    DashboardIdentityVerificationAttribute,
    OptionsDropdownComponent,
    DashboardIdVerificationDetailsComponent,
  ],
  exports: [],
  providers: [
    CanAccessGuard,
    Title,
    UserAuth,
    HttpService,
    UserService,
    CandidateService,
    CandidateScreeningService,
    CountryService,
    CompanyService,
    ReferenceFormService,
    ScreeningService,
    TableService,
    DynamicFormService,
    YotiIdentityVerificationService,
    YotiDigitalIdService,
    ScreeningOrderApi,
    WindowRefService,
    DbsService
  ]
})

export class AdminModule {

}
