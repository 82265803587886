import {Component, Input} from "@angular/core";
import {GtCustomComponent, GtRow} from "@angular-generic-table/core";

@Component({
  moduleId: module.id,
  selector: 'xavier-percent-bar',
  templateUrl: 'percent-bar.component.html',
  styleUrls: ['percent-bar.stylesheet.sass']
})

export class PercentBarComponent extends GtCustomComponent<GtRow> {

  @Input() public row;

  constructor() {
    super();
  }

}
