<ng-container *ngIf="!hidden">
  <div class="form-group file-uploader-wrapper" [formGroup]="formGroup">
    <div>
      <label [for]="formGroupId+'-'+data.id">{{data.attributeName}}</label>
      <span *ngIf="data.required" class="required">&bull;</span>
      <div *ngIf="data.informationVisible === null || !data.informationVisible"  class="xavier-popover-info">
        <i *ngIf="data.information !== ''"
           class="material-icons"
           tooltip="{{data.information}}"
           placement="auto"
           containerClass="xavier-popover-container">&#xE8FD;</i>
      </div>
      <ng-container *ngIf="data.informationVisible !== null && data.informationVisible">
        <span class="information-always-visible">{{data.information}}</span>
      </ng-container>
    </div>
    <div [id]="'fileLoaderDrop'+formGroupId+'-'+data.id">
      <input type="file" [id]="'fileLoader'+formGroupId+'-'+data.id" (change)="fileChangeEvent($event, formGroupId+'-'+data.id)"
             [formControlName]="formGroupId+'-'+data.id" accept="image/*,application/pdf"/>
      <div 
                                                         (dragover)="dragFileOverStart('fileDropArea_'+formGroupId+'-'+data.id); $event.preventDefault();" 
                                                         (dragleave)="dragFileOverEnd('fileDropArea_'+formGroupId+'-'+data.id); $event.preventDefault();" 
                                                         (drop)="dragFileAccepted($event, formGroupId+'-'+data.id); $event.preventDefault();" 
                                                         [ngClass]="errorClass" 
                                                         class="xavier-file-uploader-drop-zone" 
                                                         [id]="'fileDropArea_'+formGroupId+'-'+data.id"
                                                         (click)="openDialog('fileLoader'+formGroupId+'-'+data.id)">
        <i class="material-icons upload">&#xE2C6;</i>
        <span class="d-none d-xl-block">Drag & Drop or Browse to upload</span>
        <span class="d-xl-none">Tap to upload</span>
      </div>
      <div class="form-group col-12 uploader-small-text">
        Accepted formats: PNG, JPG, PDF. Max size 25 MB
      </div>
    </div>
  </div>
  <xavier-error-message [control]="formControl"></xavier-error-message>
  <div class="returned-comment">{{commentMsg}}</div>
  <div class="form-group col-12 visibility-none" [id]="'fileLoading'+formGroupId+'-'+data.id">
    <div class="xavier-file-uploader-loading-wrapper">
      <div class="spinner-container"><div class="spinner-wrap"></div></div>
      <div class="info">
        <span>{{fileName}}</span>
        <div *ngIf="progressVisible">
          <div class="progress-bar">
            <div class="uploaded" [ngStyle]="{'width': progress+'%'}"></div>
          </div>
          <div class="percent">{{progress}}% - Uploading</div>
        </div>
      </div>
      <div class="delete-btn" (click)="deleteDocument(formGroupId+'-'+data.id)" *ngIf="formControl.enabled">
        <i class="material-icons delete-icon">&#xE872;</i>
      </div>
    </div>
  </div>
  <div class="form-group col-12 visibility-none" [id]="'fileUploaded'+formGroupId+'-'+data.id">
    <div class="xavier-file-uploader-uploaded-wrapper">
      <div class="preview" [id]="'fileUploadedPreview'+formGroupId+'-'+data.id"></div>
      <div class="info">
        <span>{{fileName}}</span>
      </div>
      <button type="button" class="btn delete-btn" [id]="'deleteButton_'+formGroupId+'-'+data.id" (click)="deleteDocument(formGroupId+'-'+data.id)" *ngIf="formControl.enabled">
        <i class="material-icons delete-icon">&#xE872;</i>
      </button>
    </div>
  </div>
</ng-container>

