import {Component, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {DatePipe} from '@angular/common'
import {FormBuilder} from "@angular/forms";
import {Subject} from "rxjs";
import {EditClientService} from "../../services/edit-client.service";
import {CompanyService} from "../../services/company.service";
import {clientPropertiesEditPanels} from '../../../../data/variables.data';
import {
  getDocumentLabel,
  getEntityLabel,
  getEventLogActivityTypeLabel, getRetentionLengthLabel,
  getSaasClientLabel,
  getSecurityQuestionLabel,
  getWeeklySummaryLabel
} from '../../../utils/functions.utils';
import {environment} from "../../../../environments/environment";
import {ErrorMessageComponent} from "../../../shared-cmps/error-message/error-message.component";
import {UserPermission} from "../../model/permissions.model";
import {UserAuth} from "../../../../core/userAuth.core";
import {ToastyService} from "ng2-toasty";
import {fadeIn} from "../../../../data/animations.data";
import {isNullOrUndefined} from "util";
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'xavier-client-properties',
  templateUrl: 'client-properties.component.html',
  styleUrls: ['client-properties.stylesheet.sass'],
  entryComponents: [ErrorMessageComponent],
  animations: [fadeIn]
})

export class ClientPropertiesComponent implements OnInit, OnDestroy {

  @Input() public params;
  @Input() public clientData;
  @Input() public eventLogLoading: boolean = true;

  public selectedPanel;
  public eventLog;
  public properties = clientPropertiesEditPanels;

  private eventLogSub;
  private panelSub;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private companyService: CompanyService, private editClientService: EditClientService, private fb: FormBuilder,
              private renderer: Renderer2, private datepipe: DatePipe, private toastyService: ToastyService,
              private userAuth: UserAuth) {
    this.panelSub = this.editClientService.selectedPanel$.subscribe(
      panel => {
      this.selectedPanel = panel;
      if (this.selectedPanel === 'divisions' && !UserPermission.isAllowed(['DIVISION_EDIT_ALL', 'DIVISION_EDIT_COMPANY'], this.userAuth.getUser().permissionList)) {
        setTimeout(() => {
          let tables = document.getElementsByTagName('table');
          tables[0].classList.add('tr-no-pointer');
        }, 0);
      }
    });

    this.eventLogSub = this.companyService.eventLogEntry$.takeUntil(this.ngUnsubscribe).subscribe(
      entry => {
        let eventPanel = document.getElementById('eventLogPanel');
        let newEntry = document.createElement('div');
        newEntry.classList.add('row');
        let executor = document.createElement('div');
        executor.classList.add('col-8');
        executor.classList.add('executor');
        executor.innerText = entry.executorName + ', ' + entry.executorRole;
        let timestamp = document.createElement('div');
        timestamp.classList.add('col-4');
        timestamp.classList.add('timestamp');
        timestamp.classList.add('text-right');
        timestamp.innerText = this.datepipe.transform(new Date(), 'dd MMM yyyy, HH:mm');
        let activity = document.createElement('div');
        activity.classList.add('col-12');
        activity.classList.add('activity');
        activity.innerText = getEventLogActivityTypeLabel(entry.activityType);
        if (entry.details !== '')
          activity.innerText += ' - ' + entry.details;

        newEntry.appendChild(executor);
        newEntry.appendChild(timestamp);
        newEntry.appendChild(activity);

        this.renderer.insertBefore(eventPanel, newEntry, eventPanel.firstChild);
      });
  }

  ngOnInit() {
    this.eventLogSub = this.companyService.getEventLog(this.clientData.id).subscribe(eventLog => {
      if (!isNullOrUndefined(eventLog.items)) {
        this.eventLog = eventLog.items;
        this.eventLogLoading = false;
      }
    });
  }

  ngOnDestroy() {
    this.panelSub.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public getCheckParams(id: string, signatory: number) {
    let params = {
      clientId: this.clientData.id,
      status: '',
      propertySignature: id,
      signatoryId: this.clientData.signatories[signatory].id,
      signatoryArrayIndex: signatory
    };
    switch (id) {
      case 'identityStatus':
        params.status = this.clientData.signatories[signatory].identityStatus;
        break;
      case 'moneyLaunderingStatus':
        params.status = this.clientData.signatories[signatory].moneyLaunderingStatus;
        break;
      default:
        params.status = '';
        break;
    }
    return params;
  }

  public isSimpleTextField(id: string): boolean {
    let isSimple;
    switch (id) {
      case 'identification':
      case 'identityStatus':
      case 'moneyLaunderingStatus':
        isSimple = false;
        break;
      default:
        isSimple = true;
        break;
    }
    return isSimple;
  }

  public isACheck(id: string): boolean {
    let isACheck;
    switch (id) {
      case 'identityStatus':
      case 'moneyLaunderingStatus':
        isACheck = true;
        break;
      default:
        isACheck = false;
        break;
    }
    return isACheck;
  }

  public getValueFromProperty(id: string, signatory?: number): string {
    let value;
    switch(id) {
      // details
      case 'companyName':
        value = this.clientData.companyName;
        break;
      case 'firstName':
        value = this.clientData.manager.firstName;
        break;
      case 'lastName':
        value = this.clientData.manager.lastName;
        break;
      case 'entityType':
        value = getEntityLabel(this.clientData.entityType);
        break;
      case 'vat':
        value = this.clientData.vat;
        break;
      case 'postcode':
        value = this.clientData.address.postcode;
        break;
      case 'addressLine1':
        value = this.clientData.address.addressLine1;
        break;
      case 'addressLine2':
        value = this.clientData.address.addressLine2;
        break;
      case 'city':
        value = this.clientData.address.city;
        break;
      case 'officePhone':
        value = this.clientData.officePhone;
        break;
      case 'mobilePhone':
        value = this.clientData.mobilePhone;
        break;
      case 'email':
        value = this.clientData.manager.email;
        break;
      // signatories
      case 'signatoryFirstName':
        value = this.clientData.signatories[signatory].firstName;
        break;
      case 'signatoryLastName':
        value = this.clientData.signatories[signatory].lastName;
        break;
      case 'jobTitle':
        value = this.clientData.signatories[signatory].jobTitle;
        break;
      case 'identification':
        value = getDocumentLabel(this.clientData.signatories[signatory].documentType);
        break;
      case 'signatoryPostcode':
        value = this.clientData.signatories[signatory].address.postcode;
        break;
      case 'signatoryAddressLine1':
        value = this.clientData.signatories[signatory].address.addressLine1;
        break;
      case 'signatoryAddressLine2':
        value = this.clientData.signatories[signatory].address.addressLine2;
        break;
      case 'signatoryCity':
        value = this.clientData.signatories[signatory].address.city;
        break;
      // security
      case 'googleToken':
        value = this.clientData.googleToken ? "GSuite" : "Email";
        break;
      case 'securityQuestion':
        value = getSecurityQuestionLabel(this.clientData.manager.securityQuestion);
        break;
      case 'securityAnswer':
        value = this.clientData.manager.securityAnswer;
        break;
      // settings
      case 'discountPercentage':
        value = this.clientData.discountPercentage;
        break;
      case 'minimumPrice':
        value = this.clientData.minimumPrice;
        break;
      case 'retentionLength':
        value = getRetentionLengthLabel(this.clientData.retentionLength);
        break;
      case 'summaryEmailDuration':
        value = getWeeklySummaryLabel(this.clientData.summaryEmailDuration);
        break;
      case 'saasClient':
        value = getSaasClientLabel(this.clientData.saasClient);
        break;
      default:
        value = '';
        break;
    }
    return value;
  }

  public getDocumentSrcFromProperty(id: string, signatory?: number, pdfIconIfNull?: boolean): string {
    if (_.endsWith(this.clientData.signatories[signatory].idDocument.name, '.pdf') && pdfIconIfNull)
      return 'assets/img/pdf-icon.jpg';
    else
      return environment.apiUrl + "/gcs/" + this.clientData.signatories[signatory].idDocument.folder + "/"
      + this.clientData.signatories[signatory].idDocument.name;
  }

  public createFormattedTimestamp() {
    let d = new Date();
    return d.getFullYear() + '-' + _.padStart((d.getMonth() + 1).toString(), 2, '0') + '-'
      + _.padStart(d.getUTCDate().toString(), 2, '0') + 'T'+ _.padStart(d.getUTCHours().toString(), 2, '0') + ':'
      + _.padStart(d.getMinutes().toString(), 2, '0') + ':' + _.padStart(d.getSeconds().toString(), 2, '0') + '.'
      + _.padStart(d.getMilliseconds().toString(), 3, '0') + 'Z';
  }

}
