<ng2-toasty [position]="'top-center'" class="xavier-toasty"></ng2-toasty>
<div class="xavier-steps-wrapper container">
  <div *ngIf="loading" class="xavier-center-page" [@fadeIn]>
    <div class="spinner-container not-button"><div class="spinner-wrap"></div></div>
  </div>
  <div *ngIf="submitForm" class="row" [@fadeIn]>
    <div class="col-sm-4">
      <h1>Hi {{firstName}},</h1>
      <h3>Complete your account for {{companyName}}.</h3>
      <p>{{message}}</p>
    </div>
    <div class="col-sm-8">
      <form [formGroup]="registrationData" novalidate (ngSubmit)="onSubmit(registrationData)">
        <input type="text" id="username" name="username" autocomplete="username" [value]="email" style="display: none;">

        <div class="row password-wrapper">
          <div class="col-xs-12 col-xl-3 header">
            Password
          </div>
          <div class="col-xs-12 col-xl-1 password-spacer"></div>
          <div class="col-xs-12 col-xl-8">
            <div class="row" formGroupName="manager">
              <div class="form-group col-12 xavier-input-text">
                <label for="password">Enter Password</label>
                <input type="password" class="form-control" id="password" formControlName="password" placeholder="Enter Password" autocomplete="new-password" validateOnBlur [validateFormControl]="registrationData.get('manager')">
                <xavier-error-message [control]="registrationData.get('manager.password')" [passwordField]="true"></xavier-error-message>
                <xavier-password-strength [control]="registrationData.get('manager.password')"></xavier-password-strength>
              </div>
              <div class="form-group col-12 xavier-input-text">
                <label for="passwordConfirm">Re-Enter Password</label>
                <input type="password" class="form-control" id="passwordConfirm" formControlName="passwordConfirm" placeholder="Re-Enter Password" autocomplete="new-password" validateOnBlur [validateFormControl]="registrationData.get('manager')">
                <xavier-error-message [control]="registrationData.get('manager')"></xavier-error-message>
              </div>
            </div>
          </div>
        </div>
        <div class="row password-wrapper">
          <div class="col-xs-12 col-xl-3 header">
            Security Question
          </div>
          <div class="col-xs-12 col-xl-1 password-spacer"></div>
          <div class="col-xs-12 col-xl-8">
            <div class="row">
              <div class="form-group col-xs-12 col-xl-12 xavier-dropdown-wrapper">
                <i class="material-icons dropdown-caret">&#xE5C5;</i>
                <label for="securityQuestion">Secret Question</label>
                <select class="form-control xavier-dropdown" id="securityQuestion" formControlName="securityQuestion">
                  <option value=null disabled selected>Please Select</option>
                  <option *ngFor="let question of securityQuestions" [value]="question.id">{{question.label}}</option>
                </select>
              </div>
              <div class="form-group col-xs-12 col-xl-12 xavier-input-text">
                <label for="securityAnswer">Answer</label>
                <input type="text" class="form-control" id="securityAnswer" formControlName="securityAnswer" placeholder="Answer">
                <xavier-error-message [control]="registrationData.get('securityAnswer')"></xavier-error-message>
              </div>
            </div>
          </div>
        </div>
        <div class="row buttons-margin">
          <div class="col-xs-12 col-xl-3"></div>
          <div class="col-xs-12 col-xl-1 password-spacer"></div>
          <div class="col-xs-12 col-xl-2">
            <button type="submit" class="btn btn-block xavier-button continue-step" id="submitButton" [disabled]="disabled">
              <span>Submit</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="wrongToken" class="row xavier-wrong-token-wrapper" [@fadeIn]>
    <div class="col-12">
      <div class="header">
        Your Token has expired or has already been used.
        <span class="description">Go to Login page to login to Xavier.</span>
      </div>
      <div class="col-sm-12 col-xl-3 login-col">
        <button type="button" class="btn btn-block xavier-button continue-step" (click)="onLogin()">
          <span>Login</span>
        </button>
      </div>
    </div>
  </div>
</div>
