import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EmbeddedViewRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {DynamicFormDirective} from "../../../../directives/dynamic-form.directive";
import {DynamicFormService} from "../../../services/dynamic-form.service";
import {isNullOrUndefined} from "util";
import {fadeIn} from "../../../../../data/animations.data";

@Component({
  moduleId: module.id,
  selector: 'xavier-dashboard-df-group',
  templateUrl: 'dashboard-df-group.component.html',
  styleUrls: ['dashboard-df-group.stylesheet.sass'],
  animations: [fadeIn]
})

export class DashboardDfGroupComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {

  @ViewChild(DynamicFormDirective) dnForm: DynamicFormDirective;

  @Input() public group: any;
  @Input() public checkId: any;
  @Input() public preview: boolean;
  @Input() public dashboard: boolean;
  @Input() public reference: boolean = false;
  @Input() public screeningStatus: string;
  @Input() public isScreeningCompleted: boolean;
  @Input() public repeatedParentPrefix: any;
  @Input() public parentNodeId: string = null;

  public formGroup;
  public formGroupId: string;
  public isVisible: boolean = false;
  public deleteGroupIsVisible: boolean = true;

  constructor(private dynamicFormService: DynamicFormService, public cdr: ChangeDetectorRef,
              private resolver: ComponentFactoryResolver, private injector: Injector,
              private appRef: ApplicationRef, private _eref: ElementRef) {

  }

  ngOnInit() {
    this.formGroupId = this.checkId + '-' + this.group.id;
    if (this.group.repeated || this.group.level > 2) {
      if (this.repeatedParentPrefix === undefined)
        this.repeatedParentPrefix = this.dynamicFormService.getFormGroupRepeatedIndex(this.formGroupId) + '#';
      this.formGroupId = this.repeatedParentPrefix + this.formGroupId;
    }
    if (!this.group.repeated && this.repeatedParentPrefix !== undefined)
      this.formGroupId = this.repeatedParentPrefix + this.checkId + '-' + this.group.id;

    this.dynamicFormService.setGroupComponentInstance(this.formGroupId, this);
    this.newFormGroup();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    this.deleteGroupIsVisible = this.dynamicFormService.countFormGroupRepeatedElements(this.formGroupId, this.group.level) > 1;
  }

  ngOnChanges() {
    this.isVisible = !this.group.conditional || this.isVisible;
  }

  ngOnDestroy() {
    if ((this.group.repeated || this.group.level > 2) && this.formGroupId.indexOf('#')) {
      let nonRepeatedIndex = this.formGroupId.split('#')[1];
      this.dynamicFormService.removeRepeatedFormGroups(nonRepeatedIndex);
      this.dynamicFormService.removeRepeatedDateConditionValues(nonRepeatedIndex);
      this.dynamicFormService.removeRepeatedGroupComponentsInstances(nonRepeatedIndex);
    } else {
      this.dynamicFormService.removeFormGroup(this.formGroupId);
      this.dynamicFormService.removeDateConditionKey(this.formGroupId);
      this.dynamicFormService.removeGroupComponentInstance(this.formGroupId);
    }
  }

  public mouseEnter(elementId) {
    this.dynamicFormService.showToolbar(elementId);
  }

  public mouseLeave() {
    this.dynamicFormService.showToolbar('');
  }

  public newFormGroup() {
    let formGroup: FormGroup = new FormGroup({});
    this.dynamicFormService.setFormGroup(this.formGroupId, formGroup);

    this.formGroup = formGroup;
  }

  public visibilityCheck(override?: boolean) {
    if (override !== undefined) {
      this.isVisible = override;
      this.cdr.detectChanges();
      return override;
    }
    return this.isVisible;
  }

  public deleteDfGroup() {
    if (this.dynamicFormService.countFormGroupRepeatedElements(this.formGroupId) > 1) {
      this.dynamicFormService.removeFormGroup(this.formGroupId);
      this.dynamicFormService.removeGroupComponentInstance(this.formGroupId);
      this._eref.nativeElement.parentElement.removeChild(this._eref.nativeElement);
    }
  }

  public addDfGroup(parentNodeId?: string, repeatedParentPrefix?: string) {
    const componentRef = this.resolver
      .resolveComponentFactory(DashboardDfGroupComponent)
      .create(this.injector);
    componentRef.instance.group = this.group;
    componentRef.instance.checkId = this.checkId;
    componentRef.instance.isVisible = true;
    componentRef.instance.dashboard = this.dashboard;
    componentRef.instance.screeningStatus = this.screeningStatus;
    componentRef.instance.isScreeningCompleted = this.isScreeningCompleted;
    if (!isNullOrUndefined(parentNodeId))
      componentRef.instance.parentNodeId = parentNodeId;
    else if (this.group.level > 1)
      componentRef.instance.parentNodeId = this._eref.nativeElement.parentElement.id;
    if (!isNullOrUndefined(repeatedParentPrefix))
      componentRef.instance.repeatedParentPrefix = repeatedParentPrefix;

    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    this._eref.nativeElement.parentElement.appendChild(domElem);

    return componentRef.instance;
  }

}
