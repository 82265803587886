<div class="xavier-steps progression">
  Step {{step}}/{{maxSteps}}
</div>
<div class="xavier-steps description1">
  {{stepDescriptionLine1}}
</div>
<div class="xavier-steps description2">
  {{stepDescriptionLine2}}
</div>
<div class="xavier-steps visual">
  <ng-container *ngFor="let i of stepsArray">
    <div class="box" [ngClass]="{'full': step >= i, 'empty': step < i, 'step-container': maxSteps === 2}"></div>
  </ng-container>
</div>
<div *ngIf="displayStatusMsg" class="xavier-steps status-msg" [@fadeIn]>
  <h2>Return Reason</h2>
  <i class="material-icons">&#xE88E;</i> <span>{{statusMsg}}</span>
</div>
