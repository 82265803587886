import {HttpService} from '../modules/shared-services/http.service';
import {ScreeningOrderDTO} from '../model/liveid/order/screening-order.dto';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {PaginatedListDTO} from '../model/paginated-list.dto';

const API_NAME = 'screeningOrder';

@Injectable()
export class ScreeningOrderApi {

  constructor(private httpService: HttpService) { }

  public findAll(pageSize: number, pageNum: number, sortBy: string, sortOrder: string, query: string, completed: boolean): Observable<PaginatedListDTO<ScreeningOrderDTO>> {
    const params = [];

    params.push({ 'pageSize': pageSize });
    params.push({ 'pageNum': pageNum.toString() });
    params.push({ completed: completed });

    if (sortBy)
      params.push({ 'sortBy': sortBy });
    if (sortOrder)
      params.push({ 'sortOrder': sortOrder });
    if (query)
      params.push({ 'query': query });

    return this.httpService.get(`/_ah/api/${API_NAME}/v1`, 'findAll', params);
  }
}
