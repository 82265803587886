import {Component, Input, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild, ContentChild, ElementRef} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {HttpClient, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import {DashboardDynamicFormComponent} from '../dashboard-dynamic-form/dashboard-dynamic-form.component';
import {DynamicFormService} from '../../services/dynamic-form.service';
import {CandidateScreeningService} from '../../services/candidate-screening.service';
import {forkJoin} from 'rxjs/observable/forkJoin';
import {CandidateService} from '../../services/candidate.service';
import {UserPermission} from '../../model/permissions.model';
import {UserAuth} from '../../../../core/userAuth.core';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {
  candidateScreeningToasterOptions,
  candidatesOverviewDashboardFilters, candidatesToasterOptions,
  noticeToasterOptions
} from '../../../../data/variables.data';
import {environment} from '../../../../environments/environment';
import {ToastyService} from 'ng2-toasty';
import {IMyDpOptions} from 'mydatepicker';
import {fadeIn} from '../../../../data/animations.data';
import {
  addToaster,
  getEventLogActivityTypeLabel,
  getToasterMessagesLabel,
  setAsTouched
} from '../../../utils/functions.utils';
import {isNullOrUndefined} from 'util';
import * as _ from 'lodash';
import { DropdownComponentItemSourceFn } from '../../../shared-cmps/dropdown/dropdown.component';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'xavier-screening-dashboard',
  templateUrl: 'screening-dashboard.component.html',
  styleUrls: ['screening-dashboard.stylesheet.sass'],
  animations: [fadeIn]
})

export class ScreeningDashboardComponent implements OnInit, OnDestroy {

  @ViewChild('toolbarModal', { static: true }) toolbarModal: TemplateRef<any>;

  @ViewChild('confirmDeleteModal', { static: true }) confirmDeleteModal: TemplateRef<any>;
  @ViewChild('emailCandidateModal', { static: true }) emailCandidateModal: TemplateRef<any>;
  @ViewChild('terminateScreeningModal', { static: true }) terminateScreeningModal: TemplateRef<any>;
  @ViewChild('changeScreeningModal', { static: true }) changeScreeningModal: TemplateRef<any>;
  @ViewChild('deleteCandidateModal', { static: true }) deleteCandidateModal: TemplateRef<any>;
  @ViewChild('completeScreeningModal', { static: true }) completeScreeningModal: TemplateRef<any>;
  @ViewChild('dashboardDynamicForm', { static: false }) dashboardDynamicForm: DashboardDynamicFormComponent;
  @ViewChild('dropdown') dropdown;

  @Input() loading = true;
  @Input() public dynamicForm: any;
  @Input() public dynamicFormData: any;
  @Input() public dynamicFormComments: any;
  @Input() public screeningStatus: any = {
    statusBorder: '',
    statusFill: '',
    statusColor: '',
    statusSize: '',
    statusType: ''
  };

  public id;
  public screening;
  public screeningData;
  public details;
  public percCompletedChecks;
  public percCompletedSLA;
  public statusValue;
  public startDisabled = true;
  public startVisible = false;
  public returnDisabled = true;
  public returnVisible = false;
  public completeDisabled = true;
  public completeVisible = false;
  public viewCertificateVisible = false;
  public viewExportScreening = false;
  public viewCertificateDisabled = false;
  public downloadReportVisible = false;
  public downloadReportDisabled = false;
  public emailCandidateConfirmDisabled = false;
  public terminateScreeningConfirmDisabled = false;
  public changeScreeningConfirmDisabled = false;
  public completeScreeningConfirmDisabled = false;
  public emailCandidateVisible = false;
  public runCreditsafeVisible = false;
  public reactivateScreeningVisible = false;
  public terminateScreeningVisible = false;
  public changeScreeningVisible = false;
  public downloadDeclarationVisible = false;
  public downloadDeclarationHref: string;
  public downloadDeclarationHrefDisabled = '';
  public downloadInternationalDeclarationVisible = false;
  public downloadInternationalDeclarationHref: string;
  public downloadInternationalDeclarationVisible2 = false;
  public downloadInternationalDeclarationHref2: string;
  public deleteCandidateVisible = false;
  public loginAsCandidateVisible = false;
  public resendInviteVisible = false;
  public modalRef: BsModalRef;
  public modalData: any;
  public deleteModalData: any;
  public toolbarModalData: FormGroup;
  public emailCandidateModalData: FormGroup;
  public terminateScreeningModalData: FormGroup;
  public changeScreeningModalData: FormGroup;
  public completeScreeningModalData: FormGroup;
  public toolbarModalSubmitButtonDisabled = false;
  public datePickerOptions: IMyDpOptions = {
    dateFormat: 'dd mmm yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    selectionTxtFontSize: '16px',
    showClearDateBtn: false
  };

  public isStartDialogVisible = false;
  public isRunCreditsafeVisible = false;
  public noUkAddresses: boolean;

  private modalSub: any;
  private screeningStatusSub: any;
  private canCompleteScreeningSub: any;
  private checksCompletedSub: any;
  private checksReactivatedSub: any;
  private deleteModalSub: any;
  private routeSub: any;
  private startRouteUrl: string;
  private emailAttachment: any = null;
  private modalConfig = {
    animated: true,
  };

  public screeningItemSource = [];
  public displayValues: boolean = false;
  public displayLoader: boolean = false;
  public currentPos: number = -1;

  constructor(private candidateScreeningService: CandidateScreeningService, private toastyService: ToastyService,
              private router: Router, private activatedRoute: ActivatedRoute, private modalService: BsModalService,
              private dynamicFormService: DynamicFormService, private fb: FormBuilder, private renderer: Renderer2,
              private datepipe: DatePipe, private candidateService: CandidateService, private userAuth: UserAuth,
              private http: HttpClient, private _eref: ElementRef,) {
    this.screeningStatusSub = this.dynamicFormService.screeningStatus$.subscribe(newStatus => {
      this.screening.screeningStatus = newStatus;
      _.forEach(candidatesOverviewDashboardFilters, ( function(status) {
        if (status.name === this.screening.screeningStatus) {
          this.statusValue = status.value;
          this.screeningStatus.statusType = status.icon;
          this.screeningStatus.statusColor = status.color;
          this.screeningStatus.statusBorder = status.border;
          this.screeningStatus.statusFill = status.fill;
          this.screeningStatus.statusSize = status.size;
          return;
        }
      }).bind(this));
    });
    this.canCompleteScreeningSub = this.dynamicFormService.canCompleteScreening$.subscribe(value => {
      this.completeDisabled = !value;
      if (value)
        this.completeVisible = true;
    });
    this.checksCompletedSub = this.dynamicFormService.checksCompleted$.subscribe(value => {
      if (value)
        this.screening.checksCompleted += 1;
      else
        this.screening.checksCompleted -= 1;
    });
    this.modalSub = this.dynamicFormService.toolbarModal$.subscribe(modalData => {
      this.openModal(modalData);
    });
    this.deleteModalSub = this.dynamicFormService.confirmDeleteModal$.subscribe(deleteModalData => {
      this.openDeleteModal(deleteModalData);
    });
    this.routeSub = this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd))
        return;
      window.scrollTo(0, 0);
    });
  }

  ngOnInit() {
    this.toolbarModalData = this.fb.group({
      modalTextArea: ['', Validators.required]
    });
    this.startRouteUrl = (this.activatedRoute.snapshot.queryParams.hasOwnProperty('startRouteUrl')) ?
      this.activatedRoute.snapshot.queryParams['startRouteUrl'] :
      'dashboard/candidates-overview';
    this.emailCandidateModalData = this.fb.group({
      emailSubject: ['', Validators.required],
      emailBody: ['', Validators.required],
      emailAttachment: ['']
    });
    this.terminateScreeningModalData = this.fb.group({
      additionalTerminateCost: ['', Validators.required],
      closingTerminateComment: ['', Validators.required]
    });
    this.changeScreeningModalData = this.fb.group({
      newScreeningId: [null],
      newScreeningName: [null, Validators.required],
      candidateId: [null],
    });
    this.completeScreeningModalData = this.fb.group({
      additionalCost: ['', Validators.required],
      closingComment: ['', Validators.required]
    });

    if (UserPermission.isAllowed(['CANDIDATE_EMAIL'], this.userAuth.getUser().permissionList))
      this.emailCandidateVisible = true;
    if (UserPermission.isAllowed(['CANDIDATE_SCREENING_EDIT'], this.userAuth.getUser().permissionList)) {
      this.terminateScreeningVisible = true;
      this.downloadDeclarationVisible = true;
      this.changeScreeningVisible = true;
    }
    if (UserPermission.isAllowed(['CANDIDATE_SCREENING_DELETE'], this.userAuth.getUser().permissionList))
      this.deleteCandidateVisible = true;

    this.getData();
  }

  ngOnDestroy() {
    this.modalSub.unsubscribe();
    this.screeningStatusSub.unsubscribe();
    this.canCompleteScreeningSub.unsubscribe();
    this.checksCompletedSub.unsubscribe();
    this.deleteModalSub.unsubscribe();
    this.routeSub.unsubscribe();
  }

  getData() {
    forkJoin([
      this.candidateScreeningService.getCandidateScreening(this.id),
      this.candidateScreeningService.getCandidateScreeningData(this.id),
      this.candidateScreeningService.getCandidateDetails(this.id)
    ]).subscribe(results => {
      this.screening = <any>results[0];
      this.screeningData = <any>results[1];
      this.details = <any>results[2];

      this.noUkAddresses = this.screening.noUkAddresses;
      const states = ["CANDIDATE_SAVED", "WITH_CANDIDATE"];
      this.runCreditsafeVisible = !states.includes(this.screening.screeningStatus) && UserPermission.isAllowed(['CANDIDATE_EMAIL'], this.userAuth.getUser().permissionList);

      if (!isNullOrUndefined(this.screening['candidate'])) {
        this.resendInviteVisible = this.screening['candidate'].activated;
        if (UserPermission.isAllowed(['CANDIDATE_RESEND_INVITE'], this.userAuth.getUser().permissionList))
          this.resendInviteVisible = true;
        this.percCompletedChecks = (this.screening.checksCompleted / this.screening.checksCount) * 100;
        this.percCompletedSLA = 0;
        if (this.screening.screeningStatus !== 'WITH_CANDIDATE' || this.screening.remainingSLA)
          this.percCompletedSLA = Math.abs((this.screening.remainingSLA / this.screening.screeningSLA) - 1) * 100;

        _.forEach(candidatesOverviewDashboardFilters, ( function(status) {
          if (status.name === this.screening.screeningStatus) {
            this.statusValue = status.value;
            this.screeningStatus.statusType = status.icon;
            this.screeningStatus.statusColor = status.color;
            this.screeningStatus.statusBorder = status.border;
            this.screeningStatus.statusFill = status.fill;
            this.screeningStatus.statusSize = status.size;
            return;
          }
        }).bind(this));

        this.dynamicForm = this.screening;
        if (this.screening.declaration.hasOwnProperty('name')) {
          this.downloadDeclarationHref = 'https://' + environment.storageBucket + '/gcs/' +
            this.screening['declaration']['folder'] + '/' + this.screening['declaration']['name'] + '?download=true';
        } else {
          this.downloadDeclarationHrefDisabled = 'disabled';
        }

        if (this.details.hasOwnProperty('declarationInternationalFile')
            && this.details.declarationInternationalFile.hasOwnProperty('name')) {
          this.downloadInternationalDeclarationVisible = true;
          this.downloadInternationalDeclarationHref = 'https://' + environment.storageBucket + '/gcs/' +
            this.details['declarationInternationalFile']['folder']
            + '/' + this.details['declarationInternationalFile']['name'] + '?download=true';
        }

        if (this.details.hasOwnProperty('declarationInternationalFile2')
            && this.details.declarationInternationalFile2.hasOwnProperty('name')) {
          this.downloadInternationalDeclarationVisible2 = true;
          this.downloadInternationalDeclarationHref2 = 'https://' + environment.storageBucket + '/gcs/' +
            this.details['declarationInternationalFile2']['folder']
            + '/' + this.details['declarationInternationalFile2']['name'] + '?download=true';
        }

        if (!isNullOrUndefined(this.screeningData.referencesValues)) {
          this.dynamicFormData = this.mergeObjects(this.screeningData.checksValues, this.screeningData.referencesValues);
        } else {
          this.dynamicFormData = this.screeningData.checksValues;
        }
        this.dynamicFormComments = this.screeningData.comments;

        if (this.screening.screeningStatus === 'WITH_CANDIDATE')
          this.startVisible = true;

        if (this.screening.screeningStatus === 'CANDIDATE_SAVED') {
          this.startDisabled = false;
          this.startVisible = true;
        }

        if (this.screening.screeningStatus === 'IN_PROGRESS') {
          this.completeDisabled = true;
          this.completeVisible = true;
        }

        let alreadyCompleted = false;
        if (this.screening.screeningStatus === 'PASSED' ||
          this.screening.screeningStatus === 'FLAGGED' ||
          this.screening.screeningStatus === 'ADVERSE' ||
          this.screening.screeningStatus === 'TERMINATED' ||
          this.screening.screeningStatus === 'COMPLETED') {
          alreadyCompleted = true;
          this.completeVisible = false;
          this.viewCertificateVisible = true;
          this.downloadReportVisible = true;
          this.viewExportScreening = true;
          if (UserPermission.isAllowed(['CANDIDATE_SCREENING_EDIT'], this.userAuth.getUser().permissionList))
            this.reactivateScreeningVisible = true;
        }

        if (this.screening.screeningStatus === 'DELAYED') {
          this.completeDisabled = true;
          this.completeVisible = true;
          this.returnDisabled = true;
          this.returnVisible = false;
        }

        if (this.isACheckReturned() && this.screening.screeningStatus !== 'DELAYED' && this.screening.screeningStatus !== 'RETURNED') {
          this.startVisible = false;
          this.returnDisabled = false;
          this.returnVisible = true;
        } else if (this.screening.screeningStatus === 'RETURNED') {
          this.startVisible = true;
          this.startDisabled = true;
        }

        // if all checks are "completed" (passed/flagged/delayed), enable the complete screening button
        let canCompleteScreening = true;
        _.forEach(this.dynamicForm.checks, ( function(check) {
          if (check.checkStatus === 'IN_PROGRESS' ||
            check.checkStatus === 'WITH_CANDIDATE' ||
            check.checkStatus === 'CANDIDATE_SAVED' ||
            check.checkStatus === 'RETURNED') {
            canCompleteScreening = false;
            return false;
          }
        }).bind(this));
        if (this.dynamicForm.hasOwnProperty('references')) {
          _.forEach(this.dynamicForm.references, ( function(reference) {
            if (reference.checkStatus === 'WITH_CANDIDATE') {
              canCompleteScreening = false;
              return false;
            }
          }).bind(this));
        }
        if (canCompleteScreening && !alreadyCompleted) {
          this.completeDisabled = false;
          this.completeVisible = true;
        }

        if (this.screening.screeningStatus === 'TERMINATED')
          this.terminateScreeningVisible = false;

        if (
          this.screening.screeningStatus === "RETURNED" ||
          this.screening.screeningStatus === "WITH_CANDIDATE" ||
          this.screening.screeningStatus === "DELAYED"
        ) {
          this.loginAsCandidateVisible = true;
        }

        this.loading = false;
      }

      if (this.details) {
        this.candidateService.getDivisionBasedInfo(this.details.company.id, this.details.division.id)
          .pipe(
            map(res => res.screenings.filter(res_ => res_.id !== this.screening.screening.id) || [])
          ).subscribe(data => {
            this.screeningItemSource = data;
          });
      }
    });
  }

  onScreeningStarted(result) {
    this.isStartDialogVisible = false;

    this.startDisabled = true;
    this.screening.screeningStatus = 'IN_PROGRESS';
    this.dynamicForm.screening.screeningStatus = this.screening.screeningStatus;
    this.dynamicFormService.screeningStatus(this.screening.screeningStatus);
    _.forEach(candidatesOverviewDashboardFilters, ( function(status) {
      if (status.name === this.screening.screeningStatus) {
        this.statusValue = status.value;
        this.screeningStatus.statusType = status.icon;
        this.screeningStatus.statusColor = status.color;
        this.screeningStatus.statusBorder = status.border;
        this.screeningStatus.statusFill = status.fill;
        this.screeningStatus.statusSize = status.size;
        return;
      }
    }).bind(this));

    this.candidateScreeningService.sendEventLogEntry(result);

    const config = candidateScreeningToasterOptions;
    config.msg = getToasterMessagesLabel('screeningStarted', 'candidateScreening');
    this.toastyService.info(config);

    this.startDisabled = false;
    this.startVisible = false;
    this.completeDisabled = true;
    this.completeVisible = false;

    this.dashboardDynamicForm.checkStatusControlsVisibility();
  }

  startScreening() {
    this.isStartDialogVisible = true;
    return;

    // const startScreening = document.getElementById('startScreening');
    // this.startDisabled = true;
    // const spinnerWrap = document.createElement('div');
    // spinnerWrap.classList.add('spinner-wrap');
    // spinnerWrap.classList.add('button');

    // startScreening.innerHTML = '';
    // startScreening.appendChild(spinnerWrap);

    // this.candidateScreeningService.startScreening(this.id).subscribe(result => {
    //   if (!isNullOrUndefined(result.id)) {
    //     this.screening.screeningStatus = 'IN_PROGRESS';
    //     this.dynamicForm.screening.screeningStatus = this.screening.screeningStatus;
    //     this.dynamicFormService.screeningStatus(this.screening.screeningStatus);
    //     _.forEach(candidatesOverviewDashboardFilters, ( function(status) {
    //       if (status.name === this.screening.screeningStatus) {
    //         this.statusValue = status.value;
    //         this.screeningStatus.statusType = status.icon;
    //         this.screeningStatus.statusColor = status.color;
    //         this.screeningStatus.statusBorder = status.border;
    //         this.screeningStatus.statusFill = status.fill;
    //         this.screeningStatus.statusSize = status.size;
    //         return;
    //       }
    //     }).bind(this));

    //     this.candidateScreeningService.sendEventLogEntry(result);

    //     const config = candidateScreeningToasterOptions;
    //     config.msg = getToasterMessagesLabel('screeningStarted', 'candidateScreening');
    //     this.toastyService.info(config);
    //   }
    // }, err => {
    //   startScreening.innerHTML = '<span>Start Screening</span>';
    //   this.startDisabled = false;
    //   let action = 'serverError';
    //   if (err.status === 403)
    //     action = '403';
    //   addToaster('error', this.toastyService, action, 'errors');
    // }, () => {
    //   startScreening.innerHTML = '<span>Start Screening</span>';
    //   this.startDisabled = false;
    //   this.startVisible = false;
    //   this.completeDisabled = true;
    //   this.completeVisible = false;

    //   this.dashboardDynamicForm.checkStatusControlsVisibility();
    // });
  }

  backToTable() {
    this.router.navigate([this.startRouteUrl]);
  }

  emailCandidate() {
    this.modalRef = this.modalService.show(this.emailCandidateModal, this.modalConfig);
  }

  terminateScreening() {
    if (this.details.hasOwnProperty('priceAdjustment'))
      this.terminateScreeningModalData.get('additionalTerminateCost').setValue(this.details.priceAdjustment);
    if (this.details.hasOwnProperty('closingComment'))
      this.terminateScreeningModalData.get('closingTerminateComment').setValue(this.details.closingComment);
    this.modalRef = this.modalService.show(this.terminateScreeningModal, this.modalConfig);
  }

  changeScreening() {
    this.modalRef = this.modalService.show(this.changeScreeningModal, this.modalConfig);
  }

  deleteCandidate() {
    this.modalRef = this.modalService.show(this.deleteCandidateModal, this.modalConfig);
  }

  reactivateScreening() {
    this.candidateScreeningService.reactivateScreening(this.id).subscribe(result => {
      if (!isNullOrUndefined(result.id))
        location.reload();
    }, err => {
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
    });
  }

  resendInvite() {
    this.candidateService.resendInvite(this.id).subscribe(response => {
      if (!isNullOrUndefined(response.id)) {
        this.candidateScreeningService.sendEventLogEntry(response);
        const config = candidatesToasterOptions;
        config.msg = getToasterMessagesLabel('resend', 'candidates');
        this.toastyService.info(config);
      }
    }, err => {
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
    });
  }

  returnScreening() {
    const returnScreening = document.getElementById('returnScreening');
    this.returnDisabled = true;
    const spinnerWrap = document.createElement('div');
    spinnerWrap.classList.add('spinner-wrap');
    spinnerWrap.classList.add('button');

    returnScreening.innerHTML = '';
    returnScreening.appendChild(spinnerWrap);

    this.candidateScreeningService.returnScreening(this.id).subscribe(result => {
      if (!isNullOrUndefined(result.log) && !isNullOrUndefined(result.newScreeningStatus)) {
        this.candidateScreeningService.sendEventLogEntry(result.log);

        const config = candidateScreeningToasterOptions;
        config.msg = getToasterMessagesLabel('screeningReturned', 'candidateScreening');
        this.toastyService.info(config);

        this.screening.screeningStatus = result.newScreeningStatus;
        this.dynamicForm.screening.screeningStatus = this.screening.screeningStatus;
        _.forEach(candidatesOverviewDashboardFilters, ( function(status) {
          if (status.name === this.screening.screeningStatus) {
            this.statusValue = status.value;
            this.screeningStatus.statusType = status.icon;
            this.screeningStatus.statusColor = status.color;
            this.screeningStatus.statusBorder = status.border;
            this.screeningStatus.statusFill = status.fill;
            this.screeningStatus.statusSize = status.size;
            return;
          }
        }).bind(this));
      }
    }, err => {
      returnScreening.innerHTML = '<span>Return Screening</span>';
      this.returnDisabled = false;
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
    }, () => {
      returnScreening.innerHTML = '<span>Return Screening</span>';
      this.startDisabled = true;
      this.startVisible = false;
      this.completeDisabled = true;
      this.completeVisible = false;
    });
  }

  viewCertificate() {
    const viewCertificate = document.getElementById('viewCertificate');
    this.viewCertificateDisabled = true;
    const spinnerWrap = document.createElement('div');
    spinnerWrap.classList.add('spinner-wrap');
    spinnerWrap.classList.add('button');

    viewCertificate.innerHTML = '';
    viewCertificate.appendChild(spinnerWrap);

    this.candidateScreeningService.viewCertificate(this.id).subscribe(result => {
      if (!isNullOrUndefined(result.size) && result.size > 0) {
        const linkElement = document.createElement('a');
        linkElement.setAttribute('id', 'downloadCertificateLink');
        document.body.appendChild(linkElement);
        linkElement.classList.add('visibility-none');
        const file = new Blob([result], {type: 'application/pdf'});
        let fileName = this.screening.candidate.fullName + ' (' + this.screening.companyName + ') - Screening Certificate.pdf';
        fileName = fileName.trim();
        if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(file, fileName);
        } else {
          const fileURL = window.URL.createObjectURL(file);
          linkElement.href = fileURL;
          linkElement.download = fileName;
          linkElement.click();
          window.URL.revokeObjectURL(fileURL);
          linkElement.parentNode.removeChild(linkElement);
        }
      }
    }, err => {
      viewCertificate.innerHTML = '<span><i class="material-icons">visibility</i> View Certificate</span>';
      this.viewCertificateDisabled = false;
      let action = 'serverError';
      if (err.status === 409)
        action = 'conflictCertificate';
      const config = noticeToasterOptions;
      config.msg = getToasterMessagesLabel(action, 'notices');
      this.toastyService.warning(config);
    }, () => {
      viewCertificate.innerHTML = '<span><i class="material-icons">visibility</i> View Certificate</span>';
      this.viewCertificateDisabled = false;
    });
  }

  exportScreening() {
    this.candidateScreeningService.exportScreening(this.id).subscribe(result => {
      if (!isNullOrUndefined(result.size) && result.size > 0) {
        const linkElement = document.createElement('a');
        linkElement.setAttribute('id', 'downloadReportLink');
        document.body.appendChild(linkElement);
        linkElement.classList.add('visibility-none');
        const file = new Blob([result], {type: 'application/zip'});
        let fileName = this.screening.candidate.fullName + ' (' + this.screening.companyName + ') - Screening Data.zip';
        fileName = fileName.trim();
        if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(file, fileName);
        } else {
          const fileURL = window.URL.createObjectURL(file);
          linkElement.href = fileURL;
          linkElement.download = fileName;
          linkElement.click();
          window.URL.revokeObjectURL(fileURL);
          linkElement.parentNode.removeChild(linkElement);
        }
      }
    }, err => {
      let action = 'serverError';
      if (err.status === 409)
        action = 'conflictExport';
      const config = noticeToasterOptions;
      config.msg = getToasterMessagesLabel(action, 'notices');
      this.toastyService.warning(config);
    }, () => {
    });
  }

  downloadReport() {
    const downloadReport = document.getElementById('downloadReport');
    this.downloadReportDisabled = true;
    const spinnerWrap = document.createElement('div');
    spinnerWrap.classList.add('spinner-wrap');
    spinnerWrap.classList.add('button');

    downloadReport.innerHTML = '';
    downloadReport.appendChild(spinnerWrap);

    this.candidateScreeningService.downloadReport(this.id).subscribe(result => {
      if (!isNullOrUndefined(result.size) && result.size > 0) {
        const linkElement = document.createElement('a');
        linkElement.setAttribute('id', 'downloadReportLink');
        document.body.appendChild(linkElement);
        linkElement.classList.add('visibility-none');
        const file = new Blob([result], {type: 'application/pdf'});
        let fileName = this.screening.candidate.fullName + ' (' + this.screening.companyName + ') - Screening Report.pdf';
        fileName = fileName.trim();
        if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(file, fileName);
        } else {
          const fileURL = window.URL.createObjectURL(file);
          linkElement.href = fileURL;
          linkElement.download = fileName;
          linkElement.click();
          window.URL.revokeObjectURL(fileURL);
          linkElement.parentNode.removeChild(linkElement);
        }
      }
    }, err => {
      downloadReport.innerHTML = '<span><i class="material-icons">file_download</i> Download Report</span>';
      this.downloadReportDisabled = false;
      let action = 'serverError';
      if (err.status === 409)
        action = 'conflictExport';
      const config = noticeToasterOptions;
      config.msg = getToasterMessagesLabel(action, 'notices');
      this.toastyService.warning(config);
    }, () => {
      downloadReport.innerHTML = '<span><i class="material-icons">file_download</i> Download Report</span>';
      this.downloadReportDisabled = false;
    });
  }

  openModal(modalData: any) {
    this.modalData = modalData;

    if (this.modalData.hasOwnProperty('text'))
      this.toolbarModalData.get('modalTextArea').setValue(this.modalData.text);
    if (this.modalData.type !== 'upload') {
      this.modalRef = this.modalService.show(this.toolbarModal, this.modalConfig);

      setTimeout(() => {
        const input: any = document.querySelector(".modal-dialog textarea") || document.querySelector(".modal-dialog input");

        input && input.focus();
      });
    }
  }

  closeModal() {
    this.toolbarModalData.get('modalTextArea').reset('');
    this.modalRef.hide();
  }

  openDeleteModal(modalData: any) {
    this.deleteModalData = modalData;
    this.modalRef = this.modalService.show(this.confirmDeleteModal, this.modalConfig);
  }

  closeDeleteModal() {
    this.toolbarModalData.get('modalTextArea').reset('');
    this.modalRef.hide();
  }

  confirmDeleteComment() {
    this.candidateScreeningService.removeComment(
      this.id,
      this.screening.screening.id,
      this.deleteModalData.commentId)
      .subscribe(response => {
        this.modalRef.hide();
        if (response.hasOwnProperty('log'))
          this.commentResponseHandling(response);
      });
  }

  attachmentChange(event) {
    this.emailCandidateConfirmDisabled = true;
    const submitButton = document.getElementById('emailCandidateConfirm');
    const spinnerWrap = document.createElement('div');
    spinnerWrap.classList.add('spinner-wrap');
    spinnerWrap.classList.add('button');

    submitButton.innerHTML = '';
    submitButton.appendChild(spinnerWrap);

    const headers = new HttpHeaders({ 'Content-Type': event.target.files[0].type });
    const req = new HttpRequest(
      'POST',
      'https://' + environment.storageBucket + '/uploadFile/?filename=' + encodeURIComponent(event.target.files[0].name),
      event.target.files[0],
      {
        headers: headers,
        reportProgress: true,
        withCredentials: true
      }
    );

    this.http.request(req).subscribe(response => {
      if (response instanceof HttpResponse) {
        this.emailAttachment = {
          'name': response.body['name'],
          'folder': response.body['folder']
        };
        this.emailCandidateConfirmDisabled = false;
        submitButton.innerHTML = 'Send Email';
      }
    }, err => {
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
      this.emailCandidateConfirmDisabled = false;
      submitButton.innerHTML = 'Send Email';
    });
  }

  onEmailCandidate({ value, valid }: { value: any, valid: boolean }) {
    if (!valid) {
      setAsTouched(this.emailCandidateModalData);
    } else {
      this.emailCandidateConfirmDisabled = true;
      const submitButton = document.getElementById('emailCandidateConfirm');
      const spinnerWrap = document.createElement('div');
      spinnerWrap.classList.add('spinner-wrap');
      spinnerWrap.classList.add('button');

      submitButton.innerHTML = '';
      submitButton.appendChild(spinnerWrap);
      const body = {
        'subject': value.emailSubject,
        'message': value.emailBody
      };
      if (!isNullOrUndefined(this.emailAttachment))
        body['attachment'] = this.emailAttachment;

      this.candidateService.emailCandidate(this.id, body).subscribe(response => {
        if (!isNullOrUndefined(response.id)) {
          this.emailCandidateConfirmDisabled = false;
          submitButton.innerHTML = 'Send Email';
          this.candidateScreeningService.sendEventLogEntry(response);
          this.modalRef.hide();

          const config = candidateScreeningToasterOptions;
          config.msg = getToasterMessagesLabel('emailSent', 'candidateScreening');
          this.toastyService.info(config);
        }
      }, err => {
        let action = 'serverError';
        if (err.status === 403)
          action = '403';
        addToaster('error', this.toastyService, action, 'errors');
        this.emailCandidateConfirmDisabled = false;
        submitButton.innerHTML = 'Send Email';
      });
    }
  }

  closeEmailModal() {
    this.emailCandidateModalData.get('emailSubject').reset('');
    this.emailCandidateModalData.get('emailBody').reset('');
    this.emailCandidateModalData.get('emailAttachment').reset('');
    this.emailAttachment = null;
    this.modalRef.hide();
  }

  onTerminateScreening({ value, valid }: { value: any, valid: boolean }) {
    if (!valid) {
      setAsTouched(this.terminateScreeningModalData);
    } else {
      this.terminateScreeningConfirmDisabled = true;
      const submitButton = document.getElementById('terminateScreeningConfirm');
      const spinnerWrap = document.createElement('div');
      spinnerWrap.classList.add('spinner-wrap');
      spinnerWrap.classList.add('button');

      submitButton.innerHTML = '';
      submitButton.appendChild(spinnerWrap);

      this.candidateScreeningService.terminateScreening(this.id, value).subscribe(response => {
        if (!isNullOrUndefined(response.id)) {
          this.modalRef.hide();
          this.router.navigate(['/dashboard/candidates-overview'], { skipLocationChange: true });
        }
      }, err => {
        let action = 'serverError';
        if (err.status === 403)
          action = '403';
        addToaster('error', this.toastyService, action, 'errors');
        this.terminateScreeningConfirmDisabled = false;
        submitButton.innerHTML = 'Terminate Screening';
      });
    }
  }

  onChangeScreening({ value, valid }: { value: any, valid: boolean }) {
    if (!valid) {
      setAsTouched(this.changeScreeningModalData);
    } else {
      this.changeScreeningConfirmDisabled = true;
      const submitButton = document.getElementById('changeScreeningConfirm');
      const spinnerWrap = document.createElement('div');
      spinnerWrap.classList.add('spinner-wrap');
      spinnerWrap.classList.add('button');

      submitButton.innerHTML = '';
      submitButton.appendChild(spinnerWrap);

      this.candidateScreeningService.changeScreening(value).subscribe(response => {
        if (!isNullOrUndefined(response.id)) {
          this.modalRef.hide();
          location.reload();
        }
      }, err => {
        let action = 'serverError';
        if (err.status === 403)
          action = '403';
        addToaster('error', this.toastyService, action, 'errors');
        this.changeScreeningConfirmDisabled = false;
        submitButton.innerHTML = 'Delete and Resend';
      });
    }
  }

  confirmDeleteCandidate() {
    this.candidateScreeningService.deleteScreening(
      this.id)
      .subscribe(() => {
        this.modalRef.hide();
        this.router.navigate([this.startRouteUrl]);
      }, err => {
        this.modalRef.hide();
        let action = 'serverError';
        if (err.status === 403)
          action = '403';
        addToaster('error', this.toastyService, action, 'errors');
      });
  }

  onSubmitModal({ value, valid }: { value: any, valid: boolean }) {
    if (!valid) {
      setAsTouched(this.toolbarModalData);
    } else {
      const toolbarModalSubmitButton = document.getElementById('toolbarModalSubmitButton');
      this.toolbarModalSubmitButtonDisabled = true;
      const spinnerWrap = document.createElement('div');
      spinnerWrap.classList.add('spinner-wrap');
      spinnerWrap.classList.add('button');

      toolbarModalSubmitButton.innerHTML = '';
      toolbarModalSubmitButton.appendChild(spinnerWrap);

      if (this.modalData.edit) {
        this.modalData.text = value.modalTextArea;
        this.candidateScreeningService.editComment(
          this.id,
          this.screening.screening.id,
          this.modalData.id,
          value.modalTextArea)
          .subscribe(response => {
            this.toolbarModalData.get('modalTextArea').reset('');
            this.toolbarModalSubmitButtonDisabled = false;
            toolbarModalSubmitButton.innerHTML = '<i class="material-icons">' + this.modalData.submitButtonIcon + '</i> ' + this.modalData.submitButtonLabel;
            this.modalRef.hide();
            this.commentResponseHandling(response);
          }, err => {
            let action = 'serverError';
            if (err.status === 403)
              action = '403';
            addToaster('error', this.toastyService, action, 'errors');
          });
      } else if (this.modalData.type === 'delayCheck') {
        this.candidateScreeningService.delayCheck(
          this.id,
          this.screening.screening.id,
          this.modalData.elementId,
          value.modalTextArea)
          .subscribe(response => {
            if (!isNullOrUndefined(response.newScreeningStatus)) {
              this.toolbarModalData.get('modalTextArea').reset('');
              this.toolbarModalSubmitButtonDisabled = false;
              toolbarModalSubmitButton.innerHTML = '<i class="material-icons">' + this.modalData.submitButtonIcon + '</i> ' + this.modalData.submitButtonLabel;
              this.modalRef.hide();
              this.commentResponseHandling(response);
            }
          }, err => {
            let action = 'serverError';
            if (err.status === 403)
              action = '403';
            this.toolbarModalData.get('modalTextArea').reset('');
            this.toolbarModalSubmitButtonDisabled = false;
            toolbarModalSubmitButton.innerHTML = '<i class="material-icons">' + this.modalData.submitButtonIcon + '</i> ' + this.modalData.submitButtonLabel;
            this.modalRef.hide();
            addToaster('error', this.toastyService, action, 'errors');
          });
      } else {
        this.candidateScreeningService.commentOnPartOfScreening(
          this.id,
          this.screening.screening.id,
          this.modalData.elementId,
          value.modalTextArea,
          this.modalData.type)
          .subscribe(response => {
            if (!isNullOrUndefined(response.log)) {
              this.toolbarModalData.get('modalTextArea').reset('');
              this.toolbarModalSubmitButtonDisabled = false;
              toolbarModalSubmitButton.innerHTML = '<i class="material-icons">' + this.modalData.submitButtonIcon + '</i> ' + this.modalData.submitButtonLabel;
              if (this.modalData.hasOwnProperty('elementType') && this.modalData.elementType === 'check' &&
                (this.modalData.type === 'flag' || this.modalData.type === 'adverse'))
                this.screening.checksCompleted += 1;
              this.modalRef.hide();
              this.commentResponseHandling(response);
            }
          }, err => {
            let action = 'serverError';
            if (err.status === 403)
              action = 'wrongStatus';
            this.toolbarModalData.get('modalTextArea').reset('');
            this.toolbarModalSubmitButtonDisabled = false;
            toolbarModalSubmitButton.innerHTML = '<i class="material-icons">' + this.modalData.submitButtonIcon + '</i> ' + this.modalData.submitButtonLabel;
            if (this.modalData.hasOwnProperty('elementType') && this.modalData.elementType === 'check' &&
              (this.modalData.type === 'flag' || this.modalData.type === 'adverse'))
              this.screening.checksCompleted += 1;
            this.modalRef.hide();
            addToaster('error', this.toastyService, action, 'errors');
          });
      }
    }
  }

  openCompleteModal() {
    if (this.details.hasOwnProperty('priceAdjustment'))
      this.completeScreeningModalData.get('additionalCost').setValue(this.details.priceAdjustment);
    if (this.details.hasOwnProperty('closingComment'))
      this.completeScreeningModalData.get('closingComment').setValue(this.details.closingComment);
    this.modalRef = this.modalService.show(this.completeScreeningModal, this.modalConfig);
  }

  onCompleteScreening({ value, valid }: { value: any, valid: boolean }) {
    if (!valid) {
      setAsTouched(this.completeScreeningModalData);
    } else {
      this.completeScreeningConfirmDisabled = true;
      const submitButton = document.getElementById('completeScreeningConfirm');
      const spinnerWrap = document.createElement('div');
      spinnerWrap.classList.add('spinner-wrap');
      spinnerWrap.classList.add('button');

      submitButton.innerHTML = '';
      submitButton.appendChild(spinnerWrap);

      this.candidateScreeningService.completeScreening(this.id, value).subscribe(response => {
        if (!isNullOrUndefined(response.id)) {
          this.modalRef.hide();
          this.router.navigate(['/dashboard/candidates-overview'], { skipLocationChange: true });
        }
      }, err => {
        let action = 'serverError';
        if (err.status === 403)
          action = '403';
        addToaster('error', this.toastyService, action, 'errors');
        this.completeScreeningConfirmDisabled = false;
        submitButton.innerHTML = 'Complete Screening';
      });
    }
  }

  fileUpload(event) {
    this.candidateScreeningService.addFileComment(this.id, this.modalData.elementId, event.target.files[0].name, event.target.files[0])
    .subscribe(response => {
      if (response.hasOwnProperty('comment'))
        this.commentResponseHandling(response);
    }, err => {
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
    });
  }

  commentResponseHandling(response) {
    // eventually change check status
    if (response.hasOwnProperty('newCheckStatus') && !isNullOrUndefined(response.newCheckStatus)) {
      if (!isNullOrUndefined(this.modalData) && this.modalData.type === 'return') {
        this.startVisible = false;
        this.returnDisabled = false;
        this.returnVisible = true;
      }
      if (!isNullOrUndefined(this.deleteModalData) && this.deleteModalData.deleteComment) {
        this.returnDisabled = true;
      }
      _.forEach(this.dynamicForm.checks, ( function(check, index) {
        if ((!isNullOrUndefined(this.modalData) && ((_.startsWith(this.modalData.elementId, check.id + '-') || this.modalData.elementId === check.id || this.modalData.elementId.indexOf('#' + check.id + '-') !== -1))) ||
            (!isNullOrUndefined(this.deleteModalData) && ((_.startsWith(this.deleteModalData.elementId, check.id + '-') || this.deleteModalData.elementId === check.id  || this.deleteModalData.elementId.indexOf('#' + check.id + '-') !== -1)))) {
          this.dynamicForm.checks[index].checkStatus = response.newCheckStatus;
          return false;
        }
      }).bind(this));
    }
    if (response.hasOwnProperty('comment')) {
      let existingCommentNode = false;
      _.forEach(this.screeningData.comments, ( function(commentObj, index) {
        if (commentObj.nodeId === response.comment.nodeId) {
          response.comment['createdDate'] = new Date();
          this.screeningData.comments[index].comments.unshift(response.comment);
          existingCommentNode = true;
        }
      }).bind(this));
      if (!existingCommentNode) {
        response.comment['createdDate'] = new Date();
        if (this.screeningData.hasOwnProperty('comments'))
          this.screeningData.comments.push({ 'nodeId': response.comment.nodeId, 'comments': new Array(response.comment) });
        else
          this.screeningData['comments'] = new Array({ 'nodeId': response.comment.nodeId, 'comments': new Array(response.comment) });
      }
    } else if (!isNullOrUndefined(this.modalData) && this.modalData.hasOwnProperty('edit') && this.modalData.edit) {
      _.forEach(this.screeningData.comments, ( function(commentsObj, i) {
        if (commentsObj.nodeId === this.modalData.elementId) {
          _.forEach(commentsObj.comments, ( function(commentObj, j) {
            if (commentObj.nodeId === this.modalData.elementId) {
              this.screeningData.comments[i].comments[j]['createdDate'] = new Date();
              this.screeningData.comments[i].comments[j]['comment'] = this.modalData.text;
            }
          }).bind(this));
        }
      }).bind(this));
    }
    this.dynamicFormComments = this.screeningData.comments;

    // eventually change screening status
    if (response.hasOwnProperty('newScreeningStatus') && !isNullOrUndefined(response.newScreeningStatus)) {
      if (this.modalData.type === 'delayCheck') {
        _.forEach(this.dynamicForm.checks, (function (check, index) {
          if (this.modalData.elementId === check.id) {
            this.dynamicForm.checks[index].checkStatus = 'DELAYED';
            return false;
          }
        }).bind(this));
      }

      this.screening.screeningStatus = response.newScreeningStatus;
      this.dynamicForm.screening.screeningStatus = this.screening.screeningStatus;
      _.forEach(candidatesOverviewDashboardFilters, (function (status) {
        if (status.name === this.screening.screeningStatus) {
          this.statusValue = status.value;
          this.screeningStatus.statusType = status.icon;
          this.screeningStatus.statusColor = status.color;
          this.screeningStatus.statusBorder = status.border;
          this.screeningStatus.statusFill = status.fill;
          this.screeningStatus.statusSize = status.size;
          return;
        }
      }).bind(this));
    }

    // insert in the dom the new comment
    if (!isNullOrUndefined(response.comment) && !this.modalData.edit && this.modalData.type !== 'delayCheck') {
      const commentWrapper = document.getElementById('db-comments_' + response.comment.nodeId);

      const commentContainer = document.createElement('div');
      commentContainer.setAttribute('id', 'comment_' + this.modalData.elementId + '_' + response.comment.id);
      commentContainer.classList.add('comment');

      const commentIcon = document.createElement('i');
      commentIcon.classList.add('material-icons');

      const commentText = document.createElement('span');
      commentText.setAttribute('id', 'commentText_' + this.modalData.elementId + '_' + response.comment.id);
      commentText.classList.add('text');

      const commentDate = document.createElement('span');
      commentDate.classList.add('date');
      commentDate.innerText = this.datepipe.transform(new Date(), 'dd MMM yyyy, HH:mm');

      switch (response.comment.type) {
        case 'COMMENT':
          commentText.innerText = response.comment.comment;
          commentIcon.innerText = 'comment';
          break;
        case 'RETURN':
          const dbContainer = document.getElementById('db-container_' + this.modalData.elementId);
          if (!isNullOrUndefined(dbContainer)) // not for check level returns
            dbContainer.classList.add('returned');
          commentText.innerText = response.comment.comment;
          commentIcon.innerText = 'keyboard_return';
          break;
        case 'FILE':
          commentText.innerText = response.comment.file.name;
          commentIcon.innerText = 'file_download';
          commentContainer.classList.add('pointer');
          const config = candidateScreeningToasterOptions;
          config.msg = getToasterMessagesLabel('fileUploaded', 'candidateScreening');
          this.toastyService.info(config);
          break;
        case 'FLAG':
          commentText.innerText = response.comment.comment;
          commentIcon.innerText = 'flag';
          commentIcon.classList.add('flag');
          break;
        case 'ADVERSE':
          commentText.innerText = response.comment.comment;
          commentIcon.innerText = 'close';
          commentIcon.classList.add('adverse');
          break;
        default:
          break;
      }

      if (response.comment.type === 'FILE') {
        const commentHref = document.createElement('a');
        const file = 'https://' + environment.storageBucket + '/gcs/' + response.comment.file.folder + '/' + response.comment.file.name + '?download=true';
        commentHref.setAttribute('href', file);
        commentHref.setAttribute('download', response.comment.file.name);
        commentHref.appendChild(commentIcon);
        commentHref.appendChild(commentText);
        commentHref.appendChild(commentDate);
        commentContainer.appendChild(commentHref);
      } else {
        commentContainer.appendChild(commentIcon);
        commentContainer.appendChild(commentText);
        commentContainer.appendChild(commentDate);
      }

      const buttonsContainer = document.createElement('div');
      buttonsContainer.classList.add('buttons-container');
      buttonsContainer.classList.add('d-none');

      let editButton;
      let modalData;
      if (this.modalData.type !== 'upload') {
        editButton = document.createElement('button');
        editButton.setAttribute('type', 'button');
        editButton.classList.add('btn');
        editButton.classList.add('xavier-button-plain');
        editButton.classList.add('commentButton');
        editButton.innerHTML = '<i class="material-icons">edit</i>';
        modalData = {
          title: 'Edit comment',
          submitButtonIcon: 'edit',
          submitButtonLabel: 'Edit',
          type: this.modalData.type,
          elementId: this.modalData.elementId,
          text: commentText.innerText,
          edit: true,
          id: response.comment.id
        };
        editButton.addEventListener('click', (function() {
          this.dynamicFormService.toolbarModalNotification(modalData);
        }).bind(this));
      }

      const deleteModalData = {
        elementId: response.comment.nodeId,
        deleteComment: true,
        commentContainerId: 'comment_' + response.comment.nodeId + '_' + response.comment.id,
        commentId: response.comment.id
      };
      const deleteButton = document.createElement('button');
      deleteButton.setAttribute('type', 'button');
      deleteButton.classList.add('btn');
      deleteButton.classList.add('xavier-button-plain');
      deleteButton.classList.add('commentButton');
      deleteButton.innerHTML = '<i class="material-icons">delete</i>';
      deleteButton.addEventListener('click', (function() {
        this.dynamicFormService.confirmDeleteModalNotification(deleteModalData);
      }).bind(this));

      if (this.modalData.type !== 'upload')
        buttonsContainer.appendChild(editButton);
      buttonsContainer.appendChild(deleteButton);
      commentContainer.appendChild(buttonsContainer);
      commentContainer.addEventListener('mouseenter', function () {
        buttonsContainer.classList.remove('d-none');
        deleteButton.classList.remove('d-none');
      });
      commentContainer.addEventListener('mouseleave', function () {
        buttonsContainer.classList.add('d-none');
        deleteButton.classList.add('d-none');
      });

      commentContainer.appendChild(buttonsContainer);

      this.renderer.insertBefore(commentWrapper, commentContainer, commentWrapper.firstChild);
    } else if (!isNullOrUndefined(response.id) &&
               !isNullOrUndefined(this.modalData) &&
               this.modalData.hasOwnProperty('edit')) {
      document.getElementById('commentText_' + this.modalData.elementId + '_' + this.modalData.id).innerText = this.modalData.text;
      this.modalData = null;
    } else if ((!isNullOrUndefined(response.log) || !isNullOrUndefined(response.id)) &&
               !isNullOrUndefined(this.deleteModalData) &&
               this.deleteModalData.hasOwnProperty('deleteComment')) {
      _.forEach(this.screeningData.comments, ( function(commentObj, i) {
        if (commentObj.nodeId === this.deleteModalData.elementId)
          _.forEach(commentObj.comments, ( function(comment, j) {
            if (comment.id === this.deleteModalData.commentId) {
              this.screeningData.comments[i].comments.splice(j, 1);
              return false;
            }
          }).bind(this));
      }).bind(this));
      document.getElementById(this.deleteModalData.commentContainerId).remove();
      this.deleteModalData = null;
    }

    // insert in the dom the log entry
    if (!isNullOrUndefined(response.log) || !isNullOrUndefined(response.id)) {
      let responseLog;
      if (!isNullOrUndefined(response.log))
        responseLog = response.log;
      if (!isNullOrUndefined(response.id))
        responseLog = response;
      const eventPanel = document.getElementById('eventLogPanel');

      const newEntry = document.createElement('div');
      newEntry.classList.add('row');

      const executor = document.createElement('div');
      executor.classList.add('col-8');
      executor.classList.add('executor');
      executor.innerText = responseLog.executorName + ', ' + responseLog.executorRole;

      const timestamp = document.createElement('div');
      timestamp.classList.add('col-4');
      timestamp.classList.add('timestamp');
      timestamp.classList.add('text-right');
      timestamp.innerText = this.datepipe.transform(new Date(), 'dd MMM yyyy, HH:mm');

      const activity = document.createElement('div');
      activity.classList.add('col-12');
      activity.classList.add('activity');
      activity.innerText = getEventLogActivityTypeLabel(responseLog.activityType);
      if (responseLog.details !== '')
        activity.innerText += ' - ' + responseLog.details;

      newEntry.appendChild(executor);
      newEntry.appendChild(timestamp);
      newEntry.appendChild(activity);

      this.renderer.insertBefore(eventPanel, newEntry, eventPanel.firstChild);
    }

    // update check control visibility options
    this.dashboardDynamicForm.checkStatusControlsVisibility();

    // show complete screening button if all checks and references are "completed"
    let canCompleteScreening = true;
    _.forEach(this.dynamicForm.checks, ( function(check) {
      if (check.checkStatus === 'IN_PROGRESS' ||
        check.checkStatus === 'WITH_CANDIDATE' ||
        check.checkStatus === 'CANDIDATE_SAVED' ||
        check.checkStatus === 'RETURNED') {
        canCompleteScreening = false;
        return false;
      }
    }).bind(this));
    if (this.dynamicForm.hasOwnProperty('references')) {
      _.forEach(this.dynamicForm.references, ( function(reference) {
        if (reference.checkStatus === 'WITH_CANDIDATE') {
          canCompleteScreening = false;
          return false;
        }
      }).bind(this));
    }
    if (this.screening.screeningStatus === 'PASSED' ||
      this.screening.screeningStatus === 'FLAGGED' ||
      this.screening.screeningStatus === 'ADVERSE' ||
      this.screening.screeningStatus === 'TERMINATED' ||
      this.screening.screeningStatus === 'COMPLETED') {
      canCompleteScreening = false;
    }
    if (canCompleteScreening) {
      this.completeDisabled = false;
      this.completeVisible = true;
    }
  }

  private isACheckReturned() {
    let result = false;
    _.forEach(this.dynamicForm.checks, ( function(check) {
      if (check.checkStatus === 'RETURNED') {
        result = true;
        return false;
      }
    }).bind(this));
    return result;
  }

  private mergeObjects(a, b) {
    const merged = [];
    _.forEach(a, function(value) {
      merged.push(value);
    });
    _.forEach(b, function(value) {
      merged.push(value);
    });
    return merged;
  }

  public onDataChanged(): void {
    this.getData();
  }

  public onRunCreditSafeClicked() {
    this.isRunCreditsafeVisible = true;
  }

  public loginAsCandidate() {
    this.candidateService.loginAsCandidate(this.details.user.id).subscribe(
      (response) => {
        if (!isNullOrUndefined(response.id)) {
          this.router
            .navigateByUrl("/dashboard/candidate-screening")
            .then(() => {
              window.location.reload();
            });
        }
      },
      (err) => {
        let action = "serverError";
        if (err.status === 403) action = "403";
        addToaster("error", this.toastyService, action, "errors");
      }
    );
  }

  onWrapClick() {
    if (this.screeningItemSource.length > 0 && !this.displayValues)
      this.displayValues = true;
    else
      this.displayValues = false;
  }

  selectEntry(entry) {
    this.changeScreeningModalData.setValue({
      newScreeningId: entry.id,
      newScreeningName: entry.screeningName,
      candidateId: this.id
    });
    this.onWrapClick();
  }

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target) && this.displayValues === true)
      this.displayValues = !this.displayValues;
  }

  onKeyUp(event) {
    if (this.displayValues && event.code == "ArrowDown" && !(this.currentPos >= this.screeningItemSource.length - 1)) {
      this.currentPos++;
      this.dropdown.nativeElement.scrollTo(0, this.currentPos * 44);
    }
    if (this.displayValues && event.code == "ArrowUp" && !(this.currentPos < 1)) {
      this.currentPos--;
    }
    if (this.displayValues && event.code == "Escape")
      this.onWrapClick();
  }

  onChange(event, allow: boolean) {
    if(!allow)
      event.preventDefault();
  }
  onKeyDown(event, allow: boolean) {
    if (!allow && (event.code === 'Backspace' || event.code === 'Delete'))
      return false;
    if (event.code === 'Enter')
      event.preventDefault();
    if (this.displayValues && this.screeningItemSource[this.currentPos] && (event.code === 'Enter' || event.code === 'Tab')) {

      this.onWrapClick();
    }
  }

  allowTypingOnKeyPress(allow: boolean) {
    if (!allow)
      return false;
  }
}
