import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MultiSelectButtonsComponent} from "../../cmps-parts/multi-select-buttons/multi-select-buttons.component";
import {FileUploaderComponent} from "../../cmps-parts/file-uploader/file-uploader.component";
import {AdminUser} from "../../model/adminuser.model";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {FormService} from "../../services/form.service";
import {SelectDropdown} from "../../model/select-dropdown.model";
import {fadeIn, slideIn} from "../../../../data/animations.data";
import {documentTypes} from "../../../../data/variables.data";
import {environment} from "../../../../environments/environment";
import {isNullOrUndefined} from "util";
import {setAsTouched} from "../../../utils/functions.utils";
import * as _ from 'lodash';
import {ukPostcodeValidator} from '../../../utils/form.validators';

@Component({
  moduleId: module.id,
  selector: 'xavier-step3',
  templateUrl: 'step3.component.html',
  styleUrls: [
    'step3.stylesheet.sass',
    'step3-mediaqueries.stylesheet.sass'
  ],
  animations: [fadeIn, slideIn]
})

export class Step3Component implements OnInit, AfterViewInit {

  @ViewChildren(MultiSelectButtonsComponent) multiSelectButtons: QueryList<MultiSelectButtonsComponent>;
  @ViewChildren(FileUploaderComponent) fileUploaders: QueryList<FileUploaderComponent>;

  @Input() public addressLookupParams: SelectDropdown;
  @Input() public step3MultiSelectButtons = documentTypes;

  @Output() entityName: string;

  public registrationData: FormGroup;
  public multiSelectErrorClass: string = "";
  public uploadBoxErrorClass: string = "";
  public reSubmit: boolean = false;
  public disabled: boolean = false;
  public showAddresses: Array<boolean> = new Array<boolean>();

  private componentStep = 3;
  private reSubmitAdminUser;
  private token: string;

  constructor(private fb: FormBuilder, private activeRoute: ActivatedRoute, private router: Router,
              private registrationFormService: FormService) {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd))
        return;
      window.scrollTo(0, 0)
    });
  }

  ngOnInit() {
    this.reSubmit = this.registrationFormService.getResubmit();
    this.token = this.activeRoute.snapshot.queryParams['token'];
    if (this.registrationFormService.getCompletedStep() != this.componentStep - 1) {
      this.router.navigate(['/registration/' + this.registrationFormService.getCompletedStep()]);
    }
    if (this.reSubmit)
      this.reSubmitAdminUser = this.registrationFormService.getAll();

    let formData = this.registrationFormService.getAll();
    if (!isNullOrUndefined(formData.companyName))
      this.entityName = formData.companyName;
    else
      this.entityName = "Your Company";

    this.registrationData = this.fb.group({
      signatories: this.fb.array([this.initSignatories()]),
      agreement: ['', Validators.requiredTrue]
    });
    this.registrationData.patchValue(formData);

    if (Object.keys(formData).length) {
      _.forEach(formData.signatories, ( function(signatory, index) {
        setTimeout(() => {
          this.multiSelectButtons.forEach((multiSelectButtonsInstance, i) => {
            if (i === index) {
              multiSelectButtonsInstance.setActive(formData.signatories[index].documentType);
            }
          });
        }, 0);

        // first instance of the FormArray is created when the page loads
        if (index != 0)
          setTimeout(() => this.addSignatory(signatory), 0);

        setTimeout(() => {
          this.registrationData.get('signatories')['controls'][index].get('documentType').setValue(signatory.documentType, { emitEvent: true });
          this.registrationData.get('signatories')['controls'][index].get('name').setValue(signatory.name);
          this.registrationData.get('signatories')['controls'][index].get('folder').setValue(signatory.folder);
          this.registrationData.get('signatories')['controls'][index].get('postcode').setValue(signatory.postcode);
          this.displayAddressFields(signatory.documentType, index, signatory.addressLine1, signatory.addressLine2, signatory.city);
          this.registrationData.get('signatories')['controls'][index].get('country').disable();
          this.registrationData.get('signatories')['controls'][index].get('country').setValue('United Kingdom');
        }, 0);

        if (!this.reSubmit) {
          setTimeout(() => {
            this.fileUploaders.forEach((fileUploaderInstance) => {
              if (fileUploaderInstance.documentType.id === formData.signatories[index].documentType &&
                fileUploaderInstance.containerIndex === index) {
                fileUploaderInstance.hidePanel('fileLoaderDrop', fileUploaderInstance.documentType.id + '-' + fileUploaderInstance.containerIndex);
                fileUploaderInstance.showPanel('fileUploaded', fileUploaderInstance.documentType.id + '-' + fileUploaderInstance.containerIndex);

                setTimeout(() => fileUploaderInstance.fileName = formData.signatories[index].name, 0);

                let obj = document.createElement('object');
                obj.setAttribute("data", 'https://' + environment.storageBucket + '/gcs/' + formData.signatories[fileUploaderInstance.containerIndex].folder + '/' + formData.signatories[fileUploaderInstance.containerIndex].name);
                obj.setAttribute("width", "80");
                obj.setAttribute("height", "80");

                let emb = document.createElement('embed');
                emb.setAttribute("src", 'https://' + environment.storageBucket + '/gcs/' + formData.signatories[fileUploaderInstance.containerIndex].folder + '/' + formData.signatories[fileUploaderInstance.containerIndex].name);
                emb.setAttribute("width", "80");
                emb.setAttribute("height", "80");

                let previewContainer = document.getElementById('fileUploadedPreview' + fileUploaderInstance.documentType.id + '-' + fileUploaderInstance.containerIndex);
                previewContainer.appendChild(obj);

                let container = document.getElementById(fileUploaderInstance.documentType.id + '-' + fileUploaderInstance.containerIndex);
                container.classList.remove('document-visibility');
                return false;
              }
            });
          }, 0);
        }

        setTimeout(() => {
          this.multiSelectButtons.forEach((multiSelectButtonsInstance, i) => {
            if (i === index) {
              multiSelectButtonsInstance.onClick(formData.signatories[index].documentType, multiSelectButtonsInstance.id);
            }
          });
        }, 0);
      }).bind(this));
    }
  }

  ngAfterViewInit() {
    // set the status in the left column
    if (this.reSubmit)
      this.registrationFormService.stepsStatusMsg();
  }

  get signatories(): FormArray {
    return this.registrationData.get('signatories') as FormArray;
  }

  initSignatories() {
    return this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      jobTitle: ['', Validators.required],
      idDocument: ['', Validators.required],
      name: [null, Validators.required],
      folder: [null, Validators.required],
      documentType: [null, Validators.required],
      postcode: ['', [Validators.required, ukPostcodeValidator]],
      addressLine1: ['', Validators.required],
      addressLine2: [],
      city: ['', Validators.required],
      country: ['United Kingdom', Validators.required]
    });
  }

  initAndPopulateSignatories(data) {
    return this.fb.group({
      firstName: [data.firstName, Validators.required],
      lastName: [data.lastName, Validators.required],
      jobTitle: [data.jobTitle, Validators.required],
      idDocument: [data.idDocument, Validators.required],
      name: [data.name, Validators.required],
      folder: [data.folder, Validators.required],
      documentType: [data.documentType, Validators.required],
      postcode: [data.postcode, [Validators.required, ukPostcodeValidator]],
      addressLine1: [data.addressLine1, Validators.required],
      addressLine2: [data.addressLine2],
      city: [data.city, Validators.required],
      country: ['United Kingdom', Validators.required]
    });
  }

  initAddressLookupParams(index: number) {
    let reSubmit = false;
    if (this.reSubmit) {
      let adminUserSignatoriesCount = this.registrationFormService.getAll().signatories.length;
      reSubmit = this.reSubmit;
      if (index >= adminUserSignatoriesCount)
        reSubmit = false;
    }
    return new SelectDropdown(
      'postcode-'+index,
      "Residential Address",
      "Search Postcode...",
      null,
      "search",
      [],
      this.registrationData.get('signatories')['controls'][index].get('postcode'),
      "Add New Address",
      reSubmit
    );
  }

  addSignatory(data?: any) {
    const control = <FormArray>this.registrationData.controls['signatories'];
    if (isNullOrUndefined(data))
      control.push(this.initSignatories());
    else
      control.push(this.initAndPopulateSignatories(data));
    this.showAddresses.push(false);
  }

  removeSignatory(i: number) {
    const control = <FormArray>this.registrationData.controls['signatories'];
    control.removeAt(i);
    this.showAddresses.splice(i, 1);
  }

  displayAddressFields(elementId: string, containerId: number, line1?: string, line2?: string, city?: string) {
    this.showAddresses[containerId] = true;
    let formArray = <FormArray>this.registrationData.controls['signatories']['controls'][containerId];
    if (line1)
      formArray.get('addressLine1').setValue(line1);
    if (line2)
      formArray.get('addressLine2').setValue(line2);
    if (city)
      formArray.get('city').setValue(city);
  }

  onEntrySelected(elementId: string, containerId: number, value: string) {
    if (!isNullOrUndefined(value)) {
      let parsedAddress = value.split(",");
      this.displayAddressFields(elementId, containerId, parsedAddress[0], parsedAddress[1], parsedAddress[5]);
    }
  }

  onSubmit({ value, valid } : { value: AdminUser, valid: boolean }) {
    let formArray = <FormArray>this.registrationData.controls['signatories'];
    _.forEach(formArray.controls, ( function(signatory, index) {
      if (signatory.get('idDocument').value == '')
        this.multiSelectErrorClass = "multiSelectError";
      if (isNullOrUndefined(signatory.get('name').value))
        this.uploadBoxErrorClass = "uploadBoxError";
      if (this.reSubmit && !isNullOrUndefined(value.signatories[index].id)) {
        value.signatories[index].id = this.reSubmitAdminUser.signatories[index].id;
      }
    }).bind(this) );
    if (!valid) {
      setAsTouched(this.registrationData);
    } else {
      this.registrationFormService.save(value, this.componentStep);
      if (!this.reSubmit) {
        this.router.navigate(['/registration/' + (this.componentStep + 1)]);
      } else {
        this.disabled = true;
        let resubmitButton = document.getElementById('confirmForm');
        const spinnerWrap = document.createElement('div');
        spinnerWrap.classList.add('spinner-wrap');
        spinnerWrap.classList.add('button');

        resubmitButton.innerHTML = '';
        resubmitButton.appendChild(spinnerWrap);

        this.registrationFormService.resubmitRegistrationData(this.token).toPromise().then(response => {
          this.router.navigate(['/activation'], { queryParams: { resubmitApplication: 1 }});
        }).catch(error => {});
      }
    }
  }

  backRegistration() {
    if (this.token)
      this.router.navigate(['/registration/' + (this.componentStep - 1)], { queryParams: { token: this.token }});
    else
      this.router.navigate(['/registration/' + (this.componentStep - 1)]);
  }

  onEnterManuallyClicked(index: number) {
    this.showAddresses[index] = !this.showAddresses[index];

    const postcodeFc = this.registrationData.get('signatories')['controls'][index].get('postcode');
    if (this.showAddresses[index])
      postcodeFc.setValidators([Validators.required]);
    else
      postcodeFc.setValidators([Validators.required, ukPostcodeValidator]);
    postcodeFc.updateValueAndValidity();
  }
}
