<div class="form-group xavier-input-text" [formGroup]="formGroup">
  <div>
    <label [for]="formGroupId+'-'+data.id">{{data.attributeName}}</label>
    <span *ngIf="data.required" class="required">&bull;</span>
    <div class="xavier-popover-info">
      <i *ngIf="data.information !== ''"
         class="material-icons"
         tooltip="{{data.information}}"
         placement="auto"
         containerClass="xavier-popover-container">&#xE8FD;</i>
    </div>
  </div>
  <textarea type="text"
            class="form-control xavier-textarea"
            rows="{{data.textAreaRows}}"
            [id]="formGroupId+'-'+data.id"
            placeholder="{{data.placeholder}}"
            [formControlName]="formGroupId+'-'+data.id">
  </textarea>
  <xavier-error-message [control]="formControl"></xavier-error-message>
  <div class="returned-comment">{{commentMsg}}</div>
</div>
