import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, ContentChild, ElementRef, HostListener, Input, OnChanges, OnDestroy, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { of } from "rxjs/observable/of";
import { timeout, catchError, takeUntil, filter, switchMap, distinctUntilChanged, tap, debounceTime } from 'rxjs/operators';

export type DropdownComponentFilterWithFn = (item: any, term: string) => boolean;
export type DropdownComponentCompareWithFn = (a: any, b: any) => boolean;
export type DropdownComponentItemSourceFn = (term?: string) => any;

const lookupTimeout = 20000;

@Component({
  selector: 'xavier-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DropdownComponent,
    multi: true
  }]
})
export class DropdownComponent implements ControlValueAccessor, OnChanges, OnDestroy {
  @Input()
  public items: any[];

  @Input()
  public itemSource: DropdownComponentItemSourceFn;

  @Input()
  public itemSourceWithSearch: boolean = false;

  @Input()
  public filterWith: DropdownComponentFilterWithFn;

  @Input()
  public compareWith: DropdownComponentCompareWithFn = (a, b) => a === b;

  @Input()
  public icon = "";

  @Input()
  public placeholder = "Search country…";

  @ContentChild("itemTemplate", { static: true })
  itemTemplate: TemplateRef<any>;

  @ContentChild("valueTemplate", { static: true })
  valueTemplate: TemplateRef<any>;

  @ViewChild("desktopDrawer", { static: true })
  desktopDrawer: TemplateRef<any>;

  @ViewChild("searchInput")
  searchInput: ElementRef;

  public selected: any;

  public isOpen: boolean = false;
  public searchTerm: string = "";
  public searchTerm$ = new Subject<string>();
  public filteredItems: any[];
  public isLoading: boolean = false;
  public isTyping = false;

  private destroy$ = new Subject();

  constructor(
    private el: ElementRef,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.searchTerm$.pipe(
      tap(() => this.isTyping = true),
      filter(t => t.length >= 3),
      distinctUntilChanged(),
      debounceTime(250),
      tap(() => this.isTyping = false),
      tap(() => this.isLoading = true),
      // tap(term => console.log("loading")),
      switchMap((term) => this.itemSource(term).pipe(timeout(lookupTimeout), catchError(() => of(null)))),
      tap(() => this.isLoading = false),
      // tap(term => console.log("loading = false")),
    ).subscribe((items: any[]) => this.filteredItems = this.items = items);
  }

  @HostListener("document:click", ["$event.target"])
  public onDocumentClicked(target) {
    if (this.isOpen && !this.el.nativeElement.contains(target)) {
      this.isOpen = false;
    }
  }

  public onDropdownClicked() {
    if (this.itemSource) {
      this.items = this.filteredItems = [];
    }

    this.openDesktopDrawer();

    setTimeout(() => this.searchInput && this.searchInput.nativeElement.focus());

    if (this.searchTerm) {
      this.searchTerm = "";
      this.filterItems();
    }

    if (this.itemSource && !this.itemSourceWithSearch) {
      this.isLoading = true;
      this.itemSource().subscribe(
        (items: any[]) => this.filteredItems = this.items = items,
        () => 0,
        () => this.isLoading = false
      );
    }
  }

  public onItemClicked(item: any) {
    this.selected = item;

    this.isOpen = false;
    this.closeDesktopDrawer();
    this.onChangeFn && this.onChangeFn(this.selected);
  }

  public onClearClicked() {
    this.selected = null;

    this.isOpen = false;
    this.closeDesktopDrawer();
    this.onChangeFn && this.onChangeFn(this.selected);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ("items" in changes || "filterWith" in changes) {
      this.filterItems();
    }
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public onModelChange() {
    this.onChangeFn && this.onChangeFn(this.selected);
  }

  public isSelected(item: any) {
    return this.selected && this.compareWith(item, this.selected);
  }

  private openDesktopDrawer() {
    const t = new TemplatePortal(this.desktopDrawer, this.viewContainerRef);

    this.overlayRef.attach(t);
    this.isOpen = true;
  }

  private closeDesktopDrawer() {
    this.overlayRef.detach();
    this.isOpen = false;
  }

  private _overlayRef: OverlayRef;
  private get overlayRef() {
    if (this._overlayRef) {
      return this._overlayRef;
    }

    // const positions: ConnectedPosition[] = [
    //   {
    //     originX: 'start',
    //     originY: 'bottom',
    //     overlayX: 'start',
    //     overlayY: 'top',
    //     offsetY: 10
    //   },
    //   {
    //     originX: 'start',
    //     originY: 'top',
    //     overlayX: 'start',
    //     overlayY: 'bottom',
    //     offsetY: -10
    //   }
    // ];

    // const positionStrategy = this.overlay.position()
    //   // .flexibleConnectedTo(this.el)
    //   .withViewportMargin(20)
    //   .withPositions(positions);
    const positionStrategy = this.overlay.position().connectedTo(this.el, { originX: "start", originY: "top" }, { overlayX: "start", overlayY: "top" });
    const scrollStrategy = this.overlay.scrollStrategies.reposition();
    const hasBackdrop = true;
    const backdropClass = 'cdk-overlay-transparent-backdrop';

    this._overlayRef = this.overlay.create({ positionStrategy, scrollStrategy, hasBackdrop, backdropClass });

    this._overlayRef.backdropClick().pipe(takeUntil(this.destroy$)).subscribe(() => this.closeDesktopDrawer());

    return this._overlayRef;
  }

  private filterItems() {

    
    // if (this.items) {
    //   const filter = this.searchTerm;

    //   this.filteredItems = this.filterWith && filter
    //     ? this.items.filter(e => this.filterWith(e, filter))
    //     : this.items;
    // } else {
    //   this.filteredItems = this.items;
    // }
  }

  private onChangeFn: (item: any) => void;

  writeValue(obj: any): void {
    this.selected = obj;
  }
  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
