import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationStart, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {FormControl} from "@angular/forms";
import {GenericTableComponent, GtExpandedRow, GtInformation} from "@angular-generic-table/core";
import {itemsPerPageValues, candidatesToasterOptions} from "../../../../data/variables.data";
import {addToaster, getToasterMessagesLabel} from "../../../utils/functions.utils";
import {ToastyService} from "ng2-toasty";
import {CandidateService} from "../../services/candidate.service";
import {TableService} from "../../services/table.service";
import {UserPermission} from "../../model/permissions.model";
import {UserAuth} from "../../../../core/userAuth.core";
import {fadeIn} from "../../../../data/animations.data";
import {isNullOrUndefined} from "util";
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'xavier-candidates',
  templateUrl: 'candidates.component.html',
  styleUrls: ['candidates.stylesheet.sass'],
  animations: [fadeIn]
})

export class CandidatesComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('rowLength', { static: true }) public rowLength: any;
  // @ViewChild('search', { static: true }) public search: any;

  public startRouteUrl: string;
  public itemsPerPageValues = itemsPerPageValues;
  public searchControl = new FormControl();

  @ViewChild('candidatesTable', { static: true })
  private candidatesTable: GenericTableComponent<any, GtExpandedRow<any>>;
  private page: number = 1;
  private records: number = 10;
  private query: string = '';
  private sortBy: string = '';
  private sortOrder: string = '';
  private tableOwner: string;
  private getCandidatesDataReq: any;
  private routeSub: any;
  private searchSub: any;
  private tableOptionsSub: any;
  private tableOwnerSub: any;
  private toasterControls = {
    created: false
  };
  private tableInfo = {
    'pageNext': null,
    'pageCurrent': 1,
    'pagePrevious': null,
    'pageTotal': 1,
    'recordFrom': 1,
    'recordLength': 1,
    'recordsAfterFilter': 1,
    'recordsAfterSearch': 1,
    'recordsAll': 1
  };

  constructor(private candidatesService: CandidateService, private router: Router, private activatedRoute: ActivatedRoute,
              private titleService: Title, private tableService: TableService, private toastyService: ToastyService,
              private userAuth: UserAuth, private cdr: ChangeDetectorRef) {
    this.startRouteUrl = this.router.url;

    this.routeSub = this.activatedRoute.queryParams.subscribe(params => {
      this.toasterControls['created'] = (!isNullOrUndefined(params['created'])) ? params['created'] : false;
    });

    this.tableOwnerSub = this.tableService.currentOwner.subscribe(owner => {
      this.tableOwner = owner;
    });

    this.searchSub = this.searchControl.valueChanges
      .debounceTime(1000)
      .subscribe(newValue => {
        this.query = newValue;
        this.page = 1;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(this.page, this.candidatesTable.gtInfo.recordLength, this.query);
      });
  }

  ngOnInit() {
    this.titleService.setTitle('Dashboard - Xavier Eurocom CI');

    this.routeSub = this.router.events.subscribe((value) => {
      if (value instanceof NavigationStart)
        this.startRouteUrl = value.url;
    });

    this.tableOptionsSub = this.tableService.currentOptions.subscribe(options => {
      if (this.tableOwner === btoa(this.constructor.toString())) {
        this.page = options.page ? options.page : 1;
        this.records = options.records ? options.records : 10;
        this.query = options.query ? options.query : '';
        this.sortBy = options.sortBy ? options.sortBy : '';
        this.sortOrder = options.sortOrder ? options.sortOrder : '';
      } else {
        this.tableService.changeTableOwner(btoa(this.constructor.toString()));
        this.page = 1;
        this.records = 10;
        this.query = '';
        this.sortBy = '';
        this.sortOrder = '';
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
      }
    });

    this.getData(this.page, this.records, this.query, this.sortBy, this.sortOrder);
  }

  ngAfterViewInit() {
    if (!UserPermission.isAllowed(['CANDIDATE_EDIT', 'CANDIDATE_EDIT_COMPANY'], this.userAuth.getUser().permissionList)) {
      let tables = document.getElementsByTagName('table');
      tables[0].classList.add('tr-no-pointer');
    }

    _.forEach(this.toasterControls, ( function(actionValue, action) {
      if (actionValue) {
        let config = candidatesToasterOptions;
        config.msg = getToasterMessagesLabel(action, 'screenings');
        this.toastyService.info(config);
      }
    }).bind(this));

    this.rowLength.nativeElement.value = this.records;
    // this.search.nativeElement.value = this.query;

    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
    this.searchSub.unsubscribe();
    this.tableOptionsSub.unsubscribe();
    this.tableOwnerSub.unsubscribe();
    this.getCandidatesDataReq.unsubscribe();
  }

  getData = function(pageNum: number, pageSize: number, query: string, sortBy?: string, sortOrder?: string) {
    this.data = [];
    let loading: Array<any> = [];
    loading.push({ firstName: 'Loading...'} );
    this.configObject.data = loading;

    if (typeof this.getCandidatesDataReq !== 'undefined') {
      this.getCandidatesDataReq.unsubscribe();
    }

    this.info = {
      'pageNext': null,
      'pageCurrent': 1,
      'pagePrevious': null,
      'pageTotal': 1,
      'recordFrom': 1,
      'recordLength': 1,
      'recordsAfterFilter': 1,
      'recordsAfterSearch': 1,
      'recordsAll': 1
    };
    this.configObject.info = <GtInformation>this.info;
    if (isNullOrUndefined(query))
      query = '';
    if (!sortBy)
      sortBy = 'firstName';
    if (!sortOrder)
      sortOrder = 'ASC';
    this.getCandidatesDataReq = this.candidatesService.getCandidatesData(pageSize, pageNum, query, sortBy, sortOrder).subscribe(response => {
      if (!isNullOrUndefined(response.list)) {
        this.data = [];
        _.forEach(response.list, (function (candidate) {
          let candidateObject = {
            'id': candidate.id,
            'firstName': candidate.firstName,
            'lastName': candidate.lastName,
            'companyName': candidate.companyName,
            'divisionName': candidate.divisionName != null ? candidate.divisionName : '-',
            'lineManager': candidate.lineManager != null ? candidate.lineManager : '-',
            'humanResources': candidate.hr != null ? candidate.hr : '-',
            'screener': candidate.screener != null ? candidate.screener : '-',
            'activated': candidate.activated
          };
          this.data.push(candidateObject);
        }).bind(this));
        this.configObject.data = this.data;
        let pageNext = pageNum < response.pages ? pageNum + 1 : null;
        let pagePrev = pageNum === 1 ? null : pageNum - 1;
        this.tableInfo = {
          'pageNext': pageNext,
          'pageCurrent': pageNum,
          'pagePrevious': pagePrev,
          'pageTotal': response.pages,
          'recordLength': pageSize,
          'recordsAfterFilter': response.count,
          'recordsAfterSearch': response.count,
          'recordsAll': response.count
        };
        this.configObject.info = <GtInformation>this.tableInfo;
      } else if (!isNullOrUndefined(response.count) && !isNullOrUndefined(response.pages)) {
        this.configObject.data = [];
        this.tableInfo = {
          'pageNext': null,
          'pageCurrent': pageNum,
          'pagePrevious': null,
          'pageTotal': response.pages,
          'recordFrom': response.count,
          'recordLength': pageSize,
          'recordsAfterFilter': response.count,
          'recordsAfterSearch': response.count,
          'recordsAll': response.count
        };
        this.configObject.info = <GtInformation>this.tableInfo;
      }
    }, err => {
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
      this.configObject.data = this.data;
    });
  };

  addCandidate() {
    this.router.navigate(['/dashboard/candidates/add']);
  }

  trigger = function($event) {
    switch($event.name) {
      case 'gt-row-select':
      case 'gt-row-deselect':
        if (UserPermission.isAllowed(['CANDIDATE_EDIT', 'CANDIDATE_EDIT_COMPANY'], this.userAuth.getUser().permissionList))
          this.router.navigate(['/dashboard/candidates/edit/' + $event.value.changedRow.id]);
        break;
      case 'gt-row-length-changed':
        this.records = $event.value;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(this.candidatesTable.gtInfo.pageCurrent, this.records, this.query, this.sortBy, this.sortOrder);
        break;
      case 'gt-page-changed':
        this.page = $event.value.pageCurrent;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(this.page, this.candidatesTable.gtInfo.recordLength, this.query, this.sortBy, this.sortOrder);
        break;
      case 'gt-sorting-applied':
        let sortBy;
        let sortOrder = 'ASC';
        if ($event.value[0].charAt(0) === '-' && $event.value[0].charAt(0) !== '$') {
          sortOrder = 'DESC';
          sortBy = $event.value[0].slice(1, $event.value[0].length);
        } else if ($event.value[0].charAt(0) !== '$') {
          sortBy = $event.value[0];
        }
        this.sortBy = sortBy;
        this.sortOrder = sortOrder;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(
          this.candidatesTable.gtInfo.pageCurrent,
          this.candidatesTable.gtInfo.recordLength,
          this.query, this.sortBy, this.sortOrder
        );
        break;
      default:
        break;
    }
  };

  resetSearch() {
    this.query = '';
    this.tableService.changeOptions({
      page: this.page,
      records: this.records,
      query: this.query
    });
    this.searchControl.setValue('');
  }

  public configObject = {
    settings: [{
      objectKey: 'id',
      visible: false
    }, {
      objectKey: 'firstName',
      sortOrder: 0,
      columnOrder: 0,
      sort: 'asc'
    }, {
      objectKey: 'lastName',
      sortOrder: 0,
      columnOrder: 1
    }, {
      objectKey: 'companyName',
      sortOrder: 0,
      columnOrder: 2
    }, {
      objectKey: 'divisionName',
      sortOrder: 0,
      columnOrder: 3
    }, {
      objectKey: 'lineManager',
      sortOrder: 0,
      columnOrder: 4
    }, {
      objectKey: 'humanResources',
      sortOrder: 0,
      columnOrder: 5
    }, {
      objectKey: 'screener',
      sortOrder: 0,
      columnOrder: 6
    }, {
      objectKey: 'activated',
      sortOrder: 0,
      columnOrder: 7
    }],
    fields: [{
      name: 'id',
      objectKey: 'id'
    }, {
      name: 'First Name',
      objectKey: 'firstName',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'Surname',
      objectKey: 'lastName',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'Company',
      objectKey: 'companyName',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'Division',
      objectKey: 'divisionName',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'Line Manager',
      objectKey: 'lineManager',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'Human Resources',
      objectKey: 'humanResources',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'Screener',
      objectKey: 'screener',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border';
      }
    }, {
      name: 'Activated',
      objectKey: 'activated',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border';
      }
    }],
    data: [],
    info: {}
  };

}
