import {Component, Input, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {FormService} from "../../services/form.service";
import {UserService} from "../../services/user.service";
import {Title} from "@angular/platform-browser";
import {fadeIn} from "../../../../data/animations.data";
import {setAsTouched} from "../../../utils/functions.utils";
import {isNullOrUndefined} from "util";
import {UserAuth} from "../../../../core/userAuth.core";
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'xavier-application-review',
  templateUrl: 'application-review.component.html',
  styleUrls: ['application-review.stylesheet.sass'],
  animations: [fadeIn]
})

export class ApplicationReviewComponent implements OnInit {

  @Input() loading: boolean = true;
  @Input() pass: boolean = false;
  @Input() wrongToken: boolean = false;
  @Input() name: string;
  @Input() secret: string;

  public disabledSubmit: boolean = false;
  public enterPasswordData: FormGroup;

  private token: string;
  private googleSubscription;

  constructor(private fb: FormBuilder, private router: Router, private activeRoute: ActivatedRoute, private titleService: Title,
              private formService: FormService, private userService: UserService, private userAuth: UserAuth) { }

  ngOnInit() {
    this.titleService.setTitle('Application Review - Xavier Eurocom CI');

    this.enterPasswordData = this.fb.group({
      password: ['', [Validators.required]]
    });

    this.token = this.activeRoute.snapshot.queryParams['token'];

    if (isNullOrUndefined(this.token) || this.token === '') {
      this.wrongToken = true;
      this.loading = false;
    } else {
      this.loading = false;
      this.pass = true;
    }
  }

  onSubmit({ value, valid } : { value: any, valid: boolean }) {
    if (!valid) {
      setAsTouched(this.enterPasswordData);
    } else {
      this.disabledSubmit = true;
      let body = {
        token: this.token,
        password: value.password
      };
      this.userService.checkTokenPassword(body).subscribe(response => {
        if (!_.isEmpty(response)) {
          if (response.value) {
            this.disabledSubmit = false;
            this.router.navigate(['/registration/2'], { queryParams: { token: this.token }});
          } else {
            this.disabledSubmit = false;
            this.pass = false;
            this.wrongToken = true;
          }
        }
      }, err => {
        this.disabledSubmit = false;
        this.pass = false;
        this.wrongToken = true;
      });
    }
  }

  googleLogIn(event) {
    event.preventDefault();
    this.userAuth.signInWithGoogle();
  }

  back() {
    this.enterPasswordData.get('password').reset('');
    this.wrongToken = false;
    this.pass = true;
  }

}
