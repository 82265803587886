<!-- Company Details -->
<div *ngIf="selectedPanel === 'details'" class="form-panel details" [@fadeIn]>
  <xavier-client-properties-details
    [details]="properties.details"
    [clientId]="clientData.id">
  </xavier-client-properties-details>
</div>

<!-- User Access -->
<div *ngIf="selectedPanel === 'users'" class="form-panel users" [@fadeIn]>
  <xavier-client-properties-users [clientId]="clientData.id"></xavier-client-properties-users>
</div>

<!-- Legal Signatories -->
<div *ngIf="selectedPanel === 'signatories'" class="form-panel signatories" [@fadeIn]>
  <xavier-client-properties-signatories
    [clientSignatories]="clientData.signatories"
    [propSignatories]="properties.signatories"
    [clientId]="clientData.id">
  </xavier-client-properties-signatories>
</div>

<!-- Divisions -->
<div *ngIf="selectedPanel === 'divisions'" class="form-panel divisions" [@fadeIn]>
  <xavier-client-properties-divisions [clientId]="clientData.id"></xavier-client-properties-divisions>
</div>

<!-- Settings -->
<div *ngIf="selectedPanel === 'settings'" class="form-panel settings" [@fadeIn]>
  <xavier-client-properties-settings
    [settings]="properties.settings"
    [clientId]="clientData.id">
  </xavier-client-properties-settings>
</div>

<!-- Event Log -->
<div class="eventlog-panel">
  <h4>Event Log</h4>
  <div *ngIf="eventLogLoading" [@fadeIn]>
    <div class="spinner-container not-button"><div class="spinner-wrap"></div></div>
  </div>
  <div *ngIf="!eventLogLoading" class="container panel" id="eventLogPanel" [@fadeIn]>
    <div *ngFor="let entry of eventLog" class="row">
      <div class="col-8 executor">
        <ng-container *ngIf="entry.executorName != null">{{entry.executorName}}, {{entry.executorRole}}</ng-container>
      </div>
      <div class="col-4 timestamp text-right">
        {{entry.timestamp | date:'dd MMM yyyy, HH:mm'}}
      </div>
      <div class="col-12 activity">
        {{entry.activityType | translateActivity}}{{entry.details !== '' ? ' - ' + entry.details : ''}}
      </div>
    </div>
  </div>
</div>
