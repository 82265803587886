import {Component, OnInit} from "@angular/core";
import {DashboardAttribute, ATTRIBUTE_TYPES_MAP} from "../dashboard-attribute.class";
import {DynamicFormService} from "../../../../services/dynamic-form.service";
import {TEXTAREA_TYPE} from "../../../../../../data/variables.data";

@Component({
  moduleId: module.id,
  selector: 'xavier-dashboard-textarea',
  templateUrl: 'dashboard-textarea.component.html',
  styleUrls: ['dashboard-textarea.stylesheet.sass']
})

export class DashboardTextareaAttribute extends DashboardAttribute implements OnInit {

  constructor(public dynamicFormService: DynamicFormService) {
    super(dynamicFormService);
  }

  ngOnInit() {
    let formGroup = this.dynamicFormService.getFormGroup(this.formGroupId);
    let formControl = this.dynamicFormService.attributeToFormControl(this.data);

    formGroup.addControl(this.formGroupId+'-'+this.data.id, formControl);

    this.formGroup = formGroup;
    this.formControl = formControl;
  }

}

ATTRIBUTE_TYPES_MAP.set(TEXTAREA_TYPE, DashboardTextareaAttribute);
