import {Injectable} from '@angular/core';
import {Subject} from "rxjs/Subject";
import {User} from "../modules/admin/model/user.model";
import {UserService} from "../modules/admin/services/user.service";
import * as _ from 'lodash';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class UserAuth {

  public referenceFormCandidateNameSource = new Subject<string>();
  referenceFormCandidateName$ = this.referenceFormCandidateNameSource.asObservable();
  public phoneNumber$ = new BehaviorSubject<string>('');

  private static user: User;
  private static _googleUser;

  constructor(private userService: UserService) { }

  public checkLoggedIn() {
    return this.userService.checkSigned();
  }

  public setUser(user: User) {
    UserAuth.user = user;
  }

  public getUser() {
    return UserAuth.user;
  }

  public closeSession() {
    UserAuth.user = null;
  }

  public isAuthorisedAccess(permissions: string[]):boolean {
    return UserAuth.user && _.intersection(permissions, UserAuth.user.permissionList).length !== 0;
  }

  public referenceFormCandidateNameNotification(value: string) {
    this.referenceFormCandidateNameSource.next(value);
  }

  signInWithGoogle() {
    // this.af.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

}
