import { Component } from '@angular/core';
import { SwUpdateService } from '../core/sw-update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  constructor(
    private updateService: SwUpdateService
  ) { }
}
