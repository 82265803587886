import {Component, Input} from '@angular/core';
import {GtCustomComponent, GtRow} from '@angular-generic-table/core';
import {HttpService} from '../../../shared-services/http.service';
import {isNullOrUndefined} from 'util';

@Component({
  moduleId: module.id,
  selector: 'xavier-download-icon',
  templateUrl: 'download-icon.component.html',
  styleUrls: ['download-icon.stylesheet.sass']
})

export class DownloadIconComponent extends GtCustomComponent<GtRow> {

  @Input()
  public row;

  private collapsed = true;

  constructor(
    private httpService: HttpService
  ) {
    super();
  }

  iconClick(id: string, apiName: string, certificateFileName: string) {
    this.httpService.getDocument(`/${apiName}?orderId=${id}&download=true`).subscribe(result => {
      if (!isNullOrUndefined(result.size) && result.size > 0) {
        console.log(result);
        const linkElement = document.createElement('a');
        linkElement.setAttribute('id', 'downloadCertificateLink');
        document.body.appendChild(linkElement);
        linkElement.classList.add('visibility-none');
        const file = new Blob([result], {type: 'application/pdf'});
        if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(file, certificateFileName);
        } else {
          const fileURL = window.URL.createObjectURL(file);
          linkElement.href = fileURL;
          linkElement.download = certificateFileName;
          linkElement.click();
          window.URL.revokeObjectURL(fileURL);
          linkElement.parentNode.removeChild(linkElement);
        }
      }
    });
  }

  public collapse(): void {
    this.collapsed = !this.collapsed;
  }
}
