<div *ngFor="let item of params.items"
     id="{{item.id}}"
     class="row side-menu-item"
     [ngClass]="{ 'active': selectedItem == item.id }"
     (click)="setActiveItem($event, item.id)">
  <div class="col-10 item">
    {{item.title}}
  </div>
  <div class="col-2 text-right">
    <i class="material-icons right-arrow">&#xE5CC;</i>
  </div>
</div>
