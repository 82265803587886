<div *ngIf="isVisible" [id]="formGroupId" class="container panel" [ngClass]="group.level > 1 ? 'panel-noshadow' : ''" [@fadeIn] [class.identity-verification]="identityVerification">
  <div class="row">
    <div class="col-9" [ngClass]="group.repeated === true && group.repeatAutomatic === false ? 'col-9' : 'col-12'">
      <div class="returned-comment">{{commentMsg}}</div>
      <div *ngIf="!isCheck && !identityVerification" class="heading-wrapper">
        <h3 *ngIf="group.level == 1">{{group.groupName}}</h3>
        <h4 *ngIf="group.level > 1">{{group.groupName}}</h4>
        <span *ngIf="isReferenceForm" class="description">{{group.information}}</span>
        <div *ngIf="!isReferenceForm && group.information" class="group-description" [innerHTML]="group.information"></div>
      </div>
    </div>
    <div *ngIf="group.repeated === true && group.repeatAutomatic === false" class="col-3 text-right">
      <i *ngIf="deleteGroupIsVisible" class="material-icons df-repeated-icon delete" (click)="deleteDfGroup()">&#xE872;</i>
      <i *ngIf="addGroupIsVisible" class="material-icons df-repeated-icon add" (click)="addDfGroupManual(null, null, formGroupId)">&#xE147;</i>
    </div>
  </div>
  <form [formGroup]="formGroup" novalidate>
    <div class="row">
      <ng-container *ngFor="let attribute of group.attributes">
        <div [ngClass]="{ 'col-12': attribute.fullwidth, 'col-sm-12 col-xl-6': !attribute.fullwidth }">
          <ng-template df-attribute [component]="attribute" [formGroupId]="formGroupId"></ng-template>
        </div>
      </ng-container>
    </div>
  </form>
  <ng-container *ngFor="let group of group.groups">
    <xavier-df-group [group]="group"
                     [checkId]="checkId"
                     [preview]="preview"
                     [isPristine]="this.isPristine"
                     [repeatedParentPrefix]="repeatedParentPrefix"></xavier-df-group>
  </ng-container>
<!--  <span class="material-icons clear-btn" *ngIf="isClearButtonShown()" (click)="clear()">clear</span>-->
</div>
