<div [id]="elementId" class="dashboard-wrapper" *isAllowed="['CANDIDATE_SCREENING_EDIT']">
  <button *ngIf="!reference" type="button" class="btn xavier-button-plain" (click)="dashboardAction('return')"
          tooltip="Return"
          placement="top"
          container="body"
          containerClass="xavier-popover-dashboard-container">
    <i class="material-icons returnIcon">&#xE31B;</i>
  </button>
  <button type="button" class="btn xavier-button-plain" (click)="dashboardAction('comment')"
          tooltip="Comment"
          placement="top"
          container="body"
          containerClass="xavier-popover-dashboard-container">
    <i class="material-icons commentIcon">&#xE0B9;</i>
  </button>
  <button type="button" class="btn xavier-button-plain" (click)="fileUploadClickHandler()"
          tooltip="Upload File"
          placement="top"
          container="body"
          containerClass="xavier-popover-dashboard-container">
    <i class="material-icons uploadIcon">&#xE2C6;</i>
  </button>
  <button *ngIf="!reference && (screeningStatus === 'IN_PROGRESS' || screeningStatus === 'DELAYED')" type="button" class="btn xavier-button-plain" (click)="dashboardAction('flag')"
          tooltip="Flag"
          placement="top"
          container="body"
          containerClass="xavier-popover-dashboard-container">
    <i class="material-icons flagIcon">&#xE153;</i>
  </button>
  <button *ngIf="!reference && (screeningStatus === 'IN_PROGRESS' || screeningStatus === 'DELAYED')" type="button" class="btn xavier-button-plain" (click)="dashboardAction('adverse')"
          tooltip="Adverse"
          placement="top"
          container="body"
          containerClass="xavier-popover-dashboard-container">
    <i class="material-icons adverseIcon">&#xE5CD;</i>
  </button>
</div>
