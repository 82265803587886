import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {HttpService} from '../../shared-services/http.service';

@Injectable()

export class ScreeningService {

  constructor(private httpService: HttpService) {

  }

  public createScreening(body) {
    return this.put('createScreening', body, null, false);
  }

  public disableScreening(id) {
    return this.post('disableScreening/' + id, null, null, false);
  }

  public enableScreening(id) {
    return this.post('enableScreening/' + id, null, null, false);
  }

  public editScreening(id, body) {
    return this.post('editScreening/' + id, body, null, false);
  }

  public getScreeningsData(pageSize, pageNum, query, sortBy, sortOrder) {
    let params = [
      { 'pageSize': pageSize.toString() },
      { 'pageNum': pageNum.toString() },
      { 'sortBy': sortBy },
      { 'sortOrder': sortOrder },
      { 'query': query }
    ];
    return this.get('getScreeningList', null, params);
  }

  public getScreeningTopBarInfo(id: number) {
    return this.get('getTopBarInfo/' + id, null, null);
  }

  public getScreeningPreviewData(id: number) {
    return this.get('preview/' + id, null, null);
  }

  public getForEdit(screeningId) {
    return this.get('getForEdit/' + screeningId, null, null);
  }

  public getActiveChecks(component) {
    return this.httpService.get('/_ah/api/check/v1', 'getActiveChecks', null)
      .finally( () => {
        component.checksLoaded();
      });
  }

  public getAllChecksOverview() {
    return this.httpService.get('/_ah/api/check/v1', 'getAllChecksOverview', null)
  }

  public getPreviewCheck(id) {
    return this.httpService.get('/_ah/api/check/v1/preview', id, null)
  }

  public post(methodName: string, body: any, params: any, reportProgress: boolean): Observable<any> {
    return this.httpService.post('/_ah/api/screening/v1', methodName, body, params, reportProgress);
  }

  public put(methodName: string, body: any, params: any, reportProgress: boolean): Observable<any> {
    return this.httpService.put('/_ah/api/screening/v1', methodName, body, params, reportProgress);
  }

  public get(methodName: string, body: any, params: any): Observable<any> {
    return this.httpService.get('/_ah/api/screening/v1', methodName, params);
  }
}
