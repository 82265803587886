import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';

@Pipe({name: 'removeCommas'})
export class AddressLookupPipe implements PipeTransform {
  transform(value: string, args: string[]): any {
    if (!value) return value;
    else {
      let newValue = value.replace(/,\s,/g, ', ').replace(/,\s+,/, ', ');
      newValue = newValue.replace(/,\s+,/, ',');
      if (_.endsWith(newValue, ', '))
        newValue = newValue.substr(0,newValue.length - 2);
      return newValue;
    }
  }
}
