import {Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Title} from "@angular/platform-browser";
import {AutocompleteSelectDropdown} from "../../model/autocomplete-select-dropdown.model";
import {AutocompleteSelectDropdownComponent} from "../../cmps-parts/autocomplete-select-dropdown/autocomplete-select-dropdown.component";
import {ChecksSelectorComponent} from "../../cmps-parts/checks-selector/checks-selector.component";
import {ScreeningService} from "../../services/screening.service";
import {UserPermission} from "../../model/permissions.model";
import {UserAuth} from "../../../../core/userAuth.core";
import {ToastyService} from "ng2-toasty";
import {fadeIn, fadeOut} from "../../../../data/animations.data";
import {isNullOrUndefined} from "util";
import {addToaster, getToasterMessagesLabel, setAsTouched} from "../../../utils/functions.utils";
import * as _ from 'lodash';
import {clientsToasterOptions} from "../../../../data/variables.data";
import { DbsService } from '../../services/dbs.service';
import { map } from 'rxjs/operators';
import { DropdownComponentItemSourceFn, DropdownComponentCompareWithFn } from '../../../shared-cmps/dropdown/dropdown.component';

@Component({
  moduleId: module.id,
  selector: 'xavier-edit-screening',
  templateUrl: 'edit-screening.component.html',
  styleUrls: ['edit-screening.stylesheet.sass'],
  animations: [fadeIn, fadeOut]
})

export class EditScreeningComponent implements OnInit, OnDestroy {

  @ViewChildren(AutocompleteSelectDropdownComponent) autocompleteDropdowns: QueryList<AutocompleteSelectDropdownComponent>;
  @ViewChildren(ChecksSelectorComponent) checksSelector: QueryList<ChecksSelectorComponent>;
  @ViewChild('dropdown') dropdown;

  @Input() public loading: boolean = true;
  @Input() public divisionSearchParams: AutocompleteSelectDropdown;

  public editScreeningData: FormGroup;
  public companySelected: boolean = false;
  public showDiscountInput: boolean = false;
  public showDiscountControls: boolean = false;
  public showDiscountEdit: boolean = false;
  public disabled: boolean = false;
  public minimumPriceNotice: boolean = false;
  public minimumPriceToFixed: string = '0';
  public disDisabled: boolean = false;
  public enDisabled: boolean = false;
  public parentComponent = this;
  public screeningDisabled: boolean = false;
  public dbsCheckAdded: any;
  public positionItemSource = [];
  public displayBarredList: boolean = false;
  public loadedChecks = [];
  public isDbsEmployment: boolean = false;
  public displayValues: boolean = false;
  public displayLoader: boolean = false;
  public currentPos: number = -1;
  public selectedPosition: any;
  public dbsScreeningData: any;
  public saasClient: boolean = false;

  private discountPercentage: number;
  private minimumPrice: number;
  private discountedPrice: number;
  private companyId: number;
  private divisionId: number;
  private checksPriceSum: number = 0;
  private screeningData;
  private id: number;
  private routeSub: any;

  constructor(private screeningService: ScreeningService, private fb: FormBuilder, private activatedRoute: ActivatedRoute,
              private router: Router, private titleService: Title, private toastyService: ToastyService, private dbsService: DbsService,
              private userAuth: UserAuth, private _eref: ElementRef,) {
    this.routeSub = this.activatedRoute.params.subscribe(params => {
      this.id = +params['id'];
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Edit Screening - Xavier Eurocom CI');

    this.editScreeningData = this.fb.group({
      screeningName: ['', Validators.required],
      companyName: [{ value: '', disabled: true }, Validators.required],
      divisionName: ['', Validators.required],
      price: [{ value: '0.00', disabled: true }],
      discountedPrice: [''],
      cost: [{ value: '0.00', disabled: true }],
      slaTarget: ['', Validators.required],
      screeningInstructions: [""],
      idCheckType: ["", Validators.required]
    });

    this.divisionSearchParams = new AutocompleteSelectDropdown(
      'divisionName',
      'Division',
      'Select Division...',
      'arrow_drop_down',
      [],
      false,
      this.editScreeningData.get('divisionName')
    );

    this.screeningService.getForEdit(this.id).subscribe( response => {
      if (!isNullOrUndefined(response.id)) {
        this.screeningData = response;
        this.screeningDisabled = this.screeningData.disabled;
        this.discountPercentage = this.screeningData.company.discountPercentage;
        this.minimumPrice = this.screeningData.company.minimumPrice;
        this.editScreeningData.get('screeningName').setValue(this.screeningData.screeningName);
        this.editScreeningData.get('screeningInstructions').setValue(this.screeningData.instructions);
        this.editScreeningData.get('idCheckType').setValue(this.screeningData.idCheckType);
        this.editScreeningData.get('price').setValue(this.screeningData.originalPrice.toFixed(2));
        this.editScreeningData.get('slaTarget').setValue(this.screeningData.slaTarget);
        this.editScreeningData.get('companyName').setValue(this.screeningData.company.companyName);
        this.companySelected = true;
        this.companyId = this.screeningData.company.id;
        this.saasClient = this.screeningData.company.saasClient === 'YES';
        setTimeout(() => {
          this.autocompleteDropdowns.forEach(autocompleteDropdownsInstance => {
            if (autocompleteDropdownsInstance.componentParams.id == 'divisionName') {
              // display the loader on the component appearance
              autocompleteDropdownsInstance.displayLoader = true;
              autocompleteDropdownsInstance.componentParams.formControl.setValue(+this.screeningData.company.id);
              // set the value to empty and don't emit event to enable the form validation on submit
              // until an actual value is selected
              autocompleteDropdownsInstance.componentParams.formControl.setValue(this.screeningData.division.divisionName, { emitEvent: false });
            }
          });
          this.divisionId = this.screeningData.division.id;
        }, 0);
      }
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  public checksLoaded() {
    if (this.screeningData) {
      setTimeout(() => {
        if (this.screeningData['checks'].length > 0) {
          this.screeningData['checks'].forEach(check => {
            this.checksSelector.first.hideCheckRow(check.id);
            this.checksSelector.first.addCheck(check, null);
          });
        }
        if (!isNullOrUndefined(this.screeningData.discountedPrice) && UserPermission.isAllowed(['SCREENING_EDIT_ALL'], this.userAuth.getUser().permissionList)) {
          this.showDiscountInput = true;
          this.showDiscountEdit = true;
        }
      }, 300);
      setTimeout(() => {
        if (!isNullOrUndefined(this.screeningData.discountedPrice) && UserPermission.isAllowed(['SCREENING_EDIT_ALL'], this.userAuth.getUser().permissionList)) {
          document.getElementById('discount-addon').classList.add('no-border');
          document.getElementById('discountedPrice').classList.add('no-border');
          this.discountedPrice = this.screeningData.discountedPrice;
          this.editScreeningData.get('discountedPrice').setValue(this.discountedPrice);
          this.editScreeningData.get('discountedPrice').disable();
        }
        // finally set the loading overlay to false, show the content
        this.loading = false;
      }, 600);
    }
  }

  showDiscountButton() {
    let addedChecks = document.getElementById('added-checks');
    if (addedChecks.children.length === 0)
      this.deleteDiscountPrice();
    return addedChecks.children.length > 0;
  }

  applyDiscount() {
    this.discountedPrice = this.editScreeningData.get('discountedPrice').value;
    this.showDiscountInput = true;
    this.showDiscountControls = true;
  }

  saveDiscountPrice() {
    document.getElementById('discount-addon').classList.add('no-border');
    document.getElementById('discountedPrice').classList.add('no-border');
    this.editScreeningData.get('discountedPrice').disable();
    this.discountedPrice = this.editScreeningData.get('discountedPrice').value;
    this.showDiscountInput = true;
    this.showDiscountControls = false;
    this.showDiscountEdit = true;
  }

  deleteDiscountPrice() {
    this.editScreeningData.get('discountedPrice').setValue('');
    this.editScreeningData.get('discountedPrice').enable();
    this.showDiscountInput = false;
    this.showDiscountControls = false;
    this.showDiscountEdit = false;
  }

  cancelDiscountPrice() {
    if (this.editScreeningData.get('discountedPrice').value !== this.discountedPrice) {
      this.editScreeningData.get('discountedPrice').setValue(this.discountedPrice);
    }
    document.getElementById('discount-addon').classList.add('no-border');
    document.getElementById('discountedPrice').classList.add('no-border');
    this.editScreeningData.get('discountedPrice').disable();
    this.showDiscountInput = true;
    this.showDiscountControls = false;
    this.showDiscountEdit = true;
  }

  editDiscountPrice() {
    document.getElementById('discount-addon').classList.remove('no-border');
    document.getElementById('discountedPrice').classList.remove('no-border');
    this.editScreeningData.get('discountedPrice').enable();
    this.showDiscountEdit = false;
    this.showDiscountInput = true;
    this.showDiscountControls = true;
  }

  onDropdownEntrySelected(entry: any) {
    if (entry.hasOwnProperty('divisionName'))
      this.divisionId = entry.id;
  }

  onCheckAdded(check: any) {
    if (!(this.screeningData.checks.some(chk => chk.id === check.id))) {
      this.screeningData.checks.push(check);
    }
    const loadedDbs = this.screeningData.checks.filter(item => item.dbs);

    let price = !isNaN(parseFloat(check.price)) ? parseFloat(check.price) : 0;
    let minimumPrice = !isNullOrUndefined(this.minimumPrice) ? this.minimumPrice : 0;
    let discountPercentage = !isNullOrUndefined(this.discountPercentage) ? this.discountPercentage : 0;
    let cost = !isNaN(parseFloat(check.cost)) ? parseFloat(check.cost) : 0;
    this.checksPriceSum += price;
    let computedPrice = (this.checksPriceSum - (this.checksPriceSum * discountPercentage * 0.01));
    if (computedPrice < this.minimumPrice) {
      this.editScreeningData.get('price').setValue(minimumPrice.toFixed(2));
      this.minimumPriceNotice = true;
      this.minimumPriceToFixed = minimumPrice.toFixed(2);
    } else {
      this.editScreeningData.get('price').setValue(computedPrice.toFixed(2));
      this.minimumPriceNotice = false;
      this.minimumPriceToFixed = '0';
    }

    this.editScreeningData.get('cost').setValue(
      (parseFloat(this.editScreeningData.get('cost').value) + cost).toFixed(2)
    );
    loadedDbs.length > 1 ? this.removeDBSFormControls() : this.addDBSFormControls();
  }

  onCheckRemoved(check: any) {
    let price = !isNaN(parseFloat(check.price)) ? parseFloat(check.price) : 0;
    let minimumPrice = !isNullOrUndefined(this.minimumPrice) ? this.minimumPrice : 0;
    let discountPercentage = !isNullOrUndefined(this.discountPercentage) ? this.discountPercentage : 0;
    let cost = !isNaN(parseFloat(check.cost)) ? parseFloat(check.cost) : 0;
    this.checksPriceSum -= price;
    let computedPrice = (this.checksPriceSum - (this.checksPriceSum * discountPercentage * 0.01));
    if (computedPrice < this.minimumPrice) {
      this.editScreeningData.get('price').setValue(minimumPrice.toFixed(2));
      this.minimumPriceNotice = true;
      this.minimumPriceToFixed = minimumPrice.toFixed(2);
    } else {
      this.editScreeningData.get('price').setValue(computedPrice.toFixed(2));
      this.minimumPriceNotice = false;
      this.minimumPriceToFixed = '0';
    }

    this.editScreeningData.get('cost').setValue(
      (parseFloat(this.editScreeningData.get('cost').value) - cost).toFixed(2)
    );

    this.screeningData.checks = this.screeningData.checks.filter(loadedCheck => loadedCheck.id !== check.id);
    const loadedDbs = this.screeningData.checks.filter(item => item.dbs);
    loadedDbs.length > 1 ? this.removeDBSFormControls() : this.addDBSFormControls();

  }

  onSubmit({ value, valid } : { value: any, valid: boolean }) {
    let addedChecks = document.getElementById('added-checks');
    let addedChecksError = document.getElementById('added-checks-error');
    if (!valid || addedChecks.children.length === 0) {
      setAsTouched(this.editScreeningData);
      if (addedChecks.children.length === 0)
        addedChecksError.classList.remove('visibility-none');
    } else {
      this.disabled = true;
      let submitButton = document.getElementById('submitButton');
      const spinnerWrap = document.createElement('div');
      spinnerWrap.classList.add('spinner-wrap');
      spinnerWrap.classList.add('button');

      submitButton.innerHTML = '';
      submitButton.appendChild(spinnerWrap);

      if (!addedChecksError.classList.contains('visibility-none'))
        addedChecksError.classList.add('visibility-none');
      let checksId = [];
      _.forEach(addedChecks.children, function(check) {
        checksId.push({ 'id': check.id });
      });
      let body = {
        'screeningName': this.editScreeningData.get('screeningName').value,
        'company': { 'id': this.companyId },
        'division': { 'id': this.divisionId },
        'slaTarget': this.editScreeningData.get('slaTarget').value,
        'checks': checksId,
        'instructions': this.editScreeningData.get('screeningInstructions').value,
        'idCheckType': this.editScreeningData.get('idCheckType').value,
      };
      if (this.editScreeningData.get('discountedPrice').value !== '')
        body['discountedPrice'] = this.editScreeningData.get('discountedPrice').value;
        if (this.dbsCheckAdded && this.saasClient) {
          if (this.dbsCheckAdded.dbsType === 'BASIC') {
            body['basicDbsCheck'] = {...value.basicDbsCheck, id: this.dbsScreeningData.id};
          } else {
            const checkBody = {
              ...value.enhancedDbsCheck,
              position: this.selectedPosition,
              barringListToCheck: this.selectedPosition.barringListToCheck,
              workforceType: this.selectedPosition.workforceType,
              id: this.dbsScreeningData.id
            };

            if (this.dbsCheckAdded.dbsType === 'ENHANCED') {
              body['enhancedDbsCheck'] = checkBody;
            } else {
              body['standardDbsCheck'] = checkBody;
            }
          }
        }
      this.screeningService.editScreening(this.id, body).subscribe(response => {
        if (!isNullOrUndefined(response.id)) {
          this.disabled = false;
          submitButton.innerHTML = "<span>Save Screening</span>";
          this.router.navigate(['/dashboard/screenings/preview/' + response.id], { queryParams: { edited: true } });
        }
      }, err => {
        this.disabled = false;
        submitButton.innerHTML = "<span>Save Screening</span>";
        let action = 'serverError';
        if (err.status === 403)
          action = '403';
        addToaster('error', this.toastyService, action, 'errors');
      });
    }
  }

  disableScreening() {
    this.disDisabled = true;
    this.screeningService.disableScreening(this.id).subscribe(() => {
      this.disDisabled = false;
      this.screeningDisabled = true;
    }, err => {
      this.disDisabled = false;
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
    });
  }

  enableScreening() {
    this.enDisabled = true;
    this.screeningService.enableScreening(this.id).subscribe(() => {
      this.enDisabled = false;
      this.screeningDisabled = false;
    }, err => {
      this.enDisabled = false;
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
    });
  }

  cancelEditScreening() {
    this.router.navigate(['/dashboard/screenings/preview/' + this.id], { queryParams: { edited: true }, skipLocationChange: true });
  }

  addDBSFormControls() {
    this.dbsCheckAdded = this.screeningData.checks.find(({ dbs }) => dbs);
    if (this.dbsCheckAdded && this.saasClient) {
      this.editScreeningData.removeControl('enhancedDbsCheck');
      this.editScreeningData.removeControl('basicDbsCheck');
      this.displayBarredList = false;
      this.dbsCheckAdded.dbsType = this.dbsCheckAdded.checkName.split(' ')[0].toUpperCase();
      if (this.dbsCheckAdded.dbsType !== 'BASIC') {
        this.dbsScreeningData =
          this.dbsCheckAdded.dbsType === "ENHANCED"
            ? this.screeningData["enhancedDbsCheck"]
            : this.screeningData["standardDbsCheck"];
        this.editScreeningData.addControl('enhancedDbsCheck', this.fb.group({
          position: ['', Validators.required],
          organisationName: ['', Validators.required],
          barringListToCheck: [null],
          workforceType: [null],
          receivedBarredList: [null]
        }));
        this.getJobPositions();
        this.editScreeningData.get('enhancedDbsCheck.position').valueChanges.subscribe(value => {
          this.getJobPositions(value);
        });
        if (Object.keys(this.dbsScreeningData).length !== 0) {
          this.selectEntry(this.dbsScreeningData.position);
          this.editScreeningData.get('enhancedDbsCheck.organisationName').setValue(this.dbsScreeningData.organisationName);
        }
      } else {
        this.editScreeningData.addControl('basicDbsCheck', this.fb.group({
          checkPurpose: ['', Validators.required],
          sector: ['', Validators.required],
          role: ['', Validators.required],
        }));
        const dbsRole = this.editScreeningData.get('basicDbsCheck.role');
        const dbsSector = this.editScreeningData.get('basicDbsCheck.sector');
        const checkPurpose = this.editScreeningData.get('basicDbsCheck.checkPurpose');

        this.dbsScreeningData = this.screeningData["basicDbsCheck"];
        checkPurpose.valueChanges.subscribe(value => {
          this.isDbsEmployment = value === 'EMPLOYMENT';
          if (this.isDbsEmployment) {
            dbsRole.setValidators(Validators.required);
            dbsSector.setValidators(Validators.required);
            dbsRole.updateValueAndValidity();
            dbsSector.updateValueAndValidity();
          } else {
            dbsRole.clearValidators();
            dbsSector.clearValidators();
            dbsRole.updateValueAndValidity();
            dbsSector.updateValueAndValidity();
          }
        });
        if (Object.keys(this.dbsScreeningData).length !== 0) {
          checkPurpose.setValue(this.dbsScreeningData.checkPurpose);
          dbsRole.setValue(this.dbsScreeningData.role);
          dbsSector.setValue(this.dbsScreeningData.sector);
        }
      }
    }
  }

  removeDBSFormControls() {
    addToaster('error', this.toastyService, 'Multiple DBS checks', 'errors');
    this.dbsCheckAdded = null;
  }

  public sectorItemSource: DropdownComponentItemSourceFn = () => {
    return this.dbsService.getJobSectors().pipe(map(res => res.items || []));
  }

  public compareFn: DropdownComponentCompareWithFn = (a, b) => a.id === b.id;


  public getJobPositions(searchValue?: string) {
    this.dbsService.getJobPositions(this.dbsCheckAdded.dbsType).subscribe(value => {
      if (!searchValue || searchValue === "") {
        this.positionItemSource = value;
      } else {
        this.positionItemSource = value.filter((position) =>
          position.name.toLowerCase().includes(searchValue.toLowerCase())
        );
      }
    })
  }

  onWrapClick() {
    if (this.positionItemSource.length > 0 && !this.displayValues)
      this.displayValues = true;
    else
      this.displayValues = false;
  }

  selectEntry(entry) {
    if (entry) {
      this.displayBarredList = ['ADULT', 'BOTH'].includes(entry.barringListToCheck) &&
        this.dbsCheckAdded.dbsType === 'ENHANCED';
      this.editScreeningData.get('enhancedDbsCheck.position').patchValue(entry.name);
      this.selectedPosition = entry;
      const barredList = this.editScreeningData.get('enhancedDbsCheck.receivedBarredList');
      if (this.displayBarredList) {
        barredList.setValidators(Validators.required);
        barredList.updateValueAndValidity();
      } else {
        barredList.setValue(null);
        barredList.clearValidators()
        barredList.updateValueAndValidity();
      }

      barredList.setValue(this.dbsScreeningData.receivedBarredList);
      this.onWrapClick();
    }
  }

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target) && this.displayValues === true)
      this.displayValues = !this.displayValues;
  }

  onKeyUp(event) {
    if (this.displayValues && event.code == "ArrowDown" && !(this.currentPos >= this.positionItemSource.length - 1)) {
      this.currentPos++;
      this.dropdown.nativeElement.scrollTo(0, this.currentPos * 44);
    }
    if (this.displayValues && event.code == "ArrowUp" && !(this.currentPos < 1)) {
      this.currentPos--;
    }
    if (this.displayValues && event.code == "Escape")
      this.onWrapClick();
  }

  onChange(event, allow: boolean) {
    if(!allow)
      event.preventDefault();
  }
  onKeyDown(event, allow: boolean) {
    if (!allow && (event.code === 'Backspace' || event.code === 'Delete'))
      return false;
    if (event.code === 'Enter')
      event.preventDefault();
    if (this.displayValues && this.positionItemSource[this.currentPos] && (event.code === 'Enter' || event.code === 'Tab')) {
      this.onWrapClick();
    }
  }

  allowTypingOnKeyPress(allow: boolean) {
    if (!allow)
      return false;
  }

}
