import {Component, OnInit, ViewChild} from "@angular/core";
import {Attribute, ATTRIBUTE_TYPES_MAP} from "../attribute.class";
import {DynamicFormService} from "../../../../services/dynamic-form.service";
import {SignatureFieldComponent} from "./signature-field.component";
import {SIGNATURE_TYPE} from "../../../../../../data/variables.data";

@Component({
  moduleId: module.id,
  selector: 'xavier-signature',
  templateUrl: 'signature.component.html',
  styleUrls: ['signature.stylesheet.sass']
})

export class SignatureAttribute extends Attribute implements OnInit {

  @ViewChild('signatureField', { static: true }) public signatureField: SignatureFieldComponent;

  constructor(public dynamicFormService: DynamicFormService) {
    super(dynamicFormService);
  }

  ngOnInit() {
    let formGroup = this.dynamicFormService.getFormGroup(this.formGroupId);
    let formControl = this.dynamicFormService.attributeToFormControl(this.data);
    formGroup.addControl(this.formGroupId+'-'+this.data.id, formControl);

    this.formGroup = formGroup;
    this.formControl = formControl;
    console.log(this.formGroup);
  }

  public loadSignature(signature) {
    this.signatureField.writeValue(signature);
  }

}

ATTRIBUTE_TYPES_MAP.set(SIGNATURE_TYPE, SignatureAttribute);
