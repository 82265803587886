import {Component, Input, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest, HttpResponse} from "@angular/common/http";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {FormService} from "../../services/form.service";
import {environment} from "../../../../environments/environment";
import {fadeIn, slideIn} from "../../../../data/animations.data";
import {isNullOrUndefined} from "util";

@Component({
  moduleId: module.id,
  selector: 'xavier-step5',
  templateUrl: 'step5.component.html',
  styleUrls: [
    'step5.stylesheet.sass',
    'step5-mediaqueries.stylesheet.sass'
  ],
  animations: [fadeIn, slideIn]
})

export class Step5Component implements OnInit {

  @Input() emailAddress: string;
  @Input() showConfirm: boolean = false;

  public disabled: boolean = false;
  private componentStep = 5;
  private uuid: string;

  constructor(private router: Router, private registrationFormService: FormService, private activeRoute: ActivatedRoute,
              private http: HttpClient) {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd))
        return;
      window.scrollTo(0, 0)
    });
  }

  ngOnInit() {
    if (this.registrationFormService.getCompletedStep() != this.componentStep - 1) {
      this.router.navigate(['/registration/' + this.registrationFormService.getCompletedStep() ]);
    }

    if (this.activeRoute.snapshot.queryParams['uuid']) {
      this.uuid = this.activeRoute.snapshot.queryParams['uuid'];
      this.registrationFormService.lastStepRegistration(true);
    }

    let email;
    if (!isNullOrUndefined(this.registrationFormService.getAll().account))
      email = this.registrationFormService.getAll().account.email;
    this.emailAddress = "your address";
    if (!isNullOrUndefined(email))
      this.emailAddress = email;
  }

  onSubmit() {
    this.disabled = true;
    let resendButton = document.getElementById('resendButton');
    const spinnerWrap = document.createElement('div');
    spinnerWrap.classList.add('spinner-wrap');
    spinnerWrap.classList.add('button');

    resendButton.innerHTML = '';
    resendButton.appendChild(spinnerWrap);

    let headers = new HttpHeaders({ 'Content-Type': 'text/html' });
    const req = new HttpRequest(
      'POST',
      'https://'+environment.storageBucket+'/_ah/api/registration/v1/resendEmail/' + this.uuid,
      null,
      {
        headers: headers,
        withCredentials: true
      }
    );

    this.http.request(req).subscribe(event => {
      if (event instanceof HttpResponse) {
        if (event.status == 204) {
          this.showConfirm = true;
          setTimeout(() => {
            this.showConfirm = false;
            this.disabled = false;
          }, 3000);
        }
      }
    });
  }

}
