import { PercentBarComponent } from "../../cmps-parts/percent-bar/percent-bar.component";
import { StatusIconComponent } from "../../cmps-parts/status-icon/status-icon.component";
import { environment } from '../../../../environments/environment';

export const configObject = {
  settings: [{
    objectKey: 'id',
    visible: false
  }, {
    objectKey: 'candidate',
    sortOrder: 0
  }, {
    objectKey: environment.identityVerificationCheckId,
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId1',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId2',
    visible: false,
    sort: 'disable'
  }, { objectKey: 'checkId98', visible: false, sort: 'disable' }, {
    objectKey: 'checkId3',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId4',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId5',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId6',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId7',
    visible: false,
    sort: 'disable'
  }, { objectKey: 'checkId92', visible: false, sort: 'disable' }, {
    objectKey: 'checkId8',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId9',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId10',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId11',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId12',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId13',
    visible: false,
    sort: 'disable'
  }, { objectKey: 'checkId93', visible: false, sort: 'disable' }, {
    objectKey: 'checkId14',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId15',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId16',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId17',
    visible: false,
    sort: 'disable'
  }, { objectKey: 'checkId84', visible: false, sort: 'disable' }, {
    objectKey: 'checkId18',
    visible: false,
    sort: 'disable'
  }, { objectKey: 'checkId85', visible: false, sort: 'disable' }, {
    objectKey: 'checkId19',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId20',
    visible: false,
    sort: 'disable'
  },
  { objectKey: 'checkId75', visible: false, sort: 'disable' },
  {
    objectKey: 'checkId21',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId22',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId120',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId23',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId24',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId25',
    visible: false,
    sort: 'disable'
  }, { objectKey: 'checkId83', visible: false, sort: 'disable' },
  { objectKey: 'checkId109', visible: false, sort: 'disable' }, {
    objectKey: 'checkId26',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId27',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId28',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId29',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId30',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId31',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId32',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId33',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId34',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId35',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId36',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId37',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId38',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId39',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId40',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId41',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId42',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId43',
    visible: false,
    sort: 'disable'
  }, { objectKey: 'checkId110', visible: false, sort: 'disable' }, {
    objectKey: 'checkId44',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId45',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId46',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId47',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId48',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId49',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId51',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId55',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId56',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId58',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId59',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId60',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId61',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId64',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId65',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId66',
    visible: false,
    sort: 'disable'
  }, {
    objectKey: 'checkId67',
    visible: false,
    sort: 'disable'
  },
  { objectKey: 'checkId68', visible: false, sort: 'disable' },
  { objectKey: 'checkId69', visible: false, sort: 'disable' },
  { objectKey: 'checkId70', visible: false, sort: 'disable' },
  { objectKey: 'checkId71', visible: false, sort: 'disable' },
  { objectKey: 'checkId72', visible: false, sort: 'disable' },
  { objectKey: 'checkId73', visible: false, sort: 'disable' },
  { objectKey: 'checkId74', visible: false, sort: 'disable' },
  { objectKey: 'checkId76', visible: false, sort: 'disable' },
  { objectKey: 'checkId77', visible: false, sort: 'disable' },
  { objectKey: 'checkId78', visible: false, sort: 'disable' },
  { objectKey: 'checkId79', visible: false, sort: 'disable' },
  { objectKey: 'checkId80', visible: false, sort: 'disable' },
  { objectKey: 'checkId81', visible: false, sort: 'disable' },
  { objectKey: 'checkId82', visible: false, sort: 'disable' },
  { objectKey: 'checkId86', visible: false, sort: 'disable' },
  { objectKey: 'checkId87', visible: false, sort: 'disable' },
  { objectKey: 'checkId88', visible: false, sort: 'disable' },
  { objectKey: 'checkId89', visible: false, sort: 'disable' },
  { objectKey: 'checkId91', visible: false, sort: 'disable' },
  { objectKey: 'checkId94', visible: false, sort: 'disable' },
  { objectKey: 'checkId95', visible: false, sort: 'disable' },
  { objectKey: 'checkId96', visible: false, sort: 'disable' },
  { objectKey: 'checkId97', visible: false, sort: 'disable' },
  { objectKey: 'checkId99', visible: false, sort: 'disable' },
  { objectKey: 'checkId100', visible: false, sort: 'disable' },
  { objectKey: 'checkId101', visible: false, sort: 'disable' },
  { objectKey: 'checkId102', visible: false, sort: 'disable' },
  { objectKey: 'checkId103', visible: false, sort: 'disable' },
  { objectKey: 'checkId104', visible: false, sort: 'disable' },
  { objectKey: 'checkId105', visible: false, sort: 'disable' },
  { objectKey: 'checkId107', visible: false, sort: 'disable' },
  { objectKey: 'checkId108', visible: false, sort: 'disable' },
  {
    objectKey: 'checkId62',
    visible: false,
    sort: 'disable'
  },
  { objectKey: 'checkId106', visible: false, sort: 'disable' },
  {
    objectKey: 'company.companyName',
    sortOrder: 0
  }, {
    objectKey: 'division.divisionName',
    sortOrder: 0
  }, {
    objectKey: 'screening.screeningName',
    sortOrder: 0
  }, {
    objectKey: 'user.activated',
    sortOrder: 0
  }, {
    objectKey: 'initiator',
    sort: 'disable'
  }, {
    objectKey: 'screener',
    sort: 'disable'
  }, {
    objectKey: 'lineManager',
    sort: 'disable'
  }, {
    objectKey: 'humanResources',
    sort: 'disable'
  }, {
    objectKey: 'profit',
    sort: 'disable'
  }, {
    objectKey: 'submittedTime',
    sortOrder: 0
  }, {
    objectKey: 'completedTime',
    sortOrder: 0
  }, {
    objectKey: 'invoicedDate',
    sortOrder: 0
  }, {
    objectKey: 'priceAdjustment',
    sortOrder: 0
  }, {
    objectKey: 'chaserEmails',
    sortOrder: 0
  }, {
    objectKey: 'assignedTime',
    sortOrder: 0,
    sort: 'desc'
  }, {
    objectKey: 'slaRemaining',
    sortOrder: 0
  }, {
    objectKey: 'screeningStatus',
    sortOrder: 0
  }, {
    objectKey: 'statusValue',
    visible: false
  }, {
    objectKey: 'statusType',
    visible: false
  }, {
    objectKey: 'statusColor',
    visible: false
  }, {
    objectKey: 'statusBorder',
    visible: false
  }, {
    objectKey: 'statusFill',
    visible: false
  }, {
    objectKey: 'statusSize',
    visible: false
  }],
  fields: [{
    name: 'id',
    objectKey: 'id'
  }, {
    name: 'Name',
    objectKey: 'candidate',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border candidate'
    }
  }, {
    name: 'Personal Data',
    objectKey: 'checkId1',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Recent address history (5yrs)',
    objectKey: 'checkId2',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Recent address history (6yrs)',
    objectKey: 'checkId98',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Recent address history (10yrs)',
    objectKey: 'checkId3',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Most Recent Employment',
    objectKey: 'checkId4',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Employment History 2 years',
    objectKey: 'checkId5',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Employment History 3 years',
    objectKey: 'checkId6',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Employment History 5 years',
    objectKey: 'checkId7',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Employment History 7 years',
    objectKey: 'checkId8',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Employment History 10 years',
    objectKey: 'checkId9',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Highest Education Establishment',
    objectKey: 'checkId10',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Top 2 Education Establishments',
    objectKey: 'checkId11',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Top 3 Education Establishments',
    objectKey: 'checkId12',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'All Education',
    objectKey: 'checkId13',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Professional Qualifications',
    objectKey: 'checkId14',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Professional Memberships',
    objectKey: 'checkId15',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Two Professional References',
    objectKey: 'checkId16',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Directorships',
    objectKey: 'checkId17',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Bankruptcy / Insolvency',
    objectKey: 'checkId18',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'County Court Judgements (CCJ)',
    objectKey: 'checkId19',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Right To Work',
    objectKey: 'checkId20',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  // }, {
  //   name: 'Basic Disclosure',
  //   objectKey: 'checkId21',
  //   columnClass: (row) => {
  //     if (row == undefined)
  //       return 'no-top-border'
  //   }
  }, {
    name: 'Basic DBS',
    objectKey: 'checkId21',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Standard DBS',
    objectKey: 'checkId22',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Enhanced DBS',
    objectKey: 'checkId120',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Extra curricular activity',
    objectKey: 'checkId24',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Confirm ID',
    objectKey: 'checkId25',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Confirm 1 residential address',
    objectKey: 'checkId26',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Confirm 2 residential addresses',
    objectKey: 'checkId27',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Undeclared Links',
    objectKey: 'checkId28',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Credit Check',
    objectKey: 'checkId29',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Notices of Correction',
    objectKey: 'checkId30',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Gap Referencing (> 90 days)',
    objectKey: 'checkId31',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Gap Referencing (> 60 days)',
    objectKey: 'checkId32',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Gap Referencing (> 28 days)',
    objectKey: 'checkId33',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Electoral Roll',
    objectKey: 'checkId34',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Property Ownership',
    objectKey: 'checkId35',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Fraudulent Passport Verification',
    objectKey: 'checkId36',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'NI Number Validity Check',
    objectKey: 'checkId37',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Driving Licence Status Check',
    objectKey: 'checkId38',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Sanction Search BoE',
    objectKey: 'checkId39',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Money Laundering Check',
    objectKey: 'checkId40',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Financial Services Register',
    objectKey: 'checkId41',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Politically Exposed Persons Check',
    objectKey: 'checkId42',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Media Archive',
    objectKey: 'checkId43',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'FCA Regulatory Reference',
    objectKey: 'checkId44',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'International Criminal Record Search (Sweden)',
    objectKey: 'checkId45',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'International Criminal Record Search (Ireland)',
    objectKey: 'checkId46',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Address Verification (Ireland)',
    objectKey: 'checkId47',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Credit Check (Ireland)',
    objectKey: 'checkId48',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Directorship (Ireland)',
    objectKey: 'checkId49',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Two Personal References',
    objectKey: 'checkId51',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'International Criminal Record Search (Uruguay)',
    objectKey: 'checkId55',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'International Bankruptcy Search (Uruguay)',
    objectKey: 'checkId56',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: '1 Character Reference',
    objectKey: 'checkId58',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Gap Referencing (> 31 days)',
    objectKey: 'checkId59',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'International Criminal Record Search (Portugal)',
    objectKey: 'checkId60',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'New fields',
    objectKey: 'checkId61',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Declaration',
    objectKey: 'checkId62',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Education verification questionnaire',
    objectKey: 'checkId64',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Character reference questionnaire',
    objectKey: 'checkId65',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Employment reference questionnaire',
    objectKey: 'checkId66',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Professional Membership verification questionnaire',
    objectKey: 'checkId67',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Professional qualification verification questionnaire',
    objectKey: 'checkId68',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Resume check',
    objectKey: 'checkId69',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Other Employment/Business/Activity/Service',
    objectKey: 'checkId70',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Contractual Restrictions',
    objectKey: 'checkId71',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Legal Information and Consent',
    objectKey: 'checkId72',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'International Bankruptcy Search (Poland)',
    objectKey: 'checkId73',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'International Criminal Record Search (Poland)',
    objectKey: 'checkId74',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Right To Work',
    objectKey: 'checkId75',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'US Federal State-wide Bankruptcy History',
    objectKey: 'checkId76',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'US National Records Search',
    objectKey: 'checkId77',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'International CRC Germany',
    objectKey: 'checkId78',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Check Requirement for International Searches',
    objectKey: 'checkId79',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'International Bankruptcy Search (Sweden)',
    objectKey: 'checkId80',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'International Criminal Record Search (France)',
    objectKey: 'checkId81',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'International Criminal Record Search (Switzerland)',
    objectKey: 'checkId82',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Confirm ID (International)',
    objectKey: 'checkId83',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Directorships (International)',
    objectKey: 'checkId84',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Bankruptcy/Insolvency (International)',
    objectKey: 'checkId85',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Global Monitor Check',
    objectKey: 'checkId86',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'International CRC Norway',
    objectKey: 'checkId87',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Individual Civil Search International',
    objectKey: 'checkId88',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Credit Check International',
    objectKey: 'checkId89',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'International Criminal Record Check (Hong Kong)',
    objectKey: 'checkId91',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Employment History 6 years',
    objectKey: 'checkId92',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: '3rd Level College/University Qualifications',
    objectKey: 'checkId93',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'USA Criminal Search',
    objectKey: 'checkId94',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'USA Civil Search',
    objectKey: 'checkId95',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'USA SSN Trace',
    objectKey: 'checkId96',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Employment History since working with SIG',
    objectKey: 'checkId97',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Recent address history (6yrs)',
    objectKey: 'checkId98',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: '2 References',
    objectKey: 'checkId99',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: '2nd Reference - Referee questionnaire',
    objectKey: 'checkId100',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: '2 References – Care Environment',
    objectKey: 'checkId101',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Employment reference questionnaire - Care Environment',
    objectKey: 'checkId102',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: '2nd Reference - Referee questionnaire - Care Environment',
    objectKey: 'checkId103',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'ISA Adult First',
    objectKey: 'checkId104',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'International Criminal Record Search (Netherlands)',
    objectKey: 'checkId105',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Declaration (International)',
    objectKey: 'checkId106',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Legal Information and Consent (International)',
    objectKey: 'checkId107',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Criminal Record Search Certificate',
    objectKey: 'checkId108',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Driving License Check',
    objectKey: 'checkId109',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Social media search',
    objectKey: 'checkId110',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'ID',
    objectKey: environment.identityVerificationCheckId,
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Company',
    objectKey: 'company.companyName',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Division',
    objectKey: 'division.divisionName',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Screening Name',
    objectKey: 'screening.screeningName',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Candidate Status',
    objectKey: 'user.activated',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Initiator',
    objectKey: 'initiator',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Screener',
    objectKey: 'screener',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Line Manager',
    objectKey: 'lineManager',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Human Resources',
    objectKey: 'humanResources',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Profit (Ex. VAT)',
    objectKey: 'profit',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Submitted Date',
    objectKey: 'submittedTime',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Completed Date',
    objectKey: 'completedTime',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Invoice Date',
    objectKey: 'invoicedDate',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Additional Costs',
    objectKey: 'priceAdjustment',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Chaser Emails',
    objectKey: 'chaserEmails',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border'
    }
  }, {
    name: 'Assigned Date',
    objectKey: 'assignedTime',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border assigned-time'
    }
  }, {
    name: 'SLA Days Remaining',
    objectKey: 'slaRemaining',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border sla-remaining'
    },
    columnComponent: {
      type: PercentBarComponent
    }
  }, {
    name: 'Overall Status',
    objectKey: 'screeningStatus',
    columnClass: (row) => {
      if (row == undefined)
        return 'no-top-border screening-status';
    },
    columnComponent: {
      type: StatusIconComponent
    }
  }],
  data: [],
  info: {}
};
