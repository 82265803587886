import {Component, Input, OnInit} from '@angular/core';
import {BsDropdownConfig} from "ngx-bootstrap/dropdown";
import {
  DISABLED,
  FOR_REVIEW,
  IN_PROGRESS,
  PASSED,
  REJECTED,
  REQUIRED,
  RETURNED,
} from "../../../../data/variables.data";
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'xavier-filters-dropdown',
  templateUrl: 'filters-dropdown.component.html',
  styleUrls: ['filters-dropdown.stylesheet.sass'],
  providers: [{ provide: BsDropdownConfig, useValue: { autoClose: false } }]
})

export class FiltersDropdownComponent implements OnInit {

  @Input() public componentParams;
  public status: { isopen: boolean } = { isopen: false };

  private defaultStatus = [REQUIRED, PASSED, FOR_REVIEW, IN_PROGRESS, RETURNED];

  constructor() {

  }

  ngOnInit() {
    this.applyFilter(this.defaultStatus);
    this.activeFilter();
  }

  public applyFilter = function(params) {
    this.componentParams.table.gtApplyFilter({
      statusValue: params
    });
  };

  activeFilter() {
    let activeCheckboxes = [];
    let checkboxes = document.getElementsByClassName('form-check-input');
    _.forEach(checkboxes, function(checkbox) {
      if (checkbox.checked)
        activeCheckboxes.push(checkbox.id);
    });
    if (activeCheckboxes.length === this.defaultStatus.length && activeCheckboxes.every((v, i) => v === this.defaultStatus[i]))
      return false;
    if (activeCheckboxes.length === 0)
      return false;
    return true;
  }

  clickCheckBox(filterName) {
    let checkbox = <HTMLInputElement>document.getElementById(filterName);
    checkbox.checked = !(checkbox.checked);
  }

  initCheckbox(name: string) {
    return "checked" ? [REJECTED, DISABLED].indexOf(name) === -1 : "";
  }

  filterInfoToStatus(filter: any) {
    return {
      'statusValue': filter.value,
      'statusType': filter.icon,
      'statusColor': filter.color,
      'statusBorder': filter.border,
      'statusFill': filter.fill,
      'statusSize': filter.size,
    };
  }

  saveFilters($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    let activeCheckboxes = [];
    let checkboxes = document.getElementsByClassName('form-check-input');
    _.forEach(checkboxes, function(checkbox) {
      if (checkbox.checked)
        activeCheckboxes.push(checkbox.id);
    });
    this.applyFilter(activeCheckboxes);
    this.status.isopen = !this.status.isopen;
  }

  resetFilters() {
    let checkboxes = document.getElementsByClassName('form-check-input');
    _.forEach(checkboxes, ( function(checkbox) {
      if (this.defaultStatus.indexOf(checkbox.id) !== -1)
        checkbox.checked = true;
      else
        checkbox.checked = false;
    }).bind(this) );
    this.applyFilter(this.defaultStatus);
  }

  change(value: boolean): void {
    this.status.isopen = value;
  }

}
