import { AfterContentInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { RadioComponent } from '../radio.component';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.sass'],
})
export class RadioGroupComponent<T> implements AfterContentInit {

  @Input()
  public name: string;

  @ContentChildren(RadioComponent)
  public radios: QueryList<RadioComponent<T>>;

  public ngAfterContentInit(): void {
    this.radios.last.last = true;
    this.radios.forEach(r => r.name = this.name);
  }
}
