<i class="material-icons undo-signature" (click)="undoSignature()">&#xE5D5;</i>

<canvas class="pad" #canvas></canvas>

<!-- <signature-pad [options]="signaturePadOptions" -->
<!--                (onEndEvent)="drawComplete()" -->
<!--                (onBeginEvent)="drawBegin()" -->
<!--                (window:resize)="onResize()" -->
<!--                (window:scroll)="onScroll()"> -->
<!-- </signature-pad> -->
