import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {fadeIn} from "../../../../data/animations.data";
import {UserService} from "../../services/user.service";
import {isNullOrUndefined} from "util";

@Component({
  moduleId: module.id,
  selector: 'xavier-login',
  templateUrl: 'activation.component.html',
  styleUrls: ['activation.stylesheet.sass'],
  animations: [fadeIn]
})

export class ActivationComponent implements OnInit, OnDestroy {

  @Input() loading: boolean = true;
  @Input() active: boolean = false;
  @Input() resubmit: boolean = false;
  @Input() error: boolean = false;

  private token: string;
  private resubmitApp: string;
  private verifySubscription: any;

  constructor(private userService: UserService, private activeRoute: ActivatedRoute, private titleService: Title) {

  }

  ngOnInit() {
    this.titleService.setTitle('Account Activation - Xavier Eurocom CI');

    this.token = this.activeRoute.snapshot.queryParams['token'];
    this.resubmitApp = this.activeRoute.snapshot.queryParams['resubmitApplication'];

    if (this.resubmitApp) {
      this.loading = false;
      this.resubmit = true;
    } else if (this.token) {
      this.verifySubscription = this.userService.verifyEmail(this.token).subscribe(response => {
          this.loading = false;
          this.active = true;
        },
        error => {
          this.loading = false;
          this.error = true;
        });
    } else {
      this.loading = false;
      this.error = true;
    }
  }

  ngOnDestroy() {
    if(!isNullOrUndefined(this.verifySubscription))
      this.verifySubscription.unsubscribe();
  }

}
