import {Component, ElementRef, Input, OnDestroy, OnInit} from "@angular/core";
import {DynamicFormService} from "../../../../services/dynamic-form.service";
import {isNullOrUndefined} from "util";

@Component({
  moduleId: module.id,
  selector: 'xavier-dashboard-toolbar',
  templateUrl: 'dashboard-toolbar.component.html'
})

export class DashboardToolbarAttribute implements OnInit, OnDestroy {

  @Input() public elementId: string;
  @Input() public reference: boolean;
  @Input() public screeningStatus: string;

  private showToolbarSub: any;
  private screeningStatusSub: any;

  constructor(public dynamicFormService: DynamicFormService, private _eref: ElementRef) {
    this.showToolbarSub = this.dynamicFormService.showToolbar$.subscribe(toolbarId => {
      if (toolbarId !== '' && !isNullOrUndefined(this._eref.nativeElement.firstElementChild) &&
        this._eref.nativeElement.firstElementChild.id === toolbarId) {
        if (!isNullOrUndefined(this._eref.nativeElement.firstElementChild) &&
            document.getElementById(this._eref.nativeElement.firstElementChild.id).innerHTML !== '')
          this._eref.nativeElement.classList.remove('visibility-none');
      }
      else if (toolbarId === '')
        this._eref.nativeElement.classList.add('visibility-none');
    });
    this.screeningStatusSub = this.dynamicFormService.screeningStatus$.subscribe(newStatus => {
      this.screeningStatus = newStatus;
    });
  }

  ngOnInit() {
    this._eref.nativeElement.classList.add('visibility-none');
  }

  ngOnDestroy() {
    this.showToolbarSub.unsubscribe();
    this.screeningStatusSub.unsubscribe();
  }

  public dashboardAction(type: string) {
    let modalData = {};
    switch (type) {
      case 'return':
        modalData = {
          title: 'Enter your reason why is returned',
          submitButtonIcon: 'keyboard_return',
          submitButtonLabel: 'Return',
          type: 'return'
        };
        break;
      case 'comment':
        modalData = {
          title: 'Enter your comment below',
          submitButtonIcon: 'comment',
          submitButtonLabel: 'Comment',
          type: 'comment'
        };
        break;
      case 'upload':
        modalData = {
          type: 'upload'
        };
        break;
      case 'flag':
        modalData = {
          title: 'Enter the reason why you\'re flagging',
          submitButtonIcon: 'flag',
          submitButtonLabel: 'Flag',
          type: 'flag'
        };
        break;
      case 'adverse':
        modalData = {
          title: 'Comment on why the information is marked as adverse',
          submitButtonIcon: 'close',
          submitButtonLabel: 'Adverse',
          type: 'adverse'
        };
        break;
      default:
        break;
    }
    modalData['elementId'] = this.elementId;
    this.dynamicFormService.toolbarModalNotification(modalData);
  }

  fileUploadClickHandler() {
    document.getElementById('fileUploader').click();
    this.dashboardAction('upload');
  }

}
