import {AbstractControl} from "@angular/forms";

export class SelectDropdown {
  id: string;
  title: string;
  placeholder: string;
  smallHelp: string;
  icon: string;
  data: any;
  formControl: AbstractControl;
  createItemLabel: string;
  resubmit: boolean;

  constructor (id: string, title: string, placeholder: string, smallHelp: string, icon: string, data: any,
               formControl: any, createItemLabel: string, resubmit: boolean) {
    this.id = id;
    this.title = title;
    this.placeholder = placeholder;
    this.smallHelp = smallHelp;
    this.icon = icon;
    this.data = data;
    this.formControl = formControl;
    this.createItemLabel = createItemLabel;
    this.resubmit = resubmit;
  }
}
