import {Component, Input} from '@angular/core';
import {GtCustomComponent, GtRow} from '@angular-generic-table/core';

@Component({
  moduleId: module.id,
  selector: 'xavier-status-icon',
  templateUrl: 'status-icon.component.html',
  styleUrls: ['status-icon.stylesheet.sass']
})

export class StatusIconComponent extends GtCustomComponent<GtRow> {

  @Input() public row;

  constructor() {
    super();
  }

}
