<ng2-toasty [position]="'top-center'" class="xavier-toasty"></ng2-toasty>
<div class="xavier-reference-form-wrapper container" [@fadeIn]>
  <div *ngIf="loading" class="xavier-center-page" [@fadeIn]>
    <div class="spinner-container not-button"><div class="spinner-wrap"></div></div>
  </div>
  <div *ngIf="showForm" [@fadeIn]>
    <div class="row header sticky-top">
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-9">
        <h1>{{referenceForm.candidateName}}</h1>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 text-right">
        <button type="button" id="submitCheck" class="btn xavier-button passed" [disabled]="disabled"
                (click)="submitResponse()">
          Submit Response
        </button>
      </div>
    </div>
    <xavier-df-group [group]="referenceForm" [checkId]="referenceForm.id" [isReferenceForm]="true"></xavier-df-group>
  </div>
  <div *ngIf="submitted" class="row text-center" [@fadeIn]>
    <div class="col-12">
      <div class="header">
        Thank you for submitting this form.
      </div>
    </div>
  </div>
  <div *ngIf="wrongToken" class="row text-center" [@fadeIn]>
    <div class="col-12">
      <div class="header">
        This reference is no longer required.
      </div>
    </div>
  </div>
</div>
