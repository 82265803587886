import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { IMyDate, IMyDpOptions } from 'mydatepicker';
import { Observable } from 'rxjs';
import { CountryService } from '../../services/country.service';
import { map } from 'rxjs/operators';

const date2MyDate = (date: Date): IMyDate => {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate()}
};

const xdate2mydp = (date: string) => {
  if (!date) {
    return undefined;
  }

  const parts = date.split("-");
  return {
    date: {
      year: +parts[0],
      month: +parts[1],
      day: +parts[2],
    }
  }
}

const mydp2xdate = (mydp) => {
  if (!mydp) {
    return null;
  }
  const date = mydp.date

  return [date.year, date.month, date.day]
    .map(p => "" + p)
    .map((p, i) => i == 0 ? p : p.padStart(2, "0"))
    .join("-");
}

@Component({
  selector: "xavier-candidate-id-verification-group",
  templateUrl: "./candidate-id-verification-group.component.html",
  styleUrls: ["./candidate-id-verification-group.component.sass"],
})
export class CandidateIdVerificationGroupComponent implements OnInit {
  @Input() form: any;
  @Input() groupName: string;
  @Input() candidateInfo: string;
  @Output() onCheckboxClicked = new EventEmitter<any>();
  @Output() onRunIDCheckCompleted = new EventEmitter<any>();

  public isRunIDCheckVisible = false;
  public noUkAddresses: boolean;
  public countriesList$: Observable<any>;

  public dpOptions: IMyDpOptions = {
    dateFormat: "dd mmm yyyy",
    editableDateField: false,
    openSelectorOnInputClick: true,
    showClearDateBtn: true,
    disableSince: date2MyDate(new Date()),
  };

  public xdate2mydp = xdate2mydp;
  public mydp2xdate = mydp2xdate;

  constructor(private countryService: CountryService) {}

  ngOnInit(): void {
    this.countriesList$ = this.countryService
      .getCountries()
      .pipe(
        map((data) =>
          data.map((item) => ({ value: item.name, label: item.name }))
        )
      );
  }

  onCheckboxChange(item: AbstractControl) {
    this.onCheckboxClicked.emit(item);
  }

  public onRunIDCheckClicked() {
    this.isRunIDCheckVisible = true;
  }

  onRunIDCheckDone(result) {
    this.isRunIDCheckVisible = false;
    this.onRunIDCheckCompleted.emit(result);
  }
}
