<ng2-toasty [position]="'top-center'" class="xavier-toasty"></ng2-toasty>
<div class="xavier-countersign-overview-wrapper container full-width" [@fadeIn]>
  <form>
    <div class="row header">
      <div class="col-sm-6 col-xl-9">
        <h1>Countersign</h1>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-xl-2 xavier-dropdown-wrapper">
        <i class="material-icons dropdown-caret no-label">&#xE5C5;</i>
        <select class="form-control xavier-dropdown items-per-page" id="itemsPerPage" #rowLength
          (change)="countersignTable.changeRowLength(rowLength.value)">
          <option *ngFor="let item of itemsPerPageValues" [value]="item.name">{{item.value}}</option>
        </select>
      </div>
      <div class="col-sm-12 col-xl-4"></div>
      <div class="form-group col-sm-12 col-xl-6 xavier-input-text text-right">
        <div class="input-group">
          <span class="input-group-addon" id="search-addon"><i class="material-icons">&#xE8B6;</i></span>
          <input type="text" #search class="form-control" id="countersignsSearchInput" placeholder="Search countersigns..."
            aria-describedby="search-addon" [formControl]="searchControl">
        </div>
        <i *ngIf="search.value !== ''" class="material-icons search-reset" (click)="resetSearch()">&#xE5C9;</i>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <generic-table #countersignTable [gtClasses]="'table-sm table-hover'" [gtSettings]="configObject.settings"
          [gtFields]="configObject.fields" [gtOptions]="{lazyLoad: true, stack: true, rowSelection: true}"
          [gtInfo]="configObject.info" [(gtData)]="configObject.data" (gtEvent)="trigger($event)">
        </generic-table>
      </div>
      <div class="col-6">
        <gt-pagination [gtClasses]="'pagination-sm'" [genericTable]="countersignTable"></gt-pagination>
      </div>
      <div class="col-6">
        <small><gt-table-info class="form-text text-muted mb-2 text-right"
            [genericTable]="countersignTable"></gt-table-info></small>
      </div>
    </div>

    <!-- Submit Button -->
    <div class="row my-5">
      <div class="col text-right">
        <xavier-button (click)="onSubmit()" [isLoading]="submitLoading">
         Countersign
        </xavier-button>
      </div>
    </div>
  </form>
  <div class="row legend">
    <div class="col-12">
      <span class="text-muted">Statuses Legend</span>
    </div>
    <div class="col-12 icons">
      <div *ngFor="let status of countersignStatuses" class="filter-wrapper">
        <xavier-status-icon [row]="convertStatus(status)"></xavier-status-icon>
        <span class="filter-value">{{status.value}}</span>
      </div>
    </div>
  </div>
</div>
