import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CandidateService} from "../../services/candidate.service";
import {TableService} from "../../services/table.service";
import {BsDropdownConfig} from "ngx-bootstrap/dropdown";
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'xavier-columns-dropdown',
  templateUrl: 'columns-dropdown.component.html',
  styleUrls: ['columns-dropdown.stylesheet.sass'],
  providers: [{ provide: BsDropdownConfig, useValue: { autoClose: false } }]
})

export class ColumnsDropdownComponent implements OnInit, OnDestroy {

  @Input() public componentParams;
  @Input() public view;
  @Input() public hiddenNumber: string = '';
  public status: { isopen: boolean } = { isopen: false };

  private columnConfig: any = {};
  private tableColumnsSub: any;

  constructor(private candidatesService: CandidateService, private tableService: TableService) {

  }

  ngOnInit() {
    this.tableColumnsSub = this.tableService.currentConfigColumns.subscribe(config => {
      if (config.length !== 0) {
        this.columnConfig['columns'] = Object.assign({}, config);
        this.setDropdownButtonLabel();
      }
    });
  }

  ngOnDestroy() {
    this.tableColumnsSub.unsubscribe();
  }

  clickCheckBox(columnName) {
    let checkbox = <HTMLInputElement>document.getElementById(columnName);
    checkbox.checked = !(checkbox.checked);
  }

  saveColumns($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    let activeCheckboxes = [];
    let checkboxes = document.getElementsByClassName('form-check-input');
    _.forEach(checkboxes, function(checkbox) {
      if (checkbox.checked)
        activeCheckboxes.push(checkbox.id);
    });
    this.tableService.changeTableHiddenColumns(activeCheckboxes);
    this.setDropdownButtonLabel();
    this.candidatesService.setViewConfig(this.view, activeCheckboxes).subscribe(() => {});
    this.status.isopen = !this.status.isopen;
  }

  setDropdownButtonLabel() {
    let inConfig = 0;
    if (this.columnConfig.hasOwnProperty('columns')) {
      inConfig = Object.keys(this.columnConfig['columns']).length;
      if (inConfig < this.componentParams.columns.length)
        this.hiddenNumber = this.componentParams.columns.length - inConfig + ' hidden';
      else
        this.hiddenNumber = '';
    }
  }

  initCheckbox(name: string) {
    return _.values(this.columnConfig['columns']).indexOf(name) !== -1 ? 'checked' : '';
  }

  resetColumns() {
    let checkboxes = document.getElementsByClassName('form-check-input');
    _.forEach(checkboxes, ( function(checkbox) {
      checkbox.checked = false;
    }).bind(this) );
    this.setDropdownButtonLabel();
  }

  change(value: boolean): void {
    this.status.isopen = value;
  }

}
