import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {HttpService} from "../../shared-services/http.service";
import {isNullOrUndefined} from "util";

@Injectable()
export class UserService {

  constructor(private httpService: HttpService) {

  }

  public checkSigned() {
    return this.httpService.get("/_ah/api/user/v1", 'checkSigned', null);
  }

  public checkSignedWithGoogle(googleUser: any):Observable<any> {
    let data = { 'googleToken': googleUser.uid };
    return this.sendData("checkLogin", data, {}, false);
  }

  public logOut():Observable<any> {
    return this.get("logOut", null);
  }

  public logInAs(clientId: number) {
    let methodName = 'logInAs';
    return this.get(methodName, [{clientId: clientId}]);
  }

  public getAllUsers(pageSize, pageNum, query, sortBy, sortOrder) {
    let params = [
      { 'pageSize': pageSize.toString() },
      { 'pageNum': pageNum.toString() },
      { 'sortBy': sortBy },
      { 'sortOrder': sortOrder },
      { 'query': query }
    ];
    return this.get('getAllUsers', params);
  }

  public getScreeners() {
    return this.get("getScreeners", null);
  }

  public getRoles(userId?: any) {
    let params = [];
    if (!isNullOrUndefined(userId))
      params = [
        { 'editedUserId': userId }
      ];
    return this.get("getRoles", params);
  }

  public getForEdit(userId) {
    return this.get("getForEdit/" + userId, null);
  }

  public addUser(body) {
    return this.put('addUser', body, null, false);
  }

  public editUser(body) {
    return this.post('editUser', body, null, false);
  }

  public enableUser(userId) {
    return this.post('enableUser/' + userId, null, null, false);
  }

  public resendInvite(userId) {
    return this.post('resendInvite/' + userId, null, null, null);
  }

  public disableUser(userId) {
    return this.post('disableUser/' + userId, null, null, false);
  }

  public post(methodName: string, body: any, params: any, reportProgress: boolean): Observable<any> {
    return this.httpService.post("/_ah/api/user/v1", methodName, body, params, reportProgress);
  }

  public put(methodName: string, body: any, params: any, reportProgress: boolean): Observable<any> {
    return this.httpService.put("/_ah/api/user/v1", methodName, body, params, reportProgress);
  }

  public get(methodName: string, params: any): Observable<any> {
    return this.httpService.get("/_ah/api/user/v1", methodName, params);
  }

  public sendData(methodName: string, body: any, params: any, reportProgress: boolean):Observable<any> {
    return this.httpService.post("/_ah/api/user/v1", methodName, body, params, reportProgress);
  }
}
