import {AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, Renderer2, ViewChild, ViewChildren} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {DatePipe} from "@angular/common";
import {Subject} from "rxjs";
import {ToastyService} from "ng2-toasty";
import {AutocompleteSelectDropdownComponent} from "../../cmps-parts/autocomplete-select-dropdown/autocomplete-select-dropdown.component";
import {AutocompleteSelectDropdown} from "../../model/autocomplete-select-dropdown.model";
import {CandidateService} from "../../services/candidate.service";
import {FormService} from "../../../registration/services/form.service";
import {UserPermission} from "../../model/permissions.model";
import {UserAuth} from "../../../../core/userAuth.core";
import {candidatesToasterOptions} from "../../../../data/variables.data";
import {fadeIn, fadeOut} from "../../../../data/animations.data";
import {attributeRegexValidator, emailValidator, ValidateEmailExists} from "../../../utils/form.validators";
import {
  addToaster, getEventLogActivityTypeLabel, getFormGroupControlsWithErrors,
  getFormGroupValidationErrors,
  getToasterMessagesLabel,
  setAsTouched
} from '../../../utils/functions.utils';
import {isNullOrUndefined} from "util";
import {CandidateEmailValidator} from '../../../utils/candidate-email.validator';
import {map, takeUntil} from 'rxjs/operators';
import { DropdownComponentCompareWithFn, DropdownComponentItemSourceFn } from '../../../shared-cmps/dropdown/dropdown.component';
import { CompanyService } from '../../services/company.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  moduleId: module.id,
  selector: 'xavier-edit-candidate',
  templateUrl: 'edit-candidate.component.html',
  styleUrls: ['edit-candidate.stylesheet.sass'],
  animations: [fadeIn, fadeOut],
  providers: [CandidateEmailValidator],
})

export class EditCandidateComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren(AutocompleteSelectDropdownComponent) autocompleteDropdowns: QueryList<AutocompleteSelectDropdownComponent>;
  @ViewChild('termsAndConditonsModal', { static: true }) public termsAndConditonsModal;
  @Input() public loading: boolean = true;
  @Input() public companySearchParams: AutocompleteSelectDropdown;
  @Input() public divisionSearchParams: AutocompleteSelectDropdown;
  @Input() public lineManagerSearchParams: AutocompleteSelectDropdown;
  @Input() public humanResourcesSearchParams: AutocompleteSelectDropdown;
  @Input() public screeningSearchParams: AutocompleteSelectDropdown;
  @Input() public screenerSearchParams: AutocompleteSelectDropdown;
  @Input() public eventLogLoading: boolean = true;

  public editCandidateData: FormGroup;
  public eventLog;
  public activated: boolean = false;
  public disabled: boolean = false;
  public resendDisabled: boolean = false;
  public companyHiddenPadding: boolean = false;
  public candidateData;
  public saasClient;
  public displayIdVerification: boolean = false;
  public displayWorkAtHomeAddress: boolean = false;
  public modalRef: BsModalRef;

  private companyId: string;
  private divisionId: number;
  private lineManagerId: number = null;
  private humanResourcesId: number = null;
  private screeningId: number;
  private screenerId: number;
  private id;
  private routeSub: any;
  private eventLogSub;
  private destroy$: Subject<any> = new Subject();

  constructor(
    private fb: FormBuilder,
    private candidateService: CandidateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastyService: ToastyService,
    private formService: FormService,
    private renderer: Renderer2,
    private titleService: Title,
    private userAuth: UserAuth,
    private datepipe: DatePipe,
    private candidateEmailValidator: CandidateEmailValidator,
    private companyService: CompanyService,
    private modalService: BsModalService,
  ) {
    this.routeSub = this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
    });

    this.eventLogSub = this.candidateService.eventLogEntry$.takeUntil(this.destroy$).subscribe(
      entry => {
        let eventPanel = document.getElementById('eventLogPanel');
        let newEntry = document.createElement('div');
        newEntry.classList.add('row');
        let executor = document.createElement('div');
        executor.classList.add('col-8');
        executor.classList.add('executor');
        executor.innerText = entry.executorName + ', ' + entry.executorRole;
        let timestamp = document.createElement('div');
        timestamp.classList.add('col-4');
        timestamp.classList.add('timestamp');
        timestamp.classList.add('text-right');
        timestamp.innerText = this.datepipe.transform(new Date(), 'dd MMM yyyy, HH:mm');
        let activity = document.createElement('div');
        activity.classList.add('col-12');
        activity.classList.add('activity');
        activity.innerText = getEventLogActivityTypeLabel(entry.activityType);
        if (entry.details !== '')
          activity.innerText += ' - ' + entry.details;

        newEntry.appendChild(executor);
        newEntry.appendChild(timestamp);
        newEntry.appendChild(activity);

        this.renderer.insertBefore(eventPanel, newEntry, eventPanel.firstChild);
      });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd))
        return;
      window.scrollTo(0, 0)
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Edit Candidate - Xavier Eurocom CI');

    this.editCandidateData = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['',
        [Validators.required, emailValidator],
        ValidateEmailExists.createValidator(this.formService)
      ],
      phonePrefix: ['+44', Validators.required],
      phone: ['', Validators.minLength(10)],
      companyName: ['', Validators.required],
      divisionNamePersonnel: ['', Validators.required],
      positionApplied: ['',  [Validators.required, attributeRegexValidator('^[a-zA-Z ]{1,60}$')]],
      purchaseOrderNumber: [''],
      lineManagerName: [''],
      humanResourcesName: [''],
      screeningName: ['', Validators.required],
      screenerName: [''],
      idVerifier: [null],
      workAtHomeAddress: [null],
      receivePaperCertificate: [null],
      receivePaperCertificateAtCurrentAddress: [null],
      addressToReceivePaperCertificate: [null],
      nameOfEmployerOnCertificate: [null, Validators.maxLength(60)],
      confirmTermsAndConditions: [null],
    });

    this.companySearchParams = new AutocompleteSelectDropdown(
      'companyName',
      'Company',
      'Search Companies...',
      'search',
      [],
      true,
      this.editCandidateData.get('companyName')
    );

    this.divisionSearchParams = new AutocompleteSelectDropdown(
      'divisionNamePersonnel',
      'Division',
      'Select Division...',
      'arrow_drop_down',
      [],
      false,
      this.editCandidateData.get('divisionNamePersonnel')
    );

    this.lineManagerSearchParams = new AutocompleteSelectDropdown(
      'lineManagerName',
      'Line Manager',
      'Select Line Manager...',
      'arrow_drop_down',
      [],
      false,
      this.editCandidateData.get('lineManagerName')
    );

    this.humanResourcesSearchParams = new AutocompleteSelectDropdown(
      'humanResourcesName',
      'Human Resources',
      'Select Human Resources...',
      'arrow_drop_down',
      [],
      false,
      this.editCandidateData.get('humanResourcesName')
    );

    this.screeningSearchParams = new AutocompleteSelectDropdown(
      'screeningName',
      'Screening',
      'Select Screening...',
      'arrow_drop_down',
      [],
      false,
      this.editCandidateData.get('screeningName')
    );

    this.screenerSearchParams = new AutocompleteSelectDropdown(
      'screenerName',
      'Screener',
      'Select Screener...',
      'arrow_drop_down',
      [],
      false,
      this.editCandidateData.get('screenerName')
    );

    this.candidateService.getForEdit(this.id).subscribe( response => this.init(response));

    this.eventLogSub = this.candidateService.getEventLog(this.id).subscribe(eventLog => {
      if (!isNullOrUndefined(eventLog.items)) {
        this.eventLog = eventLog.items;
        this.eventLogLoading = false;
      }
    });
  }

  private init(response) {
    if (!isNullOrUndefined(response.id)) {
      this.candidateData = response;
      this.activated = this.candidateData.activated;
      this.editCandidateData.get('firstName').setValue(this.candidateData.firstName);
      this.editCandidateData.get('lastName').setValue(this.candidateData.lastName);
      this.editCandidateData.get('email').setValue(this.candidateData.email);
      this.editCandidateData.get('phonePrefix').setValue(this.candidateData.phonePrefix);
      this.editCandidateData.get('phone').setValue(this.candidateData.phone);

      this.editCandidateData.get('companyName').disable();
      this.editCandidateData.get('companyName').setValue(this.candidateData.company.companyName, {emitEvent: false});
      this.companyId = this.candidateData.company.id;

      this.autocompleteDropdowns.forEach(autocompleteDropdownsInstance => {
        switch (autocompleteDropdownsInstance.componentParams.id) {
          case 'divisionNamePersonnel':
            if (!this.candidateData.screeningStarted) {
              autocompleteDropdownsInstance.componentParams.formControl.setValue(+this.candidateData.company.id);
            } else {
              autocompleteDropdownsInstance.componentParams.formControl.disable();
            }
            this.divisionId = this.candidateData.division.id;
            autocompleteDropdownsInstance.componentParams.formControl.setValue(this.candidateData.division.divisionName, {emitEvent: false});
            break;
          case 'lineManagerName':
            if (!isNullOrUndefined(this.candidateData.lineManager)) {
              autocompleteDropdownsInstance.componentParams.formControl.setValue(this.companyId + '/' + this.divisionId);
              autocompleteDropdownsInstance.componentParams.formControl.setValue(this.candidateData.lineManager.fullName, {emitEvent: false});
              this.lineManagerId = this.candidateData.lineManager.id;
            } else if (!isNullOrUndefined(this.companyId) && this.divisionId) {
              autocompleteDropdownsInstance.componentParams.formControl.setValue(this.companyId + '/' + this.divisionId);
              autocompleteDropdownsInstance.componentParams.formControl.setValue('-', {emitEvent: false});
            } else {
              autocompleteDropdownsInstance.componentParams.formControl.setValue('-', {emitEvent: false});
            }
            break;
          case 'humanResourcesName':
            if (!isNullOrUndefined(this.candidateData.humanResources)) {
              autocompleteDropdownsInstance.componentParams.formControl.setValue(this.companyId + '/' + this.divisionId);
              autocompleteDropdownsInstance.componentParams.formControl.setValue(this.candidateData.humanResources.fullName, {emitEvent: false});
              this.humanResourcesId = this.candidateData.humanResources.id;
            } else if (!isNullOrUndefined(this.companyId) && this.divisionId) {
              autocompleteDropdownsInstance.componentParams.formControl.setValue(this.companyId + '/' + this.divisionId);
              autocompleteDropdownsInstance.componentParams.formControl.setValue('-', {emitEvent: false});
            } else {
              autocompleteDropdownsInstance.componentParams.formControl.setValue('-', {emitEvent: false});
            }
            break;
          case 'screeningName':
            if (!this.candidateData.screeningStarted) {
              autocompleteDropdownsInstance.componentParams.formControl.setValue(this.companyId + '/' + this.divisionId);
            } else {
              autocompleteDropdownsInstance.componentParams.formControl.disable();
            }
            this.screeningId = this.candidateData.screening.id;
            autocompleteDropdownsInstance.componentParams.formControl.setValue(this.candidateData.screening.screeningName, {emitEvent: false});
            break;
          case 'screenerName':
            if (!this.candidateData.screeningStarted) {
              autocompleteDropdownsInstance.componentParams.formControl.setValue('-');
            } else {
              autocompleteDropdownsInstance.componentParams.formControl.disable();
            }
            if (this.candidateData.hasOwnProperty('screener')) {
              this.screenerId = this.candidateData.screener.id;
              autocompleteDropdownsInstance.componentParams.formControl.setValue(this.candidateData.screener.fullName, {emitEvent: false});
            } else {
              autocompleteDropdownsInstance.componentParams.formControl.setValue('-', {emitEvent: false});
            }
            break;
          default:
            break;
        }
      });

      this.editCandidateData.get('positionApplied').setValue(this.candidateData.positionApplied);
      this.editCandidateData.get('purchaseOrderNumber').setValue(this.candidateData.purchaseOrderNumber);
      this.displayIdVerification = this.candidateData.idVerifier && (this.saasClient || this.candidateData.company.saasClient === 'YES');
      if (this.displayIdVerification) {
        this.editCandidateData.get('idVerifier').setValue(this.candidateData.idVerifier);
        this.editCandidateData.get('workAtHomeAddress').setValue(this.candidateData.workAtHomeAddress);
        this.editCandidateData.get('confirmTermsAndConditions').setValue(this.candidateData.confirmTermsAndConditions);
        this.editCandidateData.get('receivePaperCertificate').setValue(this.candidateData.receivePaperCertificate === "true");
        this.editCandidateData.get('receivePaperCertificateAtCurrentAddress').setValue(this.candidateData.receivePaperCertificateAtCurrentAddress === "true");
        this.editCandidateData.get('addressToReceivePaperCertificate').setValue(this.candidateData.addressToReceivePaperCertificate);
        this.editCandidateData.get('nameOfEmployerOnCertificate').setValue(this.candidateData.nameOfEmployerOnCertificate);

      }
      const emailFc = this.editCandidateData.get('email');
      this.candidateEmailValidator.ignoredEmail = emailFc.value;
      this.candidateEmailValidator.candidateId = this.id;
      this.candidateEmailValidator.companyId = this.companyId;
      emailFc.setAsyncValidators(this.candidateEmailValidator.validateEmail());
      this.editCandidateData.get('companyName').valueChanges.pipe(
        takeUntil(this.destroy$),
      ).subscribe(value => {
        if (value)
          this.candidateEmailValidator.companyId = this.companyId;
        else
          this.candidateEmailValidator.candidateId = null;

        emailFc.updateValueAndValidity();
      });

      this.editCandidateData.get("receivePaperCertificate").valueChanges.subscribe((receiveCertifcate => {
        const receiveAtCurrentAddress = this.editCandidateData.get("receivePaperCertificateAtCurrentAddress");
        // receiveAtCurrentAddress.setValue(null);
        if (receiveCertifcate) {
          receiveAtCurrentAddress.setValidators(Validators.required);
          receiveAtCurrentAddress.updateValueAndValidity();
        } else {
          receiveAtCurrentAddress.clearValidators();
          receiveAtCurrentAddress.updateValueAndValidity();
        }
      }));
      this.editCandidateData.get("receivePaperCertificateAtCurrentAddress").valueChanges.subscribe((receiveAtCurrentAddress => {
        const currentAddress = this.editCandidateData.get("addressToReceivePaperCertificate");
        currentAddress.setValue(null);
        if (receiveAtCurrentAddress === false) {
          currentAddress.setValidators(Validators.required);
          currentAddress.updateValueAndValidity()
        } else {
          currentAddress.clearValidators();
          currentAddress.updateValueAndValidity()
        }
      }));

      // finally set the loading overlay to false, show the content
      this.loading = false;
    }
  }

  ngAfterViewInit() {
    let user = this.userAuth.getUser();
    this.saasClient = user && user.saasClient === "YES";
    if (!UserPermission.isAllowed(['CANDIDATE_NEW_ALL'], user.permissionList)) {
      setTimeout(() => {
        this.onDropdownEntrySelected({ companyName: user.companyId, id: user.companyId });
        this.companyHiddenPadding = true;
      }, 0);
    }
  }

  resendInvite() {
    this.resendDisabled = true;
    let resendButton = document.getElementById('resendButton');
    const spinnerWrap = document.createElement('div');
    spinnerWrap.classList.add('spinner-wrap');
    spinnerWrap.classList.add('button');

    resendButton.innerHTML = '';
    resendButton.appendChild(spinnerWrap);

    this.candidateService.resendInvite(this.id).subscribe(response => {
      if (!isNullOrUndefined(response.id)) {
        this.candidateService.sendEventLogEntry(response);
        let config = candidatesToasterOptions;
        config.msg = getToasterMessagesLabel('resend', 'candidates');
        this.toastyService.info(config);
      }
    }, err => {
      this.resendDisabled = false;
      resendButton.innerHTML = "<span>Resend Invite</span>";
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
    }, () => {
      this.resendDisabled = false;
      resendButton.innerHTML = "<span>Resend Invite</span>";
    });
  }

  onSubmit({ value, valid } : { value: any, valid: boolean }) {
    if (this.candidateData.email === this.editCandidateData.get('email').value) {
      let errors = getFormGroupValidationErrors(this.editCandidateData);
      if (errors.length == 1 && errors[0]['key'] == 'emailExists')
        valid = true;
    }
    if (!valid) {
      setAsTouched(this.editCandidateData);
    } else {
      this.disabled = true;
      let submitButton = document.getElementById('submitButton');
      const spinnerWrap = document.createElement('div');
      spinnerWrap.classList.add('spinner-wrap');
      spinnerWrap.classList.add('button');

      submitButton.innerHTML = '';
      submitButton.appendChild(spinnerWrap);

      let lineManager = null;
      if (!isNullOrUndefined(this.lineManagerId))
        lineManager = { 'id': this.lineManagerId };
      let humanResources = null;
      if (!isNullOrUndefined(this.humanResourcesId))
        humanResources = { 'id': this.humanResourcesId };
      let body: any = {
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'phonePrefix': value.phonePrefix,
        'phone': value.phone,
        'company': { 'id': this.companyId },
        'division': { 'id': this.divisionId },
        'positionApplied': value.positionApplied,
        'purchaseOrderNumber': value.purchaseOrderNumber,
        'lineManager': lineManager,
        'humanResources': humanResources,
        'screening': { 'id': this.screeningId },
        'screener': { 'id': this.screenerId, 'fullName': value.screenerName }
      };

      if (this.displayIdVerification) {
        body = {
          ...body,
          idVerifier: value.idVerifier,
          workAtHomeAddress: value.workAtHomeAddress,
          confirmTermsAndConditions: value.confirmTermsAndConditions,
          receivePaperCertificate: value.receivePaperCertificate,
          receivePaperCertificateAtCurrentAddress: value.receivePaperCertificateAtCurrentAddress,
          addressToReceivePaperCertificate: value.addressToReceivePaperCertificate,
          nameOfEmployerOnCertificate: value.nameOfEmployerOnCertificate
        };
      }

      this.candidateService.editCandidate(body, this.id).subscribe(response => {
        if (!isNullOrUndefined(response.id)) {
          this.disabled = false;
          submitButton.innerHTML = "<span>Save Candidate</span>";
          this.router.navigate(['/dashboard/candidates']);
        }
      }, err => {
        this.disabled = false;
        submitButton.innerHTML = "<span>Save Candidate</span>";
        let action = 'serverError';
        if (err.status === 403)
          action = '403';
        addToaster('error', this.toastyService, action, 'errors');
      });
    }
  }

  onDropdownEntrySelected(entry: any, type?: string) {
    if (entry.hasOwnProperty('companyName')) {
      this.companyId = entry.id;
      setTimeout(() => {
        this.autocompleteDropdowns.forEach(autocompleteDropdownsInstance => {
          if (autocompleteDropdownsInstance.componentParams.id == 'divisionNamePersonnel') {
            autocompleteDropdownsInstance.componentParams.formControl.setValue(+entry.id);
            // set the value to empty and don't emit event to enable the form validation on submit
            // until an actual value is selected
            autocompleteDropdownsInstance.componentParams.formControl.setValue('', { emitEvent: false });
          }
        });
      }, 0);
    }
    if (entry.hasOwnProperty('divisionName')) {
      this.divisionId = entry.id;
      setTimeout(() => {
        let additionalParams = this.autocompleteDropdowns.find(dropdown => dropdown.componentParams.id === 'divisionNamePersonnel').componentParams['additionalParams'];
        this.autocompleteDropdowns.forEach(autocompleteDropdownsInstance => {
          if (autocompleteDropdownsInstance.componentParams.id == 'lineManagerName') {
            autocompleteDropdownsInstance.componentParams.formControl.setValue(this.companyId+'/'+entry.id);
            // if the division selected has a default line manager we set the input field to that value
            // otherwise set the value to empty and don't emit event to enable the form validation on submit
            // until an actual value is selected
            let value = (additionalParams.hasOwnProperty('selectedLineManager')) ? additionalParams.selectedLineManager.fullName : '';
            if (value !== '')
              this.lineManagerId = additionalParams.selectedLineManager.id;
            autocompleteDropdownsInstance.componentParams.formControl.setValue(value, { emitEvent: false });
          }
          if (autocompleteDropdownsInstance.componentParams.id == 'humanResourcesName') {
            autocompleteDropdownsInstance.componentParams.formControl.setValue(this.companyId+'/'+entry.id);
            // if the division selected has a default human resources we set the input field to that value
            // otherwise set the value to empty and don't emit event to enable the form validation on submit
            // until an actual value is selected
            let value = (additionalParams.hasOwnProperty('selectedHumanResources')) ? additionalParams.selectedHumanResources.fullName : '';
            if (value !== '')
              this.humanResourcesId = additionalParams.selectedHumanResources.id;
            autocompleteDropdownsInstance.componentParams.formControl.setValue(value, { emitEvent: false });
          }
          if (autocompleteDropdownsInstance.componentParams.id == 'screeningName') {
            autocompleteDropdownsInstance.componentParams.formControl.setValue(this.companyId+'/'+entry.id);
            //  set the value to empty and don't emit event to enable the form validation on submit
            // until an actual value is selected
            autocompleteDropdownsInstance.componentParams.formControl.setValue('', { emitEvent: false });
          }
          if (autocompleteDropdownsInstance.componentParams.id == 'screenerName') {
            autocompleteDropdownsInstance.componentParams.formControl.setValue('-');
            // this dropdown doesn't need any parameter but we have to trigger the action to fill the dropdown
            autocompleteDropdownsInstance.componentParams.formControl.setValue('', { emitEvent: false });
          }
        });
      }, 0);
    }
    if (entry.hasOwnProperty('fullName') && type === 'lineManagerName')
      this.lineManagerId = entry.id;
    if (entry.hasOwnProperty('fullName') && type === 'humanResourcesName')
      this.humanResourcesId = entry.id;
    if (entry.hasOwnProperty('screeningName')) {
      this.screeningId = entry.id;

      const idVerifier = this.editCandidateData.get('idVerifier');
      const workAtHomeAddress = this.editCandidateData.get('workAtHomeAddress');
      const confirmTermsAndConditions = this.editCandidateData.get('confirmTermsAndConditions');
      const receivePaperCertificate = this.editCandidateData.get('receivePaperCertificate');
      const receivePaperCertificateAtCurrentAddress = this.editCandidateData.get('receivePaperCertificateAtCurrentAddress');
      const addressToReceivePaperCertificate = this.editCandidateData.get('addressToReceivePaperCertificate');
      const nameOfEmployerOnCertificate = this.editCandidateData.get('nameOfEmployerOnCertificate');
      idVerifier.setValue(null);
      workAtHomeAddress.setValue(null);
      confirmTermsAndConditions.setValue(null);
      receivePaperCertificateAtCurrentAddress.setValue(null);
      receivePaperCertificate.setValue(null);
      addressToReceivePaperCertificate.setValue(null);
      nameOfEmployerOnCertificate.setValue(null);

      this.displayIdVerification = this.saasClient && entry && entry.dbs;
      this.displayWorkAtHomeAddress = this.displayIdVerification && entry.dbsCheckType === 'ENHANCED';

      if (this.displayIdVerification) {
        idVerifier.setValidators(Validators.required);
        confirmTermsAndConditions.setValidators(Validators.required);
        idVerifier.updateValueAndValidity();
        confirmTermsAndConditions.updateValueAndValidity();
        receivePaperCertificate.setValidators(Validators.required);
        receivePaperCertificate.updateValueAndValidity();
        nameOfEmployerOnCertificate.setValidators(Validators.required);
        nameOfEmployerOnCertificate.updateValueAndValidity();
        if (this.displayWorkAtHomeAddress) {
          workAtHomeAddress.setValidators(Validators.required);
          workAtHomeAddress.updateValueAndValidity();
        } else {
          workAtHomeAddress.clearValidators();
          workAtHomeAddress.updateValueAndValidity();
        }
      } else {
        idVerifier.clearValidators();
        confirmTermsAndConditions.clearValidators();
        idVerifier.updateValueAndValidity();
        confirmTermsAndConditions.updateValueAndValidity();
        receivePaperCertificate.clearValidators();
        receivePaperCertificate.updateValueAndValidity()
        nameOfEmployerOnCertificate.clearValidators();
        nameOfEmployerOnCertificate.updateValueAndValidity()
      }
    }
    if (entry.hasOwnProperty('fullName') && type === 'screenerName')
      this.screenerId = entry.id;
  }

  cancelEditCandidate() {
    this.router.navigate(['/dashboard/candidates']);
  }

  public ngOnDestroy(): void {
    this.routeSub.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  public idVerifierItemSource: DropdownComponentItemSourceFn = term => {
    const companyId = this.companyId;
    return this.companyService.getCompanyIdVerifiers(companyId).pipe(map(res => res.items || []));
  }

  public compareFn: DropdownComponentCompareWithFn = (a, b) => a.id === b.id;

  openTermsAndCondition() {
    this.modalRef = this.modalService.show(this.termsAndConditonsModal, { animated: false, backdrop: false, class: 'terms-and-condition-modal' });
  }
}
