import {Component, OnInit, ViewChild} from "@angular/core";
import {Attribute, ATTRIBUTE_TYPES_MAP} from "../attribute.class";
import {DynamicFormService} from "../../../../services/dynamic-form.service";
import {IMyDateModel, IMyDateRange, IMyDpOptions, IMyOptions, MyDatePicker} from 'mydatepicker';
import {DATE_TYPE, DF_VALIDATION_FN} from "../../../../../../data/variables.data";
import {isNullOrUndefined} from "util";

@Component({
  moduleId: module.id,
  selector: 'xavier-date',
  templateUrl: 'date.component.html',
  styleUrls: ['date.stylesheet.sass']
})

export class DateAttribute extends Attribute implements OnInit {

  @ViewChild('dfdatepicker', { static: true }) public dfdatepicker: MyDatePicker;

  public dfDatePickerOptions: IMyDpOptions = {
    dateFormat: 'dd mmm yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    selectionTxtFontSize: '16px',
    showClearDateBtn: true
  };
  public startDateOptions: IMyDpOptions = null;
  public endDateOptions: IMyDpOptions = null;
  public componentDate: any = null;
  // private _componentDate: any = null;
  // public get componentDate() { console.log("get componentDate: ", this._componentDate); return this._componentDate }
  // public set componentDate(value) { console.log("set componentDate: ", value); if (value && !value.jsdate) { value = { jsdate: value } }; this._componentDate = value }
  public startAttributeId: string = null;
  public endAttributeId: string = null;
  public hidden: boolean = false;

  constructor(public dynamicFormService: DynamicFormService) {
    super(dynamicFormService);
  }

  ngOnInit() {
    if (this.data.validation === DF_VALIDATION_FN.ONLY_PAST
        || this.data.validation === DF_VALIDATION_FN.ONLY_PAST_AND_AFTER_DOB) {
      let todayJs: Date = new Date();
      this.dfDatePickerOptions['disableSince'] = {
        year: todayJs.getFullYear(),
        month: todayJs.getMonth() + 1,
        day: todayJs.getDate() + 1
      };
    } else if (this.data.validation === DF_VALIDATION_FN.ONLY_FUTURE) {
      let todayJs: Date = new Date();
      this.dfDatePickerOptions['disableUntil'] = {
        year: todayJs.getFullYear(),
        month: todayJs.getMonth() + 1,
        day: todayJs.getDate() - 1
      };
    }

    let formGroup = this.dynamicFormService.getFormGroup(this.formGroupId);
    let formControl = this.dynamicFormService.attributeToFormControl(this.data);

    formGroup.addControl(this.formGroupId + '-' + this.data.id, formControl);

    this.formGroup = formGroup;
    this.formControl = formControl;
  }

  public onCalendarToggle(event: number): void {
    this.disableAlreadySelectedDateRanges();
    if (event === 1) { // datepicker opens, check if BE says to open year
      let yearButton = document.getElementsByClassName('yearlabel');
      [].forEach.call(yearButton, function (el) {
        setTimeout(() => {
          el.click();
        }, 0);
      });
    }
  }

  public setStartDateProperties (endAttributeId: string) {
    this.disableAlreadySelectedDateRanges();
    this.startDateOptions = this.dfDatePickerOptions;
    this.endAttributeId = endAttributeId;
  }

  public setEndDateProperties (startAttributeId: string) {
    this.disableAlreadySelectedDateRanges();
    this.endDateOptions = this.dfDatePickerOptions;
    this.startAttributeId = startAttributeId;
  }

  public onDateChanged(event: IMyDateModel) {
    // console.log("event: ", event);
    if (!event.jsdate)
      return;

    if (!isNullOrUndefined(this.startDateOptions))
      this.onStartDateChanged(event);
    if (!isNullOrUndefined(this.endDateOptions))
      this.onEndDateChanged(event);

    this.disableAlreadySelectedDateRanges();
  }

  private onStartDateChanged(event: IMyDateModel) {
    let d: Date = new Date(event.jsdate.getTime());
    this.componentDate = event;
    // this.componentDate = new Date(d);


    if (!isNullOrUndefined(this.endAttributeId)) {
      const endAttribute = this.dynamicFormService.getAttributeComponentInstance(this.endAttributeId) as DateAttribute;
      const copy: IMyOptions = this.getCopyOfOptions(endAttribute.dfDatePickerOptions);
      copy.disableUntil = {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate()
      };
      d = new Date();
      d.setDate(d.getDate() + 1);
      copy.disableSince = {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate()
      };
      endAttribute.endDateOptions = copy;
      endAttribute.dfDatePickerOptions = endAttribute.endDateOptions;
    }
  }

  private onEndDateChanged(event: IMyDateModel) {
    let d: Date = new Date(event.jsdate.getTime());
    this.componentDate = event;
    // this.componentDate = new Date(d);

    if (!!this.startAttributeId) {
      const startAttribute = this.dynamicFormService.getAttributeComponentInstance(this.startAttributeId) as DateAttribute;
      const copy: IMyOptions = this.getCopyOfOptions(startAttribute.dfDatePickerOptions);
      copy.disableSince = {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate()
      };
      startAttribute.startDateOptions = copy;
      startAttribute.dfDatePickerOptions = startAttribute.startDateOptions;
    }
  }

  private getCopyOfOptions(options): IMyOptions {
    return JSON.parse(JSON.stringify(options));
  }

  private disableAlreadySelectedDateRanges() {
    let allDates = this.dynamicFormService.getAllDateConditionValues();
    let disableDateRanges = [];
    allDates.forEach((value, key) => {
      // console.log("value: ", value);
      let componentCheckId;
      if (this.formGroupId.indexOf('#') !== -1)
        componentCheckId = this.formGroupId.split('#')[1].split('-')[0];
      else
        componentCheckId = this.formGroupId.split('-')[0];
      let keyCheckId;
      if (key.indexOf('#') !== -1)
        keyCheckId = key.split('#')[1].split('-')[0];
      else
        keyCheckId = key.split('-')[0];
      // make sure we're comparing dates for the same check
      if (key !== this.formGroupId && keyCheckId === componentCheckId) {
        let range = {
          begin: {
            year: value.dateStartValue.jsdate.getFullYear(),
            month: value.dateStartValue.jsdate.getMonth() + 1,
            day: value.dateStartValue.jsdate.getDate()
          },
          end: {
            year: value.dateEndValue.jsdate.getFullYear(),
            month: value.dateEndValue.jsdate.getMonth() + 1,
            day: value.dateEndValue.jsdate.getDate()
          }
        } as IMyDateRange;
        disableDateRanges.push(range);
      }
    });
    if (disableDateRanges.length > 0) {
      this.dfDatePickerOptions['disableDateRanges'] = disableDateRanges;
      // force the setOption method, data binding not supported/working?
      this.dfdatepicker.setOptions();
    } else {
      this.dfDatePickerOptions['disableDateRanges'] = [];
      // force the setOption method, data binding not supported/working?
      this.dfdatepicker.setOptions();
    }
  }

  show(show: boolean) {
    this.hidden = !show;
  }

}

ATTRIBUTE_TYPES_MAP.set(DATE_TYPE, DateAttribute);
