<ng2-toasty [position]="'top-center'" class="xavier-toasty"></ng2-toasty>
<div class="xavier-add-screening-wrapper container" [@fadeIn]>
  <div class="row header">
    <div class="col-11">
      <h1>Add New Screening</h1>
    </div>
    <div class="col-1 text-right">
      <button type="button" class="close close-screening" aria-label="Close" (click)="cancelAddScreening()">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
  <form [formGroup]="addScreeningData" novalidate (ngSubmit)="onSubmit(addScreeningData)">
    <div class="row">
      <div class="col-sm-12 col-xl-3">
        <h4>Screening Details</h4>
      </div>
      <div class="col-sm-12 col-xl-3">
        <div class="form-group xavier-input-text">
          <label for="screeningName">Screening Name</label>
          <input type="text" class="form-control" id="screeningName" formControlName="screeningName" placeholder="Screening Name">
          <xavier-error-message [control]="addScreeningData.get('screeningName')"></xavier-error-message>
        </div>
      </div>
      <div class="col-sm-12 col-xl-3" *isAllowed="['SCREENING_NEW_ALL']">
        <div class="form-group xavier-input-text">
          <label for="companyName">Company</label>
          <xavier-autocomplete-select-dropdown (dropdownEntrySelectedEmitter)="onDropdownEntrySelected($event)"
                                               [componentParams]="companySearchParams">
          </xavier-autocomplete-select-dropdown>
        </div>
      </div>
      <div class="col-sm-12 col-xl-3">
        <div *ngIf="companySelected" class="form-group xavier-input-text" [@fadeIn]>
          <label for="divisionName">Division</label>
          <xavier-autocomplete-select-dropdown (dropdownEntrySelectedEmitter)="onDropdownEntrySelected($event)"
                                               [componentParams]="divisionSearchParams">
          </xavier-autocomplete-select-dropdown>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-xl-3">
        <h4>Pricing and SLA</h4>
      </div>
      <div class="col-sm-12 col-xl-9 pricing">
        <div class="form-group xavier-input-text">
          <label for="price">Price</label>
          <div class="input-group">
            <span class="input-group-addon">£</span>
            <input type="text" class="form-control" id="price" formControlName="price" placeholder="Price">
          </div>
          <xavier-error-message [control]="addScreeningData.get('price')"></xavier-error-message>
        </div>
        <div *isAllowed="['SCREENING_NEW_ALL']">
          <div *ngIf="showDiscountButton() && !showDiscountInput && !showDiscountControls" class="form-group" [@fadeIn]>
            <label>&nbsp;<!-- alignment label --></label>
            <button type="button" class="btn btn-block xavier-button-plain" (click)="overridePrice()">Override Price</button>
          </div>
          <div *ngIf="showDiscountInput" class="form-group" [@fadeIn]>
            <label for="discountedPrice">Discount Price</label>
            <div class="form-group xavier-input-text input-group discount">
              <span class="input-group-addon" id="discount-addon">£</span>
              <input type="number" class="form-control" id="discountedPrice" formControlName="discountedPrice" placeholder="Discount">
            </div>
          </div>
          <div *ngIf="showDiscountControls" class="form-group-controls" [@fadeIn]>
            <button type="button" class="btn discount-controls save" (click)="saveDiscountPrice()">
              <i class="material-icons">&#xE86C;</i>
            </button>
            <button type="button" class="btn discount-controls delete" (click)="deleteDiscountPrice()">
              <i class="material-icons">&#xE872;</i>
            </button>
            <button type="button" class="btn discount-controls cancel" (click)="cancelDiscountPrice()">
              <i class="material-icons">&#xE5CD;</i>
            </button>
          </div>
          <div *ngIf="showDiscountEdit" class="form-group-edit" [@fadeIn]>
            <button type="button" class="btn discount-controls edit" (click)="editDiscountPrice()">
              <i class="material-icons">&#xE254;</i>
            </button>
          </div>
        </div>
        <div class="form-group xavier-input-text" *isAllowed="['CHECK_COST']">
          <label for="cost">Cost</label>
          <div class="input-group">
            <span class="input-group-addon">£</span>
            <input type="text" class="form-control" id="cost" formControlName="cost" placeholder="Cost">
          </div>
          <xavier-error-message [control]="addScreeningData.get('cost')"></xavier-error-message>
        </div>
        <div class="form-group-last xavier-input-text">
          <label for="slaTarget">SLA</label>
          <input type="number" class="form-control" id="slaTarget" formControlName="slaTarget" placeholder="SLA">
          <xavier-error-message [control]="addScreeningData.get('slaTarget')"></xavier-error-message>
        </div>
      </div>
      <div class="col-12 minimum-price-notice-spacer">
        <div *ngIf="minimumPriceNotice">Minimum Price: £<span class="brand-color">{{minimumPriceToFixed}}</span></div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-xl-3">
        <h4>ID Check Type</h4>
      </div>
      <div class="col">
        <div class="form-group xavier-input-text">
          <app-radio-group name="idCheckType" id="idCheckType">
            <app-radio formControlName="idCheckType" [value]="'NONE'" label="None"></app-radio>
            <app-radio formControlName="idCheckType" [value]="'DBS_LEVEL'" label="DBS Level"></app-radio>
            <app-radio formControlName="idCheckType" [value]="'ANY_COUNTRY_PASSPORT'" label="Any Country Passport"></app-radio>
            <app-radio formControlName="idCheckType" [value]="'ANY_ID'" label="Any Form of ID"></app-radio>
          </app-radio-group>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-xl-3">
        <h4>Screening Instructions</h4>
      </div>
      <div class="col">
        <div class="form-group xavier-input-text">
          <label for="screeningInstructions">Screening Instructions</label>
          <xavier-autoexpand-textarea id="screeningInstructions" formControlName="screeningInstructions"></xavier-autoexpand-textarea>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-xl-3">
        <h4>Checks</h4>
      </div>
      <div class="col-sm-12 col-xl-9">
        <xavier-checks-selector [parent]="parentComponent" (checkAddedEmitter)="onCheckAdded($event)" (checkRemovedEmitter)="onCheckRemoved($event)">
        </xavier-checks-selector>
      </div>
    </div>
    <ng-container *ngIf="dbsCheckAdded && saasClient">
      <div *ngIf="dbsCheckAdded.dbsType === 'BASIC'">
        <div formGroupName="basicDbsCheck">
          <div class="row">
            <div class="col-sm-12 col-xl-3">
              <h4>Additional Details</h4>
            </div>
            <div class="col-sm-12 col-xl-6">
              <div class="form-group xavier-input-text">
                <label for="checkPurpose">Purpose of Check</label>
                <app-radio-group name="checkPurpose" id="checkPurpose">
                  <app-radio formControlName="checkPurpose" [value]="'EMPLOYMENT'" label="Employment"></app-radio>
                  <app-radio formControlName="checkPurpose" [value]="'PERSONAL_INTEREST'" label="Personal Interest"></app-radio>
                </app-radio-group>
                <xavier-error-message [control]="addScreeningData.get('basicDbsCheck.checkPurpose')"></xavier-error-message>
              </div>
            </div>
          </div>
          <ng-container *ngIf="isDbsEmployment">
            <div class="row mb-250">
              <div class="col-sm-12 col-xl-3"></div>
              <div class="col-sm-12 col-xl-4">
                <div class="form-group xavier-input-text">
                  <label for="role">Job Role</label>
                  <input type="text" class="form-control" id="role" formControlName="role" placeholder="Job Role">
                  <xavier-error-message [control]="addScreeningData.get('basicDbsCheck.role')"></xavier-error-message>
                </div>
              </div>
              <div class="col-sm-12 col-xl-4">
                <div class="form-group xavier-input-text">
                  <label for="sector">Sector</label>
                  <xavier-dropdown [itemSource]="sectorItemSource" [compareWith]="compareFn" formControlName="sector">
                    <ng-template let-item #itemTemplate>{{ item?.name }}</ng-template>
                    <ng-template let-item #valueTemplate>{{ item?.name }}</ng-template>
                  </xavier-dropdown>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div *ngIf="dbsCheckAdded.dbsType !== 'BASIC'">
        <div formGroupName="enhancedDbsCheck">
          <div class="row">
            <div class="col-sm-12 col-xl-3">
              <h4>Additional Details</h4>
            </div>
            <div class="col-sm-12 col-xl-6">
              <div class="form-group xavier-input-text">
                <label for="position">Position</label>
                <div class="drop">
                  <div>
                    <input #input type="text" class="form-control dropdown-shadow" placeholder="Select positon"
                      formControlName="position" autocomplete="one-time-code" (focus)="onWrapClick()" (keyup)="onKeyUp($event)"
                      (paste)="onChange($event, true)" (keydown)="onKeyDown($event, true)" (keypress)="allowTypingOnKeyPress(true)">
                    <img *ngIf="displayLoader" class="loaderIcon" src="../../../../assets/img/loader.gif" />
                  </div>

                  <div *ngIf="displayValues" class="dropdown-values-wrapper">
                    <div class="drop__list items" #dropdown>
                      <ng-container *ngIf="positionItemSource?.length != 0">
                        <div *ngFor="let entry of positionItemSource; let i = index" (click)="selectEntry(entry)"
                          class="drop__list__item" [class.selected]="i === currentPos" (mouseenter)="currentPos = -1">
                          <div class="dropdown-item">
                            <div class="font-weight-bold">{{ entry.name }}</div>
                            <div class="font-weight-normal item">
                              Barring list(s) to be checked:
                              <span class="font-weight-light">
                                <span *ngIf="entry.barringListToCheck === 'BOTH'">Children / Adult</span>
                                <span *ngIf="entry.barringListToCheck === 'NONE'">None</span>
                                <span *ngIf="entry.barringListToCheck === 'CHILDREN'">Children</span>
                                <span *ngIf="entry.barringListToCheck === 'ADULT'">Adult</span>
                              </span>
                            </div>
                            <div class="font-weight-normal item">
                              Workforce type:
                              <span class="font-weight-light">
                                <span *ngIf="entry.workforceType === 'BOTH'">Children / Adult</span>
                                <span *ngIf="entry.workforceType === 'NONE'">None</span>
                                <span *ngIf="entry.workforceType === 'CHILDREN'">Children</span>
                                <span *ngIf="entry.workforceType === 'ADULT'">Adult</span>
                                <span *ngIf="entry.workforceType === 'OTHER'">Other</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="positionItemSource?.length == 0 && displayValues">
                        <div class="entry-no-results">
                          No results
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-xl-3"></div>
            <div class="col-sm-12 col-xl-6">
              <div class="form-group xavier-input-text">
                <label for="organisationName">Organisation Name</label>
                <input type="text" class="form-control" id="organisationName" formControlName="organisationName" placeholder="Organisation Name">
                <xavier-error-message [control]="addScreeningData.get('enhancedDbsCheck.organisationName')"></xavier-error-message>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="displayBarredList">
            <div class="col-sm-12 col-xl-3"></div>
            <div class="col-sm-12 col-xl-6">
              <div class="form-group xavier-input-text">
                <label for="receivedBarredList">Do you want to receive the barred list information before the enhanced DBS result?</label>
                <app-radio-group name="receivedBarredList" id="receivedBarredList">
                  <app-radio formControlName="receivedBarredList" [value]="true" label="Yes"></app-radio>
                  <app-radio formControlName="receivedBarredList" [value]="false" label="No"></app-radio>
                </app-radio-group>
                <xavier-error-message [control]="addScreeningData.get('enhancedDbsCheck.receivedBarredList')"></xavier-error-message>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="row">
      <div class="col-sm-12 col-xl-3"></div>
      <div class="col-sm-12 col-xl-9">
        <button type="submit" class="btn btn-block xavier-button continue-step screening-button" id="submitButton" [disabled]="disabled">
          <span>Add New Screening</span>
        </button>
        <button type="button" class="btn btn-block xavier-button-plain screening-button" (click)="cancelAddScreening()">
          <span>Cancel</span>
        </button>
      </div>
    </div>
  </form>
</div>
