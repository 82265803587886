import {Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl} from "@angular/forms";
import {
  goodPasswordRegex,
  minimumPasswordRegex,
  strongPasswordRegex,
  weakPasswordRegex
} from "../../../../data/variables.data";

@Component({
  moduleId: module.id,
  selector: 'xavier-password-strength',
  templateUrl: 'password-strength.component.html',
  styleUrls: ['password-strength.stylesheet.sass']
})

export class PasswordStrengthComponent implements OnInit, OnDestroy {

  @Input() public control: AbstractControl;
  @Output() public suggestion: string;

  private formSub;
  private DEFAULT_SUGGESTION: string = 'At least 8 characters, letters and numbers';
  private SUGGESTION_1_BAR: string = 'At least 8 characters and numbers';
  private SUGGESTION_2_BAR: string = 'At least 8 characters';
  private SUGGESTION_3_BAR: string = 'Weak password';
  private SUGGESTION_4_BAR: string = 'Good password';
  private SUGGESTION_5_BAR: string = 'Strong password!';

  constructor() {
    this.suggestion = this.DEFAULT_SUGGESTION;
  }

  ngOnInit() {
    this.formSub = this.control.valueChanges.subscribe( value => {
      let filled_bars_number: number = 0;

      if (value === '') {
        this.suggestion = this.DEFAULT_SUGGESTION;
      } else if (strongPasswordRegex.test(value)) {  // 5 Bars
        filled_bars_number = 5;
        this.suggestion = this.SUGGESTION_5_BAR;
      } else if (goodPasswordRegex.test(value)) {    // 4 Bars
        filled_bars_number = 4;
        this.suggestion = this.SUGGESTION_4_BAR;
      } else if (weakPasswordRegex.test(value)) {    // 3 Bars
        filled_bars_number = 3;
        this.suggestion = this.SUGGESTION_3_BAR;
      } else if (minimumPasswordRegex.test(value)) { // 2 Bars
        filled_bars_number = 2;
        this.suggestion = this.SUGGESTION_2_BAR;
      } else {                                       // 1 Bar
        filled_bars_number = 1;
        this.suggestion = this.SUGGESTION_1_BAR;
      }

      let bars = document.getElementsByClassName('password-bar');
      for (let i = 0; i < 5; i++) {
        // remove the last class in the array and replace it with the empty-bar
        bars[i].classList.remove(bars[i].classList.item(bars[i].classList.length - 1));
        bars[i].classList.add('empty-bar');
      }
      for (let i = 0; i < filled_bars_number; i++)
        // fill the necessary bars with the right strength class
        bars[i].classList.add('strength-'+filled_bars_number);
    });
  }

  ngOnDestroy() {
    this.formSub.unsubscribe();
  }

}
