import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {FormControl} from "@angular/forms";
import {clientsDashboardFilters, clientsToasterOptions, itemsPerPageValues} from "../../../../data/variables.data";
import {addToaster, formatDate, getEntityLabel, getToasterMessagesLabel} from "../../../utils/functions.utils";
import {GenericTableComponent, GtExpandedRow} from "@angular-generic-table/core";
import {ClientsRowData} from "../../model/clients.interface.model";
import {CompanyService} from "../../services/company.service";
import {TableService} from "../../services/table.service";
import {StatusIconComponent} from "../../cmps-parts/status-icon/status-icon.component";
import {ActivatedRoute, Router} from "@angular/router";
import {UserPermission} from "../../model/permissions.model";
import {UserAuth} from "../../../../core/userAuth.core";
import {ToastyService} from 'ng2-toasty';
import {fadeIn} from "../../../../data/animations.data";
import {isNullOrUndefined} from "util";
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'xavier-clients',
  templateUrl: 'clients.component.html',
  styleUrls: ['clients.stylesheet.sass'],
  animations: [fadeIn]
})

export class ClientsComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() displayName: string;
  @ViewChild('rowLength', { static: true }) public rowLength: any;
  // @ViewChild('search', { static: true }) public search: any;

  public itemsPerPageValues = itemsPerPageValues;
  public clientsFiltersParams = {
    title: "Filter by Status",
    filters: clientsDashboardFilters,
    table: null
  };
  public searchControl = new FormControl();
  public data: Array<ClientsRowData> = [];

  @ViewChild('clientsTable', { static: true })
  private clientsTable: GenericTableComponent<any, GtExpandedRow<any>>;
  private page: number = 1;
  private records: number = 10;
  private query: string = '';
  private tableOwner: string;
  private getClientsDataReq;
  private routeSub: any;
  private searchSub: any;
  private tableOptionsSub: any;
  private tableOwnerSub: any;
  private toasterControls = {
    approved: false,
    disabled: false,
    rejected: false,
    returned: false
  };

  constructor(private companyService: CompanyService, private router: Router, private activatedRoute: ActivatedRoute,
              private titleService: Title, private toastyService: ToastyService, private userAuth: UserAuth,
              private tableService: TableService, private cdr: ChangeDetectorRef) {
    this.tableOwnerSub = this.tableService.currentOwner.subscribe(owner => {
      this.tableOwner = owner;
    });

    this.searchSub = this.searchControl.valueChanges
      .debounceTime(1000)
      .subscribe(newValue => {
        this.query = newValue;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query
        });
      });

    this.routeSub = this.activatedRoute.queryParams.subscribe(params => {
      this.toasterControls['approved'] = (!isNullOrUndefined(params['approved'])) ? params['approved'] : false;
      this.toasterControls['disabled'] = (!isNullOrUndefined(params['disabled'])) ? params['disabled'] : false;
      this.toasterControls['rejected'] = (!isNullOrUndefined(params['rejected'])) ? params['rejected'] : false;
      this.toasterControls['returned'] = (!isNullOrUndefined(params['returned'])) ? params['returned'] : false;
      this.toasterControls['clientDisabled'] = (!isNullOrUndefined(params['clientDisabled'])) ? params['clientDisabled'] : false;
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Dashboard - Xavier Eurocom CI');

    this.tableOptionsSub = this.tableService.currentOptions.subscribe(options => {
      if (this.tableOwner === btoa(this.constructor.toString())) {
        this.page = options.page ? options.page : 1;
        this.records = options.records ? options.records : 10;
        this.query = options.query ? options.query : '';
      } else {
        this.tableService.changeTableOwner(btoa(this.constructor.toString()));
        this.page = 1;
        this.records = 10;
        this.query = '';
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query
        });
      }
    });

    this.getData(1, 10000);

    this.clientsFiltersParams.table = this.clientsTable;
  }

  ngAfterViewInit() {
    _.forEach(this.toasterControls, ( function(actionValue, action) {
      if (actionValue) {
        let config = clientsToasterOptions;
        config.msg = getToasterMessagesLabel(action, 'clients');
        this.toastyService.info(config);
      }
    }).bind(this));

    if (!UserPermission.isAllowed(['COMPANY_DETAILS_ALL', 'COMPANY_DETAILS_OWN'], this.userAuth.getUser().permissionList)) {
      let tables = document.getElementsByTagName('table');
      tables[0].classList.add('tr-no-pointer');
    }

    this.clientsTable.gtInfo.recordLength = +this.records;
    this.rowLength.nativeElement.value = this.records;
    if (this.query !== '') {
      this.clientsTable.gtSearch(this.query);
      // this.search.nativeElement.value = this.query;
    }

    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
    this.getClientsDataReq.unsubscribe();
    this.searchSub.unsubscribe();
    this.tableOptionsSub.unsubscribe();
    this.tableOwnerSub.unsubscribe();
  }

  getData = function(pageNum, pageSize) {
    if (typeof this.getClientsDataReq !== 'undefined') {
      this.getClientsDataReq.unsubscribe();
    }

    this.getClientsDataReq = this.companyService.getClientsData(pageSize, pageNum, 'companyName', 'ASC', {}).subscribe(response => {
      this.data = [];
      _.forEach(response.list, ( function(client) {
        let status = this.getStatusProperties(client.clientStatus);
        let clientObject = {
          'id': client.id,
          'account_name': client.companyName,
          'contact_name': client.manager ? client.manager.fullName : '',
          'entity_type': getEntityLabel(client.entityType),
          'mobile_phone': client.mobilePhone ? client.mobilePhone : '',
          'office_phone': client.officePhone ? client.officePhone : '',
          'email': (client.manager && client.manager.email) ? client.manager.email : '',
          'sign_up_date': formatDate(client.createdDate),
          'sign_up_dateValue': client.createdDate,
          'statusComponent': new StatusIconComponent(),
          'statusValue': client.clientStatus,
          'statusType': status.statusType,
          'statusColor': status.statusColor,
          'statusBorder': status.statusBorder,
          'statusFill': status.statusFill,
          'statusSize': status.statusSize
        };
        this.data.push(clientObject);
      }).bind(this) );
      this.configObject.data = this.data;
    }, err => {
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
      this.configObject.data = [];
    }, () => {
      this.clientsTable.goToPage(+this.page);
    });
  };

  trigger = function($event) {
    switch($event.name) {
      case 'gt-row-select':
      case 'gt-row-deselect':
        if (UserPermission.isAllowed(['COMPANY_DETAILS_ALL', 'COMPANY_DETAILS_OWN'], this.userAuth.getUser().permissionList))
          this.router.navigate(['/dashboard/clients/edit/' + $event.value.changedRow.id]);
        break;
      case 'gt-row-length-changed':
        this.records = $event.value;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query
        });
        break;
      case 'gt-page-changed':
        if (this.page !== $event.value.pageCurrent && this.configObject.data.length !== 0) {
          this.page = $event.value.pageCurrent;
          this.tableService.changeOptions({
            page: this.page,
            records: this.records,
            query: this.query
          });
        }
        break;
      default:
        break;
    }
  };

  resetSearch(search: any) {
    search.value = '';
    this.query = '';
    this.tableService.changeOptions({
      page: this.page,
      records: this.records,
      query: this.query
    });
    this.clientsTable.gtSearch('');
  }

  getStatusProperties(value: string) {
    let res = {
      'statusType': '',
      'statusColor': '',
      'statusBorder': '#DEE0E0',
      'statusFill': '',
      'statusSize': 'small'
    };
    _.forEach(clientsDashboardFilters, function(filter) {
      if (filter.name === value) {
        res.statusType = filter.icon;
        res.statusColor = filter.color;
        res.statusBorder = filter.border;
        res.statusFill = filter.fill;
        res.statusSize = filter.size;
      }
    });
    return res;
  }

  filterInfoToStatus(filter: any) {
    return {
      'statusValue': filter.value,
      'statusType': filter.icon,
      'statusColor': filter.color,
      'statusBorder': filter.border,
      'statusFill': filter.fill,
      'statusSize': filter.size,
    };
  }

  public configObject = {
    settings: [{
      objectKey: 'id',
      visible: false
    }, {
      objectKey: 'account_name',
      sortOrder: 0,
      columnOrder: 0,
      sort: 'asc'
    }, {
      objectKey: 'contact_name',
      sortOrder: 0,
      columnOrder: 1
    }, {
      objectKey: 'entity_type',
      sortOrder: 0,
      columnOrder: 2
    }, {
      objectKey: 'mobile_phone',
      sortOrder: 0,
      columnOrder: 3
    }, {
      objectKey: 'office_phone',
      sortOrder: 0,
      columnOrder: 4
    }, {
      objectKey: 'email',
      sortOrder: 0,
      columnOrder: 5
    }, {
      objectKey: 'sign_up_date',
      sortOrder: 0,
      columnOrder: 6
    }, {
      objectKey: 'sign_up_dateValue',
      visible: false
    }, {
      objectKey: 'statusComponent',
      sortOrder: 0,
      columnOrder: 7
    }, {
      objectKey: 'statusValue',
      visible: false
    }, {
      objectKey: 'statusType',
      visible: false
    }, {
      objectKey: 'statusColor',
      visible: false
    }, {
      objectKey: 'statusBorder',
      visible: false
    }, {
      objectKey: 'statusFill',
      visible: false
    }, {
      objectKey: 'statusSize',
      visible: false
    }],
    fields: [{
      name: 'id',
      objectKey: 'id'
    }, {
      name: 'Account Name',
      objectKey: 'account_name',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'Contact Name',
      objectKey: 'contact_name',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'Entity Type',
      objectKey: 'entity_type',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'Mobile Phone',
      objectKey: 'mobile_phone',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'Office Phone',
      objectKey: 'office_phone',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      }
    }, {
      name: 'Email',
      objectKey: 'email',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border';
        return 'email-overflow'
      }
    }, {
      name: 'Sign Up Date',
      objectKey: 'sign_up_date',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      },
      sort: function(row) { return row.sign_up_dateValue; }
    }, {
      name: 'Status',
      objectKey: 'statusComponent',
      columnClass: (row, col) => {
        if (row == undefined)
          return 'no-top-border'
      },
      columnComponent: {
        type: StatusIconComponent
      },
      sort: function(row) { return row.statusValue; }
    }],
    data: []
  };
}
