import {AfterViewInit, Component, OnInit} from "@angular/core";
import {Attribute, ATTRIBUTE_TYPES_MAP} from "../attribute.class";
import {DynamicFormService} from "../../../../services/dynamic-form.service";
import {DF_VALIDATION_FN, RADIO_TYPE} from '../../../../../../data/variables.data';
import {isNullOrUndefined} from "util";

@Component({
  moduleId: module.id,
  selector: 'xavier-radio',
  templateUrl: 'radio.component.html',
  styleUrls: ['radio.stylesheet.sass']
})

export class RadioAttribute extends Attribute implements AfterViewInit, OnInit {

  public radioModel: string;
  public options = [];
  public hidden = false;

  constructor(public dynamicFormService: DynamicFormService) {
    super(dynamicFormService);
  }

  ngOnInit() {
    let formGroup = this.dynamicFormService.getFormGroup(this.formGroupId);
    let formControl = this.dynamicFormService.attributeToFormControl(this.data);

    formGroup.addControl(this.formGroupId + '-' + this.data.id, formControl);

    this.formGroup = formGroup;
    this.formControl = this.formGroup.get(this.formGroupId + '-' + this.data.id);

    this.options = this.data.options.split(',');
  }

  ngAfterViewInit() {
    if (!isNullOrUndefined(this.data.conditions))
      for (let condition of this.data.conditions) {
        if (condition.fn === DF_VALIDATION_FN.REPEAT_MATCH_GROUP) {
          this.formControl.valueChanges.subscribe(value => this.REPEAT_MATCH_GROUP(this.formGroupId, value, condition));
        } else if (condition.fn === DF_VALIDATION_FN.REPEAT_MATCH) {
          this.formControl.valueChanges.subscribe(value => this.REPEAT_MATCH(this.formGroupId, value, condition));
        } else if (condition.fn === DF_VALIDATION_FN.EMPLOYMENT_HISTORY_MORE) {
          this.formControl.valueChanges.subscribe(value => this.EMPLOYMENT_HISTORY_MORE(this.formGroupId, value, condition));
        } else if (condition.fn === DF_VALIDATION_FN.CURRENT_EMPLOYMENT_STATUS) {
          this.formControl.valueChanges.subscribe(value => this.CURRENT_EMPLOYMENT_STATUS(this.formGroupId, value, condition));
        } else {
          this.formControl.valueChanges.subscribe(value => this.MATCH_RADIO(this.formGroupId, value, condition));
        }
      }
    super.ngAfterViewInit();
  }

  show(show: boolean, disabled = false) {
    this.hidden = !show;

    if (show && !disabled)
      this.formControl.enable();
    else
      this.formControl.disable();

    this.formControl.updateValueAndValidity();
  }

}

ATTRIBUTE_TYPES_MAP.set(RADIO_TYPE, RadioAttribute);
