import { Injectable } from "@angular/core";
import { HttpService } from "../../shared-services/http.service";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MultiverseService {
  constructor(private httpService: HttpService) {}

  public getEvidenceChecks(checkname: string) {
    return this.get(checkname, null, null);
  }

  public getQualificationRecord(qualificationID: string) {
    let params = [ { qualificationID } ];
    return this.get('getQualificationRecord', null, params);
  }

  public getEvidenceProgress(recordId: string, progressPath?: string,) {
    let params = [ { recordId } ];
    if (!progressPath) return of(null);
    return this.get(progressPath, null, params);
  }

  public getNextQualificationRecord(currentId: string, recordPath?: string, ) {
    let params = [ { currentId } ];
    if (!recordPath) return of(null);
    return this.get(recordPath, null, params);
  }

  public getPrevQualificationRecord(currentId: string, recordPath?: string, ) {
    let params = [ { currentId } ];
    if (!recordPath) return of(null);
    return this.get(recordPath, null, params);
  }

  public editQualificationRecord(body, id) {
    return this.put(`editQualificationRecord?id=${id}`, body, null, false);
  }

  public getEventLog(id) {
    return this.httpService.get('/_ah/api/log/v1', 'getMultiverseEventLog', [{ recordId: encodeURIComponent(id) }]);
  }

  public post(methodName: string, body: any, params: any, reportProgress: boolean): Observable<any> {
    return this.httpService.post("/_ah/api/multiverse/v1", methodName, body, params, reportProgress);
  }

  public put(methodName: string, body: any, params: any, reportProgress: boolean): Observable<any> {
    return this.httpService.put("/_ah/api/multiverse/v1", methodName, body, params, reportProgress);
  }

  public get(methodName: string, body: any, params: any): Observable<any> {
    return this.httpService.get("/_ah/api/multiverse/v1", methodName, params);
  }
}
