import { Injectable, TemplateRef } from '@angular/core';

export interface Confirmation {
  title: string;
  message: string;

  template?: TemplateRef<any>;

  acceptLabel?: string;
  rejectLabel?: string;
  rejectLabelHidden?: boolean;
  actionOnRevert?: boolean;
}

const defaults: Partial<Confirmation> = {
  acceptLabel: 'OK',
  rejectLabel: 'Cancel',
};

type actionType = 'ACCEPT' | 'REJECT' | 'CLOSE';

@Injectable()
export class DialogService {
  public confirmation: Confirmation;
  public isAcceptButtonLoading = false;
  public isRejectButtonLoading = false;

  private resolve: (value: actionType) => void;

  public async confirm(confirmation: Confirmation): Promise<actionType> {
    this.confirmation = { ...defaults, ...confirmation };
    this.isAcceptButtonLoading = false;

    const result = await new Promise<actionType>(resolve => this.resolve = resolve);
    this.confirmation = undefined;

    return result;
  }

  public async confirmAndDo(confirmation: Confirmation, action: () => Promise<void>, rejectAction?: () => Promise<void>): Promise<actionType> {
    this.confirmation = { ...defaults, ...confirmation };
    this.isAcceptButtonLoading = false;
    this.isRejectButtonLoading = false;

    const result = await new Promise<actionType>(resolve => this.resolve = resolve);

    if (result === 'CLOSE') {
      this.confirmation = undefined;
      return 'CLOSE';
    }

    if (result === 'REJECT') {
      if (rejectAction) {
        this.isRejectButtonLoading = true;
        await rejectAction();
      }

      this.confirmation = undefined;
      this.isRejectButtonLoading = false;
      return 'REJECT';
    }

    this.isAcceptButtonLoading = true;

    if (action) {
      await action();
    }

    this.confirmation = undefined;
    this.isAcceptButtonLoading = false;

    return 'ACCEPT';
  }

  public accept() {
    if (this.resolve) {
      this.resolve('ACCEPT');
    }
    this.resolve = undefined;
  }

  public reject() {
    if (this.resolve) {
      this.resolve('REJECT');
    }
    this.resolve = undefined;
  }

  public close() {
    if (this.resolve) {
      this.resolve('CLOSE');
    }
    this.resolve = undefined;
  }
}
