import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserAuth} from '../../../../core/userAuth.core';
import {FormService} from '../../services/form.service';
import {HttpClient, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../../admin/model/user.model';
import {UserService} from '../../../admin/services/user.service';
import {ToastyService} from 'ng2-toasty';
import {fadeIn} from '../../../../data/animations.data';
import {addToaster, addToasterWithMessage, setAsTouched} from '../../../utils/functions.utils';
import {emailValidator} from '../../../utils/form.validators';
import {isNullOrUndefined} from 'util';
import {MultiFactorStatus} from '../../model/two-factor-auth.model';

@Component({
  moduleId: module.id,
  selector: 'xavier-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.stylesheet.sass'],
  animations: [fadeIn]
})

export class LoginComponent implements OnInit, OnDestroy {

  @Input() login = true;

  public loginData: FormGroup;
  public disabled = false;

  private googleSubscription;
  private logout: number;

  constructor(private fb: FormBuilder, private formService: FormService, private userService: UserService, private http: HttpClient,
              private router: Router, private titleService: Title, private userAuth: UserAuth,
              private activeRoute: ActivatedRoute, private toastyService: ToastyService) {
    // this.googleSubscription = this.af.authState.subscribe(googleUser => {
    //   if (googleUser != null) {
    //     if (this.logout != null) {
    //       this.af.auth.signOut().then(value => {
    //         this.logout = null;
    //         this.router.navigate(['/login']);
    //       });
    //     } else {
    //       this.userService.checkSignedWithGoogle(googleUser).subscribe(response => {
    //         if (!isNullOrUndefined(response.id)) {
    //           let user = new User(response.id, response.firstName, response.lastName, response.fullName,
    //             response.lastLogin, response.company.id, response.company.companyName, response.role, response.permissions);
    //           this.userAuth.setUser(user);
    //           if (response.role.name === 'Candidate')
    //             this.router.navigate(['/dashboard/candidate-screening']);
    //           else
    //             this.router.navigate(['/dashboard/candidates-overview']);
    //         }
    //       }, error => {
    //         console.log(error);
    //       });
    //     }
    //   } else {
    //     if (this.logout != null) {
    //       this.logout = null;
    //       this.router.navigate(['/login']);
    //     }
    //   }
    // });

    this.activeRoute.params.subscribe(params => {
      this.logout = params['logout'];
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Login - Xavier Eurocom CI');

    this.loginData = this.fb.group({
      email: ['', [Validators.required, emailValidator]],
      password: ['', [Validators.required]]
    });
  }

  ngOnDestroy() {
    // this.googleSubscription.unsubscribe();
  }

  onSubmit({ value, valid }: { value: any, valid: boolean }) {
    if (!valid) {
      setAsTouched(this.loginData);
    } else {
      this.disabled = true;
      const submitButton = document.getElementById('submitButton');
      const spinnerWrap = document.createElement('div');
      spinnerWrap.classList.add('spinner-wrap');
      spinnerWrap.classList.add('button');

      submitButton.innerHTML = '';
      submitButton.appendChild(spinnerWrap);

      const headers = new HttpHeaders({ 'Content-Type': 'text/html', 'Access-Control-Allow-Credentials': 'true' });
      const credentials = {
        email: value.email,
        password: value.password
      };
      const req = new HttpRequest(
        'POST',
        environment.apiUrl + '/_ah/api/user/v1/checkLogin',
        credentials,
        {
          headers: headers,
          withCredentials: true
        }
      );

      this.http.request(req).subscribe(response => {
        if (response instanceof HttpResponse) {

          this.disabled = false;
          submitButton.innerHTML = '<span>Login</span>';
          // I believe this cookies set has no effect
          // if (response.headers.has('jsessionid'))
          // document.cookie = 'JSESSIONID=' + response.headers.get('jsessionid') + ';Path=/;Domain=' + environment.domain;
          switch (response.status) {
            case 200: {
              // console.log("body: ", response.body);
              const { phonePrefix, phone } = response.body as any;

              if (phonePrefix && phone) {
                this.userAuth.phoneNumber$.next(phonePrefix + ';' + phone);
              }

              const user = new User(
                response.body["id"],
                response.body["firstName"],
                response.body["lastName"],
                response.body["fullName"],
                response.body["lastLogin"],
                response.body["company"].id,
                response.body["company"].companyName,
                response.body["role"],
                response.body["candidateId"],
                response.body["permissions"],
                response.body["email"],
                response.body["company"].saasClient,
                response.body["dbsCountersignatory"]
              );
              this.userAuth.setUser(user);

              const multiFactorStatus = response.body['multiFactorStatus'];

              if (multiFactorStatus === MultiFactorStatus.SETUP_REQUIRED) {
                this.router.navigate(['/two-factor/1']);
              } else if (multiFactorStatus === MultiFactorStatus.OTP_SENT) {
                this.router.navigate(['/two-factor/otp-code']);
              } else {
                if (response.body['role'].name === 'Candidate')
                  this.router.navigate(['/dashboard/candidate-screening']);
                else
                  this.router.navigate(['/dashboard/candidates-overview']);
              }
              break;
            }
            case 204: {
              this.loginData.get('email').setErrors({ emailPass: true });
              this.loginData.get('password').setErrors({ emailPass: true });
              break;
            }
          }
        }
      }, error => {
        this.disabled = false;
        submitButton.innerHTML = '<span>Login</span>';
        switch (error.status) {
          case 403: {
            addToasterWithMessage('error', this.toastyService, error.error.error.message);
            break;
          }
          case 503: {
            addToaster('error', this.toastyService, 'serverError', 'errors');
            break;
          }
        }
      });
    }
  }

  googleLogIn(event) {
    event.preventDefault();
    this.userAuth.signInWithGoogle();
  }

}
