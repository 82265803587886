import {AfterViewInit, ChangeDetectorRef, Component} from '@angular/core';
import {Router} from "@angular/router";
import {FormService} from "../../services/form.service";

@Component({
  moduleId: module.id,
  selector: 'xavier-template',
  templateUrl: 'template.component.html',
  styleUrls: ['template.stylesheet.sass', 'template-mediaqueries.stylesheet.sass']
})

export class TemplateComponent implements AfterViewInit {

  footerCopyrightDate = new Date();

  constructor(private router: Router, private formService: FormService, private cdr: ChangeDetectorRef) {

  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  routeClass() {
    if (this.router.url.indexOf('reset-password') >= 0 ||
        this.router.url.indexOf('application-review') >= 0)
      return 'reset-override';
    if (this.router.url.indexOf('login') >= 0 ||
        this.router.url.indexOf('forgot') >= 0 ||
        this.router.url.indexOf('activation') >= 0)
      return 'login-override';
  }
}
