<ng2-toasty [position]="'top-center'" class="xavier-toasty"></ng2-toasty>

<div class="spinner-container not-button">
  <div class="spinner-wrap">
  </div>
  <div style="margin-top: 20px">
    {{message}}
  </div>
</div>

