import { GtCustomComponent, GtRow } from '@angular-generic-table/core';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'xavier-resistant-ai-badge',
  templateUrl: './resistant-ai-badge.component.html',
  styleUrls: ['./resistant-ai-badge.stylesheet.sass']
})
export class ResistantAiBadgeComponent extends GtCustomComponent<GtRow> {
  @Input() public row;

  constructor() {
    super();
  }
}
