import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {YotiModule} from './yoti/yoti.module';
import {IntegrationComponent} from './integration.component';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    YotiModule,
    RouterModule,
  ],
  declarations: [
    IntegrationComponent,
  ],
  exports: [
    IntegrationComponent,
    YotiModule,
  ]
})
export class IntegrationModule { }
