<ng2-toasty [position]="'top-center'" class="xavier-toasty"></ng2-toasty>
<div class="xavier-edit-client-wrapper container">
  <div *ngIf="loading" class="xavier-center-page" [@fadeIn]>
    <div class="spinner-container not-button"><div class="spinner-wrap"></div></div>
  </div>
  <div *ngIf="!loading" [@fadeIn]>
    <div class="row header">
      <div class="col-1 text-center">
        <button *isAllowed="['COMPANY_LIST']"
                routerLink="/dashboard/clients"
                type="button"
                class="btn xavier-button-plain white">
          <i class="material-icons back-link">&#xE5C4;</i>
        </button>
      </div>
      <div class="col-6">
        <h1>{{clientData?.companyName}}</h1>
        <p>{{clientData?.manager.firstName}} {{clientData?.manager.lastName}}</p>
      </div>
      <div class="col-sm-6 col-xl-5 edit-group text-right">
        <div class="btn-group" dropdown>
          <button dropdownToggle type="button" class="btn btn-block xavier-button-plain dropdown-toggle xavier-dropdown-toggle weight-normal">
            More <span class="caret xavier-caret"></span>
          </button>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-right xavier-dropdown-edit-more-menu" role="menu">
            <div *isAllowed="['COMPANY_DISABLE']" class="more-menu-item" (click)="disableClient()">
              Disable Client
            </div>
            <div *isAllowed="['COMPANY_STATUS_EDIT']">
              <div *ngIf="!showReturn" class="more-menu-item" (click)="openModal(returnModal)">
                Return Application
              </div>
              <div *ngIf="!showReject" class="more-menu-item" (click)="openModal(rejectModal)">
                Reject Application
              </div>
              <div *ngIf="!showApprove" class="more-menu-item" (click)="approveApplication()">
                Approve Application
              </div>
              <div *isAllowed="['COMPANY_EDIT_ALL']" class="dropdown-divider"></div>
              <div *isAllowed="['COMPANY_EDIT_ALL']" class="more-menu-item" (click)="logInAs()">
                Login As
              </div>
            </div>
          </div>
        </div>
        <button *ngIf="showReturn" type="button" class="btn xavier-button continue-step weight-normal" (click)="openModal(returnModal)" [@fadeIn]>
          <i class="material-icons">&#xE31B;</i> Return Application
        </button>
        <button *ngIf="showReject" type="button" class="btn xavier-button reject weight-normal" (click)="openModal(rejectModal)" [@fadeIn]>
          <i class="material-icons">&#xE5CD;</i> Reject Application
        </button>
        <button *ngIf="showApprove" type="button" class="btn xavier-button continue-step weight-normal" (click)="approveApplication()" [@fadeIn]>
          <i class="material-icons">&#xE876;</i> Approve Application
        </button>
      </div>
    </div>
    <div class="row checks-status">
      <div class="col-sm-12 col-xl-2" *isAllowed="['COMPANY_STATUS_EDIT']">
        Completed Checks
        <div class="num-out-of">{{completedChecks}}/{{totalChecks}}</div>
        <div class="progress">
          <div class="progress-bar bg-gray" role="progressbar" [style.width]="percCompleted+'%'" [style.height]="'4px'" [attr.aria-valuenow]="percCompleted" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
      <div class="col-sm-12 col-xl-3" *isAllowed="['COMPANY_STATUS_EDIT']">
        <div>Overall Status</div>
        <div class="status-icon"><xavier-status-icon [row]="filter"></xavier-status-icon></div><div class="status-text">{{statusValue}}</div>
      </div>
    </div>
    <div class="row client-properties">
      <div class="col-4">
        <xavier-client-side-menu [params]="sideMenuParams"></xavier-client-side-menu>
      </div>
      <div class="col-8">
        <xavier-client-properties [params]="propertiesParams" [clientData]="clientData"></xavier-client-properties>
      </div>
    </div>
  </div>
</div>

<ng-template #returnModal>
  <div class="modal-header">
    <h2 class="modal-title pull-left">Describe the reason you are returning this application</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="returnApplicationData" novalidate (ngSubmit)="onSubmitReturn(returnApplicationData)">
      <textarea class="modal-textarea" placeholder="Type your reason..." id="returnReason" formControlName="returnReason"
                rows="3" cols="59"></textarea>
      <xavier-error-message [control]="returnApplicationData.get('returnReason')"></xavier-error-message>
      <div class="modal-button-group">
        <button type="submit" class="btn xavier-button continue-step weight-normal">
          <i class="material-icons">&#xE31B;</i> Return Application
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #rejectModal>
  <div class="modal-header">
    <h2 class="modal-title pull-left">Describe the reason you are rejecting this application</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="rejectApplicationData" novalidate (ngSubmit)="onSubmitReject(rejectApplicationData)">
      <textarea class="modal-textarea" placeholder="Type your reason..." id="rejectReason" formControlName="rejectReason"
                rows="3" cols="59"></textarea>
      <xavier-error-message [control]="rejectApplicationData.get('rejectReason')"></xavier-error-message>
      <div class="modal-button-group">
        <button type="submit" class="btn xavier-button reject weight-normal">
          <i class="material-icons">&#xE5CD;</i> Reject Application
        </button>
      </div>
    </form>
  </div>
</ng-template>
