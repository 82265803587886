<div class="xavier-application-review-wrapper container">
  <div *ngIf="loading" class="row" [@fadeIn]>
    <div class="spinner-container not-button"><div class="spinner-wrap"></div></div>
  </div>
  <form [formGroup]="enterPasswordData" novalidate (ngSubmit)="onSubmit(enterPasswordData)">
    <div *ngIf="pass" class="row" [@fadeIn]>
      <div class="col-12">
        <div class="header">
          Login
        </div>
        <div class="header smaller">
          Your application has been returned to you, it needs your attention.
        </div>
      </div>
      <div class="form-group col-sm- 12 col-xl-5 xavier-input-text">
        <label for="password">Your Password</label>
        <input type="password" class="form-control" id="password" formControlName="password" placeholder="Your Password">
        <xavier-error-message [control]="enterPasswordData.get('password')"></xavier-error-message>
      </div>
      <div class="col-7"></div>
      <div class="col-sm-12 col-xl-5">
        <button type="submit" class="btn btn-block xavier-button continue-step" id="submitButton" [disabled]="disabledSubmit">
          <span>Submit</span>
        </button>
      </div>
      <!--div class="col-sm-12 col-xl-1 or-col text-center">
        <span>or</span>
      </div>
      <div class="col-sm-12 col-xl-6 google-col">
        <button class="btn btn-block xavier-button google" (click)="googleLogIn($event)" tabindex="5">
          <span><img src="../../../../assets/img/g-logo.png">Login with Google</span>
        </button>
      </div-->
    </div>
  </form>
  <div *ngIf="wrongToken" class="row" [@fadeIn]>
    <div class="col-12">
      <div class="header">
        Your password is incorrect or your token is invalid, has expired or has already been used.
      </div>
      <div class="col-sm-12 col-xl-5 login-col">
        <button type="button" class="btn btn-block xavier-button continue-step" (click)="back()">
          <span>Back</span>
        </button>
      </div>
    </div>
  </div>
</div>
