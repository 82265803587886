<div class="xavier-forgot-wrapper container">
  <form [formGroup]="forgotPasswordData" novalidate (ngSubmit)="onSubmit(forgotPasswordData)">
    <div *ngIf="!forgot" class="row" [@fadeIn]>
      <div class="col-12">
        <div class="header">
          Forgot your password?
          <span class="description">Enter your email below and we will send you instructions how to reset your password.</span>
        </div>
      </div>
      <div class="form-group col-12 xavier-input-text">
        <label for="email">Email Address</label>
        <input type="email" class="form-control" id="email" formControlName="email" placeholder="Email Address" validateOnBlur [validateFormControl]="forgotPasswordData.get('email')">
        <xavier-error-message [control]="forgotPasswordData.get('email')"></xavier-error-message>
      </div>
      <div class="col-sm-12 col-xl-7 submit-col">
        <button type="submit" class="btn btn-block xavier-button continue-step" id="submitButton" [disabled]="disabledForgot">
          <span>Reset Password</span>
        </button>
      </div>
      <div class="col-sm-12 col-xl-4 cancel-col">
        <button type="button" class="btn btn-block xavier-button-plain" (click)="cancel()">
          <span>Cancel</span>
        </button>
      </div>
    </div>
    <div *ngIf="forgot" class="row" [@fadeIn]>
      <div class="col-12">
        <div class="header">
          Check your email
          <span class="description">We have sent an email to {{forgotPasswordData.get('email').value}}. Please check your email for instructions how to reset your password.</span>
        </div>
      </div>
      <div class="col-12">
        <div class="header small">
          Not received the email?
          <span class="description">Please wait a few minutes and check your e-mail's spam folder. If you still haven't received the email, please resend it below.</span>
        </div>
      </div>
      <div class="col-sm-12 col-xl-8 submit-col">
        <button *ngIf="!showConfirm" type="button" class="btn btn-block xavier-button continue-step" id="resendButton" (click)="resendEmail()" [disabled]="disabledResend" [@fadeIn]>
          <span>Resend Email</span>
        </button>
        <button *ngIf="showConfirm" type="button" class="btn btn-block xavier-button continue-step" [@fadeIn]>
          <span>Email sent successfully</span> <i class="material-icons">&#xE876;</i>
        </button>
      </div>
    </div>
  </form>
</div>
