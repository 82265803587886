<pre>
    {{form.value | json}}
</pre>

<pre>
  <!-- <div *ngFor="let c of form.controls | keyvalue" [style.fontWeight]="c.value.status === 'INVALID' ? 'bold' : 'unset'"> -->
  <!--   {{ c.key }}: {{ c.value.status }} -->
  <!-- </div> -->
  <div>
    dirty: {{ form.dirty }}
  </div>
</pre>
