<small class="form-text text-muted strength-suggestion">{{suggestion}}</small>
<div class="password-bar-container">
  <div class="password-bar-row">
    <div class="password-bar empty-bar"></div>
    <div class="password-bar empty-bar"></div>
    <div class="password-bar empty-bar"></div>
    <div class="password-bar empty-bar"></div>
    <div class="password-bar empty-bar"></div>
  </div>
</div>
