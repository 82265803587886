import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Rx';
import { HttpService } from "../../shared-services/http.service";

@Injectable()
export class UserService {
  private readonly API_NAME = '/_ah/api/user/v1';

  constructor(private httpService: HttpService) { }

  public checkTokenPassword(body) {
    return this.post('checkTokenPassword', body);
  }

  public getSignUpData(token) {
    return this.get('getSignUpData/' + token);
  }

  public signUp(body, token) {
    return this.post('signUp/' + token, body);
  }

  public verifyEmail(token: string) {
    return this.httpService.post('/_ah/api/registration/v1', 'verifyEmail/' + encodeURIComponent(token), null, null, false);
  }

  public getSecurityQuestion(token: string) {
    return this.get('getSecurityQuestion/' + encodeURIComponent(token));
  }

  public checkSecurityAnswer(token: string, answer: string) {
    return this.get('checkSecurityAnswer/' + encodeURIComponent(token) + '/' + encodeURIComponent(answer));
  }

  public resetPassword(token: string, answer: string, newPassword: string) {
    const body = {
      newPassword: newPassword,
      securityAnswer: answer
    };

    return this.post('resetPassword/' + encodeURIComponent(token), body);
  }

  public post(methodName: string, body: any): Observable<any> {
    return this.httpService.post(this.API_NAME, methodName, body, null, false);
  }

  public get(methodName: string): Observable<any> {
    return this.httpService.get(this.API_NAME, methodName, null);
  }
}
