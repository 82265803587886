import { Component, OnInit, ViewChild } from "@angular/core";
import { fadeIn } from "../../../../data/animations.data";
import { countersignDashboardStatuses, itemsPerPageValues } from "../../../../data/variables.data";
import { FormControl } from "@angular/forms";
import {
  GenericTableComponent,
  GtExpandedRow,
  GtInformation,
} from "@angular-generic-table/core";
import { TableService } from "../../services/table.service";
import { isNullOrUndefined } from "util";
import * as _ from 'lodash';
import { addToaster } from "../../../utils/functions.utils";
import { Title } from "@angular/platform-browser";
import { NavigationStart, Router } from "@angular/router";
import { CandidateScreeningService } from "../../services/candidate-screening.service";
import { ToastyService } from "ng2-toasty";
import { StatusIconComponent } from "../../cmps-parts/status-icon/status-icon.component";

@Component({
  moduleId: module.id,
  selector: "xavier-countersign-overview",
  templateUrl: "./countersign-overview.component.html",
  styleUrls: ["./countersign-overview.component.sass"],
  animations: [fadeIn],
})
export class CountersignOverviewComponent implements OnInit {
  @ViewChild('rowLength', { static: true }) public rowLength: any;

  public startRouteUrl: string;
  public itemsPerPageValues = itemsPerPageValues;
  public searchControl = new FormControl();
  public submitLoading = false;
  public checkBoxes = [];
  public countersignStatuses = countersignDashboardStatuses;
  public data: any = [];

  @ViewChild("countersignTable", { static: true })
  private countersignTable: GenericTableComponent<any, GtExpandedRow<any>>;
  private query: string = '';
  private page: number = 1;
  private records: number = 10;
  private geCountersignDataReq: any;
  private sortBy: string = '';
  private sortOrder: string = '';
  private searchSub: any;
  private tableOptionsSub: any;
  private routeSub: any;
  private tableOwnerSub: any;
  private tableOwner: string;

  private tableInfo = {
    'pageNext': null,
    'pageCurrent': 1,
    'pagePrevious': null,
    'pageTotal': 1,
    'recordFrom': 1,
    'recordLength': 1,
    'recordsAfterFilter': 1,
    'recordsAfterSearch': 1,
    'recordsAll': 1
  };

  constructor(
    private router: Router,
    private tableService: TableService,
    private titleService: Title,
    private candidateScreeningService: CandidateScreeningService,
    private toastyService: ToastyService
  ) {
    this.startRouteUrl = this.router.url;
    this.tableOwnerSub = this.tableService.currentOwner.subscribe((owner) => {
      this.tableOwner = owner;
    });
    this.searchSub = this.searchControl.valueChanges
      .debounceTime(1000)
      .subscribe((newValue) => {
        this.query = newValue;
        this.page = 1;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder,
        });
        this.getData(
          this.page,
          this.countersignTable.gtInfo.recordLength,
          this.query
        );
      });
  }

  ngOnInit() {
    this.titleService.setTitle('Dashboard - Xavier Eurocom CI');

    this.routeSub = this.router.events.subscribe((value) => {
      if (value instanceof NavigationStart)
        this.startRouteUrl = value.url;
    });

    this.tableOptionsSub = this.tableService.currentOptions.subscribe(options => {
      if (this.tableOwner === btoa(this.constructor.toString())) {
        this.page = options.page ? options.page : 1;
        this.records = options.records ? options.records : 10;
        this.query = options.query ? options.query : '';
        this.sortBy = options.sortBy ? options.sortBy : '';
        this.sortOrder = options.sortOrder ? options.sortOrder : '';
      } else {
        this.tableService.changeTableOwner(btoa(this.constructor.toString()));
        this.page = 1;
        this.records = 10;
        this.query = '';
        this.sortBy = '';
        this.sortOrder = '';
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
      }
    });

    this.getData(this.page, this.records, this.query, this.sortBy, this.sortOrder);
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
    this.searchSub.unsubscribe();
    this.tableOptionsSub.unsubscribe();
    this.tableOwnerSub.unsubscribe();
    this.geCountersignDataReq.unsubscribe();
  }

  getData = function(pageNum: number, pageSize: number, query: string, sortBy?: string, sortOrder?: string) {
    this.data = [];
    let loading: Array<any> = [];
    loading.push({ applicantFullName: 'Loading...'} );
    this.configObject.data = loading;

    if (typeof this.geCountersignDataReq !== 'undefined') {
      this.geCountersignDataReq.unsubscribe();
    }

    this.info = {
      'pageNext': null,
      'pageCurrent': 1,
      'pagePrevious': null,
      'pageTotal': 1,
      'recordFrom': 1,
      'recordLength': 1,
      'recordsAfterFilter': 1,
      'recordsAfterSearch': 1,
      'recordsAll': 1
    };
    this.configObject.info = <GtInformation>this.info;
    if (isNullOrUndefined(query))
      query = '';
    if (!sortBy)
      sortBy = 'applicantFullName';
    if (!sortOrder)
      sortOrder = 'ASC';
    this.geCountersignDataReq = this.candidateScreeningService
      .getCountersignData(pageSize, pageNum, query, sortBy, sortOrder)
      .subscribe(
        (response) => {
          if (!isNullOrUndefined(response.items)) {
            this.data = [];
            _.forEach(
              response.items,
              function (countersign) {
                let statusValue = countersign.dbsSubmissionStatus;
                let status = this.getStatusProperties(statusValue);
                let countersignObject = {
                  id: countersign.id,
                  applicantFullName: countersign.applicantFullName,
                  organisationName: countersign.organisationName,
                  position: countersign.position,
                  workForceType: countersign.workForceType,
                  dbsCheckType: countersign.dbsCheckType ? countersign.dbsCheckType[0] : '',
                  barringList: countersign.barringList,
                  route: countersign.route ? countersign.route.split('_')[1] : '',
                  isUkNational: countersign.isUkNational ? 'Y' : 'N',
                  workAtHomeAddress: countersign.workAtHomeAddress ? 'H' : null,
                  additionalBarringList: countersign.additionalBarringList ? countersign.additionalBarringList : null,
                  dbsSubmissionStatus: countersign.dbsSubmissionStatus,
                  dbsSubmissionTimestamp: countersign.dbsSubmissionTimestamp,
                  dbsSubmissionErrorMessage: countersign.dbsSubmissionErrorMessage,
                  status: countersign.dbsSubmissionStatus,
                  statusComponent: new StatusIconComponent(),
                  statusValue,
                  statusType: status.statusType,
                  statusColor: status.statusColor,
                  statusBorder: status.statusBorder,
                  statusFill: status.statusFill,
                  statusSize: status.statusSize,
                };
                this.data.push(countersignObject);
              }.bind(this)
            );
            this.configObject.data = this.data;
            let pageNext = pageNum < response.pages ? pageNum + 1 : null;
            let pagePrev = pageNum === 1 ? null : pageNum - 1;
            this.tableInfo = {
              pageNext: pageNext,
              pageCurrent: pageNum,
              pagePrevious: pagePrev,
              pageTotal: response.pages,
              recordLength: pageSize,
              recordsAfterFilter: response.count,
              recordsAfterSearch: response.count,
              recordsAll: response.count,
            };
            this.configObject.info = <GtInformation>this.tableInfo;
          } else if (!isNullOrUndefined(response.count) && !isNullOrUndefined(response.pages)) {
            this.configObject.data = [];
            this.tableInfo = {
              pageNext: null,
              pageCurrent: pageNum,
              pagePrevious: null,
              pageTotal: response.pages,
              recordFrom: response.count,
              recordLength: pageSize,
              recordsAfterFilter: response.count,
              recordsAfterSearch: response.count,
              recordsAll: response.count,
            };
            this.configObject.info = <GtInformation>this.tableInfo;
          }
        },
        (err) => {
          let action = "serverError";
          if (err.status === 403) action = "403";
          addToaster("error", this.toastyService, action, "errors");
          this.configObject.data = this.data;
        }
      );
  }

  trigger = function($event) {
    switch($event.name) {
      case 'gt-row-select':
      case 'gt-row-deselect':
        this.checkBoxes = $event.value.selectedRows;
        break;
      case 'gt-row-length-changed':
        this.records = $event.value;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(this.countersignTable.gtInfo.pageCurrent, this.records, this.query, this.sortBy, this.sortOrder);
        break;
      case 'gt-page-changed':
        this.page = $event.value.pageCurrent;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(this.page, this.countersignTable.gtInfo.recordLength, this.query, this.sortBy, this.sortOrder);
        break;
      case 'gt-sorting-applied':
        let sortBy;
        let sortOrder = 'ASC';
        if ($event.value[0].charAt(0) === '-' && $event.value[0].charAt(0) !== '$') {
          sortOrder = 'DESC';
          sortBy = $event.value[0].slice(1, $event.value[0].length);
        } else if ($event.value[0].charAt(0) !== '$') {
          sortBy = $event.value[0];
        }
        this.sortBy = sortBy;
        this.sortOrder = sortOrder;
        this.tableService.changeOptions({
          page: this.page,
          records: this.records,
          query: this.query,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder
        });
        this.getData(
          this.countersignTable.gtInfo.pageCurrent,
          this.countersignTable.gtInfo.recordLength,
          this.query, this.sortBy, this.sortOrder
        );
        break;
      default:
        break;
    }
  };

  resetSearch() {
    this.query = '';
    this.tableService.changeOptions({
      page: this.page,
      records: this.records,
      query: this.query
    });
    this.searchControl.setValue('');
  }

  onSubmit() {
    if (this.checkBoxes.length > 0) {
      const body = { ids: [] };
      this.checkBoxes.forEach(checkbox => {
        body.ids.push(checkbox.id)
      });
      this.submitLoading = true;
      this.candidateScreeningService.submitCountersignedApp(body).subscribe(
        (response: any) => {
          if (response && response.responseDetails.some(res => res.status === 'FAILED')) {
            this.toastyService.error('Failure submitting some countersign to DBS');
          } else {
            this.toastyService.info('Submitted to DBS successfully');
          }
          this.getData(
            this.countersignTable.gtInfo.pageCurrent,
            this.countersignTable.gtInfo.recordLength,
            this.query, this.sortBy, this.sortOrder
          );
        },
        err => {
          addToaster('error', this.toastyService, err.status === 403 ? "403" : "serverError", 'errors');
          this.submitLoading = false;
        },
        () => this.submitLoading = false
      );
    }
  }

  getStatusProperties(value: string) {
    let res = {
      'statusType': '',
      'statusColor': '',
      'statusBorder': '#DEE0E0',
      'statusFill': '',
      'statusSize': 'small'
    };
    _.forEach(countersignDashboardStatuses, function(status) {
      if (status.name === value) {
        res.statusType = status.icon;
        res.statusColor = status.color;
        res.statusBorder = status.border;
        res.statusFill = status.fill;
        res.statusSize = status.size;
      }
    });
    return res;
  }


  convertStatus(status: any) {
    return {
      'statusValue': status.value,
      'statusType': status.icon,
      'statusColor': status.color,
      'statusBorder': status.border,
      'statusFill': status.fill,
      'statusSize': status.size,
    };
  }

  public configObject = {
    settings: [
      {
        objectKey: "id",
        visible: false,
      },
      {
        objectKey: 'checkbox',
        columnOrder: 0,
      },
      {
        objectKey: "applicantFullName",
        sortOrder: 0,
        columnOrder: 1,
        sort: "asc",
      },
      {
        objectKey: "organisationName",
        sortOrder: 0,
        columnOrder: 2,
      },
      {
        objectKey: "position",
        sortOrder: 0,
        columnOrder: 3,
      },
      {
        objectKey: "workForceType",
        sortOrder: 0,
        columnOrder: 4,
      },
      {
        objectKey: "dbsCheckType",
        sortOrder: 0,
        columnOrder: 6,
      },
      {
        objectKey: "barringList",
        sortOrder: 0,
        columnOrder: 5,
      },
      {
        objectKey: "workAtHomeAddress",
        sortOrder: 0,
        columnOrder: 7,
      },
      {
        objectKey: "route",
        sortOrder: 0,
        columnOrder: 9,
      },
      {
        objectKey: "isUkNational",
        sortOrder: 0,
        columnOrder: 10,
      },
      {
        objectKey: "additionalBarringList",
        sortOrder: 0,
        columnOrder: 8,
      },
      {
        objectKey: 'statusComponent',
        sort: 'disable',
        sortOrder: 0,
        columnOrder: 11
      }, {
        objectKey: 'statusValue',
        visible: false
      }, {
        objectKey: 'statusType',
        visible: false
      }, {
        objectKey: 'statusColor',
        visible: false
      }, {
        objectKey: 'statusBorder',
        visible: false
      }, {
        objectKey: 'statusFill',
        visible: false
      }, {
        objectKey: 'statusSize',
        visible: false
      }
    ],
    fields: [
      {
        name: "id",
        objectKey: "id",
      },
      {
        name: '',
        objectKey: 'checkbox',
        columnClass: 'text-right',
        columnComponent: {
          type: 'checkbox'
        },
        value: row => this.countersignTable.isRowSelected(row)
      },
      {
        name: "Applicant",
        objectKey: "applicantFullName",
        columnClass: (row, col) => {
          if (row == undefined) return "no-top-border";
        },
      },
      {
        name: "Organisation",
        objectKey: "organisationName",
        columnClass: (row, col) => {
          if (row == undefined) return "no-top-border";
        },
      },
      {
        name: "Position",
        objectKey: "position",
        columnClass: (row, col) => {
          if (row == undefined) return "no-top-border";
        },
      },
      {
        name: "Workforce",
        objectKey: "workForceType",
        columnClass: (row, col) => {
          if (row == undefined) return "no-top-border";
        },
      },
      {
        name: "T",
        objectKey: "dbsCheckType",
        columnClass: (row, col) => {
          if (row == undefined) return "no-top-border";
        },
      },
      {
        name: "BL",
        objectKey: "barringList",
        columnClass: (row, col) => {
          if (row == undefined) return "no-top-border";
        },
      },
      {
        name: "ID",
        objectKey: "route",
        columnClass: (row, col) => {
          if (row == undefined) return "no-top-border";
        },
      },
      {
        name: "UK",
        objectKey: "isUkNational",
        columnClass: (row, col) => {
          if (row == undefined) return "no-top-border";
        },
      },
      {
        name: "H",
        objectKey: "workAtHomeAddress",
        columnClass: (row, col) => {
          if (row == undefined) return "no-top-border";
        },
      },
      {
        name: "V",
        objectKey: "additionalBarringList",
        columnClass: (row, col) => {
          if (row == undefined) return "no-top-border";
        },
      },
      {
        name: 'Status',
        objectKey: 'statusComponent',
        columnClass: (row, col) => {
          if (row == undefined)
            return 'no-top-border'
        },
        columnComponent: {
          type: StatusIconComponent
        }
      }
    ],
    data: [],
    info: {},
  };
}
