<div class="modal fade" bsModal tabindex="-1" role="dialog" aria-hidden="true" [config]="{ show: true }">
  <div class="modal-dialog" (click)="$event.stopPropagation()">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title pull-left">Start Screening</h2>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancel.next()">
          <i class="material-icons">close</i>
        </button>
      </div>

      <form *ngIf="!isLoading; else loader" [formGroup]="form" class="modal-body" style="margin-top: 12px">
        <div class="modal-body-column">
          <div class="banner">
            <div>
              <img src="/assets/images/creditsafeLogo.svg">
            </div>

            The candidates current address is non-UK and therefore will not be sent to Creditsafe.
          </div>

          <!-- <div class="column-heading" style="margin-bottom: 12px; margin-top: 24px"> -->
          <!--   Reports Required -->
          <!-- </div> -->

          <!-- <div class="x-checkboxes"> -->
          <!--   <input type="checkbox" id="consumerSearch" [formControl]="control('consumerSearch')"> -->
          <!--   <label for="consumerSearch" class="x-checkbox-label" [class.disabled]="control('consumerSearch').disabled"> -->
          <!--     Creditsafe Consumer Report -->
          <!--   </label> -->

          <!--   <input type="checkbox" id="aml" [formControl]="control('aml')"> -->
          <!--   <label for="aml" class="x-checkbox-label"> -->
          <!--     AML Report -->
          <!--   </label> -->
          <!-- </div> -->
        </div>

        <!-- <div class="modal-body-column"> -->
        <!--   <div class="column-heading"> -->
        <!--     Candidate -->
        <!--   </div> -->

        <!--   <!-1- <xavier-check-fix-field *ngIf="enabled('title.value')"> -1-> -->
        <!--   <!-1-   <label>Title</label> -1-> -->
        <!--   <!-1-   <input type="text" [formControl]="control('title.value')"> -1-> -->
        <!--   <!-1- </xavier-check-fix-field> -1-> -->
        <!--   <xavier-check-fix-field *ngIf="enabled('firstName.value')"> -->
        <!--     <label>First Name</label> -->
        <!--     <input type="text" [formControl]="control('firstName.value')"> -->
        <!--   </xavier-check-fix-field> -->
        <!--   <xavier-check-fix-field *ngIf="enabled('middleName.value')"> -->
        <!--     <label>Middle Name</label> -->
        <!--     <input type="text" [formControl]="control('middleName.value')"> -->
        <!--   </xavier-check-fix-field> -->
        <!--   <xavier-check-fix-field *ngIf="enabled('lastName.value')"> -->
        <!--     <label>Last Name</label> -->
        <!--     <input type="text" [formControl]="control('lastName.value')"> -->
        <!--   </xavier-check-fix-field> -->

        <!--   <!-1- <xavier-check-fix-field *ngIf="enabled('previousFirstName.value')"> -1-> -->
        <!--   <!-1-   <label>Previous First Name</label> -1-> -->
        <!--   <!-1-   <input type="text" [formControl]="control('previousFirstName.value')"> -1-> -->
        <!--   <!-1- </xavier-check-fix-field> -1-> -->
        <!--   <!-1- <xavier-check-fix-field *ngIf="enabled('previousLastName.value')"> -1-> -->
        <!--   <!-1-   <label>Previous Last Name</label> -1-> -->
        <!--   <!-1-   <input type="text" [formControl]="control('previousLastName.value')"> -1-> -->
        <!--   <!-1- </xavier-check-fix-field> -1-> -->

        <!--   <xavier-check-fix-field *ngIf="enabled('dateOfBirth.value')"> -->
        <!--     <label>Date of Birth</label> -->
        <!--     <my-date-picker [options]="dpOptions" [formControl]="control('dateOfBirth.value')" [valueTransformer]="[xdate2mydp, mydp2xdate]"></my-date-picker> -->
        <!--   </xavier-check-fix-field> -->

        <!--   <!-1- <ng-container *ngIf="enabled('currentAddress')" formGroupName="currentAddress"> -1-> -->
        <!--   <!-1-   <div class="column-heading"> -1-> -->
        <!--   <!-1-     Current Address -1-> -->
        <!--   <!-1-   </div> -1-> -->

        <!--   <!-1-   <xavier-check-fix-field *ngIf="enabled('currentAddress.buildingName.value')"> -1-> -->
        <!--   <!-1-     <label>House Name</label> -1-> -->
        <!--   <!-1-     <input type="text" [formControl]="control('currentAddress.buildingName.value')"> -1-> -->
        <!--   <!-1-   </xavier-check-fix-field> -1-> -->
        <!--   <!-1-   <xavier-check-fix-field *ngIf="enabled('currentAddress.buildingNo.value')"> -1-> -->
        <!--   <!-1-     <label>House Number</label> -1-> -->
        <!--   <!-1-     <input type="text" [formControl]="control('currentAddress.buildingNo.value')"> -1-> -->
        <!--   <!-1-   </xavier-check-fix-field> -1-> -->

        <!--   <!-1-   <xavier-check-fix-field *ngIf="enabled('currentAddress.street.value')"> -1-> -->
        <!--   <!-1-     <label>Street Name</label> -1-> -->
        <!--   <!-1-     <input type="text" [formControl]="control('currentAddress.street.value')"> -1-> -->
        <!--   <!-1-   </xavier-check-fix-field> -1-> -->
        <!--   <!-1-   <xavier-check-fix-field *ngIf="enabled('currentAddress.city.value')"> -1-> -->
        <!--   <!-1-     <label>Town/City</label> -1-> -->
        <!--   <!-1-     <input type="text" [formControl]="control('currentAddress.city.value')"> -1-> -->
        <!--   <!-1-   </xavier-check-fix-field> -1-> -->
        <!--   <!-1-   <xavier-check-fix-field *ngIf="enabled('currentAddress.postCode.value')"> -1-> -->
        <!--   <!-1-     <label>Postcode</label> -1-> -->
        <!--   <!-1-     <input type="text" [formControl]="control('currentAddress.postCode.value')"> -1-> -->
        <!--   <!-1-   </xavier-check-fix-field> -1-> -->

        <!--   <!-1-   <xavier-check-fix-field *ngIf="enabled('currentAddress.startDate.value')"> -1-> -->
        <!--   <!-1-     <label>Date Moved In</label> -1-> -->
        <!--   <!-1-     <my-date-picker [options]="dpOptions" [formControl]="control('currentAddress.startDate.value')" [valueTransformer]="[xdate2mydp, mydp2xdate]"></my-date-picker> -1-> -->
        <!--   <!-1-   </xavier-check-fix-field> -1-> -->
        <!--   <!-1-   <xavier-check-fix-field *ngIf="enabled('currentAddress.endDate.value')"> -1-> -->
        <!--   <!-1-     <label>Date Moved Out</label> -1-> -->
        <!--   <!-1-     <my-date-picker [options]="dpOptions" [formControl]="control('currentAddress.endDate.value')" [valueTransformer]="[xdate2mydp, mydp2xdate]"></my-date-picker> -1-> -->
        <!--   <!-1-   </xavier-check-fix-field> -1-> -->
        <!--   <!-1- </ng-container> -1-> -->

        <!--   <!-1- <ng-container *ngIf="enabled('previousAddress')" formGroupName="previousAddress"> -1-> -->
        <!--   <!-1-   <div class="column-heading"> -1-> -->
        <!--   <!-1-     Previous Address -1-> -->
        <!--   <!-1-   </div> -1-> -->

        <!--   <!-1-   <xavier-check-fix-field *ngIf="enabled('previousAddress.buildingName.value')"> -1-> -->
        <!--   <!-1-     <label>House Name</label> -1-> -->
        <!--   <!-1-     <input type="text" [formControl]="control('previousAddress.buildingName.value')"> -1-> -->
        <!--   <!-1-   </xavier-check-fix-field> -1-> -->
        <!--   <!-1-   <xavier-check-fix-field *ngIf="enabled('previousAddress.buildingNo.value')"> -1-> -->
        <!--   <!-1-     <label>House Number</label> -1-> -->
        <!--   <!-1-     <input type="text" [formControl]="control('previousAddress.buildingNo.value')"> -1-> -->
        <!--   <!-1-   </xavier-check-fix-field> -1-> -->

        <!--   <!-1-   <xavier-check-fix-field *ngIf="enabled('previousAddress.street.value')"> -1-> -->
        <!--   <!-1-     <label>Street Name</label> -1-> -->
        <!--   <!-1-     <input type="text" [formControl]="control('previousAddress.street.value')"> -1-> -->
        <!--   <!-1-   </xavier-check-fix-field> -1-> -->
        <!--   <!-1-   <xavier-check-fix-field *ngIf="enabled('previousAddress.city.value')"> -1-> -->
        <!--   <!-1-     <label>Town/City</label> -1-> -->
        <!--   <!-1-     <input type="text" [formControl]="control('previousAddress.city.value')"> -1-> -->
        <!--   <!-1-   </xavier-check-fix-field> -1-> -->
        <!--   <!-1-   <xavier-check-fix-field *ngIf="enabled('previousAddress.postCode.value')"> -1-> -->
        <!--   <!-1-     <label>Postcode</label> -1-> -->
        <!--   <!-1-     <input type="text" [formControl]="control('previousAddress.postCode.value')"> -1-> -->
        <!--   <!-1-   </xavier-check-fix-field> -1-> -->

        <!--   <!-1-   <xavier-check-fix-field *ngIf="enabled('previousAddress.startDate.value')"> -1-> -->
        <!--   <!-1-     <label>Date Moved In</label> -1-> -->
        <!--   <!-1-     <my-date-picker [options]="dpOptions" [formControl]="control('previousAddress.startDate.value')" [valueTransformer]="[xdate2mydp, mydp2xdate]"></my-date-picker> -1-> -->
        <!--   <!-1-   </xavier-check-fix-field> -1-> -->
        <!--   <!-1-   <xavier-check-fix-field *ngIf="enabled('previousAddress.endDate.value')"> -1-> -->
        <!--   <!-1-     <label>Date Moved Out</label> -1-> -->
        <!--   <!-1-     <my-date-picker [options]="dpOptions" [formControl]="control('previousAddress.endDate.value')" [valueTransformer]="[xdate2mydp, mydp2xdate]"></my-date-picker> -1-> -->
        <!--   <!-1-   </xavier-check-fix-field> -1-> -->
        <!--   <!-1- </ng-container> -1-> -->
        <!-- </div> -->
      </form>

      <!-- <pre> -->
<!-- {{ form.value|json }} -->
      <!-- </pre> -->

      <div class="modal-footer-sticky">
        <button type="button" class="btn xavier-button passed" (click)="onSubmit()">
          <div *ngIf="isSubmitting" class="spinner-wrap button"></div>
          <div *ngIf="!isSubmitting">Start Screening</div>
        </button>
        <button type="button" class="btn xavier-button-plain" (click)="cancel.next()">Cancel</button>
      </div>
    </div>
  </div>
</div>

<ng-template #loader>
  <div class="spinner-container not-button"><div class="spinner-wrap"></div></div>
</ng-template>
