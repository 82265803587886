import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {HttpService} from "../../shared-services/http.service";
import {Subject} from "rxjs/Subject";

@Injectable()

export class CompanyService {

  private eventLogEntry = new Subject<any>();
  eventLogEntry$ = this.eventLogEntry.asObservable();

  constructor(private httpService: HttpService) {

  }

  public getClientsData(pageSize, pageNum, sortBy, sortOrder, body) {
    let params = [
      { 'pageSize': pageSize.toString() },
      { 'pageNum': pageNum.toString() },
      { 'sortBy': sortBy },
      { 'sortOrder': sortOrder },
    ];
    return this.post('getClientList', body, params,false);
  }

  public getClientData(id) {
    return this.get('getClientData', null, [{ clientId: encodeURIComponent(id) }]);
  }

  public searchCompany(companyName) {
    return this.post('searchCompany', {value: companyName}, null, false);
  }

  public searchDivision(companyId, query) {
    let body = {};
    if (companyId != null)
      body['companyId'] = companyId;
    if (query != null)
      body['query'] = query;
    return this.post('searchDivision', body, null, false);
  }

  public createDivision(companyId, body) {
    return this.put('createDivision/' + companyId, body, null, false);
  }

  public editDivision(companyId, body) {
    return this.post('editDivision/' + companyId, body, null, false);
  }

  public deleteDivision(companyId, divisionId) {
    return this.post('disableDivision/' + companyId + '/' + divisionId, null, null, false);
  }

  public searchDivisionWithPersonnel(companyId, query) {
    let body = {};
    if (companyId != null)
      body['companyId'] = companyId;
    if (query != null)
      body['query'] = query;
    return this.post('searchDivisionWithPersonnel', body, null, false);
  }

  public getCompanyUsers(companyId) {
    return this.get('getCompanyUsers/' + companyId, null, null);
  }

  public lineManagersCompany(id) {
    return this.get('lineManagersCompany/' + id, null, null);
  }

  public humanResourcesCompany(id) {
    return this.get('humanResourcesCompany/' + id, null, null);
  }

  public lineManagersDivision(companyAndDivisionId) {
    return this.get('lineManagersDivision/' + companyAndDivisionId, null, null);
  }

  public humanResourcesDivision(companyAndDivisionId) {
    return this.get('humanResourcesDivision/' + companyAndDivisionId, null, null);
  }

  public getCompanyIdVerifiers(companyId) {
    return this.get('IDVerifierCompany/' + companyId, null, null);
  }

  public getEventLog(id) {
    return this.httpService.get("/_ah/api/log/v1", 'getClientLog', [{ clientId: encodeURIComponent(id) }]);
  }

  public sendEventLogEntry(entry: any) {
    this.eventLogEntry.next(entry);
  }

  public post(methodName: string, body: any, params: any, reportProgress: boolean): Observable<any> {
    return this.httpService.post("/_ah/api/company/v1", methodName, body, params, reportProgress);
  }

  public put(methodName: string, body: any, params: any, reportProgress: boolean): Observable<any> {
    return this.httpService.put("/_ah/api/company/v1", methodName, body, params, reportProgress);
  }

  public get(methodName: string, body: any, params: any): Observable<any> {
    return this.httpService.get("/_ah/api/company/v1", methodName, params);
  }
}
