import {Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {candidateEmailErrors, errorMessages} from '../../../data/variables.data';

@Component({
  moduleId: module.id,
  selector: 'xavier-error-message',
  templateUrl: 'error-message.component.html',
  styleUrls: ['error-message.stylesheet.sass']
})

export class ErrorMessageComponent implements OnInit, OnDestroy {

  @Input() public control: AbstractControl;
  @Input() public message: string = null;
  @Input() public noLabel?: boolean;
  @Input() public passwordField?: boolean;

  private controlSub;

  constructor(public elRef: ElementRef) {

  }

  ngOnInit() {
    this.message = errorMessages['required']();
    this.controlSub = this.control.statusChanges.subscribe(() => {
      for (const error in this.control.errors) {
        if (error in errorMessages) {
          this.message = errorMessages[error](this.control.errors[error]);
          break;
        }
      }
    });
    this.control.updateValueAndValidity();
  }

  ngOnDestroy() {
    this.controlSub.unsubscribe();
  }
}
