import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'xavier-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
  @Input()
  public size: string;

  @Input()
  @HostBinding("class.autoexpand")
  public autoexpand: boolean = false;
}
