<ng2-toasty [position]="'top-center'" class="xavier-toasty"></ng2-toasty>
<div class="xavier-candidate-screening-wrapper container" [@fadeIn]>
  <div *ngIf="loading" class="xavier-center-page" [@fadeIn]>
    <div class="spinner-container not-button"><div class="spinner-wrap"></div></div>
  </div>
  <div *ngIf="!loading && !submitted && !serverError" [@fadeIn]>
    <div class="row header">
      <div class="col-sm-12 col-xl-9">
        <h1>{{dynamicForm.screeningName}}</h1>
        <span>{{dynamicForm.companyName}}</span>
      </div>
      <div class="col-sm-12 col-xl-3 d-none d-xl-block text-right">
        <button type="button" class="btn xavier-button passed"
                (click)="submitScreening()"
                [disabled]="!canSubmitScreening">
          Submit Screening
        </button>
      </div>
    </div>
    <xavier-dynamic-form [formModel]="dynamicForm"
                         [formData]="dynamicFormData"
                         [formComments]="dynamicFormComments"
                         [preview]="preview"
                         (submitted)="submittedEvent($event)"></xavier-dynamic-form>
  </div>
  <div *ngIf="submitted" [@fadeIn]>
    <div class="row header xavier-status-message-wrapper text-center">
      <div class="col-sm-12 col-xl-9 xavier-status-message">
        <h2 class="text-center">You successfully submitted your screening for {{companyName}}</h2>
        <span>We have received your data and will be in touch if we require any further information</span>
        <div *ngIf="dynamicForm.screeningStatus === 'WITH_CANDIDATE'">
          <span>Please leave us a review, it takes 10 seconds!</span>
          <div class="row">
            <div class="col-4"></div>
            <div class="col-sm-12 col-xl-4">
              <button type="button" class="btn-block btn xavier-button passed" (click)="openReview()">
                Leave a review
              </button>
            </div>
            <div class="col-4"></div>
          </div>
        </div>
      </div>
    </div>
    <footer class="xavier-footer">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <ul>
              <li>Copyright © <span>{{footerCopyrightDate | date:'y'}}</span> Xavier. All Rights Reserved.</li>
              <li>
                <a href="https://www.eurocomci.co.uk/legal.html">Terms of Service</a> /
                <a href="https://www.eurocomci.co.uk/privacy.html">Privacy Policy</a> /
                <a href="https://www.eurocomci.co.uk/contact.html">Help</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>

  <div *ngIf="serverError" [@fadeIn]>
    <div class="row header xavier-status-message-wrapper text-center">
      <div class="col-sm-12 col-xl-9 xavier-status-message">
        <h2 class="text-center">An error occurred</h2>
        <span>Please try again later.</span>
      </div>
    </div>
    <footer class="xavier-footer">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <ul>
              <li>Copyright © <span>{{footerCopyrightDate | date:'y'}}</span> Xavier. All Rights Reserved.</li>
              <li>
                <a href="https://www.eurocomci.co.uk/legal.html">Terms of Service</a> /
                <a href="https://www.eurocomci.co.uk/privacy.html">Privacy Policy</a> /
                <a href="https://www.eurocomci.co.uk/contact.html">Help</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</div>

<div *ngIf="!loading && dynamicForm && !dynamicForm.yotiCompleted"
     [config]="modalConfig"
     class="modal fade"
     tabindex="-1"
     role="dialog"
     aria-hidden="true"
     #welcomeModal="bs-modal"
     bsModal>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title pull-left">Welcome {{userName}}!</h2>
        <div class="hint">
          Complete your screening for {{ dynamicForm?.companyName }} following these simple steps:
        </div>
      </div>
      <div class="modal-body">
        <div class="welcome-candidate-steps">
          <div class="step">
            <div class="number">1</div><span>Select a section to complete from the menu</span>
          </div>
          <div class="step">
            <div class="number">2</div><span>Answer the questions</span>
          </div>
          <div class="step">
            <div class="number">3</div><span>Tap ‘Save and Continue’</span>
          </div>
          <div class="step">
            <div class="number">4</div><span>After the completion of each section, submit the screening</span>
          </div>
        </div>

        <div *ngIf="dynamicForm?.screeningInstructions" class="screeningInstructions">
          <div class="heading">Screening Instructions</div>
          <div [innerHTML]="dynamicForm.screeningInstructions" class="instructions"></div>
        </div>

        <div class="modal-button-group">
          <button type="button" class="btn xavier-button passed" (click)="hideModal()">Start Screening</button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #submitModal>
  <div class="modal-header">
    <h2 class="modal-title pull-left">Ready to submit?</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div class="modal-body">
    Thank you for completing your screening. Please choose to submit or go back and check your data and submit later.
    <div class="modal-button-group">
      <button type="button" [disabled]="modalSubmitButtonDisabled" id="modalSubmitButton"
              class="btn xavier-button continue-step" (click)="submitScreening()">
        Submit Screening
      </button>
      <button type="button" class="btn xavier-button-plain" (click)="closeModal()">
        Go Back
      </button>
    </div>
  </div>
</ng-template>
