import {Component, OnInit} from "@angular/core";
import {DashboardAttribute, ATTRIBUTE_TYPES_MAP} from "../dashboard-attribute.class";
import {DynamicFormService} from "../../../../services/dynamic-form.service";
import {FILEUPLOAD_TYPE} from "../../../../../../data/variables.data";
import {environment} from '../../../../../../environments/environment';

@Component({
  moduleId: module.id,
  selector: 'xavier-dashboard-fileupload',
  templateUrl: 'dashboard-fileupload.component.html',
  styleUrls: ['dashboard-fileupload.stylesheet.sass']
})

export class DashboardFileuploadAttribute extends DashboardAttribute implements OnInit {

  constructor(public dynamicFormService: DynamicFormService) {
    super(dynamicFormService);
  }

  ngOnInit() {
    let formGroup = this.dynamicFormService.getFormGroup(this.formGroupId);
    let formControl = this.dynamicFormService.attributeToFormControl(this.data);

    formGroup.addControl(this.formGroupId+'-'+this.data.id, formControl);

    this.formGroup = formGroup;
    this.formControl = formControl;
  }

  public get verifiedFileSrc(): string {
    if (this.verifiedFile)
      if (this.verifiedFile.hasOwnProperty('thumbnail')) {
        return 'https://' + environment.storageBucket + '/gcs/' + this.verifiedFile.folder + '/' +
          this.verifiedFile.thumbnail.replace('@xN', '@x3');
      } else {
        return 'https://' + environment.storageBucket + '/gcs/' + this.verifiedFile.folder + '/' + this.verifiedFile.name;
      }
  }

}

ATTRIBUTE_TYPES_MAP.set(FILEUPLOAD_TYPE, DashboardFileuploadAttribute);
