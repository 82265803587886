<div class="row dashboard-container xavier-popover-dashboard" [id]="'db-container_'+formGroupId+'-'+data.id"
     (mouseenter)="mouseEnter(formGroupId+'-'+data.id, pop)" (mouseleave)="mouseLeave(formGroupId+'-'+data.id)">
  <div class="col-sm-12 col-xl-6 dashboard-name">
    <div>{{data.attributeName}}</div>
  </div>
  <div class="col-sm-12 col-xl-6 dashboard-value"
       [popover]="popoverTemplate"
       [popoverContext]="context"
       [placement]="'top'"
       [containerClass]="'xavier-popover-dashboard-container value'"
       [triggers]="'mouseenter:mouseleave'"
       #pop="bs-popover">
    <ng-template #popoverTemplate let-message="message">{{message}}</ng-template>
    <div [id]="formGroupId+'-'+data.id"></div>
    <xavier-yoti-logo *ngIf="prefilledByYoti" [verified]="isVerified"></xavier-yoti-logo>
  </div>

  <ng-container *ngIf="prefilledByYoti && !isVerified">
    <div class="col-sm-12 col-xl-6 dashboard-name">
      <div></div>
    </div>
    <div *ngIf="verifiedFileSrc" class="col-sm-12 col-xl-6 dashboard-value" style="margin-top: 15px">
      <a [href]="verifiedFileSrc + '?download=true'" [download]="verifiedFileSrc" class="pointer">
        <img class="verified-file" [src]="verifiedFileSrc"/>
      </a>
      <xavier-yoti-logo *ngIf="prefilledByYoti" [verified]="true"></xavier-yoti-logo>
    </div>
  </ng-container>

  <div *ngIf="!isScreeningCompleted" class="dashboard-toolbar text-right">
    <xavier-dashboard-toolbar [elementId]="formGroupId+'-'+data.id" [screeningStatus]="screeningStatus"
                              [reference]="reference">
    </xavier-dashboard-toolbar>
  </div>
</div>
<div class="row">
  <div class="col-12 dashboard-comments" [id]="'db-comments_'+formGroupId+'-'+data.id"></div>
</div>
