<div class="row mb-5" [formGroup]="form">
  <div class="col">
    <div class="form-panel details">
      <h3>ID Verification {{ groupName }}</h3>
    </div>

    <div class="container panel">
      <div class="form-group">
        <ng-container *ngFor="let item of form.get('documentsArray').controls; let i = index">
          <ng-container *ngIf="item.value.group as group">
            <div class="row my-3">
              <div class="col-1">
                <input type="checkbox" (change)="onCheckboxChange(item)" [formControl]="item.get('isChecked')" class="form-check-input" [id]="group.label">
                <label [for]="group.label"></label>
              </div>
              <div class="col-9 px-0">
                <label [for]="group.label" class="h6">{{ group.label }}</label>
              </div>
              <div class="col-auto float-right">
                <div [tooltip]="group.helpTooltipText" placement="top" container="body"
                  containerClass="xavier-popover-dashboard-container">
                  <img src="assets/icons/help_icon.png" class="w-75" alt="">
                </div>
              </div>
            </div>

            <!-- Passports -->
            <div class="row mx-auto" *ngIf="item.get('isChecked').value && group.questionSet === 'PASSPORT'" [formGroup]="item.get('passport')">
              <div class="col-12">
                <div class="form-group xavier-input-text">
                  <label for="passport_number">Passport Number</label>
                  <input type="text" class="form-control" formControlName="passport_number" placeholder="Passport Number">
                  <xavier-error-message [control]="item.get('passport.passport_number')"></xavier-error-message>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group xavier-dropdown-wrapper">
                  <div>
                    <label for="nationality">Nationality as shown in Passport</label>
                  </div>
                  <div class="xavier-dropdown-wrapper">
                    <ng-select
                      class="form-control xavier-dropdown"
                      id="nationality"
                      [options]="countriesList$ | async"
                      placeholder="Please Select..."
                      formControlName="nationality"
                      [allowClear]="false">
                    </ng-select>
                    <i class="material-icons ng-select-search">&#xE8B6;</i>
                    <xavier-error-message [control]="item.get('passport.nationality')"></xavier-error-message>
                  </div>
                  <!-- <label for="nationality">Nationality as shown in Passport</label>
                  <xavier-dropdown [itemSource]="countryItemSource" [compareWith]="compareFn" formControlName="nationality">
                    <ng-template let-item #itemTemplate>{{ item }}</ng-template>
                    <ng-template let-item #valueTemplate>{{ item }}</ng-template>
                  </xavier-dropdown> -->
                </div>
              </div>
              <div class="col-12">
                <div class="form-group xavier-datetime-picker">
                  <label for="date_of_issue">Date of Issue</label>
                  <my-date-picker [options]="dpOptions" [valueTransformer]="[xdate2mydp, mydp2xdate]" class="form-control" [formControl]="item.get('passport.date_of_issue')"></my-date-picker>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group xavier-datetime-picker">
                  <label for="date_of_birth">Date of Birth</label>
                  <my-date-picker [options]="dpOptions" [valueTransformer]="[xdate2mydp, mydp2xdate]" class="form-control" [formControl]="item.get('passport.date_of_birth')"></my-date-picker>
                </div>
              </div>
            </div>

            <!-- Drivers Licence -->
            <div class="row mx-auto" *ngIf="item.get('isChecked').value && group.questionSet === 'DRIVERS_LICENCE'" [formGroup]="item.get('drivers_licence')">
              <div class="col-12">
                <div class="form-group xavier-input-text">
                  <label for="driving_licence_number">Driving Licence Number</label>
                  <input type="text" class="form-control" formControlName="driving_licence_number" placeholder="Driving Licence Number">
                  <xavier-error-message [control]="item.get('drivers_licence.driving_licence_number')"></xavier-error-message>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group xavier-input-text">
                  <label for="country_of_issue">Country of Issue</label>
                  <input type="text" class="form-control" formControlName="country_of_issue" placeholder="Country of Issue">
                  <xavier-error-message [control]="item.get('drivers_licence.country_of_issue')"></xavier-error-message>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group xavier-datetime-picker">
                  <label for="valid_from">Valid From</label>
                  <my-date-picker [options]="dpOptions" [valueTransformer]="[xdate2mydp, mydp2xdate]" class="form-control" [formControl]="item.get('drivers_licence.valid_from')"></my-date-picker>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group xavier-datetime-picker">
                  <label for="date_of_issue">Date of Birth</label>
                  <my-date-picker [options]="dpOptions" [valueTransformer]="[xdate2mydp, mydp2xdate]" class="form-control" [formControl]="item.get('drivers_licence.date_of_birth')"></my-date-picker>
                </div>
              </div>
            </div>

            <!-- Birth Certificate -->
            <div class="row mx-auto" *ngIf="item.get('isChecked').value && group.questionSet === 'ORIGINAL_BIRTH_CERTIFICATE'" [formGroup]="item.get('original_birth_certificate')">
              <div class="col-12">
                <div class="form-group xavier-datetime-picker">
                  <label for="date_of_issue">Date of Birth</label>
                  <my-date-picker [options]="dpOptions" [valueTransformer]="[xdate2mydp, mydp2xdate]" class="form-control" [formControl]="item.get('original_birth_certificate.date_of_birth')"></my-date-picker>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group xavier-datetime-picker">
                  <label for="valid_from">Date of Issue</label>
                  <my-date-picker [options]="dpOptions" [valueTransformer]="[xdate2mydp, mydp2xdate]" class="form-control" [formControl]="item.get('original_birth_certificate.date_of_issue')"></my-date-picker>
                </div>
              </div>
            </div>

          </ng-container>
        </ng-container>

        <!-- No candidate check -->
        <div *ngIf="form.get('noCandidateCheck') as item" class="row my-3">
          <div class="col-1"></div>
          <div class="col-9 px-0">
            <label for="NogroupCandidate" class="h6">Click here if the applicant does not have any documents from Group 1</label>
          </div>
          <div class="col-auto float-right">
            <input type="checkbox" (change)="onCheckboxChange(item)" [formControl]="item.get('isChecked')"  class="form-check-input" [id]="'NogroupCandidate'">
            <label for="NogroupCandidate"></label>
          </div>

          <!-- Run Credit Safe Button -->
          <div *ngIf="item.get('isChecked').value && candidateInfo" class="col-12 my-4">
            <button *ngIf="candidateInfo.creditSafeStatus === 'NOT_STARTED'" type="button" class="btn btn-block xavier-button continue-step" (click)="onRunIDCheckClicked()">
              <span>External ID Verification</span>
            </button>
            <h3 *ngIf="candidateInfo.creditSafeStatus === 'SUCCESSFUL'" class="pass-text text-center">External ID Verification - PASS</h3>
            <h3 *ngIf="candidateInfo.creditSafeStatus === 'FAILED'" class="fail-text text-center">External ID Verification - FAIL</h3>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<xavier-start-screening-dialog *ngIf="isRunIDCheckVisible" [onlyCreditCheck]="true" (success)="onRunIDCheckDone($event)" (cancel)="isRunIDCheckVisible = false">
  <span modalTitle>Run ID Check</span>
  <span buttonTitle>Run ID Check</span>
</xavier-start-screening-dialog>

