import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CandidateScreeningService } from '../../modules/admin/services/candidate-screening.service';
import { ActivatedRoute } from '@angular/router';
import { IMyDpOptions } from 'mydatepicker';
import { CreditSafeCandidateDTO } from '../../model/credit-safe-candidate.dto';
import * as _ from 'lodash';

@Component({
  selector: 'xavier-start-screening-no-creditsafe-dialog',
  templateUrl: './start-screening-no-creditsafe-dialog.component.html',
  styleUrls: ['./start-screening-no-creditsafe-dialog.component.sass']
})
export class StartScreeningNoCreditsafeDialogComponent {
  @Output()
  public success = new EventEmitter<any>();

  @Output()
  public cancel = new EventEmitter();

  public form = this.fb.group({
    firstName: this.fb.group({ value:  [null], nodeId: [null] }),
    middleName: this.fb.group({ value:  [null], nodeId: [null] }),
    lastName: this.fb.group({ value:  [null], nodeId: [null] }),
    dateOfBirth: this.fb.group({ value:  [null], nodeId: [null] }),

    aml: [null],
    consumerSearch: [{ value: null, disabled: true }],
  });

  public control(name: string) { return this.form.get(name) }
  public enabled(name: string) { return this.form.get(name).enabled }
  private get candidateId() { return this.route.snapshot.params["id"] }

  public isLoading = false;
  public isSubmitting = false;

  public dpOptions: IMyDpOptions = {
    dateFormat: 'dd mmm yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    showClearDateBtn: true,
  };

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private candidateScreeningService: CandidateScreeningService,
  ) { }

  public onSubmit() {
    this.isSubmitting = true;
    this.candidateScreeningService.startScreening(this.candidateId, { } as any).subscribe(
      data => {
        if (Object.keys(data as any).length === 0) return;

        this.success.next(data);
        this.isSubmitting = false;
      },
      e => {
        this.isSubmitting = false;
      });
  }
}
