<div class="btn-group" dropdown *ngIf="row.downloadIconShown && (row.certificateFileName || row.creditReportFileName || row.amlReportFileName)">
  <button dropdownToggle type="button" class="btn xavier-navbuttonitem">
    <div class="filter-icon-wrapper download" *ngIf="row.downloadIconShown">
      <i class="material-icons small">download</i>
    </div>
  </button>
  <ul *dropdownMenu class="dropdown-menu dropdown-menu-right xavier-nav-dropdown" role="menu">
    <li role="menuitem" *ngIf="!!row.certificateFileName">
      <a class="dropdown-item" (click)="iconClick(row.id, 'lidCertificate', row.certificateFileName)">
        <div class="filter-icon-wrapper download">
          <i class="material-icons small dropdown-download">download</i>
        </div>
        <div>
          Download ID Certificate
        </div>
      </a>
    </li>
    <li role="menuitem" *ngIf="!!row.creditReportFileName">
      <a class="dropdown-item" (click)="iconClick(row.id, 'creditReport', row.creditReportFileName)">
        <div class="filter-icon-wrapper download">
          <i class="material-icons small dropdown-download">download</i>
        </div>
        <div>
          Download Credit Report
        </div>
      </a>
    </li>
    <li role="menuitem" *ngIf="!!row.amlReportFileName">
      <a class="dropdown-item" (click)="iconClick(row.id, 'amlReport', row.amlReportFileName)">
        <div class="filter-icon-wrapper download">
          <i class="material-icons small dropdown-download">download</i>
        </div>
        <div>
          Download AML Report
        </div>
      </a>
    </li>
  </ul>
</div>
