<div class="dropdown-container">
  <div class="dropdown" [class.is-open]="isOpen" (click)="onDropdownClicked()">
    <ng-container *ngTemplateOutlet="valueTemplate || defaultValueTemplate, context: { $implicit: selected }">
    </ng-container>
    &nbsp;
    <i *ngIf="icon" class="material-icons chevron">{{icon}}</i>
  </div>

  <span class="material-icons error-icon">error</span>
</div>

<ng-template #defaultItemTemplate let-item>{{item}}</ng-template>
<ng-template #defaultValueTemplate let-item>{{item}}</ng-template>

<ng-template #desktopDrawer>
  <div class="drop">
    <input type="text" *ngIf="filterWith || itemSourceWithSearch" [(ngModel)]="searchTerm" (ngModelChange)="searchTerm$.next($event)" autocomplete="one-time-code" [placeholder]="placeholder" icon="search" #searchInput>

    <xavier-spinner *ngIf="isLoading"></xavier-spinner>

    <div class="list">
      <div *ngIf="filteredItems.length === 0 && !isTyping && !isLoading && (!itemSourceWithSearch || searchTerm)" class="item none-available">
        None available
      </div>

      <div *ngIf="filteredItems.length && !isLoading && this.selected" (click)="onClearClicked()" class="item clear">
        Clear
      </div>

      <div *ngFor="let item of filteredItems" (click)="onItemClicked(item)" class="item">
        <i *ngIf="!isSelected(item)" class="material-icons">radio_button_unchecked</i>
        <i *ngIf="isSelected(item)" class="material-icons">radio_button_checked</i>

        <ng-container *ngTemplateOutlet="itemTemplate || defaultItemTemplate, context: { $implicit: item }">
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
