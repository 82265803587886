<xavier-check-fix-field>
  <label>Title</label>
  <input type="text" value="Ahoj">
</xavier-check-fix-field>

<xavier-check-fix-field>
  <label>Title</label>
  <input type="text" value="Nazdar Mr">
</xavier-check-fix-field>

<xavier-check-fix-field>
  <label>Date Picker</label>
  <my-date-picker [options]="dpOptions"></my-date-picker>
</xavier-check-fix-field>

<xavier-check-fix-field>
  <label>Select</label>
  <ng-select [options]="items"></ng-select>
</xavier-check-fix-field>
