<ng2-toasty [position]="'top-center'" class="xavier-toasty"></ng2-toasty>
<div class="xavier-steps-wrapper container">
    <div class="row">
      <div class="col-sm-4">
        <div class="xavier-steps header">
            Secure Account
        </div>
        <div class="xavier-steps-component">
          <xavier-steps
            step="1"
            [maxSteps]="2"
            [stepDescriptionLine1]="'Verify your mobile number'"
            [stepDescriptionLine2]="'Please provide your mobile number for security purposes.'"
        ></xavier-steps>
        </div>
      </div>
      <div class="col-sm-8">
        <div class="xavier-register container">
          <div class="row justify-content-between">
            <form [formGroup]="form" class="w-100">
              <div class="form-group xavier-input-text w-100 d-flex justify-content-between">
                <div class="flex-1">
                  <label for="phonePrefix">Mobile Number</label>
                </div>
                <div class="d-flex flex-2 justify-content-end w-100">
                  <select class="xavier-tel-combo-select" id="phonePrefix" formControlName="phonePrefix">
                    <option *ngFor="let country of countryCodes" [attr.data-countryCode]="country.code" [value]="country.prefix">{{ country.prefix }}</option>
                  </select>

                  <input type="text" class="form-control xavier-tel-number" id="phone" formControlName="phone" placeholder="Mobile Number" autocomplete="one-time-code">
                  <xavier-error-message [control]="form.get('phone')"></xavier-error-message>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button (click)="onSubmit()" id="submitButton" type="submit" [disabled]="!form.valid || disabled" class="btn xavier-button px-4 continue-step d-inline-flex align-items-center justify-content-center">
        <span>Next</span> <i class="material-icons">&#xE5C8;</i>
      </button>
    </div>
  </div>
