import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {RegistrationModule} from '../modules/registration/registration.module';
import {RouterModule} from '@angular/router';
import {AdminModule} from '../modules/admin/admin.module';
import '../extensions/extensions';
import {DialogComponent} from './dialog/dialog.component';
import {DialogService} from './dialog/dialog.service';
import {ModalModule} from 'ngx-bootstrap/modal';
import {OverlayModule} from '@angular/cdk/overlay';
import {SwUpdateService} from '../core/sw-update.service';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    DialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AdminModule,
    RegistrationModule,
    RouterModule,
    ModalModule,
    OverlayModule,
    ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.serviceWorkerEnabled }),
  ],
  providers: [
    DialogService,
    SwUpdateService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
