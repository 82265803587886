import { Component, OnInit, ContentChildren, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ElementRef } from '@angular/core';

@Component({
  selector: 'xavier-check-fix-field',
  templateUrl: './check-fix-field.component.html',
  styleUrls: ['./check-fix-field.component.sass'],
})
export class CheckFixFieldComponent {
  @ContentChildren(NgControl) 
  public ngControl: NgControl;

  @Input()
  public hasCheck = true;

  constructor(
    private el: ElementRef
  ) { }

  public get isCheckVisible() {
    const ngControl: any = this.ngControl || {};
    return ngControl && !!this.el.nativeElement.querySelector(":focus");
  }

  public onClick() {
    const input = this.el.nativeElement.querySelector("input");

    if (!input) return;

    input.click();
    input.focus();
  }
}
