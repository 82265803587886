import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HttpClient, HttpHeaders, HttpRequest, HttpResponse} from "@angular/common/http";
import {NavigationEnd, Router} from "@angular/router";
import {AdminUser} from "../../model/adminuser.model";
import {FormService} from "../../services/form.service";
import {securityQuestions} from "../../../../data/variables.data";
import {environment} from "../../../../environments/environment";
import {fieldsMatcher, passwordValidator} from "../../../utils/form.validators";
import {setAsTouched} from "../../../utils/functions.utils";
import {slideIn} from "../../../../data/animations.data";

@Component({
  moduleId: module.id,
  selector: 'xavier-step4',
  templateUrl: 'step4.component.html',
  styleUrls: [
    'step4.stylesheet.sass',
    'step4-mediaqueries.stylesheet.sass'
  ],
  animations: [slideIn]
})

export class Step4Component implements OnInit {

  @Input() public errorClass: string;

  public disabled: boolean = false;
  public registrationData: FormGroup;
  public securityQuestions = securityQuestions;

  private componentStep = 4;

  constructor(private fb: FormBuilder, private router: Router, private registrationFormService: FormService, private http: HttpClient) {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd))
        return;
      window.scrollTo(0, 0)
    });
  }

  ngOnInit() {
    if (this.registrationFormService.getCompletedStep() != this.componentStep - 1) {
      this.router.navigate(['/registration/' + this.registrationFormService.getCompletedStep()]);
    }

    this.registrationData = this.fb.group({
      manager: this.fb.group({
        password: ['', [Validators.required, passwordValidator]],
        passwordConfirm: ['', [Validators.required]]
      }, {validator: fieldsMatcher('password', 'passwordConfirm')}),
      securityQuestion: [null, Validators.required],
      securityAnswer: ['', Validators.required]
    });
    this.registrationData.patchValue(this.registrationFormService.getAll());
  }

  onSubmit({ value, valid } : { value: AdminUser, valid: boolean }) {
    if (!valid) {
      setAsTouched(this.registrationData);
    } else {
      this.disabled = true;
      this.registrationFormService.save(value, this.componentStep);

      let confirmButton = document.getElementById('confirmForm');
      const spinnerWrap = document.createElement('div');
      spinnerWrap.classList.add('spinner-wrap');
      spinnerWrap.classList.add('button');

      confirmButton.innerHTML = '';
      confirmButton.appendChild(spinnerWrap);

      let headers = new HttpHeaders({ 'Content-Type': 'application/json;charset=utf-8' });
      const req = new HttpRequest(
        'PUT',
        'https://'+environment.storageBucket+'/_ah/api/registration/v1/submit',
        this.registrationFormService.serializeData(),
        {
          headers: headers,
          withCredentials: true
        }
      );

      this.http.request(req).subscribe(event => {
        if (event instanceof HttpResponse)
          if (event.status == 200) {
            this.router.navigate(['/registration/' + (this.componentStep + 1)],
              { queryParams: { uuid: event.body['value'] } }
            );
          } else {
            this.disabled = false;
          }
      });
    }
  }

  backRegistration() {
    this.router.navigate(['/registration/' + (this.componentStep - 1)]);
  }

}
