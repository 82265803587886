<div class="form-group xavier-datetime-picker" [formGroup]="formGroup" [hidden]="hidden">
  <div>
    <label [for]="formGroupId+'-'+data.id">{{data.attributeName}}</label>
    <span *ngIf="data.required" class="required">&bull;</span>
    <div class="xavier-popover-info">
      <i *ngIf="data.information !== ''"
         class="material-icons"
         tooltip="{{data.information}}"
         placement="auto"
         containerClass="xavier-popover-container">&#xE8FD;</i>
    </div>
  </div>
  <my-date-picker #dfdatepicker
                  class="form-control"
                  [id]="formGroupId+'-'+data.id"
                  [(ngModel)]="componentDate"
                  [options]="dfDatePickerOptions"
                  [placeholder]="data.placeholder !== undefined ? data.placeholder : 'Please Select...'"
                  [formControlName]="formGroupId+'-'+data.id"
                  (dateChanged)="onDateChanged($event)"
                  (calendarToggle)="onCalendarToggle($event)">
  </my-date-picker>
  <xavier-error-message [control]="formControl"></xavier-error-message>
  <div class="returned-comment">{{commentMsg}}</div>
  <ng-container *ngIf="subsequentDatesError">
    <div class="date-error">
      The date is not subsequent or coherent with the others, there can be no gaps.
    </div>
  </ng-container>
</div>
