import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {ScreeningService} from "../../services/screening.service";
import {screeningsDashboardStatuses} from "../../../../data/variables.data";
import {UserPermission} from "../../model/permissions.model";
import {UserAuth} from "../../../../core/userAuth.core";
import {fadeIn} from "../../../../data/animations.data";
import {formatDate} from "../../../utils/functions.utils";
import {isNullOrUndefined} from "util";
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'xavier-preview-screening',
  templateUrl: 'preview-screening.component.html',
  styleUrls: ['preview-screening.stylesheet.sass'],
  animations: [fadeIn]
})

export class PreviewScreeningComponent implements OnInit, OnDestroy {

  @Input() public loading: boolean = true;
  @Input() public dynamicForm: any;
  @Input() public filter: any = {
    statusBorder: "",
    statusFill: "",
    statusColor: "",
    statusSize: "",
    statusType: ""
  };

  public screeningName;
  public companyName;
  public divisionName;
  public price;
  public discountedPrice;
  public cost;
  public slaTarget;
  public lastUsed;
  public statusValue;

  private id: number;
  private routeSub: any;
  private screeningTobBarInfoSub: any;
  private screeningPreviewSub: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private screeningService: ScreeningService,
              private userAuth: UserAuth) {
    this.routeSub = this.activatedRoute.params.subscribe(params => {
      this.id = +params['id'];
    });
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd))
        return;
      window.scrollTo(0, 0)
    });
  }

  ngOnInit() {
    this.screeningTobBarInfoSub = this.screeningService.getScreeningTopBarInfo(this.id).subscribe(topInfo => {
      if (!isNullOrUndefined(topInfo.id)) {
        this.screeningName = topInfo.name;
        this.companyName = topInfo.company;
        this.divisionName = topInfo.hasOwnProperty('division') ? topInfo.division : '';
        this.price = '£'+topInfo.price;
        if (UserPermission.isAllowed(['SCREENING_FORM_PREVIEW_COMPANY'], this.userAuth.getUser().permissionList)) {
          this.price = topInfo.hasOwnProperty('discountedPrice') ? '£'+topInfo.discountedPrice : '£'+topInfo.price;
        }
        this.discountedPrice = topInfo.hasOwnProperty('discountedPrice') ? '£'+topInfo.discountedPrice : null;
        this.cost = '£'+topInfo.cost;
        this.slaTarget = topInfo.slaTarget;
        this.lastUsed = topInfo.hasOwnProperty('lastUsed') ? formatDate(topInfo.lastUsed) : 'Never';
        this.statusValue = topInfo.disabled ? 'Disabled' : 'Active';

        _.forEach(screeningsDashboardStatuses, ( function(filter) {
          if (filter.name === this.statusValue.toUpperCase()) {
            this.filter.statusType = filter.icon;
            this.filter.statusColor = filter.color;
            this.filter.statusBorder = filter.border;
            this.filter.statusFill = filter.fill;
            this.filter.statusSize = filter.size;
            return;
          }
        }).bind(this));
      }

      this.screeningPreviewSub = this.screeningService.getScreeningPreviewData(this.id).subscribe(previewData => {
        if (!isNullOrUndefined(previewData.screeningName)) {
          this.dynamicForm = previewData;
          this.loading = false;
        }
      });
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
    this.screeningTobBarInfoSub.unsubscribe();
    this.screeningPreviewSub.unsubscribe();
  }

  editScreening() {
    this.router.navigate(['/dashboard/screenings/edit/' + this.id], { skipLocationChange: true });
  }

}
