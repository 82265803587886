<div class="xavier-steps-wrapper container">
  <div class="row">
    <div class="col-sm-4">
      <div class="xavier-steps header">
        Create Account
      </div>
      <div class="xavier-steps-component">
        <xavier-steps step="4"></xavier-steps>
      </div>
    </div>
    <div class="col-sm-8" [@slideIn]>
      <form [formGroup]="registrationData" novalidate (ngSubmit)="onSubmit(registrationData)">
        <div class="row password-wrapper">
          <div class="col-xs-12 col-xl-3 header">
            Password
          </div>
          <div class="col-xs-12 col-xl-1 password-spacer"></div>
          <div class="col-xs-12 col-xl-8">
            <div class="row" formGroupName="manager">
              <div class="form-group col-12 xavier-input-text">
                <label for="password">Enter Password</label>
                <span class="required">&bull;</span>
                <input type="password" class="form-control" id="password" formControlName="password" placeholder="Enter Password" validateOnBlur [validateFormControl]="registrationData.get('manager')">
                <xavier-error-message [control]="registrationData.get('manager.password')" [passwordField]="true"></xavier-error-message>
                <xavier-password-strength [control]="registrationData.get('manager.password')"></xavier-password-strength>
              </div>
              <div class="form-group col-12 xavier-input-text">
                <label for="passwordConfirm">Re-Enter Password</label>
                <span class="required">&bull;</span>
                <input type="password" class="form-control" id="passwordConfirm" formControlName="passwordConfirm" placeholder="Re-Enter Password" validateOnBlur [validateFormControl]="registrationData.get('manager')">
                <xavier-error-message [control]="registrationData.get('manager')"></xavier-error-message>
              </div>
            </div>
          </div>
        </div>
        <div class="row password-wrapper">
          <div class="col-xs-12 col-xl-3 header">
            Security Question
          </div>
          <div class="col-xs-12 col-xl-1 password-spacer"></div>
          <div class="col-xs-12 col-xl-8">
            <div class="row">
              <div class="form-group col-xs-12 col-xl-12 xavier-dropdown-wrapper">
                <i class="material-icons dropdown-caret">&#xE5C5;</i>
                <label for="securityQuestion">Secret Question</label>
                <span class="required">&bull;</span>
                <select class="form-control xavier-dropdown" id="securityQuestion" formControlName="securityQuestion">
                  <option value=null disabled selected>Please Select</option>
                  <option *ngFor="let question of securityQuestions" [value]="question.id">{{question.label}}</option>
                </select>
              </div>
              <div class="form-group col-xs-12 col-xl-12 xavier-input-text">
                <label for="securityAnswer">Answer</label>
                <span class="required">&bull;</span>
                <input type="text" class="form-control" id="securityAnswer" formControlName="securityAnswer" placeholder="Answer">
                <xavier-error-message [control]="registrationData.get('securityAnswer')"></xavier-error-message>
              </div>
            </div>
          </div>
        </div>
        <div class="row buttons-margin">
          <div class="col-xs-12 col-xl-2">
            <button type="button" class="btn btn-block xavier-button-plain" (click)="backRegistration()">
              <span>Back</span>
            </button>
          </div>
          <div class="col-7 button-spacer"></div>
          <div class="col-xs-12 col-xl-3">
            <button type="submit" class="btn btn-block xavier-button continue-step" id="confirmForm" [disabled]="disabled">
              <span>Confirm</span> <i class="material-icons">&#xE5C8;</i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
