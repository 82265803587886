<h2>Company Details</h2>
<div class="container panel">
  <ng-container *ngFor="let detail of details">
    <div *ngIf="detail.id !== 'address'" class="row">
      <div class="col-3 col">
        {{detail.label}}
      </div>
      <div *ngIf="detail.id !== 'address'" class="col-6 col inline-hover" inlineEdit
           [inlineElementId]="detail.id"
           [inlineObjectSignature]="detail.objectSignature"
           [inlineObjectId]="clientId">
        <b>{{cp.getValueFromProperty(detail.id)}}</b>
      </div>
    </div>
    <ng-container *ngIf="detail.id === 'address'">
      <div *ngFor="let addressDetail of detail.elements" class="row">
        <div class="col-3 col">
          {{addressDetail.label}}
        </div>
        <div class="col-6 col inline-hover" inlineEdit
             [inlineElementId]="addressDetail.id"
             [inlineObjectSignature]="addressDetail.objectSignature"
             [inlineObjectId]="clientId">
          <b>{{cp.getValueFromProperty(addressDetail.id)}}</b>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
