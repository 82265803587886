<ng-container *ngIf="(yotiSession && !processing) else loader">
  <div *ngIf="!!yotiSession?.state || identityVerificationOnly" class="row">
    <div class="col-12 heading-wrapper">
      <h3>Verify Your Identity</h3>
    </div>
  </div>

  <ng-container *ngIf="yotiSession?.state === 'EXPIRED' || yotiSession?.state === 'FAILED'" class="row">
    <div class="row message error">
      <div class="col-12">
        There was an issue completing this step. Please start again.
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button [disabled]="startingAgain" type="button" class="btn xavier-button weight-normal passed"
                id="startAgain" (click)="startAgain()">
          <span class="start-again-label">Start Again<i class="material-icons right-arrow">&#xE5CC;</i></span>
        </button>
      </div>
    </div>
  </ng-container>

  <div class="mb-4" *ngIf="!yotiSession?.state && yotiSession?.sessionId && yotiSession?.clientSessionToken">
    <xavier-yoti-identity-download></xavier-yoti-identity-download>
  </div>

  <div class="row yoti-button-row" *ngIf="!yotiSession?.state && yotiSession?.sessionId && yotiSession?.clientSessionToken">
    <div class="col-12">
      <xavier-yoti-button [buttonText]="buttonText" (buttonClick)="showModal = true"></xavier-yoti-button>
    </div>
  </div>

  <div *ngIf="showModal" class="full-screen">
    <iframe title="Verify Your Identity" [src]="url | safe: 'resourceUrl'" [attr.src] = "" allow="camera"></iframe>
    <span class="material-icons back-to-menu" (click)="onBackToMenuClick()">close</span>
  </div>
</ng-container>

<ng-template #loader>
  <div class="spinner-container not-button"><div class="spinner-wrap"></div></div>
</ng-template>
