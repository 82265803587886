import {Component, Input, OnInit} from '@angular/core';
import {EditClientService} from "../../services/edit-client.service";
import {ClientData, Signatories} from "../../model/client-data.model";
import {addToaster, getStatusIconValues} from "../../../utils/functions.utils";
import {FOR_REVIEW, IN_PROGRESS, PASSED, REJECTED} from "../../../../data/variables.data";
import {CompanyService} from "../../services/company.service";
import {ToastyService} from "ng2-toasty";
import {fadeIn} from "../../../../data/animations.data";

@Component({
  moduleId: module.id,
  selector: 'xavier-check',
  templateUrl: 'check.component.html',
  styleUrls: ['check.stylesheet.sass'],
  animations: [fadeIn]
})

export class CheckComponent implements OnInit {

  @Input() public params;
  @Input() public icon;

  public startDisplay = true;
  public controlsDisplay = false;
  public editDisplay = false;

  constructor(private companyService: CompanyService, private editClientService: EditClientService,
              private toastyService: ToastyService) {

  }

  ngOnInit() {
    this.icon = getStatusIconValues(this.params.status);
    if (this.params.status === IN_PROGRESS) {
      this.startDisplay = false;
      this.controlsDisplay = true;
      this.editDisplay = false;
    } else if (this.params.status === PASSED || this.params.status === REJECTED) {
      this.startDisplay = false;
      this.controlsDisplay = false;
      this.editDisplay = true;
    } else if (this.params.status !== FOR_REVIEW) {
      this.startDisplay = false;
      this.controlsDisplay = false;
      this.editDisplay = false;
    }
  }

  startValidation() {
    this.startDisplay = !this.startDisplay;
    let signatoryObject = new Signatories(this.params.propertySignature, IN_PROGRESS);
    this.editClientService.updateSignatoryData(this.params.signatoryId, this.params.propertySignature, signatoryObject).toPromise().then(response => {
      this.companyService.sendEventLogEntry(response);
      if (this.editClientService.getOverallStatus() === FOR_REVIEW) {
        this.updateOverallStatus(IN_PROGRESS).toPromise().then(() => {
          this.editClientService.setOverallStatus(IN_PROGRESS);
        }).catch(err => {
          let action = 'serverError';
          if (err.status === 403)
            action = '403';
          addToaster('error', this.toastyService, action, 'errors');
        });
      }
      this.editClientService.updateClientBinding({
        signature: this.params.propertySignature,
        value: IN_PROGRESS,
        signatoryArrayIndex: this.params.signatoryArrayIndex
      });
      this.icon = getStatusIconValues(IN_PROGRESS);
      this.controlsDisplay = true;
    }).catch(err => {
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
    });
  }

  validateAction(action: string) {
    this.controlsDisplay = !this.controlsDisplay;
    let signatoryObject = new Signatories(this.params.propertySignature, action.toUpperCase());
    this.editClientService.updateSignatoryData(this.params.signatoryId, this.params.propertySignature, signatoryObject).toPromise().then(response => {
      this.companyService.sendEventLogEntry(response);
      this.editClientService.updateClientBinding({
        signature: this.params.propertySignature,
        value: action.toUpperCase(),
        signatoryArrayIndex: this.params.signatoryArrayIndex
      });
      this.icon = getStatusIconValues(action.toUpperCase());
      this.editDisplay = true;
    }).catch(err => {
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
    });
  }

  editValidation() {
    this.editDisplay = !this.editDisplay;
    let signatoryObject = new Signatories(this.params.propertySignature, IN_PROGRESS);
    this.editClientService.updateSignatoryData(this.params.signatoryId, this.params.propertySignature, signatoryObject).toPromise().then(response => {
      this.companyService.sendEventLogEntry(response);
      this.editClientService.updateClientBinding({
        signature: this.params.propertySignature,
        value: IN_PROGRESS,
        signatoryArrayIndex: this.params.signatoryArrayIndex
      });
      this.icon = getStatusIconValues(IN_PROGRESS);
      this.controlsDisplay = true;
    }).catch(err => {
      let action = 'serverError';
      if (err.status === 403)
        action = '403';
      addToaster('error', this.toastyService, action, 'errors');
    });
  }

  updateOverallStatus(value: string) {
    let clientDataObject = new ClientData('clientStatus', value);
    this.editClientService.updateClientBinding({ signature: 'clientStatus', value: value });
    return this.editClientService.updateClientData(this.params.clientId, 'clientStatus', clientDataObject);
  }

}
