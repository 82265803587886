import {FormArray, FormControl, FormGroup, ValidationErrors} from "@angular/forms";
import {
  clientsDashboardFilters, documentTypes, entities, errorsToasterOptions, eventLogActivityTypes,
  genericToasterOptions, monthNames, noticeToasterOptions, retentionLengths, securityQuestions, toasterMessages, summaryEmailDuration, saasClient
} from '../../data/variables.data';
import {ToastyService} from "ng2-toasty";
import * as _ from 'lodash';
import { format, parseISO } from "date-fns";


export function getEntityLabel(value: string): string {
  let entity = value;
  _.forEach(entities, ( function(ent) {
    if (ent.id === value) {
      entity = ent.label;
    }
  }).bind(this) );
  return entity;
}

export function getRetentionLengthLabel(value: string): string {
  let retentionLength = value;
  _.forEach(retentionLengths, ( function(ent) {
    if (ent.id === value) {
      retentionLength = ent.label;
    }
  }).bind(this) );
  return retentionLength;
}

export function getWeeklySummaryLabel(value: string): string {
  let summary = summaryEmailDuration[0].label;
  _.forEach(summaryEmailDuration, ( function(ent) {
    if (ent.id === value) {
      summary = ent.label;
    }
  }).bind(this) );
  return summary;
}

export function getSaasClientLabel(value: string): string {
  let isSaasClient = saasClient[0].label;
  _.forEach(saasClient, ( function(ent) {
    if (ent.id === value) {
      isSaasClient = ent.label;
    }
  }).bind(this) );
  return isSaasClient;
}

export function getDocumentLabel(value: string): string {
  let document = value;
  _.forEach(documentTypes, ( function(doc) {
    if (doc.id === value) {
      document = doc.label;
    }
  }).bind(this) );
  return document;
}

export function getSecurityQuestionLabel(value: string): string {
  let question = value;
  _.forEach(securityQuestions, ( function(quest) {
    if (quest.id === value) {
      question = quest.label;
    }
  }).bind(this) );
  return question;
}

export function getEventLogActivityTypeLabel(value: string): string {
  let activityType = value;
  _.forEach(eventLogActivityTypes, ( function(activity) {
    if (activity.id === value) {
      activityType = activity.label;
    }
  }).bind(this) );
  return activityType;
}

export function getToasterMessagesLabel(value: string, type: string): string {
  let message = value;
  _.forEach(toasterMessages[type], ( function(msg) {
    if (msg.id === value) {
      message = msg.label;
    }
  }).bind(this) );
  return message;
}

export function addToaster(type: string, toastyService: ToastyService, action: string, actionType: string) {
  let config;
  switch (type) {
    case 'error':
      config = errorsToasterOptions;
      config.msg = getToasterMessagesLabel(action, actionType);
      toastyService.error(config);
      break;
    case 'notice':
      config = noticeToasterOptions;
      config.msg = getToasterMessagesLabel(action, actionType);
      toastyService.error(config);
      break;
    default:
      config = genericToasterOptions;
      config.msg = getToasterMessagesLabel(action, actionType);
      toastyService.info(config);
      break;
  }
}

export function addToasterWithMessage(type: string, toastyService: ToastyService, message) {
  let config;
  switch (type) {
    case 'error':
      config = errorsToasterOptions;
      config.msg = message;
      toastyService.error(config);
      break;
    case 'notice':
      config = noticeToasterOptions;
      config.msg = message;
      toastyService.error(config);
      break;
    default:
      config = genericToasterOptions;
      config.msg = message;
      toastyService.info(config);
      break;
  }
}

export function formatDate(value: string) {
  let date = new Date(value);
  let day = date.getDate();
  let monthIndex = date.getMonth();
  let year = date.getFullYear();
  return day+" "+monthNames[monthIndex]+" "+year;
}

const zeroPad = (num, places) => String(num).padStart(places, '0');

export function formatDateTime(value: string) {
  const date = new Date(value);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${day} ${monthNames[monthIndex]} ${year}, ${zeroPad(hours, 2)}:${zeroPad(minutes, 2)}`;
}

export function parseDate(jsdate: string) {
  let postCodeRegExp = new RegExp(/(\d{4})-(\d{2})-(\d{2})/);
  return postCodeRegExp.test(jsdate);
}

export function formatDueDate(dateString: string) {
  const date = parseISO(dateString);

  // Format the day of the week and time
  const formattedDate = format(date, 'EEEE ha');

  // Calculate the working hours left
  const now = new Date();
  const workingHoursLeft = calculateWorkingHoursLeft(now, date);
  let indicator = 'ellipse-success';

  if (workingHoursLeft <= 2) {
    indicator = "ellipse-danger";
  } else if (workingHoursLeft >= 4) {
    indicator = "ellipse-success";
  } else {
    indicator = "ellipse-warning";
  }

  return {formattedDate, workingHoursLeft, indicator};
}

const calculateWorkingHoursLeft = (now: Date, dueDate: Date): number => {
  if (dueDate <= now) return 0;

  now.setHours(0, 0, 0, 0);
  let numOfDays = 0

  while (now <= dueDate) {
    // Weekdays only
    if (now.getDay() !== 0 && now.getDay() !== 6)
      numOfDays++;
    now.setDate(now.getDate() + 1);
  }

  const startHour = 9;
  const endHour = 17;
  const currentHour = Math.min(Math.max(startHour, new Date().getHours()), endHour);
  const dueDateHour = Math.min(Math.max(startHour, dueDate.getHours()), endHour);

  if (numOfDays > 1) {
    return endHour - currentHour + ((numOfDays - 2) * 8) + dueDateHour - startHour;
  }
  return dueDateHour - currentHour;
};

export function getStatusIconValues(status: string) {
  let values = {
    statusValue: '',
    statusType: '',
    statusColor: '',
    statusBorder: '',
    statusFill: '',
    statusSize: ''
  };
  _.forEach(clientsDashboardFilters, function(filter) {
    if (filter.name === status) {
      values.statusValue = filter.value;
      values.statusType = filter.icon;
      values.statusColor = filter.color;
      values.statusBorder = filter.border;
      values.statusFill = filter.fill;
      values.statusSize = filter.size;
    }
  });
  return values;
}

export function getFormGroupValidationErrors(group: FormGroup) {
  let errors = [];
  Object.keys(group.controls).forEach(key => {
    const controlErrors: ValidationErrors = group.get(key).errors;
    if (controlErrors != null) {
      Object.keys(controlErrors).forEach(keyError => {
        errors.push({ key: keyError });
      });
    }
  });
  return errors;
}

export function getFormGroupControlsWithErrors(group: FormGroup) {
  const controlsWithErrors: {controlName: string, error: string}[] = [];
  Object.keys(group.controls).forEach(key => {
    const controlErrors: ValidationErrors = group.get(key).errors;
    if (controlErrors != null) {
      Object.keys(controlErrors).forEach(keyError => {
        controlsWithErrors.push({ controlName: key, error: keyError });
      });
    }
  });
  return controlsWithErrors;
}

export function setAsTouched(group: FormGroup | FormArray) {
  group.markAsTouched();
  for (let i in group.controls) {
    if (group.controls[i] instanceof FormControl) {
      group.controls[i].markAsTouched();
    } else {
      setAsTouched(group.controls[i]);
    }
  }
}

export function isVerified(value: string, verifiedValue: string): boolean {
  if (!value || !verifiedValue)
    return value === verifiedValue;

  if (typeof value === 'string' && typeof verifiedValue === 'string')
    return value.toLowerCase() === verifiedValue.toLowerCase();

  const date = value['date'];
  if (date) {
    return `${date.year}-${zeroPad(date.month, 2)}-${zeroPad(date.day, 2)}` === verifiedValue;
  }

  return value === verifiedValue;
}
