<p *ngIf="!isBeenEdited" (click)="enableEditing()" class="m-0 h6 font-weight-bold">
  <span *ngIf="!isDateField()">{{ control.value ? control.value : 'N/A' }}</span>
  <span *ngIf="isDateField()">{{ control.value && isDateField(control.value) ? (control.value | date: 'd MMMM yyyy') : 'N/A' }}</span>
</p>

<ng-container *ngIf="isBeenEdited">
  <div class="form-group xavier-input-text">
    <input [placeholder]="isDateField() ? 'yyyy-mm-dd' : ''" type="text" class="form-control" [formControl]="control" (blur)="disableEditing()">
    <xavier-error-message [control]="control"></xavier-error-message>
  </div>
</ng-container>
