<ng2-toasty [position]="'top-center'" class="xavier-toasty"></ng2-toasty>
<div class="xavier-login-wrapper container">
  <form [formGroup]="loginData" novalidate (ngSubmit)="onSubmit(loginData)">
    <div *ngIf="login" class="row" [@fadeIn]>
      <div class="col-12">
        <div class="header">
          Login
        </div>
      </div>
      <div class="form-group col-12 xavier-input-text">
        <label for="email">Email Address</label>
        <input type="email" class="form-control" id="email" formControlName="email" placeholder="Email Address" validateOnBlur
               [validateFormControl]="{ controls: [loginData.get('email'), loginData.get('password')] }"
               tabindex="1">
        <xavier-error-message [control]="loginData.get('email')"></xavier-error-message>
      </div>
      <div class="form-group col-12 xavier-input-text">
        <div class="forgot-link"><a href="/forgot" tabindex="3">Forgot Password?</a></div>
        <label for="password">Password</label>
        <input type="password" class="form-control" id="password" formControlName="password" placeholder="Password" validateOnBlur
               [validateFormControl]="{ controls: [loginData.get('email'), loginData.get('password')] }"
               tabindex="2">
        <xavier-error-message [control]="loginData.get('password')"></xavier-error-message>
      </div>
      <div class="col-12 submit-col">
        <button type="submit" class="btn btn-block xavier-button continue-step" id="submitButton" [disabled]="disabled" tabindex="4">
          <span>Login</span>
        </button>
      </div>
      <!--div class="col-12 or-col">
        <span>or</span>
      </div>
      <div class="col-12 google-col">
        <button class="btn btn-block xavier-button google" (click)="googleLogIn($event)" tabindex="5">
          <span><img src="../../../../assets/img/g-logo.png">Login with Google</span>
        </button>
      </div-->
      <div class="col-12 register-col">
        <a href="/registration/1" tabindex="6">Don't have an account? Create Account here.</a>
      </div>
    </div>
  </form>
</div>
