<h2>Divisions</h2>
<div class="xavier-table-wrapper">
  <form>
    <div class="row">
      <div class="form-group col-sm-12 col-xl-6 xavier-input-text">
        <div class="input-group">
          <span class="input-group-addon" id="search-addon-divisions"><i class="material-icons">&#xE8B6;</i></span>
          <input type="text"
                 #divisionSearch
                 class="form-control"
                 id="divisionSearchInput"
                 placeholder="Search divisions..."
                 aria-describedby="search-addon"
                 (keyup)="divisionTable.gtSearch(divisionSearch.value)">
        </div>
        <i *ngIf="divisionSearch.value !== ''" class="material-icons search-reset" (click)="resetDivisionSearch(divisionSearch)">&#xE5C9;</i>
      </div>
      <div class="col-sm-12 col-xl-6 text-right" *isAllowed="['DIVISION_NEW_ALL', 'DIVISION_NEW_COMPANY']">
        <button type="button" class="btn xavier-button continue-step weight-normal" (click)="openDivisionModal('add')">
          <i class="material-icons addIcon">&#xE145;</i> <span>Add New Division</span>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <generic-table #divisionTable
                       [gtClasses]="'table-sm table-hover'"
                       [gtSettings]="divisionTableConfigObject.settings"
                       [gtFields]="divisionTableConfigObject.fields"
                       [gtOptions]="{stack: true, rowSelection: true}"
                       [(gtData)]="divisionTableConfigObject.data"
                       (gtEvent)="trigger($event)">
        </generic-table>
      </div>
      <div class="col-6">
        <gt-pagination [gtClasses]="'pagination-sm'" [genericTable]="divisionTable"></gt-pagination>
      </div>
      <div class="col-6">
        <small><gt-table-info class="form-text text-muted mb-2 text-right" [genericTable]="divisionTable"></gt-table-info></small>
      </div>
    </div>
  </form>
</div>

<!-- Division Modal -->
<div *ngIf="showDivisionModal" class="xavier-modal-backdrop"></div>
<div *ngIf="showDivisionModal" class="xavier-modal" [@fadeIn]>
  <div class="modal-content container">
    <div class="modal-header">
      <h1 class="modal-title pull-left">{{divisionModalTitlePrefix}} Division</h1>
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeDivisionModal()">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="divisionModalData" novalidate (ngSubmit)="onSubmitDivision(divisionModalData)">
        <div class="row">
          <div class="col-sm-12 col-xl-3">
            <h4>Division Details</h4>
          </div>
          <div class="col-sm-12 col-xl-4">
            <div class="form-group xavier-input-text">
              <label for="divisionName">Division Name</label>
              <input type="text" class="form-control" id="divisionName" formControlName="divisionName" placeholder="Division Name">
              <xavier-error-message [control]="divisionModalData.get('divisionName')"></xavier-error-message>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-xl-3">
            <h4>Default Personnel Assignments</h4>
          </div>
          <div class="col-sm-12 col-xl-4">
            <div class="form-group xavier-input-text">
              <label for="defaultLineManager">Default Line Manager</label>
              <xavier-autocomplete-select-dropdown (dropdownEntrySelectedEmitter)="onDropdownEntrySelected('lineManager', $event)"
                                                   [componentParams]="defaultLineManagerSearchParams">
              </xavier-autocomplete-select-dropdown>
            </div>
          </div>
          <div class="col-sm-12 col-xl-4">
            <div class="form-group xavier-input-text">
              <label for="defaultHumanResources">Default Human Resources</label>
              <xavier-autocomplete-select-dropdown (dropdownEntrySelectedEmitter)="onDropdownEntrySelected('humanResources', $event)"
                                                   [componentParams]="defaultHumanResourcesSearchParams">
              </xavier-autocomplete-select-dropdown>
            </div>
          </div>
        </div>
        <div class="row xavier-add-division">
          <div class="col-sm-12 col-xl-3"></div>
          <div class="col-sm-12 col-xl-7">
            <button type="submit" class="btn xavier-button continue-step" id="submitButton" [disabled]="disabled">
              <span>{{divisionModalSubmitButton}}</span>
            </button>
            <button type="button" class="btn xavier-button-plain" (click)="closeDivisionModal()">
              <span>Cancel</span>
            </button>
          </div>
          <div *ngIf="divisionId !== 0" class="col-sm-12 col-xl-2 text-right">
            <button type="button" class="btn xavier-button-plain white" (click)="openDeleteDivisionModal(deleteModal)" *isAllowed="['DIVISION_DISABLE_ALL', 'DIVISION_DISABLE_COMPANY']">
              <span>Delete</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Delete Division Modal -->
<ng-template #deleteModal>
  <div class="modal-header">
    <h2 class="modal-title pull-left">Are you sure you want to disable this division?</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div class="modal-body">
    <small>All places where this division have been used will be replaced with the default division "All divisions".</small>
    <div class="modal-button-group">
      <button type="button" class="btn xavier-button reject weight-normal" (click)="deleteDivision()" id="deleteButton" [disabled]="disabled">
        Delete Division
      </button>
      <button type="button" class="btn xavier-button-plain" (click)="modalRef.hide()">
        <span>Close</span>
      </button>
    </div>
  </div>
</ng-template>
