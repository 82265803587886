import {Component, forwardRef, Input, OnInit} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR, Validators} from "@angular/forms";
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest, HttpResponse} from "@angular/common/http";
import {Attribute, ATTRIBUTE_TYPES_MAP} from "../attribute.class";
import {DynamicFormService} from "../../../../services/dynamic-form.service";
import {environment} from "../../../../../../environments/environment";
import {FILEUPLOAD_TYPE} from "../../../../../../data/variables.data";
import {isNullOrUndefined} from "util";
import { EventUtils } from '../../../../../utils/event.utils';

@Component({
  moduleId: module.id,
  selector: 'xavier-fileupload',
  templateUrl: 'fileupload.component.html',
  styleUrls: ['fileupload.stylesheet.sass'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FileuploadAttribute), multi: true }]
})

export class FileuploadAttribute extends Attribute implements OnInit, ControlValueAccessor {
  @Input() public errorClass: string;
  @Input() public fileName: string;
  @Input() public progressVisible: boolean;
  @Input() public progress: number = 0;

  public supportedFileTypes: string[] = ['image/png', 'image/jpeg', 'application/pdf'];

  // ControlValueAccess implementation
  value: any;
  onChange = () => {};
  onTouched = () => {};

  writeValue(value) {
    if (!isNullOrUndefined(value))
      this.value = value;
  }

  getValue() { return this.value; }
  registerOnChange(fn: any) { this.onChange = fn; }
  registerOnTouched(fn: any) { this.onTouched = fn; }
  // End of ControlValueAccess implementation

  constructor(public dynamicFormService: DynamicFormService, public http: HttpClient) {
    super(dynamicFormService, http);
  }

  ngOnInit() {
    const formGroup = this.dynamicFormService.getFormGroup(this.formGroupId);
    const formControl = this.dynamicFormService.attributeToFormControl(this.data);

    // validators are set on the controls below
    formControl.clearValidators();
    formControl.updateValueAndValidity();

    this.filenameFormControl = this.dynamicFormService.attributeToFormControl(this.data);
    this.folderFormControl = this.dynamicFormService.attributeToFormControl(this.data);
    this.thumbnailFormControl = this.dynamicFormService.attributeToFormControl(this.data);

    formGroup.addControl(this.formGroupId + '-' + this.data.id, formControl);

    this.formGroup = formGroup;
    this.formControl = formControl;
  }

  deleteDocument(containerId: string) {
    let fileInput = <HTMLInputElement>document.getElementById('fileLoader'+containerId);
    fileInput.value = '';
    let previewContainer = document.getElementById('fileUploadedPreview'+containerId);
    previewContainer.innerHTML = '';
    this.fileName = '';
    this.progress = 0;
    this.progressVisible = false;
    this.value = null;
    this.formControl.setErrors({ invalid: true }, { emitEvent: true });
    this.formControl.markAsUntouched();
    this.filenameFormControl.setValue(null);
    this.folderFormControl.setValue(null);
    this.thumbnailFormControl.setValue(null);
    this.hidePanel('fileLoading', containerId);
    this.hidePanel('fileUploaded', containerId);
    this.showPanel('fileLoaderDrop', containerId);
  }

  fileChangeEvent(fileInput: Event, containerId: string) {
    if (this.supportedFileTypes.indexOf(fileInput.target['files'][0].type) >= 0)
      this.dragFileAccepted(fileInput.target['files'][0], containerId);
    else
      this.dragFileRejected(fileInput.target['files'][0], 'fileDropArea_' + containerId);
  }

  dragFileAccepted(acceptedFile: any, containerId: string) {
    this.hidePanel('fileLoaderDrop', containerId);
    this.showPanel('fileLoading', containerId);

    let file;

    if (acceptedFile.dataTransfer) {
      file = EventUtils.getFile(acceptedFile);

      if (!this.supportedFileTypes.includes(file.type)) {
        this.dragFileOverEnd('fileDropArea_'+containerId);
        this.hidePanel('fileLoading', containerId);
        this.showPanel('fileLoaderDrop', containerId);
        return;
      }
    } else {
    if (!isNullOrUndefined(acceptedFile.file))
      file = acceptedFile.file;
    else
      file = acceptedFile;
    }

    this.fileName = file.name;
    let headers = new HttpHeaders({ 'Content-Type': file.type });
    const req = new HttpRequest(
      'POST',
      'https://'+environment.storageBucket+'/uploadFile/?filename=' + encodeURIComponent(file.name),
      file,
      {
        headers: headers,
        reportProgress: true,
        withCredentials: true
      }
    );

    this.http.request(req).subscribe(event => {
      this.progressVisible = true;
      if (event.type === HttpEventType.UploadProgress) {
        this.progress = Math.round(100 * event.loaded / event.total);
      } else if (event instanceof HttpResponse) {
        this.filenameFormControl.setValue(event.body['name']);
        this.folderFormControl.setValue(event.body['folder']);
        let value = {
          name: event.body['name'],
          folder: event.body['folder']
        };
        if (event.body['thumbnail']) {
          this.thumbnailFormControl.setValue(event.body['thumbnail']);
          value['thumbnail'] = event.body['thumbnail'];
        }

        this.writeValue(value);
        setTimeout(() => {
          // this is a necessary HACK to make the file input form element to work with Safari, IE/Edge and other mobile browsers
          // Angular doesn't handle the input type='file' properly as a ReactiveForm element so a ControlValueAccessor is necessary
          // but the implementation is not flawless and it's proved here where the FormControl value is empty and the control
          // is always set to INVALID
          this.formControl.setErrors(null, {emitEvent: false});
          this.formControl.markAsTouched();
        }, 0);

        let previewFileName;
        if (event.body.hasOwnProperty('thumbnail')) {
          previewFileName = 'https://'+environment.storageBucket+'/gcs/' + event.body['folder'] + '/' +
            event.body['thumbnail'].replace('@xN', '@x3');
        } else {
          previewFileName = 'https://' + environment.storageBucket + '/gcs/' + event.body['folder'] + '/' + event.body['name'];
        }

        let obj = document.createElement('object');
        obj.setAttribute("data", previewFileName);
        obj.setAttribute("type", file.type);
        obj.setAttribute("width", "80");
        obj.setAttribute("height", "80");

        let emb = document.createElement('embed');
        emb.setAttribute("src", previewFileName);
        emb.setAttribute("type", file.type);
        emb.setAttribute("width", "80");
        emb.setAttribute("height", "80");

        obj.appendChild(emb);

        let previewContainer = document.getElementById('fileUploadedPreview' + containerId);
        previewContainer.appendChild(obj);

        // hide the Drop Zone and show the Delete tab of the document types the user is not using at the moment
        this.hidePanel('fileLoaderDrop', containerId);
        let dropAreaContainer = document.getElementById('fileDropArea_' + containerId);
        dropAreaContainer.classList.remove('uploadBoxError');

        this.hidePanel('fileLoading', containerId);
        this.showPanel('fileUploaded', containerId);
      }
    });
  }

  dragFileRejected(rejectedFile: any, id) {
    let dropAreaContainer = document.getElementById(id);
    dropAreaContainer.classList.add('rejectedFile');
    setTimeout(() => {
      dropAreaContainer.classList.remove('rejectedFile');
    }, 500);
  }

  dragFileOverStart(id) {
    let dropAreaContainer = document.getElementById(id);
    dropAreaContainer.classList.add('dragOver');
  }

  dragFileOverEnd(id) {
    let dropAreaContainer = document.getElementById(id);
    dropAreaContainer.classList.remove('dragOver');
  }

  openDialog(id) {
    let fileUploader = document.getElementById(id);
    fileUploader.click();
  }

  private hidePanel(prefix: string, id: string) {
    let panel = document.getElementById(prefix.concat(id));
    if (!isNullOrUndefined(panel))
      panel.classList.add('visibility-none');
  }

  private showPanel(prefix: string, id: string) {
    let panel = document.getElementById(prefix.concat(id));
    if (!isNullOrUndefined(panel))
      panel.classList.remove('visibility-none');
  }

  public setThumbnail(file: any) {
    this.fileName = file.name;

    let previewFileName;
    if (file.hasOwnProperty('thumbnail')) {
      previewFileName = 'https://' + environment.storageBucket + '/gcs/' + file.folder + '/' +
        file.thumbnail.replace('@xN', '@x3');
    } else {
      previewFileName = 'https://' + environment.storageBucket + '/gcs/' + file.folder + '/' + file.name;
    }

    let obj = document.createElement('object');
    obj.setAttribute("data", previewFileName);
    obj.setAttribute("type", 'image/'+file.name.split('.').pop());
    obj.setAttribute("width", "80");
    obj.setAttribute("height", "80");

    let emb = document.createElement('embed');
    emb.setAttribute("src", previewFileName);
    emb.setAttribute("type", 'image/'+file.name.split('.').pop());
    emb.setAttribute("width", "80");
    emb.setAttribute("height", "80");

    obj.appendChild(emb);

    let previewContainer = document.getElementById('fileUploadedPreview' + this.formGroupId + '-' + this.data.id);
    if (!isNullOrUndefined(previewContainer))
      previewContainer.appendChild(obj);

    // hide the Drop Zone and show the Delete tab of the document types the user is not using at the moment
    this.hidePanel('fileLoaderDrop', this.formGroupId + '-' + this.data.id);
    let dropAreaContainer = document.getElementById('fileDropArea_' + this.formGroupId + '-' + this.data.id);
    if (!isNullOrUndefined(dropAreaContainer))
      dropAreaContainer.classList.remove('uploadBoxError');

    this.hidePanel('fileLoading', this.formGroupId + '-' + this.data.id);
    this.showPanel('fileUploaded', this.formGroupId + '-' + this.data.id);
  }

  public removeThumbnail() {
    // remove preview
    const previewContainer = document.getElementById('fileUploadedPreview' + this.formGroupId + '-' + this.data.id);
    if (!isNullOrUndefined(previewContainer) && previewContainer.hasChildNodes()) {
      previewContainer.removeChild(previewContainer.children[0]);
    }

    // show the Drop Zone
    this.showPanel('fileLoaderDrop', this.formGroupId + '-' + this.data.id);
    const dropAreaContainer = document.getElementById('fileDropArea_' + this.formGroupId + '-' + this.data.id);
    if (!isNullOrUndefined(dropAreaContainer))
      dropAreaContainer.classList.remove('uploadBoxError');
  }

}

ATTRIBUTE_TYPES_MAP.set(FILEUPLOAD_TYPE, FileuploadAttribute);
