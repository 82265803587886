import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {WindowRefService} from '../../../../../../shared-services/window-ref.service';
import {YotiDigitalIdService} from '../../../../../services/yoti-digital-id.service';
import {Observable} from 'rxjs/Observable';
import {YotiShareUrlDTO} from '../../../../../model/yoti/digital-id/yoti-share-url.dto';
import {UserAuth} from '../../../../../../../core/userAuth.core';
import {environment} from '../../../../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {fromEvent} from 'rxjs/observable/fromEvent';
import {first, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/Subject';
import {DynamicFormService} from '../../../../../services/dynamic-form.service';

// time to wait till start listening to yoti iframe messages (initial messages when iframe is being initialised must be ignored, as they would wrongly try to complete session)
const LISTEN_TO_YOTI_IFRAME_MESSAGES_TIMEOUT = 100;

@Component({
  moduleId: module.id,
  selector: 'xavier-yoti-digital-id',
  templateUrl: 'yoti-digital-id.component.html',
  styleUrls: ['yoti-digital-id.stylesheet.sass']
})
export class YotiDigitalIdComponent implements OnInit, OnDestroy {
  @Input()
  public buttonText: string;
  @Output()
  public backToMenu: EventEmitter<boolean> = new EventEmitter();

  public yotiButton: any;
  public contextReady = false;
  public processing = false;

  private nativeWindow: any;
  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userAuth: UserAuth,
    private windowRefService: WindowRefService,
    private yotiDigitalIdService: YotiDigitalIdService,
    private dynamicFormService: DynamicFormService,
  ) {
    this.nativeWindow = windowRefService.nativeWindow;
  }

  ngOnInit(): void {
    this.processing = true;
    this.yotiShareUrl$.pipe(first()).subscribe(dto => {
      this.processing = false;
      return this.reload(dto)
    });
    this.startSubscribingToYotiIframeMessages();
  }

  public get yotiShareUrl$(): Observable<YotiShareUrlDTO> {
    return this.yotiDigitalIdService.createShareUrl$(this.userAuth.getUser().id, true);
  }

  // To destroy the Yoti-Button object, before re-initializing it, eg: on Router Path Changes.
  public ngOnDestroy(): void {
    this.yotiButton.destroy();
    this.destroy$.next();
  }

  public reload(dto: YotiShareUrlDTO) {
    // destroy before reinitialising
    if (this.yotiButton) {
      this.yotiButton.destroy();
    }

    // initialise
    if (dto && dto.url) {
      // Append query params to the landing page URL that displays the Yoti button.
      // These will be added by Yoti to the callback URL.
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: {candidateId: dto.entityId},
          queryParamsHandling: 'merge',
        }).then(() => {
        const idCheckType = this.dynamicFormService.dynamicForm.idCheckType;
        if (idCheckType && idCheckType !== 'NONE') {
          let element_data: any = {
            domId: "yoti-button",
            clientSdkId: environment.yotiDigitalIdClientSdkId,
            displayLearnMoreLink: true,
            button: {
              label: this.buttonText,
              align: "center", // "left" | "right"
              width: "auto", // "full"
            },
            modal: { zIndex: 9999 },
            skinId: "digital-id-uk",
          };

          element_data =
            idCheckType === "DBS_LEVEL"
              ? { ...element_data, shareUrl: dto.url }
              : { ...element_data, scenarioId: environment.yotiScenarioIds[idCheckType], };
          this.yotiButton = this.nativeWindow.Yoti.Share.init({
            elements: [element_data]
          });
        }
      });
    }
  }

  private startSubscribingToYotiIframeMessages() {
    window.setTimeout(() => {
      fromEvent<MessageEvent>(window, 'message').pipe(
        takeUntil(this.destroy$)
      ).subscribe(event => {
        this.processYotiMessage(event);
      });
    }, LISTEN_TO_YOTI_IFRAME_MESSAGES_TIMEOUT);
  }

  public processYotiMessage(event: MessageEvent): void {
    if (event && event.data && event.data.payload) {
      console.log('Received iFrame message: ' + event.data.payload.name);
      if (event.data.payload.name === 'close-iframe') {
        this.onBackToMenuClick();
      }
      if (event.data.payload.name === 'context') {
        // timeout as it's pretty slow until Yoti shows button, so better to show loader a bit longer
        window.setTimeout(() => {
          this.contextReady = true;
        }, 4000);
      }
    }
  }

  onBackToMenuClick() {
    this.contextReady = false;
    this.dynamicFormService.identityVerificationBackToMenu.next();
    this.backToMenu.next(true);
    this.yotiDigitalIdService.forceReload();
  }
}
