import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Rx';
import { Subject } from "rxjs/Subject";
import { HttpService } from "../../shared-services/http.service";
import { AdminUser } from '../model/adminuser.model';

@Injectable()
export class FormService {

  public sendLastStepRegistrationMsg = new Subject<boolean>();
  lastStepRegistrationMsg$ = this.sendLastStepRegistrationMsg.asObservable();

  private sendStatusMsg = new Subject<string>();
  sendStatusMsg$ = this.sendStatusMsg.asObservable();

  private adminUser: AdminUser = new AdminUser;
  private completedStep: number = 0;
  private resubmit: boolean = false;
  private resubmitStatusMsg: string;

  constructor(private httpService: HttpService, private http: HttpClient) {
  }

  getAll() : AdminUser {
    return this.adminUser;
  }

  setAdminUser(adminUser: AdminUser) {
    this.adminUser = adminUser;
  }

  getClientDataByToken(token: string) {
    return this.httpService.get('/_ah/api/company/v1', 'getClientDataByToken', [{ token: token }]);
  }

  resubmitRegistrationData(token: string) {
    return this.httpService.post('/_ah/api/registration/v1', 'reSubmit/'+token, this.serializeData(), null, false);
  }

  public postcodeLookup(search: string) {
    return this.httpService.get('/_ah/api/address/v1', 'getAddress/' + encodeURIComponent(search), null);
  }

  public emailExists(email: string, currentUserId?: string) {
    return this.httpService.get('/_ah/api/user/v1', 'emailExists/' + encodeURIComponent(email), null);
  }

  public getCompletedStep() {
    return this.completedStep;
  }

  public setCompletedStep(step: number) {
    this.completedStep = step;
  }

  public getResubmit() {
    return this.resubmit;
  }

  public setResubmit(value: boolean) {
    this.resubmit = value;
  }

  public setResubmitStatusMsg(msg: string) {
    this.resubmitStatusMsg = msg;
  }

  public convertClientData(clientData: any) {
    return new AdminUser(null, null, clientData);
  }

  public lastStepRegistration(end: boolean) {
    this.sendLastStepRegistrationMsg.next(end);
  }

  public stepsStatusMsg() {
    this.sendStatusMsg.next(this.resubmitStatusMsg);
  }

  public save(adminUser: AdminUser, step: number) {
    _.assign(this.adminUser, adminUser);
    this.completedStep = step;
  }

  public reset() {
    this.adminUser = new AdminUser;
  }

  serializeData() {
    let signatories = [];
    _.forEach(this.adminUser.signatories, function(signatory) {
      let temp = {
        "firstName": signatory.firstName,
        "lastName": signatory.lastName,
        "jobTitle": signatory.jobTitle,
        "address": {
          "addressLine1": signatory.addressLine1,
          "addressLine2": signatory.addressLine2,
          "postcode": signatory.postcode.toUpperCase(),
          "city": signatory.city,
          "county": signatory.county,
          "country": signatory.country
        },
        "idDocument": {
          "name": signatory.name,
          "folder": signatory.folder
        },
        "documentType": signatory.documentType
      };
      if (signatory.id)
        temp["id"] = signatory.id;
      signatories.push(temp);
    });

    let value = {
      "companyName": this.adminUser.companyName,
      "entityType": this.adminUser.entityType,
      "registrationNumber": this.adminUser.registrationNumber,
      "insuranceNumber": this.adminUser.insuranceNumber,
      "address": {
        "addressLine1": this.adminUser.address.addressLine1,
        "addressLine2": this.adminUser.address.addressLine2,
        "postcode": this.adminUser.address.postcode.toUpperCase(),
        "city": this.adminUser.address.city,
        "county": this.adminUser.address.county,
        "country": 'United Kingdom'
      },
      "vatInEU": this.adminUser.vatInEU,
      "vat": this.adminUser.vat,
      "mobilePhone": this.adminUser.mobilePhone,
      "officePhone": this.adminUser.officePhone,
      "officePhoneExt": this.adminUser.officePhoneExt,
      "signatories": signatories,
      "manager": {
        "firstName": this.adminUser.firstName,
        "lastName": this.adminUser.lastName,
        "email": this.adminUser.account.email,
      }
    };
    if (this.adminUser.id) {
      value["id"] = this.adminUser.id;
    } else {
      value["manager"]["password"] = this.adminUser.manager.password;
      value["manager"]["securityQuestion"] = this.adminUser.securityQuestion;
      value["manager"]["securityAnswer"] = this.adminUser.securityAnswer;
      value["manager"]["googleToken"] = this.adminUser.googleToken;
    }
    return JSON.stringify(value);
  }

  private extractData(res: Response) {
    let body = res.json();
    return body || { };
  }

  private handleError (error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else
      errMsg = error.message ? error.message : error.toString();
    return Observable.throw(errMsg);
  }

}
