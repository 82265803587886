<ng2-toasty [position]="'top-center'" class="xavier-toasty"></ng2-toasty>
<div class="xavier-preview-screening-wrapper container">
  <div *ngIf="loading" class="xavier-center-page" [@fadeIn]>
    <div class="spinner-container not-button"><div class="spinner-wrap"></div></div>
  </div>
  <div *ngIf="!loading" [@fadeIn]>
    <div class="row header">
      <div class="col-1 text-center">
        <button routerLink="/dashboard/screenings" type="button" class="btn xavier-button-plain white">
          <i class="material-icons back-link">&#xE5C4;</i>
        </button>
      </div>
      <div class="col-10">
        <h1>{{screeningName}}</h1>
        <small>{{companyName}}<ng-container *ngIf="divisionName !== ''">, {{divisionName}}</ng-container></small>
      </div>
      <div class="col-sm-2 col-xl-1 text-right editIcon" *isAllowed="['SCREENING_EDIT_ALL', 'SCREENING_EDIT_COMPANY']">
        <button type="button" class="btn btn-block xavier-button continue-step" id="editScreening" (click)="editScreening()">
          <i class="material-icons">&#xE3C9;</i>
        </button>
      </div>
    </div>
    <div class="row screening-status">
      <div class="col-2">
        <div>Price</div>
        <div class="info">{{price}}</div>
      </div>
      <div *ngIf="discountedPrice != null" class="col-2">
        <div>Discount Price</div>
        <div class="info">{{discountedPrice}}</div>
      </div>
      <div class="col-2" *isAllowed="['CHECK_COST']">
        <div>Cost</div>
        <div class="info">{{cost}}</div>
      </div>
      <div class="col-1">
        <div>SLA</div>
        <div class="info">{{slaTarget}}</div>
      </div>
      <div class="col-3">
        <div>Last Used</div>
        <div class="info date">{{lastUsed}}</div>
      </div>
      <div class="col-2">
        <div>Status</div>
        <div class="status-icon"><xavier-status-icon [row]="filter"></xavier-status-icon></div><div class="info status">{{statusValue}}</div>
      </div>
    </div>
    <xavier-dynamic-form [formModel]="dynamicForm" [preview]="true"></xavier-dynamic-form>
  </div>
</div>
