import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NavigationStart, Router} from "@angular/router";
import {UserAuth} from "../../../../core/userAuth.core";
import {UserService} from "../../services/user.service";
import {UserPermission} from "../../model/permissions.model";

@Component({
  moduleId: module.id,
  selector: 'xavier-navbar',
  templateUrl: 'navbar.component.html',
  styleUrls: ['navbar.stylesheet.sass']
})

export class NavbarComponent implements OnInit, OnDestroy {

  @Input() public displayName: string;
  @Input() public isReferenceForm: boolean = false;
  @Input() public isCandidateScreeningForm: boolean = false;

  public startRouteUrl: string;
  public myCompanyMenuItem: boolean = false;
  public companyId;
  public isCollapsed: boolean = true;
  public referenceFormCandidateName: string;
  public dbsCountersignatory: boolean = false;

  private candidateNameSub: any;
  private routeSub: any;

  constructor(private userAuth: UserAuth, private userService: UserService, private router: Router) {
    this.startRouteUrl = this.router.url;
    this.candidateNameSub = this.userAuth.referenceFormCandidateName$.subscribe(value => {
      this.referenceFormCandidateName = value;
    });
  }

  ngOnInit() {
    this.routeSub = this.router.events.subscribe((value) => {
      if (value instanceof NavigationStart)
        this.startRouteUrl = value.url;
    });
    let user = this.userAuth.getUser();
    if (user !== undefined) {
      this.displayName = user.firstName;
      this.dbsCountersignatory = user.dbsCountersignatory;

      // To set freshdesk's widget client name
      // (window as any).fcWidget.user.setFirstName(user.firstName);
      // To set freshdesk's widget client email
      // (window as any).fcWidget.user.setEmail(user.email);


      if (UserPermission.isAllowed(['MY_COMPANY_MENU'], user.permissionList)) {
        this.companyId = user.companyId;
        this.myCompanyMenuItem = true;
      }
    }
  }

  ngOnDestroy() {
    this.candidateNameSub.unsubscribe();
    this.routeSub.unsubscribe();
  }

  collapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  logout() {
    event.preventDefault();
    this.userService.logOut().subscribe(result => {
      this.userAuth.closeSession();
      this.router.navigate(['/login', { logout: 1 }]);
    });
  }
}
