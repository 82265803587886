<div (click)="onClick()" class="label">
  <ng-content select="label"></ng-content>
</div>

<ng-content select="input"></ng-content>
<ng-content select="textarea"></ng-content>
<ng-content select="my-date-picker"></ng-content>
<ng-content select="ng-select"></ng-content>

<i *ngIf="hasCheck && isCheckVisible" class="material-icons">check</i>

