import {Component, Input} from '@angular/core';
import {ClientPropertiesComponent} from "../client-properties.component";

@Component({
  moduleId: module.id,
  selector: 'xavier-client-properties-settings',
  templateUrl: 'settings.component.html'
})

export class ClientPropertiesSettingsComponent {

  @Input() public settings;
  @Input() public clientId;

  constructor(public cp: ClientPropertiesComponent) {

  }

}
