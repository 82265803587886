import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {HttpService} from "../../shared-services/http.service";
import { Subject } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';

@Injectable()
export class DbsService {

  private jobPositions$: Observable<any>;
  private enhancedJobPositions$: Observable<any>;
  private standardJobPositions$: Observable<any>;
  private forceReload$ = new Subject<void>();

  constructor(private httpService: HttpService) {

  }

  public getJobSectors() {
    return this.get('getJobSectors', null);
  }

  public get(methodName: string, params: any): Observable<any> {
    return this.httpService.get("/_ah/api/dbs/v1", methodName, params);
  }

  public getJobPosition(checkType: string): Observable<any[]> {
    return this.get(`getJobPositions?checkType=${checkType}&covidRelated=null`, null).pipe(
      map(r => {
        const sortedItems = r.items;
        sortedItems.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        return sortedItems;
      })
    );
  }

  public getJobPositions(checkType: string): Observable<any[]> {
    if (checkType === 'STANDARD') {
      if (!this.standardJobPositions$) {
        this.standardJobPositions$ = this.getJobPosition(checkType).pipe(
          shareReplay(1),
          takeUntil(this.forceReload$)
        );
      }
      return this.standardJobPositions$;
    } else {
      if (!this.enhancedJobPositions$) {
        this.enhancedJobPositions$ = this.getJobPosition(checkType).pipe(
          shareReplay(1),
          takeUntil(this.forceReload$)
        );
      }
      return this.enhancedJobPositions$;
    }

  }

  public forceReloadJobPositions(): void {
    this.forceReload$.next();
    this.enhancedJobPositions$ = null;
    this.standardJobPositions$ = null;
  }
}
