import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {ScreeningService} from "../../services/screening.service";
import {fadeIn} from "../../../../data/animations.data";

@Component({
  moduleId: module.id,
  selector: 'xavier-preview-check',
  templateUrl: 'preview-check.component.html',
  styleUrls: ['preview-check.stylesheet.sass'],
  animations: [fadeIn]
})

export class PreviewCheckComponent implements OnInit, OnDestroy {

  @Input() public group: any;
  @Input() public checkId: any;
  @Input() public preview: boolean;
  @Input() public loading: boolean = true;

  private id;
  private routeSub: any;
  private checkPreviewSub: any;
  private startRouteUrl: string;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private screeningService: ScreeningService,
              private titleService: Title) {
    this.routeSub = this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
    });

    this.checkPreviewSub = this.screeningService.getPreviewCheck(this.id).subscribe(previewData => {
      if (previewData.hasOwnProperty('id')) {
        this.group = previewData;
        this.checkId = this.id;
        this.preview = true;
        this.loading = false;
      }
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Check Preview - Xavier Eurocom CI');
    this.startRouteUrl = (this.activatedRoute.snapshot.queryParams.hasOwnProperty('startRouteUrl')) ?
      this.activatedRoute.snapshot.queryParams['startRouteUrl'] :
      'dashboard/checks';
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
    this.checkPreviewSub.unsubscribe();
  }

  closePreviewCheck() {
    this.router.navigate([this.startRouteUrl]);
  }

}
