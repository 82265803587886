import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'xavier-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input()
  public isLoading: boolean;

  @Input()
  public type: string;

  @Input()
  public disabled: boolean;
}
