import {Component, OnInit} from '@angular/core';
import {DynamicFormService} from '../../../../services/dynamic-form.service';
import {IDENTITY_VERIFICATION_TYPE} from '../../../../../../data/variables.data';
import {Attribute, ATTRIBUTE_TYPES_MAP} from '../attribute.class';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../../../../environments/environment';

@Component({
  moduleId: module.id,
  selector: 'xavier-identity-verification',
  templateUrl: 'identity-verification.component.html',
  styleUrls: ['identity-verification.stylesheet.sass']
})
export class IdentityVerificationAttribute extends Attribute implements OnInit {

  public readonly YOTI_BUTTON_TEXT = 'CONTINUE';
  public form: FormGroup;
  public mode: 'PASSPORT_QUESTION' | 'IDENTITY_VERIFICATION_ONLY' | 'COMPLETED' = 'PASSPORT_QUESTION';

  constructor(
    public dynamicFormService: DynamicFormService,
    public fb: FormBuilder,
  ) {
    super(dynamicFormService);
  }

  public ngOnInit(): void {
    const formGroup = this.dynamicFormService.getFormGroup(this.formGroupId);
    const formControl = this.dynamicFormService.attributeToFormControl(this.data);

    formGroup.addControl(this.formGroupId + '-' + this.data.id, formControl);

    this.formGroup = formGroup;
    this.formControl = formControl;

    this.form = this.fb.group({
      mode: [null, Validators.required]
    });

    // select mode
    if (this.dynamicFormService.dynamicForm.yotiCompleted) {
      this.mode = 'COMPLETED';
    } else if (!this.isDbsRtw) {
      this.mode = 'IDENTITY_VERIFICATION_ONLY';
    } else {
      this.mode = 'PASSPORT_QUESTION';
    }
  }

  public get isDbsRtw(): string {
    return environment.production && this.dynamicFormService.dynamicForm.dbsRtw;
  }

  public onBackToMenuFromIdentityVerification(): void {
    this.mode = this.isDbsRtw ? 'PASSPORT_QUESTION' : 'IDENTITY_VERIFICATION_ONLY';
    this.backToMenu();
  }

  public onBackToMenuFromDigitalId(): void {
    this.mode = 'PASSPORT_QUESTION';
    this.backToMenu();
  }

  private backToMenu(): void {
    const mode = this.form.get('mode').value;
    this.form.get('mode').setValue(null);
    window.setTimeout(() => {
      this.form.get('mode').setValue(mode);
    }, 10);
  }
}

ATTRIBUTE_TYPES_MAP.set(IDENTITY_VERIFICATION_TYPE, IdentityVerificationAttribute);
