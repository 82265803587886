<div class="row">
  <div class="form-group col-12 xavier-input-multi-select">
    <label>{{title}}</label>
    <span class="required">&bull;</span>
    <div class="group" [ngClass]="errorClass" id="{{id}}">
      <button *ngFor="let element of elements"
              type="button"
              class="btn btn-primary xavier-multi-button"
              [ngClass]="{'active': isActive(element.id)}"
              id="{{element.id}}"
              (click)="onClick($event.target.id, id)">
        {{element.label}}
      </button>
    </div>
  </div>
</div>
