import {Component, OnInit} from "@angular/core";
import {ATTRIBUTE_TYPES_MAP, DashboardAttribute} from "../dashboard-attribute.class";
import {DynamicFormService} from "../../../../services/dynamic-form.service";
import {RADIO_TYPE} from "../../../../../../data/variables.data";

@Component({
  moduleId: module.id,
  selector: 'xavier-dashboard-radio',
  templateUrl: 'dashboard-radio.component.html',
  styleUrls: ['dashboard-radio.stylesheet.sass']
})

export class DashboardRadioAttribute extends DashboardAttribute implements OnInit {

  public options = [];

  constructor(public dynamicFormService: DynamicFormService) {
    super(dynamicFormService);
  }

  ngOnInit() {
    let formGroup = this.dynamicFormService.getFormGroup(this.formGroupId);
    let formControl = this.dynamicFormService.attributeToFormControl(this.data);

    formGroup.addControl(this.formGroupId + '-' + this.data.id, formControl);

    this.formGroup = formGroup;
    this.formControl = formControl;

    this.options = this.data.options.split(',');
  }

}

ATTRIBUTE_TYPES_MAP.set(RADIO_TYPE, DashboardRadioAttribute);
