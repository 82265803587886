import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { isNullOrUndefined } from "util";
import { environment } from "../../../../environments/environment";
import { AbstractControl, FormControl } from "@angular/forms";
import { EventUtils } from "../../../utils/event.utils";

@Component({
  selector: "xavier-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.stylesheet.sass"],
})
export class FileUploadComponent implements OnInit, AfterViewInit {
  @Input() public inputId: string;
  @Input() public progressVisible: boolean;
  @Input() public progress: number = 0;
  @Input() public errorClass: string;
  @Input() public control: AbstractControl;
  public fileName: string;
  public filenameFormControl: AbstractControl;
  public folderFormControl: AbstractControl;
  public thumbnailFormControl: AbstractControl;
  public downloadFile: string;

  public supportedFileTypes: string[] = ["image/png", "image/jpeg", "application/pdf"];

  // ControlValueAccess implementation
  value: any;
  onChange = () => {};
  onTouched = () => {};

  writeValue(value) {
    if (!isNullOrUndefined(value))
      this.value = value;
      this.control.setValue(this.value);
  }

  getValue() { return this.value; }
  registerOnChange(fn: any) { this.onChange = fn; }
  registerOnTouched(fn: any) { this.onTouched = fn; }

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.control.clearValidators();
    this.control.updateValueAndValidity();

    this.filenameFormControl = new FormControl("");
    this.folderFormControl = new FormControl("");
    this.thumbnailFormControl = new FormControl("");
  }

  ngAfterViewInit(): void {
    if (this.control.value) {
      this.removeThumbnail();
      this.setThumbnail(this.control.value);
    }
  }

  fileChangeEvent(fileInput: Event, containerId: string) {
    if (this.supportedFileTypes.indexOf(fileInput.target["files"][0].type) >= 0)
      this.dragFileAccepted(fileInput.target["files"][0], containerId);
    else
      this.dragFileRejected(fileInput.target["files"][0], "fileDropArea_" + containerId);
  }

  dragFileAccepted(acceptedFile: any, containerId: string) {
    this.hidePanel("fileLoaderDrop", containerId);
    this.showPanel("fileLoading", containerId);

    let file;
    if (acceptedFile.dataTransfer) {
      file = EventUtils.getFile(acceptedFile);

      if (!this.supportedFileTypes.includes(file.type)) {
        this.dragFileOverEnd("fileDropArea_" + containerId);
        this.hidePanel("fileLoading", containerId);
        this.showPanel("fileLoaderDrop", containerId);
        return;
      }
    } else {
      if (!isNullOrUndefined(acceptedFile.file)) file = acceptedFile.file;
      else file = acceptedFile;
    }


    this.fileName = file.name;
    let headers = new HttpHeaders({ 'Content-Type': file.type });
    const req = new HttpRequest(
      'POST',
      'https://'+environment.storageBucket+'/uploadFile/?filename=' + encodeURIComponent(file.name),
      file,
      {
        headers: headers,
        reportProgress: true,
        withCredentials: true
      }
    );

    this.http.request(req).subscribe((event) => {
      this.progressVisible = true;
      if (event.type === HttpEventType.UploadProgress) {
        this.progress = Math.round((100 * event.loaded) / event.total);
      } else if (event instanceof HttpResponse) {
        this.filenameFormControl.setValue(event.body["name"]);
        this.folderFormControl.setValue(event.body["folder"]);

        let value = {
          name: event.body['name'],
          folder: event.body['folder']
        };
        if (event.body['thumbnail']) {
          this.thumbnailFormControl.setValue(event.body['thumbnail']);
          value['thumbnail'] = event.body['thumbnail'];
        }
        this.writeValue(value);
        setTimeout(() => {
          // this is a necessary HACK to make the file input form element to work with Safari, IE/Edge and other mobile browsers
          // Angular doesn't handle the input type='file' properly as a ReactiveForm element so a ControlValueAccessor is necessary
          // but the implementation is not flawless and it's proved here where the FormControl value is empty and the control
          // is always set to INVALID
          this.control.setErrors(null, {emitEvent: false});
          this.control.markAsTouched();
        }, 0);

        let previewFileName;
        if (event.body.hasOwnProperty('thumbnail')) {
          previewFileName = 'https://'+environment.storageBucket+'/gcs/' + event.body['folder'] + '/' +
            event.body['thumbnail'].replace('@xN', '@x3');
        } else {
          previewFileName = 'https://' + environment.storageBucket + '/gcs/' + event.body['folder'] + '/' + event.body['name'];
        }

        this.downloadFile = 'https://' + environment.storageBucket + '/gcs/' + event.body['folder'] + '/' + event.body['name'] + '?download=true';

        let obj = document.createElement('object');
        obj.setAttribute("data", previewFileName);
        obj.setAttribute("type", file.type);
        obj.setAttribute("width", "80");
        obj.setAttribute("height", "80");

        let emb = document.createElement('embed');
        emb.setAttribute("src", previewFileName);
        emb.setAttribute("type", file.type);
        emb.setAttribute("width", "80");
        emb.setAttribute("height", "80");

        obj.appendChild(emb);

        let previewContainer = document.getElementById('fileUploadedPreview' + containerId);
        previewContainer.appendChild(obj);

        // hide the Drop Zone and show the Delete tab of the document types the user is not using at the moment
        this.hidePanel('fileLoaderDrop', containerId);
        let dropAreaContainer = document.getElementById('fileDropArea_' + containerId);
        dropAreaContainer.classList.remove('uploadBoxError');

        this.hidePanel('fileLoading', containerId);
        this.showPanel('fileUploaded', containerId);
      }
    });
  }

  dragFileRejected(rejectedFile: any, id) {
    let dropAreaContainer = document.getElementById(id);
    dropAreaContainer.classList.add("rejectedFile");
    setTimeout(() => {
      dropAreaContainer.classList.remove("rejectedFile");
    }, 500);
  }

  dragFileOverEnd(id) {
    let dropAreaContainer = document.getElementById(id);
    dropAreaContainer.classList.remove('dragOver');
  }

  onDragOver(event: Event) {
    event.preventDefault();
  }

  onDragLeave(event: Event) {
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    if (this.supportedFileTypes.indexOf(files[0].type) >= 0)
      this.dragFileAccepted(files[0], this.inputId);
    else this.dragFileRejected(files[0], "fileDropArea" + this.inputId);
  }

  openDialog(id) {
    let fileUploader = document.getElementById(id);
    fileUploader.click();
  }

  deleteDocument(containerId: string) {
    let fileInput = <HTMLInputElement>document.getElementById('fileLoader'+containerId);
    fileInput.value = '';
    let previewContainer = document.getElementById('fileUploadedPreview'+containerId);
    previewContainer.innerHTML = '';
    this.fileName = '';
    this.progress = 0;
    this.progressVisible = false;
    this.value = null;
    this.control.setErrors({ invalid: true }, { emitEvent: true });
    this.control.markAsUntouched();
    this.filenameFormControl.setValue(null);
    this.folderFormControl.setValue(null);
    this.thumbnailFormControl.setValue(null);
    this.hidePanel('fileLoading', containerId);
    this.hidePanel('fileUploaded', containerId);
    this.showPanel('fileLoaderDrop', containerId);
  }

  downloadDocument(containerId: string) {}

  public setThumbnail(file: any) {
    this.fileName = file.name;

    let previewFileName;
    if (file.hasOwnProperty('thumbnail')) {
      previewFileName = 'https://' + environment.storageBucket + '/gcs/' + file.folder + '/' +
        file.thumbnail.replace('@xN', '@x3');
    } else {
      previewFileName = 'https://' + environment.storageBucket + '/gcs/' + file.folder + '/' + file.name;
    }

    this.downloadFile = 'https://' + environment.storageBucket + '/gcs/' + file.folder + '/' + file.name + '?download=true';

    let obj = document.createElement('object');
    obj.setAttribute("data", previewFileName);
    obj.setAttribute("type", 'image/'+file.name.split('.').pop());
    obj.setAttribute("width", "80");
    obj.setAttribute("height", "80");

    let emb = document.createElement('embed');
    emb.setAttribute("src", previewFileName);
    emb.setAttribute("type", 'image/'+file.name.split('.').pop());
    emb.setAttribute("width", "80");
    emb.setAttribute("height", "80");

    obj.appendChild(emb);

    let previewContainer = document.getElementById('fileUploadedPreview' + this.inputId);
    if (!isNullOrUndefined(previewContainer))
      previewContainer.appendChild(obj);

    // hide the Drop Zone and show the Delete tab of the document types the user is not using at the moment
    this.hidePanel('fileLoaderDrop', this.inputId);
    let dropAreaContainer = document.getElementById('fileDropArea_' + this.inputId);
    if (!isNullOrUndefined(dropAreaContainer))
      dropAreaContainer.classList.remove('uploadBoxError');

    this.hidePanel('fileLoading', this.inputId);
    this.showPanel('fileUploaded', this.inputId);
  }

  public removeThumbnail() {
    // remove preview
    const previewContainer = document.getElementById('fileUploadedPreview' + this.inputId);
    if (!isNullOrUndefined(previewContainer) && previewContainer.hasChildNodes()) {
      previewContainer.removeChild(previewContainer.children[0]);
    }

    // show the Drop Zone
    this.showPanel('fileLoaderDrop', this.inputId);
    const dropAreaContainer = document.getElementById('fileDropArea_' + this.inputId);
    if (!isNullOrUndefined(dropAreaContainer))
      dropAreaContainer.classList.remove('uploadBoxError');
  }

  private hidePanel(prefix: string, id: string) {
    let panel = document.getElementById(prefix.concat(id));
    if (!isNullOrUndefined(panel)) panel.classList.add("visibility-none");
  }

  private showPanel(prefix: string, id: string) {
    let panel = document.getElementById(prefix.concat(id));
    if (!isNullOrUndefined(panel)) panel.classList.remove("visibility-none");
  }
}
